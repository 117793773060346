import uuid from 'react-uuid'
import { isSKSDKAttribute } from './HWIframeBridge';


class HWLocalStorage {

    static load = (keyString, decompress) => {
        try {
            const jsonStringData = localStorage.getItem(keyString);
            // if (decompress) {
            //     let decompressString = LZString.decompress(jsonStringData);
            //     console.log("DECOMPRESS:");
            //     let data = JSON.parse(decompressString);
            //     return data;
            // }
            return JSON.parse(jsonStringData);
        } catch (error) {
            // console.log(error);
            return null;
        }
    }
    
    static save = (state, keyString, compress) => {
        try {
            const jsonStringDataToSave = JSON.stringify(state);
            // if (compress) {
            //     let data = LZString.compress(jsonStringDataToSave);
            //     console.log("COMPRESS:");
            //     localStorage.setItem(keyString, data);
            //     return;
            // }
            localStorage.setItem(keyString, jsonStringDataToSave);
        } catch (error) {
            // console.log(error);
        }
    }

    static clear = (keyString) => {
        if (!isSKSDKAttribute(keyString)) {
            try { localStorage.removeItem(keyString); }
            catch (error) {
                // console.log(error); 
            }
        }
    }

    static clearWithPrefix = (keyPrefix) => {
        if (!keyPrefix) return;
        Object.keys(localStorage).forEach(itemKey => {
           if (itemKey.startsWith(keyPrefix) && !isSKSDKAttribute(itemKey)) { // Don't remove any SK SDK Attributes,
              window.localStorage.removeItem(itemKey);
           }
        })
     }

     static deviceId = () => {
        let deviceId = localStorage.getItem("platform-deviceId");
        if (!deviceId) { 
            let sdkDeviceId = localStorage.getItem("sk-sdk-device-id");
            if (!sdkDeviceId) {
                return uuid();
            }
            return sdkDeviceId;
         }
        return deviceId;
    }

}

export default HWLocalStorage;
