import React, { useEffect, useState } from 'react';
import "./SkRadioBtn.css"
function SkRadioBtn(props) {

    const [isChecked, setIsChecked] = useState(false);
    const [unqId, setUnqId] = useState(Math.floor(Math.random() * 100))

    useEffect(()=>{
        if(props.checked){
            setIsChecked(props.checked)
        }else{
            setIsChecked(false)
        }
         
    },[props.checked])
    
    const onCheckHandler = (value,value1) => {
       if(!props.disabled){
        props.onChange && props.onChange(!isChecked===true?value:value1?value1:null);
        if(!props.setCheckValueDependsOnPropsValue){
            setIsChecked(!isChecked);
        }
       }
    }
    return (
        <div className={`sk-radioBtn ${props.className}`} >
            <div className="wrap" >
                <input  onClick={(e) => { e.stopPropagation();e.stopPropagation()}} className={props.disabled ? 'skRadioBtnInput skCheckInputDesable' : 'skRadioBtnInput'} value={props.value} id={props.id ? props.id : `skRadioBtn${unqId}`} type="radio" disabled={props.disabled} name={props.name} checked={isChecked ? isChecked : false}/>
                <span className="design" onClick={(e) => { e.stopPropagation(); onCheckHandler(props.value,props?.value1) }}></span>
                <label style={{cursor:props.isLableClickable?"pointer":""}} onClick={(e) => { e.stopPropagation();e.stopPropagation();if(props.isLableClickable){onCheckHandler(props.value)} }} className={props.disabled ? "skRadioBtnInputDesable" : ""} htmlFor={props.id ? props.id : `skRadioBtn${unqId}`}>{props.label}</label>
            </div>
        </div>
    );
}
export default SkRadioBtn;