import HWLocalStorage from './HWLocalStorage';
import TicketService from '../SkSmartUtil/FreshdeskTicketService';
import HWUser from './HWUser';
import asanaService from '../SkSmartUtil/AsanaService';
import HWLog from './HWLog';
import PlatformAPI from './PlatformAPI';
import gsuiteAPIService from '../SkSmartUtil/GsuiteAPIService';
import axios from 'axios'
import cache from '../SkSmartUtil/InMemoryCache';
import smartFilterTokenService from '../SmartFilterTab/SmartFilterToken';



const platformTargetDomain = process.env.REACT_APP_PLATFORM_TARGET_DOMAIN;

let onDateRefreshMain = null;
const platformWatchForPlatformEvents = (appComponent, onDateRefresh) => {
  onDateRefreshMain = onDateRefresh;
};

const clearPlatformDetails = () => {
  Object.keys(localStorage).forEach(itemKey => {
    if (itemKey.startsWith('platform-')) { window.localStorage.removeItem(itemKey); }
  });
  window.localStorage.removeItem('showAPIAlertFlag');
  window.localStorage.removeItem('showProductMappingFlag');
  
};

// The default css in the following function is taken from ':root' object of App.css. This may require changes if something is changed in the root object of App.css
const handleThemeReset = () => {
	const defaultCss = `
	:root {
		--primary-color: #FFFFFF;
		--secondary-color: #7327CC;			
		--sk-font-family: Urbanist;
		--sk-font-urbanist-family: Urbanist;
		--sk-font-sans: DM Sans;
		--sk-nav-font-size: 14px;
		--sk-select-color: #ECE1F9;		
		--sk-heading-color: #2E2B78;			
		--sk-border-color: #E5E5E8;
		--sk-black-text-color: #000000;
		--sk-black-text-bg: #000000;
		--sk-table-font-size: 14px;
		--sk-nav-color: #000044;
		--sk-actions-create: #ff7e72;
		--sk-menu-bg-color: #F3ECFC;
		--sk-loyalty-user-churn-color-one: #AA8FCC;
		--sk-loyalty-user-churn-color-two: #EAD9FC;
		--sk-dropdown-hover-bg-color: #ece1f95e;
		--sk-dashboard-bg-img-1: #3A1366;
		--sk-dashboard-bg-img-2: #E2DDE8;					
		--sk-dashboard-bg-img--colour-3: #7d659a;		
		--sk-orange-color-notification: #FC7242;
		--radio-btn-unselect-bg-color: #11081f;
		--radio-btn-lable-color: #8C8893;
		--sk-share-and-konversations-label-color: #707070;
		--sk-datepicker-month-color: #2e2b78;
		--scrollbarBG: transparent;
		--thumbBG: #fdfdfd;
		--global-filter-Atributes-bg: #FAFAFA;		
		--confirm-btn-color: #FD7E72;
		--action-btn-hover-color:#FF7E721A;
		--sk-modal-header-color : #ECE1FA;		
		--sk-modal-backdrop-bg: rgba(0,0,68,0.46);    
		--sk-loader-background: rgba(249, 250, 252, 0.5);
		--sk-template-card-bg: #EDE7F5;   
		--sk-highchart-color: #B9A2D5;
		--sk-highchart-marker-color: #806BAA;
		--sk-no-data-text-color: #8A7AA7;
		--smart-pro-icon-color:#5f1fa6;
		--smart-konversations-label-wrap-color: #5f1fa6; 
		--setting-selected-menu: #6834C5;
		--setting-table-header-bg: #F6F0FF;
		--sk-highchart-color-var: #D6C7E8;
		--expand-tabele-border-color: #2304681A;

		--graph-shades-start-color: #170c53;
		--graph-shades-end-color: #F1ECF9;

		/* smart konversations */
		--sk-placeholder-color: #9e9e9e;
		--smart-insights-icon-background-color: #7C629B; 
		--smart-insights-and-konversations-intelligence-heading-color: #000043;		
		--smart-konversations-label-color: #5f1fa6;			
		/* smart konversations */
	
		/* call intelligence */
		--call-intelligence-header-color: #F9F3FF;
		--call-intelligence-table-label-color: #070401;
		--call-intelligence-smart-assist-input-dark-color: #5f1fa6;
		--call-intelligence-light-color: #bca3d9;
		--call-intelligence-graph-dark-color: #aa8fcc; 
		--call-intelligence-graph-light-color: #d4c3e6;
		/* call intelligence */
		--pc-graph-sub-header-color: #59686B;
		--red-dot: #FF0000;
		--green-dot: #108110;
		--sk-date-filter-header-color: #511992;
		--sk-smart-modle-bg-color: #000044a3;
		--desable-color: #5f5f64;
		/* Sia */
		--sia-user-textbox-border-color: #ead9fc;
		--sia-chatbox-textbox-border-color: #DCCDEC;
		--sia-chatbox-textbox-background-color: #f6f1fc;
		--sia-header-color: #818181;
		--sia-input-box-color: #F6F6F7;
		/* Sia */
		--sk-smart-primary-btn-bg: #FF7E72;
		--sk-smart-datepicker-border-color: #5F1FA6;
		--sk-line-pc-graph-color: #9F8FB3;
	
		/*Eaxanpsion Analyser*/
		--sk-sub-tab-border-color: #69697629;
		--sk-sub-tab-text-color: #696976ab;
		--sk-feature-tab-text-color: #070707;
		--sk-expansion-analyser-container-border-color: #69697605;
		--sk-expansion-analyser-table-q1-color: #F7F2FD;
	
		/* placeholder colors */
		--sk-smart-placeholder-color: #564f4f;
		--sk-smart-react-select-focus-color : #EFE8F6;
	
		/* smart comments */
		--sk-comment-time-color: #999999;
		/* side pop up  */
		--side-pop-up-footer-color:#F2F2F2; 
		/*graph popper*/
		--sk-graph-popper:#B0B7C324;
		--sk-graph-popper-boder-color: #CFCFCF;
		--desable-color-input :#f2f2f2;
	
		--tabele-border-color:#2304681A;					
		--template-toggle-bg-color:#7C6399;
		--sk-placeholder-color:#757575;
		--playbook_progress_bar_bg:#e2dfdf;
		--active-row-bg:#F3ECFC80;				
		--progress_bar_active_bg:#4FD17D;
		--progress_bar_delayed_bg:#F57967;
		--account_360_graph_light_color: #D4C3E6;
		--nps_yellow_color:#FFC86E;
		--health_score_red_color:#F24D34;
		--health_score_footer_date_color: #574F62;
		--health_score_undefined_color:#d8dbde;
		--signup-input-bg-color:#FBFBFB;
	
		/* Churn Dashboard colors */
		--churn_rate_color: #ED4A34;
		--non_churn_rate_color: #C5C6D2;
		--getting-started-donut-chart-light-color: #EBE3F6;
		--playbook-overview-gray-color: #666666;
		height: 100vh;
		
		--iconUrl: var(--iconUrl-copy); 
		--logo-url: var(--logo-url-copy);
	}`;

	const styleElement = document.createElement('style');
	styleElement.textContent = defaultCss;
	document.head.appendChild(styleElement);
} 

const handleUrlReset = () => {
	window.localStorage.removeItem('redirect-url');
	if(window.location.host.includes('digiplaybooks')){
		// let url = process.env.REACT_APP_DIGIPLAYBOOKS_DOMAIN + '/redirect-to-log-out';
		// let url = 'http://localhost:3000' + '/redirect-to-log-out';
		let url = process.env.REACT_APP_CURRENT_PLATFORM_DOMAIN + '/redirect-to-log-out';
		window.location.href = url;
	}
}

 const logoutOnUnsupportedBrowser = () =>{
	smartFilterTokenService.token=null;
    clearPlatformDetails();
    window.localStorage.removeItem('lsmViewType');
    window.localStorage.removeItem('listView');
    asanaService.clearAllAsanaCache();
    gsuiteAPIService.clearGsuiteCache();
    TicketService.clearAllFreshdeskCache();
    asanaService.clearAllJiraCache();
    HWLocalStorage.clearWithPrefix('sk-');
    HWLocalStorage.clear('showAPIAlertFlag');
    HWLocalStorage.clear('showProductMappingFlag');
    HWLocalStorage.clear("sk-filterToken");
		HWLocalStorage.clear('teamKonversationVisible');
		HWLocalStorage.clear('SmartKonDashboardVisible');
		HWLocalStorage.clear('functionConfig');
		HWLocalStorage.clear('outcomeConfig');
		HWLocalStorage.clear('CustomTheme');
		handleThemeReset();

}

const platformSignOut = () => {
  let platformAPIService = new PlatformAPI();
  platformAPIService.signout().then(response => {
    // portFolioService.filterData = null;
    smartFilterTokenService.token=null;
    HWUser.resetUserImpersonated();
    clearPlatformDetails();
    window.localStorage.removeItem('lsmViewType');
    window.localStorage.removeItem('listView');
    asanaService.clearAllAsanaCache();
    gsuiteAPIService.clearGsuiteCache();
    TicketService.clearAllFreshdeskCache();
    asanaService.clearAllJiraCache();
    HWLocalStorage.clearWithPrefix('sk-');
    HWLocalStorage.clear('showAPIAlertFlag');
    HWLocalStorage.clear('showProductMappingFlag');
    HWLocalStorage.clear("sk-filterToken");
		HWLocalStorage.clear('teamKonversationVisible');
		HWLocalStorage.clear('SmartKonDashboardVisible');
		HWLocalStorage.clear('functionConfig');
		HWLocalStorage.clear('outcomeConfig');	
		HWLocalStorage.clear('CustomTheme');
		HWLocalStorage.clear('showGettingStarted');
    // postPlatformMessage (message);
    resetSDKUser();
    onDateRefreshMain && onDateRefreshMain(true);
		handleThemeReset();
		handleUrlReset();
  });
};

const allySignOut = () => {
  let platformAPIService = new PlatformAPI();
  
  platformAPIService.allySignout().then(response => {

    if(response?.data?.message==='SignOut'){
    HWUser.resetUserImpersonated();
    clearPlatformDetails();
    asanaService.clearAllAsanaCache();
    gsuiteAPIService.clearGsuiteCache();
    TicketService.clearAllFreshdeskCache();
    asanaService.clearAllJiraCache(); 

    HWLocalStorage.clearWithPrefix('sk-');
    HWLocalStorage.clear('showAPIAlertFlag');
    // postPlatformMessage (message);
    resetSDKUser();
    onDateRefreshMain && onDateRefreshMain(true);
    //window.location.reload();
    }
  });
};

const onPlatformSignInOrSignUp = data => {
	// clearGsuitePopUpAsShown();
	const gsuiteLocalStorageStatusKey = "gsuite-auth-pop-up-status";
	HWLocalStorage.clear(gsuiteLocalStorageStatusKey);
  localStorage.setItem('platform-currentUser', JSON.stringify(data));
  localStorage.setItem('platform-accessToken', JSON.stringify(data['accessToken']));
  localStorage.setItem('platform-refreshToken', JSON.stringify(data['refreshToken']));
  let currentUser = JSON.parse(localStorage.getItem('platform-currentUser'));
  if (currentUser) {
    localStorage.setItem("platform-userPic", currentUser?.user?.profileImageURL);
    localStorage.setItem("platform-multiLevelType", currentUser?.multiLevelType);
    if (window.UsageAnalytics) {
      let userId = currentUser.user.userId;
      let orgId = currentUser.user.organizationId;
      if (userId && orgId && !HWUser.isImpersonatedUser()) {
        window.UsageAnalytics.setUser(userId, orgId);
        // console.log("SETTING SDK USER");
      }
    }
  }
  if(data?.filterToken){
    let filterToken = JSON.parse(atob(data.filterToken));
    HWLocalStorage.save(filterToken, "sk-filterToken");
  }
  if(HWUser.appId() === "7227fbfc-6cae-4f44-8a33-4612781455d9"){
    window.localStorage.setItem("showProductMappingFlag", '0');
  }
  onDateRefreshMain && onDateRefreshMain(true);
};

const onPlatformUserSwitch = responseData => {
  // console.log(responseData)
  clearPlatformDetails();
  // Clear local data,
  HWLocalStorage.clearWithPrefix('sk-');
  asanaService.clearAllAsanaCache();
  TicketService.clearAllFreshdeskCache();
  asanaService.clearAllJiraCache();
  gsuiteAPIService.clearGsuiteCache();

  // Udpate local SDK
  resetSDKUser();

  // Update new data
  onPlatformSignInOrSignUp(responseData)
};

const updateProfileDetails = () => {
  return fetchUserProfile();
};

const isSKSDKAttribute = attributeKey => {
  if (!attributeKey) return false;
  let skSDKAttributesList = [
    'app-config',
    'sessionId',
    'sk-sdk-device-id',
    'fetch-actions-for-trigger-event-request',
    'platform-app-config',
    'platform-sessionId',
    'platform-sk-sdk-device-id',
    'platform-fetch-actions-for-trigger-event-request',
  ];
  let isPresent = (skSDKAttributesList.indexOf(attributeKey) !== -1) ||
    (attributeKey.endsWith('-user-info') || attributeKey.endsWith('-sk-launch-trigger-share-url'));
  return isPresent;
};

const logSDK = (module, eventName) => {
  try {
      let eventParams = { "Module": module }
      window && window.UsageAnalytics && window.UsageAnalytics.logEvent(eventName, eventParams);
  } catch { 
		// console.log("Error while logging SDK event: "); 
	}
}

const markUserAsLoggedInSDK = () => {
  let userId = HWUser.userId();
  let orgId = HWUser.orgId()
  if (window.UsageAnalytics && userId && orgId && !HWUser.isImpersonatedUser()) {
    window.UsageAnalytics.setUser(userId, orgId);
    updateSDKUserAttributes();
    // SKAnalyticsLog.log("Login", "Access: Login");
    logSDK("Login", "Access: Login");
  }
}

const updateSDKUserAttributes = () => {
  let userId = HWUser.userId();
  let user = HWUser.user();
  if (userId && user && window.UsageAnalytics && !HWUser.isImpersonatedUser()) {
    window.UsageAnalytics.setUserAttribute(window.UsageAnalytics.UserAttribute.userId, userId);
    if (user.name) { window.UsageAnalytics.setUserAttribute(window.UsageAnalytics.UserAttribute.name, user.name); }
    if (user.emailId) { window.UsageAnalytics.setUserAttribute(window.UsageAnalytics.UserAttribute.emailId, user.emailId); }
    if (user.phoneNumber) { window.UsageAnalytics.setUserAttribute(window.UsageAnalytics.UserAttribute.mobileNumber, (user.callingCode || '') + (user.phoneNumber + '')); }
    if (user.userRole) {
      window.UsageAnalytics.setUserAttribute(window.UsageAnalytics.UserAttribute.designation, user.userRole);
      window.UsageAnalytics.setUserAttribute("Persona", user.userRole);
    }
  }
};

const resetSDKUser = () => {
   cache.invalidate();
  if (window.UsageAnalytics) {
    // Log Event
    // SKAnalyticsLog.log("Login", "Access: Logout");
    logSDK("Login", "Access: Logout");
    window.UsageAnalytics.resetUser();
    // portFolioService.filterData = null;
    // portFolioService.parseFilterData = null
    // portFolioService.filterCount = null
  }
};

// const prefetchOnPlatformSignIn = () => {
//   // Possible Alert Attribute Values.
//   taskDashboardService.fetchPosiibleAttributes({ userId: HWUser.userId(), appId: HWUser.appId() }, true, () => { });
// };

const onPlatformEnvironmentChange = (data) => {
  asanaService.clearAllAsanaCache();
  TicketService.clearAllFreshdeskCache();
  asanaService.clearAllJiraCache();

  localStorage.setItem("platform-Toggle", data.user.platformEnvironment);
  localStorage.setItem('platform-currentUser', JSON.stringify(data));
  localStorage.setItem('platform-accessToken', JSON.stringify(data['accessToken']));
  localStorage.setItem('platform-refreshToken', JSON.stringify(data['refreshToken']));

  let currentUser = JSON.parse(localStorage.getItem('platform-currentUser'));
  currentUser.apps = data.apps;
  gsuiteAPIService.clearGsuiteCache();
  fetchUserProfile();
  onDateRefreshMain && onDateRefreshMain(true);
}

const onPlatformTokenRefresh = (accessToken, refreshToken) => {
  localStorage.setItem('platform-accessToken', JSON.stringify(accessToken));
  localStorage.setItem('platform-refreshToken', JSON.stringify(refreshToken));
  const currentUser = JSON.parse(localStorage.getItem('platform-currentUser'));
  currentUser['accessToken'] = accessToken;
  currentUser['refreshToken'] = refreshToken;
  localStorage.setItem('platform-currentUser', JSON.stringify(currentUser));
  fetchUserProfile();
  onDateRefreshMain && onDateRefreshMain(false);
}

const fetchUserProfile = () => {
	
  let url = window.resolveRegion(process.env.REACT_APP_BASE_API_URL_AUTH) + "/fetchprofile";
  const accessToken = HWUser.accessToken();
  const body = { "userId": HWUser.userId(), "accessToken": accessToken }
  let aConfig = { headers: { "Authorization": accessToken, "Content-Type": "application/json" } };
  return axios.post(url, body, aConfig).then(response => {
    let currentUser = JSON.parse(localStorage.getItem('platform-currentUser'));
    currentUser.userAppPreferences = response.data.userAppPreferences;
    currentUser.userPlatformPreference = response.data.userPlatformPreference;
    currentUser.user = response.data.user;
    currentUser.apps = response.data.apps;
    currentUser.userRoles = response.data.userRoles;
    localStorage.setItem('platform-currentUser', JSON.stringify(currentUser));
		localStorage.setItem('outcomeConfig', JSON.stringify(response.data.outcomeConfig));
		if (response.data.functionConfig) {
			window.localStorage.setItem('functionConfig', JSON.stringify(response.data.functionConfig));
		}
		localStorage.setItem('currency', response.data.currency);
		localStorage.setItem('browserSupport', response.data.browserSupport);
  }).catch(error => { 
		// console.log(error); 
	});
}
// const fetchUserFilterPreference = ()=>{
//   let url = window.resolveRegion(process.env.REACT_APP_SMART_DASHBOARDS_BASE_URL)+ "/smart-filter/user-filter-preference";
//   let aConfig = { headers: { "Authorization": HWUser.accessToken(), "Content-Type": "application/json","addFilter": true } };
//   aConfig.params = { orgId: HWUser.orgId(), appId: HWUser.appId(), userId: HWUser.userId()}
//   return axios.get(url, aConfig)
// }

///

export {
  markUserAsLoggedInSDK,
  updateSDKUserAttributes,
  resetSDKUser,
  updateProfileDetails,
  platformSignOut,
  allySignOut,
  platformWatchForPlatformEvents,
  platformTargetDomain,
  onPlatformEnvironmentChange,
  onPlatformSignInOrSignUp,
  onPlatformTokenRefresh,
  isSKSDKAttribute,
  onPlatformUserSwitch,
  fetchUserProfile,
	logoutOnUnsupportedBrowser
  // prefetchOnPlatformSignIn,
  // onSwitchOrganization,
};
