import axios from 'axios';
import HWLocalStorage from '../HWLibrary/HWLocalStorage'
import HWUser from '../HWLibrary/HWUser';
import { chunk } from 'lodash';
import { serializePromises } from '../HWLibrary/HWUtils';
import moment from 'moment';
import cachedAPI from './CachedAPI';
export class AsanaService {

    accessToken = null;
    config = () => { return { headers: { "Authorization": "Bearer " + this.accessToken } } };

    workspaceId = null;///this.smartkarrotWorkspaceId;

    taskAPIBaseURL = "https://app.asana.com/api/1.0";

    static credentialsLoadStatusEnum = { loaded: "LOADED", notStarted: "NOT_STARTED", loading: "LOADING", notAvailable: "NOT_AVAILABLE", serverError: "SERVER_ERROR" }

    credentialsLoadStatus = AsanaService.credentialsLoadStatusEnum.notStarted;
    onCredentialsUpdateCallback = null;
    onCredentialsUpdate = () => {
        AsanaService.saveCredentialsStatus(this.credentialsLoadStatus);
        if (this.onCredentialsUpdateCallback) { this.onCredentialsUpdateCallback(this.credentialsLoadStatus); }
    };

    static getCredentialsStatusKey = () => { return "sk-asana-credentials-status-" + HWUser.userId() + "-" + HWUser.appId(); }
    static getCredentialsStatus = () => { return HWLocalStorage.load(this.getCredentialsStatusKey()); }
    static saveCredentialsStatus = (status) => { HWLocalStorage.save(status, this.getCredentialsStatusKey()); }

    constructor() {
        // console.log("NEW OBJECT ASANA");
        if (!AsanaService.instance) { AsanaService.instance = this }
        return AsanaService.instance
    }

    configureWithCredentials(onCredentialsUpdateCallback) {
        if (HWUser.isDemoAccount()) {
            this.taskAPIBaseURL = window.resolveRegion(process.env.REACT_APP_DASHBOARDS_BASE_API_URL) + "/demo-data/demo-tasks";
        }
        this.credentialsLoadStatus = AsanaService.credentialsLoadStatusEnum.notStarted;
        this.onCredentialsUpdateCallback = onCredentialsUpdateCallback;
        this.loadCredentials();
    }

    asanaDataLocalCacheKey = "sk-asana-project" + this.workspaceId;
    isServerCallInProgress = false;

    asanaProjectsLocalCacheKey = () => { return "sk-asana-projects-list-" + HWUser.userId(); }
    asanaWorkingProjectLocalCacheKey = () => { return "sk-asana-working-project" + HWUser.userId(); }

    cachedProjectsList = () => { return HWLocalStorage.load(this.asanaProjectsLocalCacheKey()) || [] }
    cachedWorkingProject = () => { return HWLocalStorage.load(this.asanaWorkingProjectLocalCacheKey()); }

    onWorkingProjectChange = (changedProjectGid, onUpdate) => {
        let changedProject = (this.cachedProjectsList() || []).filter(p => p.gid === changedProjectGid)[0];
        if (!changedProject) { onUpdate(); }

        HWLocalStorage.clear(this.asanaWorkingProjectLocalCacheKey());
        HWLocalStorage.save(changedProject, this.asanaWorkingProjectLocalCacheKey());
        onUpdate();
        this.fetchSectionsAndTasksForProject(changedProject, onUpdate);
    }

    // Quick load one project to show,
    quickLoadProjects = (onSuccess) => {
        let fetchingForCacheKey = this.asanaProjectsLocalCacheKey();
        return this.getProjectsList(1).then(response => {
            response.data.data.sort((a, b) => b.modified_at.localeCompare(a.modified_at))
            let projectsListCache = response.data.data;
            HWLocalStorage.save(projectsListCache, fetchingForCacheKey);

            if (!this.cachedWorkingProject() && projectsListCache && projectsListCache.length > 0) {
                this.fetchSectionsAndTasksForProject(projectsListCache[0], onSuccess);
            }
            onSuccess();
            return response;
        });
    }

    // 
    loadProjects = (onSuccess) => {
        let fetchingForCacheKey = this.asanaProjectsLocalCacheKey();
        return this.getProjectsList().then(response => {
            response.data.data.sort((a, b) => b.modified_at.localeCompare(a.modified_at))
            let projectsListCache = response.data.data;
            HWLocalStorage.save(projectsListCache, fetchingForCacheKey);

            if (!this.cachedWorkingProject() && projectsListCache && projectsListCache.length > 0) {
                this.fetchSectionsAndTasksForProject(projectsListCache[0], onSuccess);
            }
            onSuccess();
            return response;
        });
    }

    fetchingForProjectId = null;
    fetchSectionsAndTasksForProject = (project, onResponse) => {
        if (!project.gid) return;
        let projectGid = project.gid;
        let sections = [];
        let tasks = [];
        let fetchingWorkingProjectForCacheKey = this.asanaWorkingProjectLocalCacheKey();

        let fetchSectionsTask = this.fetchSectionsForProject(projectGid).then(result => {
            sections = result.data.data;
            onResponse && onResponse();
            if (!project) { return; }
            project["sections"] = sections || [];
            HWLocalStorage.save(project, fetchingWorkingProjectForCacheKey);
        });

        let fetchTasksForProjectTask = null
				// this.fetchTasksForProject(projectGid).then(result => {
        //     tasks = result.data.data;
        // });

        return Promise.all([fetchSectionsTask, fetchTasksForProjectTask]).then(() => {
            // let project =  this.projectsCache.filter(project_1 => project_1.gid === projectGid)[0];
            if (!project) { return; }
            project["sections"] = sections || [];

            for (let section of project.sections) {
                section["tasks"] = tasks.filter(task => {
                    const memberships = task.memberships;
                    const sectionMembershipOfTask = (memberships.filter(membership => 'section' in membership))[0];
                    const parent = task.parent
                    return sectionMembershipOfTask.section.gid === section.gid && !parent;
                });
            }
            HWLocalStorage.save(project, fetchingWorkingProjectForCacheKey);
            onResponse && onResponse(project);
        });
    }

    // For Task Details PopUp only, Fetch Only Sections Data.
    fetchSectionsForProjectIds = (projectGids) => {
        let projectIdSections = [];
        let fetchSectionsTasks = projectGids.map(projectGid => {
            return this.fetchSectionsForProject(projectGid).then(result => {
                projectIdSections.push({ projectGid: projectGid, sections: result.data.data });
            });
        })
        return Promise.all(fetchSectionsTasks).then((response) => {
            let projects = this.cachedProjectsList() || [];
            if (projects.length === 0) return response;
            projects.map(project => {
                if (projectGids.indexOf(project.gid) !== -1) {
                    let sectionsData = projectIdSections.filter(i => i.projectGid === project.gid)[0];
                    if (sectionsData) { project.sections = sectionsData.sections || []; }
                }
                return project;
            });
            HWLocalStorage.save(projects, this.asanaProjectsLocalCacheKey());
        });
    }

    // Only Fetch
    fetchSectionsForProject = (projectGid) => {
        let aConfig = this.config();   // Shallow copy.
        const url = this.taskAPIBaseURL + "/projects/" + projectGid + "/sections?opt_fields=name";
        return axios.get(url, aConfig);
    }


    //  Only Fetch
    getProjectsList(limit) {
        let aConfig = { ...this.config() };   // Shallow copy.
        aConfig["params"] = { workspace: this.workspaceId };
        if (limit) {
            aConfig["params"].limit = limit;
        }
        const url = this.taskAPIBaseURL + "/projects?opt_fields=name,modified_at";
        return axios.get(url, aConfig);
    }

    fetchUserAndAccountMapping(forDashboard) {
        let url = window.resolveRegion(process.env.REACT_APP_SK_INTEGRATIONS_BASE_API_URL) + "/mapping";
        let aConfig = { ...this.config() };
        aConfig.params = { appId: HWUser.appId(), userId: HWUser.userId(), integrationType: "ASANA", forDashboard: forDashboard, forDemoAccount: HWUser.isDemoAccount() }
        return axios.get(url, aConfig);
    }

    fetchTeamsByWorkspaceID() {
        let aConfig = { ...this.config() };
        return axios.get(this.taskAPIBaseURL + "/organizations/" + this.workspaceId + "/teams", aConfig)
    }


    moveProjectSection(projectId, data) {
        let aConfig = { ...this.config() };
        return axios.post(this.taskAPIBaseURL + "/projects/" + projectId + "/sections/insert?", data, aConfig)
    }

    fetchCustomFields() {
        let aConfig = { ...this.config() };
        return axios.get(this.taskAPIBaseURL + "/workspaces/" + this.workspaceId + "/custom_fields", aConfig)
    }

    fetchCustomFieldsWithId(id) {
        let aConfig = { ...this.config() };
        return axios.get(this.taskAPIBaseURL + "/custom_fields/" + id, aConfig)
    }

    // Local Cache Related APIs
    updateWorkingProjectCache(workingProject) {
        HWLocalStorage.save(workingProject, this.asanaWorkingProjectLocalCacheKey()); // Update Local Cache.
    }

    clearCache() {
        HWLocalStorage.clear(this.asanaWorkingProjectLocalCacheKey());
        HWLocalStorage.clear(this.asanaProjectsLocalCacheKey());
        this.taskAPIBaseURL = "https://app.asana.com/api/1.0";
        this.credentialsLoadStatus = AsanaService.credentialsLoadStatusEnum.notStarted;
    }

    clearAllAsanaCache() {
        let localCacheKey = "sk-asana-";
        HWLocalStorage.clearWithPrefix(localCacheKey);
        this.clearCache();
    }

    clearAllJiraCache() {
        let localCacheKey = "sk-jira-";
        HWLocalStorage.clearWithPrefix(localCacheKey);
    }

    // Credentials Related APIS

    loadAsanaCredentialsFromLocal() {
        try {
            let asanaCredentialsId = "sk-asana-credentials" + HWUser.userId() + HWUser.appId();
            const cachedCredentials = JSON.parse(HWLocalStorage.load(asanaCredentialsId));
            let isValid = Date.parse(cachedCredentials.expiryTime) > (new Date() - (5 * 60000))
            if (!isValid) { HWLocalStorage.clear(asanaCredentialsId); return null; }
            return cachedCredentials;
        } catch (error) { }
    }

    updateTokenToLocalCache(accessToken, expiryTime, asanaWorkspaceId) {
        try {
            let asanaCredentialsId = "sk-asana-credentials" + HWUser.userId() + HWUser.appId();
            const tokenDataString = JSON.stringify({ "accessToken": accessToken, "expiryTime": expiryTime, "asanaWorkspaceId": asanaWorkspaceId });
            HWLocalStorage.save(tokenDataString, asanaCredentialsId);
            // this.credentialsLoadStatus = AsanaService.AsanaService.credentialsLoadStatusEnum.notStarted;
        } catch (error) { }
    }

    loadCredentials() {
        if (HWUser.isDemoAccount()) {
            this.credentialsLoadStatus = AsanaService.credentialsLoadStatusEnum.loaded;
            this.onCredentialsUpdate && this.onCredentialsUpdate();
            return;
        }
        const cachedAccessData = this.loadAsanaCredentialsFromLocal();
        if (cachedAccessData) {
            this.credentialsLoadStatus = AsanaService.credentialsLoadStatusEnum.loaded;
            this.accessToken = cachedAccessData.accessToken;
            this.workspaceId = cachedAccessData.asanaWorkspaceId;
            this.onCredentialsUpdate()
        }
        this.credentialsLoadStatus = AsanaService.credentialsLoadStatusEnum.loading;

        AsanaService.fetchAsanaCredentials().then(response => {
            if (response.data.statusCode === 200) {
                this.accessToken = response.data.accessToken;
                this.workspaceId = response.data.asanaWorkspaceId;
                this.updateTokenToLocalCache(response.data.accessToken, response.data.expiryTime, response.data.asanaWorkspaceId);
                this.credentialsLoadStatus = AsanaService.credentialsLoadStatusEnum.loaded;
            } else { this.credentialsLoadStatus = AsanaService.credentialsLoadStatusEnum.notAvailable; }
            this.onCredentialsUpdate()
        }).catch(error => {
            this.credentialsLoadStatus = AsanaService.credentialsLoadStatusEnum.serverError;
            this.onCredentialsUpdate()
            // console.log(error); 
        });
    }

    static fetchAsanaCredentials() {
        // let skConfig = { headers: { "Authorization": HWUser.accessToken() } };
        // skConfig.params = { "userId": HWUser.userId(), "appId": HWUser.appId(), "integrationType": "ASANA", orgId: HWUser.orgId() }
        // let url = window.resolveRegion(process.env.REACT_APP_SK_INTEGRATIONS_BASE_API_URL) + "/credential";
        // return axios.get(url, skConfig);
        return cachedAPI.getCredentials("ASANA");
    }

    // Task Count APIs
    asanaProjectsCountLocalCacheKey = () => { return "sk-asana-project-counts-" + HWUser.userId(); }

    // Parsed Cache Counts
    fetchCachedCountData = (filterProjectGids) => {
        let cachedCountData = HWLocalStorage.load(this.asanaProjectsCountLocalCacheKey());
        if (!cachedCountData || !filterProjectGids || filterProjectGids.length === 0) { return { totalTasksCount: 0, completedTasksCount: 0, incompleteTasksCount: 0 } }
        let totalTasksCount = filterProjectGids.map(projectGid => (cachedCountData[projectGid] && cachedCountData[projectGid].num_tasks) || 0).reduce((a, b) => a + b);
        let completedTasksCount = filterProjectGids.map(projectGid => (cachedCountData[projectGid] && cachedCountData[projectGid].num_completed_tasks) || 0).reduce((a, b) => a + b);
        let incompleteTasksCount = filterProjectGids.map(projectGid => (cachedCountData[projectGid] && cachedCountData[projectGid].num_incomplete_tasks) || 0).reduce((a, b) => a + b);
        return { totalTasksCount, completedTasksCount, incompleteTasksCount }
    }

    // Update Counts For All Given Project Gids, 
    isFetchingTaskCounts = false;
    updateTaskCountsForProjects = (projectGids, onTaskCountUpdate) => {
        if (this.isFetchingTaskCounts === true) { return Promise.resolve("Already Fetching Tasks Count."); }
        if (!projectGids) return Promise.reject("Invalid Project Gid List");
        // let countsData = HWLocalStorage.load(this.asanaProjectsCountLocalCacheKey()) || {};
        this.isFetchingTaskCounts = true;
        let partitionedGidsList = chunk(projectGids, 4);
        let callForUserId = HWUser.userId();
        const batchUpdateTaskCountsForProjects = serializePromises(this.batchUpdateTaskCountsForProjects);
        return Promise.all(partitionedGidsList.map((gids, index) => batchUpdateTaskCountsForProjects(gids, onTaskCountUpdate, index, callForUserId))).finally(() => { this.isFetchingTaskCounts = false; });
    }

    batchUpdateTaskCountsForProjects = (projectGids, onTaskCountUpdate, index, callForUserId) => {
        // console.log("Fetching For Batch:" + index);
        if (callForUserId !== HWUser.userId()) {
            return Promise.resolve(); // Ignore calls if user logs out or new user logs in, we can't cancel async calls.
        }
        let countsData = HWLocalStorage.load(this.asanaProjectsCountLocalCacheKey()) || {};
        let countFetchTasks = projectGids.map(projectGid => {
            return this.fetchTaskCountForProject(projectGid).then(result => {
                countsData[projectGid] = result.data.data;
                HWLocalStorage.save(countsData, this.asanaProjectsCountLocalCacheKey());
                onTaskCountUpdate && onTaskCountUpdate();
            })
        });
        return Promise.all(countFetchTasks).then(onSuccess => {
            HWLocalStorage.save(countsData, this.asanaProjectsCountLocalCacheKey());
            onTaskCountUpdate && onTaskCountUpdate();
        })
    }

    // Update Single Project Count Data.
    updateTaskCountForProject = (projectGid) => {
        if (!projectGid) return Promise.reject("Invalid Project Gid");
        return this.fetchTaskCountForProject(projectGid).then(result => {
            let cachedCountData = HWLocalStorage.load(this.asanaProjectsCountLocalCacheKey()) || {};
            cachedCountData[projectGid] = result.data.data;
            HWLocalStorage.save(cachedCountData, this.asanaProjectsCountLocalCacheKey());
            return result;
        });
    }

    fetchTaskCountForProject = (projectGid) => {
        let aConfig = this.config();   // Shallow copy.
        aConfig["params"] = { opt_fields: "num_completed_tasks,num_incomplete_tasks,num_tasks" };
        return axios.get(this.taskAPIBaseURL + "/projects/" + projectGid + "/task_counts", aConfig);
        // return Promise.reject(null);
    }
    getAPIAlertCount = () => {
        let date = moment(new Date()).format('YYYY-MM-DD')
        return axios.get(window.resolveRegion(process.env.REACT_APP_SMART_DASHBOARDS_BASE_API_URL) + '/api-validation-report/notification?orgId=' + HWUser.orgId() +'&appId='+HWUser.appId()  +'&toDate=' + date + "&fromDate=" + date + "&userId=" + HWUser.userId());
    }
    getEmailMessage = () => {
        let date = moment(new Date()).format('YYYY-MM-DD')
        return axios.get(window.resolveRegion(process.env.REACT_APP_SMART_DASHBOARDS_BASE_API_URL) + '/api-validation-report/email?orgId=' + HWUser.orgId() + '&toDate=' + date + "&fromDate=" + date + "&userId=" + HWUser.userId());
    }
    getAPIAlertLog = () => {
        let date = moment(new Date()).format('YYYY-MM-DD')
        return axios.get(window.resolveRegion(process.env.REACT_APP_SMART_DASHBOARDS_BASE_API_URL) + '/api-validation-report/export?orgId=' + HWUser.orgId() + '&toDate=' + date + "&fromDate=" + date + "&userId=" + HWUser.userId());
    }
}

var asanaService = new AsanaService();
// Object.freeze(asanaService)

export default asanaService;

// export default AsanaService;