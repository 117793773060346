import axios from "axios";
import hwAxios from "../HWLibrary/HWAxios";
/* import { toast } from "react-toastify"; */
import HWUser from "../HWLibrary/HWUser";

export const apiCall = async ({ url, method, payload ,addFilter}) => {
  try {
        let token = HWUser.accessToken();
        let aConfig = {}
        /* if (token) { */
        //axios.defaults.headers.common["Authorization"] = HWUser.accessToken();
        //axios.defaults.headers.common["Content-Type"] = "application/json";
        if(addFilter){
          aConfig    = { headers: { "addFilter": true,"Authorization": HWUser.accessToken(), "Content-Type": "application/json" } };
        }else{
          aConfig = { headers: { "Authorization": HWUser.accessToken(), "Content-Type": "application/json" } };
        }
        
       // }
       let updatedPayload = {...payload,...aConfig}
       let resp ="";
       if(addFilter){
         resp = await method?.toLowerCase()==='get'?hwAxios[method?.toLowerCase()](url,updatedPayload):hwAxios[method?.toLowerCase()](url,payload,aConfig)
       }else{
         resp = await method?.toLowerCase()==='get'?axios[method?.toLowerCase()](url,updatedPayload):axios[method?.toLowerCase()](url,payload,aConfig)
       }
        return resp;
  } catch (error) {
    if (error.response.status === 401 && error.response.data.message === 'unauthorized') {
      /* toast.error("Token expired"); */
      //localStorage.clear();
      window.location.href = "/login";
    } else if (error.response.status === 403) {
      /* toast.error(error.response.data.message); */
      window.location.href = "/dashboard";
    } else {
      throw error;
    }
  }
};
