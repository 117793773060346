import { skTouchpointActions } from "../constants/actionType"

const initialState = {
	isLoading: false,
	touchpointsData: null,
	touchpointSortList: null,
	touchpointTypeCount: null,
	touchpointActiveView: null
}

export const skTouchpointActionReducer = (state = initialState, { type, payload }) => {
	switch (type) {
		case skTouchpointActions?.SET_SMART_TOUCHPOINT_STATES:{
			return {
				...state, ...payload
			}
		}
		default: return state
	}
}


export default skTouchpointActionReducer 