import React, { useMemo, useState } from 'react';
import Joyride, { STATUS, ACTIONS, EVENTS  } from 'react-joyride';
import { useDispatch } from "react-redux";
import { useNavigate } from 'react-router-dom';
import { setGlobalStates } from '../store/reducers/skGlobalReducer';
import HWUser from '../HWLibrary/HWUser';
import '../SkSmartUtil/SkJoyride/SkJoyride.css'

const WorkspaceExternalUserJoyride = (props) => {
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const [currentTooltip, setCurrentTooltip] = useState(-1);

	const redirectTo = (url) => {
		navigate('/dashboard/' + url);
	}

	const { run, steps } = useMemo(() =>  
	{
	return {
		run: props.run,
		steps: [
			{
				content:
					<div className='joyride__content'>
						<div className='joyride__first__step'>Welcome to SmartPlaybooks</div>
						<br />
						<span style={{ marginLeft: "0px", textAlign:"left" }} className='joyride__first__step__line'>
							Let's help you get on-boarded to the platform quickly !
						</span>
					</div>
				,
				locale: {
					skip: <strong className={"joyride__secondary__btn"} aria-label="skip">SKIP</strong>,
					next: <strong className={"joyride__primary__btn"} aria-label="next"> NEXT <div className='joyride__progress'>1/4</div></strong>,
					back: <strong className={"joyride__secondary__btn"} aria-label="back">BACK</strong>
				},
				placement: 'center',
				target: 'body',
			},
			{
				content: <div className='joyride__content' style={{ textAlign: "left", }}>
					<span style={{paddingLeft:"2rem"}}>Here are 2 quick steps to get going :</span><br /><br />
					<ul style={{paddingRight: "1rem"}}>
						<li style={{textAlign:"left", }}>Start your journey on Playbooks by first checking it out on 'Academy'. This can be accessed through the 'Menu' option on the left.</li>
						<br />
						<li style={{textAlign:"left"}}>A 'Getting Started' Playbook (visible behind this panel) will guide you through your onboarding journey, ensuring your familiarity with the product upon completion of the tasks.</li>
					</ul>
				</div>,
				// floaterProps: {
				// 	disableAnimation: true,
				// },
				// spotlightPadding: 3,
				locale: {
					skip: <strong className={"joyride__secondary__btn"} aria-label="skip">SKIP</strong>,
					next: <strong className={"joyride__primary__btn"} onClick={e => redirectTo('workspace/timeline')} aria-label="next"> NEXT <div className='joyride__progress'>2/4</div></strong>,
					back: <strong className={"joyride__secondary__btn"} aria-label="back">BACK</strong>
				},
				target: 'body',
				placement: 'center',
			},
			{
				content: (
					<div className='joyride__content' style={{textAlign:"left",  paddingLeft:"10px", paddingRight:"10px"}}>The Tasks Tab gives you a comprehensive list of all currently Incomplete Tasks you should be viewing.<br/><br/> There are several views and filters for your ease of use, and all tasks come with comprehensive notification systems.</div>
				),
				placement: 'bottom',
				target: '#timeline',
				spotlightPadding: 1,
				locale: {
					skip: <strong className={"joyride__secondary__btn"} aria-label="skip">SKIP</strong>,
					next: <strong className={"joyride__primary__btn"} onClick={e => redirectTo('workspace/getting_started')} aria-label="next"> NEXT <div className='joyride__progress'>3/4</div></strong>,
					back: <strong className={"joyride__secondary__btn"} onClick={e => redirectTo('workspace/getting_started')} aria-label="back">BACK</strong>
				},
			},
			{
				content: (
					<div className='joyride__content' style={{textAlign:"left",  paddingLeft:"10px", paddingRight:"10px"}}>And Finally, reminding you again about the “Getting Started” Playbook (Triggered automatically as you login), visible behind this panel. <br /><br /> Please take this Playbook to closure by completing each brief task on it quickly. We wish you find it easy to close out your first Playbook! </div>
				),
				placement: 'center',
				target: 'body',
				spotlightPadding: 3,
				locale: {
					back: <strong className={"joyride__secondary__btn"} onClick={e => redirectTo('workspace/timeline')} aria-label="skip">BACK</strong>,
					last: <strong className={"joyride__primary__btn"} aria-label="next">CLOSE</strong>

					// skip: <strong className={"joyride__secondary__btn"} aria-label="skip">SKIP</strong>,
					// next: <strong onClick={e => redirectTo('workspace/playbook/playbooks_Summary')} className={"joyride__primary__btn"} aria-label="next"> NEXT <div className='joyride__progress'>4/7</div></strong>,
					// back: <strong className={"joyride__secondary__btn"} aria-label="back">BACK</strong>
				},
			}
			// {
			// 	content: (<div className='joyride__content' style={{textAlign:"left", paddingLeft:"10px", paddingRight:"10px"}}>{ HWUser.permissionLevelAdminOrLeader() ?  'Dashboard & Summary gives you status of current Playbooks. For Managers and Admins - Triggers let you configure conditions for Playbooks to become live, and Templates let you define the checklist / process for each Playbook.' : 'Summary gives you status of current Playbooks. For Managers and Admins - Triggers let you configure conditions for Playbooks to become live, and Templates let you define the checklist / process for each Playbook.'}</div>),
			// 	placement: 'bottom-start',
		
			// 	spotlightPadding: 0,
			// 	locale: {
			// 		skip: <strong className={"joyride__secondary__btn"} aria-label="skip">SKIP</strong>,
			// 		next: <strong onClick={e => redirectTo('workspace/timeline')} className={"joyride__primary__btn"} aria-label="next"> NEXT <div className='joyride__progress'>5/7</div></strong>,
			// 		back: <strong className={"joyride__secondary__btn"} aria-label="back">BACK</strong>
			// 	},
			// 	target: '.sk_tab_filter_and_condition_box_left_element',
			// },
			// {
			// 	content: (
			// 		<div className='joyride__content' style={{textAlign:"left",  paddingLeft:"10px", paddingRight:"10px"}}>The Tasks Tab gives you a comprehensive list of all currently Incomplete Tasks you should be viewing (Self and others).<br /> There are several views and filters for your ease of use, and all tasks come with comprehensive notification systems.</div>
			// 	),
			// 	placement: 'top',
			// 	target: `.sk_tab_filter_tab_title#timeline`,
			// 	spotlightPadding: 3,
			// 	locale: {
			// 		skip: <strong className={"joyride__secondary__btn"} aria-label="skip">SKIP</strong>,
			// 		next: <strong onClick={e => redirectTo('workspace/getting_started')} className={"joyride__primary__btn"} aria-label="next"> NEXT <div className='joyride__progress'>6/7</div></strong>,
			// 		back: <strong onClick={e => redirectTo('workspace/playbook/playbooks_Summary')} className={"joyride__secondary__btn"} aria-label="back">BACK</strong>
			// 	},
			// },
			// {
			// 	content: (
			// 		<div className='joyride__content' style={{textAlign:"left",  paddingLeft:"10px", paddingRight:"10px"}}>And Finally, reminding you again about the “Getting Started” Playbook (Triggered automatically as you login), visible behind this panel. <br /><br /> Please take this Playbook to closure by completing each brief task on it quickly. We wish you find it easy to close out your first Playbook! </div>
			// 	),
			// 	placement: 'center',
			// 	spotlightPadding: 3,
			// 	locale: {
			// 		back: <strong className={"joyride__secondary__btn"} aria-label="skip">BACK</strong>,
			// 		last: <strong className={"joyride__primary__btn"} aria-label="next">CLOSE</strong>
			// 	},
			// 	target: `body`,
			// }
		],
	}}, []);

	const handleJoyrideCallback = (data) => {
		const { action, index, status, type } = data;
		const finishedStatuses = [STATUS.FINISHED, STATUS.SKIPPED];

		if ([EVENTS.STEP_AFTER, EVENTS.TARGET_NOT_FOUND].includes(type)) {
			if([EVENTS.TARGET_NOT_FOUND].includes(type) && index === 4){
				return;
			}
			setCurrentTooltip(index + (action === ACTIONS.PREV ? -1 : 1));
    } else if ([STATUS.FINISHED, STATUS.SKIPPED].includes(status)) {
      dispatch(setGlobalStates({ showExternalUserWorkspaceJoyride: false }));
    }

	};

	return (
		<div>
			<Joyride
				callback={handleJoyrideCallback}
				continuous
				hideCloseButton
				run={run}
				stepIndex={currentTooltip}
				disableCloseOnEsc={true}
				disableOverlayClose={true}
				scrollToFirstStep
				showProgress={false}
				showSkipButton
				steps={steps}
				styles={{
					options: {
						zIndex: 900,
						top: '0px',
						primaryColor: 'transparent',
						textColor: 'var(--sk-heading-color)'
					},
				}}
			/>
		</div>
	);
}

export default WorkspaceExternalUserJoyride