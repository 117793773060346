import * as React from 'react';
import "./SKSmartUiBtns.css"
import SkCheckBoxBtn from '../SkCheckBoxBtn/SkCheckBoxBtn';

function SKSmartUiBtns({ secondaryBtnAction, primaryBtnLabel, primaryBtnAction, isEdit, secondaryBtnLabel, primaryBtnOnEdit,
  secondaryBtnAction2, secondaryBtnLabel2, ...props }) {
  return (
    <>
      <div className={`${props?.className} sk-smart-uiBtn_wrap`}>
        {props.leftBtnLabel ?
          <>
            <div className={`${props?.skBtnContainerClassName} w-100  d-flex  justify-content-between`} >
              <div className='d-flex align-items-center'>
                <div>
                  {props.leftBtnLabel &&
                    <>
                      {props?.leftBtnCheckBoxBtn?<>
                        <SkCheckBoxBtn tooltipLable={props.leftBtnCheckBoxBtnonMousehoverTooltipText} onMouseOverLable={props.leftBtnCheckBoxBtnonMousehoverText} poperClassName={props.letBtnPoperClassName}  keyName={props.leftBtnkey} key={props.leftBtnKeyId} label={props.leftBtnLabel} isChecked={props.leftBtnValue} onChange={props.leftBtnOnChange} className={props.leftBtnClassName} value={props.leftBtnValue} showCustomOption={props.leftBtnCustomMenuOptions?true:null} customMenuOptions={props.leftBtnCustomMenuOptions}  isDisabled={props.leftBtnIsDisabled} />
                      </>:
                      <input className={`skSecondaryBtn  ${props?.disabledleftBtnLabel && 'btnDisabled'}`}  disabled={props?.disabledleftBtnLabel} type="button" id="see_result1" onClick={(e) => { e.stopPropagation(); e.preventDefault(); props.leftBtnAction(e, props) }} value={props.leftBtnLabel} />}
                    </> 
                }
                </div>
								{props.leftBtnSubLabel &&<div onClick={props.leftBtnSubLabelOnClick} className={props.leftBtnSubLabelCalssname}>{props.leftBtnSubLabel}</div>}
              </div>
              <div className='d-flex'>
                <div>{secondaryBtnLabel && <input className={`skSecondaryBtn ${props?.disabledSecondaryBtnLabel && 'btnDisabled'}`} style={{ marginRight: !primaryBtnLabel && '0px' }} disabled={props?.disabledSecondaryBtnLabel} type="button" id="see_result1" onClick={(e) => { e.stopPropagation(); e.preventDefault(); secondaryBtnAction(e, props) }} value={secondaryBtnLabel} />}</div>
                <div>{secondaryBtnLabel2 && <input className={`skSecondaryBtn ${props?.disabledSecondaryBtnLabel2 && 'btnDisabled'}`} disabled={props?.disabledSecondaryBtnLabel2} type="button" id="see_result1" onClick={(e) => { e.stopPropagation(); e.preventDefault(); secondaryBtnAction2(e, props) }} value={secondaryBtnLabel2} />}</div>
                <div>{primaryBtnLabel && <input className={`skPrimaryBtn ${props?.disabledPrimaryBtnLabel && 'btnDisabled'}`} disabled={props?.disabledPrimaryBtnLabel} type="button" id="see_result1" onClick={(e) => { e.stopPropagation(); e.preventDefault(); primaryBtnAction(e, props) }} value={!isEdit ? primaryBtnLabel : primaryBtnOnEdit} />}</div>
              </div>
          </div>
          </> :
          <div className={`${props?.skBtnContainerClassName} w-100  d-flex  justify-content-end`} >
            <div>{secondaryBtnLabel && <input className={`skSecondaryBtn ${props?.disabledSecondaryBtnLabel && 'btnDisabled'}`} style={{ marginRight: !primaryBtnLabel && '0px' }} disabled={props?.disabledSecondaryBtnLabel} type="button" id="see_result1" onClick={(e) => { e.stopPropagation(); e.preventDefault(); secondaryBtnAction(e, props) }} value={secondaryBtnLabel} />}</div>
            <div>{secondaryBtnLabel2 && <input className={`skSecondaryBtn ${props?.disabledSecondaryBtnLabel2 && 'btnDisabled'}`} disabled={props?.disabledSecondaryBtnLabel2} type="button" id="see_result1" onClick={(e) => { e.stopPropagation(); e.preventDefault(); secondaryBtnAction2(e, props) }} value={secondaryBtnLabel2} />}</div>
            <div>{primaryBtnLabel && <input className={`skPrimaryBtn ${props?.disabledPrimaryBtnLabel && 'btnDisabled'}`} disabled={props?.disabledPrimaryBtnLabel} type="button" id="see_result1" onClick={(e) => { e.stopPropagation(); e.preventDefault(); primaryBtnAction(e, props) }} value={!isEdit ? primaryBtnLabel : primaryBtnOnEdit} />}</div>
          </div>
        }
      </div>
    </>
  )
}
export default SKSmartUiBtns
