import React from "react";
import {Tooltip}  from '@material-ui/core';

const Image = (props) => {
  return (
    <Tooltip title={props.tooltipText?props.tooltipText:""}> 
    <img className={props.className} onClick={props.onClick} style={props.style} src={props.src}  alt={props.alt} data-type = {props.dataType}/>
    </Tooltip>
  );
};

export default Image;