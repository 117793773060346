import  React,{ lazy } from "react";
import access from "../HWLibrary/AccessControl";
import HWUser from "../HWLibrary/HWUser";
// import SuccessIntelligenceDashboard from "../Intelligence/Success Intelligence/SuccessIntelligenceDashboard";
// import KommunityDetailPage from "../KaffeineKarrots/KommunityDetailPage";
// import KeyGrowthIndicator from "../KeyGrowthIndicator/KeyGrowthIndicator";
// import ProductSuccessConsole from "../ProductSuccessConsole/ProductSuccessConsole";
// import Portfolio from "../SkPortfolio/Portfolio";
import Workspace from "../SkWorkSpaceModule/WorkSpace";
// import SmartInsight from "../SmartInsight/SmartInsight";
// import SmartKonversations from "../SmartKonversations/SmartKonversations";
import { getGlobalStates } from "../store/reducers/skGlobalReducer";


// Lazy imports
const SuccessIntelligenceDashboard = React.lazy(() => import("../Intelligence/Success Intelligence/SuccessIntelligenceDashboard"));
const SmartInsight = React.lazy(() => import("../SmartInsight/SmartInsight"));
const SmartKonversations = React.lazy(() => import("../SmartKonversations/SmartKonversations"));
const ProductSuccessConsole = React.lazy(() => import("../ProductSuccessConsole/ProductSuccessConsole"));
const Portfolio = React.lazy(() => import("../SkPortfolio/Portfolio"));
// const Workspace = React.lazy(() => import("../SkWorkSpaceModule/WorkSpace"));
const KeyGrowthIndicator = React.lazy(() => import("../KeyGrowthIndicator/KeyGrowthIndicator"));

const SideBarRoutes = (CustomerPortalUser, open) => {
	return [
		{
			name: "Key Outcomes",
			path: "/dashboard/csm-dashboard",
			component: <KeyGrowthIndicator />,
			isEnabled: access?.smartKeyOutcomes?.isEnable(),
			className: "indicator",
			isClickable: access?.smartKeyOutcomes?.view(),
			upgrade: false,
			proFeature: false
		},
		{
			name: "My Portfolio",
			path: "/dashboard/portfolio",
			component: <Portfolio />,
			isEnabled: access?.contacts?.isEnable() || access?.notes?.isEnable() || access?.myAccounts?.isEnable() || access?.account360?.isEnable(),
			className: "portfolio",
			isClickable: access?.contacts?.list() || access?.notes?.list() || access?.myAccounts?.list() || access?.account360?.dashboard(),
			upgrade: false,
			proFeature: false
		},
		{
			name: "My Workspace",
			path: "/dashboard/workspace",
			component: <Workspace />,
			isEnabled: access?.playbook?.isEnable() || access?.smartTask?.isEnable() || access?.smartTouchpoint?.isEnable(),
			className: "workspace",
			isClickable: (access?.playbook?.dashboard() || access?.playbook?.summary() || access?.playbook?.rule() || access?.playbook?.template() || access?.smartTask?.list() || access?.smartTouchpoint?.viewTab()),
			upgrade: false,
			proFeature: false
		},
		{
			name: HWUser.orgType() === "playbookStandalone" ? "Notifications" : 'SmartOps',
			path: "/dashboard/success-intelligence",
			component: <SuccessIntelligenceDashboard></SuccessIntelligenceDashboard>,
			isEnabled: /* access?.productOrService?.isPermitted() && */ access?.smartOps?.isEnable(),
			className: "smart_operation",
			isClickable: access?.smartOps?.view(),
			upgrade: false,
			proFeature: false
		},
		{
			name: "SmartInsights",
			path: "/dashboard/smart-insight",
			component: <SmartInsight />,
			isEnabled: HWUser?.checkproFeatureStatus("SmartInsights") !== "Hide" || false,
			className: "smart_insight",
			// isClickable: true,
			isClickable: HWUser?.checkproFeatureStatus("SmartInsights") === "Enable" || false,
			upgrade: false,
			// proFeature: true && HWUser?.checkproFeatureStatus("SmartInsights") === "Disable",
			proFeature: true,
			tooltipText: "An embedded analytics tool that makes all datasets and a Power BI interface available to you for self-designing reports instantly (and with a visual interface). And also schedule these reports for delivery, as required. Contact new@smartkarrot.io for more details.",
			hideTooltipText: HWUser?.checkproFeatureStatus("SmartInsights") === "Enable"
		},
		{
			name: "SmartKonversations",
			path: HWUser.isPremiumFeatureEnable() ? HWUser.isZoomIntegratedForThisUser() ? "/dashboard/smart-konversations" : '/dashboard/zoomAuthentication' : '/dashboard/smart-konversation',
			component: <SmartKonversations />,
			isEnabled: HWUser?.checkproFeatureStatus("SmartKonversation") !== "Hide" || false,
			className: "konversation",
			isClickable: HWUser?.checkproFeatureStatus("SmartKonversation") === "Enable" || false,
			upgrade: !(HWUser.premiumFeature()?.SmartKonversation === "Enable") && !CustomerPortalUser,
			// proFeature: true && HWUser?.checkproFeatureStatus("SmartKonversation") === "Disable",
			proFeature: true,
			tooltipText: "AI tool to create actionable insights from client calls (Action Items, Meeting Notes, Call Metrics, etc.) Use this to boost customer satisfaction, operational efficiency, and drive business growth. Contact new@smartkarrot.io for more details.",
			hideTooltipText: HWUser?.checkproFeatureStatus("SmartKonversation") === "Enable"

		},

		{
			name: "Product Analytics",
			path: "/dashboard/product-success-console",
			component: <ProductSuccessConsole></ProductSuccessConsole>,
			isEnabled: HWUser?.checkproFeatureStatus("ProductAnalytics") !== "Hide" || false,
			className: 'product_analytics',
			isClickable: HWUser?.checkproFeatureStatus("ProductAnalytics") === "Enable" || false,
			upgrade: false,
			// proFeature: true && HWUser?.checkproFeatureStatus("ProductAnalytics") === "Disable",
			proFeature: true,
			tooltipText: "Light Analytics Tool to provide product usage insights by feature. Relevant for product companies only. Contact new@smartkarrot.io for more details.",
			hideTooltipText: HWUser?.checkproFeatureStatus("ProductAnalytics") === "Enable"
		},

		// {
		// 	name: "Kommunity",
		// 	path: "/dashboard/kommunity",
		// 	component: <KommunityDetailPage />,
		// 	disabled: false,
		// 	isEnabled: false,
		// 	// isEnabled: access?.kommunity?.isEnable(),
		// 	className: "kommunity",
		// 	isClickable: access?.kommunity?.view(),
		// 	upgrade: !access?.kommunity?.view() && !CustomerPortalUser,
		// 	proFeature: false
		// },

		{
			name: "SmartAssist",
			path: "/dashboard/smart-assist",
			description: "New Premium Feature",
			isEnabled: HWUser?.checkproFeatureStatus("SmartAssist") !== "Hide" || false,
			className: "smart_assist",
			isClickable: HWUser?.checkproFeatureStatus("SmartAssist") === "Enable" || false,
			upgrade: false,
			// proFeature: true && HWUser?.checkproFeatureStatus("SmartAssist") === "Disable",
			proFeature: true,
			tooltipText: "(Customer Success / Key Account Management) GPT that has access to your portfolio and can chat incredibly intelligently as your virtual assistant. Contact new@smartkarrot.io for more details.",
			hideTooltipText: HWUser?.checkproFeatureStatus("SmartAssist") === "Enable"
		},
		{
			name: "Academy",
			isEnabled: HWUser.orgType() === "playbookStandalone",
			className: "academy_sidebar_icon",
			isClickable: true,
			upgrade: false,
			proFeature: false
		},

		{
			name: "Welcome to SmartPlaybooks",
			isEnabled: HWUser.orgType() === "playbookStandalone" && getGlobalStates().noOfLogin > 0 && getGlobalStates().noOfLogin <= 5 && !open,
			className: "sidenav__smart__playbooks__welcome__icon",
			isClickable: true,
			upgrade: false,
			proFeature: false
		},
	]
}
export default SideBarRoutes;