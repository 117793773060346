import { skUserPermissionActions } from "../constants/actionType";

const initialState = {
	users: [],
	isLoading: false,
	nextToken: null,
	showSkeleton: true,
	roles: [],
	isAddLoading: false,
	isAddingRole: false,
	isAccountLoading:false,
	accountList: [],
	accountListNextToken:null,
}


export const skUserPermissionActionReducer = (state = initialState, { type, payload } = {}) => {
	switch (type) {
    case skUserPermissionActions.FETCH_USERS_LIST:
    case skUserPermissionActions.ADD_USER:
		case skUserPermissionActions.EDIT_USER: 
		case skUserPermissionActions.FETCH_SINGLE_USER: 
		case skUserPermissionActions.DELETE_USER:
		case skUserPermissionActions.SET_USER_LOADING:
		case skUserPermissionActions.SET_SMART_STATE:
		case skUserPermissionActions.DELETE_ALLOCATION:
		case skUserPermissionActions.SET_IS_LOADING:
		case skUserPermissionActions.UPDATE_ALLOCATIONT:
		case skUserPermissionActions.FETCH_ACCOUNT:
		case skUserPermissionActions.SET_IS_ERROR: {return { ...state, ...payload }}
    default: return state;
  }
}


export default skUserPermissionActionReducer
