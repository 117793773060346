import React, {useState } from 'react';
import './LandingPage.css';
import Image from '../../SkSmartUtil/SkImage/SkImage';
import './LandingPage.css';
import smart_konversation from '../../assets/images/premiumFeature/smart-konversation-main.svg';
import smart_konversation2 from '../../assets/images/premiumFeature/image-smart-knv-2.png';
import HWPopMsg from '../../HWLibrary/HWPopMsg';
import intelligenceAPIService from '../../Intelligence/IntelligenceAPIService';
import checkIcon from "../../assets/images/checkmark-green-circle.svg";
import { Modal, ModalBody } from 'react-bootstrap';
import ModalHeader from "react-bootstrap/ModalHeader";
import closeImage from '../../assets/images/close.svg';
import ReactPlayer from "react-player";

function LandingPage(props) {
    const [showSuccessMsg, setShowSuccessMsg] = useState('');
    const [fullScreen, setFullScreen] = useState(false);

    const msg = "Processing your request..."
    const smartKonversationPdf = "https://stage-web-profile-images.s3.amazonaws.com/SmartKonversations+Installation+Guide.pdf"

    const sendMail = () => {
        setShowSuccessMsg(msg);
        intelligenceAPIService.sendMail("SmartKonversation").then(response => {
            setShowSuccessMsg(response.data);
        }).catch(() => {
            setShowSuccessMsg("Something went wrong")
        })
    }


    const downloadSmartKoversationPdf = () => {
        fetch(smartKonversationPdf).then(response => {
            response.blob().then(blob => {
                let pdfProcessMsg = "Downloading your SmartKonversations Pdf..."
                let pdfSuccessMsg = "SmartKonversations PdF Downloaded Successfully..."
                setShowSuccessMsg(pdfProcessMsg);
                // Creating new object of PDF file
                const fileURL = window.URL.createObjectURL(blob);
                // Setting various property values
                let alink = document.createElement('a');
                alink.href = fileURL;
                alink.download = "SmartKonversation.pdf";
                alink.click();
                var delayInMilliseconds = 4000;
                setTimeout(() => {
                    setShowSuccessMsg(pdfSuccessMsg);
                }, delayInMilliseconds)
            })
        }).catch(e => {
            let pdfErrorMsg = "Something went wrong while downloading the SmartKonversations Pdf.."
            setShowSuccessMsg(pdfErrorMsg)

        })
    }


    return (
        <>
            <div className="smart__konversation__landing__page main-content-container container-fluid">
                {showSuccessMsg && <HWPopMsg closeTime={8000} className='successMsg tuchpointIndexPop' close={() => setShowSuccessMsg('')} hide={() => setShowSuccessMsg('')} message={showSuccessMsg} />}
                <div className="smart-knvrsat-sectiom">
                    <div className="smart-knvrsat-inner">
                        <div className="row justify-content-center align-items-start mb-5">
                            <div className="col-md-6 col-12 col-sm-12 ">
                                <div className="smart-knvrsat-topleft">
                                    <div className="smart-knvrsat-head">
                                        <h2 className="popup_smart_konver">SmartKonversations</h2>
                                        <p>Your call intelligence capability</p>
                                    </div>
                                    <div className="smart-knvrsat-desc">
                                        <h4>Improve online customer interactions with SmartKarrot's Conversation Intelligence capability that:</h4>
                                        <ul>
                                            <li>highlights 'calls of interest' using sentiment and phrase analysis</li>
                                            <li>provides real time call assists</li>
                                            <li>performs keyword analysis in customer calls</li>
                                        </ul>
                                    </div>
                                    <div className="smart-knvrsat-left-btm">
                                        <a onClick={(e) => setFullScreen(true)} style={{ marginRight: "10px" }} className="anchor_btn save_apply">Watch Video</a>
                                        <a style={{ marginRight: "10px" }} onClick={(e) => { sendMail() }} className="anchor_btn save_apply">I am interested!</a>
                                        <a onClick={(e) => { downloadSmartKoversationPdf(); }}
                                            role="button" className="anchor_btn save_apply" >
                                            Download PDF
                                        </a>
                                    </div>

                                </div>
                            </div>
                            <div className="col-md-6 col-12 col-sm-12 ">
                                <div className="smart-knvrsat-topright text-center">
                                    <img src={smart_konversation} alt="" title="" />
                                </div>
                            </div>
                        </div>

                        <div className="row justify-content-center align-items-center pt-5 mb-5">
                            <div className="col-md-7 col-12 col-sm-12 ">
                                <div className="smart-knvrsat-middle-left text-center">
                                    <img src={smart_konversation2} alt="" title="" />
                                </div>
                            </div>
                            <div className="col-md-5 col-12 col-sm-12 ">
                                <div className="smart-knvrsat-middle-right">
                                    <div className="smart-knvrsat-head">
                                        <h2>What does it include?</h2>
                                        <p>With SmartKonversations you will be able to:</p>
                                    </div>
                                    <div className="smart-knvrsat-desc">
                                        <ul>
                                            <li><div> <Image className="SmartKonCheckImage" src={checkIcon} /></div><div>Track and manage all your customer calls</div> </li>
                                            <li><div> <Image className="SmartKonCheckImage" src={checkIcon} /></div><div>Obtain necessary call analysis</div> </li>
                                            <li><div> <Image className="SmartKonCheckImage" src={checkIcon} /></div><div>Get an intimation if there are calls of interest</div> </li>
                                            <li><div> <Image className="SmartKonCheckImage" src={checkIcon} /></div><div>Collaborate and discuss as a team</div> </li>
                                            <li><div> <Image className="SmartKonCheckImage" src={checkIcon} /></div><div>Provide/ obtain coaching</div> </li>
                                            <li>
                                                <div> <Image style={{ height: "18px", width: "18px", margin: "6px" }} src={checkIcon} /></div>
                                                <div>CS Leaders can analyze their teams calls and performance</div>
                                            </li>
                                        </ul>
                                    </div>

                                </div>
                            </div>

                        </div>

                        <div className="row mb-5">
                            <div className="smart-knvrsat-bottom-sec w-100 d-flex justify-content-between align-items-center">
                                <div className="smart-knvrsat-bottom-left">
                                    <div className="smart-knvrsat-bottom-left-text">
                                        <h2>Ask your CSM about SmartKonversations! </h2>
                                    </div>
                                </div>
                                <div>
                                    <div className="smart-knvrsat-bottom-btn">
                                        <a onClick={(e) => { sendMail() }} className="anchor_btn save_apply">I am interested!</a>
                                    </div>

                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
            {fullScreen && <FullscreenVideo setFullScreen={setFullScreen} />}
        </>
    );
}


const FullscreenVideo = (props) => {

    let url = "https://youtu.be/h1MiQOiLUPw";

    return (
        <Modal centered="true" show backdropClassName="sk-modal-backdrop" style={{ borderRadius: '0px', overflow: 'hidden', maxWidth: 'auto', }} dialogClassName={`disable-modal-content sk-task-popup`}>
            <ModalHeader className="sk-modal-header" style={{ borderBottom: 'none', background: 'white' }}>
                <h6 className="modal-title"></h6>
                <img className="close_btn_pop" style={{ cursor: 'pointer' }} src={closeImage} data-dismiss="modal" alt="" onClick={() => props.setFullScreen()} />
            </ModalHeader>
            <ModalBody className="sk-modal">
                <div style={{ color: 'black' }} >
                    <ReactPlayer url={url} width={"100%"} height={"400px"} controls={true} playing={true} muted={true} loop={true} />
                </div>
            </ModalBody>
        </Modal>
    );
}

export default LandingPage;