import { skAlertActions } from "../constants/actionType";

const initialState = {
	notificationsByTypeData: null,
	notificationsByCategory: null,
	notificationsByTime: null,
	notificationsByClickRateData: null,
	alertSummaryData: null,
	isAlertSummaryLoading: false,
	isAlertDashboardLoading: false,
	alertSummaryToken: null,
	alertSummaryExpandedIds: []
}


export const skAlertActionReducer = (state = initialState, { type, payload }) => {
	switch (type) {
		case skAlertActions.FETCH_ALERT_SUMMARY_DATA:
		case skAlertActions.FETCH_ALERT_DASHBOARD_DATA:
		case skAlertActions.UPDATE_ALERT_CLICK_DATA:
		case skAlertActions.SET_SMART_ALERT_STATES: { return { ...state, ...payload } }
		default: return state;
	}
};


export default skAlertActionReducer;
