import { skKonversationActions } from "../constants/actionType";

const initialState = {
	list: [],
	transcripts: [],
	videoUrl: '',
	currentSeekTime: '',
	nextToken: null,
	isApiLoading: false,
	isLoading: true,
	teamMetricsSkeleton:true,
	errorPopupMsg: "",
	successMsg: "",
	isTransApiLoading: false,
	isZoomIntPullover: false,
	summary: '',
	actionItem: "",
	activeTab: '',
	myKonLoader: false,
	teamMetricsList:[],
	teamKonLoader: false,
	teamMetricsNextToken:null,
	referenceData: null,
	teamMetricsLoading:false,
	userForSummary: (window.localStorage.getItem("platform-currentUser")?.user?.userRole === "Admin" || window.localStorage.getItem("platform-currentUser")?.user?.userRole === "CSMLeader")?null:{userId: window.localStorage.getItem("platform-currentUser")?.user?.userId, userName: window.localStorage.getItem("platform-currentUser")?.user?.name},
	teamKonversationVisible: window.localStorage.getItem("teamKonversationVisible"),
	SmartKonDashboardVisible:window.localStorage.getItem("SmartKonDashboardVisible"),
	manageKeywordReferenceData: null,
	redAlertKeywordData: null,
	amberAlertKeywordData: null,
	isManageKeywordRedAlertKeywordLoader: false,
	isManageKeywordAmberAlertKeywordLoader: false,
	manageKeywordOptions: [
		{id:1, label: 'Red Alert', value: 'red_alert'},
		{id:2, label: 'Amber Alert', value: 'amber_alert'}
	],
	callSentimentSkeleton: true,
	coachingScoreSkeleton: true,
	longestMonologueSkeleton: true,
	talkRatioSkeleton: true
};



export const skKonversationActionReducer = (state = initialState, { type, payload } = {}) => {

	switch (type) {
		case skKonversationActions.FETCH_KONVERSATIONS_LIST:
		case skKonversationActions.FETCH_TRANSCRIPTS:
		case skKonversationActions.CALL_SUMMARY:
		case skKonversationActions.SET_KONVERSATIONS_LOADING:
		case skKonversationActions.SET_SMARTK_STATE:
		case skKonversationActions.EDIT_COACHING:
		case skKonversationActions.FETCH_COMMENTS:
		case skKonversationActions.SET_RATING:
		case skKonversationActions.ZOOM_INTIGRATION_PULLOVER:
		case skKonversationActions.FETCH_REFERENCE_DATA:
		case skKonversationActions.FETCH_MANAGE_KEYWORD_REFERENCE_DATA:
		case skKonversationActions.DELETE_KEYWORD_DATA:
		case skKonversationActions.UPDATE_KEYWORD_DATA:
		case skKonversationActions.FETCH_RISK_RATING: { return { ...state, ...payload } }
		default: return state;
	}
};

export default skKonversationActionReducer