import React, { useState, useEffect, useMemo, useCallback } from 'react'
import { ClickAwayListener } from '@material-ui/core';
import Popper from '@material-ui/core/Popper';
import '../SKUtil.css';
import '../SkCheckBox/SkCheckBox.css';
import AutoUiLabel from "../../SkSmartUtil/UiLabel/AutoUiLabel";
import SKSmartUiBtns from "../SKSmartUiBtns/SKSmartUiBtns";
import SkRadioBtn from "../SkRadioBtn/SkRadioBtn";
import UiLabel from '../../SkSmartUtil/UiLabel/UiLabel';
import { cloneDeep } from 'lodash';
import SkLoaderComponent from '../SkLoader/SkLoaderComponent';
import {  useSelector } from 'react-redux';
import { getGlobalStates } from '../../store/reducers/skGlobalReducer';
import SkSmartMultiSelectDropDown from '../SkSmartMultiSelectDropDown/SkSmartMultiSelectDropDown';


const MultiSeclectDropdown = (props) => {
	const [searchText, setSearchText] = useState("");
	const [referenceList, setreferenceList] = useState(props.referenceList)
	const [selectOption, setSelectedOption] = useState(props.selectOption);
	const [anchorElTarget, setAnchorElTarget] = useState(props.anchorElTarget);
	const [checkedReferenceList, setCheckReferenceList] = useState(props.checkedReferenceList);
	const [flag ,setFlag] = useState(false)
	const getGlobalState = useSelector(getGlobalStates);
	const { paramRowData } = getGlobalState

	useEffect(()=>{
			setFlag(true)
	}, [])
	
useEffect(()=>{
	if(paramRowData && flag ){
		setFlag(false)
		props.hidePopup && 
		props.hidePopup(checkedReferenceList)
	}
}, [paramRowData])
	
	useEffect(() => {
		const handleScroll = () => {
			props.hidePopup &&	props.hidePopup(checkedReferenceList)
		}
		let elemnt = document.querySelector(".datagrid__table__container .MuiDataGrid-virtualScroller");
		let elemnt1 = document.querySelector(".smart__konversation__table");
		let skDashBoardContainer = document.querySelector(".sk-smart-dashboard-container-content");
		if (skDashBoardContainer) { skDashBoardContainer.addEventListener('scroll', handleScroll) }
		if (elemnt) {  elemnt.addEventListener('scroll', handleScroll)}
		if (elemnt1) {  elemnt1.addEventListener('scroll', handleScroll)}
		window.addEventListener('scroll', handleScroll)
		return () => {
			if (skDashBoardContainer) { skDashBoardContainer.removeEventListener('scroll', handleScroll) }
			if (elemnt) { elemnt.removeEventListener('scroll', handleScroll) }
			if (elemnt1) {  elemnt1.removeEventListener('scroll', handleScroll)}
			window.removeEventListener('scroll', handleScroll)
		}
	}, [])

	useEffect(() => {
		// setDelayNoData(true)
		let  combinedList  = (() => {
			let selectedlist =
				props?.checkedReferenceList &&
				props?.checkedReferenceList?.length > 0 &&
				Array.isArray(props?.checkedReferenceList) && props?.checkedReferenceList?.flatMap((checkedItem) => {
					let array =
						props.referenceList &&
						props.referenceList?.length > 0 &&
						props.referenceList?.filter((referenceListItem) => {
							if (referenceListItem?.id) {
								return referenceListItem?.id === checkedItem;
							} else if (referenceListItem?.value) {
								return referenceListItem?.value === checkedItem;
							}
							
						});
					array && array?.sort((a, b) => (a?.label || '').localeCompare(b?.label || ''));
					return array;
				});
		
			let notSelectedList =
				props?.checkedReferenceList &&
				props?.checkedReferenceList?.length > 0 &&
				props.referenceList &&
				props.referenceList?.length > 0 &&
				props.referenceList
					?.filter(
						(referenceListItem) => {
							if (referenceListItem?.id) {
								return !props.checkedReferenceList.includes(referenceListItem?.id)
							} else if (referenceListItem?.value) {
								return !props.checkedReferenceList.includes(referenceListItem?.value)
							}
						}
							
					)
					.sort((a, b) => (a?.label || '').localeCompare(b?.label || ''));
			
		
			let combinedList =selectedlist && notSelectedList && selectedlist?.concat(notSelectedList);
		
			return combinedList;
	
		
		})();

		if (combinedList && combinedList?.length > 0) {
			if(!props?.dontSort){
				setreferenceList(combinedList)
			} else{
				const all = combinedList.filter(item => item.label === 'All');
			  combinedList = combinedList.filter(item => item.label !== 'All');
				setreferenceList([...all, ...combinedList])
			}
		} else {
			setreferenceList(props.referenceList)
		}

	}, [props.referenceList])



	useEffect(() => {
		setCheckReferenceList(props.checkedReferenceList);
	}, [props.checkedReferenceList]);

	useEffect(() => {
		setAnchorElTarget(props.anchorElTarget)
	}, [props.anchorElTarget])

	useEffect(() => {
		document.getElementById("search")?.focus();
	}, [anchorElTarget])

	const btns = {
		primaryBtnLabel: "APPLY",
		primaryBtnAction: props.onApply,
		className: "globalfilter_appy_btn_container"
	}


	const onClickRadioBtn = (option, value) => {
		props.onChangeKey(option, value,null,checkedReferenceList);
	}

	const customFilter = useMemo(() => {
		let list = cloneDeep(referenceList);
		if (searchText) {
			list = list && list.filter((i) => {
				return (i.label?.toLowerCase()?.includes(searchText?.toLowerCase()))
			})
		}
		// console.log('customFilter', referenceList,searchText );
		return list
	}, [referenceList, searchText])

	const referencelist = useMemo(() => {
		if (props.isCustomFilter) { return customFilter; }

		return referenceList
	}, [customFilter, props.isCustomFilter, referenceList])

	const onSearch = (e) => {
		e?.stopPropagation()
		if(!props.isCustomFilter){ props.onSearch && props.onSearch(e.target.value); }
	}

	// const handleCrossSymbolClick = useCallback((e, data) => {
	// 	e?.stopPropagation()
	// 	let selectedData = referenceList?.find(item => item?.id === data?.id)
	// 	let selectedList = cloneDeep(checkedReferenceList);
	// 	if (selectedList?.includes(data?.id)) {
	// 		selectedList = selectedList?.filter(item => item !== data?.id)
	// 	}
	// 	props.onChangeKey(data?.id, selectedData,null,checkedReferenceList);
	// }, [checkedReferenceList, props, referenceList])
	
	const onChangeOfCheckBox = useCallback((e, value) => {
				props.onChangeKey(e, value,null,checkedReferenceList);
	}, [checkedReferenceList])
	


	return (
		<div>
			<ClickAwayListener onClickAway={() => { 
							props?.hidePopup &&	props?.hidePopup(checkedReferenceList) 
				}} mouseEvent="onMouseDown" touchEvent="onTouchStart" >
				<Popper
					id={props?.id}
					className={props.className + " " + "multiSeclectDropdown_overlay"}
					open={true} anchorEl={anchorElTarget}
					placement={props?.placement}
					disablePortal={props?.disablePortal}
					container={props.container}
					onClose={() => { 
						props?.hidePopup &&	props?.hidePopup(checkedReferenceList) 
					}}
					style={{...props.style}}
					PaperProps={{style:{"color":"#000"}}}
				>
					<div className="multiSelectDropdown">
						<SkLoaderComponent isLoading={props?.isLoading}/>
						<div className="filter-dropdown-search-box-container multi__select__dropdown__scroll__container" onClick={(e)=> {e.preventDefault(); e.stopPropagation()}}>
								{props.showSearch && 
								<div className='filter-dropdown-search-box_contaciner'><div className="filter-dropdown-search-box">
									<input autoComplete='off' placeholder="Search..." className="filter-dropdown-search-box-input" onChange={(e) => {
										e?.stopPropagation()
										onSearch(e); setSearchText(e.target.value);
									}}
										onClick={(e) => { e?.stopPropagation(); e?.preventDefault() }}
										id={"search"+props?.searchId}
										key={21863786 + props?.searchId}
										name="search_new" />
								</div></div>}
								{referencelist && referencelist.length > 0 ? <div className="filter-dropdown-search-box-options">
									<div className="filter-dropdown-search-box-options-container">
										{referencelist.map(value => {
											const selected = value.isEnabled ? value.isEnabled : checkedReferenceList?.includes(value.value) ? true : false
						
											return (
												<div key={value?.value || value?.id} onClick={(e) => {
													e?.stopPropagation();
													e?.preventDefault();
													props?.stopClickPropagation && e?.preventDefault();
													if (selectOption === "singleSelect") {
														onClickRadioBtn(e, value)
													}
													else {
														!props?.disableOnChangeOfCheckBox && onChangeOfCheckBox(e, value) 
													}
												}} className={((selected) ? "filter-attribute-dropdown-option-selected" : "filter-attribute-dropdown-option")} >
													{selectOption === "multiSelect" &&
														<>

														<SmartCheckBox key={"SmartCheckBox_" + value?.value || value?.id} value={value?.value || value?.id} disabled={value?.cantEnable} label={value?.label} dropDownType={props?.dropDownType} accountType={value?.accountType} onChange={(e) => {
													
															onChangeOfCheckBox(e, value)

														}} checked={value.isEnabled ? value.isEnabled : checkedReferenceList?.includes(value.value) ? true : checkedReferenceList?.includes("All") ? true : false}  handlePropagationFromParent={props?.handlePropagationFromParent}/>
														</>}
													
													{selectOption === "singleSelect" &&
														<>
															{/* <div> */}
																<SkRadioBtn setCheckValueDependsOnPropsValue={props.CheckValueDependsOnPropsValue} isLableClickable={true} checked={selected?true:value.selected ? true : checkedReferenceList?.includes?.(value?.value) ? true : false} 
																onChange={(e) =>
																	 {
																	let checked = selected?true:value.selected ? true : checkedReferenceList?.includes?.(value?.value) ? true : false;
																	if(!checked){
																		props.onChangeKey(e,value, props.id, props.emailId,checkedReferenceList)
																	} else{
																		props.onChangeKey(e,null, props.id, props.emailId,checkedReferenceList)
																	}
																}} 
																name={'ASSIGNED_ACCOUNTS'} />
															{/* </div> */}
														<div className="w-100 radio__button__text" style={{display: (props?.dropDownType === 'Account_Dropdown') ? 'inline-flex': ''}}>
															{props?.dropDownType === 'Account_Dropdown' &&
																<span className="smart__react__select__other__account__div" >
																	{value?.accountType === 'PARENT' &&
																		<span className="smart__react__select__parent__account__icon" >
																		</span> }
																</span>
															}
																<AutoUiLabel className="radio__button__text-span" showOnlyTooltip={true} width={ props?.dropDownType === 'Account_Dropdown' ? "72%" :"90%"} placement="top" tooltipClass="HWSelectMultiSelectTooltip" label={value?.label} />
															</div>
														</>}
												</div>)
										})}
									</div>
								</div>:<div className={`${props.isLoading ?"hide1":""} filter-attribute-dropdown-option text-center justify-content-center`}>No data found</div>}
								{(selectOption === "singleSelect" && !props.hideApplyBtn && referencelist && referencelist.length > 0 ) && <SKSmartUiBtns  {...btns} />}
								{(selectOption === "multiSelect" && props.showApplyBtn && referencelist && referencelist.length > 0 ) && <SKSmartUiBtns  {...btns} />}

							</div>
						
					</div>
				
				</Popper>
			</ClickAwayListener>
		</div>
	)
}

export const MultiSeclectDropdownWithDropDown = (props) => {
	const [searchText, setSearchText] = useState("");
	const [referenceList, setreferenceList] = useState(props.referenceList)
	const [selectOption, setSelectedOption] = useState(props.selectOption);
	const [anchorElTarget, setAnchorElTarget] = useState(props.anchorElTarget);
	const [checkedReferenceList, setCheckReferenceList] = useState(props.checkedReferenceList);
	const [flag ,setFlag] = useState(false)
	const getGlobalState = useSelector(getGlobalStates);
	const { paramRowData } = getGlobalState
	const [dropdownErrorMessage, setDropDownErrorMessage] = useState(null);

	useEffect(()=>{
			setFlag(true)
	}, [])
 
useEffect(()=>{
	if(paramRowData && flag ){
		setFlag(false)
		props.hidePopup && 
		props.hidePopup(checkedReferenceList)
	}
}, [paramRowData])
	
	useEffect(() => {
		const handleScroll = () => {
			props.hidePopup &&	props.hidePopup(checkedReferenceList)
		}
		let elemnt = document.querySelector(".datagrid__table__container .MuiDataGrid-virtualScroller");
		let elemnt1 = document.querySelector(".smart__konversation__table");
		let skDashBoardContainer = document.querySelector(".sk-smart-dashboard-container-content");
		if (skDashBoardContainer) { skDashBoardContainer.addEventListener('scroll', handleScroll) }
		if (elemnt) {  elemnt.addEventListener('scroll', handleScroll)}
		if (elemnt1) {  elemnt1.addEventListener('scroll', handleScroll)}
		window.addEventListener('scroll', handleScroll)
		return () => {
			if (skDashBoardContainer) { skDashBoardContainer.removeEventListener('scroll', handleScroll) }
			if (elemnt) { elemnt.removeEventListener('scroll', handleScroll) }
			if (elemnt1) {  elemnt1.removeEventListener('scroll', handleScroll)}
			window.removeEventListener('scroll', handleScroll)
		}
	}, [])

	useEffect(() => {
		// setDelayNoData(true)
		let  combinedList  = (() => {
			let selectedlist =
				props?.checkedReferenceList &&
				props?.checkedReferenceList?.length > 0 &&
				Array.isArray(props?.checkedReferenceList) && props?.checkedReferenceList?.flatMap((checkedItem) => {
					let array =
						props.referenceList &&
						props.referenceList?.length > 0 &&
						props.referenceList?.filter((referenceListItem) => {
							if (referenceListItem?.id) {
								return referenceListItem?.id === checkedItem;
							} else if (referenceListItem?.value) {
								return referenceListItem?.value === checkedItem;
							}
							
						});
					array && array?.sort((a, b) => (a?.label || '').localeCompare(b?.label || ''));
					return array;
				});
		
			let notSelectedList =
				props?.checkedReferenceList &&
				props?.checkedReferenceList?.length > 0 &&
				props.referenceList &&
				props.referenceList?.length > 0 &&
				props.referenceList
					?.filter(
						(referenceListItem) => {
							if (referenceListItem?.id) {
								return !props.checkedReferenceList.includes(referenceListItem?.id)
							} else if (referenceListItem?.value) {
								return !props.checkedReferenceList.includes(referenceListItem?.value)
							}
						}
							
					)
					.sort((a, b) => (a?.label || '').localeCompare(b?.label || ''));
			
		
			let combinedList =selectedlist && notSelectedList && selectedlist?.concat(notSelectedList);
		
			return combinedList;
	
		
		})();

		if (combinedList && combinedList?.length > 0) {
			if(!props?.dontSort){
				setreferenceList(combinedList)
			} else{
				const all = combinedList.filter(item => item.label === 'All');
			  combinedList = combinedList.filter(item => item.label !== 'All');
				setreferenceList([...all, ...combinedList])
			}
		} else {
			setreferenceList(props.referenceList)
		}

	}, [props.referenceList])



	useEffect(() => {
		setCheckReferenceList(props.checkedReferenceList);
	}, [props.checkedReferenceList]);

	useEffect(() => {
		setAnchorElTarget(props.anchorElTarget)
	}, [props.anchorElTarget])

	useEffect(() => {
		document.getElementById("search")?.focus();
	}, [anchorElTarget])

	const btns = {
		primaryBtnLabel: "APPLY",
		primaryBtnAction: props.onApply,
		className: "globalfilter_appy_btn_container"
	}


	const onClickRadioBtn = (option, value) => {
		props.onChangeKey(option, value,null,checkedReferenceList);
	}

	const customFilter = useMemo(() => {
		let list = cloneDeep(referenceList);
		if (searchText) {
			list = list && list.filter((i) => {
				return (i.label?.toLowerCase()?.includes(searchText?.toLowerCase()))
			})
		}
		// console.log('customFilter', referenceList,searchText );
		return list
	}, [referenceList, searchText])

	const referencelist = useMemo(() => {
		if (props.isCustomFilter) { return customFilter; }

		return referenceList
	}, [customFilter, props.isCustomFilter, referenceList])

	const onSearch = (e) => {
		e?.stopPropagation()
		if(!props.isCustomFilter){ props.onSearch && props.onSearch(e.target.value); }
	}

	// const handleCrossSymbolClick = useCallback((e, data) => {
	// 	e?.stopPropagation()
	// 	let selectedData = referenceList?.find(item => item?.id === data?.id)
	// 	let selectedList = cloneDeep(checkedReferenceList);
	// 	if (selectedList?.includes(data?.id)) {
	// 		selectedList = selectedList?.filter(item => item !== data?.id)
	// 	}
	// 	props.onChangeKey(data?.id, selectedData,null,checkedReferenceList);
	// }, [checkedReferenceList, props, referenceList])
	
	const onChangeOfCheckBox = useCallback((e, value) => {
				props.onChangeKey(e, value,null,checkedReferenceList);
	}, [checkedReferenceList])

	const handleInputKeyDown = (e) => {
		setDropDownErrorMessage(null);
		if(e?.key === 'Enter') {
			const res = props?.handleEnterKeyFromParent && props?.handleEnterKeyFromParent(searchText);
			if(res === "Invalid email address" || res === "User already exists") setDropDownErrorMessage(res);
		}
	}

	return (
		<div>
			<ClickAwayListener onClickAway={() => { 
							props?.hidePopup &&	props?.hidePopup(checkedReferenceList) 
							props?.dropDownListDataReset && props?.dropDownListDataReset();
				}} mouseEvent="onMouseDown" touchEvent="onTouchStart" >
				<Popper
					id={props?.id}
					className={props.className + " " + "multiSeclectDropdown_overlay"}
					open={true} anchorEl={anchorElTarget}
					placement={props?.placement}
					disablePortal={props?.disablePortal}
					container={props.container}
					onClose={() => { 
						props?.hidePopup &&	props?.hidePopup(checkedReferenceList) 
					}}
					style={{...props.style}}
					PaperProps={{style:{"color":"#000"}}}
				>
					<div className="multiSelectDropdown custom__multi__select__with__drop__down">
						<SkLoaderComponent isLoading={props?.isLoading}/>

						

						<div className="filter-dropdown-search-box-container multi__select__dropdown__scroll__container" onClick={(e)=> {e.preventDefault(); e.stopPropagation()}}>

							{/* Adding Code Here  */}

							<div className="selection--container">
									<SkSmartMultiSelectDropDown menuPosition={"absolute"} type="single_select" optionTooltipClass={"HWSelectMultiSelectTooltip grid_title__tooltip"} isDisabled={props.disableAccountSegmentFilter?true:false} onChange={(e) => props?.onChangeDropDown(e)}
									isSearchable={false} isRequired={false} value={props?.dropDownValue} isMultiSelect={false} options={props?.dropDownOptionList} isClearable={false} className='' showArrowIcon={true} placeholderText={"-Select-"} blurInputOnSelect={true} />
							</div>

								{props.showSearch && 
								<div className='filter-dropdown-search-box_contaciner'><div className="filter-dropdown-search-box">
									<input autoComplete='off' placeholder={props?.customPlaceholder || "Search..."} className="filter-dropdown-search-box-input" onChange={(e) => {
											e?.stopPropagation()
											onSearch(e); setSearchText(e.target.value);
										}}
										onKeyDown={(e) => { props?.allowCustomInputAddition && handleInputKeyDown(e) }}
										onClick={(e) => { e?.stopPropagation(); e?.preventDefault() }}
										id={"search"+props?.searchId}
										key={21863786 + props?.searchId}
										name="search_new" />
								</div></div>}
								{dropdownErrorMessage && <div className='drop_down_error_container'>
									<div className='drop_down_error_message'>
										{dropdownErrorMessage}
									</div>	
								</div>}
								{referencelist && referencelist.length > 0 ? <div className="filter-dropdown-search-box-options">
									<div className="filter-dropdown-search-box-options-container">
										{referencelist.map(value => {
											const selected = value.isEnabled ? value.isEnabled : checkedReferenceList?.includes(value.value) ? true : false
						
											return (
												<div key={value?.value || value?.id} onClick={(e) => {
													e?.stopPropagation()
													e?.preventDefault();
													if (selectOption === "singleSelect") {
														onClickRadioBtn(e, value)
													}
													else {
														!props?.disableOnChangeOfCheckBox && onChangeOfCheckBox(e, value) 
													}
												}} className={((selected) ? "filter-attribute-dropdown-option-selected" : "filter-attribute-dropdown-option")} >
													{selectOption === "multiSelect" &&
														<>

														<SmartCheckBox key={"SmartCheckBox_" + value?.value || value?.id} value={value?.value || value?.id} disabled={value?.cantEnable} label={value?.label} dropDownType={props?.dropDownType} accountType={value?.accountType} onChange={(e) => {
													
															onChangeOfCheckBox(e, value)

														}} checked={value.isEnabled ? value.isEnabled : checkedReferenceList?.includes(value.value) ? true : checkedReferenceList?.includes("All") ? true : false}  handlePropagationFromParent={props?.handlePropagationFromParent}/>
														</>}
													
													{selectOption === "singleSelect" &&
														<>
															{/* <div> */}
																<SkRadioBtn setCheckValueDependsOnPropsValue={props.CheckValueDependsOnPropsValue} isLableClickable={true} checked={selected?true:value.selected ? true : checkedReferenceList?.includes?.(value?.value) ? true : false} 
																onChange={(e) =>
																	{
																	let checked = selected?true:value.selected ? true : checkedReferenceList?.includes?.(value?.value) ? true : false;
																	if(!checked){
																		props.onChangeKey(e,value, props.id, props.emailId,checkedReferenceList)
																	} else{
																		props.onChangeKey(e,null, props.id, props.emailId,checkedReferenceList)
																	}
																}} 
																name={'ASSIGNED_ACCOUNTS'} />
															{/* </div> */}
														<div className="w-100 radio__button__text" style={{display: (props?.dropDownType === 'Account_Dropdown') ? 'inline-flex': ''}}>
															{props?.dropDownType === 'Account_Dropdown' &&
																<span className="smart__react__select__other__account__div" >
																	{value?.accountType === 'PARENT' &&
																		<span className="smart__react__select__parent__account__icon" >
																		</span> }
																</span>
															}
																<AutoUiLabel className="radio__button__text-span" showOnlyTooltip={true} width={ props?.dropDownType === 'Account_Dropdown' ? "72%" :"90%"} placement="top" tooltipClass="HWSelectMultiSelectTooltip" label={value?.label} />
															</div>
														</>}
												</div>)
										})}
									</div>
								</div>:<div className={`${props.isLoading ?"hide1":""} filter-attribute-dropdown-option text-center justify-content-center`}>No data found</div>}
								{(selectOption === "singleSelect" && !props.hideApplyBtn && referencelist && referencelist.length > 0 ) && <SKSmartUiBtns  {...btns} />}
								{(selectOption === "multiSelect" && props.showApplyBtn && referencelist && referencelist.length > 0 ) && <SKSmartUiBtns  {...btns} />}

							</div>
						
					</div>
				
				</Popper>
			</ClickAwayListener>
		</div>
	)
}

export const SmartCheckBox = (props) => {
	const [isChecked, setIsChecked] = useState(false);
	const [unqId, setUnqId] = useState(Math.floor(Math.random() * 100));

	useEffect(() => {
		if (props.checked) {
			setIsChecked(props.checked)
		} else {
			setIsChecked(false)
		}
	}, [props.checked])

	const onCheckHandler = (e) => {
		if (!props.disabled) {
			let chkStatus = e.target && e.target.checked ? true : false;
			props.onChange && props.onChange(chkStatus === true ? props.value ? props.value : e : '');
						setIsChecked(!isChecked);
		}
	}
	return (<div data-id={props.value || props.id} className={`smartCheckBox w-100 ${props.className}`} >
		<input className='skCheckInput' value={props.value} id={(props.value || props.id) ? props.value || props.id : `skCheck${unqId}`} type="checkbox" disabled={props.disabled} name={props.name} checked={isChecked ? isChecked : false} {...(props.handlePropagationFromParent && { onClick: (e) => { e?.stopPropagation(); e?.preventDefault() } })} onChange={(e) => { e?.stopPropagation();  onCheckHandler(e) }} />
		<label {...(props.handlePropagationFromParent && { onClick: (e) => {e?.stopPropagation(); e.preventDefault()} })} className='smartCheckBox__label' htmlFor={(props.value || props.id) ? (props.value || props.id) : `skCheck${unqId}`} >
		{props?.dropDownType === 'Account_Dropdown' &&
																<span className="smart__react__select__other__account__div" >
																	{props.accountType === 'PARENT' &&
																		<span className="smart__react__select__parent__account__icon" >
																		</span> }
																</span>
															}
			<AutoUiLabel  showOnlyTooltip={true} width={"100%"} placement="top" tooltipClass="HWSelectMultiSelectTooltip" label={props.label} />
		</label>
		{props?.showInfoBtn && <span className={props?.infoClassName}>
			<UiLabel tooltipClass={`${props?.infoClassName}-message`} showToolTipWithHtml={true} length={0} placement="top" /*tooltipClass={"pc-usage-infi-tooltip "}*/ label={props?.infoMessage}>
				<div className='theme__based__info__icon'/>
			</UiLabel>
		</span>
		}
	</div>)
}

export default MultiSeclectDropdown;