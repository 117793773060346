import { useSelector } from "react-redux";
import { getGlobalStates } from '../store/reducers/skGlobalReducer';
import access from "../HWLibrary/AccessControl";
import HWLocalStorage from "../HWLibrary/HWLocalStorage";
import HWUser from "../HWLibrary/HWUser";
import { playbookRbackScope } from "../store/reducers/skPlaybooksReducer";

const SideBarRoutes = () => {
	let platformUser = HWLocalStorage.load("platform-currentUser");
	const globalNotificationState = useSelector(getGlobalStates);
	const { kommunityCount } = globalNotificationState;

	return [
		{
			className: "search__header__icon",
			isEnable: access?.search?.view() && !(playbookRbackScope(["CustomerPortalUser:Enabled"])),
			onClick: "search",
			altTag: "Search",
		},
		{
			className: "kommunity_header_icon",
			isEnable: false,
			// isEnable: platformUser?.kAndKStatus === "Active" && access?.kommunity?.view() &&  !playbookRbackScope(["CustomerPortalUser:Enabled"]),
			redirect: "/dashboard/kommunity",
			altTag: "Kommunity",
			onClick: "kommunity",
			showBadge: kommunityCount > 0 ? true : false,
			badgeCount: kommunityCount,
		},

		{
			className: "notification_header_icon",
			isEnable: access?.alerts?.view() && !playbookRbackScope(["CustomerPortalUser:Enabled"]),
			onClick: "notification",
			altTag: "Notification",
		},
		{
			className: "academy_header_icon",
			isEnable: HWUser.orgType() !== "playbookStandalone",
			onClick: "academy",
			altTag: "Academy",
		},
		{
			className: "support_header_icon",
			isEnable: true,
			onClick: "support",
			altTag: "Support",
		},
		{
			className: "settings_header_icon",
			isEnable:  !playbookRbackScope(["CustomerPortalUser:Enabled"]) && !(HWUser.loggedInUserType() === 'VendorPortal'),
			redirect: "/dashboard/settings",
			altTag: "Settings",
			onClick: "Settings"
		},
	]
}
export default SideBarRoutes;