import { skTaskActions, skPlayBookActions } from "../constants/actionType";

import { store } from "../index";
import HWUser from "../../HWLibrary/HWUser";
import { apiCall } from "../api";
import cachedAPI from "../../SkSmartUtil/CachedAPI";
import skExpansionAnalyserService from "../../KeyGrowthIndicator/ExapansionModule/SkExpansionAnalyserService";
import smartFilterTokenService from "../../SmartFilterTab/SmartFilterToken";
import skApiService from "../../SkApiService";
import moment from "moment";
import axios from "axios";
import cache from "../../SkSmartUtil/InMemoryCache";
import { setSmartPlayBookStates } from '../../store/reducers/skPlaybooksReducer';

import hwAxios from "../../HWLibrary/HWAxios";
import { cloneDeep } from "lodash";
import { setGlobalStates } from "./skGlobalReducer";
import uuid from "react-uuid";
import { playbookRbackScope } from "./skPlaybooksReducer";



const servers = [
  'https://cs-labs1.smartkarrot.com',
  'https://cs-labs2.smartkarrot.com',
  'https://cs-labs3.smartkarrot.com',
	'https://cs-labs4.smartkarrot.com',
];

let currentServerIndex = 0;

const getNextServer = () => {
  const server = servers[currentServerIndex];
  currentServerIndex = (currentServerIndex + 1) % servers.length;
  return server;
};


const originalBaseURL = 'https://cs-labs.smartkarrot.com'

export const getSmartTaskStates = () => {
	return store.getState()?.smartTaskReducer;
}

export const getSmartTask = (categoryId , taskId)=> {
	let taskCategoryList =  getSmartTaskStates().smartTaskCategoryList ;
	let index = taskCategoryList && taskCategoryList.findIndex(x => x?.categoryId === categoryId);
	let taskIndex = taskCategoryList[index].taskData.findIndex(x => x?.taskId === taskId);
	return taskCategoryList[index]?.taskData[taskIndex] ;
}

export const setSmartTaskStates = (obj) => async dispatch => {
	dispatch({
		type: skTaskActions?.SET_SMART_TASK_STATES, payload: obj
	})
}

export const resetSmartTaskList  = (categoryId)=> async dispatch =>{
	dispatch({
		type : skTaskActions?.RESET_SMART_TASK_LIST,
		payload : {categoryId}
	})
}

export const reloadAllTables = (searchText,sortList, callApi) => async dispatch =>{
	dispatch({
		type : skTaskActions?.RESET_SMART_TASK_LIST,
		payload : {categoryId : null}
	})
	let categoriesList = getSmartTaskStates()?.smartTaskCategoryList || [];
	let categoriesToOpen = categoriesList && categoriesList?.length > 0 && categoriesList?.map(category => category?.categoryId);
	const categoryIds = (getSmartTaskStates()?.activeTaskToggleView === "outcome"  || getSmartTaskStates()?.activeTaskToggleView === "function") ?  [categoriesToOpen?.[0]] :categoriesToOpen
	dispatch(setSmartTaskStates({isLoading : true ,  openedCategoryIds : categoryIds }));
	categoryIds && categoryIds?.length > 0 && categoryIds?.forEach(category => {
	dispatch(fetchSmartTasksList(getSmartTaskStates()?.activeTaskToggleView , category, null,sortList, null,null,null,searchText,true, callApi)) ;
	})
}

// fetch task with categories
export const fetchTaskWithCategories = (taskView, searchText, sortList) => async dispatch => {
	let request = {};
	request = {
		orgId: HWUser.orgId(), 
		appId: HWUser.appId(), 
		userId: HWUser.userId(), 
		gtmatrics:localStorage?.getItem("auth") ? true:false,
		view: taskView, 
		sortList:getSmartTaskStates()?.taskSortList && getSmartTaskStates()?.taskSortList.length>0?getSmartTaskStates()?.taskSortList:null,
		searchText: searchText,
		disableSort: getSmartTaskStates()?.disableSort 
	
	}
try {
	const response = await apiCall({
		url: window.resolveRegion(process.env.REACT_APP_SMART_DASHBOARDS_BASE_URL) + "/smart-task/task-home",
		method: "POST",
		payload: request,
		addFilter: true
	});
	dispatch(setSmartTaskStates({ isLoading: false, showMainSkeleton: false }));

	const taskTableView = getSmartTaskStates().globalTaskTableView;
	if (response.status === 200 
		&& (!taskTableView || !taskView || taskTableView === taskView)		// Adding this condition to handle the situation when we toggle between between list-timeline-outcome views quickly and multiple API calls are made for each view. The condition prevents data override when data from API has different view that the view which is currently open on the UI.
	) {
		const taskCategoriesData = response?.data?.data;
		const sortListFromApi = response?.data?.sortList;
		const canEditFromApi = response?.data?.canEdit;
		const taskViewFromApi = response?.data?.view;	
		dispatch({
			type: skTaskActions?.FETCH_TIMELINE_OUTCOME_OPTIONS,
			payload: {
				smartTaskCategoryList: taskCategoriesData || [],
				currentTaskView : taskView
			}
		})
		dispatch(setSmartTaskStates({
			// activeTaskToggleView: taskViewFromApi,
			taskSortList: sortListFromApi,
			isTaskTableEditable:canEditFromApi
		}))

		if (!getSmartTaskStates()?.activeTaskToggleView) {
			dispatch(setSmartTaskStates({
				activeTaskToggleView: taskViewFromApi,
			}))
		}
		
		dispatch(reloadAllTables(searchText,sortList)) ;
	}
} catch (error) {
	dispatch({
		type: skTaskActions?.SET_IS_ERROR,
		payload: { isError: true, errorMessage: error?.response?.data?.message, isLoading: false }
	})
	setTimeout(() => {
		dispatch(setSmartTaskStates({ errorMessage: '', isError: false }))
	}, 2000)
}
}

// fetch task list

export const fetchSmartTasksList = (taskView, categoryId, nextToken, sortList, sortMode, parentTaskId, resetTable, searchText, isReloadAllTables, callApi) => async dispatch => {
	let request = {};
	request = {
		orgId: HWUser.orgId(), 
		appId: HWUser.appId(), 
		userId: HWUser.userId(), 
		tableView: taskView, 
		categoryIds: sortMode === "manual" ? getSmartTaskStates()?.openedCategoryIds : categoryId ? [categoryId] : null, 
		nextToken: nextToken, 
		sortMode:sortMode,
		sortList:sortList ?sortList:null,
		parentTaskId, 
		gtmatrics:localStorage?.getItem("auth") ? true:false,
		searchText: searchText,
		disableSort: getSmartTaskStates()?.disableSort 
	}
	if (!callApi ) {
		dispatch({type : skTaskActions?.SHOW_SMART_SKELETON , payload:{categoryId}}) ;
	} else{
		dispatch({type : skTaskActions?.SHOW_SMART_LOADING , payload : {categoryId}})
	}	
	
	try {
		let response
		if (callApi) {
			response = await apiCall({
			url: window.resolveRegion(process.env.REACT_APP_SMART_DASHBOARDS_BASE_URL) + "/smart-task/task-home",
			method: "POST",
			payload: request,
			addFilter: true
		});
		}
		let categoriesListWithTask = getSmartTaskStates()?.smartTaskCategoryList || [];
		const taskDataFromCategories = categoriesListWithTask && categoriesListWithTask?.length > 0 ? categoriesListWithTask?.find(category => category?.categoryId === categoryId) : [];
		const taskList = callApi  ? response?.data?.data?.[0]?.list : taskDataFromCategories?.list;
		let taskData = taskList?.map((task) => {
			return {
				...task,
				collaborators : task?.collaboratorIds,
				productAllIds : task?.productIds,
				outcomeId :  task?.outcome ? task.outcome?.id : '',
				path : parentTaskId ? [parentTaskId, task.taskId] : [task.taskId],
				categoryId
			}
		})

		
		if(parentTaskId){
			// CurrentTask is storing the parentTask for the current subtask (when this function is being called after clicking on expand subtask icon)
			// "Add subtask" is not being shown for playbook task which are present in Task list
			let currentTask = taskDataFromCategories?.list?.filter((task) => task.taskId === parentTaskId);
			if(!currentTask) currentTask = taskDataFromCategories?.taskData?.filter((task) => task?.taskId === parentTaskId);
			const isPlaybookTask = currentTask && currentTask[0]?.livePlaybookTaskId;

			if(HWUser.isSubtaskEnabled() && currentTask && currentTask[0]?.status === 'INCOMPLETE' && currentTask[0]?.assigneeType !== 'ExternalUser') {
				taskData = [{
					id: "add_actions_row_" + parentTaskId,
					taskId: uuid(),
					rowType: "add_actions",
					path: [parentTaskId, "add_actions_row_" + parentTaskId],
					parentTaskId: parentTaskId,
					customerAccount: currentTask[0]?.customerAccount ? currentTask[0]?.customerAccount : [],
				}, ...taskData ]
			}
		}
		dispatch({
			type: skTaskActions?.FETCH_SMART_TASK_LIST,
			payload: {
				taskList: taskData ,
				isSubTask : parentTaskId ? true : false ,
				prevToken : nextToken,
				nextToken: callApi ? response?.data?.data?.[0]?.nextToken :  taskDataFromCategories?.nextToken,
				categoryId: categoryId,
				sortList:sortList && sortList.length>0?sortList:null,
				sortMode:sortMode,
				parentTaskId,
				resetTable ,
				canEditTask:callApi ? response?.data?.data?.[0]?.canEdit: taskDataFromCategories?.canEdit,
				totalTaskCount : callApi ? response?.data?.data?.[0]?.totalCount : taskDataFromCategories?.totalCount,
				delayedTaskCount : callApi ? response?.data?.data?.[0]?.delayedCount : taskDataFromCategories?.delayedCount
			}
		})
		dispatch({type : skTaskActions?.HIDE_SMART_SKELETON , payload : {categoryId}}) ;
		dispatch({type : skTaskActions?.HIDE_SMART_LOADING , payload : {categoryId}})
		dispatch(setSmartTaskStates({ isLoading: false , isSuccess: true, showSkeleton : false,}))
		if (isReloadAllTables && getSmartTaskStates()?.smartTaskCategoryList?.length > 1 && taskView?.toLowerCase() === "timeline") {
			// let category = getSmartTaskStates()?.smartTaskCategoryList && getSmartTaskStates()?.smartTaskCategoryList?.length > 0 && getSmartTaskStates()?.smartTaskCategoryList?.find(category => category?.categoryId === categoryId)?.categoryId;
			// let categoriesToOpen = [category, ...getSmartTaskStates()?.openedCategoryIds]
			dispatch(setSmartTaskStates({isLoading : true , }));
			dispatch(fetchSmartTimeTasksList(getSmartTaskStates()?.activeTaskToggleView, getSmartTaskStates()?.smartTaskCategoryList[1]?.categoryId, null,sortList, sortMode,null,null,searchText,true)) ;
		}
	} catch (error) {
		dispatch({ type: skTaskActions?.HIDE_SMART_SKELETON, payload: { categoryId } });
		dispatch({type : skTaskActions?.HIDE_SMART_LOADING , payload : {categoryId}})
		dispatch(setSmartTaskStates({ isError: true, errorMessage: error?.response?.data?.message, isLoading: false , showSkeleton : false}))
		setTimeout(() => {
			dispatch(setSmartTaskStates({ errorMessage: '', isError: false }))
		}, 2000)
	}
}

export const fetchSmartTimeTasksList = (taskView, categoryId, nextToken, sortList, sortMode, parentTaskId , resetTable, searchText,isReloadAllTables) => async dispatch => {
	let request = {};
	request = {
		orgId: HWUser.orgId(), 
		appId: HWUser.appId(), 
		userId: HWUser.userId(), 
		view: taskView, 
		categoryId: categoryId, 
		nextToken: nextToken, 
		sortMode:sortMode,
		sortList:sortList && sortList.length>0?sortList:null,
		parentTaskId, 
		searchText: searchText,
	}
	// dispatch(setSmartTaskStates({ isLoading: true }))
	if(!(nextToken || sortList || parentTaskId)){
		dispatch({type : skTaskActions?.SHOW_SMART_SKELETON , payload:{categoryId}}) ;
	} else{
		dispatch({type : skTaskActions?.SHOW_SMART_LOADING , payload : {categoryId}})
	}	
	try {
		// const response = await apiCall({
		// 	url: window.resolveRegion(process.env.REACT_APP_SMART_DASHBOARDS_BASE_URL) + "/smart-task/task-home",
		// 	method: "GET",
		// 	payload: request,
		// 	addFilter: true
		// });
		let categoriesListWithTask = getSmartTaskStates()?.smartTaskCategoryList || [];
		const taskDataFromCategories = categoriesListWithTask && categoriesListWithTask?.length > 0 ? categoriesListWithTask?.find(category => category?.categoryId === categoryId) : []
		// const response = await apiCall({
		// 	url: window.resolveRegion(process.env.REACT_APP_SMART_DASHBOARDS_BASE_URL) + "/smart-task/task-home",
		// 	method: "POST",
		// 	payload: request,
		// 	addFilter: true
		// });
		let taskData = taskDataFromCategories?.list.map((task) => {
			return {...task,
				collaborators : task?.collaboratorIds,
				productAllIds : task?.productIds,
				outcomeId :  task?.outcome ? task.outcome?.id : '',
				path : parentTaskId ? [parentTaskId, task.taskId] : [task.taskId],
				categoryId
			}
		})
		dispatch({
			type: skTaskActions?.FETCH_SMART_TASK_LIST,
			payload: {
				taskList: taskData ,
				isSubTask : parentTaskId ? true : false ,
				prevToken : nextToken,
				nextToken: taskDataFromCategories?.nextToken,
				categoryId: categoryId,
				// sortKey: sortKey,
				// sortOrder: sortOrder,
				sortMode:sortMode,
				sortList:sortList && sortList.length>0?sortList:null,
				parentTaskId,
				resetTable ,
				canEditTask:taskDataFromCategories?.canEdit,
				totalTaskCount : taskDataFromCategories?.totalCount,
				delayedTaskCount: taskDataFromCategories?.delayedCount
			}
		})
		dispatch({type : skTaskActions?.HIDE_SMART_SKELETON , payload : {categoryId}}) ;
		dispatch({type : skTaskActions?.HIDE_SMART_LOADING , payload : {categoryId}})
		dispatch(setSmartTaskStates({ isLoading: false , isSuccess: true, showSkeleton : false,}))
		if(isReloadAllTables && taskView?.toLowerCase === "timeline" && getSmartTaskStates()?.smartTaskCategoryList?.length>2){
			let categoriesToOpen = null ;
			if(getSmartTaskStates()?.activeTaskToggleView === "timeline"){
				categoriesToOpen = getSmartTaskStates()?.smartTaskCategoryList && getSmartTaskStates()?.smartTaskCategoryList.map((i,index)=>{
					return i?.categoryId
				})
			}
		dispatch(setSmartTaskStates({isLoading : true ,  openedCategoryIds : categoriesToOpen }));
		categoriesToOpen.forEach((category ,index) => {
			if(index>1){
				dispatch(fetchSmartTasksList(getSmartTaskStates()?.activeTaskToggleView , category, null,sortList, sortMode,null,null,searchText)) ;
			}
		})
		}
	} catch (error) {
		dispatch({type : skTaskActions?.HIDE_SMART_SKELETON , payload : {categoryId}}) ;
		dispatch(setSmartTaskStates({ isError: true, errorMessage: error?.response?.data?.message, isLoading: false , showSkeleton : false}))
		setTimeout(() => {
			dispatch(setSmartTaskStates({ errorMessage: '', isError: false }))
		}, 2000)
	}
}

export const fetchSmartTask = (taskId, task , isRefresh) => async dispatch => {
	let request = {};
	request.params = { orgId: HWUser.orgId(), appId: HWUser.appId(), userId: HWUser.userId(), taskId: taskId ? taskId : task?.taskId };
	// dispatch(setSmartTaskStates({ isLoading: true }))
	try{
		const response = await apiCall({
			url: window.resolveRegion(process.env.REACT_APP_SMART_DASHBOARDS_BASE_URL) + "/smart-task/task",
			method: "GET",
			payload: request,
			addFilter: true
		});


		if (response.status === 200) {
			
			if(isRefresh){
				let newTask = response.data;
				newTask.categoryId = task?.categoryId
				newTask.path  =  task?.path 
				newTask.outcomeId =  newTask?.outcome ? newTask.outcome?.id : ''
				dispatch({
					type : skTaskActions?.EDIT_SMART_TASK, 
					payload : {categoryId : newTask?.categoryId , data : newTask }
				})
				// dispatch(setSmartTaskStates({ isLoading: false}))
			}
			else{
				dispatch(setSmartTaskStates({ isLoading: false}));
				return response.data  ;
			}
		}
	}catch(error){
		dispatch(setSmartTaskStates({ isError: true, errorMessage: error?.response?.data?.message, isLoading: false }))
		setTimeout(() => {
			dispatch(setSmartTaskStates({ errorMessage: '', isError: false }))
		}, 2000)
	}
}




const fetchCategoryRefrenceDataCache = () => {
	const cacheKey      = HWUser.userId() ;
	const fetchFunction = () => {
		let url     = window.resolveRegion(process.env.REACT_APP_SMART_DASHBOARDS_BASE_URL) + "/playbook/reference-data/outcome";
		let aConfig = { headers: { "Authorization": HWUser.accessToken(), "Content-Type": "application/json","addFilter": true } };
		aConfig.params = { orgId: HWUser.orgId(), appId: HWUser.appId(), userId: HWUser.userId()}
		return hwAxios.get(url, aConfig);
	}
	return cache.get(cacheKey, cache.CacheModule.outcomeRefData, fetchFunction);
}

// fetch outcomes list
export const fetchTaskOutcomeOptions = () => async dispatch => {
	dispatch(setSmartTaskStates({ isLoading: true, }));
	try {

		fetchCategoryRefrenceDataCache().then(response => {
			let outcomeList = [];
			let data = cloneDeep(response?.data?.data);
			data.forEach(outcome => {
				outcomeList.push({ id: outcome?.id, value: outcome?.id, label: outcome?.name, colorCode: outcome?.colorCode,linkedKeyOutcome:outcome?.linkedKeyOutcome })
			})
			outcomeList = outcomeList.sort((a, b) => a?.label?.localeCompare(b?.label));
			dispatch(setSmartTaskStates({ isLoading: false }));
			dispatch({
				type: skTaskActions?.FETCH_OUTCOME_OPTIONS,
				payload: { taskOutcomeOptions: outcomeList }
			})
			dispatch(setSmartTaskStates({ isLoading: false , isSuccess: true,}))
		})

	} catch (error) {
		dispatch(setSmartTaskStates({ isError: true, errorMessage: error?.response?.data?.message, isLoading: false }))
		setTimeout(() => {
			dispatch(setSmartTaskStates({ errorMessage: '', isError: false }))
		}, 2000)
	}
}

export const markAsComplete = (data, rowData, searchText, undoCall) => async dispatch => {
	let request = {  ...data };
	dispatch({type : skTaskActions?.SHOW_SMART_LOADING , payload : {categoryId :  data?.categoryId}})
	try {
		const response = await apiCall({
			url: window.resolveRegion(process.env.REACT_APP_SMART_DASHBOARDS_BASE_URL) + "/smart-task/task/complete",
			method: "PUT",
			payload: request,
		});
		if (response?.status === 200) {
			dispatch(setSmartTaskStates({disableSort: true}))
			let currentTaskView = getSmartTaskStates()?.activeTaskToggleView ;
				let smartTaskCategoryList = getSmartTaskStates()?.smartTaskCategoryList ;
				let index = smartTaskCategoryList.findIndex(item => item?.categoryId === rowData?.categoryId);
				let sortList = smartTaskCategoryList[index]?.sortList || null ;
				let sortMode = smartTaskCategoryList[index]?.sortMode;
				let resetTable = true;
				setTimeout(()=> {
					if(undoCall) dispatch(fetchSmartTasksList(currentTaskView, rowData?.categoryId, null , sortList, sortMode , null,  resetTable, null, null, true));
					dispatch({type : skTaskActions?.HIDE_SMART_LOADING , payload : {categoryId :  rowData?.categoryId}})
				}, 2000)

			return 'success'
	}
	} catch (error) {
		dispatch({type : skTaskActions?.HIDE_SMART_LOADING , payload : {categoryId :  data?.categoryId}})
		dispatch({
			type: skTaskActions?.SET_IS_ERROR,
			payload: { isError: true, errorMessage: error?.response?.data?.message, isLoading: false }
		})
		setTimeout(() => {
			dispatch(setSmartTaskStates({ errorMessage: '', isError: false }))
		}, 2000)
		return error?.response?.data?.message
	}
	
}

export const cleanSmartSubTask  = (parentTaskId, categoryId)=>{
}

// fetch product list
export const fetchTaskProductOptions = () => async dispatch => {
	dispatch(setSmartTaskStates({ isLoading: true }));
	dispatch(setSmartPlayBookStates({ isPlayBookLoading: true }));
	try {
		cachedAPI.fetchConfigureProducts().then(response => {
			let list = response?.data?.productList && response?.data?.productList.sort((a, b) => (a.productName + '').localeCompare(b.productName + ''));
			list = list && list.map((i) => {
				return ({ id: i?.id, value: i?.id, label: i?.name })
			})
			dispatch(setSmartTaskStates({ isLoading: false }));
			dispatch({
				type: skTaskActions?.FETCH_PRODUCT_OPTIONS,
				payload: { taskProductOptions: list }
			})

			dispatch(setSmartPlayBookStates({ isPlayBookLoading: false }))
			dispatch({
				type: skPlayBookActions?.FETCH_REFERENCE_PRODUCTS,
				payload: { productDataForOrganization: list }
			});
		})
	} catch (error) {
		dispatch({
			type: skTaskActions?.SET_IS_ERROR,
			payload: { isError: true, errorMessage: error?.response?.data?.message, isLoading: false }
		})
		dispatch(setSmartPlayBookStates({ isPlayBookLoading: false }));
		setTimeout(() => {
			dispatch(setSmartTaskStates({ errorMessage: '', isError: false }))
		}, 2000)

	}
}

const fetchTaskCategory = (feature) => {
	const cacheKey      = HWUser.userId();
	const fetchFunction = () => {
		let url     = window.resolveRegion(process.env.REACT_APP_SMART_DASHBOARDS_BASE_URL) + "/smart-task/reference-data/task-category";
		let aConfig = { headers: { "Authorization": HWUser.accessToken(), "Content-Type": "application/json" } };
		aConfig.params = { 
			orgId: HWUser.orgId(), appId: HWUser.appId(), userId: HWUser.userId(),roleId:HWUser.roleId(), feature:feature
		}
		return axios.get(url, aConfig);
	}
	return cache.get(cacheKey, cache.CacheModule.taskCategoryData, fetchFunction);
}

export const fetchTaskCategoryReferenceData = (feature) => async dispatch => {
	let request = {};
	request.params = { orgId: HWUser.orgId(), appId: HWUser.appId(), userId: HWUser.userId() };
	dispatch(setSmartTaskStates({ isLoadingTaskCategory: true }))
	try {
		fetchTaskCategory(feature).then(response => {
			let data = []
			response?.data.forEach(eachCategory => {
				data.push({ id: eachCategory?.id, value: eachCategory?.id, label: eachCategory?.name,...eachCategory })
			})
			data = data.sort((a, b) => a?.label?.localeCompare(b?.label));
			dispatch(setSmartTaskStates({ isLoadingTaskCategory: false,taskCategoryReferenceData: data }))
		})
	} catch (error) {
		dispatch(setSmartTaskStates({ isLoadingTaskCategory: false,taskCategoryReferenceData:[] }));
	}
}

export const fetchColumnPreference = (path) => async dispatch => {
	dispatch(setSmartTaskStates({ isLoading: true }));
	try {
		skExpansionAnalyserService.fetchColumnPreference(path).then(response => {
			dispatch(setSmartTaskStates({ isLoading: false }));
			dispatch({
				type: skTaskActions?.COLUMN_PREFERENCE,
				payload: { columnPreferenceData: response?.data?.columnPreference }
			})
		})
	} catch (error) {
		dispatch(setSmartTaskStates({ isLoading: false }));
	}
}

export const updateColumnReorderPreference = (request) => async dispatch => {
	dispatch({
		type: skTaskActions?.COLUMN_PREFERENCE,
		payload: { columnPreferenceData: request?.updatedColOrder }
	})

	dispatch(setSmartTaskStates({ isLoading: true }));
	
	try {
		
		skExpansionAnalyserService.updateColumnPreference(request).then(response => {
			dispatch(setSmartTaskStates({ isLoading: false }));
			// dispatch({
			// 	type: skTaskActions?.COLUMN_PREFERENCE,
			// 	payload: { columnPreferenceData: response?.data?.columnPreference }
			// })
		})
	} catch (error) {
		dispatch(setSmartTaskStates({ isLoading: false }));
	}
}

export const updateColumnPreference = (request) => async dispatch => {
	dispatch(setSmartTaskStates({ isLoading: true }));
	try {
		
		skExpansionAnalyserService.updateColumnPreference(request).then(response => {
			dispatch(setSmartTaskStates({ isLoading: false }));
			dispatch({
				type: skTaskActions?.COLUMN_PREFERENCE,
				payload: { columnPreferenceData: response?.data?.columnPreference }
			})
		})
	} catch (error) {
		dispatch(setSmartTaskStates({ isLoading: false }));
	}
}

export const fetchSortPreference = (path) => async dispatch => {
	dispatch(setSmartTaskStates({ isLoading: true }));
	try {
		const response = await skExpansionAnalyserService.fetchSortPreference(path)
		await	dispatch(setSmartTaskStates({ isLoading: false }));
			await	dispatch({
				type: skTaskActions?.SORT_PREFERENCE,
				payload: {sortPreference: response?.data?.sortPreference, viewPreference: response?.data?.view}
			})
		return response?.data
		
	} catch (error) {
		dispatch(setSmartTaskStates({ isLoading: false }));
	}
}

export const fetchToggleViewPreference = (path) => async dispatch => {
	dispatch(setSmartTaskStates({ isLoading: true }));
	try {
		const response = await skExpansionAnalyserService.fetchViewPreference(path);
		dispatch(setSmartTaskStates({ isLoading: false }));
		return response?.data
		
	} catch (error) {
		dispatch(setSmartTaskStates({ isLoading: false }));
	}
}

export const fetchViewPreference = (path) => async dispatch => {
	let request = {}
	request.params = {orgId: HWUser.orgId(), 
		appId: HWUser.appId(), 
		userId: HWUser.userId(),
		path:path}
	try {
		const response = await apiCall({
			url: window.resolveRegion(process.env.REACT_APP_SMART_DASHBOARDS_BASE_URL) + "/smart-filter/user-sort-preference",
			method: "GET",
			payload: request,
			addFilter: false
		});
		dispatch({
			type: skTaskActions?.SORT_PREFERENCE,
			payload: {viewPreference: response?.data?.view}
		})
     return response?.data?.view;
	} catch (error) {
		dispatch(setSmartTaskStates({ isLoading: false }));
	}
}

export const updateSortPreference = (request) => async dispatch => {
	dispatch(setSmartTaskStates({ isLoading: true }));
	try {
		skExpansionAnalyserService.updateSortPreference(request).then(response => {
			dispatch(setSmartTaskStates({ isLoading: false }));
			dispatch({
				type: skTaskActions?.SORT_PREFERENCE,
				payload: { sortPreference: response?.data?.sortPreference}
			})
		})
	} catch (error) {
		dispatch(setSmartTaskStates({ isLoading: false }));
	}
}

export const updateViewPreference = (request) => async dispatch => {
	dispatch(setSmartTaskStates({ isLoading: true }));
	try {
		skExpansionAnalyserService.updateViewPreference(request).then(response => {
			dispatch(setSmartTaskStates({ isLoading: false }));
		})
	} catch (error) {
		dispatch(setSmartTaskStates({ isLoading: false }));
	}
}

// fetch account list
export const fetchTaskAccountOptions = () => async dispatch => {
	dispatch(setSmartTaskStates({ isLoading: true }));
	try {
		smartFilterTokenService.fetchAccountList().then(response => {
			let accounts = response?.data?.data || [];
			accounts = accounts && accounts.filter((i) => {
				return !(i.accountId?.toLowerCase()?.includes('all'))
			})
			accounts = accounts && accounts.map((i) => {
				return ({ id: i?.accountId, value: i?.accountId, label: i?.accountName, accountType: i?.type })
			});
			dispatch(setSmartTaskStates({ isLoading: false }));
			dispatch({
				type: skTaskActions?.FETCH_ACCOUNT_OPTIONS,
				payload: { taskAccountOptions: accounts }
			})
		})
	} catch (error) {
		dispatch({
			type: skTaskActions?.SET_IS_ERROR,
			payload: { isError: true, errorMessage: error?.response?.data?.message, isLoading: false }
		})
		setTimeout(() => {
			dispatch(setSmartTaskStates({ errorMessage: '', isError: false }))
		}, 2000)
	}
}

// fetch collaborators list
export const fetchTaskCollaboratorOptions = (forceInvalidate) => async dispatch => {
	dispatch({
		type: skTaskActions?.SET_IS_LOADING,
		payload: { isLoading: true }
	})
	try {
		let users = [];
		skApiService.fetchUsers(null, forceInvalidate).then(response => {
			let data = response.data ? response.data : [];
			let userColorMap = {};
			data.forEach(item => {
				if(!item?.imageUrl) userColorMap[item?.userId] = item?.colorCode || 'var(--smart-konversations-label-wrap-color)';

				users.push({
					id: item?.userId,
					value: item?.userId,
					label: item?.username,
					imageUrl: item?.imageUrl,
					email: item?.email,
					data: {
						...item
					}
				})
			})
			users = users.sort((a, b) => a?.label?.localeCompare(b?.label));
			dispatch({
				type: skTaskActions?.FETCH_COLLABORATOR_OPTIONS,
				payload: { taskCollaboratorOptions: users, usersColorMap: userColorMap, isLoading: false }
			})
		})
	} catch (error) {
		dispatch({
			type: skTaskActions?.SET_IS_ERROR,
			payload: { isError: true, errorMessage: error?.response?.data?.message, isLoading: false }
		})
		setTimeout(() => {
			dispatch(setSmartTaskStates({ errorMessage: '', isError: false }))
		}, 2000)

	}
}


// create task
export const postTask = (data) => async dispatch => {
	let request = { orgId: HWUser.orgId(), appId: HWUser.appId(), userId: HWUser.userId(), ...data };
	dispatch(setSmartTaskStates({ popOverLoader: true }));
	try {
		const response = await apiCall({
			url: window.resolveRegion(process.env.REACT_APP_SMART_DASHBOARDS_BASE_URL) + "/smart-task/task",
			method: "POST",
			payload: request,
		});
		if (response?.status === 200) {
			dispatch(setSmartTaskStates({disableSort: true}))
			dispatch(setSmartTaskStates({ popOverLoader: false, successMsg: "Saved" }));
			setTimeout(() => {
				dispatch(fetchTaskWithCategories(getSmartTaskStates()?.activeTaskToggleView));
				// dispatch(reloadAllTables()) ;
			}, 4000)
		}
		return 'Success'
	} catch (error) {
		dispatch({
			type: skTaskActions?.SET_IS_ERROR,
			payload: { isError: true, errorMessage: error?.response?.data?.message, popOverLoader: false }
		})
		dispatch(setSmartTaskStates({ popOverLoader: false }));
		setTimeout(() => {
			dispatch(setSmartTaskStates({ errorMessage: '', isError: false }))
		}, 2000)
	}
}

// on edit task
export const editTask = (data, categoryId, isComplete, makeApiCall) => async dispatch => {
	let request = { orgId: HWUser.orgId(), appId: HWUser.appId(), userId: HWUser.userId(), ...data , dueDate : data.dueDate ? data.dueDate: data?.taskDueDate };
	dispatch(setSmartTaskStates({ popOverLoader: true }));
	try {
		const response = await apiCall({
			url: window.resolveRegion(process.env.REACT_APP_SMART_DASHBOARDS_BASE_URL) + "/smart-task/task",
			method: "PUT",
			payload: request,
			addFilter: true
		});
		if (response?.status === 200) {
			// dispatch(setSmartTaskStates({disableSort: true}))	// Commenting this code and moving it in if block of task-home API call (in the code block of the dispatch function "fetchSmartTasksList"). Doing this because this 'disableSort is being used only when "task-home" API is being called and setting 'disableSort' to true unconditionally is causing task-home to fail.
			if(isComplete){
				dispatch(setSmartTaskStates({ successMsg: "Edited" }));
			}
			else{
				dispatch(setSmartTaskStates({ popOverLoader: false, successMsg: "Edited" }));
			}
			let activeTaskToggleView = getSmartTaskStates()?.activeTaskToggleView ;
			let oldTask =  getSmartTask(categoryId ? categoryId :  data?.categoryId , data?.taskId) ;
			let newTask = response?.data;
			if(isComplete || makeApiCall){
				dispatch(setSmartTaskStates({disableSort: true}))		// Setting disableSort to true so that when "task-home" API call is made, the new task is at the top of the list.
				let smartTaskCategoryList = getSmartTaskStates()?.smartTaskCategoryList ;
				let index = smartTaskCategoryList.findIndex(item => item?.categoryId === categoryId);
				let sortList = smartTaskCategoryList[index]?.sortList;
				let sortMode = smartTaskCategoryList[index]?.sortMode;
				let resetTable = true;
				setTimeout(()=> {
					dispatch(fetchSmartTasksList(activeTaskToggleView, categoryId, null , sortList, sortMode , null,  resetTable,null, null, true));
				
				}, 2500)
			}
			else if ((activeTaskToggleView?.toLowerCase()=== 'timeline'?.toLowerCase() && isDateEqual(oldTask?.taskDueDate, newTask?.taskDueDate)) || (activeTaskToggleView?.toLowerCase() === 'Outcome'?.toLowerCase() && oldTask?.outcome?.id === newTask?.outcome?.id) || activeTaskToggleView?.toLowerCase() === 'List'?.toLowerCase()) {
				dispatch({
					type: skTaskActions?.EDIT_SMART_TASK,
					payload: {
						categoryId: categoryId ? categoryId :  data?.categoryId,
						popOverLoader : false ,
						data : { 
							...response?.data , categoryId : categoryId ? categoryId : data?.categoryId , 
							path : data?.path, productAllIds : response?.data?.productIds, 
							outcomeId : response?.data?.outcome ? response.data.outcome?.id : '',
						}
					}
				})
				setTimeout(() => {
					dispatch({type : skTaskActions?.HIDE_SMART_LOADING , payload : {categoryId :  data?.categoryId}})
				}, 1000);
				// 
			}	
			else{
				// dispatch(reloadAllTables()) ;
				setTimeout(() => {
					dispatch({type : skTaskActions?.HIDE_SMART_LOADING , payload : {categoryId :  data?.categoryId}})
				}, 1000);
			}
			return "Success"
		}
	} catch (error) {	
		dispatch({type : skTaskActions?.HIDE_SMART_LOADING , payload : {categoryId :  data?.categoryId}})
		dispatch({
			type: skTaskActions?.SET_IS_ERROR,
			payload: { isError: true, errorMessage: error?.response?.data?.message, popOverLoader: false }
		})
		setTimeout(() => {
			dispatch(setSmartTaskStates({ errorMessage: '', isError: false }))
		}, 2000)
		return error?.response?.data?.message
	}
}

// on delete task

export const deleteTask = (data, taskToBeDeleted) => async dispatch => {
	let request = {}
	request.params = { appId: HWUser.appId(), userId: HWUser.userId(), ...data };
	dispatch(setSmartTaskStates({ popOverLoader: true }));
	try {
		const response = await apiCall({
			url: window.resolveRegion(process.env.REACT_APP_SMART_DASHBOARDS_BASE_URL) + "/smart-task/task",
			method: "DELETE",
			payload: request,
		});
		if (response?.status === 200) {
			 dispatch(setSmartTaskStates({disableSort: true}))
			dispatch(setSmartTaskStates({ popOverLoader: false, successMsg: 'Deleted' }));
		}
	} catch (error) {
		dispatch({
			type: skTaskActions?.SET_IS_ERROR,
			payload: { isError: true, errorMessage: error?.response?.data?.message, popOverLoader: false }
		})
		setTimeout(() => {
			dispatch(setSmartTaskStates({ errorMessage: '', isError: false }))
		}, 2000)
	}
}

// on On/Off notification task

export const subscribeUnsubscribeTask = (data, hideloader) => async dispatch => {
	let request = {}
	request = { appId: HWUser.appId(), userId: HWUser.userId() , orgId:HWUser.orgId(), ...data };
	!hideloader && dispatch(setSmartTaskStates({ isLoading: true }));
	try {
		const response = await apiCall({
			url: window.resolveRegion(process.env.REACT_APP_SMART_DASHBOARDS_BASE_URL) + "/email-notification-settings/unsubscribe",
			method: "PUT",
			payload: request,
		});
		if (response?.status === 200) {
			dispatch(setSmartTaskStates({ isLoading: false, successMsg: '' }));
			return 'Updated successfully.';
		}
	} catch (error) {
		dispatch({
			type: skTaskActions?.SET_IS_ERROR,
			payload: { isError: true, errorMessage: error?.response?.data?.message, isLoading: false }
		})
		setTimeout(() => {
			dispatch(setSmartTaskStates({ errorMessage: '', isError: false }))
		}, 2000)
		return error?.response?.data?.message
	}
}




export const fetchListViewTasks = (taskView, searchText,sortList) => async dispatch => {
	dispatch({
		type: skTaskActions?.FETCH_TIMELINE_OUTCOME_OPTIONS,
		payload: {
			smartTaskCategoryList: [{categoryId:'List'}],
			category: '',
			taskList: null,
			nextToken: '',
			currentTaskView : taskView,
			tableView:taskView === "timeline"?"Timeline":taskView,
		}
	})
	dispatch(reloadAllTables(searchText,sortList, true)) ;
}

const isDateEqual = (oldDate , newDate)=> {
	const date1 = moment(oldDate);
	const date2 = moment(newDate);
	return date1.isSame(date2 , 'day') ;
}


export const performUndoAction  = ( msg,dataforUndo,  toasterUndoBtnClick, toasterUndoCrossClick)=> async dispatch => {

	// console.log("aaaaa=============", msg,dataforUndo,  toasterUndoBtnClick, toasterUndoCrossClick);
	dispatch(setGlobalStates({
		showUndoToaster: true,
		showUndoToasterMessage: msg,
		previousDataForUndo: dataforUndo,
		toasterUndoBtnClick: toasterUndoBtnClick,
		toasterUndoCrossClick:toasterUndoCrossClick
	}))
	setTimeout(() => {
		dispatch(setGlobalStates({ showUndoToaster: false, showUndoToasterMessage: null, previousDataForUndo: null }))
	}, 10000);

}

export const sendDependentTaskReminder = (req) => async dispatch => {
	let request = {}
	request = { appId: HWUser.appId(), userId: HWUser.userId() , orgId:HWUser.orgId(), ...req };
	try {
		const response = await apiCall({
			url: window.resolveRegion(process.env.REACT_APP_SMART_DASHBOARDS_BASE_URL) + "/smart-task/task/reminder",
			method: "POST",
			payload: request,
		});
		if (response?.status === 200) {
			dispatch(setGlobalStates({ showNotification: true, notificationMsg: 'Reminder sent.' }));
			setTimeout(() => {
				dispatch(setGlobalStates({ showNotification: false, notificationMsg: null}));
			}, 5000)
		}
	} catch (error) {
		console.log(error?.response?.data?.message);
	}
}

