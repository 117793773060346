import  React, { useState, useEffect, useCallback, memo }  from "react";
import { Route, Routes, Navigate, } from "react-router-dom";
import Sidebar from "../SmartNavbar/SideBar";
import dashboardRoutes from "../Routes/DashboardRoutes";
import SkNavBar from "../SmartNavbar/SkNavBar";
import { useDispatch } from "react-redux";
import { setSmartPlayBookStates } from "../store/reducers/skPlaybooksReducer";

const Dashboard = () => {
    const [open, setOpen] = useState(true);
    const [menuName, setMenuName] = useState(null);
    const [showOverlay, setShowOverlay] = useState(false);
    const [updateAlert, setUpdateAlert] = useState(false);
		const dispatch = useDispatch();

    useEffect(() => {
        const receivedMessage = (event) => {
            if (event && event.data === "updateAlertFeed") {
                setUpdateAlert(true);
            }
        };

        window.addEventListener('message', receivedMessage, false);

        return () => {
            window.removeEventListener('message', receivedMessage, false);
						dispatch(setSmartPlayBookStates({ disableGettingStartedTab: false}))
        };
    }, []);

    const updateAlertHandler = useCallback(() => {
        setUpdateAlert(true);
    }, []);

    const updateOverlayStatus = useCallback((status) => {
        setShowOverlay(status);
    }, []);

    const toggleOpen = useCallback(() => {
        setOpen(prevOpen => !prevOpen);
        setShowOverlay(false);
    }, []);

    const setOpenHandler = useCallback((state) => {
        setOpen(state);
        setShowOverlay(false);
    }, []);

    const onClickNavMenu = useCallback((name) => {
        if (name === "SmartOps") {
            setMenuName(name + " (Proactive Intelligence)");
        } else {
            setMenuName(name);
        }
    }, []);

    const actionsDashboard = {
        updateAlert: updateAlertHandler,
        updateOverlayStatus,
        toggleOpen,
        setOpen: setOpenHandler,
        onClickNavMenu,
    };


    return (
        <div className="mainView container-fluid sk_smart__main_dashborad_conatiner">
            <div className="row main_row">
                <Sidebar actionsDashboard={actionsDashboard} open={open || showOverlay} />
                <main id="main-content-body" className={open ? "openNav main-content" : "main-content"}>
                    <div className="h-100 w-100">
                        <SkNavBar menuName={menuName || 'Settings'} />
												<Routes>
								{dashboardRoutes().filter(prop => prop.isEnabled).map((prop, key) => {
									return prop.redirect ?
										<Route key={key} path={prop.path + "route_path"} element={<Navigate key={prop.path + "-dashboard-nav"} from={prop.path} to={prop.pathTo} />}></Route> :
										<Route key={key} path={prop.path} element={prop.component}></Route>
								})}
							</Routes>
                    </div>
                </main>
            </div>
        </div>
    );
};

export default memo(Dashboard);
