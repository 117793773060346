import axios from 'axios';
import HWUser from './HWLibrary/HWUser';
import hwAxios, {  getCurrentSmartDefaultFilterDataBase64Enncoded } from "./HWLibrary/HWAxios";
import cache from './SkSmartUtil/InMemoryCache';

class SkApiService {

	// constructor() {
	// 	this.servers = [
	// 		'https://cs-labs1.smartkarrot.com',
	// 		'https://cs-labs2.smartkarrot.com',
	// 		'https://cs-labs3.smartkarrot.com',
	// 		'https://cs-labs4.smartkarrot.com',
	// 	];

	// 	this.originalBaseURL = 'https://cs-labs.smartkarrot.com';
		
	// 	this.currentServerIndex = 0;	
	// }

	// getNextServer = () => {
	// 	const server = this.servers[this.currentServerIndex];
	// 	this.currentServerIndex = (this.currentServerIndex + 1) % this.servers.length;
	// 	return server;
	// };


	fetchRoles = () => {
		let url = window.resolveRegion(process.env.REACT_APP_SMART_DASHBOARDS_BASE_URL) + "/rbac/reference-data/roles";
		let aConfig = { headers: { "Authorization": HWUser.accessToken(), "Content-Type": "application/json" }, params: { appId: HWUser.appId(), orgId: HWUser.orgId(), userId: HWUser.userId() } };
		return axios.get(url, aConfig);
	}
	fetchAccountProductMapping = (searchTxt, nextToken, roleId,data) => {
		let url = window.resolveRegion(process.env.REACT_APP_SMART_DASHBOARDS_BASE_URL) + "/rbac/account-product-mapping";
		let aConfig = {
			headers: { "Authorization": HWUser.accessToken(), "Content-Type": "application/json" }, 
			params: {
				appId: HWUser.appId(),
				orgId: HWUser.orgId(),
				userId: HWUser.userId(),
				searchForText: searchTxt,
				nextToken: nextToken,
				smartFilterToken: getCurrentSmartDefaultFilterDataBase64Enncoded(null, "account_management"),
				noMappingForRoleId: roleId,
				...data
			}
		};
		return axios.get(url, aConfig);
	}

	fetchUsers = (searchTxt, forceInvalidate) => {
		const cacheKey = HWUser.userId();
		const fetchFunction = () => {
			let url = window.resolveRegion(process.env.REACT_APP_SMART_DASHBOARDS_BASE_URL) + "/rbac/reference-data/users";
			let aConfig = { headers: { "Authorization": HWUser.accessToken(), "Content-Type": "application/json" }, params: { appId: HWUser.appId(), orgId: HWUser.orgId(), userId: HWUser.userId(), searchForText: searchTxt } };
			return axios.get(url, aConfig);
		}
		return cache.get(cacheKey, cache.CacheModule.RbacUsers, fetchFunction, forceInvalidate);			// 4th parameter passing to make API call without checking for cached data
	}

	fetchExternalUsers = (searchTxt, forceInvalidate) => {
		const cacheKey = HWUser.userId();
		const fetchFunction = () => {
			let url = window.resolveRegion(process.env.REACT_APP_SMART_DASHBOARDS_BASE_URL) + "/external-users";
			let aConfig = { headers: { "Authorization": HWUser.accessToken(), "Content-Type": "application/json" }, params: { appId: HWUser.appId(), orgId: HWUser.orgId(), userId: HWUser.userId(), searchForText: searchTxt } };
			return axios.get(url, aConfig);
		}
		return cache.get(cacheKey, cache.CacheModule.externalUsers, fetchFunction, forceInvalidate);		// 4th parameter passing to make API call without checking for cached data
	}

	// Fetch Asana users. Sample output is as below. The list of users is unordered.
	fetchAsanaUsers = () => {
		let aConfig =  { headers: { "Authorization": HWUser.accessToken(), "Content-Type": "application/json" } };   // Shallow copy.
		aConfig["params"] = { opt_fields: "name,photo", workspace: this.workspaceId };
		return axios.get(this.taskAPIBaseURL + "/users", aConfig);
	}
	fetchJiraUsers = (id) => {
		let aConfig =  { headers: { "Authorization": HWUser.accessToken(), "Content-Type": "application/json" } };   // Shallow copy.
		return axios.get("https://api.atlassian.com/ex/jira/" + this.workspaceId + "/rest/api/3/user/assignable/search?project=" + id, aConfig);
	}
	updateAccountProductMapping = (data) => {
		let url = window.resolveRegion(process.env.REACT_APP_SMART_DASHBOARDS_BASE_URL) + "/rbac/account-product-mapping";
		let aConfig = { headers: { "Authorization": HWUser.accessToken(), "Content-Type": "application/json" } };
		return axios.post(url, data, aConfig);
	}
	fetchRoleCount = (roleId) => {
		let url = window.resolveRegion(process.env.REACT_APP_SMART_DASHBOARDS_BASE_URL) + "/rbac/no-mapping";
		let aConfig = { headers: { "Authorization": HWUser.accessToken(), "Content-Type": "application/json" }, params: { appId: HWUser.appId(), orgId: HWUser.orgId(), userId: HWUser.userId(), roleId: roleId, smartFilterToken: getCurrentSmartDefaultFilterDataBase64Enncoded(null, "account_management") } };
		return axios.get(url, aConfig);
	}

	fetchUserPreference = () => {
		let url = window.resolveRegion(process.env.REACT_APP_DASHBOARDS_BASE_API_URL) + "/platform-setting/user-preference";
		let aConfig = { headers: { "Authorization": HWUser.accessToken(), "Content-Type": "application/json", "addFilter": true }, params: { appId: HWUser.appId(), orgId: HWUser.orgId(), userId: HWUser.userId() } };
		return hwAxios.get(url, aConfig);
	}
	updateUserRbacRoleColumnPreference = (data) => {
		let url = window.resolveRegion(process.env.REACT_APP_SMART_DASHBOARDS_BASE_URL) + "/rbac/role-column-preference";
		let aConfig = { headers: { "Authorization": HWUser.accessToken(), "Content-Type": "application/json" } };
		return axios.put(url, data, aConfig);
	}
	fetchBannerStatus = () => {
		let url = window.resolveRegion(process.env.REACT_APP_SMART_DASHBOARDS_BASE_API_URL) + "/premium/banner-notification";
		let aConfig = { headers: { "Content-Type": "application/json" } };
		aConfig.params = { userId: HWUser.userId(), orgId: HWUser.orgId(), timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone };
		return axios.get(url, aConfig);
	}
	setBannerStatus = (data) => {
		let url = window.resolveRegion(process.env.REACT_APP_SMART_DASHBOARDS_BASE_API_URL) + "/premium/banner";
		let aConfig = { headers: { "Authorization": HWUser.accessToken(), "Content-Type": "application/json" } };
		return axios.post(url, data, aConfig);
	}
	// TODO: Put back on complete migraton from old platform to phoenix.
	lowBandWidthLogs = (request) => {
		let url = window.resolveRegion(process.env.REACT_APP_SMART_DASHBOARDS_BASE_API_URL) + "/low-bandwidth-logs";
		let aConfig    = { headers: { "Authorization": HWUser.accessToken(), "Content-Type": "application/json" } };
		return axios.post(url,request,aConfig);
	}
	updatePassword = (data) => {
		let aConfig = {
			headers: {
				Authorization: HWUser.accessToken(),
				'Content-Type': 'application/json',
			},
		};
		let url = window.resolveRegion(process.env.REACT_APP_PLATFORM_BASE_API_URL_WEB_ADMIN) + '/webapp/updatePassword-region';
		return axios.post(url, data, aConfig);
	}
	forgotPassword = (data) => {
		let aConfig = {
			headers: {
				Authorization: HWUser.accessToken(),
				'Content-Type': 'application/json',
			},
		};
		let url = window.resolveRegion(process.env.REACT_APP_PLATFORM_BASE_API_URL_WEB_ADMIN) + '/webapp/forgotpassword-region';
		return axios.post(url, data, aConfig);
	}
	login = (data) => {
		let aConfig = { headers: { Authorization: HWUser.accessToken(), 'Content-Type': 'application/json', }, };
		let url = window.resolveRegion(process.env.REACT_APP_PLATFORM_BASE_API_URL_WEB_ADMIN) + '/webapp/signin-region';
		return axios.post(url, data, aConfig);
	}
	signup = (data) => {
		let aConfig = {
			headers: {
				Authorization: HWUser.accessToken(),
				'Content-Type': 'application/json',
			},
		};

		let url = window.resolveRegion(process.env.REACT_APP_PLATFORM_BASE_API_URL_WEB_ADMIN) + '/webapp/signup';
		return axios.post(url, data, aConfig);
	};
	getCustomerAccountList = () => {
		let url = window.resolveRegion(process.env.REACT_APP_DASHBOARDS_BASE_API_URL) + "/reference-data/customer-account";
		let aConfig = { headers: { "Authorization": HWUser.accessToken(), "Content-Type": "application/json" } };
		aConfig.params = { userId: HWUser.userId(), appId: HWUser.appId(), orgId: HWUser.orgId() }
		return axios.get(url, aConfig);
	}
	getPlatformUserList = (accountId) => {
		accountId = accountId ? { accountId: accountId } : {}
		let url = window.resolveRegion(process.env.REACT_APP_DASHBOARDS_BASE_API_URL) + "/reference-data/platform-user";
		let aConfig = { headers: { "Authorization": HWUser.accessToken(), "Content-Type": "application/json" } };
		aConfig.params = { userId: HWUser.userId(), appId: HWUser.appId(), orgId: HWUser.orgId(), ...accountId }
		return axios.get(url, aConfig);
	}
	getSuccessPlayInstances = (request) => {
		let url = window.resolveRegion(process.env.REACT_APP_DASHBOARDS_BASE_API_URL) + "/success-play/instance/dashboard"
		let aConfig = { headers: { "Authorization": HWUser.accessToken(), "Content-Type": "application/json" } };
		aConfig.params = { appId: request.appId ? request.appId : HWUser.appId(), userId: request.userId ? request.userId : HWUser.userId() }
		return axios.get(url, aConfig)
	}

	serachAccountsOptionsForGlobalFilter = (request) => {
		if(request){
			let url = window.resolveRegion(process.env.REACT_APP_SMART_DASHBOARDS_BASE_API_URL) + "/account/search";
			let aConfig = { headers: { "Authorization": HWUser.accessToken(), "Content-Type": "application/json", "defaultFilter": true } };
			aConfig.params = {
				orgId: HWUser.orgId(),
				appId: HWUser.appId(),
				userId: HWUser.userId(),
				...request
			}
			return hwAxios.get(url, aConfig);
		}else{
			const cacheKey = HWUser.userId();
			let token = {
				userId: HWUser.userId(),
				orgId: HWUser.orgId(),
				appId: HWUser.appId(),
				"accountFilter": {
					"accountIds": [],
					"allFilters": ["AllAccounts"],
					accountSelection: "Manual"
				},
				"productFilter": {
					"productIds": [],
					"allFilters": ["AllProducts"]
				}
			}
			token = JSON.stringify((token))
			token = btoa(token.replace(/[\u00A0-\u2666]/g, function (c) {
				return '&#' + c.charCodeAt(0) + ';';
			}));
			const fetchFunction = () => {
				let url = window.resolveRegion(process.env.REACT_APP_SMART_DASHBOARDS_BASE_API_URL) + "/account/search";
				let aConfig = { headers: { "Authorization": HWUser.accessToken(), "Content-Type": "application/json" } };
				aConfig.params = {
					orgId: HWUser.orgId(),
					appId: HWUser.appId(),
					userId: HWUser.userId(),
					smartFilterToken: token,
				}
				return axios.get(url, aConfig);
			}
			return cache.get(cacheKey, cache.CacheModule.globalfilterAccounts, fetchFunction);
		}
	
	}
	fetchKaffeineAndKarrotData = (nextToken, filter, sortBy, postId) => {
		let url = window.resolveRegion(process.env.REACT_APP_SMART_DASHBOARDS_BASE_API_URL) + '/k-and-k/posts/scaled'
		let aConfig = {
			headers: {
				Authorization: HWUser.accessToken(),
				'Content-Type': 'application/json',
			},
		};
		aConfig.params = {
			appId: HWUser.appId(),
			userId: HWUser.userId(),
			nextToken: nextToken ? nextToken : null,
			scope: filter ? filter : null,
			sortBy: sortBy ? sortBy : null,
			postId: postId
		};
		return axios.get(url, aConfig);
	}
	fetchKaffeineAndKarrotSinglePost = (postId) => {
		let url = window.resolveRegion(process.env.REACT_APP_SMART_DASHBOARDS_BASE_API_URL) + `/k-and-k/posts/${postId}`
		let aConfig = {
			headers: {
				Authorization: HWUser.accessToken(),
				'Content-Type': 'application/json',
			},
		};
		aConfig.params = {
			appId: HWUser.appId(),
			userId: HWUser.userId()
		};
		return axios.get(url, aConfig);
	}
	postKnKComment(data, postId, attachments) {
		let url = window.resolveRegion(process.env.REACT_APP_SMART_DASHBOARDS_BASE_API_URL) + `/k-and-k/posts/${postId}/comment`
		let aConfig = { headers: { "Authorization": HWUser.accessToken(), "Content-Type": "application/json" } };
		data = { appId: HWUser.appId(), userId: HWUser.userId(), orgId: HWUser.orgId(), ...data, attachments: attachments }
		return axios.post(url, data, aConfig)
	}
	fetchComments(postId, nextToken, commentId) {
		let url = window.resolveRegion(process.env.REACT_APP_SMART_DASHBOARDS_BASE_API_URL) + `/k-and-k/posts/${postId}/comment`
		let aConfig = { headers: { "Authorization": HWUser.accessToken(), "Content-Type": "application/json" } };
		aConfig.params = {
			appId: HWUser.appId(),
			userId: HWUser.userId(),
			nextToken: nextToken ? nextToken : null,
			commentId: commentId
		};
		return axios.get(url, aConfig);
	}
	deleteKnKComment = (postId, commentId) => {
		let url = window.resolveRegion(process.env.REACT_APP_SMART_DASHBOARDS_BASE_API_URL) + `/k-and-k/posts/${postId}/comment`;
		let aConfig = { headers: { "Authorization": HWUser.accessToken(), "Content-Type": "application/json" } };
		aConfig.params = { appId: HWUser.appId(), userId: HWUser.userId(), commentId: commentId }
		return axios.delete(url, aConfig)
	}
	knkEditComment = (postId, commentId, commentBody, attachments, taggedUsers) => {
		let url = window.resolveRegion(process.env.REACT_APP_SMART_DASHBOARDS_BASE_API_URL) + `/k-and-k/posts/${postId}/comment`;
		let aConfig = { headers: { "Authorization": HWUser.accessToken(), "Content-Type": "application/json" } };
		let data = { userId: HWUser.userId(), appId: HWUser.appId(), commentId: commentId, commentBody: commentBody, attachments: attachments, taggedUsers: taggedUsers }
		return axios.put(url, data, aConfig)
	}

	deleteKnKPost = (postId) => {
		let url = window.resolveRegion(process.env.REACT_APP_SMART_DASHBOARDS_BASE_API_URL) + `/k-and-k/posts/${postId}`;
		let aConfig = { headers: { "Authorization": HWUser.accessToken(), "Accept": "*/*", "Content-Type": "application/json" } };
		aConfig.params = { appId: HWUser.appId(), userId: HWUser.userId() }
		return axios.delete(url, aConfig)
	}
	knKCommentLikeUnlike(data) {
		let url = window.resolveRegion(process.env.REACT_APP_SMART_DASHBOARDS_BASE_API_URL) + `/k-and-k/posts/${data?.postId}/comment/like`
		let aConfig = { headers: { "Authorization": HWUser.accessToken(), "Content-Type": "application/json" } };
		data = { appId: HWUser.appId(), userId: HWUser.userId(), ...data }
		return axios.post(url, data, aConfig)
	}

	knKPostLikeUnlike(data, postId) {
		let url = window.resolveRegion(process.env.REACT_APP_SMART_DASHBOARDS_BASE_API_URL) + `/k-and-k/posts/${postId}/like`
		let aConfig = { headers: { "Authorization": HWUser.accessToken(), "Content-Type": "application/json" } };

		data = { userId: HWUser.userId(), appId: HWUser.appId(), ...data }
		return axios.post(url, data, aConfig)
	}
	knKPostUnlike(data, postId) {
		let url = window.resolveRegion(process.env.REACT_APP_SMART_DASHBOARDS_BASE_API_URL) + `/k-and-k/posts/${postId}/like`
		let aConfig = { headers: { "Authorization": HWUser.accessToken(), "Content-Type": "application/json" } };
		aConfig.params = { appId: HWUser.appId(), userId: HWUser.userId(), ...data }
		return axios.delete(url, aConfig)
	}
	knkCreatePost = (data) => {
		let url = window.resolveRegion(process.env.REACT_APP_SMART_DASHBOARDS_BASE_API_URL) + `/k-and-k/posts/create`
		let aConfig = { headers: { "Authorization": HWUser.accessToken(), "Content-Type": "application/json" } };
		data = {userId:HWUser.userId(),appId:HWUser.appId(),...data}
		return axios.post(url, data, aConfig) 
}

knkEditPost = (data,postId) => {
	let url = window.resolveRegion(process.env.REACT_APP_SMART_DASHBOARDS_BASE_API_URL) + `/k-and-k/posts/${postId}`
	let aConfig = { headers: { "Authorization": HWUser.accessToken(), "Content-Type": "application/json" } };
	data = {userId:HWUser.userId(),appId:HWUser.appId(),...data}
	return axios.put(url, data, aConfig) 
}
fetchHeatMapData(data,sortBy) {
	const cacheKey      = HWUser.userId() ;
	const fetchFunction = () => {
		let url = window.resolveRegion(process.env.REACT_APP_DASHBOARDS_BASE_API_URL) + "/csm/heat-map";
		let aConfig = { headers: { "addFilter": true,"Authorization": HWUser.accessToken(), "Content-Type": "application/json" } };
		aConfig.params = {
				userId: HWUser.userId(), 
				appId: HWUser.appId(), 
				orgId:HWUser.orgId(), 
				filterUserId: data.filterUserId , 
				customerAccountId: data.customerAccountId ,
				persona:data.persona? data.persona :null,
				platform:data.platform? data.platform : null,
				portfolioFilter: data.portfolioFilter,
				sortBy:sortBy   
		}
		return hwAxios.get(url, aConfig)
	}
	return cache.get(cacheKey, cache.CacheModule.heatMapData, fetchFunction);
}

fetchProductHeatMapUserxl(type,customerAccountId,filterUserId,portfolioFilter){
	let url = window.resolveRegion(process.env.REACT_APP_DASHBOARDS_BASE_API_URL) + "/csm/heat-map/userxl";
	return axios.get(url,{ params: 
			{customerAccountId: customerAccountId, filterUserId: filterUserId, portfolioFilter: portfolioFilter, userId:HWUser.userId(),appId:HWUser.appId(),heatMapExportType:type}}).then(response => {return response.data})
}
saveSegments = (requestData) => {
	let url = window.resolveRegion(process.env.REACT_APP_DASHBOARDS_BASE_API_URL) + "/account-segment/";
	let aConfig = { headers: { "Authorization": HWUser.accessToken(), "Content-Type": "application/json" } };
	requestData = { ...requestData, userId: HWUser.userId(), appId: HWUser.appId() }
	return axios.post(url, requestData, aConfig)
}

getUserSegmentList=(request)=>{
	let url = window.resolveRegion(process.env.REACT_APP_SMART_DASHBOARDS_BASE_API_URL)+"/csm/fetch-heat-map-users";
	let aConfig = { headers: { "Authorization": HWUser.accessToken(), "Content-Type": "application/json","addFilter": true } };
	//aConfig.params = { userId: HWUser.userId(), appId: HWUser.appId() }
	request = { 
			...request , 
			userId: HWUser.userId(), 
			appId: HWUser.appId()
	}
	return hwAxios.post(url,request,aConfig)
}
exportDurationUserstList =(date,userIds)=>{
	let url = window.resolveRegion(process.env.REACT_APP_SMART_DASHBOARDS_BASE_API_URL)+"/csm/duration/fetch-impacted-users/export";
	let aConfig = { headers: { "Authorization": HWUser.accessToken(), "Content-Type": "application/json","addFilter": true } };
	aConfig.params = { 
			date:date, 
			userId: HWUser.userId(), 
			appId: HWUser.appId(),
			orgId:HWUser.orgId(),
			userIds:userIds ?Array.isArray(userIds) && userIds.join(','):null,
	}
	return hwAxios.get(url,aConfig)
}
    
exportUserSegment(request){
	let url = window.resolveRegion(process.env.REACT_APP_SMART_DASHBOARDS_BASE_API_URL) + "/csm/user-export"
	let aConfig = { headers: { "Authorization": HWUser.accessToken(), "Content-Type": "application/json", "addFilter": true} };
	aConfig.params = {appId: HWUser.appId(),userId:HWUser.userId(), ...request  }
	return hwAxios.get(url,aConfig)
}
getDurationUserstList=(date,searchText,userIds)=>{
	let url = window.resolveRegion(process.env.REACT_APP_SMART_DASHBOARDS_BASE_API_URL)+"/csm/duration/fetch-impacted-users";
	let aConfig = { headers: { "Authorization": HWUser.accessToken(), "Content-Type": "application/json","addFilter": true } };
	aConfig.params = { 
			searchText:searchText,
			date:date, 
			userId: HWUser.userId(), 
			appId: HWUser.appId(),
			orgId:HWUser.orgId(),
			userIds:userIds ?Array.isArray(userIds) && userIds.join(','):null,
	}
	return hwAxios.get(url,aConfig)
}
   // Get Ticket with emailId(requester) for Customer Support from Jira
	 fetchAllTicketsWithEmailIdForJira =(nextPage) =>{
		let emailId = HWUser.user() && HWUser.user().emailId
		let aConfig =  { headers: { "Authorization": HWUser.accessToken(), "Content-Type": "application/json" } };
		aConfig.params = {email:emailId,nextPage:nextPage}
		return axios.get(this.url_sk+"/jira-issue?include=description,requester", aConfig);
}
createTicketForJira(data) {
	let uri = window.resolveRegion(process.env.REACT_APP_SMART_DASHBOARDS_BASE_API_URL) + "/jira-issue";
	let aConfig =  { headers: { "Authorization": HWUser.accessToken(), "Content-Type": "application/json" } };
	return axios.post(uri, data, aConfig);
}

fetchTicketForJira(data){
	let uri = window.resolveRegion(process.env.REACT_APP_SMART_DASHBOARDS_BASE_API_URL) + "/jira-issue";
	let aConfig =  { headers: { "Authorization": HWUser.accessToken(), "Content-Type": "application/json" }, params:data };
	console.log('###4567', data, uri)
	return axios.get(uri, aConfig);
}

ticketFields(){
	let aConfig =  { headers: { "Authorization": HWUser.accessToken(), "Content-Type": "application/json" } };
	return axios.get(this.url+"/ticket_fields?type=default_status", aConfig);
}
fetchPlatformUsers(id){
	let url = window.resolveRegion(process.env.REACT_APP_SMART_DASHBOARDS_BASE_API_URL) + "/cs-platform-user"
	let aConfig = { headers: { "Authorization": HWUser.accessToken(), "Content-Type": "application/json" } };
	aConfig.params = {appId: HWUser.appId(),userId:HWUser.userId(),orgId:HWUser.orgId(),customerAccountId:id}
	return axios.get(url,aConfig)
}
sendOTP = (data) => {
	return axios.post(window.resolveRegion(process.env.REACT_APP_BASE_API_URL_AUTH)+"/sendverifyphoneotp", data, {headers: this.headers})
}

verifyOTP = (data) => {
	return axios.post(window.resolveRegion(process.env.REACT_APP_BASE_API_URL_AUTH)+"/verifyphone", data, {headers: this.headers})
}

updateprofile = (data)=>{
	return axios.post(window.resolveRegion(process.env.REACT_APP_BASE_API_URL_AUTH)+"/updateprofile", data, {headers: this.headers})
}

updatepassword = (data) => {
	return axios.post(window.resolveRegion(process.env.REACT_APP_BASE_API_URL_AUTH)+"/updatepassword", data, {headers: this.headers})
}
parseReactMentionsToHTMLText = (description) => {
	if (!description) { return description; }
	const regex = /@\[([^\]]+?)\]\(([^)]+?)\)/g;
	for (const match of description.matchAll(regex)) { description = description.replace(match[0], "<span class=\"user-mention-highlight\">@"+match[1]+"</span>"); }
	return description;
}
	otpUtilApi = (request) => {
		let url = window.resolveRegion(process.env.REACT_APP_PLATFORM_BASE_API_URL_WEB_ADMIN)+"/webapp/playbook-ss/verify-otp";
		let aConfig = { headers: { "Content-Type": "application/json" } };
		request = { 
				...request , 
		// 		userId: HWUser.userId(), 
		// 		appId: HWUser.appId()
		}
		return hwAxios.post(url,request,aConfig)
	}
}
var skApiService = new SkApiService();
export default skApiService;