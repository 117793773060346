import { skCustomerPortalActions } from "../constants/actionType";

const initialState = {
	referenceUsersListForCustomerPortal: null,
	isCustomerPortalLoading: false,

}


export const skCustomerPortalActionReducer = (state = initialState, { type , payload }= {}) => {
	switch (type) {
		case skCustomerPortalActions.SET_SMART_CUSTOMER_PORTAL_STATES:
		case skCustomerPortalActions.FETCH_CUSTOMER_PORTAL_USERS:  { return { ...state, ...payload } }
    default: return state; 
  }
}


export default skCustomerPortalActionReducer 