import React, { useEffect,useRef } from 'react'
import { useState } from 'react';
import './SkCheckBoxBtn.css'
import SKThreeDotMenu from '../SKThreeDotMenu/SKThreeDotMenu';
import { Tooltip, makeStyles } from '@material-ui/core';

const SkCheckBoxBtn = ({label, id, isChecked , isDisabled , className, onChange ,poperClassName, showMenu, customMenuOptions, showCustomOption, ...props}) => {
  const [unqId, setUnqId] = useState(Math.floor(Math.random() * 100));
  const [checked, setChecked] = useState(isChecked ? isChecked : false);
	const[labe1,setlabe1] = useState(null);

  const onCheckHandler = (e, key) => {
    if (!isDisabled) {
			let chkStatus = !checked;
			onChange && onChange(chkStatus, key);
		}
  }
	useEffect(() =>{
		setChecked(isChecked);
	},[isChecked])

	const addBtnElement = useRef();
	const useStyles = makeStyles((theme) => ({
		customTooltip: {
			backgroundColor: 'white !important',
			color: 'var(--smart-insights-and-konversations-intelligence-heading-color)',
			fontSize: '14px',
			fontFamily: 'var(--sk-font-urbanist-family)',
			boxShadow: '0px 9px 30px var(--sk-graph-popper)',
			borderRadius: '11px',
			padding: '8px !important',
			minWidth: '50px !important',
			border: '1px solid var(--sk-smart-datepicker-border-color)',
			textAlign: 'center',
			fontWeight: '500',
			'& .MuiTooltip-arrow': {
				color: 'white !important'
			},
			'& .MuiTooltip-arrow:before': {
				border: '1px solid var(--sk-smart-datepicker-border-color)',
			}

		},
	}));
	const classes = useStyles();

  return (<>
    {!showCustomOption && <div onMouseOver={()=>{props?.onMouseOverLable && setlabe1(props?.onMouseOverLable)}} onMouseLeave={()=>{setlabe1(null)}}  className = {`sk__checkbox__btn-container sk__checkbox__btn-${checked?'checked' : 'unchecked'} ${className}`}  style={{cursor:isDisabled ? 'default': 'pointer'}} onClick={(e) => {e.stopPropagation(); onCheckHandler(e, props?.keyName) }}>
      <input className='sk__checkbox__btn' id={ id ? id  : `skCheck${unqId}`} type="checkbox" disabled={isDisabled} />
      <span className={`sk__checkbox__btn-checkmark sk__checkbox__btn-checkmark-${checked?'checked':'unchecked'} ${isDisabled?'disabled':''}`} >
      </span>
			{props.tooltipLable ? <Tooltip innerRef={addBtnElement} classes={{ tooltip: classes.customTooltip }} className='add__column__info__react__tooltip' title={props.tooltipLable} arrow placement="top">
				<span  ref={addBtnElement} className={`sk__checkbox__btn-label ${isDisabled?'disabled':''}`}>
				{labe1?labe1:label}
				</span>
			</Tooltip>:	<span  ref={addBtnElement}  className={`sk__checkbox__btn-label ${isDisabled?'disabled':''}`}>
				{labe1?labe1:label}
				</span>}
    </div>}
   { showCustomOption &&
		<SKThreeDotMenu dontPreventEventBubbling={false} placement={'top'}  className={`sk__checkbox_three__dot__menu  ${poperClassName}`} list={customMenuOptions()}>
	   <div className = {`sk__checkbox__btn-container sk__checkbox__btn-${checked?'checked' : 'unchecked'} ${className} ${isDisabled?'disabled':''}` } onClick={(e) => {onCheckHandler(e, props?.keyName) }}>
      <input className='sk__checkbox__btn' id={ id ? id  : `skCheck${unqId}`} type="checkbox" disabled={isDisabled} />
      <span className={`sk__checkbox__btn-checkmark sk__checkbox__btn-checkmark-${checked?'checked':'unchecked'} ${isDisabled?'disabled':''}`} >
      </span>
      <span className={`sk__checkbox__btn-label ${isDisabled?'disabled':''}`}>
      	{label}
      </span>
    </div>
	</SKThreeDotMenu>
	 }
  </>)
}

export default SkCheckBoxBtn