import { store } from "..";
import HWLocalStorage from "../../HWLibrary/HWLocalStorage";
import HWUser from "../../HWLibrary/HWUser";
import { apiCall } from "../api";
import { skMailActions } from "../constants/actionType";

const emailServiceBaseURL = window.resolveRegion(process.env.REACT_APP_DASHBOARDS_BASE_API_URL) + "/email";

export const getSmartMailState = () => {

	return store.getState()?.smartMailReducer;
}


export const setSmartMailState = (obj) => async dispatch => {
	dispatch({ type: skMailActions?.SET_SMART_MAIL_STATE, payload: obj });
};
export const setSmartMailLoadingDate = (obj) => async dispatch => {
	dispatch({ type: skMailActions?.SET_MAIL_LOADING, payload: obj });
};

export const fetchMails = (req, showSkeleton) => async dispatch => {
	let request = {}
	request.params = { appId: HWUser.appId(), userId: HWUser.userId(), orgId: HWUser.orgId(), showMappedMails: false, ...req };
	dispatch(setSmartMailState({ isLoading: true, showSkeleton: showSkeleton }))
	try {
		const response = await apiCall({
			url: emailServiceBaseURL + "/messages",
			method: "GET",
			payload: request,
			addFilter: true
		});
		let mails = response?.data?.mails
		if (req?.nextPage) {
			mails = [...getSmartMailState().mails, ...response?.data?.mails]
		}
		let data = {
			mails: mails,
			nextToken: response?.data?.nextPage,
			activeTab: req?.mailType,
			isLoading: false,
			showSkeleton: req?.mailType === getSmartMailState().activeTab ? false : showSkeleton
		}
		dispatch({
			type: skMailActions?.FETCH_MAILS_LIST,
			payload: data
		});

	} catch (error) {
		dispatch(setSmartMailState({ isLoading: false, showPreviewSkeleton: false, mails: [], }))
	}

};

export const forwardMail = (req, mailType) => async dispatch => {
	let request = { appId: HWUser.appId(), userId: HWUser.userId(), orgId: HWUser.orgId(), showMappedMails: false, ...req };
	dispatch(setSmartMailState({ isLoading: true }))
	try {
		const response = await apiCall({
			url: emailServiceBaseURL + "/" + mailType,
			method: "POST",
			payload: request,
		});
		dispatch(setSmartMailState({ isLoading: false }))
		return "Mail Sent Successfully";
	} catch (error) {
		dispatch(setSmartMailState({ isLoading: false }))
		return "Something Went Wrong";
	}
}

export const saveContactMapping = (mappingData) => async dispatch => {
	let request = { appId: HWUser.appId(), userId: HWUser.userId(), orgId: HWUser.orgId(), mappings: mappingData };
	dispatch(setSmartMailState({ isLoading: true }))
	try {
		const response = await apiCall({
			url: window.resolveRegion(process.env.REACT_APP_DASHBOARDS_BASE_API_URL) + "/contacts/map",
			method: "POST",
			payload: request,
		});
		dispatch(setSmartMailState({ isLoading: false }))
	} catch (error) {
		dispatch(setSmartMailState({ isLoading: false }))
	}
}

export const conversationThread = (req, showPreviewSkeleton) => async dispatch => {
	let request = {}
	request.params = { appId: HWUser.appId(), userId: HWUser.userId(), orgId: HWUser.orgId(), bodyContentType: "html", ...req };
	dispatch(setSmartMailState({ isLoading: true, showPreviewSkeleton: showPreviewSkeleton }))
	try {
		const response = await apiCall({
			url: emailServiceBaseURL + "/messages",
			method: "GET",
			payload: request,
			addFilter: false
		});
		let mails = response?.data?.mails
		dispatch(setSmartMailState({ messageThread: mails, isLoading: false, showPreviewSkeleton: false }));
		return mails;
	} catch (error) {
		dispatch(setSmartMailState({ isLoading: false, showPreviewSkeleton: false }))
	}
}

// PopUp Shown Status
export const gsuiteLocalStorageStatusKey = "gsuite-auth-pop-up-status";

export const markGsuitePopUpAsShown = () => async dispatch => {

	HWLocalStorage.save("SHOWN", gsuiteLocalStorageStatusKey);
}
export const clearGsuitePopUpAsShown = () => async dispatch => {
	HWLocalStorage.clear(gsuiteLocalStorageStatusKey);
}