import axios from 'axios'
import HWUser from '../../HWLibrary/HWUser';
import hwAxios from '../../HWLibrary/HWAxios';

class HealthScoreService {
    accessToken = null
    config = () => {
        return {
            headers: {
                "Authorization": "Bearer " + this.accessToken
            }
        }
    };

    constructor() {
        if (!HealthScoreService.instance) {
            HealthScoreService.instance = this
        }
        return HealthScoreService.instance
    }

    fetchHealthScore(request) {
        let config = {
            headers: {
                "Authorization": HWUser.accessToken(),
                "Content-Type": "application/json"
            }
        };
        let url = window.resolveRegion(process.env.REACT_APP_DASHBOARDS_BASE_API_URL) + "/csm/health-score-definition";
        config.params = {
            orgId: request.orgId
        }
        return axios.get(url, config);
    }

    fetchParameters(request) {
        let url = window.resolveRegion(process.env.REACT_APP_SMART_DASHBOARDS_BASE_API_URL) + "/platform-setting/health-score-definition/parameters";
        let config = {headers: { "Authorization": HWUser.accessToken(),"Content-Type": "application/json"}};
        config.params = {orgId: HWUser.orgId(),userId: HWUser.userId(),appId: HWUser.appId()}
        return axios.get(url, config);
    }

    saveParameters(request) {
        let url = window.resolveRegion(process.env.REACT_APP_SMART_DASHBOARDS_BASE_API_URL) + "/platform-setting/health-score-definition/parameters";
        let config = {headers: { "Authorization": HWUser.accessToken(),"Content-Type": "application/json"}};
        request = {orgId: HWUser.orgId(),userId: HWUser.userId(),appId: HWUser.appId(),parameters:request}
        return axios.post(url,request, config);
    }

    fetchProductAllocation(request) {
        let url = window.resolveRegion(process.env.REACT_APP_SMART_DASHBOARDS_BASE_API_URL) + "/platform-setting/health-score-definition/product-allocation";
        let config = {headers: { "Authorization": HWUser.accessToken(),"Content-Type": "application/json"}};
        config.params = {orgId: HWUser.orgId(),userId: HWUser.userId(),appId: HWUser.appId()}
        return axios.get(url, config);
    }

    saveProductAllocation(request) {
        let url = window.resolveRegion(process.env.REACT_APP_SMART_DASHBOARDS_BASE_API_URL) + "/platform-setting/health-score-definition/product-allocation";
        let config = {headers: { "Authorization": HWUser.accessToken(),"Content-Type": "application/json"}};
        request = {orgId: HWUser.orgId(),userId: HWUser.userId(),appId: HWUser.appId(),productAllocation:request}
        return axios.post(url,request, config);
    }


    fetchRatingScale(request) {
        let url = window.resolveRegion(process.env.REACT_APP_SMART_DASHBOARDS_BASE_API_URL) + "/platform-setting/health-score-definition/org-rating-scale";
        let config = {headers: { "Authorization": HWUser.accessToken(),"Content-Type": "application/json"}};
        config.params = {orgId: HWUser.orgId()/* ,userId: HWUser.userId(),appId: HWUser.appId() */}
        return axios.get(url, config);
    }

    saveRatingScale(request) {
        let url = window.resolveRegion(process.env.REACT_APP_SMART_DASHBOARDS_BASE_API_URL) + "/platform-setting/health-score-definition/org-rating-scale";
        let config = {headers: { "Authorization": HWUser.accessToken(),"Content-Type": "application/json"}};
        request = {orgId: HWUser.orgId(),...request}
        return axios.post(url,request, config);
    }

    saveTouchpointIndex(request) {
        let config = {
            headers: {
                "Authorization": HWUser.accessToken(),
                "Content-Type": "application/json"
            }
        };
        let url = window.resolveRegion(process.env.REACT_APP_DASHBOARDS_BASE_API_URL) + "/csm/health-score-definition/touchpoint-index";
        request = {
            orgId: HWUser.orgId(),
            touchpointParameters: request,
            appId: HWUser.appId()
        }
        return axios.post(url, request, config)
    }



    saveHealthScoreParameters(request) {
        let url = window.resolveRegion(process.env.REACT_APP_DASHBOARDS_BASE_API_URL) + "/csm/health-score-definition";
        // let url   = window.resolveRegion(process.env.REACT_APP_API_DOMAIN_NAME) + "/dashboards/v4/csm/health-score-definition";
        return axios.post(url, request)
    }

    fetchAccountDetails(request) {
        let config = {
            headers: {
                "Authorization": HWUser.accessToken(),
                "Content-Type": "application/json"
            }
        };
        let url = window.resolveRegion(process.env.REACT_APP_DASHBOARDS_BASE_API_URL) + "/platform-setting/account-details";
        config.params = {
            orgId: request.orgId
        }
        return axios.get(url, config);
    }

    uploadOrgImage(imageFile, uploadURL) {
        let config = {
            headers: {
                "content-type": imageFile.type
            }
        }
        return axios.put(uploadURL, imageFile, config);
    }

    saveOrgAccountDetails(request) {
        let config = {
            headers: {
                "Authorization": HWUser.accessToken(),
                "Content-Type": "application/json"
            }
        };
        let url = window.resolveRegion(process.env.REACT_APP_DASHBOARDS_BASE_API_URL) + "/platform-setting/account-details";
        return axios.post(url, request, config);
    }

    fetchEventMapping(request) {
        // console.log('data...eventfunc',request)
        let url = window.resolveRegion(process.env.REACT_APP_DASHBOARDS_BASE_API_URL) + "/platform-setting/events/mapping";
        let config = {
            headers: {
                "Authorization": HWUser.accessToken(),
                "Content-Type": "application/json",
                "addFilter": true,
                 "addOnlySelectedFilter": true
            }
        };
      
        config.params = {
            ...request
        }
        return hwAxios.get(url, config);
    }

    fetchEventMappingPatterns(request) {
        let url = window.resolveRegion(process.env.REACT_APP_DASHBOARDS_BASE_API_URL) + "/platform-setting/events/mapping/patterns";
        let config = {
            headers: {
                "Authorization": HWUser.accessToken(),
                "Content-Type": "application/json",
                "addFilter": true,
                 "addOnlySelectedFilter": true
            }
        };
        config.params = {
           // appId: request.appId
           ...request
        }
        return hwAxios.get(url, config);
    }

    updateEventPatternData = (request) => {
        let config = {
            headers: {
                "Authorization": HWUser.accessToken(),
                "Content-Type": "application/json"
            }
        };
        let url = window.resolveRegion(process.env.REACT_APP_DASHBOARDS_BASE_API_URL) + "/platform-setting/events/mapping/patterns"
        return axios.put(url, request, config);
    }

    addEventPatternData = (request) => {
        let config = {
            headers: {
                "Authorization": HWUser.accessToken(),
                "Content-Type": "application/json"
            }
        };
        let url = window.resolveRegion(process.env.REACT_APP_DASHBOARDS_BASE_API_URL) + "/platform-setting/events/mapping/patterns"
        return axios.post(url, request, config);
    }

    deleteEventPatternData = (request) => {
        let config = {
            headers: {
                "Authorization": HWUser.accessToken(),
                "Content-Type": "application/json"
            },
            params: request
        };
        let url = window.resolveRegion(process.env.REACT_APP_DASHBOARDS_BASE_API_URL) + "/platform-setting/events/mapping/patterns"
        return axios.delete(url, config);
    }

    fetchEventHierarchy(request) {
        let url = window.resolveRegion(process.env.REACT_APP_DASHBOARDS_BASE_API_URL) + "/platform-setting/events/hierarchy";

        let config = {
            headers: {
                "Authorization": HWUser.accessToken(),
                "Content-Type": "application/json",
                "addFilter": true,
                 "addOnlySelectedFilter": true
            }
        };
        config.params = {
           // appId: request.appId;
           ...request
        }
        return hwAxios.get(url, config);
    }

    createNewModule(request) {
        let url = window.resolveRegion(process.env.REACT_APP_DASHBOARDS_BASE_API_URL) + "/platform-setting/events/hierarchy/module";
        let aConfig = {
            headers: {
                Authorization: HWUser.accessToken(),
                'Content-Type': 'application/json',
            },
        };
        aConfig.params = request
        return axios.post(url, request, aConfig);
    }
    editModuleName(request) {
        let config = {
            headers: {
                "Authorization": HWUser.accessToken(),
                "Content-Type": "application/json"
            }
        };
        let url = window.resolveRegion(process.env.REACT_APP_DASHBOARDS_BASE_API_URL) + "/platform-setting/events/hierarchy/module"
        return axios.put(url, request, config);
    }
    deleteModule(request) {
        let url = window.resolveRegion(process.env.REACT_APP_DASHBOARDS_BASE_API_URL) + "/events/hierarchy/module";
        let aConfig = {
            headers: {
                "Authorization": HWUser.accessToken(),
                "Content-Type": "application/json"
            }
        };
        aConfig.params = request
        return axios.delete(url, aConfig)
    }

    createNewFeature(request) {
        let url = window.resolveRegion(process.env.REACT_APP_DASHBOARDS_BASE_API_URL) + "/platform-setting/events/hierarchy/feature";
        let aConfig = {
            headers: {
                Authorization: HWUser.accessToken(),
                'Content-Type': 'application/json',
            },
        };
        return axios.post(url, request, aConfig);
    }

    editFeatureName(request) {
        let config = {
            headers: {
                "Authorization": HWUser.accessToken(),
                "Content-Type": "application/json"
            }
        };
        let url = window.resolveRegion(process.env.REACT_APP_DASHBOARDS_BASE_API_URL) + "/platform-setting/events/hierarchy/feature"
        return axios.put(url, request, config);
    }

    updateWeekendStatus(request) {
        let config = {
            headers: {
                "Authorization": HWUser.accessToken(),
                "Content-Type": "application/json"
            }
        };
        let url = window.resolveRegion(process.env.REACT_APP_SMART_DASHBOARDS_BASE_API_URL) + "/holiday-preference/toggle"
        return axios.put(url, request, config);
    }
  
    createNewHoilday(request) {
        let url = window.resolveRegion(process.env.REACT_APP_SMART_DASHBOARDS_BASE_API_URL) + "/holiday-preference/calendar"
        let aConfig = {
            headers: {
                Authorization: HWUser.accessToken(),
                'Content-Type': 'application/json',
            },
        };
        return axios.post(url, request, aConfig);
    }
    deleteFeature(request) {
        let url = window.resolveRegion(process.env.REACT_APP_DASHBOARDS_BASE_API_URL) + "/platform-setting/events/hierarchy/feature";
        let aConfig = {
            headers: {
                "Authorization": HWUser.accessToken(),
                "Content-Type": "application/json"
            }
        };
        aConfig.params = request
        return axios.delete(url, aConfig)
    }

    editEvent(request) {
        let url = window.resolveRegion(process.env.REACT_APP_DASHBOARDS_BASE_API_URL) + "/platform-setting/events/mapping"
        let aConfig = {
            headers: {
                Authorization: HWUser.accessToken(),
                'Content-Type': 'application/json',
            },
        };
        return axios.post(url, request, aConfig);
    }

    updateEventHierarchyMapping(request) {
        
        let config = {
            headers: {
                "Authorization": HWUser.accessToken(),
                "Content-Type": "application/json"
            }
        };
        let url = window.resolveRegion(process.env.REACT_APP_DASHBOARDS_BASE_API_URL) + "/platform-setting/events/mapping"
        return axios.put(url, request, config);
    }

    updateEventStatus(request) {
        let config = {
            headers: {
                "Authorization": HWUser.accessToken(),
                "Content-Type": "application/json"
            }
        };
        let url = window.resolveRegion(process.env.REACT_APP_DASHBOARDS_BASE_API_URL) + "/platform-setting/events/mapping/status"
        return axios.put(url, request, config);
    }

    fetchAutoCaptureSetupMode(request) {
        let url = window.resolveRegion(process.env.REACT_APP_DASHBOARDS_BASE_API_URL) + "/platform-setting/events/auto-capture-setup-mode";
        let config = {
            headers: {
                "Authorization": HWUser.accessToken(),
                "Content-Type": "application/json"
            }
        };
        config.params = {
           // appId: request.appId
           ...request
        }
        return axios.get(url, config);
    }

  
    savePremiumFeature(data){
        let config = {headers: { "Authorization": HWUser.accessToken(), "Content-Type": "application/json" }};
        let url = window.resolveRegion(process.env.REACT_APP_SMART_DASHBOARDS_BASE_API_URL) + "/premium/features";
        return axios.post(url,data,config)
    }


    updateAutoCaptureSetupMode(request) {
        let url = window.resolveRegion(process.env.REACT_APP_DASHBOARDS_BASE_API_URL) + "/platform-setting/events/auto-capture-setup-mode"
        let aConfig = {
            headers: {
                Authorization: HWUser.accessToken(),
                'Content-Type': 'application/json',
            },
        };
        return axios.post(url, request, aConfig);
    }
    verifyEmailForMyOrg(data) {
        let aConfig = {
            headers: {
                "Authorization": HWUser.accessToken(),
                "Content-Type": "application/json"
            }
        };
        let url = window.resolveRegion(process.env.REACT_APP_DASHBOARDS_BASE_API_URL) + "/platform-setting/resend-verification-email";
        return axios.post(url, data, aConfig)
    }

    fetchConfigureProducts = () => {
        let url = window.resolveRegion(process.env.REACT_APP_DASHBOARDS_BASE_API_URL) + "/multi-product/product-config";
        let config = {
            headers: {
                "Authorization": HWUser.accessToken(),
                "Content-Type": "application/json"
            }
        };
        config.params = {
            orgId: HWUser.orgId()
        }
        return axios.get(url, config);
    }


    fetchBusinessUnits = () => {
        let url = window.resolveRegion(process.env.REACT_APP_DASHBOARDS_BASE_API_URL) + "/multi-product/business-unit";
        let config = {
            headers: {
                "Authorization": HWUser.accessToken(),
                "Content-Type": "application/json"
            }
        };
        config.params = {
            orgId: HWUser.orgId()
        }
        return axios.get(url, config);
    }
}

export default HealthScoreService;
