import React, { useState, useRef, useEffect, useCallback } from 'react';
import { Overlay, Tooltip } from 'react-bootstrap';
import './UiLabel.css';
import { Tooltip as CustomeTooltip, makeStyles } from '@material-ui/core';

const UiLabel = (props) => {
  const [show, setShow] = useState(false);
  const target = useRef();
	const[conatiner,setConatiner] = useState(false);
  let { label, className, length = 15, placement, top, children, tooltipClass, clicked, showTip, list, index, hideLable, showToolTipWithHtml, makeTooltipClickable } = props
  const shortLabel = label && label.length > 0 && label.length > length ? <>{label.slice(0, length)}...</> : label;
  let listLabels = list ? list : '';
  let toolTipText = listLabels ? listLabels : label

	useEffect(() => {
		const handleScroll = () => {
			setShow(false)
		}
		let elemnt = document.querySelector(".datagrid__table__container .MuiDataGrid-virtualScroller");
		let skDashBoardContainer = document.querySelector(".sk-smart-dashboard-container-content");

		if(document.querySelector(".sk-smart-backgroung-light-bg-height")){
			setConatiner(document.querySelector(".sk-smart-backgroung-light-bg-height"))
		}

		if (skDashBoardContainer) {
			skDashBoardContainer.addEventListener('scroll', handleScroll)
		}
		if (elemnt) {
			elemnt.addEventListener('scroll', handleScroll)
		}
		window.addEventListener('scroll', handleScroll)
		return () => {
			if (skDashBoardContainer) {
				skDashBoardContainer.removeEventListener('scroll', handleScroll)
			}
			if (elemnt) {
				elemnt.removeEventListener('scroll', handleScroll)
			}
			window.removeEventListener('scroll', handleScroll)
		}
	}, [])
	
	const useStyles = makeStyles((theme) => ({
		customTooltip: {
			color:props.theme === "white"?"var(--smart-insights-and-konversations-intelligence-heading-color)":"var(--primary-color)",
			backgroundColor:props.theme === "white"?'white !important':'var(--sk-heading-color)  !important',
			fontSize: '13px',
			fontFamily: 'var(--sk-font-urbanist-family)',
			boxShadow: '0px 9px 30px var(--sk-graph-popper)',
			borderRadius: '11px',
			padding: '8px !important',
			minWidth: '50px !important',
			border: '1px solid var(--sk-heading-color)',
			fontWeight: '500',
			top: '12px',
			left: props.left || '8px',
			'& .MuiTooltip-arrow': {
				color: props.theme === "white"?'white !important':'var(--sk-heading-color)!important'
			},
			'& .MuiTooltip-arrow:before': {
				border: props.theme === "white"?'1px solid var(--sk-smart-datepicker-border-color)':'1px solid var(--sk-heading-color)',
			}
		},
	}));

	const classes = useStyles();
	const refElement =  useRef();
  return (<>
		{
			props.showOnlyTooltip?
			<>
				 <CustomeTooltip open={show}  innerRef={refElement} classes={{ tooltip: classes.customTooltip }} className='add__column__info__react__tooltip' title={label} arrow placement={props?.placement ||"top"}>
				 <span key={"s"+index} className={className} ref={refElement} style={{ textTransform: (props.callingFrom === "accountMangement") ? "uppercase" : "none",/* marginLeft:"2px" */}}
				 onClick={(e) => { setShow(false); clicked && clicked(e) }}  onMouseOver={(e) => { 
					 if(props.preventDefaultActions){
						e.stopPropagation();e.preventDefault();
					 }
					 props.onMouseOverTitle && props.onMouseOverTitle()
					 label && setShow(true)}} 
					 onMouseLeave={(e) => {
						 if(props.preventDefaultActions){
							e.stopPropagation();e.preventDefault();
							props.onMouseLevTitle && props.onMouseLevTitle()
						}
						 setShow(false);
						}}>  
						 {hideLable?"":children ? children : label}
					 </span>
				 </CustomeTooltip>
		 </>:

			(label && label.length > length || listLabels) ?
				<span key={"s"+index}  className={className}
					ref={target} onClick={(e) => { setShow(false); clicked && clicked(e) }} 
			onMouseOver={(e) => {
				if(props.preventDefaultActions){
					e.stopPropagation();e.preventDefault();
				}
				setShow(true)
			}} 
			onMouseLeave={(e) => {
				if(props.preventDefaultActions){
					e.stopPropagation();e.preventDefault();
				}
				setShow(false)
			}} 
			style={{ textTransform: (props.callingFrom === "accountMangement") ? "uppercase" : "none" }}>
        {hideLable ? "" : children ? children : shortLabel} 
        {
				!showTip 
				&& 
				show &&
          <Overlay container={props.overlayConatiner ? props.overlayConatiner? refElement.current:conatiner : null} target={target.current} show={true}
            placement={(index === 0) ? 'right' : placement ? placement : 'top'}>
            {(props) => (
              <Tooltip  id="UiLabelTooltip" key={"t"+index} className={`${tooltipClass} ${index && index === 0 ? 'firstLineLabel' : ''}`} style={{ marginTop: top }} {...props}>
											{showToolTipWithHtml ? <span onMouseOver={(e) => {
												if (!makeTooltipClickable) {
														e.stopPropagation(); e.preventDefault(); setShow(false)
												}
											
											}} dangerouslySetInnerHTML={{ __html: toolTipText }}></span> : toolTipText}
              </Tooltip>
            )}
          </Overlay>}
      </span>
        : <span className={className} style={{ textTransform: (props.callingFrom === "accountMangement") ? "uppercase" : "none", ...props.style}} >{label}</span>
	}
  </>
  )
}


export default UiLabel;
