import { ClickAwayListener, Popper } from '@mui/material';
import { cloneDeep } from 'lodash';
import moment from 'moment';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
// import '../../KaffeineKarrots/kaffeineKarrots.css';
import HWUser from '../../HWLibrary/HWUser';
import SmartReactions from '../SkSmartEmojiAndReactions/SmartReactions';
import { parseReactMentionsToHTMLText } from '../SkSmartFunctions';
import { formatNameToTwoLetters } from '../SkSmartFunctions.js';
import SmartConfirmModal from '../SmartConfirmModal/SmartConfirmModal';
import { deleteComment, getSmartCommentStates, setSmartCommentStates } from '../../store/reducers/skCommentsReducer';
import SkSmartEditDeleteCommentComponent from './SkSmartEditDeleteCommentComponent';
import SmartCommentMention from './SmartCommentMention';
import SmartReplyField from './SmartReplyField';
import { frmValidate } from '../../HWLibrary/commonFunc';


export default function SmartCommentsList({ featureId,skComment, smartComment, onEditCommentClicked, onMentionsInputChange, setIsInsidePopperOn, parentCommentId, feature, ...props }) {

	const [showCommentMenu, setShowCommentMenu] = useState(false);
	const [showCommentEditBox, setshowCommentEditBox] = useState(false);
	const [showWarningPopUp, setShowWarningPopUp] = useState(false);
	const [showWarningMsg, setShowWarningMsg] = useState('');
	const [showOnlyOkAction, setShowOnlyOkAction] = useState(false);
	const[showReplyEditor, setShowReplyEditor] = useState(false);


	let commentText = parseReactMentionsToHTMLText(smartComment?.commentBody)
	var showDate = false;
	var date2 = moment().format('DD/MM/YYYY');
	var date1 = moment(smartComment?.updatedTime).format('DD/MM/YYYY');
	if (date1 < date2) {
		showDate = true
	};

	const dispatch = useDispatch();
	const getSmartCommentState = useSelector(getSmartCommentStates);
	const { smartCommentsList, currentSelectedComment, smartKommunityCommentsList } = getSmartCommentState;

	const deleteKnkComment = (comment) => {
    let commentList = smartKommunityCommentsList;
		commentList[featureId].commentResponses = comment;
		dispatch(setSmartCommentStates({ smartKommunityCommentsList: commentList }))
	}

	let onCommentDelete = () => {
		let commentList = feature !== 'KnkPost' ? cloneDeep(smartCommentsList) : smartKommunityCommentsList[featureId]?.commentResponses;
		if(smartComment?.parentCommentId) {
      let updatedComments = commentList?.find(item => item?.parentComment?.commentId === smartComment?.parentCommentId);
			updatedComments.replyList["replies"] = updatedComments?.replyList?.replies?.filter(item => item?.commentId !== smartComment?.commentId);
			updatedComments["replyCount"] = updatedComments.replyCount - 1;
		}
		else {
      commentList = commentList?.filter(item => item?.parentComment?.commentId !== smartComment?.commentId);
		}
		dispatch(deleteComment(smartComment?.featureId, smartComment?.commentId));
		if(feature === 'KnkPost'){props?.onCommentCallBack(smartComment?.featureId, "delete");}
		setShowCommentMenu(false)
		feature !== 'KnkPost' ? dispatch(setSmartCommentStates({ smartCommentsList: commentList })) : deleteKnkComment(commentList);
		handleWarningMsgsForPopUp(true, true, 'Comment Deleted Successfully.')
	}
	const handleWarningMsgsForPopUp = (onlyOk, popupState, msg) => {
		setShowOnlyOkAction(onlyOk)
		setShowWarningPopUp(popupState);
		setShowWarningMsg(msg);
	}

	const onCancelConfirmation = () => {
		if (showWarningMsg === 'Are you sure you want to delete this comment?') {
			handleWarningMsgsForPopUp(false, false, '');
		}
	}

	const onOkConfirmation = () => {
		if (showWarningMsg === 'Are you sure you want to delete this comment?') {
			onCommentDelete()
		}
		if (showWarningMsg === 'Comment Deleted Successfully.') {
			handleWarningMsgsForPopUp(false, false, '');
		}
	}
	const isValidLink = frmValidate(commentText?.trim());
	return (
		<>
			{
				!showCommentEditBox &&
				<div id={smartComment?.commentedBy} className={`smart__comments__list__comment__box__wrap`}>
					<div className='smart__comments__list__comment__box-profile-icon'>
					<div className='smart__react__select__profile__icon__wrap' style={{marginTop: smartComment?.userImage ? "" : '5px'}}>
					  <div className='smart__react__select__profile__icon__container popover--text' style={{backgroundColor: smartComment?.userImage ? "#fff" : ''}}>
							<div className='smart__react__select__profile__icon'>
								{smartComment?.userImage ? <img src={smartComment?.userImage} className="smart__comments__profile__icon" /> :
									<div className="smart__react__select__profile__icon-text">{formatNameToTwoLetters(smartComment?.userName)}</div>
								}	
						</div>
					</div>
					</div>
						{/* <img className='smart__profile__icon' src={smartComment?.userImage ? smartComment?.userImage : avatarIcon} /> */}
					</div>
					<div className='smart__comments__list__comment__container'>
						<div className='smart__comments__list__comment__box'>
							<div className='smart__comments__list__comment__box-header'>
								<div className='smart__comments__list__comment__box-header-label'>
									{smartComment?.userName}
									{smartComment?.isEdited && <div className='smart__notes_edit_text'>(edited)</div>}
								</div>
								{smartComment?.commentedBy === HWUser.userId() &&  
									<SkSmartEditDeleteCommentComponent
										handleWarningMsgsForPopUp={handleWarningMsgsForPopUp} isScroll = {props?.isScroll}
										showCommentMenu={showCommentMenu} setShowCommentMenu={setShowCommentMenu} setshowCommentEditBox={setshowCommentEditBox}
										commentId={smartComment?.commentId} featureId={smartComment.featureId}
										popUpRefNumber={props.popUpRefNumber} setPopUpRefNumber={props.setPopUpRefNumber} skComment={skComment} />
								}
								{/* <div onClick={(e) => { setShowCommentMenu(true); setAnchorElTarget(e?.currentTarget) }} className='smart__comments__list__settings__icon__container'>
									<img src={ellipsis} className='smart__comments__list__settings__icon' alt='settings_icon_comments' />
								</div> */}
							</div>
							{isValidLink?<>
								<a href={commentText?.trim()?.includes("https://")?commentText?.trim() :"https://" + commentText?.trim()}  target='_blank' style={{textDecoration:"underline"}} className='name'>
									{commentText?.trim()}
								</a>
							</>
							:<div className='smart__comments__list__comment__box-commented-text' dangerouslySetInnerHTML={{ __html: commentText?.trim().replace(/\n/g, "<br/>") }}>
							</div>}
							
						</div>
						<div className='smart__comments__list__comment__box__footer'>
							<SmartReactions smartComment={smartComment} feature={feature}/>
							<div className="smart__comments__list__reaction-button-unselected-text smart-reply-text" onClick={e => setShowReplyEditor(true)}>Reply</div>
							{!smartComment?.parentCommentId && props?.replyCount > 0 && <div className='smart__comments__list__footer-time'>{props?.replyCount} {props?.replyCount === 1  ? " Reply" : " Replies"}</div>}
							<div className='smart__comments__list__footer-time'>
								{showDate ?
									moment(smartComment?.updatedTime).fromNow(true) + " ago" :
									((moment(smartComment?.updatedTime).fromNow(true)) === 'a few seconds' ? 'Published now' : moment(smartComment?.commentedAtTime).fromNow(true) + " ago")
								}
							</div>
							
						</div>
						{showReplyEditor && <SmartReplyField isScroll={props?.isScroll} feature={feature} parentCommentId={parentCommentId} featureId={featureId} smartComment={smartComment} action="edit" visibility={setshowCommentEditBox} setIsInsidePopperOn={setIsInsidePopperOn} setShowReplyEditor={setShowReplyEditor} {...props}/>}
					</div>
				</div>}
			{showCommentEditBox && <div id={smartComment?.userId} className='smart__comments__edit__comment__box__wrap'>
				<SmartCommentMention isScroll={props?.isScroll} key={smartComment?.parentCommentId} onCommentCallBack={props.onCommentCallBack} feature={feature} parentCommentId={smartComment?.parentCommentId} featureId={featureId} smartComment={smartComment} action="edit" visibility={setshowCommentEditBox} setIsInsidePopperOn={setIsInsidePopperOn} {...props}/>
			</div>}

			{showWarningPopUp && <SmartConfirmModal showOnlyOkButton={showOnlyOkAction} onlyOkAction={onOkConfirmation} className="asDeleteModel" msg={showWarningMsg} okClickCallback={onOkConfirmation} callBackNotRequired={true} cancelClick={onCancelConfirmation} headtxt="Delete" targetObject={showWarningPopUp} />}
		</>
	)
}

const EditDeleteCommentPopper = ({ showCommentMenu, setShowCommentMenu, anchorElTarget, className, setDeleteComment, setshowCommentEditBox, showWarningPopUp, setShowWarningPopUp, setShowWarningMsg, handleWarningMsgsForPopUp, ...props }) => {

	return (
		<ClickAwayListener onClickAway={() => { setShowCommentMenu(!showCommentMenu) }}>
			<Popper placement='bottom' className={'sk_suggestion ' + className} open={true} anchorEl={anchorElTarget} >
				<div className='comment__list__settings_container'>
					<div className='comment__list__settings' onClick={(e) => {
						setshowCommentEditBox(true); setShowCommentMenu(false)
					}}>
						Edit
					</div>
					<div className='comment__list__settings'
						onClick={(e) => handleWarningMsgsForPopUp(false, true, 'Are you sure you want to delete this comment?')}>
						Delete
					</div>
				</div>
			</Popper>
		</ClickAwayListener>
	)


}