// import axios from 'axios';
import JSZip from 'jszip';
import { saveAs } from 'file-saver';
import html2canvas from 'html2canvas'
import jsPDF from 'jspdf';

// Checks if an object is undefined or empty. A string is empty if it has only whitespaces.
export const isEmpty = object => {
  return (
    object === undefined ||
    object === null ||
    (typeof object === 'string' && object.trim() === '') ||
    (Array.isArray(object) && object.length === 0)
  );
};

export const removeNullValueFromFilterToken =(obj)=>{
  for (var propName in obj) {
    if (!(obj[propName] || obj[propName] === false)) {
      delete obj[propName];
    }
    if (typeof (obj[propName]) === "object") {
      for (var propName1 in obj[propName]) {
        if (!(obj[propName][propName1] || obj[propName][propName1] === false)) {
          delete obj[propName][propName1];
        }
      }
    }
  }
  return obj;
}

export const isAnyEmpty = (...objects) => {
  for (let object of objects) {
    if (isEmpty(object)) {
      return true;
    }
  }
  return false;
};

export const areAllEmpty = (...objects) => {
  for (let object of objects) {
    if (!isEmpty(object)) {
      return false;
    }
  }
  return true;
};

export const formatCurrencyToKAndMNoRoundOff = value => {
  if (isEmpty(value) || value === 0) {
    return 0;
  } else if (value < 1e3) {
    value = value.toFixed(1);
    value = parseFloat(value);
    return value;
  } else if (value < 1e6) {
    return Math.trunc((value / 1e3) * 10) / 10 + 'K';
  } else if (value <= 1e9) {
    return Math.trunc((value / 1e6) * 10) / 10 + 'M';
  } else {
    return Math.trunc((value / 1e9) * 10) / 10 + 'B';
  }
};

export const formatCurrencyToKAndM = value => {
  if (isEmpty(value) || value === 0) {
    return 0;
  } else if (value < 1e3) {
    value = value.toFixed(1);
    value = parseFloat(value);
    return value;
  } else if (value < 1e6) {
    return (value / 1e3).toFixed(1) + 'K';
  } else if (value <= 1e9) {
    return (value / 1e6).toFixed(1) + 'M';
  } else {
    return (value / 1e9).toFixed(1) + 'B';
  }
};

export const formatCurrencyToKAndMInInteger = value => {
  if (isEmpty(value)) {
    return 0;
  } else if (value < 1e3) {
    return value;
  } else if (value < 1e6) {
    return (value / 1e3).toFixed(0) + 'K';
  } else if (value <= 1e9) {
    return (value / 1e6).toFixed(0) + 'M';
  } else {
    return (value / 1e9).toFixed(0) + 'B';
  }
};

export const validateEmail = emailId => {
  if (!emailId) return false;
  return /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(emailId) ?
    true :
    false;
};

export const formatCurrencyToKAndMInIntegerFunc = (value, isSpace = false, decimal = 1) => {
  if (isEmpty(value)) {
    return 0;
  } else if (value < 1e3) {
    return Number(value) === value && value % 1 === 0? value:Number(value).toFixed(decimal);
  } else if (value < 1e6) {
    return (value / 1e3).toFixed(decimal) + `${isSpace ? " K" : "K"}`;
  } else if (value <= 1e9) {
    return (value / 1e6).toFixed(decimal) + `${isSpace ? " M" : "M"}`;
  } else {
    return (value / 1e9).toFixed(decimal) + `${isSpace ? " B" : "B"}`;
  }
};


export const revenueFormatter = (currency, value, decimal,isSpace = true, isIcon=false) => {

  let currencyIcon = "";
  if (currency) {
    switch (currency) {
      case "AMERICAN_DOLLAR":
        currencyIcon = "$";
        break;
      case "INDIAN_RUPEE":
        currencyIcon = "₹ ";
        break;
      case "BRITISH_POUND":
        currencyIcon = "£";
        break;
      case "PHILIPPINE_PESO":
        currencyIcon = "₱";
        break;
      case "SINGAPORE_DOLLAR":
        currencyIcon = "S$";
        break;
      case "EURO"    : currencyIcon= "€" ; break;
      default:
        break;
    }
    
    return !isIcon?currencyIcon + "" + formatCurrencyToKAndMInIntegerFunc(value, isSpace, decimal):currencyIcon;
  }
};
export const revenueFormatterWithoutKandM = (currency, value, decimal,isSpace = true, isIcon=false) => {
  let currencyIcon = "";
	value = (Number.isInteger(value))?value:(value?.toFixed(0) || 0)
  if (currency) {
    switch (currency) {
      case "AMERICAN_DOLLAR":
        currencyIcon = "$";
				// value = new Intl.NumberFormat('en-US').format(value);
        break;
      case "INDIAN_RUPEE":
        currencyIcon = "₹ ";
				// value = new Intl.NumberFormat('en-IN').format(value);
        break;
      case "BRITISH_POUND":
        currencyIcon = "£";
				// value = new Intl.NumberFormat('en-UK').format(value);
        break;
      case "PHILIPPINE_PESO":
				// value = new Intl.NumberFormat('	en-PH').format(value);
        currencyIcon = "₱";
        break;
      case "SINGAPORE_DOLLAR":
				// value = new Intl.NumberFormat('en-SG').format(value);
        currencyIcon = "S$";
        break;
      case "EURO"    : 
			currencyIcon= "€" ; 
			// value = new Intl.NumberFormat('de-DE').format(value);
			break;
      default:
        break;
    }
		// value = new Intl.NumberFormat('en-IN').format(value);
		value = Number(value).toLocaleString()
		// value = value.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,")
    return !isIcon?currencyIcon + "" +value :currencyIcon;
  }
};
export const generateDashboardPDF = (
  id,
  width,
  height,
  fileName,
  onFileSave
) => {
  //height and width is to adjust the resolution of image downloaded in pdf....
  window.scrollTo(0, 0);
  html2canvas(document.getElementById(id), {
    useCORS: true
  }).then(canvas => {
    // content.width((a4[0]*1.33333) -80).css('max-width','none');
    let pdf = new jsPDF('landscape', 'mm', [1200, 600], true);
    var finalWidth = pdf.internal.pageSize.getWidth();
    var finalHeight = pdf.internal.pageSize.getHeight();
    pdf.addImage(
      canvas.toDataURL('image/jpeg'),
      'JPEG',
      5,
      5,
      finalWidth - width,
      finalHeight - height
    );

    pdf.save(fileName, {
      returnPromise: true
    }).finally(() => {
      onFileSave && onFileSave();
      return true;
    });
  });
};

// This will ignore promise error.
export const serializePromises = immediate => {
  // This works as our promise queue
  let last = Promise.resolve();
  return function (...a) {
    // Catch is necessary here — otherwise a rejection in a promise will
    // break the serializer forever
    last = last.catch(error => {}).then(() => immediate(...a));
    return last;
  };
};

//  { files: [
//       { url: "https://platform.smartkarrot.com/favicon.ico", name: "01.jpeg" },
//       { url: "https://platform.smartkarrot.com/favicon.ico", name: "02.jpeg" }],
//    zipFileName: "name.zip"
//  }
export const downloadAndSaveZipFile = (files, zipFileName) => {
  const zip = new JSZip();
  const fetchPromises = files.map(async file => {
    try {
			const response = await fetch(file.url, {
				headers: new Headers({
					Origin: window.location.origin,
				}),
				mode: 'no-cors',
			});
			const blob = await response.blob();
			zip.file(file.name, blob, {
				binary: true,
			});
		} catch (error) {
			// return console.log('Error while downloading file: ' + JSON.stringify(error));
		}
  });

  Promise.all(fetchPromises)
    .then(() => {
      zip
        .generateAsync({
          type: 'blob',
        })
        .then(function (content) {
          saveAs(content, zipFileName + '.zip');
        })
        .catch(error =>
          console.log('Error While Saving Zip File: ' + JSON.stringify(error))
        );
    })
    .catch(error =>
      console.log('Error while fetching files: ' + JSON.stringify(error))
    );
};

export const downloadResource = (url, filename) => {
  if (!filename) filename = url.split('\\').pop().split('/').pop();
  fetch(url + '?t=' + new Date().getTime(), {
      headers: new Headers({
        Origin: window.location.origin
      }),
      mode: 'cors',
    })
    .then(response => response.blob())
    .then(blob => {
      let blobUrl = window.URL.createObjectURL(blob);
      forceDownload(blobUrl, filename);
    })
    .catch(e => console.error(e));
};

//Added By Noor on 3-sep-2020 for customer support Download
export const downloadResourceForCS = (url, filename) => {
  if (!filename) filename = url.split('\\').pop().split('/').pop();
  fetch(url).then(response => response.blob()).then(blob => {
    let blobUrl = window.URL.createObjectURL(blob);
    forceDownload(blobUrl, filename);
  }).catch(e => console.error(e));
};


export const forceDownload = (blob, filename) => {
  var a = document.createElement('a');
  a.download = filename;
  a.href = blob;
  document.body.appendChild(a);
  a.click();
  a.remove();
};

export const disableBodyScroll = () => {
  document.body.style.overflow = 'hidden';
  document.body.style.position = 'fixed';
  document.body.style.width = '100%';
};

export const enableBodyScroll = () => {
  document.body.style.overflow = 'visible';
  document.body.style.position = '';
  document.body.style.width = '';
};

export const parseDisplayFilterString = stringItem => {
  if (!stringItem || !stringItem.trim()) {
    return '';
  }
  if (stringItem.length <= 18) {
    return stringItem;
  } else {
    if (
      (stringItem.substr(0, 15) +
        stringItem.substr(15, stringItem.length - 1).split(' ')[
          stringItem.substr(15, stringItem.length - 1).split(' ').length - 1
        ]).replace(/ /g, '') === stringItem.replace(/ /g, '')
    ) {
      return stringItem;
    }
    return (
      stringItem.substr(0, 15) +
      '...' +
      stringItem.substr(15, stringItem.length - 1).split(' ')[
        stringItem.substr(15, stringItem.length - 1).split(' ').length - 1
      ]
    );
  }
};
export const parseDisplayFilterStringForMultiSlectOption = (stringItem,len=7) => {
  
  if (!stringItem || !stringItem.trim()) {
    return '';
  }
  if (stringItem.length <= 18) {
    return stringItem;
  } else {
    return (`${stringItem.substr(0, len-5)}${stringItem.length>len ? "..." + stringItem.substr(-5):''}`);
  }
};

export const parseDisplayFilterStringFofKeyContas = stringItem => {
  if (!stringItem || !stringItem.trim()) {
    return '';
  }
  if (stringItem.length <= 8) {
    return stringItem;
  } else {
    if (
      (stringItem.substr(0, 6) +
        stringItem.substr(6, stringItem.length - 1).split(' ')[
          stringItem.substr(6, stringItem.length - 1).split(' ').length - 1
        ]).replace(/ /g, '') === stringItem.replace(/ /g, '')
    ) {
      return stringItem;
    }
    return (
      stringItem.substr(0, 6) +
      '...' +
      stringItem.substr(6, stringItem.length - 1).split(' ')[
        stringItem.substr(6, stringItem.length - 1).split(' ').length - 1
      ]
    );
  }
};

export const getDateWithoutTimeZone =() =>{
	const currentDate = new Date();
	const year = currentDate.getFullYear();
	const month = String(currentDate.getMonth() + 1).padStart(2, '0');
	const day = String(currentDate.getDate()).padStart(2, '0');
	const hours = String(currentDate.getHours()).padStart(2, '0');
	const minutes = String(currentDate.getMinutes()).padStart(2, '0');
	const seconds = String(currentDate.getSeconds()).padStart(2, '0');
	const formattedDate = `${year}-${month}-${day}T${hours}:${minutes}:${seconds}.000Z`;
	console.log(formattedDate);
	
}

export const parseDisplayFilterStringWithEllipsesForMostActiveUser = stringItem => {
  stringItem = stringItem && stringItem.trim();
  if (!stringItem || !stringItem.trim()) {
    return '';
  }
  if (stringItem.length <= 8) {
    return stringItem;
  } else {
    return (
      stringItem.substr(0, 10) +
      '...'
    );
  }
};
export const parseDisplayFilterStringWithEllipsesForPhase = stringItem => {
  stringItem = stringItem && stringItem.trim();
  if (!stringItem || !stringItem.trim()) {
    return '';
  }
  if (stringItem.length <= 6) {
    return stringItem;
  } else {
    return (
      stringItem.substr(0, 6) +
      '...'
    );
  }
};

export const parseDisplayFilterStringWithEllipsesForChunComments = stringItem => {
  stringItem = stringItem && stringItem.trim();
  if (!stringItem || !stringItem.trim()) {
    return '';
  }
  if (stringItem.length <= 149) {
    return stringItem;
  } else {
    return (
      stringItem.substr(0, 145) +
      '...'
    );
  }
};


export const parseDisplayFilterStringWithEllipsesForAccoutView = stringItem => {
  stringItem = stringItem && stringItem.trim();
  if (!stringItem || !stringItem.trim()) {
    return '';
  }
  if (stringItem.length <= 10) {
    return stringItem;
  } else {
    return (
      stringItem.substr(0, 10) +
      '...'
    );
  }
};

export const parseDisplayFilterStringWithEllipses = stringItem => {
  if (!stringItem || !stringItem.trim()) {
    return '';
  }
  if (stringItem.length <= 18) {
    return stringItem;
  } else {
    return (
      stringItem.substr(0, 15) +
      '...'
    );
  }
};
export const parseDisplayFilterForUserRole = stringItem => {
  if (!stringItem || !stringItem.trim()) {
    return '';
  }
  if (stringItem.length <= 30) {
    return stringItem;
  } else {
    return (
      stringItem.substr(0, 25) +
      '...'
    );
  }
};
export const parseDisplayFilterStringWithEllipsesWith30Char = stringItem => {
  if (!stringItem || !stringItem.trim()) {
    return '';
  }
  if (stringItem.length <= 25) {
    return stringItem;
  } else {
    return (
      stringItem.substr(0, 23) +
      '...'
    );
  }
};

export const parseDisplayFilterStringWithEllipsesWith40Char = stringItem => {
  if (!stringItem || !stringItem.trim()) {
    return '';
  }
  if (stringItem.length <= 35) {
    return stringItem;
  } else {
    return (
      stringItem.substr(0, 35) +
      '...'
    );
  }
};

export const parseDisplayFilterStringWithEllipsesWith32Char = stringItem => {
  if (!stringItem || !stringItem.trim()) {
    return '';
  }
  if (stringItem.length <= 32) {
    return stringItem;
  } else {
    return (
      stringItem.substr(0, 35) +
      '...'
    );
  }
};

export const parseDisplayFilterStringWithEllipsesForAccount = stringItem => {
  if (!stringItem || !stringItem.trim()) {
    return '';
  }
  if (stringItem.length <= 15) {
    return stringItem;
  } else {
    return (
      stringItem.substr(0, 12) +
      '...'
    );
  }
};

export const parseDisplayFilterStringWithEllipsesForAccountForContact = stringItem => {
  if (!stringItem || !stringItem.trim()) {
    return '';
  }
  if (stringItem.length <= 21) {
    return stringItem;
  } else {
    return (
      stringItem.substr(0, 19) +
      '...'
    );
  }
};



export const parseDisplayFilterStringForInnerScreen = stringItem => {
  if (!stringItem || !stringItem.trim()) {
    return '';
  }
  // let filterString = (stringItem.length <= 25) ? stringItem: (stringItem.substr(0,25) + "..." + stringItem.substr(25, stringItem.length -1).split(" ")[stringItem.substr(25, stringItem.length -1).split(" ").length - 1])
  // return filterString

  if (stringItem.length <= 30) {
    return stringItem;
  } else {
    if (
      (stringItem.substr(0, 25) +
        stringItem.substr(25, stringItem.length - 1).split(' ')[
          stringItem.substr(25, stringItem.length - 1).split(' ').length - 1
        ]).replace(/ /g, '') === stringItem.replace(/ /g, '')
    ) {
      return stringItem;
    }
    return (
      stringItem.substr(0, 25) +
      '...' +
      stringItem.substr(25, stringItem.length - 1).split(' ')[
        stringItem.substr(25, stringItem.length - 1).split(' ').length - 1
      ]
    );
  }
};

export const parseDisplayFilterStringForIndividualScreen = stringItem => {
  if (!stringItem || !stringItem.trim()) {
    return '';
  }

  if (stringItem && stringItem.length <= 15) {
    return stringItem;
  } else {
    return stringItem.substr(0, 12) + '...';
  }
};



export const parseDisplayFilterStringForIstanceList = stringItem => {
  if (!stringItem || !stringItem.trim()) {
    return '';
  }

  if (stringItem && stringItem.length <= 10) {
    return stringItem;
  } else {
    return stringItem.substr(0, 9) + '...';
  }
};

export const parseDisplayFilterStringForContacts = stringItem => {
  if (!stringItem || !stringItem.trim()) {
    return '';
  }
  if (stringItem.length <= 25) {
    return stringItem;
  } else {
    if (
      (stringItem.substr(0, 20) +
        stringItem.substr(20, stringItem.length - 1).split(' ')[
          stringItem.substr(20, stringItem.length - 1).split(' ').length - 1
        ]).replace(/ /g, '') === stringItem.replace(/ /g, '')
    ) {
      return stringItem;
    }
    return (
      stringItem.substr(0, 20) +
      '...' +
      stringItem.substr(20, stringItem.length - 1).split(' ')[
        stringItem.substr(20, stringItem.length - 1).split(' ').length - 1
      ]
    );
  }
};

export const parseDisplayFilterStringContact = stringItem => {
  if (!stringItem || !stringItem.trim()) {
    return '';
  }
  if (stringItem && stringItem.length <= 7) {
    return stringItem;
  } else {
    return stringItem.substr(0, 6) + '...';
  }
};

export const parseDisplayFilterStringFilter = stringItem => {
  if (!stringItem || !stringItem.trim()) {
    return '';
  }
  if (stringItem && stringItem.length <= 5) {
    return stringItem;
  } else {
    return stringItem.substr(0, 4) + '...';
  }
};

// // Current blob size limit is around 500MB for browsers
// function downloadResource(url, filename) {
//     if (!filename) filename = url.split('\\').pop().split('/').pop();
//     fetch(url, {
//         headers: new Headers({ 'Origin': location.origin }),
//         mode: 'cors'
//     }).then(response => response.blob())
//         .then(blob => {
//             let blobUrl = window.URL.createObjectURL(blob);
//             forceDownload(blobUrl, filename);
//         })
//         .catch(e => console.error(e));
// }

export const localFileToBase64 = file =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = error => reject(error);
  });



export const onKeyPressDisableEnterAction = (e) => {
  e && e.key === 'Enter' && e.preventDefault();
}

export const convertTextToCapitalize = (textToConvert) => {
  let text = textToConvert || "";
  return (
    text.slice(0, 1).toUpperCase() + text.slice(1).toLocaleLowerCase()
  )
}
export const secondsToHMS = (seconds) => {
  let h, m, s, hDisplay, mDisplay, sDisplay;

  h = Math.floor(seconds / 3600);
  m = Math.floor(seconds % 3600 / 60);
  s = Math.floor(seconds % 3600 % 60);

  hDisplay = h > 0 ? h + "h " : "";
  mDisplay = m > 0 ? m + "m " : "";
  sDisplay = s > 0 ? s + "s " : "";
  return hDisplay + mDisplay + sDisplay;
}

export const secondsToHMSWithZeroAppended = (seconds) => {
  let h, m, s, hDisplay, mDisplay, sDisplay;

  h = Math.floor(seconds / 3600);
  m = Math.floor(seconds % 3600 / 60);
  s = Math.floor(seconds % 3600 % 60);

  hDisplay = (h > 0 && h < 10) ? "0" + h + ":" : (h > 9) ? h : "00:";
  mDisplay = (m > 0 && m < 10) ? "0" + m + ":" : (m > 9) ? m : "00:";
  sDisplay = (s > 0 && s < 10) ? "0" + s : (s > 9) ? s : "00";
  return hDisplay + mDisplay + sDisplay;
}

export const numberToLocalFormat = (numberValue) => {
    try { return numberValue.toLocaleString(navigator.language); }
    catch { }
    return numberValue;
}

export default {
  isEmpty,
  isAnyEmpty,
  areAllEmpty,
  formatCurrencyToKAndM,
  generateDashboardPDF,
  serializePromises,
  downloadResource,
  forceDownload,
  disableBodyScroll,
  enableBodyScroll,
  localFileToBase64,
  onKeyPressDisableEnterAction,
  convertTextToCapitalize,
  secondsToHMS,
  secondsToHMSWithZeroAppended,
  numberToLocalFormat,
	getDateWithoutTimeZone
};