import _ from "lodash";
import moment from "moment";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import * as userActions from "../store/user/userAction";

// Convert count for progressbar
export const getProgressCount = (addCount, count) => {
  const totalCount = addCount + count;
  return parseInt(((count / totalCount) * 100).toFixed(0));
};

export const getTotalTaskProgressCount = (totalCount1, totalCount2) => {
  return parseInt(((totalCount1 / totalCount2) * 100).toFixed(0));
};

export const addZero = (num, size) => {
  num = num.toString();
  while (num.length < size) num = "0" + num;
  return num;
};

export const useLocalizer = () => {
  let { t } = useTranslation();
  const trans = (key, data) => t(key, data);
  return { trans };
};

export const useRoleOptions = () => {
  const dispatch = useDispatch();
  dispatch(userActions.fetchRoleOptions());
  const [userRoles, setUserRoles] = useState([]);
  const userState = useSelector();
  useEffect(() => {
    if (userState?.userRoleOptions?.length > 0) {
      setUserRoles(userState?.userRoleOptions);
    }
  }, [userState?.userRoleOptions]);
  return { userRoles };
};

// convert string in santance case
export const titleCaseStr = (str) => {
  const strLower = str && str.toLowerCase();
  return strLower && strLower.charAt(0).toUpperCase() + strLower.slice(1);
};

export const capFirstLetter = (str) => {
  return str && typeof str == "string"
    ? str.charAt(0).toUpperCase() + str.slice(1)
    : "Please pass valid string";
};

// convert every word in title case // convert _str in Str
export const titleCaseStrForWords = (str) => {
  if (!str) {
    return "";
  }
  let i;
  let frags;
  let newStr = "";
  let strLower = str && str.toLowerCase();
  let isUnderscore = str.indexOf("_");
  if (isUnderscore) {
    frags = strLower && strLower.split("_");
    if (frags) {
      for (i = 0; i < frags.length; i++) {
        frags[i] = frags[i].charAt(0).toUpperCase() + frags[i].slice(1);
      }
    }
  } else {
    newStr = strLower && strLower.charAt(0).toUpperCase() + strLower.slice(1);
  }

  // console.log('fr...',frags)
  return isUnderscore ? frags.join(" ") : newStr;
};

//Check the string is url or not.
export const frmValidate = (str) => {
  if (!str) {
    return false;
  }
  let link = false;
  let pattern = new RegExp('^(https?:\\/\\/)?'+ // protocol
    '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|'+ // domain name
    '((\\d{1,3}\\.){3}\\d{1,3}))'+ // OR ip (v4) address
    '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*'+ // port and path
    '(\\?[;&a-z\\d%_.~+=-]*)?'+ // query string
    '(\\#[-a-z\\d_]*)?$','i'); // fragment locator
    if(pattern.test(str)){
      // console.log("Valid Domain Name==>>>",str);
      link = true;
    } else {
      // console.log("Enter Valid Domain Name===>>",str);
      link = false;
  }
  return link;
};


// ... trimStr

export const trimStrLength = (str, length) => {
  if (!str) {
    return "";
  }
  const shortStr = str.length > length ? `${str.slice(0, length)}...` : str;
  return shortStr;
};

export const trimStrLengthWithCount = (stringItem, length) => {
  if (!stringItem) {
    return "";
  }
  const shortStr = stringItem.substr(0, 10) + "..." + stringItem.length;
  // return(stringItem.substr (0, 10) +"..."+stringItem.substr(stringItem.length - 10,stringItem.length));
  return shortStr;
};

export const checkHoursMin = (value, flag) => {
  let chkReg = "";
  // regex for check min: reg for check hours
  chkReg =
    flag === "MM"
      ? !/^([0-5]?[0-9]|60)$/.test(value)
      : !/^(?:1[0-2]|[1-9])$/.test(value);
  if (chkReg) {
    return value.slice(0, -1);
  } else {
    return value;
  }
};

// get unique object using prop name
export const getUniqObj = (myArr, prop) => {
  return (myArr || [])?.filter((obj, pos, arr) => {
    return arr.map((mapObj) => mapObj[prop]).indexOf(obj[prop]) === pos;
  });
};

export const isSpace = (val) => {
  // console.log("data...val", /^\s/.test(val));
  if (/^\s/.test(val)) {
    return true;
  }
};

// Filter functions

export const isFilterEmpty = (obj) => {
  let filterdKey = Object.keys(obj).filter((key) => {
    let chk =
      obj && Array.isArray(obj[key])
        ? obj[key].filter(
            (item, index) => index === 0 && item["value"] !== "all"
          )
        : obj[key] !== "";
    return chk && chk.length > 0 ? obj : false;
  });
  return filterdKey;
};

export const setMapFiledName = (key) => {
  let keyName = "";

  switch (String(key)) {
    case "isBySize":
      keyName = "size";
      break;
    case "isPhase":
      keyName = "phase";
      break;
    case "isSubscription":
      keyName = "segment0";
      break;
    case "isCategory":
      keyName = "segment1";
      break;
    case "isCountry":
      keyName = "segment2";
      break;
    case "isVertical":
      keyName = "segment4";
      break;
    case "isHealthScore":
      keyName = "healthScore";
      break;
    case "isPlayTags":
      keyName = "successPlays";
      break;
    case "isRenewal":
      keyName = "renewals";
      break;
    default:
      keyName = key;
      break;
  }
  return keyName;
};

export const getMapFiledName = (key) => {
  // map database value with filter properties
  let keyName = "";
  switch (key) {
    case "phase":
      keyName = "isPhase";
      break;
    case "size":
      keyName = "isBySize";
      break;
    case "segment1":
      keyName = "isCategory";
      break;
    case "healthScore":
      keyName = "isHealthScore";
      break;
    case "successPlays":
      keyName = "isPlayTags";
      break;
    case "segment0":
      keyName = "isSubscription";
      break;
    case "segment2":
      keyName = "isCountry";
      break;
    case "segment4":
      keyName = "isVertical";
      break;
    case "accountSegmentName":
      keyName = "isSegment";
      break;
    case "renewals":
      keyName = "isRenewal";
      break;
    default:
      keyName = key;
  }
  return keyName;
};

export const getMapisSizeValue = (val) => {
  let updatedVal = "";

  switch (val.trim()) {
    case "Top (20%)":
      updatedVal = "top20";
      break;
    case "Mid (40%)":
      updatedVal = "mid40";
      break;
    case "Base (40%)":
      updatedVal = "base40";
      break;
    default:
      updatedVal = val;
  }

  return updatedVal;
}; // getVal

// Show border bottom in Required Field
export const checkValueEmpty = (val, isError) => {
  return isError && (val === "" || val === 0)
    ? "errorMsg"
    : (val === "" || val === 0) && !isError
    ? "errorBorderBottom"
    : "";
};
export const checkValueEmptyOnChange = (val, isError) => {
  return isError && (val === "" || val === 0)
    ? { borderBottom: "" }
    : (val === "" || val === 0) && isError == ""
    ? { borderBottom: "1px solid red" }
    : { borderBottom: "" };
};

export const durationToTime = (duration, unit, sort) => {
  let d = moment.duration(duration, unit);
  let hours = Math.floor(d.asHours());
  let mins = Math.floor(d.asMinutes()) - hours * 60;
  let isTime = +hours + +mins;

  hours = +hours > 1 ? `${hours} hours` : +hours === 1 ? `${hours} hour` : "";
  mins =
    +mins > 1
      ? ` ${mins} ${sort ? "mins" : "minutes"}`
      : +mins === 1
      ? ` ${mins} ${sort ? "min" : "minute"}`
      : "";
  return hours + mins;
};

// sampel function for handlecheck
export const onHandleCheck = (
  isChecked,
  name,
  options,
  optionName,
  stateValue
) => {
  let values = stateValue;
  if (name === "-Select-") {
    values = [];
  } else {
    values =
      values &&
      values.filter((i) => i && i.value && i.value.toString() !== name);
    if (isChecked && name !== "all") {
      let optionToAdd = options.filter((i) => i.value.toString() === name)[0];

      if (optionToAdd) {
        values.push(optionToAdd);
      }
      if (
        values.filter((i) => i.value.toString() !== "all").length ===
        options.filter((i) => i.value.toString() !== "all").length
      ) {
        let allOptionToAdd = options.filter(
          (i) => i.value.toString() === "all"
        )[0];
        values.push(allOptionToAdd);
      }
    } else if (isChecked && name === "all") {
      values = _.cloneDeep(options);
    } else if (!isChecked && name === "all") {
      values = [];
      // this.setState({ [optionName]: [] })
    } else if (
      !isChecked &&
      values &&
      values.filter((i) => i.value.toString() === "all").length > 0
    ) {
      values = values.filter((i) => i.value.toString() !== "all");
    }
    values =
      values &&
      values.filter((i) => {
        return i !== undefined && i.value !== "-Select-";
      });
  }

  return values;

  // this.setState({ selectedSupportOption: values })
};

// calling handleCheck

export const handleCheck = (isChecked, name, options, optionName) => {
  let stateValue = onHandleCheck(
    isChecked,
    name,
    options,
    optionName,
    "stateValue"
  );
  this.setState({ ["stateLabel"]: stateValue });
};

export const getfileType = (type) => {
  // console.log('data...type',type)
  switch (type) {
    case "vnd.openxmlformats-officedocument.wordprocessingml.document":
      return "docx";
    case "msword":
      return "doc";
    case "vnd.openxmlformats-officedocument.spreadsheetml.sheet":
      return "xlsx";
    case "text":
      return "text";
    case "plain":
      return "text";
    case "vnd.ms-excel":
      return "xls";
    default:return type
  }
  
};
