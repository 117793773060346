import { skAccount360Actions } from "../constants/actionType";

const initialState = {
	accountViewSummaryData: null,
	accountViewProductData: null,
	accountViewNPSTrendData: null,
	accountViewHealthScoreData: null,
	accountViewArrTrendData: null,
	accountViewProductUsageTrendData: null,
	accountViewRevenueTrendData: null,
	accountViewUserData: null,
	churnedAccount360: false,
	churnReasonModelObj: null,
	childAccountsForSelectedParentInA360: null,
	account360InternalParentChildNavigationLoader: false,
	account360CustomerPrioritiesData: null,
	isAccountViewLoading: false,
	isAccountViewError: false,
	accountViewErrorMessage: '',
	accountViewSuccessMessage:'',
	redirectionFromChurn: false
}


export const skAccount360ActionReducer = (state = initialState, { type, payload }) => {
	switch (type) {
		case skAccount360Actions.FETCH_ACCOUNT_360_USER_DATA:
		case skAccount360Actions.FETCH_ACCOUNT_360_REVENUE_TREND_DATA:
		case skAccount360Actions.FETCH_ACCOUNT_360_PRODUCT_USAGE_TREND_DATA:
		case skAccount360Actions.FETCH_ACCOUNT_360_ARR_TREND_DATA:
		case skAccount360Actions.FETCH_ACCOUNT_360_HEALTH_SCORE_DATA:
		case skAccount360Actions.FETCH_ACCOUNT_360_NPS_TREND_DATA:
		case skAccount360Actions.FETCH_ACCOUNT_360_SUMMARY:
		case skAccount360Actions.FETCH_ACCOUNT_360_CUSTOMER_PRIORITIES_DATA:
		case skAccount360Actions.UPDATE_ACCOUNT_360_CUSTOMER_PRIORITY:
		case skAccount360Actions.POST_ACCOUNT_360_CUSTOMER_PRIORITY:	
		case skAccount360Actions.DELETE_ACCOUNT_360_CUSTOMER_PRIORITY:
		case skAccount360Actions.SET_SMART_ACCOUNT_360_STATES: { return { ...state, ...payload } }
		default: return state;
	}
};


export default skAccount360ActionReducer;
