import React, { Component } from 'react'
import NewSKlogo from "./assets/phoenix/low-resolution.png" 
import './App.css'

export const UnsupportedScreenSize = () => {
    return (
			<div style={{height:"100vh"}}>
			<img src={NewSKlogo} className="main-no-logo" alt="" height={"100%"} width={"100%"} style={{height:"100%", width:"100%"}}/>
			</div>


    )
}