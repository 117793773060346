import { skCalendarActions } from "../constants/actionType";

const initialState = {
	events: [],
	isLoading: false,
	nextToken: null,
	showTouchPointPopover : false,
	touchpointData : null,
	touchpointEditData: null,
}


export const skCalendarActionsReducer = (state = initialState, { type , payload }= {}) => {
	switch (type) {
    case skCalendarActions.FETCH_EVENT_LIST: {
		let isLoading = (payload?.isLoading !== undefined && payload?.isLoading != null) ? payload.isLoading : state.isLoading;
		return ({...state, events:payload?.events, isLoading : isLoading}); 
		}
		case skCalendarActions.SET_SMART_CALENDAR_STATE: return {...state, ...payload}; 
    default: return state; 
  }
}

export default skCalendarActionsReducer 