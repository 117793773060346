import axios from "axios";
import hwAxios from "../../HWLibrary/HWAxios";
import HWUser from "../../HWLibrary/HWUser";
import cache from "../../SkSmartUtil/InMemoryCache";
import { cloneDeep } from "lodash";

export class SkExpansionAnalyserService {
    constructor() {
        if (!SkExpansionAnalyserService.instance) { SkExpansionAnalyserService.instance = this }
        return SkExpansionAnalyserService.instance
    }
		
    // expansionAnalyzerOverViewData = (request) => {
    //     let url = window.resolveRegion(process.env.REACT_APP_SMART_DASHBOARDS_BASE_API_URL) + "/expansion-analyzer/overview/expansion-potential";
    //     let aConfig = { headers: { "Authorization": HWUser.accessToken(), "Content-Type": "application/json","addFilter": true } };
    //     aConfig.params = { 
    //         orgId: HWUser.orgId(), 
    //         appId: HWUser.appId(), 
    //         userId: HWUser.userId(),
    //         ...request 
    //     }
    //     return hwAxios.get(url, aConfig);
    // }

		expansionAnalyzerOverViewData = (request) => {
			let url = window.resolveRegion(process.env.REACT_APP_SMART_DASHBOARDS_BASE_URL) + "/expansion-analyzer/overview/expansion-potential";
			let aConfig = { headers: { "Authorization": HWUser.accessToken(), "Content-Type": "application/json", "addFilter": true, } };
			let data = {
				orgId: HWUser.orgId(),
				appId: HWUser.appId(),
				userId: HWUser.userId(),
				...request
			}
			return hwAxios.post(url, data, aConfig);
		}


    expansionAnalyzerSummary = (request) => {
        let data   = { 
            appId: HWUser.appId(), 
            userId: HWUser.userId(),
            orgId:HWUser.orgId(),
            ...request
        }
        let config = { headers: { "Authorization": HWUser.accessToken(), "Content-Type": "application/json","addFilter": true  } };
        let url    = window.resolveRegion(process.env.REACT_APP_SMART_DASHBOARDS_BASE_API_URL) + "/expansion-analyzer/summary";
        return hwAxios.post(url, data, config);
    }

    fetchColumnPreference = (path) => {
				const cacheKey      = HWUser.userId() + path;
				const fetchFunction = () => {
					let url     = window.resolveRegion(process.env.REACT_APP_SMART_DASHBOARDS_BASE_URL) + "/user-column-preference";
					let aConfig = { headers: { "Authorization": HWUser.accessToken(), "Content-Type": "application/json" } };
					aConfig.params = { 
							orgId: HWUser.orgId(), 
							appId: HWUser.appId(), 
							userId: HWUser.userId(),
							path:path
					}
					return axios.get(url, aConfig);
				}
				return cache.get(cacheKey, cache.CacheModule.userColumnPreference, fetchFunction);
    }

    fetchSortPreference = (path) => {
			
			const cacheKey      = HWUser.userId() + path;
			const fetchFunction = () => {
				let url     = window.resolveRegion(process.env.REACT_APP_SMART_DASHBOARDS_BASE_URL) + "/smart-filter/user-sort-preference";
				let aConfig = { headers: { "Authorization": HWUser.accessToken(), "Content-Type": "application/json" } };
				aConfig.params = { 
						orgId: HWUser.orgId(), 
						appId: HWUser.appId(), 
						userId: HWUser.userId(),
						path:path
				}
				return axios.get(url, aConfig);
			}
			return cache.get(cacheKey, cache.CacheModule.sortPreference, fetchFunction);
		}
	
	fetchSegments = (request) => {
		const cacheKey      = HWUser.userId() ;
			const fetchFunction = () => {
				let url     =window.resolveRegion(process.env.REACT_APP_SMART_DASHBOARDS_BASE_URL) + "/smart-filter/account-segments";
				let aConfig = { headers: { "Authorization": HWUser.accessToken(), "Content-Type": "application/json" } };
				aConfig.params = { 
						orgId: HWUser.orgId(), 
						appId: HWUser.appId(), 
						userId: HWUser.userId(),
						...request
				}
				return axios.get(url, aConfig);
			}
			return cache.get(cacheKey, cache.CacheModule.smartSegments, fetchFunction);
	}

	updateSortPreference= async(data) => {

		let updateFunction = () => {
			let url = window.resolveRegion(process.env.REACT_APP_SMART_DASHBOARDS_BASE_URL) + "/smart-filter/user-sort-preference";
			let aConfig = { headers: { "Authorization": HWUser.accessToken(), "Content-Type": "application/json" } };
			let request = { userId: HWUser.userId(), orgId: HWUser.orgId(), appId: HWUser.appId(), ...data }
			return axios.put(url, request, aConfig);
		}
		const cacheKey = HWUser.userId() + data.path;
		const cacheReducer = (responseData) => {
			return responseData;
		}
		return await cache.cacheAndUpdateAfterResponse(cacheKey, cache.CacheModule.sortPreference, cacheReducer, updateFunction);
	}

	fetchViewPreference = (path) => {
		const cacheKey      = HWUser.userId() + path+"user_view";
		const fetchFunction = () => {
			let url     = window.resolveRegion(process.env.REACT_APP_SMART_DASHBOARDS_BASE_URL) + "/smart-filter/view";
			let aConfig = { headers: { "Authorization": HWUser.accessToken(), "Content-Type": "application/json" } };
			aConfig.params = { 
					orgId: HWUser.orgId(), 
					appId: HWUser.appId(), 
					userId: HWUser.userId(),
					path:path
			}
			return axios.get(url, aConfig);
		}
		return cache.get(cacheKey, cache.CacheModule.viewPreference, fetchFunction);
	}
	updateViewPreference = async (data) => {	
		let updateFunction = async() => {
			let url = window.resolveRegion(process.env.REACT_APP_SMART_DASHBOARDS_BASE_URL) + "/smart-filter/view";
			let aConfig = { headers: { "Authorization": HWUser.accessToken(), "Content-Type": "application/json" } };
			let request = { userId: HWUser.userId(), orgId: HWUser.orgId(), appId: HWUser.appId(), ...data }
			return axios.put(url, request, aConfig);
		}
		const cacheKey = HWUser.userId() + data.path+"user_view";
		const cacheReducer = (responseData) => {
			return responseData;
		}
		return await cache.cacheAndUpdateAfterResponse(cacheKey, cache.CacheModule.viewPreference, cacheReducer, updateFunction);
	}

	updateColumnPreference = (data) => {
		// console.log("mmmm==");
		let updateFunction = () => {
			let url = window.resolveRegion(process.env.REACT_APP_SMART_DASHBOARDS_BASE_URL) + "/user-column-preference";
			let aConfig = { headers: { "Authorization": HWUser.accessToken(), "Content-Type": "application/json" } };
			let request = { userId: HWUser.userId(), orgId: HWUser.orgId(), appId: HWUser.appId(), ...data }
			return axios.put(url, request, aConfig);
		}
		try {
			const cacheKey = HWUser.userId() + data.path;


			const cacheReducer = (cachedData) => {
				 if(data?.updatedColOrder){
					let updatedColumnOrder = cloneDeep(data.updatedColOrder);
					cachedData.data.columnPreference = updatedColumnOrder;
					return cachedData;
					
				 }else if (cachedData?.data?.columnPreference?.some(i => i.columnId === data.columnId)) {
					cachedData.data.columnPreference = cachedData.data.columnPreference.map(i => {
							if (i.columnId === data.columnId) {
									i.isEnabled = data.isEnabled;
							}
							return i;
					});
					return cachedData;
			  }
				throw new Error("Invalidate Cache:");
			}
			return cache.cacheAndUpdate(cacheKey, cache.CacheModule.userColumnPreference, cacheReducer, updateFunction);
		} catch {
			return updateFunction();
		}
	}

    expansionAnalyzerCsmData = (request) => {
        let url = window.resolveRegion(process.env.REACT_APP_SMART_DASHBOARDS_BASE_URL) + "/expansion-analyzer/csm-performance";
        let aConfig = { headers: { "Authorization": HWUser.accessToken(), "Content-Type": "application/json","addFilter": true } };
        let data = { 
            orgId: HWUser.orgId(), 
            appId: HWUser.appId(), 
            userId: HWUser.userId(),
            ...request 
        }
        return hwAxios.post(url, data, aConfig);
    }
		
	expansionAnalyzerCsmreakDownData = (request, callingFrom) => {
		if (callingFrom === 'Account360') {
			let url = window.resolveRegion(process.env.REACT_APP_SMART_DASHBOARDS_BASE_URL) + "/account-view/dashboard/revenue-trend/detail";
			let config = { headers: { "Authorization": HWUser.accessToken(), "Content-Type": "application/json", "addFilter": true } };
			config.params = {
				orgId: HWUser.orgId(),
				appId: HWUser.appId(),
				userId: HWUser.userId(),
				...request
			}
			return hwAxios.get(url, config);
		} else {
			let data = {
				appId: HWUser.appId(),
				userId: HWUser.userId(),
				orgId: HWUser.orgId(),
				...request
			}

			let url = window.resolveRegion(process.env.REACT_APP_SMART_DASHBOARDS_BASE_URL) + "/expansion-analyzer/opportunities";
			let config = { headers: { "Authorization": HWUser.accessToken(), "Content-Type": "application/json", "addFilter": true } };
			return hwAxios.post(url, data, config);
		}


	}


    expansionAnalyserShareAndExport = (request) => {
        let url = window.resolveRegion(process.env.REACT_APP_SMART_DASHBOARDS_BASE_API_URL) + "/expansion-analyzer/export";
        let aConfig = { headers: { "Authorization": HWUser.accessToken(), "Content-Type": "application/json","addFilter": true } };
        aConfig.params = { 
            orgId: HWUser.orgId(), 
            appId: HWUser.appId(), 
            userId: HWUser.userId(),
            ...request 
        }
        return hwAxios.get(url, aConfig);
    }

}

var skExpansionAnalyserService = new SkExpansionAnalyserService();

export default skExpansionAnalyserService;