
import axios from 'axios';
import HWUser from './HWUser';
class HWLog {

    static config = () => { return { headers: { "Authorization": "Bearer " + this.accessToken } } };

    static log = (logType, message, logParamObejct) => {
        try {
            // let url  = window.resolveRegion(process.env.REACT_APP_PLATFORM_BASE_API_URL_WEB_ADMIN) + "/logs";
            let data = { logType: logType, message: message , userId: HWUser.userId(), appId: HWUser.appId(), orgId: HWUser.orgId(), orgName: HWUser.orgName() }
            if (logParamObejct) {
                data.logParams = logParamObejct;
            }
            // if (window.navigator && window.navigator.sendBeacon) { // Beacon API
            //     let headers = { type: "application/json" };
            //     let blob    = new Blob([JSON.stringify(data)], headers);
            //     window.navigator && window.navigator.sendBeacon(url, blob);
            //      return Promise.resolve("SUCCESS");
            // } else {
                // return axios.post(url, data, this.config());
            // }
        } catch (error) { return Promise.reject("Could Not Log"); }
    }

    // enum PlatformLogType { Visit, LogOut, LogIn }
    static PlatformFeature = { Touchpoint:"Touchpoint", SuccessPlay:"SuccessPlay", Alerts:"Alerts" }
    static logVisit = (feature, metadataObject) => {
        try {
            let url  = window.resolveRegion(process.env.REACT_APP_PLATFORM_BASE_API_URL_WEB_ADMIN) + "/logs/async";
            let metadataJSON = (metadataObject) ? JSON.stringify(metadataObject): null;
            let data = { feature: feature, logType: "Visit", userId: HWUser.userId(), appId: HWUser.appId(),loggedAtTime: new Date().toISOString() , metadataJSON: metadataJSON }
            // if (window.navigator && window.navigator.sendBeacon) { // Beacon API
            //     let headers = { type: "application/json" };
            //     let blob    = new Blob([JSON.stringify(data)], headers);
            //     window.navigator && window.navigator.sendBeacon(url, blob);
            //      return Promise.resolve("SUCCESS");
            // } else {
                return axios.post(url, data, this.config());
            // }
        } catch (error) { return Promise.reject("Could Not Log Visit"); }
    }

}

export default HWLog;