import { ClickAwayListener } from '@material-ui/core';
import { styled } from '@mui/material/styles';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import React, { useEffect, useRef, useState } from 'react';
import UiLabel from '../SkSmartUtil/UiLabel/UiLabel';


const SKSearch = (props) => {

	const [state, setState] = useState({
		isError: '',
		searchStr: props.searchStr,
		list: [],
		listAll: [],
		resetList: [],
		showSearch: false,
		searchLength: props.searchLength ? props.searchLength : 0
	})

	useEffect(() =>{
		setState({searchStr: props.searchStr})
	},[props?.searchStr]);


	useEffect(() =>{
		setStates({ searchStr: '', /* showFilters: true, */ isCloseSearch: true, showSearch: false });
			props?.setIsSearchBarFocused && props?.setIsSearchBarFocused(false);
	},[props.inflowActiveTab])

	const setStates = (obj) => {
		setState((prevState) => (
			{
				...prevState,
				...obj
			}
		))
	}

	useEffect(() => {
		setStates({ list: props?.list, listAll: props?.list })
	}, [])

	useEffect(() => {
		setStates({ list: props?.list })
	}, [props?.list])

	const onChangeHandler = (text) => {
		setStates({ searchStr: text });
		let matches = []
		if (props?.searchType !== 'api') {
			if (text?.length > 0) {
				matches = (state.list || []).filter(item => {
					return (item?.[props?.searchFiled]?.toLowerCase())?.match(text?.toLowerCase())
				})
			}
			matches = text?.length > 0 ? matches : state?.listAll;
		}
		else {
			matches = text;
		}
		props.onChange(matches, text)
	}

	const onClickHandler = (val,e) => {
		if(state?.searchStr?.length > 0){ return;}
		setStates({ showSearch: val });
		props.isFocus && props.isFocus(val);
		props?.setIsSearchBarFocused && props?.setIsSearchBarFocused(val);
	}

	const onClearHandler = () => {
		// setFocus(inputRef); 
			setStates({ searchStr: '', /* showFilters: true, */ isCloseSearch: true, showSearch: false });
			props?.setIsSearchBarFocused && props?.setIsSearchBarFocused(false);
			onChangeHandler('');
	}

	return (
		<span>
			<ClickAwayListener onClickAway={(e) => { onClickHandler(false,e) }}>
				<span className={"smart__sk__search__container " + props?.className}>
					<div className="mini-toolbar version-2">
						<div className="mini-toolbar-left">
							<StyledTooltip title={"Search"}>
								<form action=""  style={{ zIndex: props?.zIndex ? props?.zIndex : 1 }}>
									<SearchWrapper disabled={props?.disabled || false}>
										<input type="text"
											style={{	width: (state?.searchStr?.length > 0 || state?.showSearch) ? "5px" : "",fontSize: '14px', height: "30px", fontWeight: "400"}} 
											disabled={props?.disabled || false}
											className={`${(state?.showSearch) ? "onFocus" : "onBlur"} ${props?.disabled ? 'sk-btnDisabled' : ''}`}
											// onFocus={() => onClickHandler(true)}
											onClick={(e) => onClickHandler(true)} autoComplete='off' id="segment" onChange={(e) => { onChangeHandler(e?.target?.value) }} placeholder={props.placeholder? "Search "+ props.placeholder : "Search"} value={state.searchLength === 0 ? state.searchStr : state?.searchStr?.substring(0, state.searchLength)} />

										{(state?.searchStr?.length > 0 && state?.showSearch) && 
											<div className="sk-smart_white_closeBtn" onClick={(e) => { onClearHandler(e) }} ></div>
										}
										<input className=" sk_icon sk_smart_seach_icon" readOnly value="Search" />
									</SearchWrapper>
								</form>
							</StyledTooltip>
						</div>
					</div>
				</span>
			</ClickAwayListener>
		</span>);
}

export default SKSearch;

const SearchWrapper = (props) => {
	return (props?.disabled ? <UiLabel label='Please, select an account ' length={15}>{props.children}</UiLabel> : props.children)

}

const StyledTooltip = styled(({ className, ...props }) => (
	<Tooltip {...props} classes={{ popper: className }} />
))


	(() => ({
		[`& .${tooltipClasses.tooltip}`]: {
			backgroundColor: "var(--sk-heading-color)",
			marginTop: "-8px !important",
			top: "7px ",
			position: "absolute",
		}
	}));