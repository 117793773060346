import { store } from "../index";
import HWUser from "../../HWLibrary/HWUser";
import { apiCall } from "../api";
import { skTouchpointActions } from "../constants/actionType";


export const getSmartTochpointStates = () => {
	return store.getState()?.smartTouchpointReducer;
}

export const setSmartTochpointStates = (obj) => async dispatch => {
	dispatch({ type: skTouchpointActions?.SET_SMART_TOUCHPOINT_STATES, payload: obj})
}
const parseOptions = (data) => {
	return (data || []).map((item, index) => {
			return { value: item.id, valid: true, label: item.name }
	})
}
const sortOpt = (data) => {
	data = data && data.sort((a, b) => {
			return ((a.label) || " ").localeCompare(((b.label) || " "));
	})
	return data;
}
// fetch collaborators list
export const fetchTochpointRefenceData = () => async dispatch => {
	let request = {};
	request.params = { orgId: HWUser.orgId(), appId: HWUser.appId(), userId: HWUser.userId() };
	dispatch(setSmartTochpointStates({ isLoading: true }))
	try {
		const response = await apiCall({
			url: window.resolveRegion(process.env.REACT_APP_SMART_DASHBOARDS_BASE_URL) + "/touchpoints/reference-data",
			method: "GET",
			payload: request,
			addFilter: true
		});
		dispatch({
			type: skTouchpointActions?.SET_SMART_TOUCHPOINT_STATES,
			payload: {
				touchpointCategory:sortOpt(parseOptions(response?.data?.touchpointCategory)),
				touchpointType:sortOpt(parseOptions(response?.data?.touchpointType)),
				outcome:sortOpt(parseOptions(response?.data?.outcome))
			}
		})
		dispatch(setSmartTochpointStates({ isLoading: false , isSuccess: true}))
	} catch (error) {
		dispatch(setSmartTochpointStates({ isError: true, errorMessage: error?.response?.data?.message, isLoading: false }))
		setTimeout(() => {
			dispatch(setSmartTochpointStates({ errorMessage: '', isError: false }))
		}, 2000)
	}
}

// create touchpoint
export const createTouchpoint = (data) => async dispatch => {
	let request = { orgId: HWUser.orgId(), appId: HWUser.appId(), userId: HWUser.userId(), ...data };
	dispatch(setSmartTochpointStates({ isLoading: true }));
	try {
		const response = await apiCall({
			url: window.resolveRegion(process.env.REACT_APP_SMART_DASHBOARDS_BASE_URL) + "/touchpoints/smart-touchpoint",
			method: "POST",
			payload: request,
		});
		dispatch(setSmartTochpointStates({disableSort: true}))
		dispatch(setSmartTochpointStates({ isLoading: false, successMsg: "Touchpoint Added Successfully." }))
		setTimeout(() => {
			dispatch(setSmartTochpointStates({ errorMessage: '', isError: false,successMsg:null }))
		}, 2000)
		return "Touchpoint Added Successfully."
	} catch (error) {
		dispatch({
			type: skTouchpointActions?.SET_IS_ERROR,
			payload: { isError: true, errorMessage: error?.response?.data?.message, isLoading: false }
		})
		setTimeout(() => {
			dispatch(setSmartTochpointStates({ errorMessage: '', isError: false }))
		}, 2000)
	}
}

// update touchpoint
export const updateTouchpoint = (data) => async dispatch => {
	let request = { orgId: HWUser.orgId(), appId: HWUser.appId(), userId: HWUser.userId(), ...data };
	dispatch(setSmartTochpointStates({ isLoading: true }));
	try {
		const response = await apiCall({
			url: window.resolveRegion(process.env.REACT_APP_SMART_DASHBOARDS_BASE_URL) +"/touchpoints/smart-touchpoint",
			method: "PUT",
			payload: request,
		});
		dispatch(setSmartTochpointStates({disableSort: true}))
		dispatch(setSmartTochpointStates({ isLoading: false, successMsg:data?.archive === true?"Touchpoint has been archived successfully.":"Touchpoint Updated Successfully.", editedTouchpoint:response?.data?.touchpoint}))
		setTimeout(() => {
			dispatch(setSmartTochpointStates({ errorMessage: '', isError: false,successMsg:null }))
		}, 2000)
	} catch (error) {
		dispatch({
			type: skTouchpointActions?.SET_IS_ERROR,
			payload: { isError: true, errorMessage: error?.response?.data?.message, isLoading: false }
		})
		setTimeout(() => {
			dispatch(setSmartTochpointStates({ errorMessage: '', isError: false }))
		}, 2000)
	}
}
// fect outCome Data

export const fetchTouchpointOutComesData = (searchText,dontSetdeafeault) => async dispatch => {
  let request = {};
  request.params = { orgId: HWUser.orgId(), appId: HWUser.appId(), userId: HWUser.userId(),searchText:searchText };
  if(!dontSetdeafeault){ dispatch(setSmartTochpointStates({ isLoadingOtcomeData: true,deafeaultOpendIds:null }))}
  try {
    const response = await apiCall({
      url: window.resolveRegion(process.env.REACT_APP_SMART_DASHBOARDS_BASE_URL) + "/touchpoints/dashboard",
      method: "GET",
      payload: request,
      addFilter: true
    });
    let payloadData = {
      isLoadingOtcomeData:false,
      outcomes:response?.data?.outcomes,
      typeCount:response?.data?.typeCount,
    }
    if(!dontSetdeafeault){payloadData = {...payloadData,deafeaultOpendIds:[response?.data?.outcomes[0]?.outcomeId]}}
    dispatch({
      type: skTouchpointActions?.SET_SMART_TOUCHPOINT_STATES,
      payload: {...payloadData}
    })
    dispatch(setSmartTochpointStates({ isLoadingOtcomeData: false , isSuccess: true}))
  } catch (error) {
    dispatch(setSmartTochpointStates({ isError: true, errorMessage: error?.response?.data?.message, isLoadingOtcomeData: false }))
    setTimeout(() => {
      dispatch(setSmartTochpointStates({ errorMessage: '', isError: false }))
    }, 2000)
  }
}
export const fetchTouchpointWithOutComesData = (searchText,dontSetdeafeault, accountIdForTouchpoint, filterContactIds, view) => async dispatch => {

	let request =  {
		orgId: HWUser.orgId(),
		appId: HWUser.appId(),
		userId: HWUser.userId(),
		searchText: searchText,
		view:view ? view: getSmartTochpointStates()?.touchpointActiveView,
		accountIds: accountIdForTouchpoint ? [accountIdForTouchpoint] : null,
		filterContactIds: filterContactIds ? filterContactIds : null,
		disableSort: getSmartTochpointStates()?.disableSort 

	};
	if(!dontSetdeafeault){
		 dispatch(setSmartTochpointStates({ isLoadingOtcomeData: true,deafeaultOpendIds:null }))
		}
	try {
		const response = await apiCall({
			url: window.resolveRegion(process.env.REACT_APP_SMART_DASHBOARDS_BASE_URL) + "/touchpoints/listing",
			method: "POST",
			payload: request,
			addFilter: true
		});
		const validOutComes = response?.data?.data && response?.data?.data?.length > 0 && response?.data?.data?.map(data => {
			return {
				categoryId: data?.categoryId,
				categoryName: data?.categoryName,
				colorCode: data?.colorCode,

			}
		})
		let payloadData = {
			isLoadingOtcomeData:false,
			outcomes:validOutComes,
			typeCount:response?.data?.typeCount,
		}


		if(!dontSetdeafeault){payloadData = {...payloadData,deafeaultOpendIds:[response?.data?.data[0]?.categoryId]}}
		dispatch({
			type: skTouchpointActions?.SET_SMART_TOUCHPOINT_STATES,
			payload: {...payloadData}
		})
		dispatch(setSmartTochpointStates({
			touchpointsData: response?.data?.data,
			touchpointSortList: response?.data?.sortList,
			touchpointTypeCount: response?.data?.typeCounts,
			
		}))

		if (!getSmartTochpointStates()?.touchpointActiveView) {
			dispatch(setSmartTochpointStates({
				touchpointActiveView: response?.data?.view ? response?.data?.view : "list",
			}))
		}
		
		dispatch(setSmartTochpointStates({ isLoadingOtcomeData: false , isSuccess: true}))
	} catch (error) {
		dispatch(setSmartTochpointStates({ isError: true, errorMessage: error?.response?.data?.message, isLoadingOtcomeData: false }))
		setTimeout(() => {
			dispatch(setSmartTochpointStates({ errorMessage: '', isError: false }))
		}, 2000)
	}
}


export const fetchTouchpointUpdateType = ()=> async dispatch => {
	let request = {};
	request.params = { orgId: HWUser.orgId(), appId: HWUser.appId(), userId: HWUser.userId() };
	dispatch(setSmartTochpointStates({ isLoadingOtcomeData: true }))
	try {
		const response = await apiCall({
			url: window.resolveRegion(process.env.REACT_APP_SMART_DASHBOARDS_BASE_URL) + "/touchpoints/dashboard",
			method: "GET",
			payload: request,
			addFilter: true
		});
		dispatch({
			type: skTouchpointActions?.SET_SMART_TOUCHPOINT_STATES,
			payload: {
				isLoadingOtcomeData:false,
				// outcomes:response?.data?.outcomes,
				typeCount:response?.data?.typeCount,
				// deafeaultOpendIds:[response?.data?.outcomes[0]?.outcomeId]
			}
		})
		dispatch(setSmartTochpointStates({ isLoadingOtcomeData: false , isSuccess: true}))
	} catch (error) {
		dispatch(setSmartTochpointStates({ isError: true, errorMessage: error?.response?.data?.message, isLoadingOtcomeData: false }))
		setTimeout(() => {
			dispatch(setSmartTochpointStates({ errorMessage: '', isError: false }))
		}, 2000)
	}
}
export const singleTouchpoint = (req) => async dispatch => {
	let request = {};
	request.params = { orgId: HWUser.orgId(), appId: HWUser.appId(), userId: HWUser.userId(), ...req };
	dispatch(setSmartTochpointStates({ isLoading: true }))
	try {
		const response = await apiCall({
			url: window.resolveRegion(process.env.REACT_APP_DASHBOARDS_BASE_API_URL) + "/touchpoints",
			method: "GET",
			payload: request,
			addFilter: true
		});
		dispatch({
			type: skTouchpointActions?.SET_SMART_TOUCHPOINT_STATES,
			payload: {
				singleTouchpointData : response?.data?.touchpoint
			}
		})
		dispatch(setSmartTochpointStates({ isLoading: false , isSuccess: true}))
		return response?.data?.touchpoint;
	} catch (error) {
		dispatch(setSmartTochpointStates({ isError: true, errorMessage: error?.response?.data?.message, isLoading: false }))
		setTimeout(() => {
			dispatch(setSmartTochpointStates({ errorMessage: '', isError: false }))
		}, 2000)
	}
}