import React, { Suspense, lazy } from 'react';
import { createRoot } from 'react-dom/client';
import App from './App';
import * as serviceWorker from './serviceWorker';
import { ToastsContainer, ToastsStore } from "react-toasts";
import { store } from '../src/store/index';
import { Provider } from 'react-redux';
import SkLoaderComponent from './SkSmartUtil/SkLoader/SkLoaderComponent';



// const App = lazy(() => import('./App'));





// ReactDOM.render(<Provider store={store}><BrowserRouter>
// <Suspense fallback={<BlockUi blocking={true}/> }>  <App/></Suspense> 
// <ToastsContainer store={ToastsStore} /></BrowserRouter></Provider>, document.getElementById('root'));

const container = document.getElementById('root');
const root = createRoot(container); // createRoot(container!) if you use TypeScript
root.render(<Provider store={store}>
     {/* <Suspense fallback={<SkLoaderComponent isLoading={true} className="skLoaderComponent" />}> */}
		  <App/>
			{/* </Suspense>  */}
     <ToastsContainer store={ToastsStore} /></Provider>);
     
// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();

