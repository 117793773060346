import { store } from "../index";
import HWUser from "../../HWLibrary/HWUser";
import { apiCall } from "../api";
import { skKonversationActions } from "../constants/actionType";
import { getTrans } from "../../SkSmartUtil/SkLocalizer/Localizer";
import HWLocalStorage from "../../HWLibrary/HWLocalStorage";

export const setLoading = (isLoading, key) => async dispatch => {
	let loadder = isLoading ? "isLoading" : key ? key : "isApiLoading";
	dispatch({ type: skKonversationActions?.SET_KONVERSATIONS_LOADING, payload: { [loadder]: true } });
};

export const getKonversationState = () => {
	return store.getState()?.smartKonv;
}
// setStates
export const setSmartKState = (obj) => async dispatch => {
	dispatch({ type: skKonversationActions?.SET_SMARTK_STATE, payload: obj });
};


export const fetchKonversations = (isLoading, nextToken, searchText, sortBy, key, category, activeTab) => async (dispatch) => {
	let loadder = isLoading ? 'isLoading' : 'isApiLoading'
	let request = {};
	request.params = { orgId: HWUser.orgId(), appId: HWUser.appId(), userId: HWUser.userId(), filter: key ? key : 'MY_TOUCHPOINTS', searchText: searchText, nextToken: nextToken, sortBy: sortBy, category: 'External' }
	dispatch(setLoading(isLoading));
	if (activeTab === 'my_conversations') {
		dispatch(setSmartKState({ myKonLoader: isLoading ? true : false }))
	}
	if (activeTab === 'team_conversations') {
		dispatch(setSmartKState({ teamKonLoader: isLoading ? true : false }))
	}
	try {

		const response = await apiCall({
			url: window.resolveRegion(process.env.REACT_APP_SMART_DASHBOARDS_BASE_API_URL) + "/smart-konversation",
			method: "GET",
			payload: request,
			addFilter: true
		});

		if (activeTab === 'my_conversations') {
			dispatch(setSmartKState({ myKonLoader: false }))
		}
		if (activeTab === 'team_conversations') {
			dispatch(setSmartKState({ teamKonLoader: false }))
		}

		if (getKonversationState().activeTab === activeTab) {
			dispatch({
				type: skKonversationActions?.FETCH_KONVERSATIONS_LIST,
				payload: { list: ((activeTab === 'my_conversations' && key === 'MY_TOUCHPOINTS') || (activeTab === 'team_conversations' && key === 'TEAM_TOUCHPOINTS')) ? response?.data : [], [loadder]: false, sortSearch: sortBy },
			});
		}

	} catch (error) {
		if (getKonversationState().activeTab === activeTab) {
			dispatch(setSmartKState({ errorMessage: error?.response?.data?.message, [loadder]: false }))
		}
	}
};

export const deleteKonversations = (isLoading, id) => async (dispatch) => {

	let loadder = isLoading ? 'isLoading' : 'isApiLoading'
	let request = {};
	request.params = { orgId: HWUser.orgId(), appId: HWUser.appId(), userId: HWUser.userId(), id: id }
	dispatch(setLoading(isLoading));
	try {
		const response = await apiCall({
			url: window.resolveRegion(process.env.REACT_APP_SMART_DASHBOARDS_BASE_API_URL) + "/smart-konversation",
			method: "DELETE",
			payload: request,
		});

		if (response?.data?.message === 'Success') { dispatch(fetchKonversations(true)) }
		dispatch({
			type: skKonversationActions?.FETCH_KONVERSATIONS_LIST,
			payload: { /* list: response?.data,  */[loadder]: false },
		});
	} catch (error) {
		/* toast.error(error.response.data.message); */
		dispatch(setSmartKState({ errorMessage: error?.response?.data?.message, [loadder]: false }))
	}
};

export const fetchTranscripts = (meetingId, isLoading, sortBy) => async (dispatch) => {

	let loadder = isLoading ? 'isLoading' : 'isTransApiLoading';

	let request = {};
	request.params = { orgId: HWUser.orgId(), appId: HWUser.appId(), userId: HWUser.userId(), meetingId: meetingId }
	//
	//dispatch(setLoading(isLoading,'isTransApiLoading'));
	dispatch(setSmartKState({ [loadder]: true }))
	try {
		const response = await apiCall({
			url: window.resolveRegion(process.env.REACT_APP_SMART_DASHBOARDS_BASE_API_URL) + "/smart-konversation/detail",
			method: "GET",
			payload: request,
		});


		if (response?.data?.transcripts) {
			dispatch({
				type: skKonversationActions?.FETCH_TRANSCRIPTS,
				payload: { transcripts: response?.data?.transcripts, transcriptRating: response?.data?.transcriptRating, touchpointDetail: response?.data?.touchpointDetail, videoUrl: response?.data?.videoURL, sortSearch: sortBy },
			});
			setTimeout(() => { dispatch(setSmartKState({ [loadder]: false })) }, 1000)
		}

	} catch (error) {
		/* toast.error(error.response.data.message); */
		dispatch(setSmartKState({ errorMessage: error?.response?.data?.message, [loadder]: false }))
	}
};

export const fetchSummary = (meetingId, isLoading) => async (dispatch) => {

	let loadder = isLoading ? 'isLoading' : 'isSummaryApiLoading';

	let request = {};
	request.params = { orgId: HWUser.orgId(), appId: HWUser.appId(), userId: HWUser.userId(), meetingId: meetingId }
	dispatch(setSmartKState({ [loadder]: true }))
	try {
		const response = await apiCall({
			url: window.resolveRegion(process.env.REACT_APP_SMART_DASHBOARDS_BASE_API_URL) + "/smart-konversation/summary",
			method: "GET",
			payload: request,
		});


		if (response?.data) {
			dispatch({
				type: skKonversationActions?.CALL_SUMMARY,
				payload: { summary: response?.data?.summary, actionItem: response?.data?.actionItems },
			});
			setTimeout(() => { dispatch(setSmartKState({ [loadder]: false })) }, 1000)
		}

	} catch (error) {
		dispatch(setSmartKState({ errorMessage: error?.response?.data?.message, [loadder]: false, summary: null }))
	}
};

export const fetchReferenceData = (isLoading) => async (dispatch) => {

	let loadder = isLoading ? 'isLoading' : 'isReferenceDataLoading';

	let request = {};
	request.params = { orgId: HWUser.orgId(), appId: HWUser.appId(), userId: HWUser.userId() }
	dispatch(setSmartKState({ [loadder]: true }))
	try {
		const response = await apiCall({
			url: window.resolveRegion(process.env.REACT_APP_SMART_DASHBOARDS_BASE_URL) + "/smart-konversation/referencedata",
			method: "GET",
			payload: request,
		});
		if (response?.data) {
			HWLocalStorage.save(response?.data?.teamKonversationVisible,"teamKonversationVisible");
			HWLocalStorage.save(response?.data?.SmartKonDashboardVisible,"SmartKonDashboardVisible");
			dispatch({
				type: skKonversationActions?.FETCH_REFERENCE_DATA,
				payload: { referenceData: response?.data, teamKonversationVisible:response?.data?.teamKonversationVisible, SmartKonDashboardVisible:  response?.data?.SmartKonDashboardVisible },
			});
			setTimeout(() => { dispatch(setSmartKState({ [loadder]: false })) }, 1000)
		}

	} catch (error) {
		dispatch(setSmartKState({ errorMessage: error?.response?.data?.message, [loadder]: false, referenceData: null }))
	}
};

export const editCoaching = (data, isLoading) => async (dispatch) => {

	let loadder = isLoading ? 'isLoading' : 'isApiLoading'
	let request = { orgId: HWUser.orgId(), appId: HWUser.appId(), userId: HWUser.userId(), ...data }
	dispatch(setSmartKState({ [loadder]: true }))
	try {
		const response = await apiCall({
			url: window.resolveRegion(process.env.REACT_APP_SMART_DASHBOARDS_BASE_API_URL) + "/smart-konversation/coach",
			method: "POST",
			payload: request,
		});

		if (response?.data?.message === 'Success') {
			dispatch({
				type: skKonversationActions?.EDIT_COACHING,
				payload: { [loadder]: false, successMsg: getTrans("commonUtilities:msg_saved_successfully") },
			});
			dispatch(fetchTranscripts(data?.meetingId, true))
		}
	} catch (error) {
		/* toast.error(error.response.data.message); */
		dispatch(setSmartKState({ errorMessage: error?.response?.data?.message, [loadder]: false }))
	}
};



export const setRating = (data, isLoading) => async (dispatch) => {

	let loadder = isLoading ? 'isLoading' : 'isApiLoading'
	let request = { orgId: HWUser.orgId(), appId: HWUser.appId(), userId: HWUser.userId(), rating: data?.rating, meetingId: data?.meetingId }
	dispatch(setLoading(isLoading));
	try {
		const response = await apiCall({
			url: window.resolveRegion(process.env.REACT_APP_SMART_DASHBOARDS_BASE_API_URL) + "/smart-konversation/rating",
			method: "POST",
			payload: request,
		});

		if (response?.data?.message === 'Success') {
			dispatch({
				type: skKonversationActions?.SET_RATING,
				payload: { [loadder]: false, successMsg: getTrans("commonUtilities:msg_saved_successfully") },
			});
			dispatch(fetchTranscripts(data?.meetingId, true))
		}
	} catch (error) {
		/* toast.error(error.response.data.message); */
		dispatch(setSmartKState({ errorMessage: error?.response?.data?.message, [loadder]: false }))
	}
};


export const fetchComments = (meetingId, isLoading) => async (dispatch) => {

	let loadder = isLoading ? 'isLoading' : 'isApiLoading'
	let request = {};
	request.params = { orgId: HWUser.orgId(), appId: HWUser.appId(), userId: HWUser.userId(), meetingId: meetingId }
	dispatch(setLoading(isLoading));
	try {
		const response = await apiCall({
			url: window.resolveRegion(process.env.REACT_APP_SMART_DASHBOARDS_BASE_API_URL) + "/smart-konversation/comment",
			method: "GET",
			payload: request,
		});
		dispatch({
			type: skKonversationActions?.FETCH_COMMENTS,
			payload: { comments: response?.data, [loadder]: false },
		});
	} catch (error) {
		/* toast.error(error.response.data.message); */
		dispatch(setSmartKState({ errorMessage: error?.response?.data?.message, [loadder]: false }))
	}
};

export const addComment = (data, isLoading) => async (dispatch) => {

	let loadder = isLoading ? 'isLoading' : 'isApiLoading'
	let request = { orgId: HWUser.orgId(), appId: HWUser.appId(), userId: HWUser.userId(), ...data }
	dispatch(setLoading(isLoading));
	try {
		const response = await apiCall({
			url: window.resolveRegion(process.env.REACT_APP_SMART_DASHBOARDS_BASE_API_URL) + "/smart-konversation/comment",
			method: "POST",
			payload: request,
		});

		if (response?.data?.message === 'Success') {
			dispatch({
				type: skKonversationActions?.EDIT_COACHING,
				payload: { [loadder]: false, successMsg: getTrans("commonUtilities:msg_saved_successfully") },
			});
			dispatch(fetchComments(data?.meetingId, true))
		}
	} catch (error) {
		/* toast.error(error.response.data.message); */
		dispatch(setSmartKState({ errorMessage: error?.response?.data?.message, [loadder]: false }))
	}
};

export const editComment = (data, isLoading) => async (dispatch) => {

	let loadder = isLoading ? 'isLoading' : 'isApiLoading'
	let request = { orgId: HWUser.orgId(), appId: HWUser.appId(), userId: HWUser.userId(), ...data }
	dispatch(setLoading(isLoading));
	try {
		const response = await apiCall({
			url: window.resolveRegion(process.env.REACT_APP_SMART_DASHBOARDS_BASE_API_URL) + "/smart-konversation/comment",
			method: "PUT",
			payload: request,
		});

		if (response?.data?.message === 'Success') {
			dispatch({
				type: skKonversationActions?.EDIT_COMMENT,
				payload: { [loadder]: false, successMsg: getTrans("commonUtilities:msg_saved_successfully") },
			});
			dispatch(fetchTranscripts(data?.meetingId, false))
		}
	} catch (error) {
		/* toast.error(error.response.data.message); */
		dispatch(setSmartKState({ errorMessage: error?.response?.data?.message, [loadder]: false }))
	}
};

export const deleteComment = (id, isLoading) => async (dispatch) => {

	let loadder = isLoading ? 'isLoading' : 'isApiLoading'
	let request = {};
	request.params = { orgId: HWUser.orgId(), appId: HWUser.appId(), userId: HWUser.userId(), commentId: id }
	dispatch(setLoading(isLoading));
	try {
		const response = await apiCall({
			url: window.resolveRegion(process.env.REACT_APP_SMART_DASHBOARDS_BASE_API_URL) + "/smart-konversation/comment",
			method: "DELETE",
			payload: request,
		});

		if (response?.data?.message === 'Success') { dispatch(fetchKonversations(false)) }
		dispatch({
			type: skKonversationActions?.DELETE_COMMENT,
			payload: { /* list: response?.data,  */[loadder]: false },
		});
	} catch (error) {
		/* toast.error(error.response.data.message); */
		dispatch(setSmartKState({ errorMessage: error?.response?.data?.message, [loadder]: false }))
	}
};

// Flags - Manage Keywords ==================================
export const fetchReferenceKeywords = (showSkeleton, req, refreshTable) => async (dispatch) => {
	let request = {};
	request.params = { orgId: HWUser.orgId(), appId: HWUser.appId(), userId: HWUser.userId(), ...req }
	if (refreshTable === 'RedAlert') {
		dispatch(setSmartKState({ isManageKeywordRedAlertKeywordLoader: true }));
	} else if (refreshTable === 'AmberAlert') {
		dispatch(setSmartKState({ isManageKeywordAmberAlertKeywordLoader: true }));
	} else {
		dispatch(setSmartKState({ isManageKeywordRedAlertKeywordLoader: true, isManageKeywordAmberAlertKeywordLoader: true, isManageKeywordSkeleton: showSkeleton }));
	}

	try {
		const response = await apiCall({
			url: window.resolveRegion(process.env.REACT_APP_SMART_DASHBOARDS_BASE_URL) + "/smart-konversation/keywords",
			method: "GET",
			payload: request,
		});
		if (response?.status === 200) {
			if (refreshTable === 'RedAlert') {
				dispatch({
					type: skKonversationActions?.FETCH_MANAGE_KEYWORD_REFERENCE_DATA,
					payload: {
						redAlertKeywordData: response?.data?.redKeywords
					}
					
				});
			} else if (refreshTable === 'AmberAlert') {
				dispatch({
					type: skKonversationActions?.FETCH_MANAGE_KEYWORD_REFERENCE_DATA,
					payload: {
						amberAlertKeywordData: response?.data?.amberKeywords
					}
					
				});
			} else {
				dispatch({
					type: skKonversationActions?.FETCH_MANAGE_KEYWORD_REFERENCE_DATA,
					payload: {
						redAlertKeywordData: response?.data?.redKeywords,
						amberAlertKeywordData : response?.data?.amberKeywords},
				});
			}

		}
		dispatch(setSmartKState({ isManageKeywordRedAlertKeywordLoader: false , isManageKeywordAmberAlertKeywordLoader: false ,    isManageKeywordSkeleton: false}));
	} catch (error) {
		dispatch(setSmartKState({ errorMessage: error?.response?.data?.message, isManageKeywordRedAlertKeywordLoader: false , isManageKeywordAmberAlertKeywordLoader: false , }))
	}
};

export const updateReferenceKeywords = (data) => async (dispatch) => {
	let request = { orgId: HWUser.orgId(), appId: HWUser.appId(), userId: HWUser.userId(), ...data }
	dispatch(setSmartKState({blockConfirmationManageKeywordPopup: true, isManageKeywordLoader: true}));
	try {
		const response = await apiCall({
			url: window.resolveRegion(process.env.REACT_APP_SMART_DASHBOARDS_BASE_URL) + "/smart-konversation/keywords",
			method: "PUT",
			payload: request,
		});
		if (response?.status === 200) {
			dispatch(setSmartKState({ blockConfirmationManageKeywordPopup: false, isManageKeywordLoader:false }));
			return 'Keyword(s) Added Successfully.'
		} else {
			dispatch(setSmartKState({ blockConfirmationManageKeywordPopup: false, isManageKeywordLoader:false }));
			return 'Keyword Already Exist.'
		}
	} catch (error) {
		dispatch(setSmartKState({ errorMessage: error?.response?.data?.message, isManageKeywordLoader: false }))
		return 'Keyword Already Exist.'
	}
};


export const teamMetricsData = (req, showSkeleton) => async(dispatch) => {
	let request = {}
	request.params = {  orgId: HWUser.orgId(), appId: HWUser.appId(), userId: HWUser.userId(), ...req};
	dispatch(setSmartKState({ teamMetricsLoading: true, teamMetricsSkeleton: showSkeleton}));
	try {
		const response = await apiCall({
			url: window.resolveRegion(process.env.REACT_APP_SMART_DASHBOARDS_BASE_URL) + "/konversation-dashboard",
			method: "GET",
			payload: request,
			addFilter: true,
		});
		let teamMetricsResponse = response?.data;
		if(req?.nextToken){
			teamMetricsResponse = [...getKonversationState().teamMetricsResponse,...response?.data]
		}
		dispatch(setSmartKState({ teamMetricsLoading: false, teamMetricsList: teamMetricsResponse,
			 teamMetricsSkeleton: false, teamMetricsNextToken:response?.data?.nextToken }));
	} catch (error) {
		dispatch(setSmartKState({ errorMessage: error?.response?.data?.message, teamMetricsLoading:false }))
	}
}

export const fetchCallSentimentData = (req) => async(dispatch) => {
	let request = {}
	request.params = {  orgId: HWUser.orgId(), appId: HWUser.appId(), userId: HWUser.userId(), ...req};
	dispatch(setSmartKState({callSentimentSkeleton: true}));
	try {
		const response = await apiCall({
			url: window.resolveRegion(process.env.REACT_APP_SMART_DASHBOARDS_BASE_URL) + "/konversation-dashboard/call-sentiment-summary",
			method: "GET",
			payload: request,
			addFilter: true,
		});
		dispatch(setSmartKState({callSentimentSummary: response?.data, callSentimentSkeleton:false}));
	} catch (error) {
		dispatch(setSmartKState({ errorMessage: error?.response?.data?.message, callSentimentSkeleton:false }))
	}
}

export const fetchCoachingScoreData = (req) => async(dispatch) => {
	let request = {}
	request.params = {  orgId: HWUser.orgId(), appId: HWUser.appId(), userId: HWUser.userId(), ...req};
	dispatch(setSmartKState({coachingScoreSkeleton: true, callSentimentSkeleton:true}));	try {
		const response = await apiCall({
			url: window.resolveRegion(process.env.REACT_APP_SMART_DASHBOARDS_BASE_URL) + "/konversation-dashboard/coaching-sentiment",
			method: "GET",
			payload: request,
			addFilter: true,
		});
		dispatch(setSmartKState({coachingScoreSummary: response?.data?.coachingScoreSummary, callSentimentSummary:response?.data?.sentimentScoreSummary,  coachingScoreSkeleton: false, callSentimentSkeleton:false}));
	} catch (error) {
		dispatch(setSmartKState({ errorMessage: error?.response?.data?.message, coachingScoreSkeleton:false, callSentimentSkeleton:false }))
	}
}

export const fetchLongestMonologueData = (req) => async(dispatch) => {
	let request = {}
	request.params = {  orgId: HWUser.orgId(), appId: HWUser.appId(), userId: HWUser.userId(), ...req};
	dispatch(setSmartKState({longestMonologueSkeleton: true}));
	try {
		const response = await apiCall({
			url: window.resolveRegion(process.env.REACT_APP_SMART_DASHBOARDS_BASE_URL) + "/konversation-dashboard/longest-monologue-summary",
			method: "GET",
			payload: request,
			addFilter: true,
		});
		dispatch(setSmartKState({monologueSummary: response?.data, longestMonologueSkeleton:false}));
	} catch (error) {
		dispatch(setSmartKState({ errorMessage: error?.response?.data?.message, longestMonologueSkeleton:false }))
	}
}

export const fetchTalkRatioData = (req) => async(dispatch) => {
	let request = {}
	request.params = {  orgId: HWUser.orgId(), appId: HWUser.appId(), userId: HWUser.userId(), ...req};
	dispatch(setSmartKState({talkRatioSkeleton: true, longestMonologueSkeleton: true}));
	try {
		const response = await apiCall({
			url: window.resolveRegion(process.env.REACT_APP_SMART_DASHBOARDS_BASE_URL) + "/konversation-dashboard/call-detail",
			method: "GET",
			payload: request,
			addFilter: true,
		});
		dispatch(setSmartKState({talkRatioSummary: response?.data?.talkRatioSummary, monologueSummary: response?.data?.monologueSummary, talkRatioSkeleton:false, longestMonologueSkeleton: false}));
	} catch (error) {
		dispatch(setSmartKState({ errorMessage: error?.response?.data?.message, talkRatioSkeleton:false, longestMonologueSkeleton: false}))
	}
}

export const exportDashboardData = (req) => async(dispatch) => {let request = {}
request.params = {  orgId: HWUser.orgId(), appId: HWUser.appId(), userId: HWUser.userId()};
try {
	const response = await apiCall({
		url: window.resolveRegion(process.env.REACT_APP_SMART_DASHBOARDS_BASE_URL) + "/konversation-dashboard/export-data",
		method: "GET",
		payload: request,
		addFilter: true,
	});
} catch (error) {
	dispatch(setSmartKState({ errorMessage: error?.response?.data?.message}))
}
}



export const deleteReferenceKeywords = (req) => async (dispatch) => {
	let request = {}
	request.params = {  orgId: HWUser.orgId(), appId: HWUser.appId(), userId: HWUser.userId(), ...req};
	dispatch(setSmartKState({blockConfirmationManageKeywordPopup: true, isManageKeywordLoader:true}));
	try {
		const response = await apiCall({
			url: window.resolveRegion(process.env.REACT_APP_SMART_DASHBOARDS_BASE_URL) + "/smart-konversation/keywords",
			method: "DELETE",
			payload: request,
		});
		if (response?.status === 200) {
			dispatch(setSmartKState({blockConfirmationManageKeywordPopup: false, isManageKeywordLoader:false}));
			return 'Keyword(s) Deleted Successfully.'
		} else {
			dispatch(setSmartKState({blockConfirmationManageKeywordPopup: false, isManageKeywordLoader:false}));
			return 'Something went wrong.'		
		}
	} catch (error) {
		dispatch(setSmartKState({ errorMessage: error?.response?.data?.message, isManageKeywordLoader:false }))
	}
};



