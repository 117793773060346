import { skGlobalActions } from "../constants/actionType";

const initialState = {
	isLoading: false,
	isError: false,
	konversationCount:0,
	kommunityCount: 0,
	noOfLogin: 0,
	showPortfolioJoyride: false,
	showKeyOutcomeJoyride: false,
	showWorkspaceJoyride: false,
	showExternalUserWorkspaceJoyride: false,
	taskCategoryDisplayName: 'Category',
	globalActiveTab: null,
	globalParentActiveTab: null,
	gridTablesLastAccessedRowArray: null,
	globalActiveSideBarTab:"My Workspace",
	showZoomPullover: false,
	persistAccount360Filter: false,
	persistNotesFilter: false,
	errorMessage:'',
	accountList: [],
	accountSegmentsList: [],
	globalSearchSelectedAccounts: null,
	globalSkSearchedText: undefined,
	showGlobalSKSearch: false,
	globalSearchOptionsOnSearch: [],
	notificationCheckedRefList: [],
	disableFilterPreferenceForAccount360: false,
	filterTabAccountList: [],
	outcomeVisibleName:JSON.parse(window.localStorage.getItem("platform-currentUser"))?.outcomeConfig?.outcomeVisibleName,
	outcomeStatus: JSON.parse(window.localStorage.getItem("platform-currentUser"))?.outcomeConfig?.status,
	showWelcomePlaybookPopup: false,
	showUndoToaster: false,
	showUndoToasterMessage:null,
	toasterUndoBtnClick: null,
	toasterUndoCrossClick: null,
	previousDataForUndo: null,
	isOtpSignup: false,
	otpSignupEmail: null,
	otpUserId: null,
	otpAuthTokenPath: null,

};



export const skGlobalReducer = (state = initialState, { type, payload }) => {
	switch (type) {
		case skGlobalActions.FETCH_ACCOUNT_SEGMENT_LIST:
		case skGlobalActions.SET_ACCOUNT_STATE:
		case skGlobalActions.FETCH_ACCOUNT_LIST:
		case skGlobalActions.SET_GLOBAL_STATE:
		case skGlobalActions.ADD_LOG:
		case skGlobalActions.GET_COUNT: { return { ...state, ...payload } }
		default: return state;
	}
};


export default skGlobalReducer