import React from 'react';
import norecordingIcon from '../assets/images/icons/norecording.png'

const NoRecordings = ({ adminEmailId, ...props }) => {

	return (
		<div className="noRecordings">
			<img src={norecordingIcon} className="norecordingIcon" alt="" />
			<div className="content">
				<p>Call recordings and transcripts are not available anymore. If you require them to be stored in future, get in touch with your <a href={`mailto:${adminEmailId}`} className="skLink">ADMIN</a> for a change in subscription.</p>

				<p>However you can continue to examine the analysis and information about the touchpoint</p>

			</div>
		</div>
	);
}

export default NoRecordings;
