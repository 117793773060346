import hwAxios from "../HWLibrary/HWAxios";
import HWUser from "../HWLibrary/HWUser";
import cache from "./InMemoryCache";
import axios from 'axios';
export class CachedAPI {
     
    getCredentials(integrationType, invalidate) {
        if (invalidate) { cache.invalidateForKey(integrationType); } 
        let fetchFunction = (integrationType) => {
            let skConfig    = { headers: { "Authorization": HWUser.accessToken() } };
            skConfig.params = { "userId" : HWUser.userId(), "appId" : HWUser.appId(), "integrationType": integrationType, orgId:HWUser.orgId() }
            let url = window.resolveRegion(process.env.REACT_APP_SK_INTEGRATIONS_BASE_API_URL) + "/credential";
            return hwAxios.get(url, skConfig);
        }
        return cache.get(integrationType, cache.CacheModule.skCredentials ,fetchFunction);
    }

    getUserPreference() {
        var userId = HWUser.userId();
        const fetchFunction = () => {
            let url = window.resolveRegion(process.env.REACT_APP_DASHBOARDS_BASE_API_URL) + "/platform-setting/user-preference";
            let aConfig = { headers: { "Authorization": HWUser.accessToken(), "Content-Type": "application/json" } };
            aConfig.params = { "userId": userId, "appId": HWUser.appId(), orgId: HWUser.orgId() };
            return hwAxios.get(url, aConfig).
						catch(error => {
							// console.log('data...', error?.response?.data?.message)
						})
        }
        return cache.get(userId, cache.CacheModule.userPreference, fetchFunction);
    }
    getPossibleChurnReasons(){
        var userId = HWUser.userId();
        const fetchFunction = () => {
            let url = window.resolveRegion(process.env.REACT_APP_SMART_DASHBOARDS_BASE_API_URL) + "/reference-data/possible-churn-reasons";
            let aConfig = { headers: { "Authorization": HWUser.accessToken(), "Content-Type": "application/json" } };
            aConfig.params = { "userId": userId, "appId": HWUser.appId(), orgId: HWUser.orgId() };
            return hwAxios.get(url, aConfig).catch(error => {
							// console.log('data...', error?.response?.data?.message)
						})
        }
        return cache.get(userId, cache.CacheModule.possibleChurnReasons, fetchFunction);
    }

    updateUserPreference(request) {
        var userId = HWUser.userId();
        cache.invalidateForKey(userId, cache.CacheModule.userPreference);
        let url        = window.resolveRegion(process.env.REACT_APP_DASHBOARDS_BASE_API_URL) + "/platform-setting/user-preference";
        let aConfig    = { headers: { "Authorization": HWUser.accessToken(), "Content-Type": "application/json" } };
        return hwAxios.post(url,request,aConfig);
    }

    fetchExpansionLabels(){
        var userId = HWUser.userId();
        const fetchFunction = () => {
            let url = window.resolveRegion(process.env.REACT_APP_SMART_DASHBOARDS_BASE_API_URL) + "/expansion-analyzer/label";
            let aConfig = { headers: { "Authorization": HWUser.accessToken(), "Content-Type": "application/json" } };
            aConfig.params = { "userId": userId, "appId": HWUser.appId(), orgId: HWUser.orgId() };
            return hwAxios.get(url, aConfig).catch(error => {
							// console.log('data...', error?.response?.data?.message)
						})
        }
        return cache.get(userId, cache.CacheModule.expansionLabels, fetchFunction);
    }

    getOtherFilters = () => {
        var userId = HWUser.userId();
        const fetchFunction = () => {
            let url = window.resolveRegion(process.env.REACT_APP_DASHBOARDS_BASE_API_URL) + "/multi-product/filter/other-filter";
            let aConfig = { headers: { "Authorization": HWUser.accessToken(), "Content-Type": "application/json" } };
            aConfig.params = { orgId: HWUser.orgId(), userId: HWUser.userId() }
            return hwAxios.get(url, aConfig);
        }
        return cache.get(userId, cache.CacheModule.otherFilterData, fetchFunction);
    }
    fetchPersona(data) {
        const fetchFunction = () => {
          let url = window.resolveRegion(process.env.REACT_APP_DASHBOARDS_BASE_API_URL) + "/product-success-console/persona/list";
          return axios.get(url, {
            params: {
              userId: data.userId, appId: data.appId,
              customerAccountId: data ? data.customerAccountId : '', filterUserId: data ? data.filterUserId : '', portfolioFilter: data ? data.portfolioFilter : ''
            }
          });
        }
        return cache.get(data.userId, cache.CacheModule.personaData, fetchFunction)
               .then(response => { return response.data });
    }

    fetchFilteredKeySegment = () => {
        var userId = HWUser.userId();
        const fetchFunction = () => {
            let url = window.resolveRegion(process.env.REACT_APP_SMART_DASHBOARDS_BASE_API_URL) + "/account-segment/fetch-account-key-segments/scaled";
            let aConfig = { headers: { "Authorization": HWUser.accessToken(), "Content-Type": "application/json", "addFilter": true } };
            aConfig.params = { orgId: HWUser.orgId(), userId: HWUser.userId(), appId: HWUser.appId() }
            return hwAxios.get(url, aConfig);
        }
        return cache.get(userId, cache.CacheModule.keySegments, fetchFunction);
    }

 

    getProductAssociateReferenceData=()=>{
        let userId = HWUser.userId();
       const fetchFunction = () => {
        let url        = window.resolveRegion(process.env.REACT_APP_DASHBOARDS_BASE_API_URL) + "/finance/upcross/reference";
        let aConfig    = { headers: { "Authorization": HWUser.accessToken(), "Content-Type": "application/json" } };
        aConfig.params = {userId: userId, orgId:HWUser.orgId()};
        return hwAxios.get(url, aConfig);
       }
       return cache.get(userId, cache.CacheModule.upcrossReference, fetchFunction);
      }

    fetchConfigureProducts = ()=>{
        var userId = HWUser.userId();
        const fetchFunction = () => {
            let url = window.resolveRegion(process.env.REACT_APP_SMART_DASHBOARDS_BASE_URL) + "/smart-filter/products";
            let config = {
                headers: {
                    "Authorization": HWUser.accessToken(),
                    "Content-Type": "application/json",
                    "addFilter": true
                }
            };
            config.params = {
                orgId: HWUser.orgId(),
                appId: HWUser.appId(),
                userId: HWUser.userId(),
                // shortSummary:true\
            }
            return hwAxios.get(url, config);
        }
        return cache.get(userId, cache.CacheModule.productConfig, fetchFunction);
    }
		fetchRiskRatingData = ()=>{
			var userId = HWUser.userId();
			const fetchFunction = () => {
					let url = window.resolveRegion(process.env.REACT_APP_SMART_DASHBOARDS_BASE_URL) + "/account-view/reference-data";
					let config = {
							headers: {
									"Authorization": HWUser.accessToken(),
									"Content-Type": "application/json",
							}
					};
					config.params = {
							orgId: HWUser.orgId(),
							appId: HWUser.appId(),
							userId: HWUser.userId(),
					}
					return axios.get(url, config);
			}
			return cache.get(userId, cache.CacheModule.riskRatingData, fetchFunction);
	}

		fetchSkSmartFilterAttributes = (path)=>{
			var userId = HWUser.userId();
			const fetchFunction = () => {
					let url = window.resolveRegion(process.env.REACT_APP_SMART_DASHBOARDS_BASE_URL)+ "/smart-filter/attributes";
					let config = {
							headers: {
									"Authorization": HWUser.accessToken(),
									"Content-Type": "application/json",
									"addFilter": true
							}
					};
					config.params = {
							orgId: HWUser.orgId(),
							appId: HWUser.appId(),
							userId: HWUser.userId(),
							path:path
					}
					return hwAxios.get(url, config);
			}
			return cache.get(userId, cache.CacheModule.skFilterAttributes, fetchFunction);
	}

 

      addConfigureProduct = (request) => {
        var userId = HWUser.userId();
        cache.invalidateForKey(userId, cache.CacheModule.productConfig);
        let url = window.resolveRegion(process.env.REACT_APP_DASHBOARDS_BASE_API_URL) + "/multi-product/product-config"
        let aConfig = {
            headers: {
                Authorization: HWUser.accessToken(),
                'Content-Type': 'application/json',
            },
        };
        return axios.post(url, request, aConfig);
    }

    updateConfigureProduct = (request) => {
        var userId = HWUser.userId();
        cache.invalidateForKey(userId, cache.CacheModule.productConfig);
        let config = {
            headers: {
                "Authorization": HWUser.accessToken(),
                "Content-Type": "application/json"
            }
        };
        let url = window.resolveRegion(process.env.REACT_APP_DASHBOARDS_BASE_API_URL) + "/multi-product/product-config";
        config.params = {
            userId: HWUser.userId(),
            appId: HWUser.appId(),
            orgId: HWUser.orgId()
        }
        return axios.put(url, request, config)
    }

    removeConfigureProduct = (request) => {
        var userId = HWUser.userId();
        cache.invalidateForKey(userId, cache.CacheModule.productConfig);
        let config = {
            headers: {
                "Authorization": HWUser.accessToken(),
                "Content-Type": "application/json"
            },
            params: request
        };
        let url = window.resolveRegion(process.env.REACT_APP_DASHBOARDS_BASE_API_URL) + "/multi-product/product-config"
        return axios.delete(url, config);
    }
    
    fetchUserRoles=(data) => {
        var userId = HWUser.userId();
        const fetchFunction = () => {
            let config = { headers: { "Authorization": HWUser.accessToken(), "Content-Type": "application/json" } };
            let url = window.resolveRegion(process.env.REACT_APP_PLATFORM_BASE_API_URL_WEB_ADMIN) + "/rolesandpermission/reference-data";
            config.params = {appId: HWUser.appId(),  orgId:HWUser.orgId(), userId: HWUser.userId() }
            return axios.get(url, config)
        }
        return cache.get(userId, cache.CacheModule.fetchUserRoles, fetchFunction);
        
    }
}

var cachedAPI = new CachedAPI();
export default  cachedAPI;