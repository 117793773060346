import  React,{ lazy } from "react";
import { Navigate } from "react-router-dom";
import HWUser from "../HWLibrary/HWUser";
import access from "../HWLibrary/AccessControl";
import { getGlobalStates} from '../store/reducers/skGlobalReducer';
// import Settings from "../views/Settings/Settings";
// import KeyGrowthIndicator from '../KeyGrowthIndicator/KeyGrowthIndicator';
import Profile from "../Profile/Profile"
// import SmartInsight from "../SmartInsight/SmartInsight";
import LandingPage from "../SmartKonversations/Banners/LandingPage";
import SideBarRoutes from "../SmartNavbar/SideBarRoutes";

// import SmartKonversations from "../SmartKonversations/SmartKonversations"
import TouchpointIntelligence from "../SmartKonversations/TouchpointIntelligence"
// import SmartEmailNotification from "../SmartEmailNotification/SmartEmailNotification"
import Workspace from "../SkWorkSpaceModule/WorkSpace"
// import Portfolio from "../SkPortfolio/Portfolio"
// import SuccessIntelligenceDashboard from "../Intelligence/Success Intelligence/SuccessIntelligenceDashboard"
// import JiraIntegrationCallback from "../views/Settings/SettingsChildren/AppIntegration/JiraIntegrationCallback.jsx"
// import GSuiteIntegrationCallback from "../views/Settings/SettingsChildren/AppIntegration/GSuite/GSuiteIntegrationCallback.jsx"
// import ZendeskIntegrationCallback from "../views/Settings/SettingsChildren/AppIntegration/Zendesk/ZendeskIntegrationCallback.jsx"
// import OutlookIntegrationCallback from "../views/Settings/SettingsChildren/AppIntegration/Outlook/OutlookIntegrationCallback.jsx"
// import HubSpotIntegrationCallback from "../views/Settings/SettingsChildren/AppIntegration/HubSpot/HubSpotIntegrationCallback.jsx"
// import SalesforceIntegrationCallback from "../views/Settings/SettingsChildren/AppIntegration/Salesforce/SalesforceIntegrationCallback.jsx"
// import ZohoBookIntegrationCallback from "../views/Settings/SettingsChildren/AppIntegration/ZohoBook/ZohoBookIntegrationCallback.jsx"
// import PipedriveIntegrationCallback from "../views/Settings/SettingsChildren/AppIntegration/Pipedrive/PipedriveIntegrationCallback.jsx"
// import ChargebeeIntegrationCallback from "../views/Settings/SettingsChildren/AppIntegration/Chargebee/ChargebeeIntegrationCallback.jsx"
// import TeamworkDeskIntegrationCallback from "../views/Settings/SettingsChildren/AppIntegration/TeamworkDesk/TeamworkDeskIntegrationCallback.jsx"
// import TeamSupportIntegrationCallback from "../views/Settings/SettingsChildren/AppIntegration/TeamSupport/TeamSupportIntegrationCallback.jsx"
// import IntercomIntegrationCallback from "../views/Settings/SettingsChildren/AppIntegration/Intercom/IntercomIntegrationCallback.jsx"
// import ZoomIntegrationCallback from "../views/Settings/SettingsChildren/AppIntegration/Zoom/ZoomIntegrationCallback.jsx"
// import SettingsDashboard from '../SmartSettings/SettingsDashboard.jsx'
// import ProductSuccessConsole from "../ProductSuccessConsole/ProductSuccessConsole";
// import SmartAssist from "../SmartAssist/SmartAssist"
import AcademyRedirect from "../SkSmartUtil/AcademyRedirect/AcademyRedirect"
// import SmartKonversations from "../SmartKonversations/SmartKonversations"


// import SmartAssist from "../SmartAssist/SmartAssist"
const SettingsDashboard = React.lazy(() => import('../SmartSettings/SettingsDashboard.jsx'));
const ProductSuccessConsole = React.lazy(() => import("../ProductSuccessConsole/ProductSuccessConsole"));
const SmartKonversations = React.lazy(()=> import("../SmartKonversations/SmartKonversations"));
const SmartAssist = React.lazy(() => import("../SmartAssist/SmartAssist"));
const SuccessIntelligenceDashboard = React.lazy(() =>import("../Intelligence/Success Intelligence/SuccessIntelligenceDashboard"));
const JiraIntegrationCallback = React.lazy(() =>import("../views/Settings/SettingsChildren/AppIntegration/JiraIntegrationCallback.jsx"));
const GSuiteIntegrationCallback = React.lazy(() =>import("../views/Settings/SettingsChildren/AppIntegration/GSuite/GSuiteIntegrationCallback.jsx"));
const ZendeskIntegrationCallback =React.lazy(()=>import("../views/Settings/SettingsChildren/AppIntegration/Zendesk/ZendeskIntegrationCallback.jsx"));
const OutlookIntegrationCallback =React.lazy(()=>import("../views/Settings/SettingsChildren/AppIntegration/Outlook/OutlookIntegrationCallback.jsx"));
const HubSpotIntegrationCallback =React.lazy(()=>import("../views/Settings/SettingsChildren/AppIntegration/HubSpot/HubSpotIntegrationCallback.jsx"));
const SalesforceIntegrationCallback =React.lazy(()=>import("../views/Settings/SettingsChildren/AppIntegration/Salesforce/SalesforceIntegrationCallback.jsx"));
const ZohoBookIntegrationCallback =React.lazy(()=>import( "../views/Settings/SettingsChildren/AppIntegration/ZohoBook/ZohoBookIntegrationCallback.jsx"));
const PipedriveIntegrationCallback =React.lazy(()=>import("../views/Settings/SettingsChildren/AppIntegration/Pipedrive/PipedriveIntegrationCallback.jsx"));
const ChargebeeIntegrationCallback =React.lazy(()=>import("../views/Settings/SettingsChildren/AppIntegration/Chargebee/ChargebeeIntegrationCallback.jsx"));
const TeamworkDeskIntegrationCallback =React.lazy(()=>import("../views/Settings/SettingsChildren/AppIntegration/TeamworkDesk/TeamworkDeskIntegrationCallback.jsx"));
const TeamSupportIntegrationCallback =React.lazy(()=>import("../views/Settings/SettingsChildren/AppIntegration/TeamSupport/TeamSupportIntegrationCallback.jsx"));
const IntercomIntegrationCallback =React.lazy(()=>import( "../views/Settings/SettingsChildren/AppIntegration/Intercom/IntercomIntegrationCallback.jsx"));
const ZoomIntegrationCallback  = React.lazy(()=>import("../views/Settings/SettingsChildren/AppIntegration/Zoom/ZoomIntegrationCallback.jsx"));
const Portfolio = React.lazy(()=>import("../SkPortfolio/Portfolio"));
const KeyGrowthIndicator = React.lazy(()=>import("../KeyGrowthIndicator/KeyGrowthIndicator"));
const SmartInsight = React.lazy(()=>import("../SmartInsight/SmartInsight"));
const SmartEmailNotification = React.lazy(()=>import("../SmartEmailNotification/SmartEmailNotification"));

var dashboardRoutes = () => {
	let EnabledTabs = SideBarRoutes().filter(tab => tab.isClickable);
	
	let defaultTab = (access?.contacts?.list() || access?.notes?.list() || access?.myAccounts?.list() || access?.account360?.dashboard()) ? '/dashboard/workspace' : EnabledTabs[0]?.path;
	if(getGlobalStates()?.showKeyOutcomeJoyride && access?.smartKeyOutcomes?.isEnable()) {
		defaultTab = '/dashboard/csm-dashboard/renewal/renewal_dashboard'
	}
	return [
		{
			renderToSidebar: false,
			renderToNavbar: false,
			path: "/dashboard/csm-dashboard",
			component: <KeyGrowthIndicator />,
			isEnabled:access?.smartKeyOutcomes?.view() || access?.smartKeyOutcomes?.onboarding?.view() || access?.smartKeyOutcomes?.renewal?.view() || access?.smartKeyOutcomes?.churn?.view() || access?.smartKeyOutcomes?.expansion?.view(),
		},
		{
			renderToSidebar: false,
			renderToNavbar: false,
			path: "/dashboard/csm-dashboard/churn/renewal_touchpoints",
			component: <KeyGrowthIndicator />,
			isEnabled: access?.smartKeyOutcomes?.view() || access?.smartKeyOutcomes?.onboarding?.view() || access?.smartKeyOutcomes?.renewal?.view() || access?.smartKeyOutcomes?.churn?.view() || access?.smartKeyOutcomes?.expansion?.view(),
		},
		{
			renderToSidebar: false,
			renderToNavbar: false,
			path: "/dashboard/csm-dashboard/churn/churn_dashboard",
			component: <KeyGrowthIndicator />,
			isEnabled: access?.smartKeyOutcomes?.view() || access?.smartKeyOutcomes?.onboarding?.view() || access?.smartKeyOutcomes?.renewal?.view() || access?.smartKeyOutcomes?.churn?.view() || access?.smartKeyOutcomes?.expansion?.view(),
		},
		{
			renderToSidebar: false,
			renderToNavbar: false,
			path: "/dashboard/csm-dashboard/churn/churn_summary",
			component: <KeyGrowthIndicator />,
			isEnabled:  access?.smartKeyOutcomes?.view() || access?.smartKeyOutcomes?.onboarding?.view() || access?.smartKeyOutcomes?.renewal?.view() || access?.smartKeyOutcomes?.churn?.view() || access?.smartKeyOutcomes?.expansion?.view(),
		},
		{
			renderToSidebar: false,
			renderToNavbar: false,
			path: "/dashboard/csm-dashboard/churn/churn_playbooks_summary",
			component: <KeyGrowthIndicator />,
			isEnabled:  access?.smartKeyOutcomes?.view() || access?.smartKeyOutcomes?.onboarding?.view() || access?.smartKeyOutcomes?.renewal?.view() || access?.smartKeyOutcomes?.churn?.view() || access?.smartKeyOutcomes?.expansion?.view(),
		},
		{
			renderToSidebar: false,
			renderToNavbar: false,
			path: "/dashboard/csm-dashboard/churn/churn_tasks",
			component: <KeyGrowthIndicator />,
			isEnabled:  access?.smartKeyOutcomes?.view() || access?.smartKeyOutcomes?.onboarding?.view() || access?.smartKeyOutcomes?.renewal?.view() || access?.smartKeyOutcomes?.churn?.view() || access?.smartKeyOutcomes?.expansion?.view(),
		},
		{
			renderToSidebar: false,
			renderToNavbar: false,
			path: "/dashboard/csm-dashboard/churn/churn_touchpoints",
			component: <KeyGrowthIndicator />,
			isEnabled: access?.smartKeyOutcomes?.view() || access?.smartKeyOutcomes?.onboarding?.view() || access?.smartKeyOutcomes?.renewal?.view() || access?.smartKeyOutcomes?.churn?.view() || access?.smartKeyOutcomes?.expansion?.view(),
		},
		{
			renderToSidebar: false,
			renderToNavbar: false,
			path: "/dashboard/csm-dashboard/expansion/expansion_dashboard",
			component: <KeyGrowthIndicator />,
			isEnabled:  access?.smartKeyOutcomes?.view() || access?.smartKeyOutcomes?.onboarding?.view() || access?.smartKeyOutcomes?.renewal?.view() || access?.smartKeyOutcomes?.churn?.view() || access?.smartKeyOutcomes?.expansion?.view(),
		},
		{
			renderToSidebar: false,
			renderToNavbar: false,
			path: "/dashboard/csm-dashboard/expansion/playbooks_Summary",
			component: <KeyGrowthIndicator />,
			isEnabled: access?.smartKeyOutcomes?.view() || access?.smartKeyOutcomes?.onboarding?.view() || access?.smartKeyOutcomes?.renewal?.view() || access?.smartKeyOutcomes?.churn?.view() || access?.smartKeyOutcomes?.expansion?.view(),
		},
		{
			renderToSidebar: false,
			renderToNavbar: false,
			path: "/dashboard/csm-dashboard/expansion/timeline",
			component: <KeyGrowthIndicator />,
			isEnabled:access?.smartKeyOutcomes?.view() || access?.smartKeyOutcomes?.onboarding?.view() || access?.smartKeyOutcomes?.renewal?.view() || access?.smartKeyOutcomes?.churn?.view() || access?.smartKeyOutcomes?.expansion?.view(),
		},
		{
			renderToSidebar: false,
			renderToNavbar: false,
			path: "/dashboard/csm-dashboard/expansion/Touchpoints",
			component: <KeyGrowthIndicator />,
			isEnabled:  access?.smartKeyOutcomes?.view() || access?.smartKeyOutcomes?.onboarding?.view() || access?.smartKeyOutcomes?.renewal?.view() || access?.smartKeyOutcomes?.churn?.view() || access?.smartKeyOutcomes?.expansion?.view(),
		},

		{
			renderToSidebar: false,
			renderToNavbar: false,
			path: "/dashboard/csm-dashboard/renewal/renewal_dashboard",
			component: <KeyGrowthIndicator />,
			isEnabled: access?.smartKeyOutcomes?.view() || access?.smartKeyOutcomes?.onboarding?.view() || access?.smartKeyOutcomes?.renewal?.view() || access?.smartKeyOutcomes?.churn?.view() || access?.smartKeyOutcomes?.expansion?.view(),
		},
		{
			renderToSidebar: false,
			renderToNavbar: false,
			path: "/dashboard/csm-dashboard/on_boarding/on_boarding_dashboard",
			component: <KeyGrowthIndicator />,
			isEnabled:access?.smartKeyOutcomes?.view() || access?.smartKeyOutcomes?.onboarding?.view() || access?.smartKeyOutcomes?.renewal?.view() || access?.smartKeyOutcomes?.churn?.view() || access?.smartKeyOutcomes?.expansion?.view(),
		},
		{
			renderToSidebar: false,
			renderToNavbar: false,
			path: "/dashboard/csm-dashboard/on_boarding/on_boarding_playbooks_Summary",
			component: <KeyGrowthIndicator />,
			isEnabled: access?.smartKeyOutcomes?.view() || access?.smartKeyOutcomes?.onboarding?.view() || access?.smartKeyOutcomes?.renewal?.view() || access?.smartKeyOutcomes?.churn?.view() || access?.smartKeyOutcomes?.expansion?.view(),
		},
		{
			renderToSidebar: false,
			renderToNavbar: false,
			path: "/dashboard/csm-dashboard/on_boarding/on_boarding_timeline",
			component: <KeyGrowthIndicator />,
			isEnabled: access?.smartKeyOutcomes?.view() || access?.smartKeyOutcomes?.onboarding?.view() || access?.smartKeyOutcomes?.renewal?.view() || access?.smartKeyOutcomes?.churn?.view() || access?.smartKeyOutcomes?.expansion?.view(),
		},
		{
			renderToSidebar: false,
			renderToNavbar: false,
			path: "/dashboard/csm-dashboard/on_boarding/on_boarding_touchpoints",
			component: <KeyGrowthIndicator />,
			isEnabled: access?.smartKeyOutcomes?.view() || access?.smartKeyOutcomes?.onboarding?.view() || access?.smartKeyOutcomes?.renewal?.view() || access?.smartKeyOutcomes?.churn?.view() || access?.smartKeyOutcomes?.expansion?.view(),
		},
		{
			renderToSidebar: false,
			renderToNavbar: false,
			path: "/dashboard/csm-dashboard/renewal/renewal_summary",
			component: <KeyGrowthIndicator />,
			isEnabled: access?.smartKeyOutcomes?.view() || access?.smartKeyOutcomes?.onboarding?.view() || access?.smartKeyOutcomes?.renewal?.view() || access?.smartKeyOutcomes?.churn?.view() || access?.smartKeyOutcomes?.expansion?.view(),
		},
		{
			renderToSidebar: false,
			renderToNavbar: false,
			path: "/dashboard/csm-dashboard/renewal/renewal_playbooks_summary",
			component: <KeyGrowthIndicator />,
			isEnabled: access?.smartKeyOutcomes?.view() || access?.smartKeyOutcomes?.onboarding?.view() || access?.smartKeyOutcomes?.renewal?.view() || access?.smartKeyOutcomes?.churn?.view() || access?.smartKeyOutcomes?.expansion?.view(),
		},
		{
			renderToSidebar: false,
			renderToNavbar: false,
			path: "/dashboard/csm-dashboard/renewal/renewal_timeline",
			component: <KeyGrowthIndicator />,
			isEnabled:access?.smartKeyOutcomes?.view() || access?.smartKeyOutcomes?.onboarding?.view() || access?.smartKeyOutcomes?.renewal?.view() || access?.smartKeyOutcomes?.churn?.view() || access?.smartKeyOutcomes?.expansion?.view(),
		},
		{
			renderToSidebar: false,
			renderToNavbar: false,
			path: "/dashboard/csm-dashboard/renewal/renewal_touchpoints",
			component: <KeyGrowthIndicator />,
			isEnabled:  access?.smartKeyOutcomes?.view() || access?.smartKeyOutcomes?.onboarding?.view() || access?.smartKeyOutcomes?.renewal?.view() || access?.smartKeyOutcomes?.churn?.view() || access?.smartKeyOutcomes?.expansion?.view(),
		},
		{
			renderToNavbar: false,
			renderToSidebar: false,
			path: "/dashboard/product-success-console",
			component: <ProductSuccessConsole />,
			isEnabled:  access?.productOrService?.isPermitted() && (access?.productAnalytics?.productUsage?.view() || access?.productAnalytics?.heatMap?.view() || access?.productAnalytics?.duration?.view() || access?.productAnalytics?.loyalty?.view() || access?.productAnalytics?.featureUsage?.view() || access?.productAnalytics?.users?.view())
		},
		{
			renderToNavbar: false,
			renderToSidebar: false,
			path: "/dashboard/product-success-console/product_usage",
			component: <ProductSuccessConsole />,
			isEnabled:  access?.productOrService?.isPermitted() && (access?.productAnalytics?.productUsage?.view() || access?.productAnalytics?.heatMap?.view() || access?.productAnalytics?.duration?.view() || access?.productAnalytics?.loyalty?.view() || access?.productAnalytics?.featureUsage?.view() || access?.productAnalytics?.users?.view())
		},
		{
			renderToNavbar: false,
			renderToSidebar: false,
			path: "/dashboard/product-success-console/heatMap",
			component: <ProductSuccessConsole />,
			isEnabled:  access?.productOrService?.isPermitted() && (access?.productAnalytics?.productUsage?.view() || access?.productAnalytics?.heatMap?.view() || access?.productAnalytics?.duration?.view() || access?.productAnalytics?.loyalty?.view() || access?.productAnalytics?.featureUsage?.view() || access?.productAnalytics?.users?.view())
		},
		{
			renderToNavbar: false,
			renderToSidebar: false,
			path: "/dashboard/product-success-console/duration",
			component: <ProductSuccessConsole />,
			isEnabled:  access?.productOrService?.isPermitted() && (access?.productAnalytics?.productUsage?.view() || access?.productAnalytics?.heatMap?.view() || access?.productAnalytics?.duration?.view() || access?.productAnalytics?.loyalty?.view() || access?.productAnalytics?.featureUsage?.view() || access?.productAnalytics?.users?.view())
		},
		{
			renderToNavbar: false,
			renderToSidebar: false,
			path: "/dashboard/product-success-console/loyalty",
			component: <ProductSuccessConsole />,
			isEnabled: access?.productOrService?.isPermitted() && (access?.productAnalytics?.productUsage?.view() || access?.productAnalytics?.heatMap?.view() || access?.productAnalytics?.duration?.view() || access?.productAnalytics?.loyalty?.view() || access?.productAnalytics?.featureUsage?.view() || access?.productAnalytics?.users?.view())
		},
		{
			renderToNavbar: false,
			renderToSidebar: false,
			path: "/dashboard/product-success-console/feature",
			component: <ProductSuccessConsole />,
			isEnabled: access?.productOrService?.isPermitted() && (access?.productAnalytics?.productUsage?.view() || access?.productAnalytics?.heatMap?.view() || access?.productAnalytics?.duration?.view() || access?.productAnalytics?.loyalty?.view() || access?.productAnalytics?.featureUsage?.view() || access?.productAnalytics?.users?.view())
		},
		{
			renderToNavbar: false,
			renderToSidebar: false,
			path: "/dashboard/product-success-console/users",
			component: <ProductSuccessConsole />,
			isEnabled:  access?.productOrService?.isPermitted() && (access?.productAnalytics?.productUsage?.view() || access?.productAnalytics?.heatMap?.view() || access?.productAnalytics?.duration?.view() || access?.productAnalytics?.loyalty?.view() || access?.productAnalytics?.featureUsage?.view() || access?.productAnalytics?.users?.view())
		},
		{
			renderToNavbar: false,
			renderToSidebar: false,
			path: "/dashboard/workspace",
			component: <Workspace />,
			isEnabled: (access?.playbook?.dashboard() || access?.playbook?.summary() || access?.playbook?.rule() || access?.playbook?.template() || access?.smartTask?.list() || access?.smartTouchpoint?.viewTab())
		},
		{
			renderToNavbar: false,
			renderToSidebar: false,
			path: "/dashboard/workspace/getting_started",
			component: <Workspace />,
			isEnabled:  true
		},
		{
			renderToNavbar: false,
			renderToSidebar: false,
			path: "/dashboard/workspace/Calendar",
			component: <Workspace />,
			isEnabled: (access?.playbook?.dashboard() || access?.playbook?.summary() || access?.playbook?.rule() || access?.playbook?.template() || access?.smartTask?.list() || access?.smartTouchpoint?.viewTab())
		},
		{
			renderToNavbar: false,
			renderToSidebar: false,
			path: "/dashboard/workspace/timeline",
			component: <Workspace />,
			isEnabled: (access?.playbook?.dashboard() || access?.playbook?.summary() || access?.playbook?.rule() || access?.playbook?.template() || access?.smartTask?.list() || access?.smartTouchpoint?.viewTab())
		},
		{
			renderToNavbar: false,
			renderToSidebar: false,
			path: "/dashboard/workspace/Touchpoints",
			component: <Workspace />,
			isEnabled: (access?.playbook?.dashboard() || access?.playbook?.summary() || access?.playbook?.rule() || access?.playbook?.template() || access?.smartTask?.list() || access?.smartTouchpoint?.viewTab())
		},
		{
			renderToNavbar: false,
			renderToSidebar: false,
			path: "/dashboard/workspace/playbook/PlayBooksDashboard",
			component: <Workspace />,
			isEnabled: (access?.playbook?.dashboard() || access?.playbook?.summary() || access?.playbook?.rule() || access?.playbook?.template() || access?.smartTask?.list() || access?.smartTouchpoint?.viewTab())
		},
		{
			renderToNavbar: false,
			renderToSidebar: false,
			path: "/dashboard/workspace/playbook/playbooks_Summary",
			component: <Workspace />,
			isEnabled: (access?.playbook?.dashboard() || access?.playbook?.summary() || access?.playbook?.rule() || access?.playbook?.template() || access?.smartTask?.list() || access?.smartTouchpoint?.viewTab())
		},
		{
			renderToNavbar: false,
			renderToSidebar: false,
			path: "/dashboard/workspace/playbook/PlayBooksAssignmentRules",
			component: <Workspace />,
			isEnabled: (access?.playbook?.dashboard() || access?.playbook?.summary() || access?.playbook?.rule() || access?.playbook?.template() || access?.smartTask?.list() || access?.smartTouchpoint?.viewTab())
		},
		{
			renderToNavbar: false,
			renderToSidebar: false,
			path: "/dashboard/workspace/playbook/template",
			component: <Workspace />,
			isEnabled: (access?.playbook?.dashboard() || access?.playbook?.summary() || access?.playbook?.rule() || access?.playbook?.template() || access?.smartTask?.list() || access?.smartTouchpoint?.viewTab())
		},
		{
			renderToNavbar: false,
			renderToSidebar: false,
			path: "/dashboard/workspace/email/Inbox",
			component: <Workspace />,
			isEnabled: (access?.playbook?.dashboard() || access?.playbook?.summary() || access?.playbook?.rule() || access?.playbook?.template() || access?.smartTask?.list() || access?.smartTouchpoint?.viewTab())
		},
		{
			renderToNavbar: false,
			renderToSidebar: false,
			path: "/dashboard/workspace/email/Sent",
			component: <Workspace />,
			isEnabled: (access?.playbook?.dashboard() || access?.playbook?.summary() || access?.playbook?.rule() || access?.playbook?.template() || access?.smartTask?.list() || access?.smartTouchpoint?.viewTab())
		},

		{
			renderToSidebar: false,
			renderToNavbar: false,
			path: "/dashboard/portfolio",
			component: <Portfolio />,
			isEnabled: access?.contacts?.list() || access?.notes?.list() || access?.myAccounts?.list() || access?.account360?.dashboard(),
		},
		{
			renderToSidebar: false,
			renderToNavbar: false,
			path: "/dashboard/portfolio/my_accounts",
			component: <Portfolio />,
			isEnabled: access?.contacts?.list() || access?.notes?.list() || access?.myAccounts?.list() || access?.account360?.dashboard(),
		},
		{
			renderToSidebar: false,
			renderToNavbar: false,
			path: "/dashboard/portfolio/segment_summary",
			component: <Portfolio />,
			isEnabled: access?.contacts?.list() || access?.notes?.list() || access?.myAccounts?.list() || access?.account360?.dashboard(),
		},
		{
			renderToSidebar: false,
			renderToNavbar: false,
			path: "/dashboard/portfolio/Notes",
			component: <Portfolio />,
			isEnabled: access?.contacts?.list() || access?.notes?.list() || access?.myAccounts?.list() || access?.account360?.dashboard(),
		},
		{
			renderToSidebar: false,
			renderToNavbar: false,
			path: "/dashboard/portfolio/CustomerPortal",
			component: <Portfolio />,
			isEnabled: access?.contacts?.list() || access?.notes?.list() || access?.myAccounts?.list() || access?.account360?.dashboard(),
		},
		{
			renderToSidebar: false,
			renderToNavbar: false,
			path: "/dashboard/success-intelligence/portal",
			component: <SuccessIntelligenceDashboard />,
			isEnabled: true
		},
		{
			renderToSidebar: false,
			renderToNavbar: false,
			path: "/dashboard/portfolio/Contacts",
			component: <Portfolio />,
			isEnabled: access?.contacts?.list() || access?.notes?.list() || access?.myAccounts?.list() || access?.account360?.dashboard(),
		},
		{
			renderToSidebar: false,
			renderToNavbar: false,
			path: "/dashboard/portfolio/account360_touchpoint_list",
			component: <Portfolio />,
			isEnabled: access?.contacts?.list() || access?.notes?.list() || access?.myAccounts?.list() || access?.account360?.dashboard(),
		},
		{
			renderToSidebar: false,
			renderToNavbar: false,
			path: "/dashboard/portfolio/List",
			component: <Portfolio />,
			isEnabled: access?.contacts?.list() || access?.notes?.list() || access?.myAccounts?.list() || access?.account360?.dashboard(),
		},
		{
			renderToSidebar: false,
			renderToNavbar: false,
			path: "/dashboard/portfolio/account_playbooks",
			component: <Portfolio />,
			isEnabled: access?.contacts?.list() || access?.notes?.list() || access?.myAccounts?.list() || access?.account360?.dashboard(),
		},
		{
			renderToSidebar: false,
			renderToNavbar: false,
			path: "/dashboard/portfolio/Dashboard",
			component: <Portfolio />,
			isEnabled: access?.contacts?.list() || access?.notes?.list() || access?.myAccounts?.list() || access?.account360?.dashboard(),
		},
		
		{
			renderToSidebar: false,
			renderToNavbar: true,
			path: "/dashboard/settings",
			component: <SettingsDashboard/>,
			isEnabled: true,
		},
		{
			renderToSidebar: false,
			renderToNavbar: true,
			path: "/dashboard/settings/configuration/category",
			component: <SettingsDashboard/>,
			isEnabled: true,
		},
		{
			renderToSidebar: false,
			renderToNavbar: true,
			path: "/dashboard/settings/configuration/functions",
			component: <SettingsDashboard/>,
			isEnabled: true,
		},	{
			renderToSidebar: false,
			renderToNavbar: true,
			path: "/dashboard/settings/configuration/products",
			component: <SettingsDashboard/>,
			isEnabled: true,
		},
		{
			renderToSidebar: false,
			renderToNavbar: true,
			path: "/dashboard/settings/configuration/outcomes",
			component: <SettingsDashboard/>,
			isEnabled: true,
		},
		{
			renderToSidebar: false,
			renderToNavbar: true,
			path: "/dashboard/settings/organisation",
			component: <SettingsDashboard/>,
			isEnabled: true,
		},
		{
			renderToSidebar: false,
			renderToNavbar: true,
			path: "/dashboard/settings/userManagement/manageUser",
			component: <SettingsDashboard/>,
			isEnabled: true,
		},
		{
			renderToSidebar: false,
			renderToNavbar: true,
			path: "/dashboard/settings/userManagement/manageRole",
			component: <SettingsDashboard/>,
			isEnabled: true,
		},
		{
			renderToSidebar: false,
			renderToNavbar: true,
			path: "/dashboard/settings/userManagement/portalUser",
			component: <SettingsDashboard/>,
			isEnabled: true,
		},
		{
			renderToSidebar: false,
			renderToNavbar: true,
			path: "/dashboard/settings/accounts/management",
			component: <SettingsDashboard />,
			isEnabled: true,
		},
		{
			renderToSidebar: false,
			renderToNavbar: true,
			path: "/dashboard/settings/accounts/category",
			component: <SettingsDashboard />,
			isEnabled: true,
		},	{
			renderToSidebar: false,
			renderToNavbar: true,
			path: "/dashboard/settings/accounts/access",
			component: <SettingsDashboard />,
			isEnabled: true,
		},
		{
			renderToSidebar: false,
			renderToNavbar: true,
			path: "/dashboard/settings/accounts/phases",
			component: <SettingsDashboard />,
			isEnabled: true,
		},	
		{
			renderToSidebar: false,
			renderToNavbar: true,
			path: "/dashboard/settings/integrations",
			component: <SettingsDashboard />,
			isEnabled: true,
		},
		{
			renderToSidebar: false,
			renderToNavbar: true,
			path: "/dashboard/settings/health_score_definition/health_score",
			component: <SettingsDashboard />,
			isEnabled: true,
		},
		{
			renderToSidebar: false,
			renderToNavbar: true,
			path: "/dashboard/settings/health_score_definition/risk_rating",
			component: <SettingsDashboard />,
			isEnabled: true,
		},
		{
			renderToSidebar: false,
			renderToNavbar: true,
			path: "/dashboard/settings/event_config/event_summary",
			component: <SettingsDashboard />,
			isEnabled: true,
		},
		{
			renderToSidebar: false,
			renderToNavbar: true,
			path: "/dashboard/settings/event_config/event_logs",
			component: <SettingsDashboard />,
			isEnabled: true,
		},
		{
			renderToSidebar: false,
			renderToNavbar: true,
			path: "/dashboard/settings/event_config/event_mapping",
			component: <SettingsDashboard />,
			isEnabled: true,
		},
		{
			renderToSidebar: false,
			renderToNavbar: true,
			path: "/dashboard/settings/event_config/event_pattern",
			component: <SettingsDashboard />,
			isEnabled: true,
		},
		// {
		// 	renderToSidebar: false,
		// 	renderToNavbar: true,
		// 	path: "/dashboard/settings/my_org",
		// 	component: <Settings />,
		// 	isEnabled: true,
		// },
		// {
		// 	renderToSidebar: false,
		// 	renderToNavbar: true,
		// 	path: "/dashboard/settings/user_management",
		// 	component: <Settings />,
		// 	isEnabled: true,
		// },
		// {
		// 	renderToSidebar: false,
		// 	renderToNavbar: true,
		// 	path: "/dashboard/settings/account_phases",
		// 	component: <Settings />,
		// 	isEnabled: true,
		// },
		// {
		// 	renderToSidebar: false,
		// 	renderToNavbar: true,
		// 	path: "/dashboard/settings/configure_products",
		// 	component: <Settings />,
		// 	isEnabled: true,
		// },
		// {
		// 	renderToSidebar: false,
		// 	renderToNavbar: true,
		// 	path: "/dashboard/settings/roles_permissions",
		// 	component: <Settings />,
		// 	isEnabled: true,
		// },
		// {
		// 	renderToSidebar: false,
		// 	renderToNavbar: true,
		// 	path: "/dashboard/settings/user_access",
		// 	component: <Settings />,
		// 	isEnabled: true,
		// },
		// {
		// 	renderToSidebar: false,
		// 	renderToNavbar: true,
		// 	path: "/dashboard/settings/account_management",
		// 	component: <Settings />,
		// 	isEnabled: true,
		// },
		// {
		// 	renderToSidebar: false,
		// 	renderToNavbar: true,
		// 	path: "/dashboard/settings/account_categories",
		// 	component: <Settings />,
		// 	isEnabled: true,
		// },
		// {
		// 	renderToSidebar: false,
		// 	renderToNavbar: true,
		// 	path: "/dashboard/settings/account_access",
		// 	component: <Settings />,
		// 	isEnabled: true,
		// },
		// {
		// 	renderToSidebar: false,
		// 	renderToNavbar: true,
		// 	path: "/dashboard/settings/app_integrations",
		// 	component: <Settings />,
		// 	isEnabled: true,
		// },
		// {
		// 	renderToSidebar: false,
		// 	renderToNavbar: true,
		// 	path: "/dashboard/settings/health_score",
		// 	component: <Settings />,
		// 	isEnabled: true,
		// },
		// {
		// 	renderToSidebar: false,
		// 	renderToNavbar: true,
		// 	path: "/dashboard/settings/risk_rating",
		// 	component: <Settings />,
		// 	isEnabled: true,
		// },
		// {
		// 	renderToSidebar: false,
		// 	renderToNavbar: true,
		// 	path: "/dashboard/settings/event_summary",
		// 	component: <Settings />,
		// 	isEnabled: true,
		// },
		// {
		// 	renderToSidebar: false,
		// 	renderToNavbar: true,
		// 	path: "/dashboard/settings/event_logs",
		// 	component: <Settings />,
		// 	isEnabled: true,
		// },
		// {
		// 	renderToSidebar: false,
		// 	renderToNavbar: true,
		// 	path: "/dashboard/settings/event_mapping",
		// 	component: <Settings />,
		// 	isEnabled: true,
		// },
		// {
		// 	renderToSidebar: false,
		// 	renderToNavbar: true,
		// 	path: "/dashboard/settings/event_pattern",
		// 	component: <Settings />,
		// 	isEnabled: true,
		// },
		{
			renderToNavbar: false,
			renderToSidebar: false,
			path: "/dashboard/edit-profile",
			component: <Profile />,
			isEnabled: true
		},
		{
			renderToNavbar: false,
			renderToSidebar: false,
			path: "/dashboard/manage-notification",
			component: <SmartEmailNotification />,
			isEnabled: true
		},
	
		{
			renderToSidebar: false,
			renderToNavbar: false,
			path: "/dashboard/success-intelligence",
			component: <SuccessIntelligenceDashboard />,
			isEnabled:  access?.smartOps?.view() 
		},
		{
			renderToSidebar: false,
			renderToNavbar: false,
			path: "/dashboard/success-intelligence/RI",
			component: <SuccessIntelligenceDashboard />,
			isEnabled:  (access?.smartOps?.view()  && access?.productOrService?.isPermitted())
		},
		{
			renderToSidebar: false,
			renderToNavbar: false,
			path: "/dashboard/success-intelligence/EI",
			component: <SuccessIntelligenceDashboard />,
			isEnabled:  (access?.smartOps?.view()  && access?.productOrService?.isPermitted())
		},
		{
			renderToSidebar: false,
			renderToNavbar: false,
			path: "/dashboard/success-intelligence/PAI",
			component: <SuccessIntelligenceDashboard />,
			isEnabled:  (access?.smartOps?.view()  && access?.productOrService?.isPermitted())
		},
		{
			renderToSidebar: false,
			renderToNavbar: false,
			path: "/dashboard/success-intelligence/RiskIntelligence",
			component: <SuccessIntelligenceDashboard />,
			isEnabled: (access?.smartOps?.view()  && access?.productOrService?.isPermitted())
		},
		{
			renderToSidebar: false,
			renderToNavbar: false,
			path: "/dashboard/success-intelligence/Alerts",
			component: <SuccessIntelligenceDashboard />,
			isEnabled: access?.smartOps?.view(),
		},
		{
			renderToSidebar: false,
			renderToNavbar: false,
			path: "/dashboard/success-intelligence/alerts-dashboard",
			component: <SuccessIntelligenceDashboard />,
			isEnabled: access?.smartOps?.view(),
		},
		{
			renderToSidebar: false,
			renderToNavbar: false,
			path: "/dashboard/success-intelligence/alerts-summary",
			component: <SuccessIntelligenceDashboard />,
			isEnabled: access?.smartOps?.view()
		},
	
	
		// {
		// 	renderToNavbar: false,
		// 	renderToSidebar: false,
		// 	path: "/dashboard/risk-intelligence",
		// 	component: <RiskIntelligenceDashboard />,
		// 	isEnabled: true
		// },

		{
			renderToNavbar: false,
			renderToSidebar: false,
			path: "/dashboard/smart-insight",
			component: <SmartInsight />,
			isEnabled: access?.smartInsights?.view()
		},

		{
			renderToNavbar: false,
			renderToSidebar: false,
			path: "/dashboard/smart-insight/canned_report",
			component: <SmartInsight />,
			isEnabled: access?.smartInsights?.view()
		},

		{
			renderToNavbar: false,
			renderToSidebar: false,
			path: "/dashboard/smart-insight/canned_report/smart_hs_report",
			component: <SmartInsight />,
			isEnabled: access?.smartInsights?.view()
		},
		{
			renderToNavbar: false,
			renderToSidebar: false,
			path: "/dashboard/smart-insight/canned_report/touchpoint_report",
			component: <SmartInsight />,
			isEnabled: access?.smartInsights?.view()
		},
		{
			renderToNavbar: false,
			renderToSidebar: false,
			path: "/dashboard/smart-insight/canned_report/nrr_walk",
			component: <SmartInsight />,
			isEnabled: access?.smartInsights?.view()
		},
		{
			renderToNavbar: false,
			renderToSidebar: false,
			path: "/dashboard/smart-insight/custom_report",
			component: <SmartInsight />,
			isEnabled: access?.smartInsights?.view()
		},
		{
			renderToNavbar: false,
			renderToSidebar: false,
			path: "/dashboard/smart-assist",
			component: <SmartAssist />,
			isEnabled: access?.smartAssist?.view()
		},
		// {
		// 	renderToSidebar: false,
		// 	renderToNavbar: false,
		// 	path: "/dashboard/kommunity",
		// 	component: <KommunityDetailPage />,
		// 	isEnabled: access?.kommunity?.view()
		// },
		{
			renderToNavbar: false,
			renderToSidebar: false,
			path: "/dashboard/smart-konversation",
			component: <LandingPage />,
			isEnabled: access?.smartKonversation?.view()
		},
		{
			renderToNavbar: false,
			renderToSidebar: false,
			path: "/dashboard/smart-konversations",
			component: <SmartKonversations />,
			isEnabled: (HWUser.isPremiumFeatureEnable() && access?.smartKonversation?.view()) ? true : false
		},
		{
			renderToNavbar: false,
			renderToSidebar: false,
			path: "/dashboard/smart-konversations/team_metrics",
			component: <SmartKonversations />,
			isEnabled: (HWUser.isPremiumFeatureEnable() && access?.smartKonversation?.view()) ? true : false
		},
		{
			renderToNavbar: false,
			renderToSidebar: false,
			path: "/dashboard/smart-konversations/Summary",
			component: <SmartKonversations />,
			isEnabled: (HWUser.isPremiumFeatureEnable() && access?.smartKonversation?.view()) ? true : false
		},
		{
			renderToNavbar: false,
			renderToSidebar: false,
			path: "/dashboard/smart-konversations/team_conversations",
			component: <SmartKonversations />,
			isEnabled: (HWUser.isPremiumFeatureEnable() && access?.smartKonversation?.view()) ? true : false
		},
		{
			renderToNavbar: false,
			renderToSidebar: false,
			path: "/dashboard/smart-konversations/my_conversations",
			component: <SmartKonversations />,
			isEnabled: (HWUser.isPremiumFeatureEnable() && access?.smartKonversation?.view()) ? true : false
		},
		{
			renderToNavbar: false,
			renderToSidebar: false,
			path: "/dashboard/smart-konversations/call_intelligence",
			component: <SmartKonversations />,
			isEnabled: (HWUser.isPremiumFeatureEnable() && access?.smartKonversation?.view()) ? true : false
		},
		{
			renderToNavbar: false,
			renderToSidebar: false,
			path: "/dashboard/smart-konversations/key_cloud",
			component: <SmartKonversations />,
			isEnabled: (HWUser.isPremiumFeatureEnable() && access?.smartKonversation?.view()) ? true : false
		},
		{
			renderToNavbar: false,
			renderToSidebar: false,
			path: "/dashboard/smart-konversations/manage_keywords",
			component: <SmartKonversations />,
			isEnabled: (HWUser.isPremiumFeatureEnable() && access?.smartKonversation?.view()) ? true : false
		},
		{
			renderToNavbar: false,
			renderToSidebar: false,
			path: "/dashboard/smart-konversations-team",
			component: <SmartKonversations />,
			param: { key: 'team', userId: '' },
			isEnabled: true
		},
	
		{
			renderToNavbar: false,
			renderToSidebar: false,
			path: "/dashboard/callIntelligence",
			component: <SmartKonversations />,
			param: { key: 'callIntelligence' },
			isEnabled: true
		},
		{
			renderToNavbar: false,
			renderToSidebar: false,
			path: "/dashboard/touchpoint-intelligence",
			component: <TouchpointIntelligence />,
			isEnabled: true
		},

		{
			renderToNavbar: false,
			renderToSidebar: false,
			path: "*",
			component: <Navigate key={"path-nav-default-dashboard-nav"} from={"*"} to={defaultTab} />,
			isEnabled: true
		},
		{
			path: "/callBackSalesforce",
			component: <SalesforceIntegrationCallback />,//CallBackSalesforce
			isEnabled: true,
		},
		// http://localhost:3000/asana/oauth/callback?code=0%2F0fa864e6f401408be44ab7610c46adbf&state=thisIsARandomString
		// {
		// 	path: "/asana/oauth/callback",
		// 	component: <AsanaIntegrationCallback />,
		// 	isEnabled: true,
		// },
		{
			path: "/jira/oauth/callback",
			component: <JiraIntegrationCallback />,
			isEnabled: true,
		},
		{
			path: "/gsuite/oauth/callback",
			component: <GSuiteIntegrationCallback />,
			isEnabled: true,
		},
		{
			path: "/outlook/oauth/callback",
			component: <OutlookIntegrationCallback />,
			isEnabled: true,
		},
		{
			path: "/hubspot/oauth/callback",
			component: <HubSpotIntegrationCallback />,
			isEnabled: true,
		},
		{
			path: "/zendesk/oauth/callback",
			component: <ZendeskIntegrationCallback />,
			isEnabled: true,
		},
		{
			path: "/zohoBook/oauth/callback",
			component: <ZohoBookIntegrationCallback />,
			isEnabled: true,
		},
		{
			path: "/pipedrive/oauth/callback",
			component: <PipedriveIntegrationCallback />,
			isEnabled: true,
		},
		{
			path: "/chargebee/oauth/callback",
			component: <ChargebeeIntegrationCallback />,
			isEnabled: true,
		},
		{
			path: "/teamworkdesk/oauth/callback",
			component: <TeamworkDeskIntegrationCallback />,
			isEnabled: true,
		},
		{
			path: "/teamSupport/oauth/callback",
			component: <TeamSupportIntegrationCallback />,
			isEnabled: true,
		},
		{
			path: "/intercom/oauth/callback",
			component: <IntercomIntegrationCallback />,
			isEnabled: true,
		},
		{
			path: "/zoom/oauth/callback",
			component: <ZoomIntegrationCallback />,
			isEnabled: true,
		},
		{
			path: "/academy-redirect",
			component: <AcademyRedirect />,
			isEnabled: true,
		},
	]
}


export default dashboardRoutes;