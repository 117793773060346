import { isInteger } from "lodash";

export const formatNameToTwoLetters = (usersList) => {
	let firstName = usersList && usersList;
	let nameArray = firstName && firstName.split(" ");
	let letterList = []
	nameArray && nameArray.map((item, index) => {
		letterList.push(item.charAt(0))
	})
	let userName = ""
	if (letterList && letterList.length === 1) {
		userName = letterList.toString()
	} else if (letterList && letterList.length > 1) {
		userName = letterList.splice(0, 2).toString()
		userName = userName.replace(",", "")
	} else {
		userName = null
	}
	return userName
}

// React Mentions: Here we are using the default markup regex '@[__display__](__id__)' format.
export const parseReactMentionsToHTMLText = (description) => {
	if (!description) { return description; }
	const regex = /@\[([^\]]+?)\]\(([^)]+?)\)/g;
	for (const match of description.matchAll(regex)) { description = description.replace(match[0], "<span class=\"user-mention-highlight\">@" + match[1] + "</span>"); }
	return description;
}

// getFileMimeType
// @param {Object} the file object created by the input[type=file] DOM element.
// @return {Object} a Promise that resolves with the MIME type as argument or undefined
// if no MIME type matches were found.
export const getFileMimeType = file => {

	// Making the function async.
	return new Promise(resolve => {
		let fileReader = new FileReader();
		fileReader.onloadend = event => {
			const byteArray = new Uint8Array(event.target.result);

			// Checking if it's JPEG. For JPEG we need to check the first 2 bytes.
			// We can check further if more specific type is needed.
			if (byteArray[0] == 255 && byteArray[1] == 216) {
				// resolve('image/jpeg');
				resolve('Image');
				return;
			}

			// If it's not JPEG we can check for signature strings directly.
			// This is only the case when the bytes have a readable character.
			const td = new TextDecoder("utf-8");
			const headerString = td.decode(byteArray);

			// Array to be iterated [<string signature>, <MIME type>]
			const mimeTypes = [
				// Images
				['PNG', 'image/png'],
				// Audio
				// ['ID3', 'audio/mpeg'],// MP3
				['ID3', 'Document'],// MP3
				// Video
				['ftypmp4', 'Video'],// MP4
				['ftypisom', 'Video'],// MP4
				// ['ftypmp4', 'video/mp4'],// MP4
				// ['ftypisom', 'video/mp4'],// MP4
				// HTML
				// ['<!DOCTYPE html>', 'text/html'],
				['<!DOCTYPE html>', 'Document'],
				// PDF
				// ['%PDF', 'application/pdf']
				['%PDF', 'Document']
				// Add the needed files for your case.
			];

			// Iterate over the required types.
			for (let i = 0; i < mimeTypes.length; i++) {
				// If a type matches we return the MIME type
				if (headerString.indexOf(mimeTypes[i][0]) > -1) {
					resolve(mimeTypes[i][1]);
					return;
				}
			}

			// If not is found we resolve with a blank argument
			resolve('Document');

		}
		// Slice enough bytes to get readable strings.
		// I chose 32 arbitrarily. Note that some headers are offset by
		// a number of bytes.
		fileReader.readAsArrayBuffer(file.slice(0, 32));
	});

};

export 	const convertTo2Digits = (value) => {
	let val = isInteger(value) ? value : parseInt(value);
	let str;

	if(val === 0) str = "00";
	else if(val < 10) str = '0' + val.toString();
	else str = val.toString();
	return str;
}