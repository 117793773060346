import { Button } from '@material-ui/core'
import React, { useEffect } from 'react'
import { useNavigate } from 'react-router-dom';
import errorIcon from "../../assets/images/error (1).svg"
import { Link } from 'react-router-dom'

function SkNoData(props) {

    useEffect(()=>{
        let elem1 = document.getElementById("element2-churn-logo");
        if(elem1){
            elem1 && elem1.remove();
        }
    },[])

    const history = useNavigate();
    
    const {text,supportText,minHeight,marginTop,borderBottom,marginBottom,showButton, buttonText, backGround,width,fromInvoice,marginLeft,link,linkText} = props;

    return (
        <div className="succesplays__noData" style={{minHeight:minHeight,marginLeft:marginLeft, backGround: backGround == true ? "white" : "", borderBottom:borderBottom == true ? '1px solid #ddd': ""}}>
            {fromInvoice ?  <div className="sk_popup_close"  onClick={() => props.actions.hideInvoiceModelActive()}></div> : ""}
             <div style={{marginTop:marginTop, marginBottom:marginBottom, display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
						 	<div className='theme__based__no__data__icon' />
                <h3 className={(props && props.isContactMappingScreen && props.isContactMappingScreen) ?"textContentForNoDataContactMapping":"textContentForNoData"}style={{fontSize:'14px !important'}} >{text}</h3>
               <p className={props && props.isDashboardScreen && props.isDashboardScreen ?"supportTextForDashboardScreen" :"supportTextAccount360"} style={{fontSize:'12px !important',width:width}}>{supportText}</p>
               {link ?<Link style={{ color: "rgb(252, 114, 66)", cursor: "pointer", fontSize:"14px", fontWeight:"400", }} to={{ pathname: link}} >
                           {linkText}
                </Link>:'' }
           </div>
          {showButton == true ? <div style={{marginTop:'10px'}}><Button onClick = {()=>{history.push(props.navigationPath)}}className="add__btn">{buttonText}</Button></div>: ""  }  
         
        </div>
    )
}

export default SkNoData
