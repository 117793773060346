import { skKeyOutcomeActions } from "../constants/actionType";

const initialState = {
	isChurnDashboardLoading: false,
	churnDashboardImpactedData: null,
	churnReasonsReferenceData: null,
	churnRateData: null,
	churnTimeLineTrendData: null,
	churnReasonData: null,
	churnReasonDataByProducts: null,
	churnByProductGraphData: null,
	churnAccountsByCategory: null,
	updatingChurnTolerance: false,
	churnCurrency: null,
	churnedAccountCount: 0,
	renewalPotentialData: null,
	renewalAchievedData: null
}


export const skKeyOutcomesActionReducer = (state = initialState, { type, payload }) => {
	switch (type) {
		case skKeyOutcomeActions.FETCH_CHURN_LIST:
		case skKeyOutcomeActions.FETCH_CHURN_REASONS_REFERENCE_LIST:
		case skKeyOutcomeActions.UPDATE_CHURN_REASON:
		case skKeyOutcomeActions.FETCH_CHURN_DASHBOARD_COUNT:
		case skKeyOutcomeActions.FETCH_DATA_CHURN_DASHBOARD:
		case skKeyOutcomeActions.POST_CHURN_TOLERANCE:
		case skKeyOutcomeActions.SET_SMART_KEY_OUTCOME_STATES: { return { ...state, ...payload } }
		default: return state;
	}
};

export default skKeyOutcomesActionReducer
