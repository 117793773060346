import { ClickAwayListener } from '@material-ui/core';
import { cloneDeep, debounce } from 'lodash';
import React, { useRef, useState } from 'react';
import Select, { components } from 'react-select';
import AsyncSelect from 'react-select/async';
import AutoUiLabel from '../../SkSmartUtil/UiLabel/AutoUiLabel';
import { formatNameToTwoLetters } from '../SkSmartFunctions';
import './SkSmartMultiSelectDropDown.css';
import { styleReactMultiSelect } from './SkSmartReactSelectStyles';
import { getSmartTaskStates } from '../../store/reducers/skTaskReducer';
import { useSelector } from 'react-redux';


const DropdownIndicator = (props) => {
	return (
		<span className='multi__select__dropdown__icon__container'>
			{(props?.selectProps?.showArrowIcon) ?
				<span className='multi__select__dropdown__icon__container-arrow__icon'>
				</span>
				:
				<span className='multi__select__dropdown__icon__container-plus__icon'>
				</span>}
		</span>
	)
};

const NoOptionsMessage = (props) => {
	return (
		<div className='smart___react__multi__select__no__data__wrapper'>
			<div className='smart___react__multi__select__no__data'> No data found</div>
		</div>
	)
}

//Add your search logic here.
const customFilter = (option, searchText) => {
	if (
		option?.data?.label?.toLowerCase().includes(searchText.toLowerCase()) ||
		option?.data?.value?.toLowerCase().includes(searchText.toLowerCase())
	) {
		return true;
	} else {
		return false;
	}
};

export default function SkSmartMultiSelectDropDown({ showProfileIcon, isSearchable, isClearable, blurInputOnSelect, id, asyncSelect, placeholderText, 
	isMultiSelect, className, customLabelClassName,showFlagClassname, options, actions, isRequired, selectFilterType,showFlag,showOnlyTooltip, ...props }) {

	const getSmartTaskState = useSelector(getSmartTaskStates);
	const { usersColorMap } = getSmartTaskState;
	const selectRef = useRef();
	const [openSelectList, setOpenSelectList] = useState(false);
	let selectOptions = (options && options?.length > 0) ? options : [];

	
	const asyncOptions   = (searchText, optionsCallback) => {
		props?.asyncOptionsCallback(searchText).then(response => {
				optionsCallback(response);
		}).catch(() => { optionsCallback([]); })
}
  const loadSuggestions = debounce(asyncOptions, 100);
	const formatOptionLabel = ({ label, value, id, imageUrl, accountType,flagClassName }) => {

		return (
			<div className='smart__react__select__label__wrap'>
			{showProfileIcon &&
				<div className='smart__react__select__profile__icon__wrap'>
					<div className='smart__react__select__profile__icon__container'  style={{ backgroundColor: (usersColorMap[id] || 'var(--smart-konversations-label-wrap-color)') }}>
							<div className='smart__react__select__profile__icon'>
								{imageUrl ? <img alt="" src={imageUrl} className="smart__react__select__profile__icon-image" /> :
									<div className="smart__react__select__profile__icon-text">{formatNameToTwoLetters(label)}</div>
								}	
						</div>
					</div>
				</div>
				}
				{showFlag && 
				<>
					<div className={flagClassName}></div>
				</>
				}
				{selectFilterType === 'Account' && <>
					{/* <div className='smart__react__select__parent__account__container'> */}
						{accountType === 'PARENT' && <div className='smart__react__select__parent__account__icon__second-attempt'></div>}
						{accountType === 'CHILD' && <div className='smart__react__select__child__account__icon__second-attempt'></div>}
						{/* </div>		 */}
				</>}
			<div className={'smart__react__select__label ' + customLabelClassName}>
				<AutoUiLabel showOnlyTooltip={showOnlyTooltip} tooltipClass={props?.optionTooltipClass} id={id} label={label} width={"100%"} placement={'top'} />
			</div>
		</div>
		)
	} 

	return (
		<ClickAwayListener onClickAway={() => { setOpenSelectList(false);props.onClickAway && props.onClickAway() }}>
			<div ref={selectRef} onClick={(e) => { e.preventDefault(); e.stopPropagation(); }} className={`smart__react__multi__select__wrapper  ${isRequired && (!props?.value || props?.value?.length === 0) ? 'smart_react_required_select' : ''}`}>
				{asyncSelect ?
					<AsyncSelect {...props}
						id={'smart_async_select_id' + id}
						classNamePrefix='smart__react__select'
						isSearchable={isSearchable === undefined ? true :  isSearchable}
						isClearable={isClearable === undefined ? true :  isClearable}
						blurInputOnSelect={blurInputOnSelect === undefined ? true :  blurInputOnSelect}
						formatOptionLabel={formatOptionLabel}
						className={'smart_async_select__container '+className}
						styles={styleReactMultiSelect}
						options={selectOptions}
						menuPosition={props?.menuPosition || 'fixed'}  
						loadOptions={loadSuggestions}
						defaultOptions={selectOptions}
						filterOption={customFilter}
						isDisabled={props?.isDisabled}
						isMulti={isMultiSelect}
						isOptionDisabled={(option) => option?.disabled}
						hideSelectedOptions={true}
						components={{
							DropdownIndicator,
							NoOptionsMessage,
							IndicatorSeparator: () => null
						}} placeholder={placeholderText ? placeholderText : ''}
						onChange={(e) => {
							props?.onChange && typeof props?.onChange === 'function' && props?.onChange(e,props?.keyName,props)
							actions?.handleCheck && actions?.handleCheck(e,props?.keyName);
							setOpenSelectList(!openSelectList)
						}}
					/> :
					<Select {...props}
						id={'smart_react_select_id' + id}
						classNamePrefix='smart__react__select'
						isMulti={isMultiSelect ? isMultiSelect : false}
						isSearchable={isSearchable === undefined ? true :  isSearchable}
						isClearable={isClearable === undefined ? true :  isClearable}
						menuPlacement={props?.menuPlacement}
						// blurInputOnSelect={blurInputOnSelect === undefined ? true :  blurInputOnSelect}
						formatOptionLabel={formatOptionLabel}
						openMenuOnClick={true}
						closeMenuOnSelect={isMultiSelect? false : true}
						options={selectOptions}
						isOptionDisabled={(option)=>option?.disabled}
						hideSelectedOptions={true}
						filterOption={customFilter}
						styles={styleReactMultiSelect}
						menuPosition={props?.menuPosition || 'fixed'}  
						className={'smart__react__select__container ' + className}
						onChange={(e) => {
							props?.onChange && typeof props?.onChange === 'function' && props?.onChange(e,props?.keyName,props)
							actions?.handleCheck && actions?.handleCheck(e,props?.keyName);
							setOpenSelectList(!openSelectList)
						}}
						isDisabled={props?.isDisabled}
						components={{
							DropdownIndicator,
							NoOptionsMessage,
							IndicatorSeparator: () => null
						}} 
						placeholder={placeholderText ? placeholderText : ''} />}
						{props?.showRightIcon && <div className={props?.showRightIconClassName} onClick={e => props.onShowRightIconClick(props.keyName)}></div>}
			</div>
		</ClickAwayListener>
	);
}

const CustomOption = (props) => {
	const {customProp} = props;
	if(!customProp?.customOptions) {
		return (
			<components.Option {...props}>{props.children}</components.Option>
		)
	}

	return (
		<div>
			<components.Option {...props}>
				<div style={{ width: "100%" }} className="d-flex align-items-center">
					{customProp?.hasCheckBox && <div className={props?.isSelected ? "filter-checkmark-container-selected" : "filter-checkmark-container-unselected"}>
						<div className="filter-checkmark-tick"></div>
					</div>}
					<div className="w-100" style={{ display: 'inline-flex' }}>
						<div className="global__filter__account__icon__text">
							<AutoUiLabel width={"100%"} placement="top" tooltipClass="HWSelectMultiSelectTooltip" label={props?.label} />
						</div>
						{ props?.data?.infoText && 
							<AutoUiLabel showToolTipWithHtml={true} width={"5%"} tooltipClass="custom_menu_option_info_wrap_tooltip" placement="left" label={props?.data?.infoText}>
								<span className='custom_menu_option_info_wrap'/>
							</AutoUiLabel>
						}
					</div>
				</div>
			</components.Option>
		</div>
	);
};

export function SkCustomMultiSelectDropDown({showProfileIcon, isSearchable, isClearable, blurInputOnSelect, id, asyncSelect, placeholderText, 
	isMultiSelect, className, customLabelClassName,showFlagClassname, options, actions, isRequired, selectFilterType,showFlag,showOnlyTooltip, ...props}) {
		const customProps = cloneDeep(props);
		const [openSelectList, setOpenSelectList] = useState(false);
		const selectRef = useRef();
		let selectOptions = (options && options?.length > 0) ? options : [];

		return (
			<ClickAwayListener onClickAway={() => { setOpenSelectList(false); props.onClickAway && props.onClickAway() }}>
				<div ref={selectRef} onClick={(e) => { e.preventDefault(); e.stopPropagation(); }} className={`smart__react__multi__select__wrapper  ${isRequired && (!props?.value || props?.value?.length === 0) ? 'smart_react_required_select' : ''}`}>
					<Select {...props}
							id={'smart_react_select_id' + id}
							classNamePrefix='smart__react__select'
							isMulti={isMultiSelect ? isMultiSelect : false}
							isSearchable={isSearchable === undefined ? true :  isSearchable}
							isClearable={isClearable === undefined ? true :  isClearable}
							menuPlacement={props?.menuPlacement}
							openMenuOnClick={true}
							closeMenuOnSelect={isMultiSelect? false : true}
							options={selectOptions}
							isOptionDisabled={(option)=>option?.disabled}
							hideSelectedOptions={true}
							filterOption={customFilter}
							styles={styleReactMultiSelect}
							menuPosition={props?.menuPosition || 'fixed'}  
							className={'smart__react__select__container ' + className}
							onChange={(e) => {
								props?.onChange && typeof props?.onChange === 'function' && props?.onChange(e,props?.keyName,props);
								setOpenSelectList(!openSelectList);
							}}
							isDisabled={props?.isDisabled}
							components={{
								DropdownIndicator,
								Option : (props) => <CustomOption customProp={customProps} {...props} /> ,
								NoOptionsMessage
							}}
							placeholder={placeholderText ? placeholderText : ''} />
				</div>
			</ClickAwayListener>
		);
	}