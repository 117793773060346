import { ClickAwayListener, Popper } from "@material-ui/core";
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import { styled } from '@mui/material/styles';
import React, { memo, useCallback, useMemo, useRef, useState, lazy, Suspense } from "react";
import PageVisibility from 'react-page-visibility/dist/umd/PageVisibility';
import { useDispatch } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import "../CustomerSupport/CustomerSupport.css";
import access from "../HWLibrary/AccessControl";
import { fetchUserProfile, onPlatformEnvironmentChange, onPlatformTokenRefresh, onPlatformUserSwitch, platformSignOut } from '../HWLibrary/HWIframeBridge';
import HWUser from "../HWLibrary/HWUser";
import { disableBodyScroll } from "../HWLibrary/HWUtils";
import PlatformAPI from "../HWLibrary/PlatformAPI";
// import SKAnalyticsLog from "../HWLibrary/SKAnalyticsLog";
import { singleSelect } from '../HWLibrary/SKUtility';
import skApiService from "../SkApiService";
import gsuiteAPIService from "../SkSmartUtil/GsuiteAPIService";
import cache from "../SkSmartUtil/InMemoryCache";
import outlookAPIService from '../SkSmartUtil/OutLookApiService';
// import SkSmartGlobalSearch from "../SkSmartUtil/SkSmartGlobalSearch/SkSmartGlobalSearch";
import SkSmartMultiSelectDropDown from '../SkSmartUtil/SkSmartMultiSelectDropDown/SkSmartMultiSelectDropDown';
import smartFilterTokenService from "../SmartFilterTab/SmartFilterToken";
// import ConfigureZoomPullover from '../SmartKonversations/ConfigureZoomPullover';
import EditProfile from '../assets/images/EditProfile.svg';
import Logout from '../assets/images/Logout.svg';
import "../assets/styles/digikit.css";
import { getAccountListStates, getGlobalStates, getNotifcationCount, setAccountListStates } from '../store/reducers/skGlobalReducer';
import { fetchReferenceData } from '../store/reducers/skKonversationReducer';
import { playbookRbackScope, setSmartPlayBookStates } from "../store/reducers/skPlaybooksReducer";

import NavbarRoutes from "./NavbarRoutes.jsx";
import ProfileOptions from "./ProfileOptions";
import './SmartSideBar.css';
import { setGlobalStates } from '../store/reducers/skGlobalReducer';
import sk_logo_standard from '../assets/phoenix/skLogoStandard.ico';
import sk_logo_standalone from '../assets/phoenix/skLogoStandalone.ico';
import { useEffectOnce } from "../SkSmartUtil/useEffectOnce.jsx";
import SkLoaderComponent from "../SkSmartUtil/SkLoader/SkLoaderComponent.jsx";
import { formatNameToTwoLetters } from "../SkSmartUtil/SkSmartFunctions.js";
 
// import SkSmartSupportPopUp from "../CustomerSupport/SkSmartSupportPopUp.jsx";

// import zohoAPIService from "../views/Settings/SettingsChildren/AppIntegration/ZohoBook/ZohoApiService";
// import zoomApiService from "../views/Settings/SettingsChildren/AppIntegration/Zoom/ZoomApiService";
const SKAnalyticsLog = lazy(() => import("../HWLibrary/SKAnalyticsLog"));
const SkSmartSupportPopUp  = lazy(() => import("../CustomerSupport/SkSmartSupportPopUp.jsx"));
// const SkSmartMultiSelectDropDown= lazy(() => import('../SkSmartUtil/SkSmartMultiSelectDropDown/SkSmartMultiSelectDropDown'));
const zohoAPIService= lazy(() => import("../views/Settings/SettingsChildren/AppIntegration/ZohoBook/ZohoApiService"));
const zoomApiService= lazy(() => import("../views/Settings/SettingsChildren/AppIntegration/Zoom/ZoomApiService"));
// const EditProfile = lazy(() => import('../assets/images/EditProfile.svg'));
const ConfigureZoomPullover = lazy(() => import('../SmartKonversations/ConfigureZoomPullover'));
const SkSmartGlobalSearch = lazy(() => import("../SkSmartUtil/SkSmartGlobalSearch/SkSmartGlobalSearch"));






 function SkNavBar({  menuName }) {
	const [navBarState, setNavBarState] = useState({
		showKnkPopover: false,
		showSearchBarForNav: false,
		platformUserRole: [],
		prefferedRole: [],
		activeLink: '',
		accountOptions: [],
		roleToggle: null,
		isFetchingAlertFeeds: false,
		refreshTime: new Date().toISOString(),
		alertFeeds: null,
		alertsNewlyAddedCount: null,
		isAlertDataLoaded: false,
		isFetchingZohoDetails: false,
		showCustomerSupportPopOver: false,
		isRefreshingToken: false,
		redirectToCSM: true,
		showAlertPopup: false
	})

	// console.log("========", menuName);
	const showCustomerPopupRef = useRef(null);
	const roleOptions = useMemo(() => {
		return [{ label: 'CSM-LSM (CSM View)', value: 'CSM_VIEW' }, { label: 'CSM-LSM (LSM View)', value: 'LSM_VIEW' }]
			;
	}, [])

	// const alertServices = new AlertServices();
	// const oldTouchPointService = new APIservices();
	const platformAPIService = new PlatformAPI();
	let newimage = ''
	let selectedRole = navBarState?.prefferedRole;
	let user = []
	user = (JSON.parse(window.localStorage.getItem("platform-currentUser")))
	let isProductionPlatformEnvironment = HWUser.platformEnvironment() === "Production";
	if (user && user.user && user.user.profileImageURL) { newimage = user.user.profileImageURL }
	let userImageURL = HWUser.userProfileImageURL() || newimage;
	let userName = user && user.user && user.user.name;

	const dispatch = useDispatch();

	const setNavBarDetails = (updatedState) => {
		setNavBarState(prevState => (
			{
				...prevState,
				...updatedState
			}
		))
	}

	const onVisibilityChange = (isVisible) => {
		if (!isVisible) { return; }
		// dispatch(getNotifcationCount());
	}

	useEffectOnce(() => {
		
		// dispatch(getNotifcationCount());
		window.addEventListener("message", (message) => {
			if (message.data === "refresh-nav-bar") {
				setNavBarDetails({ refreshTime: new Date().toISOString() })
			}
		});

		if(localStorage.getItem("auth")){
			 return;
		 }
		fetchUserProfile();
		fetchAccounts();
		dispatch(fetchReferenceData());
		setTimeout(() => {
			fetchAlertFeedList();
			fetchPlatformUserRole();
		}, 1000)

		return () => {
			const link = document?.querySelector("link[rel~='icon']");
			link.href = sk_logo_standard; 
			document.head.appendChild(link);
			window.document.title = "SmartKarrot - Customer Success Platform";
			setNavBarDetails({
				showKnkPopover: false,
				showSearchBarForNav: false,
				platformUserRole: [],
				prefferedRole: [],
				activeLink: '',
				accountOptions: [],
				roleToggle: null,
				isFetchingAlertFeeds: false,
				refreshTime: new Date().toISOString(),
				alertFeeds: null,
				alertsNewlyAddedCount: null,
				isAlertDataLoaded: false,
				isFetchingZohoDetails: false,
				showCustomerSupportPopOver: false,
				isRefreshingToken: false,
				redirectToCSM: true,
				showAlertPopup: false
			})
		}
	})


	const switchPlatform = useCallback(() => {
		if (window.confirm("Are you sure you want to switch ?")) {
			platformAPIService.switchPlatformEnvironment().then(response => {
				let status = response.data.status;
				if (status === "RESTRICTED_PLATFORM") {
					const val = HWUser.platformEnvironment()
					// alert("This account is available only in " + val + " Mode.")
					return;
				}
				onPlatformEnvironmentChange(response);
			}).catch(error => {
				// alert("Could not switch platform");
			})
		}
	}, [platformAPIService])


	const setLsmview = useCallback(() => {
		let viewType = window.localStorage.getItem("listView") || window.localStorage.getItem("lsmViewType");
		let selectedObj = roleOptions?.filter(item => item?.value === viewType)[0];
		setNavBarDetails({ roleToggle: selectedObj })
	}, [roleOptions])

	const fetchPlatformUserRole = useCallback(() => {
		let roles = []
		let platformUserRole = JSON.parse(window.localStorage.getItem("platform-currentUser")).userRoles || [];
		platformUserRole.map(item => roles.push({ value: item.roleId, label: item.roleName, preferredRole: item.preferredRole }))
		let prefferedRole = roles.filter(item => item.preferredRole)[0];
		setNavBarDetails({ platformUserRole: roles, prefferedRole: prefferedRole });
	}, [])


	const fetchAlertFeedList = useCallback(() => {
		if (navBarState?.isFetchingAlertFeeds === true) { return; }
		setNavBarDetails({ isFetchingAlertFeeds: true });
		let data = { userId: HWUser.userId(), appId: HWUser.appId() }
		// oldTouchPointService.fetchAlertFeedData(data).then(response => {
		// 	setNavBarDetails({ isFetchingAlertFeeds: false });
		// 	setNavBarDetails({ alertFeeds: response.alertFeeds, alertsNewlyAddedCount: response.newlyAddedCount, isAlertDataLoaded: true, isFetchingAlertFeeds: false });
		// 	// actionsDashboard.updateAlert();
		// }).catch(error => {
		// 	setNavBarDetails({ isFetchingAlertFeeds: false });
		// });
	}, [])


	const onConnectToAsana = useCallback(() => {
		let callbackURLAsana = window.origin + "/asana/oauth/callback";
		let asanaRedirectURL = "https://app.asana.com/-/oauth_authorize?client_id=" + process.env.REACT_APP_ASANA_APP_CLIENT_ID + "&redirect_uri=" + callbackURLAsana + "&response_type=code&state=csm&scope=default";
		window.location = asanaRedirectURL;
	}, [])

	const onConnectToJira = useCallback(() => {
		let callbackURLJira = window.origin + "/jira/oauth/callback";
		let jiraRedirectURL = "https://auth.atlassian.com/authorize?audience=api.atlassian.com&client_id=" + process.env.REACT_APP_JIRA_APP_CLIENT_ID + "&scope=offline_access%20read%3Ajira-user%20read%3Ajira-work%20manage%3Ajira-project%20manage%3Ajira-configuration%20write%3Ajira-work%20manage%3Ajira-data-provider&redirect_uri=" + callbackURLJira + "&state=" + HWUser.userId() + "&response_type=code&prompt=consent";
		window.location = jiraRedirectURL;
	}, [])

	const onConnectToGSuite = useCallback(() => {
		window.location = gsuiteAPIService.gsuiteOAuthURL();
	}, [])

	const onConnectToOutlook = useCallback(() => {
		window.location = outlookAPIService.outlookOAuthURL();
	}, [])

	const onConnectToZoho = useCallback(() => {
		const skIntegrationScopes = ["ZohoEmailAndCalendar"];
		setNavBarDetails({ isFetchingZohoDetails: true });
		zohoAPIService.zohoOauthURLforEmailOtherUser(skIntegrationScopes).then(oAuthURL => {
			window.location = oAuthURL;
		}).finally(() => {
			setNavBarDetails({ isFetchingZohoDetails: false });

		});
	}, [])

	const onConnectToZoom = useCallback(() => {
		window.location = zoomApiService.zoomOAuthURL("zoom");
	}, [])

	const handleClick = useCallback((clicked) => {
		setNavBarDetails({ activeLink: clicked });
		switch (clicked) {
			case "search": actions.onClickSearch(); break;
			case "support": actions.onClickSupport(); break;
			case "academy": actions.onClickAcademy(); break;
				
				case "notification": actions.onClickNotification(); break;
			default: break;
		}
	}, [])

	const handleProfileIconClick = useCallback((click) => {
		switch (click) {
			case "onConnectToAsana": onConnectToAsana(); break;
			case "onConnectToJira": onConnectToJira(); break;
			case "onConnectToGSuite": onConnectToGSuite(); break;
			case "onConnectToOutlook": onConnectToOutlook(); break;
			case "onConnectToZoho": onConnectToZoho(); break;
			case "onConnectToZoom": onConnectToZoom(); break;
			default: break;
		}
	}, [onConnectToAsana, onConnectToGSuite, onConnectToJira, onConnectToOutlook, onConnectToZoho, onConnectToZoom])


	const showCustomerSupportPopOver = useCallback((showStatus) => {
		SKAnalyticsLog.logEvent("Customer Support: View"); disableBodyScroll();
		setNavBarDetails({ showCustomerSupportPopOver: !navBarState?.showCustomerSupportPopOver });
	}, [])

	const navigate = useNavigate()



	// Search Functions===================
	const handleSearchIcon = useCallback(async () => {
		// when user types something and searches redirect to account list.....
		if (getAccountListStates()?.globalSearchOptionsOnSearch && getAccountListStates()?.globalSearchOptionsOnSearch?.length > 0) {
			let filteredAccounts = getAccountListStates()?.globalSearchOptionsOnSearch?.map((account, index) => {
				return account?.value
			})
			await smartFilterTokenService.addPathFilter("account_listing");
			await smartFilterTokenService?.addAccounts((filteredAccounts), ([]));
	
			const state = { activeTab: { title: 'My Accounts', id: 'my_accounts', primary: true, isEnable: true, path: "account_listing" } };
					setTimeout(() => {
						navigate('/dashboard/portfolio', { state: state });
			}, 1000)

			dispatch(setAccountListStates({ globalSearchOptionsOnSearch: [] }))
		}
		// when user has selected some account already search icon click shouldn't do anything.....
		else if (getAccountListStates()?.globalSearchSelectedAccounts || getAccountListStates()?.globalSkSearchedText) {
			return;
		}
		else {
			dispatch(setAccountListStates({ showGlobalSKSearch: !getAccountListStates()?.showGlobalSKSearch }));
		}
	}, [dispatch, navigate])


	const asyncOptionsCallback = useCallback((searchText, type) => {
		let reqdata = {
			searchForText: searchText,
		}
		let accounts = skApiService?.serachAccountsOptionsForGlobalFilter(reqdata).then(response => response.data.accounts.map(i => {
			return {
				value: i.id,
				valid: true,
				label: i.name,
				productIds: i.productIds,
				customerAccountType: i.customerAccountType,
			}
		}));

		return accounts;
	}, [])

	const fetchAccounts = useCallback(() => {
		skApiService.serachAccountsOptionsForGlobalFilter().then(response => {
			let accountList = response?.data?.accounts && response?.data?.accounts?.length > 0 && response?.data?.accounts?.map((account, index) => {
				return {
					value: account?.id,
					valid: true,
					label: account?.name,
					productIds: account?.productIds,
					customerAccountType: account?.customerAccountType,
				}
			})
			setNavBarDetails({ accountOptions: accountList });
		})
	}, [])

	const asyncOptions = useCallback((searchText, optionsCallback) => {
		asyncOptionsCallback(searchText).then(response => {
			optionsCallback(response);
			dispatch(setAccountListStates({ globalSearchOptionsOnSearch: response }));
		}).catch(() => { optionsCallback([]); })
	}, [])

	// Search Functions===================



	const onRoleChange = (e) => {
		window.localStorage.setItem('refresh-load-path', window.location.pathname);
		setNavBarDetails({ prefferedRole: { value: e.value, label: e.label, preferredRole: true } });
		platformAPIService.switchRole(e?.value).then(response => {
			fetchAccessTokenAndRefresh(true);
			// let url = window.localStorage.getItem('refresh-load-path');
			// window.location = url;

		
			cache.invalidateForKey(HWUser.userId(), cache.CacheModule.productConfig);
		
		}).catch(err => {
			// console.log(err)
		})
	}

	const fetchAccessTokenAndRefresh = (onRoleChange) => {
		if (navBarState?.isRefreshingToken === true) {
			return;
		}
		platformAPIService.fetchAccessTokenAndRefresh().then(response => {
			onPlatformTokenRefresh(response.data.accessToken, response.data.refreshToken)
		}).finally(() => {
			setNavBarDetails({ isRefreshingToken: false });
			if (onRoleChange) {
				setTimeout(() => {
					window.location.reload()
				}, 2000)
					
				}
		});
	}

	const switchOrganization = (org) => {
		platformAPIService.switchOrganization(org).then(response => {
			onPlatformUserSwitch(response);
			window.localStorage.setItem("platform-currentUser", JSON.stringify(response.data))
			HWUser.updateUserProfileData(response.data);
			setNavBarDetails({ redirectToCSM: true });
		})
	}
	const onUserLogOut = () => {
		setNavBarDetails({ accountOptions: [] });
		dispatch(setAccountListStates({ accountList: [], showGlobalSKSearch: false, globalSearchSelectedAccounts: null }))
		platformSignOut();
		dispatch(setSmartPlayBookStates({ playbookRbacResponse: null }))
	}

	const getAcademyPath = useCallback(() => {
		let academyPath;
		let sideTabMapper = [{ id: 'My Workspace', name: 'workspace' },
		{ id: 'Key Outcomes', name: "key_outcomes" }, { id: 'My Portfolio', name: 'myportfolio' }, { id: 'Product Analytics', name: 'product_analytics' }, { id: 'SmartInsights', name: 'smart_insights' }, { id: 'Kommunity', name: 'Kommunity' }, { id: 'SmartOps', name: 'smartops' }, { id: 'SmartKonversations', name: 'smartkonversation' }, { id: 'SmartAssist', name: 'smart_assist' }, { id: 'Playbooks', name: 'playbook_standalone' }, { id: 'Kommunity Stand Alone', name: 'Kommunity_standalone' }, { id: "SmartKonversations Stand Alone", name: 'smartkonversation_standalone' }]

		if (getGlobalStates()?.globalParentActiveTab && getGlobalStates()?.globalActiveSideBarTab) {
			let sideTab = sideTabMapper?.find(item => item?.id === getGlobalStates()?.globalActiveSideBarTab)
			academyPath = sideTab?.name;
			if (getGlobalStates()?.globalActiveSideBarTab === 'Kommunity' || getGlobalStates()?.globalActiveSideBarTab === 'SmartAssist' || getGlobalStates()?.globalActiveSideBarTab === 'SmartInsights') {
				return academyPath
			} else {
				let activeTab = getGlobalStates()?.globalParentActiveTab?.title;
				academyPath = academyPath + '_' + activeTab?.split(' ')?.join('_')?.toLowerCase();
				return academyPath
			}
		}

	}, [])

	const handleAcademyRedirect = useCallback(() => {
		let path = getAcademyPath()
		window.open("/academy-redirect?path=" + path, '_blank', 'noopener,noreferrer');
	}, [getAcademyPath])

	const handleOnClickNotification = useCallback(() => {
		const state = { activeTab: { title: "Dashboard", id: "alerts-dashboard", path: 'smart_notification_alert_dashboard', isEnable: access?.smartOps?.alert?.dashboardView(), rootPath: "/dashboard/success-intelligence/alerts-dashboard", } };
		navigate('/dashboard/success-intelligence/alerts-dashboard', { state: state });
	},[])

	const actions = {
		onClickSearch: (e) => { handleSearchIcon() },
		onClickSupport: () => {
			setNavBarDetails({ showCustomerSupportPopOver: !navBarState?.showCustomerSupportPopOver });
		},
		onClickAcademy: () => {
			handleAcademyRedirect()

		},
		onAddAlertToggle: (selectedAlertAccount, alertType) => {
			setNavBarDetails({ showAlertPopup: false, isAddAlertModalActive: !navBarState?.isAddAlertModalActive, alertType: alertType, selectedAlertAccount: selectedAlertAccount });

		},
		onClickNotification: () => {
			handleOnClickNotification()
		},
		archiveAlertNav: (data) => {
			// archiveAlert(data)
		},
		closeAlertPopup: () => {
			setNavBarDetails({ isAddAlertModalActive: false });

		},
		fetchFilteredAlertFeedList: (data) => {
			// fetchFilteredAlertFeedList(data)
		}
	}

	const notifyIssueSubmission = () => {
    dispatch(
      setGlobalStates({
        showNotification: true,
        notificationMsg: 'Issue Reported.',
      }),
    );

    setTimeout(() => {
      dispatch(
        setGlobalStates({
          showNotification: false,
          notificationMsg: '',
        }),
      );
    }, 3000);
  };

	const customCheckForSettingsActive = (item) => {
		if(item?.altTag !== "Settings") return true;
		if(window.location.pathname.includes("settings")) return true;
		return false;
	}
	const getCssVariableValue = (variableName) => {
		return getComputedStyle(document.documentElement).getPropertyValue(variableName).trim();
	}

	const handleFavIconAndTitleChange = () => {
		const link = document?.querySelector("link[rel~='icon']");
		if(window.localStorage.getItem("CustomTheme")) {
			let faviconUrl = getCssVariableValue('--favicon-url');
			if(faviconUrl) {
				faviconUrl = faviconUrl?.slice(1,-1);
				link.href = faviconUrl;
				document.title = "Playbooks";
			}
			else {
				if(HWUser.orgType() === "playbookStandalone") { 
					link.href = sk_logo_standalone; 
					document.title = "SmartPlaybooks";
				} 
				else { 
					link.href = sk_logo_standard; 
					document.title = "SmartKarrot - Customer Success Platform";
				}
			}
		}else {
			if(HWUser.orgType() === "playbookStandalone") { 
				link.href = sk_logo_standalone; 
				document.title = "SmartPlaybooks";
			} 
			else { 
				link.href = sk_logo_standard; 
				document.title = "SmartKarrot - Customer Success Platform";
			}
		}
		document.head.appendChild(link);
	}

	handleFavIconAndTitleChange();

	const User = (JSON.parse(window.localStorage.getItem("platform-currentUser")))?.user;
	const getUserNameInitials = () => {
		let name = formatNameToTwoLetters(User?.name?.toUpperCase());
		return name;
	}

	return (
		<>
		<Suspense fallback={<SkLoaderComponent isLoading={true} className="skLoaderComponent" />}>

		<div className="main-navbar sticky-top main_header">
			{getGlobalStates()?.showZoomPullover && <ConfigureZoomPullover />}
			{navBarState?.showCustomerSupportPopOver &&
				<SkSmartSupportPopUp showCustomerSupportPopOver={actions?.onClickSupport}/>
			}

			<nav className="navbar align-items-stretch navbar-light flex-md-nowrap">
				<div style={{ position: "relative" }} action="#" className="main-navbar__search d-none d-md-flex d-lg-flex">
					<div className="clinet_logo">
						<span className="nav-header themeColor">
							<span className="org__name">{(HWUser.orgName() || "").trim() + "\u00A0\u00A0" + "/" + "\u00A0\u00A0"}</span>
							<span className="tab__name">{(playbookRbackScope(["CustomerPortalUser:Enabled"]) ? 'Customer Portal' :menuName ? menuName : "").trim()}</span>
						</span>
					</div>
				</div>
				{HWUser.isSandboxAndProdSwitchEnabled() ? <div className="sk_toggle_wrap" style={{ marginTop: "-2px" }} >
					<span className="cstl1">Production</span>
					<div className="custom-control custom-toggle top_bar d-block my-2"  >
						<input key={isProductionPlatformEnvironment} checked={!isProductionPlatformEnvironment} onChange={switchPlatform} type="checkbox" id="customToggle1" name="customToggle1" className="custom-control-input" />
						<label className="custom-control-label" htmlFor="customToggle1"></label>
					</div>
					<span className="cstl1" style={{ paddingRight: "0" }}>Sandbox</span>
				</div> : ""}

				<ul className="navbar-nav flex-row ">

					<li className="navbar-nav__nav_items" style={{ padding: '0.625rem', marginRight: '-2.188rem' }}>
						{getAccountListStates()?.showGlobalSKSearch &&
							<div className="smart__global__search-container"
								style={{ border: (getAccountListStates()?.showGlobalSKSearch) ? '1px solid #cfcfcf' : '' }}>
								<SkSmartGlobalSearch
									asyncOptions={asyncOptions}
									handleSearchIcon={handleSearchIcon}
									accountOptions={navBarState?.accountOptions} />
							</div>
						}

					</li>
					{NavbarRoutes().filter(prop => prop.isEnable).map((item, routeIndex) => {
						return (
							<li key={item?.className} className="navbar-nav__nav_items">
								<StyledTooltip title={item.altTag}><div key={"side-bar-route-key-" + routeIndex} className={`navbar-nav__header-icons`}>
									{item.redirect ? <>
										<Link to={item.redirect}>
											<i className={`${item.className} ${navBarState?.activeLink === item.onClick && customCheckForSettingsActive(item) ? 'activeLink' : ''}`} onClick={(e) => { handleClick(item.onClick) }} />
											{item.showBadge &&
												<div style={{ fontSize: (item.badgeCount) >= 100 ? '9px' : '', height: (item.badgeCount) >= 100 ? '21px' : '', width: (item.badgeCount) >= 100 ? '21px' : '' }} className="sk-smart-badge">
													<div className="sk-smart-badge-count">
													{item.badgeCount}
													</div>
												
												</div>}
										</Link>
									</>
										:
										<>
											<i className={item.className} onClick={(e) => { handleClick(item.onClick) }} />
										</>
									}
								</div>
								</StyledTooltip>
							</li>);
					})}
					<li className="navbar-nav__nav_items">
						<div style={{ marginTop: "-10px" }}>
							<span className="left__profile__border"></span>
							<a className="dropdown-toggle text-nowrap" style={{ cursor: "pointer", display: 'flex' }}  onClick={(e) => { setLsmview(); setNavBarDetails({ showMenu: !navBarState?.showMenu, menuTargetRef: e.currentTarget }) }}>
								<StyledTooltip title="Profile" className={"sk_smart_Profile"}>
									{
										User?.profileImageURL ? 
											<img className="user-avatar rounded-circle mr-2" ref={showCustomerPopupRef} src={userImageURL} alt="User Avatar" />
											:
											<div className="user-avatar rounded-circle mr-2 navbar_profile__name_initials" ref={showCustomerPopupRef} style={{ color: 'white', backgroundColor: (User?.colorCode || 'var(--smart-konversations-label-wrap-color)')}}>
												{getUserNameInitials()}
											</div>
									}
								</StyledTooltip>
								<i className="nav__profile__dropdownIcon"></i>
							</a>
							{navBarState?.showMenu &&
								<ClickAwayListener onClickAway={() => {
									setNavBarDetails({ showMenu: false });

								}}>
									<Popper placement="bottom" open={navBarState?.showMenu} anchorEl={navBarState?.menuTargetRef} className="menu_popover_wrap">
										<div className="dropdown-menu dropdown-menu-small" style={{ width: '230px', right: '5px' }}>
											<a className="dropdown-item" style={{ padding: '0 0.875rem' }}>
												<p style={{ color: 'black', fontWeight: '500', width: "100%", textOverflow: "ellipsis", overflow: "hidden" }}>{userName}</p>
												{window.localStorage.getItem("lsmViewType") === 'LSM_VIEW' || window.localStorage.getItem("lsmViewType") === 'CSM_VIEW' ?
													<SkSmartMultiSelectDropDown showArrowIcon={true} menuPortalTarget={document.body} menuPlacement="auto" key={"roleToggle"} name='roleToggle' value={navBarState?.roleToggle?.value || ''} className={`${'roleToggle' + 'Options'}`} isSearchable={true} styles={singleSelect} defaultValue={navBarState?.roleToggle?.value || '- Select -'} placeholder={navBarState?.roleToggle?.label || '- Select -'} options={roleOptions} onChange={(e) => {
														// onChangeToggle(e)
													}} />
													: <p style={{ overflow: "hidden", width: "100%", textOverflow: "ellipsis", color: 'grey', fontWeight: '400' }}>{selectedRole?.label}</p>}
											</a>
											<div className="dropdown-divider"></div>
											<span className="dropdown-item" href="#" role="button">
											<SkSmartMultiSelectDropDown showArrowIcon={true} menuPortalTarget={document.body} isSearchable={true} value={selectedRole} options={navBarState?.platformUserRole} onChange={(e) => {
												onRoleChange(e);
											}} />
											</span>
											<div className="dropdown-divider"></div>
											{ProfileOptions().filter(prop => prop.isEnable).map(item => {
												return (
													<>
														<a className="dropdown-item" onClick={(e) =>
															handleProfileIconClick(item.onClick)
														}>
															<span>
																<div className={item.className}></div>
															</span>
															{item.label}
														</a>
														<div className="dropdown-divider"></div></>)
											})}
											{access?.profile?.edit() && <><Link to="/dashboard/edit-profile" style={{ textDecoration: 'none' }}>
												<span className="dropdown-item" href="#" role="button" onClick={(e) => {
													setNavBarDetails({ showMenu: false });

												}}>
													<img src={EditProfile} alt="" style={{ paddingRight: 10 }} />
													Edit Profile
												</span></Link>
												<div className="dropdown-divider"></div></>}
											{true && <><Link to="/dashboard/manage-notification" style={{ textDecoration: 'none' }}>

												<span className="dropdown-item" style={{ display: "flex" }} href="#" role="button" onClick={(e) => {
													setNavBarDetails({ showMenu: false });
												}}>
													<div className="email-notification-setting--icon"></div>
													Notification Settings
												</span></Link>
												<div className="dropdown-divider"></div></>}
											<a className="dropdown-item" onClick={() => { onUserLogOut(); }} href="#">
												<img src={Logout} alt="" style={{ paddingRight: 10 }} />
												Logout
											</a>
										</div>
									</Popper>
								</ClickAwayListener>}
						</div>
					</li>
				</ul>
			</nav>
			<PageVisibility onChange={onVisibilityChange} />
		</div>
		</Suspense>
		</>
	);
}
export default memo(SkNavBar)
const StyledTooltip = styled(({ className, ...props }) => (
	<Tooltip {...props} classes={{ popper: className }} />
))

	(() => ({
		[`& .${tooltipClasses.tooltip}`]: {
			backgroundColor: 'var(--smart-insights-and-konversations-intelligence-heading-color)',
			color: "#FFFFFF",
			top: "7px ",
			height: "33px !important",
			opacity: "0.65 !important",
			position: "absolute",
			display: "flex",
			alignItems: "center !important",
			marginTop: "30px !important"
		}
	}));


