import React from 'react';
import { useDispatch } from 'react-redux';
import AutoUiLabel from '../SkSmartUtil/UiLabel/AutoUiLabel';
import { setSmartKState } from '../store/reducers/skKonversationReducer';

const TouchpointItem = ({ name, length, callingFrom, zoomMeetingId, hostId, setActiveTab, isLink, commentsCount, activeTab }) => {
	const dispatch = useDispatch();

	return (
		<div className={`${callingFrom==="touchpointIntelligence"?"w-100":" "} table-icon-td`}
			style={{  height: "100%", display: "flex" }}
		>
			<div className="leftAlign " style={{ width: callingFrom==="touchpointIntelligence"?"100%":"calc(100% - 40px)" }}>
				<div
					style={{
						width: "100%",
						height: "100%",
						display: "flex",
						alignItems: "center",
						justifyContent: "flex-start",
						paddingRight: "10px"
					}}
				>
					{!isLink ?
						<AutoUiLabel placement="top" tooltipClass="HWSelectMultiSelectTooltip" width={"95%"} label={name} /> :
						<a style={{ cursor: "pointer", width: "100%" }} onClick={() => { dispatch(setSmartKState({ hostId: hostId, meetingId: zoomMeetingId, callingFrom: activeTab, showComment:false })) }} /*to="/dashboard/touchpoint-intelligence" state={{ hostId: hostId, meetingId: zoomMeetingId, callingFrom: activeTab }}*/ className="text-left skLink smart__konversations__call__label">
							<AutoUiLabel className="" placement="top" tooltipClass="HWSelectMultiSelectTooltip" width={"95%"} label={name} /></a>}
				</div>
			</div>
			{callingFrom === "smartKonversations" ?
				<>
				{/* <div className='table-icon-td__vl'></div> */}
					{(commentsCount <= 0) ?
						<div className='smart__konversations__comment__container'
							style={{ width: "40px" }}
						>
							<a style={{ cursor: "pointer" }} onClick={() => { dispatch(setSmartKState({ hostId: hostId, meetingId: zoomMeetingId, callingFrom: activeTab, fromSmartKonversations: true, showComment: true })) }}/*  to={"/dashboard/touchpoint-intelligence"} state={{ hostId: hostId, meetingId: zoomMeetingId, callingFrom: activeTab, fromSmartKonversations:true }} */ className='smart__konversations__comment__container-icon'>
							</a>
						</div> :
						<div className='smart__konversations__comment__count__container'
							style={{ width: "40px" }}
						>
							<a style={{ cursor: "pointer" }} onClick={() => { dispatch(setSmartKState({ hostId: hostId, meetingId: zoomMeetingId, callingFrom: activeTab, fromSmartKonversations: true, showComment: true })) }} /* to={"/dashboard/touchpoint-intelligence"} state={{ hostId: hostId, meetingId: zoomMeetingId, callingFrom: activeTab, fromSmartKonversations:true }} */ className='smart__konversations__comment__count__container-icon'>
								<div className='smart__konversations__comment__count__text'>{commentsCount}</div>
							</a>
						</div>} </>
				:
				<></>

			}
		</div>
	);
}

export default TouchpointItem;
