import { useEffect, useState } from 'react';

export const useEffectOnce = (effect) => {
  const [needToCall, setNeedToCall] = useState(false);
  useEffect(() => {
    if (needToCall) {
      const cleanup = effect();
      return cleanup; // Ensure cleanup is returned for unmounting
    } else {
      setNeedToCall(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [needToCall]);
};
