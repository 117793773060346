
import axios from 'axios';
// import portFolioService, { defaultFilterData } from '../Components/HWPortfolioFilter/PortFolioService';
import moment from 'moment-timezone';
import smartFilterTokenService, { defaultSmartFilterData } from '../SmartFilterTab/SmartFilterToken';
import HWUser from './HWUser';

const hwAxios = axios.create();

const isEmpty = (obj) => {
  for(let key in obj) {
      if(obj.hasOwnProperty(key))
          return false;
  }
  return true;
}

export const getCurrentFilterDataBase64Enncoded = (headers) => {
  let data = {
		"userId": HWUser.userId(),
		"orgId": HWUser.orgId(),
		"customerSuccessPerspective": {
				"perspectiveFilter": (HWUser.iscsmUser() || HWUser.iscslUser())?"MY_PORTFOLIO":"ORG_ACCOUNTS",
				"accountIds": [
						"ALL_ACCOUNTS"
				],
				"productIds": [
						"ALL_PRODUCTS"
				],
				"csmUserId": null,
				"businessUnit": null,
				"accountSegmentId": null
		}
};
  if(data !== undefined && data && !isEmpty(data)){
    let dataItem = data.userId ? data : {
			"userId": HWUser.userId(),
			"orgId": HWUser.orgId(),
			"customerSuccessPerspective": {
					"perspectiveFilter": (HWUser.iscsmUser() || HWUser.iscslUser())?"MY_PORTFOLIO":"ORG_ACCOUNTS",
					"accountIds": [
							"ALL_ACCOUNTS"
					],
					"productIds": [
							"ALL_PRODUCTS"
					],
					"csmUserId": null,
					"businessUnit": null,
					"accountSegmentId": null
			}
	};
    try {
      const timeZone = moment.tz.guess();
      dataItem["browserTimezone"] = timeZone;
    } catch {}
    return window.btoa(JSON.stringify(dataItem));
  }else{
    return null
  }
}
export const getCurrentSmartFilterDataBase64Enncoded= (headers) => {
  // const isOnlySelctedFilters = (headers["addOnlySelectedFilter"] === true);
  if(!smartFilterTokenService.token){
    smartFilterTokenService.token = defaultSmartFilterData();
  }
  
  // Don't add filter token, if its only for selected things.
  // if (isOnlySelctedFilters && !portFolioService.isAnythingSelected()) { return null; }

  let data = smartFilterTokenService.token;
  if(data !== undefined && data && !isEmpty(data)){
    // let dataItem = data.userId ? data : defaultSmartFilterData();
    try {
      const timeZone = moment.tz.guess();
      data["browserTimezone"] = timeZone;
    } catch {}
		let str = JSON.stringify(data)
		let  base64 = btoa(str.replace(/[\u00A0-\u2666]/g, function(c) {
			return '&#' + c.charCodeAt(0) + ';';
		}));
    return base64;
  }else{
    return null
  }
}

export const getCurrentSmartDefaultFilterDataBase64Enncoded= (headers, path) => {
  // const isOnlySelctedFilters = (headers["addOnlySelectedFilter"] === true);

    // smartFilterTokenService.token = defaultSmartFilterData(path);

  
  // Don't add filter token, if its only for selected things.
  // if (isOnlySelctedFilters && !portFolioService.isAnythingSelected()) { return null; }
 
  let data = defaultSmartFilterData(path)
  if(data !== undefined && data && !isEmpty(data)){
    // let dataItem = data.userId ? data : defaultSmartFilterData();
    try {
      const timeZone = moment.tz.guess();
      data["browserTimezone"] = timeZone;
    } catch {}
		let str = JSON.stringify(data)
		let  base64 = btoa(str.replace(/[\u00A0-\u2666]/g, function(c) {
			return '&#' + c.charCodeAt(0) + ';';
		}));
    return base64;
  }else{
    return null
  }
}

hwAxios.interceptors.request.use((config) => {

    if (config.headers && (config.headers["addFilter"] === "true" || config.headers["addOnlySelectedFilter"] === "true")) {
		       switch(config.method) {
           case "get":  config.params = {...(config.params || {}), 
           filterToken: getCurrentFilterDataBase64Enncoded(config.headers),
            smartFilterToken: getCurrentSmartFilterDataBase64Enncoded(config.headers) 
				 }; break; 
				 case "post": config.data   = {...(config.data || {}), filterToken: getCurrentFilterDataBase64Enncoded(config.headers),  smartFilterToken: getCurrentSmartFilterDataBase64Enncoded(config.headers) }; break; 
					 case "put": config.data   = {...(config.data || {}), filterToken: getCurrentFilterDataBase64Enncoded(config.headers),  smartFilterToken: getCurrentSmartFilterDataBase64Enncoded(config.headers) }; break; 
           default : break;
       }
      //  config.headers["addFilter"] = null;
      if (config.headers["addFilter"])             { delete config.headers.addFilter; }
      if (config.headers["addOnlySelectedFilter"]) { delete config.headers.addOnlySelectedFilter; }
		}
	

	if (config.headers && (config.headers["defaultFilter"] === "true")) {

		switch (config.method) {
			case "get": config.params = {
				...(config.params || {}),
				filterToken: getCurrentFilterDataBase64Enncoded(config.headers),
				smartFilterToken: getCurrentSmartDefaultFilterDataBase64Enncoded(config.headers)
			}; break;

			default: break;
		}
		//  config.headers["addFilter"] = null;
		if (config.headers["defaultFilter"]) { delete config.headers.defaultFilter; }
	}
    return config;
}, (error) => { return Promise.reject(error); });

hwAxios.interceptors.request.use()

export default hwAxios;