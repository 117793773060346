import React from "react";
import BlockUi from "react-block-ui";
import hwAxios from "../../HWLibrary/HWAxios";
import HWUser from "../../HWLibrary/HWUser";

class AcademyRedirect extends React.Component {

    componentDidMount() {
        try {
            if (HWUser.academyHelpStatus() !== "Visible" ) {
                this.redirectToOrigin(); 
                return;
						}
					let searchParams = new URLSearchParams(window.location.search);
					let path = searchParams?.get("path")
					this.fecthAcademyToken(path).then(response => {
							if (response?.data?.location) {
                  window.location = response.data.location;
                } else { this.redirectToOrigin(); } 
              }).catch(error => {
								//  console.log(error); 
								 this.redirectToOrigin(); })
        } catch (error) { 
					// console.log(error);  
					this.redirectToOrigin() }
    }

    fecthAcademyToken = (path)=>{
        let url = window.resolveRegion(process.env.REACT_APP_SMART_DASHBOARDS_BASE_API_URL) + `/academy/auth`
        let aConfig = { headers: { "Authorization": HWUser.accessToken(), "Content-Type": "application/json" } };
        let data    = { "userId" : HWUser.userId(), "appId": HWUser.appId(), platformName: HWUser.platformName(), path:path }
        return hwAxios.post(url, data, aConfig)
       }
    
    redirectToOrigin = () => {
        window.location = window.origin;
    }

    render() {
        return (
            <BlockUi style={{ width: "100%", height: "100%" }} blocking={true} placeholder="Connecting to academy..." />
        );
    }
}

export default AcademyRedirect;
