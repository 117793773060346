import { applyMiddleware, compose } from 'redux';
import { createLogger } from 'redux-logger';
import thunk from 'redux-thunk';
import HWUser from '../../HWLibrary/HWUser';

const loggerMiddleware = createLogger({
    duration: true,
    collapsed: true,
    colors: {
        title: () => '#139bfe',
        error: () => '#ff0005',
    },
});

// const logger = store => next => action => {
//   console.group(action.type)
//   console.info('dispatching', action)
//   let result = next(action)
//   console.log('next state', store.getState())
//   console.groupEnd()
//   return result
// }

// const round = number => Math.round(number * 100) / 100

// const monitorReducerEnhancer =
//   createStore => (reducer, initialState, enhancer) => {
//     const monitoredReducer = (state, action) => {
//       const start = performance.now()
//       const newState = reducer(state, action)
//       const end = performance.now()
//       const diff = round(end - start)

// 			console.log('reducer process time:', diff,HWUser )
			
// 			if (HWUser.appId()) {
// 				return newState
// 			}

   
//     }

//     return createStore(monitoredReducer, initialState, enhancer)
//   }



const devtools = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__;
const composeEnchancers = devtools || compose;

// const middlewareEnhancer = applyMiddleware(loggerMiddleware, thunk);
// const composedEnhancers = compose(middlewareEnhancer, monitorReducerEnhancer)


const enhancedStore = composeEnchancers(applyMiddleware(/* loggerMiddleware,  */thunk));
// const enhancedStore =	composeEnchancers(applyMiddleware(logger, thunk));

export { enhancedStore };
