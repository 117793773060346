export const styleReactMultiSelect = {
	control: (provided, state) => ({
		...provided,
		color: '#000000',
		fontSize: '0.904rem',
		fontFamily: 'var(--sk-font-urbanist-family)',
		width: '100% !important',
		border: '1px solid var(--sk-border-color)',
		borderRadius: '4px',
		minHeight:"42px",
		// This line disable the blue border
		boxShadow: state.isFocused ? '1px solid var(--sk-border-color)' : '1px solid var(--sk-border-color)',
		'&:hover': {
			border: state.isFocused ? '1px solid var(--sk-border-color)' : '1px solid var(--sk-border-color)'
		},

	}),
	container: (provided, state) => ({
		...provided,
		border: 'none',
		borderRadius: '4px',
		width: '100% !important',
		fontSize: '0.904rem',
		fontFamily: 'var(--sk-font-urbanist-family)',
	}),
	indicatorSeparator: (provided, state) => ({
		...provided,
		width: '0px',

	}),
	menuPortal: base => ({ ...base, zIndex: '9999999' }),
	reactValue: (provided, state) => ({
		...provided,
		width: '68%',
		color: '#757575',
		lineHeight: '34px',
		fontWeight: '400',
		fontFamily: 'var(--sk-font-urbanist-family)',
	}),
	menu: (provided, state) => ({
		...provided, borderRadius: '4px', marginTop: '1px',
		width: '100% !important',
		zIndex: '9999999'
	}),
	indicatorsContainer: (provided, state) => ({
		...provided,
		borderRadius: '0px',
	}),
	multiValueLabel: (provided, state) => ({
		...provided,
		whiteSpace: 'normal',
		lineHeight: '31px'
	}),
	multiValueRemove: (base, state) => ({
		...base,
		color: '#000',
		backgroundColor: '#fad192',
		borderRadius: 0,
		'&:hover': {
			backgroundColor: '#fad192',
			color: '#000'
		}
	}),

	indicatorContainer: (provided, state) => ({
		...provided,
		padding: '0px',
	}),
	placeholder: (provided, state) => ({
		...provided,
		color: '#757575',
		width: '100%',
		fontWeight: '400',
		fontFamily: 'var(--sk-font-urbanist-family)',
	}),

	valueContainer: (provided, state) => ({
		...provided,
		cursor:state.isDisabled?"auto":"pointer",
		position: 'none',
		maxWidth: '68%',
		borderRadius: '0px',
	}),

	option: (styles, { data, isDisabled, isFocused, isSelected }) => {
		const color = 'var(--sk-menu-bg-color)';
		return {
			...styles,
			backgroundColor: isDisabled
				? null
				: isSelected
					? color
					: isFocused
						? color
						: null,
			':active': {
				...styles[':active'],
				backgroundColor: color,
			},
			color: 'black',
			borderBottom: '1px solid var(--sk-border-color)',
			cursor: 'pointer',
			width: '100% !important',
			overflow: 'hidden',
			borderRadius: '0px',
			fontWeight: '400'
		};
	},
	menuList: (provided, state) => ({
		...provided,
		paddingBottom: '0px',
		paddingTop: '0px',
		borderRadius: '0px',
		boxShadow: '0px 1px 5px #ddd',
		overflowX: 'hidden',
		width: '100% !important',
		fontWeight: '400',
		fontFamily: 'var(--sk-font-urbanist-family)',
	}),
};