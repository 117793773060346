import access from '../HWLibrary/AccessControl';
import HWUser from "../HWLibrary/HWUser";
import { AsanaService } from '../SkSmartUtil/AsanaService';

const ProfileOptions = () => {
	const shouldShowAsanaAuthOption = () => {
		return AsanaService.getCredentialsStatus() === AsanaService.credentialsLoadStatusEnum.notAvailable;
	}
	const shouldShowJiraAuthOption = () => {
		// return JiraService.getCredentialsStatus() === JiraService.credentialsLoadStatusEnum.notAvailable;
	}

	return [
		{
			className: "kommunity_header_icon",
			isEnable: HWUser.isAsanaIntegrated() && shouldShowAsanaAuthOption(),
			label: "Authenticate ASANA",
			onClick: "onConnectToAsana",
		},
		{
			className: "notification_header_icon",
			isEnable: HWUser.isJiraTaskIntegrated() && shouldShowJiraAuthOption(),
			onClick: "onConnectToJira",
			label: "Authenticate JIRA",
		},
		{
			className: "academy_header_icon",
			isEnable: !HWUser.isGsuiteIntegrated() && HWUser.isGsuiteIntegratedForApp() && access?.settings?.integration?.googleWorkspace?.view(),
			onClick: "onConnectToGSuite",
			label: "Authenticate GSUITE",
		},
		{
			className: "kommunity_header_icon",
			isEnable: !HWUser.isOutlookIntegrated() && HWUser.isOutlookIntegratedForApp() && access?.settings?.integration?.microsoft365?.view(),
			label: "Authenticate OUTLOOK",
			onClick: "onConnectToOutlook",
		},
		{
			className: "kommunity_header_icon",
			isEnable: HWUser.isSKZohoMailIntegrated() && !HWUser.isZohoEmailIntegratedForUser(),
			label: "Authenticate ZOHO",
			onClick: "onConnectToZoho",
		},
		{
			className: "kommunity_header_icon",
			isEnable: HWUser.isZoomConfigure() && !HWUser.isZoomIntegratedForThisUser(),
			label: "Authenticate ZOOM",
			onClick: "onConnectToZoom",
		},
	]
}

export default ProfileOptions;
