import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { getSmartCommentStates } from '../../store/reducers/skCommentsReducer';
import SmartCommentMention from './SmartCommentMention';


const SmartReplyField = (props) => {
  const getSmartCommentState = useSelector(getSmartCommentStates);
	const {smartComment} = getSmartCommentState;
	const [currentComment, setCurrentComment] = useState('');

	return (
		<div>
			<div className='smart_reply_editor'>
			<SmartCommentMention feature={props.feature} isScroll={props?.isScroll} setShowReplyEditor={props.setShowReplyEditor} parentCommentId={props?.parentCommentId} smartComment={smartComment} featureId={props?.featureId} action="create" setIsInsidePopperOn={props.setIsInsidePopperOn} setCurrentComment={setCurrentComment} onCommentCallBack = {props?.onCommentCallBack}/>
			</div>
		</div>
	);
}

export default SmartReplyField;
