import axios from 'axios';
import HWLocalStorage from '../HWLibrary/HWLocalStorage'
import HWUser from '../HWLibrary/HWUser';

class FreshdeskTicketService {
    freshdeskAPIKey = null;
    config = () => {
        let authKey = this.freshdeskAPIKey; // (credentials && credentials.freshdeskAPIKey) || "";
        let basicAuthValue = "Basic " + btoa(authKey + ":x");
        return { headers: { "Authorization": basicAuthValue } }
    };

    adminConfig = () => {
        let credentials = HWUser.freshdeskCredentials(HWUser.appId());
        let authKey = (credentials && credentials.freshdeskAPIKey) || "";
        let basicAuthValue = "Basic " + btoa(authKey + ":x");
        return { headers: { "Authorization": basicAuthValue } }
    }

    baseURL = () => {
        return HWUser.isDemoAccount()
            ? window.resolveRegion(process.env.REACT_APP_DASHBOARDS_BASE_API_URL) + "/demo-data/demo-tickets"
            : "https://" + HWUser.freshdeskDomainName(HWUser.appId()) + ".freshdesk.com";
    }

    onCredentialsUpdateCallback = null;
    onCredentialsUpdate = () => { if (this.onCredentialsUpdateCallback) { this.onCredentialsUpdateCallback(this.credentialsLoadStatus); } };
    constructor(onCredentialsUpdateCallback, delayBySeconds) {
        if (delayBySeconds && delayBySeconds > 0) {
            setTimeout(() => { this.loadCredentials(); }, delayBySeconds * 1000);
        } else { this.loadCredentials(); }
        this.onCredentialsUpdateCallback = onCredentialsUpdateCallback;
    }

    // fetchTickets = () => {
    //     let aConfig = { ...this.config() };
    //     return axios.get("https://smartkarrot.freshdesk.com/api/v2/tickets", aConfig);
    // }
    static credentialsLoadStatusEnum = { loaded: "LOADED", notStarted: "NOT_STARTED", loading: "LOADING", notAvailable: "NOT_AVAILABLE", serverError: "SERVER_ERROR" }
    credentialsLoadStatus = FreshdeskTicketService.credentialsLoadStatusEnum.notStarted;

    loadFreshdeskCredentialsFromLocal() {
        try {
            let freshdeskCredentialsId = "sk-freshdesk-credentials" + HWUser.userId() + HWUser.appId();
            const cachedCredentials = JSON.parse(HWLocalStorage.load(freshdeskCredentialsId));
            return cachedCredentials;
        } catch (error) { }
    }

    updateKeyToLocalCache(freshdeskAPIKey) {
        try {
            let asanaCredentialsId = "sk-freshdesk-credentials" + HWUser.userId() + HWUser.appId();
            const tokenDataString = JSON.stringify({ "freshdeskAPIKey": freshdeskAPIKey });
            HWLocalStorage.save(tokenDataString, asanaCredentialsId);
        } catch (error) { }
    }

    static clearAllFreshdeskCache() {
        HWLocalStorage.clearWithPrefix("sk-freshdesk");
        HWLocalStorage.clearWithPrefix("sk-ticket");
    }

    loadCredentials() {
        if (HWUser.isDemoAccount()) {
            this.credentialsLoadStatus = FreshdeskTicketService.credentialsLoadStatusEnum.loaded;
            this.onCredentialsUpdate && this.onCredentialsUpdate();
            return;
        }
        const cachedAPIKeyData = this.loadFreshdeskCredentialsFromLocal();
        if (cachedAPIKeyData) {
            this.credentialsLoadStatus = FreshdeskTicketService.credentialsLoadStatusEnum.loaded;
            this.freshdeskAPIKey = cachedAPIKeyData.freshdeskAPIKey;
            this.onCredentialsUpdate();
        }
        this.credentialsLoadStatus = FreshdeskTicketService.credentialsLoadStatusEnum.loading;

        return FreshdeskTicketService.fetchFreshdeskredentials().then(response => {
            if (response.data.statusCode === 200) {
                this.freshdeskAPIKey = response.data.freshdeskPersonalAPIKey;
                this.updateKeyToLocalCache(response.data.freshdeskPersonalAPIKey);
                this.credentialsLoadStatus = FreshdeskTicketService.credentialsLoadStatusEnum.loaded;
            } else { this.credentialsLoadStatus = FreshdeskTicketService.credentialsLoadStatusEnum.notAvailable; }
            this.onCredentialsUpdate()
        }).catch(error => {
            this.credentialsLoadStatus = FreshdeskTicketService.credentialsLoadStatusEnum.serverError;
            this.onCredentialsUpdate()
            // console.log(error); 
        });
    }

   

  

    ticketsFetched = []


    updateTicketCache(updatedTicketData) {
        let ticketDataLocalCacheKey = "sk-ticket-data-" + HWUser.appId();
        HWLocalStorage.save(updatedTicketData, ticketDataLocalCacheKey);
        return updatedTicketData;
    }

    isContactsServerCallInProgress = false;
    freshdeskContacts = []

    fetchAllFreshdeskContacts(incrementalUpdate, pageNumber) {
        let service = this;
        return new Promise((resolve, reject) => {
            let aConfig = { ...service.config() };   // Shallow copy.
            const pageCount = pageNumber || 1;
            const pageLimit = 100
            aConfig.params = { "per_page": pageLimit , page: pageCount }
            axios.get(service.baseURL() + "/api/v2/contacts", aConfig).then(response => {
                incrementalUpdate(response.data);
                if (response.headers["link"] || (response.data && response.data.length === pageLimit)) { // Has next page.
                    // console.log("About to fetch contacts next page : " + (pageCount + 1));
                    service.fetchAllFreshdeskContacts(incrementalUpdate, pageCount + 1);
                }
                resolve(null);
            }).catch(error => { reject(error); })
        });
    }

  

    fetchAgents() {
        let aConfig = { ...this.adminConfig() };   // Shallow copy.
        return axios.get(this.baseURL() + "/api/v2/agents", aConfig);
    }

  
    fetchFreshdeskGroups(cacheExists, onUpdate) {
        let ticketDataLocalCacheKey = "sk-ticket-data-groups-" + HWUser.appId();

        // Check for cached data if no data on the screen.
        if (!cacheExists) {
            const cachedData = HWLocalStorage.load(ticketDataLocalCacheKey);
            if (cachedData) { cacheExists = true; onUpdate(cachedData); }
        }

        const incrementalUpdate = (responseData) => {
            if (!cacheExists) { onUpdate(responseData.data); }
            HWLocalStorage.save(responseData.data, ticketDataLocalCacheKey);
            onUpdate(responseData.data);
        }

        let aConfig = { ...this.adminConfig() };   // Shallow copy.
        return axios.get(this.baseURL() + "/api/v2/groups", aConfig).then(incrementalUpdate);
    }

    isCompaniesServerCallInProgress = false;
    freshdeskComapines = []
    fetchFreshdeskCompanies(cacheExists, onUpdate) {
        if (this.isCompaniesServerCallInProgress === true) { 
					// console.log("FETCHING SERVICE: ALREADY IN PROGRESS"); 
					return; }
        this.isCompaniesServerCallInProgress = true;
        this.freshdeskComapines = []
        const incrementalUpdate = (responseData, isLastPage) => {
            this.freshdeskComapines = this.freshdeskComapines.concat(responseData);
            if (isLastPage) {
                onUpdate(this.freshdeskComapines);
                this.isCompaniesServerCallInProgress = false;
            }
        }
        this.fetchAllFreshdeskCompanies(incrementalUpdate)
    }

    fetchAllFreshdeskCompanies(incrementalUpdate, pageNumber) {
        let service = this;
        return new Promise((resolve, reject) => {
            let aConfig = { ...this.adminConfig() };   // Shallow copy.
            const pageLimit = 100
            const pageCount = pageNumber || 1
            aConfig.params = { "per_page": pageLimit, page: pageCount }
            axios.get(service.baseURL() + "/api/v2/companies", aConfig).then(response => {
                const isLastPage = !(response.headers["link"] || (response.data && response.data.length === pageLimit));
                if (!isLastPage) { // Has next page.
                    service.fetchAllFreshdeskCompanies(incrementalUpdate, pageCount + 1);
                }
                incrementalUpdate(response.data, isLastPage);
                resolve(null);
            }).catch(error => { reject(error); incrementalUpdate([], true); });
        });
    }

 

   


 
 

   



}

export default FreshdeskTicketService;