import { cloneDeep } from "lodash";
import { store } from "..";
import HWUser from "../../HWLibrary/HWUser";
import skApiService from "../../SkApiService";
import { defaultSmartFilterData } from "../../SmartFilterTab/SmartFilterToken";
import { apiCall } from "../api";
import { skGlobalActions } from "../constants/actionType";
import uuid from "react-uuid";
import HWLocalStorage from "../../HWLibrary/HWLocalStorage";
import cache from "../../SkSmartUtil/InMemoryCache";

export const getGlobalStates = () => {
	return store.getState()?.globalReducer;
}

export const setGlobalStates = (obj) => async dispatch => {
	dispatch({
		type: skGlobalActions?.SET_GLOBAL_STATE, payload: obj
	})
}

export const sendLogsToSever = (data) => async dispatch => {
	let request = { appId: HWUser.appId(), userId: HWUser.userId(), ...data };
	try {
		const response = await apiCall({
			url: window.resolveRegion(process.env.REACT_APP_PLATFORM_BASE_API_URL_WEB_ADMIN) + "/logs/async",
			method: "POST",
			payload: request,
		});
		dispatch({
			type: skGlobalActions?.ADD_LOG,
			payload: { successMsg: "Saved" },
		});

	} catch (error) {
		dispatch({
			type: skGlobalActions?.SET_IS_ERROR,
			payload: { isError: true, errorMessage: error?.response?.data?.message, }
		})
	}
}


export const getNotifcationCount = (data) => async dispatch => {
	let request = {}
	request.params = { appId: HWUser.appId(), userId: HWUser.userId(), orgId: HWUser.orgId() };
	try {
		const response = await apiCall({
			url: window.resolveRegion(process.env.REACT_APP_SMART_DASHBOARDS_BASE_URL) + "/premium/count",
			method: "GET",
			payload: request,
		});
		let data = response?.data?.data;
		let kommunityCount = data.find(item => item.feature === 'Kommunity');
		let konversationCount = data.find(item => item.feature === 'Konversation');
		dispatch({
			type: skGlobalActions?.GET_COUNT,
			payload: { kommunityCount: kommunityCount.count, konversationCount: konversationCount.count},
		});

	} catch (error) {
		dispatch({
			type: skGlobalActions?.SET_IS_ERROR,
			payload: { isError: true, errorMessage: error?.response?.data?.message, }
		})
	}
}

export const getAccountListStates = () => {
	return store.getState()?.globalReducer;
}

export const setAccountListStates = (obj) => async dispatch => {
	dispatch({
		type: skGlobalActions?.SET_ACCOUNT_STATE, payload: obj
	})
}

export const fetchAccountList = (data, addFilter) => async dispatch => {
	let request = {};
	dispatch(setAccountListStates({ isLoading: true }))
	let token = JSON.stringify(defaultSmartFilterData())
	token = btoa(token.replace(/[\u00A0-\u2666]/g, function (c) {
		return '&#' + c.charCodeAt(0) + ';';
	}));
	request.params = {
		orgId: HWUser.orgId(),
		appId: HWUser.appId(),
		userId: HWUser.userId(),
		...data,
		smartFilterToken: token
	};
	try {
		if(!data){
			skApiService.serachAccountsOptionsForGlobalFilter().then(response => {
				let accountLists = response?.data?.accounts?.map(item => { return ({ value: item.id, valid: true, label: item.name, productIds: item.productIds, accountType: item.customerAccountType }) });
				accountLists = accountLists?.sort((a, b) => a?.label?.localeCompare(b?.label));
				dispatch(setAccountListStates({ accountList: accountLists }))
				dispatch(setAccountListStates({ isLoading: false, isSuccess: true }))
			})
		}else{
		const response = await apiCall({
				url: window.resolveRegion(process.env.REACT_APP_SMART_DASHBOARDS_BASE_API_URL) + "/account/search",
				method: "GET",
				payload: request,

			});
			let accountLists = response.data.accounts.map(item => { return ({ value: item.id, valid: true, label: item.name, productIds: item.productIds, accountType: item.customerAccountType }) });
			accountLists = accountLists.sort((a, b) => a?.label?.localeCompare(b?.label));
			dispatch(setAccountListStates({ accountList: accountLists }))
			dispatch(setAccountListStates({ isLoading: false, isSuccess: true }))
		}

	
	} catch (error) {
		dispatch(setAccountListStates({ isError: true, errorMessage: error?.response?.data?.message, isLoading: false }))
		setTimeout(() => {
			dispatch(setAccountListStates({ errorMessage: '', isError: false }))
		}, 2000)
	}
}



export const handleClickOutsideOfGlobalSearch = (showSearch) => async dispatch => {
	// hide or show search by passing true or false
	if (showSearch) {
		dispatch(setAccountListStates({ showGlobalSKSearch: showSearch }));
	} else {
		// default click away handling..
		// if there are options filtered after typing on search click away shouldnt do anything
		if (getAccountListStates().globalSearchOptionsOnSearch?.length > 0) {
			return
		}
		// if there are either account selected or value typed in search box  click away shouldnt do anything
		if (getAccountListStates().globalSkSearchedText !== undefined || getAccountListStates().globalSearchSelectedAccounts !== null) {
			// dont remove this... else the filtered value will be retained....
			dispatch(setAccountListStates({ globalSearchOptionsOnSearch: [] }));
			return;
		} else {
			// when none of this works the search will close....
			dispatch(setAccountListStates({ showGlobalSKSearch: !getAccountListStates()?.showGlobalSKSearch }));
		}
	}
};

export const fetchAccountSegmentList = (data) => async dispatch => {
	let request = {};
	request.params = { orgId: HWUser.orgId(), appId: HWUser.appId(), userId: HWUser.userId(), ...data };
	dispatch(setAccountListStates({ isLoading: true }))
	try {
		const response = await apiCall({
			url: window.resolveRegion(process.env.REACT_APP_SMART_DASHBOARDS_BASE_URL) + "/smart-filter/account-segments",
			method: 'GET',
			payload: request,
		});
		if (response?.status === 200) {
			let list = response?.data?.accountSegments.map(item => { return ({ id: item?.id, value: item?.name, label: item?.name }) })
			list = list.sort((a, b) => a?.label?.localeCompare(b?.label));
			dispatch(setAccountListStates({ accountSegmentsList: list }))
			dispatch(setAccountListStates({ isLoading: false, isSuccess: true }))
		}
	} catch (error) {
		dispatch(setAccountListStates({ isError: true, errorMessage: error?.response?.data?.message, isLoading: false }))
		setTimeout(() => {
			dispatch(setAccountListStates({ errorMessage: '', isError: false }))
		}, 2000)
	}
}

export const getkeyOutcomesReferenceData =  ()=> async dispatch =>{
	let request={}
	request.params = {userId:HWUser.userId(),orgId:HWUser.orgId(),appId:HWUser.appId()}
	dispatch(setAccountListStates({isLoadingOutcomeData:true}))
	try{
		const reponse = await apiCall({
			url: window.resolveRegion(process.env.REACT_APP_SMART_DASHBOARDS_BASE_URL) + "/outcome",
			method: 'GET',
			payload: request
		})
		if(reponse){
			let list = reponse.data.keyOutcomesPossibleValues && reponse.data.keyOutcomesPossibleValues.map((i)=>{
				return ({value:i.id,label:i.visibleName,...i})
			})
			list.push({id: "None",label: "None",value: "None",visibleName: "None "});
			dispatch(setAccountListStates({ outcomeStatus : reponse?.data?.outcomeConfig?.status, outcomeVisibleName:reponse?.data?.outcomeConfig?.outcomeVisibleName,keyOutcomesPossibleValues:list,outcomeData:reponse?.data?.data,isLoadingOutcomeData:false}));
			let profileKey = 'platform-currentUser';
			let currentUser = HWLocalStorage.load(profileKey);
			currentUser.outcomeConfig.outcomeVisibleName = reponse?.data?.outcomeConfig?.outcomeVisibleName;
			currentUser.outcomeConfig.status = reponse?.data?.outcomeConfig?.status;
			HWLocalStorage.save(currentUser, profileKey);
		}
	}
	catch (err) {
		return "error"
	}
}
export const addNewOutcomeData = () => async dispatch => {
	let list =  cloneDeep(getAccountListStates().outcomeData);
	list = [{
		id:uuid(),
		outcomeId:"add_outcome",
		index:list.length+1,
		
		// indexNumber:list.length+1,
		saveDataUsingSaveIcon:true
	},...list]
	dispatch(setAccountListStates({outcomeData:list}))
}
export const removeOutcomeData = () => async dispatch => {
	let list =  cloneDeep(getAccountListStates().outcomeData);
	list = list && list.filter((i)=>{
		return (i.outcomeId !== "add_outcome")
	})
	dispatch(setAccountListStates({outcomeData:list}))
}
export const updateOutcome = (data)=> async dispatch=>{
	let request = {userId:HWUser.userId(),appId:HWUser.appId(),orgId:HWUser.orgId(),...data}
	dispatch(setAccountListStates({isLoadingOutcomeData:true}))
	try{
		const response = await apiCall({
			url :window.resolveRegion(process.env.REACT_APP_SMART_DASHBOARDS_BASE_URL) + "/outcome",
			method:"PUT",
			payload:request,
		})
		cache.invalidateForKey(HWUser.userId(), cache.CacheModule.outcomeRefData);
		cache.invalidateForKey(HWUser.userId(), cache.CacheModule.taskCategoryData);
		if(response){
			return "success"
		}
	}
	catch(err){
		dispatch(setAccountListStates({isLoadingOutcomeData:false}))
		return "error"
		
	}
	}
	export const createNewOutcome = (data)=> async dispatch=>{
		let request = {userId:HWUser.userId(),appId:HWUser.appId(),orgId:HWUser.orgId(),...data}
		dispatch(setAccountListStates({isLoadingOutcomeData:true}))
		try{
			const response = await apiCall({
				url :window.resolveRegion(process.env.REACT_APP_SMART_DASHBOARDS_BASE_URL) + "/outcome",
				method:"POST",
				payload:request,
			})
			cache.invalidateForKey(HWUser.userId(), cache.CacheModule.outcomeRefData);
			cache.invalidateForKey(HWUser.userId(), cache.CacheModule.taskCategoryData);
			if(response){
				return "success"
			}
		}
		catch(error){
			dispatch(setAccountListStates({isLoadingOutcomeData:false}))
			return error?.response?.data?.message
			
		}
		}
	export const deleteOutcome = (data)=> async dispatch => {
		let request = {};
		request.params = {userId:HWUser.userId(),appId:HWUser.appId(),orgId:HWUser.orgId(),...data}
		dispatch(setAccountListStates({isLoadingOutcomeData:true}))
		try{
			const response = await apiCall({
				url:window.resolveRegion(process.env.REACT_APP_SMART_DASHBOARDS_BASE_URL) + "/outcome",
				method:"DELETE",
				payload:request
			})
			cache.invalidateForKey(HWUser.userId(), cache.CacheModule.outcomeRefData);
			cache.invalidateForKey(HWUser.userId(), cache.CacheModule.taskCategoryData);
			if(response){
				return "success"
			}
		}
		catch(err){
			return ("error")
		}
}


