import HWUser from "./HWUser.jsx"

class AccessControl {

    // static testToken = 
    // "eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJyZWZyZXNoVG9rZW5FeHBUaW1lIjoiMjAyMy0wNy0yMVQxMzowNjowMC4zMTcyMDhaIiwiaXNzIjoiU21hcnRLYXJyb3QiLCJpc1N1cGVyQWRtaW4iOiJmYWxzZSIsInR5cGUiOiJBdXRob3JpemF0aW9uVG9rZW4iLCJ1c2VySWQiOiJiZGM1NjJlYS00MGQxLTRhOGItODY4MC01NGNhNGI3NGU1NmUiLCJkZXZpY2VJZCI6IjI1MDEwMTU3NTM3MzYxMTIwMDA1MzczNjU5ODIxNTEyMzAtYzY1OTMwYzUtY2QwNi00OGY4LWJhNDMtZTg2NjkyNGYzYTBiIiwidmVyc2lvbiI6InYzIiwiZW52aXJvbm1lbnQiOiJQcm9kdWN0aW9uIiwiYXBwSWQiOiI3Njc5N2FhZi05MmZiLTQwOGEtYjMxMy03NDFiZWM4YzlkMzMiLCJzY29wZSI6IiFwbGF5Ym9vay5kYXNoYm9hcmQ6KiAqLioiLCJza1BsYXRmb3JtIjoiUGhvZW5peCIsImV4cCI6MTY4NzM3NDM2MCwidGltZXN0YW1wIjoiMjAyMy0wNi0yMVQxMzowNjowMC4zMjg3ODhaIn0.o8j-RsX112MybRyso-1KnWvhrXF1G0o1D_M6kcnUU7Y"
    // static testToken =
    // "eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJlbnZpcm9ubWVudCI6IlByb2R1Y3Rpb24iLCJyZWZyZXNoVG9rZW5FeHBUaW1lIjoiMjAyMi0wOS0yMlQwNDo0MzoxNC42MzUyNDRaIiwiYXBwSWQiOiJkYmQ4YjkyYi0yZGEwLTQ2OTgtOGU1NC0yMTg2NTA3ZTg0NDYiLCJzY29wZSI6IiBzdWNjZXNzUGxheTpsaXN0IHN1Y2Nlc3NQbGF5OmNyZWF0ZSBzdWNjZXNzUGxheS5hY3Rpdml0eTpjcmVhdGUgc3VjY2Vzc1BsYXkudGVtcGxhdGU6bGlzdCBzdWNjZXNzUGxheS5tb25pdG9yOmxpc3Qgc3VjY2Vzc1BsYXkubW9uaXRvci5hY3Rpdml0eTpjcmVhdGUgc3VjY2Vzc1BsYXkubW9uaXRvci5hdWRpZW5jZTp1cGRhdGUgc3VjY2Vzc1BsYXkuYWN0aXZpdHk6bWFya0FzSW5jb21wbGV0ZSBzdWNjZXNzUGxheS5hY3Rpdml0eTptYXJrQXNOb3RBcHBsaWNhYmxlIHN1Y2Nlc3NQbGF5SW5zaWdodHM6bGlzdCB0aWNrZXQubGlzdDp2aWV3IiwiaXNzIjoiU21hcnRLYXJyb3QiLCJpc1N1cGVyQWRtaW4iOiJmYWxzZSIsInR5cGUiOiJBdXRob3JpemF0aW9uVG9rZW4iLCJleHAiOjE2NjM3NTY5OTQsInVzZXJJZCI6IjRjM2Q1NmU0LTY1ZTEtNDE3Ny1iMTg0LWM2MTYxZjhjOTM5MCIsImRldmljZUlkIjoiMjUwMTAxNTc1MzczNjEwMzAwMDUzNzM2NTkwMDE0NDAzMC00OWU2MTczNC1kZTVhLTQyMDYtOTgwMi02MmViMTc1MDFiZjgiLCJ2ZXJzaW9uIjoidjMiLCJ0aW1lc3RhbXAiOiIyMDIyLTA5LTIxVDA0OjQzOjE0LjYzNTI4NloifQ.fz2xfXxH5Yrk-7WrJ-e0u_tV9lT1IyRfn3o_1WbWb1A"
    static permissions() {
        let token   = HWUser.accessToken(); 
				// const lexipolOrgId = "ee29dd99-611c-4585-bdb2-5be35350a892";
				// if (HWUser.orgId() === lexipolOrgId) {
				// 	token = "*.*";
				// }
      // let token   = this.testToken; //HWUser.accessToken();
      
				// return ['profile:edit', 'smartKonversation:*', 'kommunity:*','!playbook.summary:*', 'playbook.summary:*', 'playbook.rule:*', 'playbook.template:*', 'tasks:*'] 
        return JSON.parse(atob(token.split(".")[1])).scope.split(" ");
				// return ["tasks:list","tasks:create", "tasks:delete"]
    }

		isPermitted = (featureScopes) => {
			return (function () {
					try {
						 let permissions = AccessControl.permissions();
					//    console.log("Permissions: " + permissions);
						 if (!featureScopes) { return false; }
						 if (!permissions)	{ return true; }
						 let status = featureScopes.some((scope) => permissions.some((featureScope) =>  featureScope === "!"+scope))
						 if(status){return false}
						 if (!permissions || (permissions.indexOf("*.*") !== -1)) { return true; }
						 return featureScopes.some((scope) => permissions.some((featureScope) =>  featureScope === scope));
					} catch { return true; }
			});
	 }

	//  Replace above function with the code in sprint85b branch.
	// isPermitted = (featureScopes) => {
	// 	return (function () {
	// 		try {
	// 			let permissions = AccessControl.permissions();
	// 			if (!featureScopes || !permissions) { return false; }
	// 			let isNegatedScope = featureScopes.some((scope) => permissions.some((featureScope) => featureScope === "!" + scope))
	// 			if (isNegatedScope) { return false; }
	// 			if ((permissions.indexOf("*.*") !== -1)) { return true; }
	// 			return featureScopes.some((scope) => permissions.some((featureScope) => featureScope === scope));
	// 		} catch (error) {
	// 			console.log("Could not parse the token scopes");
	// 			console.log(error);
	// 			return false;
	// 		}
	// 	});
	// }

    
    // touchpoint    = { 
    //     read: this.isPermitted(["touchpoint:read"]), 
    //     create: this.isPermitted(["touchpoint:create"]), 
    //     list: this.isPermitted(["touchpoint:list"]) ,
    //     update: this.isPermitted(["touchpoint:update"]),
    //     search: this.isPermitted(["touchpoint:search"]),
    //     archive: this.isPermitted(["touchpoint:archive"]),
    //     comment: this.isPermitted(["touchpoint:comment"]),
    // }
   

    // csmDashbboard = { 
    //     performanceManagement: { 
    //         view: this.isPermitted(["csmDashboard.*:view","csmDashboard.performanceManagement:view"]) 
    //     },
    //     customerHealthSummary:{ 
    //         view: this.isPermitted(["csmDashboard.*:view","csmDashboard.customerHealthSummary:view"]) 
    //     },
    //     successOrchestration:{ 
    //         view: this.isPermitted(["csmDashboard.*:view","csmDashboard.successOrchestration:view"]) 
    //     },
    //     portfolioAnalysis:{ 
    //         view: this.isPermitted(["csmDashboard.*:view","csmDashboard.portfolioAnalysis:view"]) 
    //     },
    //     accountIntelligence:{
    //         view:this.isPermitted(["account360.accountIntelligence:view"]),
    //         alerts:{
    //             archive:this.isPermitted(["account360.accountIntelligence.alerts:archive"]),
    //         }
    //     },
    //     tasks:{
    //         view: this.isPermitted(["csmDashboard.*:view","csmDashboard.tasks:view"]) 
    //     },
    //     tickets:{
    //         view: this.isPermitted(["csmDashboard.*:view","csmDashboard.tickets:view"]) 
    //     }
    // }
    // succcessToDos = {
    //     list: this.isPermitted(["succcessToDos:list"]) ,
    //     view: this.isPermitted(["succcessToDos:view"]) ,
    // }
    alertNotification = {
        list: this.isPermitted(["alertNotification:list"]) ,
        view: this.isPermitted(["alertNotification:view"]) ,
        search:this.isPermitted(["alertNotification:search"]) ,
        archive:this.isPermitted(["alertNotification:archive"]) ,
        touchpoint:{
            create: this.isPermitted(["alertNotification.touchpoint:create"])
        },
        task:{
            create: this.isPermitted(["alertNotification.task:create"])
        },
        email:{
            send: this.isPermitted(["alertNotification.email:send"])
        },
    }

		defaultTab = {
			smartKonversation : this.isPermitted(["defaultTab:smart-konversations"])
		}

    // focusAccounts = {
    //     list: this.isPermitted(["focusAccounts:list"]) ,
    //     view: this.isPermitted(["focusAccounts:view"]) ,
    //     edit: this.isPermitted(["focusAccounts:edit"]) ,
    //     delete: this.isPermitted(["focusAccounts:delete"]) ,
    //     export: this.isPermitted(["focusAccounts:export"]) ,
    //     account:{
    //         remove: this.isPermitted(["focusAccounts.account:remove"])
    //     },
    //     touchpoint:{
    //         create: this.isPermitted(["focusAccounts.touchpoint:create"])
    //     },
    //     task:{
    //         create: this.isPermitted(["focusAccounts.task:create"])
    //     },
    //     alert:{
    //         create: this.isPermitted(["focusAccounts.alert:create"])
    //     },
    // }
    profile={
        customerSupport: this.isPermitted(["profile:customerSupport"]), 
        edit: this.isPermitted(["profile:edit"])
    }
    calendar = {
        list: this.isPermitted(["calendar:list", "calendar:*"]),
        view: this.isPermitted(["calendar:view", "calendar:*"]) ,
        touchpoint:{
            create: this.isPermitted(["calendar.touchpoint:create", "calendar:*"])
        },
        account:{
            assign: this.isPermitted(["calendar.account:assign", "calendar:*"])
        },
    }
    email = {
        list: this.isPermitted(["email:list", "email:*"]),
        view: this.isPermitted(["email:view", "email:*"]),
        create: this.isPermitted(["email:create", "email:*"]),
        search: this.isPermitted(["email:search", "email:*"]),
        archive: this.isPermitted(["email:archive", "email:*"]),
        reply: this.isPermitted(["email:reply", "email:*"]),
        forward: this.isPermitted(["email:forward", "email:*"]),
        touchpoint:{
            create: this.isPermitted(["email.touchpoint:create", "email:*"])
        },
        account:{
            assign: this.isPermitted(["email.account:assign", "email:*"])
        },
    }
    // accountList = {
    //     list: this.isPermitted(["accountList:list"]),
    //     defineExpansion: this.isPermitted(["accountList:defineExpansion"]),
    //     focusAccounts: {
    //         create:this.isPermitted(["accountList.focusAccounts:create"]),
    //     },
    //     utilization:{
    //         view: this.isPermitted(["accountList.utilization:view"]),
    //         update: this.isPermitted(["accountList.utilization:update"]),
    //     },
    //     orders:{
    //         view: this.isPermitted(["accountList.orders:view"]),
    //         update: this.isPermitted(["accountList.orders:update"]),
    //     },
    //     invoices:{
    //         view: this.isPermitted(["accountList.invoices:view"]),
    //         update: this.isPermitted(["accountList.invoices:update"]),
    //     },
    //     opportunities:{
    //         view: this.isPermitted(["accountList.opportunities:view"]),
    //         update: this.isPermitted(["accountList.opportunities:update"]),
    //     },
    //     export: this.isPermitted(["accountList:export"]),
    //     updateColumnPreference: this.isPermitted(["accountList:updateColumnPreference"]),
		// }
	
	
	customerPortal = {
		view: this.isPermitted(["customerPortal:*"]),

	}
	
  
    // account360 = {
    //     view:this.isPermitted(["account360.*:view","account360:view"]),
    //     summary: {
    //         view:this.isPermitted(["account360.*:view","account360.summary:view"]),
    //     },
    //     activity: {
    //         view:this.isPermitted(["account360.*:view","account360.activity:view"]),
    //     },
    //     keyContacts:{
    //         view:this.isPermitted(["account360.*:view","account360.keyContacts:view"]),
    //     },
    //     activeSuccessPlay:{
    //         view:this.isPermitted(["account360.*:view","account360.activeSuccessPlay:view"]),
    //     },
    //     financialSnapshot:{
    //         view:this.isPermitted(["account360.*:view","account360.financialSnapshot:view"]),
    //     },
    //     healthScoreTrend:{
    //         view:this.isPermitted(["account360.*:view","account360.healthScoreTrend:view"]),
    //     },
    //     productSummary:{
    //         view:this.isPermitted(["account360.*:view","account360.productSummary:view"]),
    //     },
    //     productUsageActivity:{
    //         view:this.isPermitted(["account360.*:view","account360.productUsageActivity:view"]),
    //     },
    //     wauAndMostActiveUser:{
    //         view:this.isPermitted(["account360.*:view","account360.wauAndMostActiveUser:view"]),
    //     },
    //     accountIntelligence:{
    //         view:this.isPermitted(["account360.*:view","account360.accountIntelligence:view"]),
    //         alerts:{
    //             archive:this.isPermitted(["account360.accountIntelligence.alerts:archive"]),
    //         }
    //     },
    //     productConsole:{
    //         view:this.isPermitted(["account360.*:view","account360.productConsole:view"]),
    //     },
    //     timeLine:{
    //         view:this.isPermitted(["account360.*:view","account360.timeLine:view"]),
    //     },
    //     successPlay:{
    //         view:this.isPermitted(["account360.*:view","account360.successPlay:view"]),
    //     },
    //     phaseAndSegmentAttributes:{
    //         view:this.isPermitted(["account360.*:view","account360.phaseAndSegmentAttributes:view"]),
    //         update:this.isPermitted(["account360.*:view","account360.phaseAndSegmentAttributes:update"]),
    //     },
    // }
    // parent360 = {
    //     view:this.isPermitted(["parent360.*:view","parent360:view"]),
    //     summary: {
    //         view:this.isPermitted(["parent360.*:view","parent360.summary:view"]),
    //     },
    //     financialSummary: {
    //         view:this.isPermitted(["parent360.*:view","parent360.financialSummary:view"]),
    //     },
    //     statusAndPhaseSummary: {
    //         view:this.isPermitted(["parent360.*:view","parent360.statusAndPhaseSummary:view"]),
    //     },
    //     healthScoreTrend: {
    //         view:this.isPermitted(["parent360.*:view","parent360.healthScoreTrend:view"]),
    //     },
    //     parentHealthSummary: {
    //         view:this.isPermitted(["parent360.*:view","parent360.parentHealthSummary:view"]),
    //     },
    //     financialSnapshot: {
    //         view:this.isPermitted(["parent360.*:view","parent360.financialSnapshot:view"]),
    //     },
    //     productPerformance: {
    //         view:this.isPermitted(["parent360.*:view","parent360.productPerformance:view"]),
    //     },
    //     keyContacts:{
    //         view:this.isPermitted(["parent360.*:view","parent360.keyContacts:view"]),
    //     },
    //     phaseAndSegmentAttributes:{
    //         view:this.isPermitted(["parent360.*:view","parent360.phaseAndSegmentAttributes:view"]),
    //         update:this.isPermitted(["parent360.*:view","parent360.phaseAndSegmentAttributes:update"]),
    //     },
    // }
    productSuccessConsole = {
        view:this.isPermitted(["productSuccessConsole.*:view","productSuccessConsole:view"]),
        export:this.isPermitted(["productSuccessConsole.*:view","productSuccessConsole:export"]),
        heatMap: {
            view:this.isPermitted(["parent360.*:view","parent360.heatMap:view"]),
        },
        engagement: {
            view:this.isPermitted(["parent360.*:view","parent360.engagement:view"]),
        },
        features: {
            view:this.isPermitted(["parent360.*:view","parent360.features:view"]),
        },
        users: {
            view:this.isPermitted(["parent360.*:view","parent360.users:view"]),
        }
    }
    // taskDashboard = {
    //     home: {
    //         view:this.isPermitted(["taskDashboard.home:view"]),
    //     },
    //     tasks: {
    //         view:this.isPermitted(["taskDashboard.tasks:view"]),
    //     },
    // }
    // task = { 
    //     markAsComplete: this.isPermitted(["task:markAsComplete"]), 
    //     create: this.isPermitted(["task:create"]), 
    //     delete: this.isPermitted(["task:delete"]) ,
    //     update: this.isPermitted(["task:update"]),
    //     sendEmail: this.isPermitted(["task:sendEmail"]),
    //     view: this.isPermitted(["task:view"]),
    //     // comment: this.isPermitted(["touchpoint:comment"]),
    // }

    // financial ={
    //     defineExpansion:this.isPermitted(["financial:defineExpansion"]),
    //     export:this.isPermitted(["financial:export"]),
    //     home:{
    //         view:this.isPermitted(["financial.home:view"])
    //     },
    //     productView:{
    //         view:this.isPermitted(["financial.productView:view"])
    //     }
    // }
    // globalFilter ={ 
    //     read: this.isPermitted(["globalFilter.read"]),
    //     update: this.isPermitted(["globalFilter.update"]),
    // } 

    //mychanges
    
    knk = {
        create: this.isPermitted(["KandK:create", "KandK:*"]),
        read: this.isPermitted(["KandK:read", "KandK:*"]),
        update: this.isPermitted(["KandK:update", "KandK:*"]),
        delete: this.isPermitted(["KandK:delete", "KandK:*"]),
        like: this.isPermitted(["KandK:like", "KandK:*"]),
        comment: this.isPermitted(["KandK:comment", "KandK:*"]),
    
    }
    settings ={
			  users: {
					view:this.isPermitted(["settings.users:view", "settings.users:*"]),
					management : {
						view:this.isPermitted(["settings.users.management:view", "settings.users.management:*", "settings.users:*"]),
						create:this.isPermitted(["settings.users.management:create", "settings.users.management:*", "settings.users:*"]),
						update:this.isPermitted(["settings.users.management:update", "settings.users.management:*", "settings.users:*"]),
						delete:this.isPermitted(["settings.users.management:delete", "settings.users.management:*", "settings.users:*"])
					},
					access:{
						view:this.isPermitted(["settings.users.access:*"]),
						update: this.isPermitted(["settings.users.access:update"]),
					},
					roleAndPermission:{
						view:this.isPermitted(["settings.users.roleAndPermission:view", "settings.users.roleAndPermission:*", "settings.users:*"]),
						create:this.isPermitted(["settings.users.roleAndPermission:create", "settings.users.roleAndPermission:*", "settings.users:*"]),
						update:this.isPermitted(["settings.users.roleAndPermission:update", "settings.users.roleAndPermission:*", "settings.users:*"]),
						delete:this.isPermitted(["settings.users.roleAndPermission:delete", "settings.users.roleAndPermission:*", "settings.users:*"]),
					},
					accountAccess:{
						view:this.isPermitted(["settings.users.accountAccess:*"]),
					}
				},

				vendorPortal: {
					view:this.isPermitted(["settings.vendorPortal:view", "settings.vendorPortal:*"]),
					update:this.isPermitted(["settings.vendorPortal:update", "settings.vendorPortal:*"]),
					delete:this.isPermitted(["settings.vendorPortal:delete", "settings.vendorPortal:*"])
				},

        myOrg:{
            view:this.isPermitted(["settings.myOrg:view"]),
            update:this.isPermitted(["settings.myOrg:update"])
        },
        products:{
            view:this.isPermitted(["settings.products:view", "settings.products:*", "settings.configurations:*"]),
            update:this.isPermitted(["settings.products:update", "settings.products:*", "settings.configurations:*"]),
            delete:this.isPermitted(["settings.products:delete", "settings.products:*", "settings.configurations:*"]),
            create:this.isPermitted(["settings.products:create", "settings.products:*", "settings.configurations:*"])
        },

				outcomes: {
						view:this.isPermitted(["settings.outcomes:view", "settings.outcomes:*", "settings.configurations:*"]),
            update:this.isPermitted(["settings.outcomes:update", "settings.outcomes:*", "settings.configurations:*"]),
            delete:this.isPermitted(["settings.outcomes:delete", "settings.outcomes:*", "settings.configurations:*"]),
            create:this.isPermitted(["settings.outcomes:create", "settings.outcomes:*", "settings.configurations:*"])
				},

        userManagement:{
            view:this.isPermitted(["settings.userManagement:view"]),
            platformUser:{
                update:this.isPermitted(["settings.userManagement.platformUser:update"]),
                updateStatus:this.isPermitted(["settings.userManagement.platformUser:updateStatus"]),
                create:this.isPermitted(["settings.userManagement.platformUser:create"])
            },
            csmAssignment:{
                view:this.isPermitted(["settings.userManagement.csmAssignment:view"]),
                update:this.isPermitted(["settings.userManagement.csmAssignment:update"])
            },
            accountContacts:{
                view:this.isPermitted(["settings.userManagement.accountContacts:view"])
            },
            endUsers:{
                view:this.isPermitted(["settings.userManagement.endUsers:view"])
            }
        },
        accountManagement:{
            view:this.isPermitted(["settings.accountManagement:view"]),
            update:this.isPermitted(["settings.accountManagement:update"]),
						create:this.isPermitted(["settings.accountManagement:create"])
        },
        rolesAndPermissions:{
            view:this.isPermitted(["settings.rolesAndPermissions:view"]),
            update:this.isPermitted(["settings.rolesAndPermissions:update"]),
            csmAssignment:{
                view:this.isPermitted(["settings.rolesAndPermissions.csmAssignment:view"]),
                update:this.isPermitted(["settings.rolesAndPermissions.csmAssignment:update"])
            }
        },
       kAndkScheduled : {
        view : this.isPermitted(["settings.kAndkScheduled:view"]),
        create : this.isPermitted(["settings.kAndkScheduled:create"]),
        update : this.isPermitted(["settings.kAndkScheduled:update"]),
        delete : this.isPermitted(["settings.kAndkScheduled:delete"])
       },
       integration : {
        view : this.isPermitted(["settings.integration:view"]),
        delete : this.isPermitted(["settings.integrations:delete"]),
        integrate : this.isPermitted(["settings.integrations:integrate"]),
        salesforce :{
            view : this.isPermitted(["settings.integrations.salesforce:view"]),
        },
        stripe :{
            view : this.isPermitted(["settings.integrations.stripe:view"]),
        },
        zohoBooks :{
            view : this.isPermitted(["settings.integrations.zohoBooks:view"]),
        },
        chargebee :{
            view : this.isPermitted(["settings.integrations.chargebee:view"]),
        },
        skTask :{
            view : this.isPermitted(["settings.integrations.skTask:view"]),
        },
        jira :{
            view : this.isPermitted(["settings.integrations.jira:view"]),
        },
        asana :{
            view : this.isPermitted(["settings.integrations.asana:view"]),
        },
        zohoMail :{
            view : this.isPermitted(["settings.integrations.zohoMail:view"]),
        },
        hubspot :{
            view : this.isPermitted(["settings.integrations.hubspot:view"]),
        },
        pipedrive :{
            view : this.isPermitted(["settings.integrations.pipedrive:view"]),
        },
        googleWorkspace :{
            view : this.isPermitted(["settings.integrations.googleWorkspace:view"]),
        },
        microsoft365 :{
            view : this.isPermitted(["settings.integrations.microsoft365:view"]),
        },
        zoom :{
            view : this.isPermitted(["settings.integrations.zoom:view"]),
        },
        zohoDesk : {
            view : this.isPermitted(["settings.integrations.zohoDesk:view"]),
        },
        zenDesk :{
            view : this.isPermitted(["settings.integrations.zenDesk:view"]),
        },
        skTicket :{
            view : this.isPermitted(["settings.integrations.skTicket:view"]),
        },
        freshdesk :{
            view : this.isPermitted(["settings.integrations.freshdesk:view:view"]),
        },
        teamworkDesk :{
            view : this.isPermitted(["settings.integrations.teamworkDesk:view"]),
        },
				teamSupport :{
					view : this.isPermitted(["settings.integrations.teamSupport:view"]),
			},
        intercom :{
            view : this.isPermitted(["settings.integrations.intercom:view"]),
        },
       },
       eventData : {
        view : this.isPermitted(["settings.eventData:view"]),
        eventSummary : {
            view : this.isPermitted(["settings.eventData.eventSummary:view"]),
        },
        eventLogs : {
            view : this.isPermitted(["settings.eventData.eventLogs:view"]),
            detail: {
                view : this.isPermitted(["settings.eventData.eventLogs.detail:view"]),
            }
        },
        eventMapping : {
            view : this.isPermitted(["settings.eventData.eventMapping:view"]),
            update : this.isPermitted(["settings.eventData.eventMapping:update"]),
        },
        eventPattern : {
            view : this.isPermitted(["settings.eventData.eventPattern:view"]),
            update : this.isPermitted(["settings.eventData.eventPattern:update"]),
        }
       },
       notifications : {
           view : this.isPermitted(["settings.notifications:view"]),
        touchpoint : {
            view : this.isPermitted(["settings.notifications.touchpoint:view"]),
            update : this.isPermitted(["settings.notifications.touchpoint:update"]),
        },
        successPlanner : {
            view : this.isPermitted(["settings.notifications.successPlanner:view"]),
            update : this.isPermitted(["settings.notifications.successPlanner:update"]),
        },
        task : {
            view : this.isPermitted(["settings.notifications.task:view"]),
            update : this.isPermitted(["settings.notifications.task:update"]),
        },
       },
       automation : {
        view : this.isPermitted(["settings.automation:view"]),
        update : this.isPermitted(["settings.automation:update"]),
       },
       phasesAndSuccessPlay : {
        view : this.isPermitted(["settings.phasesAndSuccessPlay:view"]),
        customerJourneyPhases : {
            view : this.isPermitted(["settings.phasesAndSuccessPlay.customerJourneyPhases:view"]),
            update : this.isPermitted(["settings.phasesAndSuccessPlay.customerJourneyPhases:update"]),
            delete : this.isPermitted(["settings.phasesAndSuccessPlay.customerJourneyPhases:delete"]),
        },
        successPlay : {
            view : this.isPermitted(["settings.phasesAndSuccessPlay.successPlay:view"]),
            create : this.isPermitted(["settings.phasesAndSuccessPlay.successPlay:create"]),
            update : this.isPermitted(["settings.phasesAndSuccessPlay.successPlay:update"]),
            delete : this.isPermitted(["settings.phasesAndSuccessPlay.successPlay:delete"]),
        }
       },
			configurations:{
        view:this.isPermitted(["settings.configurations:view", "settings.configurations:*"]),
				category:{
          view:this.isPermitted(["settings.configurations.category:view", "settings.configurations.category:*", "settings.configurations:*"]),
					delete: this.isPermitted(["settings.configurations.category:delete", "settings.configurations.category:*", "settings.configurations:*"]),
					create: this.isPermitted(["settings.configurations.category:create", "settings.configurations.category:*", "settings.configurations:*"]),
					update: this.isPermitted(["settings.configurations.category:update", "settings.configurations.category:*", "settings.configurations:*"]),
        },
				functions:{
					view:this.isPermitted(["settings.configurations.functions:view", "settings.configurations.functions:*", "settings.configurations:*"]),
					delete: this.isPermitted(["settings.configurations.functions:delete", "settings.configurations.functions:*", "settings.configurations:*"]),
					create: this.isPermitted(["settings.configurations.functions:create", "settings.configurations.functions:*", "settings.configurations:*"]),
					update: this.isPermitted(["settings.configurations.functions:update", "settings.configurations.functions:*", "settings.configurations:*"]),
				}
      },
			account: {	
				view: this.isPermitted(["settings.account:disabled"]),
				accountPhases: {
					create: this.isPermitted(["settings.account.accountPhases:create"])
				}
			}

    }
    

    // campaigns ={
    //     view:this.isPermitted(["campaigns:view"]),
    //     update:this.isPermitted(["campaigns:update"]),
    //     delete:this.isPermitted(["campaigns:delete"]),
    //     create:this.isPermitted(["campaigns:create"]),
    //     viewResult:this.isPermitted(["campaigns:viewResult"]),
    // }

    // surveys ={
    //     view:this.isPermitted(["surveys:view"]),
    //     update:this.isPermitted(["surveys:update"]),
    //     delete:this.isPermitted(["surveys:delete"]),
    //     create:this.isPermitted(["surveys:create"]),
    //     viewResult:this.isPermitted(["surveys:viewResult"]),
    // }

    // nps ={
    //     view:this.isPermitted(["nps:view"]),
    //     update:this.isPermitted(["nps:update"]),
    //     delete:this.isPermitted(["nps:delete"]),
    //     create:this.isPermitted(["nps:create"]),
    //     viewResult:this.isPermitted(["nps:viewResult"]),
    // }

    smartKarrotAcademy = {
        view: this.isPermitted(["smartKarrotAcademy:view"])
    }

    // smartKonversation = {
    //     view:this.isPermitted(["smartKonversation:view"]),
    //     delete:this.isPermitted(["smartKonversation:delete"]),
    //     rating:this.isPermitted(["smartKonversation:rating"]),
    //     comment:{
    //        view:this.isPermitted(["smartKonversation.comment:view"]),
    //        create:this.isPermitted(["smartKonversation.comment:create"])
    //     },
    //     transcript:{
    //        view:this.isPermitted(["smartKonversation.transcript:view"])
    //     },
    //     video:{
    //         view:this.isPermitted(["smartKonversation.video:view"])
    //     },
    //     myKonversations:{
    //         view: this.isPermitted(["smartKonversation.myKonversations:view"])
    //     },
    //     teamKonversations:{
    //         view:this.isPermitted(["smartKonversation.teamKonversations:view"])
    //     },
    //     callIntelligence:{
    //         view:this.isPermitted(["smartKonversation.callIntelligence:view"])
    //     }
    // }

    // report = {
    //     view: this.isPermitted(["report:view"]),
    //     update: this.isPermitted(["report:update"]),
    //     create: this.isPermitted(["report:create"]),
    //     delete: this.isPermitted(["report:delete"]),
    //     export:this.isPermitted(["report:export"]),
    //     schedule:this.isPermitted(["report:schedule"]),
    //     overview:{
    //         view:this.isPermitted(["report.overview:view"])
    //     },
    //     custom:{
    //         view:this.isPermitted(["report.custom:view"])
    //     },
    //     canned:{
    //         view:this.isPermitted(["report.canned:view"])
    //     }
    // }

    // successPlayInsights ={
    //     export:this.isPermitted(["successPlayInsights:export"]),
    //     list: this.isPermitted(["successPlayInsights:list"]) 
    // }

    churnDashboard ={
        view:this.isPermitted(["churnDashboard:view"]),
        export:this.isPermitted(["churnDashboard:export"])
    }

    accountSegments = {
        view: this.isPermitted(["accountSegments:view"]),
        update: this.isPermitted(["accountSegments:update"]),
        create: this.isPermitted(["accountSegments:create"]),
        delete: this.isPermitted(["accountSegments:delete"]),
    }
    alerts = {
        view: this.isPermitted(["alerts:view"]),
        update: this.isPermitted(["alerts:update"]),
        create: this.isPermitted(["alerts:delete"]),
        delete: this.isPermitted(["alerts:create"]),
    }
		search = {
			view: this.isPermitted(["search:view"])
		}
    userSegment = {
        view: this.isPermitted(["userSegment:view"]),
        update: this.isPermitted(["userSegment:update"]),
        create: this.isPermitted(["userSegment:create"]),
        delete: this.isPermitted(["userSegment:delete"]),
    }
    // successPlay   = { 
    //     list: this.isPermitted(["successPlay:list"]) ,
    //     create:  this.isPermitted(["successPlay.*","successPlay:create"]), 
    //     update: this.isPermitted(["successPlay:update"]) ,
    //     delete: this.isPermitted(["successPlay:delete"]) ,
    //     assign: this.isPermitted(["successPlay:assign"]) ,
    //     activity:{
    //         create:this.isPermitted(["successPlay.activity:create"]),
    //         update:this.isPermitted(["successPlay.activity:update"]),
    //         delete:this.isPermitted(["successPlay.activity:delete"]),
    //         markAsApplicable:this.isPermitted(["successPlay.activity:markAsApplicable"]),
    //         markAsNotApplicable:this.isPermitted(["successPlay.activity:markAsNotApplicable"]),
    //         markAsIncomplete:this.isPermitted(["successPlay.activity:markAsIncomplete"]),
    //         markAsComplete:this.isPermitted(["successPlay.activity:markAsComplete"]),
    //     },
    //     template:{
    //         list:this.isPermitted(["successPlay.template:list"]),
    //         create:this.isPermitted(["successPlay.template:create"]),
    //         update:this.isPermitted(["successPlay.template:update"]),
    //         delete:this.isPermitted(["successPlay.template:delete"]),
    //     },
    //     monitor:{
    //         list:this.isPermitted(["successPlay.monitor:list"]),
    //         export:this.isPermitted(["successPlay.monitor:export"]),
    //         editStartDate:this.isPermitted(["successPlay.monitor:editStartDate"]),
    //         activity:{
    //             create:this.isPermitted(["successPlay.monitor.activity:create"]),
    //             update:this.isPermitted(["successPlay.monitor.activity:update"]),
    //             delete:this.isPermitted(["successPlay.monitor.activity:delete"]),
    //         },
    //         audience:{
    //             view:this.isPermitted(["successPlay.monitor.audience:view"]),
    //             update:this.isPermitted(["successPlay.monitor.audience:update"]),
    //         }
    //     }
    // }
    // ticket  = { 
    //     view:this.isPermitted(["ticket:view"]), 
    //     mapAccount:this.isPermitted(["ticket:mapAccount"]), 
    //     list:{
    //         view:this.isPermitted(["ticket.list:view"]),  
    //     },
    //     action:{
    //         sendEmail:this.isPermitted(["tickets.action:sendEmail"]),  
    //     }
    // }


    /*PHOENIX Scope */
    productAnalytics={
			isEnable:this.isPermitted(["productAnalytics:menu:*", "productAnalytics:isEnable", "menu:*", "productAnalytics:*"]), 
        productUsage:{
            view:this.isPermitted(["productAnalytics.productUsage:view","productAnalytics:*","productAnalytics.productUsage.*:view"]), 
            export:this.isPermitted(["productAnalytics.productUsage:export","productAnalytics:*","productAnalytics.productUsage.*:view"]),
            puScoreTrend:{
                view:this.isPermitted(["productAnalytics.productUsage.puScoreTrend:view","productAnalytics:*","productAnalytics.productUsage.*","productAnalytics.productUsage.*:view"])
            },
            userTrend:{
                view:this.isPermitted(["productAnalytics.productUsage.userTrend:view","productAnalytics:*","productAnalytics.productUsage.*","productAnalytics.productUsage.*:view"])
            },
            topAccount:{
                view:this.isPermitted(["productAnalytics.productUsage.topAccount:view","productAnalytics:*","productAnalytics.productUsage.*","productAnalytics.productUsage.*:view"])
            },
            bottomAccount:{
                view:this.isPermitted(["productAnalytics.productUsage.bottomAccount:view","productAnalytics:*","productAnalytics.productUsage.*","productAnalytics.productUsage.*:view"])
            },

        },
        heatMap:{
            view:this.isPermitted(["productAnalytics.heatMap:view","productAnalytics:*"]), 
            export:this.isPermitted(["productAnalytics.heatMap:export","productAnalytics:*"]), 
        },
        duration:{
            view:this.isPermitted(["productAnalytics.duration:view","productAnalytics:*","productAnalytics.duration.*:view"]), 
            export:this.isPermitted(["productAnalytics.duration:export","productAnalytics:*"]), 
            sessionLength:{
                view:this.isPermitted(["productAnalytics.duration.sessionLength:view","productAnalytics:*","productAnalytics.duration.*:view"])
            },
            sessionFrequency:{
                view:this.isPermitted(["productAnalytics.duration.sessionFrequency:view","productAnalytics:*","productAnalytics.duration.*:view"])
            }
        },
        loyalty:{
            view:this.isPermitted(["productAnalytics.loyalty:view","productAnalytics:*"]), 
            export:this.isPermitted(["productAnalytics.loyalty:export","productAnalytics:*"]),
        },
        featureUsage:{
            view:this.isPermitted(["productAnalytics.featureUsage:view","productAnalytics:*"]),
            export:this.isPermitted(["productAnalytics.featureUsage:export","productAnalytics:*"]), 
        },
        users:{
            view:this.isPermitted(["productAnalytics.users:view","productAnalytics:*","productAnalytics.users.*:view"]), 
            export:this.isPermitted(["productAnalytics.users:export","productAnalytics:*"]), 
        },
    }
    smartInsights={
        view:this.isPermitted(["smartInsights:view", "smartInsights:*"]), 
				isEnable:this.isPermitted(["smartInsights:isEnable", "menu:*", "smartInsights:*"]), 
        export:this.isPermitted(["smartInsights:export", "smartInsights:*"]), 
        cannedReport:{
            view:this.isPermitted(["cannedReport:view", "smartInsights:*"]), 
        },
        cannedReportcustomReport:{
            view:this.isPermitted(["cannedReportcustomReport:view", "smartInsights:*"]),
        }
    }

		hsDefinition = {
			view: this.isPermitted(["hsDefinition:view"]),
		}



    smartOps={
        view : this.isPermitted(["smartOps:view"]), 
				isEnable : this.isPermitted(["smartOps:isEnable", "menu:*", "smartOps:view"]), 

        revenue:{
            view: this.isPermitted(["smartOps.revenue:view","smartOps:*"]),
            export: this.isPermitted(["smartOps.revenue:export","smartOps:*"])
        },
        engagement: {
            view: this.isPermitted(["smartOps.engagement:view","smartOps:*"]),
            export: this.isPermitted(["smartOps.engagement:export","smartOps.*"])
        },
        productAdoption: {
            view: this.isPermitted(["smartOps.productAdoption:view","smartOps:*"]),
            export: this.isPermitted(["smartOps.productAdoption:export","smartOps:*"])
        },
        risk: {
            view: this.isPermitted(["smartOps.risk:view","smartOps:*"]),
            export: this.isPermitted(["smartOps.risk:export","smartOps:*"])
        },
				alert:{
					dashboardView: this.isPermitted(["smartOps.alert.dashboard:view","smartOps:*", "smartOps:alert*"]),
					summaryView: this.isPermitted(["smartOps.alert.summary:view","smartOps:*", "smartOps:alert*"]),
				}
    }
    smartAssist={
        view:this.isPermitted(["smartAssist:*"]), 
				isEnable:this.isPermitted(["smartAssist:isEnable", "menu:*", "smartAssist:*"])
    }

	smartKeyOutcomes = {
		view: this.isPermitted(["keyoutcomes:view:*"]),
		isEnable: this.isPermitted(["keyoutcomes:menu:*", "menu:*", "keyoutcomes:view:*"]),
		expansion: {
			view: this.isPermitted(["keyoutcomes.expansion:view", "keyoutcomes:view:*"]),
		},
		churn: {
			view: this.isPermitted(["keyoutcomes.churn:view", "keyoutcomes:view:*"]),
		},
		renewal: {
			view: this.isPermitted(["keyoutcomes.renewal:view", "keyoutcomes:view:*"]),
		},
		onboarding: {
			view: this.isPermitted(["keyoutcomes.onboarding:view", "keyoutcomes:view:*"]),
		},
	}
    smartKonversation={
        view:this.isPermitted(["smartKonversation:*"]), 
				isEnable:this.isPermitted(["smartKonversation:menu:*", "smartKonversation:isEnable", "menu:*", "smartKonversation:*"]), 
				teamMetrics: {
          view:this.isPermitted(["teamMetrics:view", "teamMetrics:*","smartKonversation:*"]),
				},
				summary:{
					view:this.isPermitted(["summary:view", "teamMetrics:*","smartKonversation:*"]),
				},
        team:{
            view:this.isPermitted(["team:view", "team:*","smartKonversation:*"]),
            callDetail:{
                view:this.isPermitted(["callDetail:view", "team:*", "callDetail:*","smartKonversation:*"]),
                comment:{
                    view:this.isPermitted(["comment:view", "team:*", "callDetail:*","smartKonversation:*"]),
                    comment:this.isPermitted(["comment:comment", "team:*", "callDetail:*","smartKonversation:*"]),
                }
            }
        },
        my:{
            view:this.isPermitted(["my:*", "my:view","smartKonversation:*"]), 
            callDetail:{
                comment:{
                    view:this.isPermitted(["comment:view", "team:*", "callDetail:*","smartKonversation:*"]),
                    comment:this.isPermitted(["comment:comment", "team:*", "callDetail:*","smartKonversation:*"]),
                }
            }
        },
        intelligence:{
            view:this.isPermitted(["my:*", "intelligence:view","smartKonversation:*"]), 
        },
        keyCloud:{
            view:this.isPermitted(["my:*", "keyCloud:view","smartKonversation:*"]),
			},
			manageKeyWords: {
				view: this.isPermitted(["keywords:view", "keywords:*","smartKonversation:*"]),
				create: this.isPermitted(["keywords:create", "keywords:*","smartKonversation:*"]),
				delete:this.isPermitted(["keywords:delete", "keywords:*","smartKonversation:*"]),
				}
    }
    kommunity={
        view:this.isPermitted(["kommunity:*", "kommunity.post:list", "post:read"]), 
				isEnable:this.isPermitted(["kommunity:menu:*", "menu:*", "kommunity:*"]),
        post:{
            list:this.isPermitted(["kommunity.post:list", "kommunity:*"]), 
            create:this.isPermitted(["kommunity.post:create", "kommunity:*"]),
            read:this.isPermitted(["kommunity.post:read", "kommunity:*"]),
            update:this.isPermitted(["kommunity.post:update", "kommunity:*"]),
            delete:this.isPermitted(["kommunity.post:delete", "kommunity:*"]),
            like:this.isPermitted(["kommunity.post:like", "kommunity:*"]),
            comment:this.isPermitted(["kommunity.post:comment", "kommunity:*"]),
        }
    }
		notes={
			create:this.isPermitted(["notes:create","notes:*"]), 
			edit:this.isPermitted(["notes:edit","notes:*"]), 
			delete:this.isPermitted(["notes:delete","notes:*"]), 
			list:this.isPermitted(["notes:list","notes:*"]), 
			search:this.isPermitted(["notes:search","notes:*"]), 
			isEnable: this.isPermitted(["notes:menu:*", "menu:*", "notes:*"]),

		}
		contacts    = { 
			read: this.isPermitted(["contacts:read", "contacts:*"]), 
			create: this.isPermitted(["contacts:create", "contacts:*"]), 
			list: this.isPermitted(["contacts:list","contacts:view", "contacts:*"]) ,
			isEnable: this.isPermitted(["contacts:menu:*", "menu:*", "contacts:*"]),
			update: this.isPermitted(["contacts:update", "contacts:*"]),
			search: this.isPermitted(["contacts:search", "contacts:*"]),
			export: this.isPermitted(["contacts:export", "contacts:*"]),
			delete: this.isPermitted(["contacts:delete", "contacts:*"]),
			markAsChampion: this.isPermitted(["contacts:markAsChampion", "contacts:*"]),
			view:this.isPermitted(["contacts:view", "contacts:*"]),
			// touchpoint:{
			// 		list: this.isPermitted(["contacts.touchpoint:list"])
			// },
	}

	smartTask = {
		create: this.isPermitted(["tasks:create","tasks:*"]),
		edit: this.isPermitted(["tasks:edit" , "tasks:*"]),
		delete: this.isPermitted(["tasks:delete" , "tasks:*"]),
		list: this.isPermitted(["tasks:list" ,"tasks:viewTab", "tasks:*"]),
		isEnable: this.isPermitted(["tasks:menu:*, tasks:isEnable", "menu:*", "tasks:*"]),
		markAsComplete: this.isPermitted(["tasks:markAsComplete" , "tasks:*"]),
		editDueDate: this.isPermitted(["tasks:editDueDate" , "tasks:*"]),
		editCollaborator: this.isPermitted(["tasks:editCollaborator" , "tasks:*"]),
		viewTab: this.isPermitted(["tasks:viewTab" , "tasks:*"]),
		search: this.isPermitted(["tasks:search" , "tasks:*"]),
		}

		smartTouchpoint = {
			create: this.isPermitted(["touchpoints:create","touchpoints:*"]),
			editTouchpointType: this.isPermitted(["touchpoints:editTouchpointType","touchpoints:*"]),
			addTouchpointType: this.isPermitted(["touchpoints:addTouchpointType","touchpoints:*"]),
			viewTab: this.isPermitted(["touchpoints:viewTab","touchpoints:*"]),
			isEnable: this.isPermitted(["touchpoints:menu:*", "touchpoints:isEnable", "menu:*", "touchpoints:*"]),
			}
			playbook = {
				dashboard: this.isPermitted(["playbook.dashboard:*"]),
				isEnable: this.isPermitted(["playbook.dashboard:menu:*", "playbook.dashboard:isEnable", "menu:*", "playbook.dashboard:*"]),
				summary: this.isPermitted(["playbook.summary:*", "playbook:*"]),
				rule: this.isPermitted(["playbook.rule:*"]),
				template: this.isPermitted(["playbook.template:*"]),
				playbook: this.isPermitted(["playbook:*"])
			}
			skAccountSegments = {
				view: this.isPermitted(["accountSegment:*"]),
    }
			account360 = {
				dashboard: this.isPermitted(["account360:*"]),
				isEnable: this.isPermitted(["account360:menu:*", "account360:isEnable", "menu:*", "account360:*"]),

			}
			myAccounts = {
				list: this.isPermitted(["accounts.list:*"]),
				isEnable: this.isPermitted(["accounts.list:menu:*", "accounts.list:isEnable", "menu:*", "accounts.list:*"]),

			}
		productOrService = { isPermitted: () =>  {  return HWUser.productOrService() === 'product' } }
}

var access = new AccessControl();
export default access;