import { store } from "../index";
import PlatformAPI from "../../HWLibrary/PlatformAPI";
import { skCommentActions } from "../constants/actionType";
import HWUser from "../../HWLibrary/HWUser";
import { apiCall } from "../api";

export const getSmartCommentStates = () => {
	return store.getState()?.smartCommentReducer;
}

export const setSmartCommentStates = (obj) => async dispatch => {
	dispatch({
		type: skCommentActions?.SET_SMART_COMMENT_STATES, payload: obj
	})
}
// fetch entire comment list
export const fetchSmartCommentsList = (featureId, commentId, nextToken, data) => async dispatch => {

	let request = {};
	request.params = {orgId: HWUser.orgId(), appId: HWUser.appId(), userId: HWUser.userId(), featureId: featureId, commentId:commentId, nextToken: nextToken, ...data };
	dispatch(setSmartCommentStates({ isCommentLoading: true }))
	try {
		const response = await apiCall({
			url: window.resolveRegion(process.env.REACT_APP_SMART_DASHBOARDS_BASE_URL) + "/comment",
			method: "GET",
			payload: request,
		});

		let comment = response?.data?.commentResponses
		if(nextToken) {
			comment = [...getSmartCommentStates().smartCommentsList, ...response?.data?.commentResponses]
		}
		dispatch({
			type: skCommentActions?.FETCH_SMART_COMMENTS_LIST,
			payload: {
				smartCommentsList: comment,
				smartCommentNextToken:  !data?.parentCommentId ? response?.data?.nextToken : getSmartCommentStates()?.smartCommentNextToken,
				smartCommentCount: response?.data?.commentResponses?.commentCount,
				isCommentLoading: false,
			}
		})
		return response?.data;
	} catch (error) {
		dispatch(setSmartCommentStates({
			errorMessage: error?.response?.data?.message,
			isCommentLoading: false
		}))
	}
}

export const fetchSmartCommentsListForKommunity = (featureId, commentId, nextToken, data) => async dispatch => {
	let request = {};
	request.params = {orgId: HWUser.orgId(), appId: HWUser.appId(), userId: HWUser.userId(), featureId: featureId, commentId:commentId, nextToken: nextToken, ...data };
	try {
		const response = await apiCall({
			url: window.resolveRegion(process.env.REACT_APP_SMART_DASHBOARDS_BASE_URL) + "/comment",
			method: "GET",
			payload: request,
		});
    let commentMap = getSmartCommentStates().smartKommunityCommentsList || {};
		let comment = response?.data;
		if(nextToken) {
		  comment = getSmartCommentStates().smartKommunityCommentsList[featureId];
      comment?.commentResponses?.push(...response?.data?.commentResponses);
			comment['nextToken'] = !data?.parentCommentId && response?.data?.nextToken
		}
		commentMap[featureId] = comment;
		dispatch({
			type: skCommentActions?.FETCH_SMART_COMMENTS_LIST,
			payload: {
				smartKommunityCommentsList: commentMap,
			}
		})
		return response?.data;
	} catch (error) {
		dispatch(setSmartCommentStates({
			errorMessage: error?.response?.data?.message,
		}))
	}
}

// on save comment
export const postComment = (data) => async dispatch => {
	let request = { orgId: HWUser.orgId(), appId: HWUser.appId(), userId: HWUser.userId(), ...data };
	dispatch(setSmartCommentStates({ isLoading: true }))
	try {
		const response = await apiCall({
			url: window.resolveRegion(process.env.REACT_APP_SMART_DASHBOARDS_BASE_URL) + "/comment",
			method: "POST",
			payload: request,
		});
		// if (response?.status === 200) {
		// 	dispatch({
		// 		type: skCommentActions?.POST_SMART_COMMENT,
		// 		payload: { isLoading: false, successMsg: "Saved" },
		// 	});
		// 	dispatch(fetchSmartCommentsList(featureId));
		// }
	} catch (error) {
		dispatch(
			setSmartCommentStates({ errorMessage: error?.response?.data?.message, isLoading: false })
		)
	}
}

// fetch single comment for edit
export const fetchSingleCommentForEdit = (featureId, commentId) => async dispatch => {
	let request = {};
	request.params = { orgId: HWUser.orgId(), appId: HWUser.appId(), userId: HWUser.userId(), featureId: featureId, commentId: commentId };
	dispatch(setSmartCommentStates({ isLoading: true }))
	try {
		const response = await apiCall({
			url: window.resolveRegion(process.env.REACT_APP_SMART_DASHBOARDS_BASE_URL) + "/comment",
			method: "GET",
			payload: request,
		});
		dispatch({
			type: skCommentActions?.FETCH_SINGLE_COMMENT,
			payload: { smartComment: response?.data?.comments?.[0],isLoading: false },
		})
	} catch (error) {
		dispatch(setSmartCommentStates({
			errorMessage: error?.response?.data?.message,
			isLoading: false
		}))
	}
}

// on edit comment
export const editComment = (commentId, featureId, editedComment) => async dispatch => {
	let request = { orgId: HWUser.orgId(), appId: HWUser.appId(), userId: HWUser.userId(), featureId: featureId, commentId: commentId, ...editedComment };
	dispatch(setSmartCommentStates({ isLoading: true }))
	try {
		const response = await apiCall({
			url: window.resolveRegion(process.env.REACT_APP_SMART_DASHBOARDS_BASE_URL) + "/comment",
			method: "PUT",
			payload: request,
		});
		if (response?.status === 200) {
			dispatch({
				type: skCommentActions?.EDIT_SMART_COMMENT,
				payload: { isLoading: false, successMsg: "Edited" },
			});
			dispatch(fetchSmartCommentsList(featureId));
		}
	} catch (error) {
		dispatch(
			setSmartCommentStates({ errorMessage: error?.response?.data?.message, isLoading: false })
		)
	}
}

// on delete comment
export const deleteComment = (featureId, commentId) => async dispatch => {
	let request = {};
	request.params = { commentId: commentId };
	dispatch(setSmartCommentStates({ isLoading: true }))
	try {
		const response = await apiCall({
			url: window.resolveRegion(process.env.REACT_APP_SMART_DASHBOARDS_BASE_URL) + "/comment",
			method: "DELETE",
			payload: request,
		});
		// if (response?.status === 200) {
		// 	dispatch({
		// 		type: skCommentActions?.DELETE_SMART_COMMENT,
		// 		payload: { isLoading: false, successMsg: "Deleted" },
		// 	});
		// 	dispatch(fetchSmartCommentsList(featureId));
		// }
	} catch (error) {
		dispatch(setSmartCommentStates({ errorMessage: error?.response?.data?.message, isLoading: false }))
	}
}

export const postCommentReaction = (featureId, commentId, reaction, feature) => async dispatch => {
	let request = {};
	request = { orgId: HWUser.orgId(), appId: HWUser.appId(), userId: HWUser.userId(), featureId: featureId, commentId: commentId, reaction: reaction, feature: feature};
	dispatch(setSmartCommentStates({ isLoading: true }))
	try {
		const response = await apiCall({
			url: window.resolveRegion(process.env.REACT_APP_SMART_DASHBOARDS_BASE_URL) + "/comment/like",
			method: "POST",
			payload: request,
		});
	} catch (error) {
		dispatch(setSmartCommentStates({ errorMessage: error?.response?.data?.message, isLoading: false }))
	}
}

export const deleteReaction = (likeId, commentId) => async dispatch => {
	let request = {};
	request.params = { likeId: likeId, commentId: commentId };
	dispatch(setSmartCommentStates({ isLoading: true }))
	try {
		const response = await apiCall({
			url: window.resolveRegion(process.env.REACT_APP_SMART_DASHBOARDS_BASE_URL) + "/comment/like",
			method: "DELETE",
			payload: request,
		});
	} catch (error) {
		dispatch(setSmartCommentStates({ errorMessage: error?.response?.data?.message, isLoading: false }))
	}
}

// fetch org users
export const fetchOrganizationUsers = (smartUsersList) => async dispatch => {
	let cacheExists = ((smartUsersList && smartUsersList?.length > 0) || false);
	dispatch(setSmartCommentStates({ isLoading: true }))
	try {
		PlatformAPI.fetchOrgUsers(cacheExists, (orgUsers) => {
			let activeOrgUsers = orgUsers && orgUsers.filter((i) => {
				return (i?.userStatus === 'Active')
			})
			let userList = activeOrgUsers.map(user => { return { id: user.id, display: user.name } });
			dispatch(setSmartCommentStates({ smartUsersList: userList, isLoading: false }))
		})
	} catch (error) {
		dispatch(setSmartCommentStates({ errorMessage: error?.response?.data?.message, isLoading: false }))
	}
}





