import { skAccountManagementActions } from "../constants/actionType";
const initialState = {
	acccounts: [],
	isLoading: false,
	nextToken: null,
	showSkeleton: true,
	isAddLoading: false,
	attributes: [],
	accountDetails:{},
	productSegment:{},
}



export const skAcountManagementActionReducer = (state = initialState, { type , payload }= {}) => {
	switch (type) {
    case skAccountManagementActions.FETCH_ACCOUNTS_LIST:
    case skAccountManagementActions.ADD_ACCOUNT:
		case skAccountManagementActions.EDIT_ACCOUNT: 
		case skAccountManagementActions.FETCH_SINGLE_ACCOUNT: 
		case skAccountManagementActions.DELETE_ACCOUNT:
		case skAccountManagementActions.SET_ACCOUNT_LOADING:
		case skAccountManagementActions.SET_SMART_STATE:
		case skAccountManagementActions.DELETE_ALLOCATION:
		case skAccountManagementActions.SET_IS_LOADING:
		case skAccountManagementActions.UPDATE_ALLOCATIONT:
		case skAccountManagementActions.FETCH_ATTRIBUTES:
		case skAccountManagementActions.SET_IS_ERROR: {return { ...state, ...payload }}
    default: return state;
  }
}

export default skAcountManagementActionReducer

