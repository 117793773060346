import { skAttachmentActions } from "../constants/actionType"



const initialState = {
	smartAttachmentsList: null,
	isAttachmentLoading: false,
	isAttachmentError: false,
	attachmentSuccessMsg: '',
	attachmentErrorMessage: '',
}


const skAttachmentActionReducer = (state = initialState, { type, payload }) => {
	switch (type) {
		case skAttachmentActions.POST_SMART_ATTACHMENT:
		case skAttachmentActions.DELETE_SMART_ATTACHMENT:
		case skAttachmentActions.SET_ATTACHMENT_ERROR:
		case skAttachmentActions.SET_ATTACHMENT_LOADING:
		case skAttachmentActions.SET_SMART_ATTACHMENT_STATES:
		case skAttachmentActions.FETCH_SMART_ATTACHMENTS_LIST: {
			return {
				...state, ...payload
			}
		}
		default: return state
	}
}

export default skAttachmentActionReducer