import { ClickAwayListener, Popover } from "@material-ui/core";
import React, { useCallback, useEffect, useRef, useState } from "react";
import DatePicker from "react-datepicker";
import 'react-datepicker/dist/react-datepicker.css';
import smartFilterTokenService from "../../SmartFilterTab/SmartFilterToken";
import { cloneDeep } from "lodash";
import "./SmartDatePicker.css";
import moment from "moment";
import SkRadioBtn from "../SkRadioBtn/SkRadioBtn";
import SKSmartUiBtns from '../SKSmartUiBtns/SKSmartUiBtns';

function SmartDatePicker(props) {

	const [startDate, setStartDate] = useState(null);
	const [endDate, setEndDate] = useState(null);
	const [selectedIds, setSelectedIds] = useState([]);
	const [dateAttributes, setDateAttributes] = useState([]);
	const [selectText, setSelectText] = useState(null);
	const [text, setText] = useState(null);
	const [showMenuItems, setShowMenuItems] = useState(false);
	const [parentDateAttributes, setParentDateAttributes] = useState(null)
	const [dateText, setDateText] = useState();
	const [dateKeyRefresher, setDateKeyRefresher] = useState(new Date().getMilliseconds() + "")
	


	const anchorEle = useRef();

	useEffect(() => {
		if (props?.enableGlobalDateFilter) {
			setParentDateAttributes(props?.dateAttributes);	
		} else {
			setParentDateAttributes(null);
			setSelectedIds([]);
			setSelectText(null);
			setText(null);
		}	

		return () => {
			setStartDate(null)
			setEndDate(null)
		}

	}, [props?.enableGlobalDateFilter, props?.dateAttributes])
	

	const onChange = (dates) => {
		const [start, end] = dates;
		setStartDate(start);
		setEndDate(end);
		setDateKeyRefresher(new Date().getMilliseconds() + "");
	};

	const onClosePopUp = () => {
		setShowMenuItems(false);
	}
	const dropdownPaperStyle = {
		width: '260px',
		background: "#FFFFFF 0% 0% no-repeat padding-box",
		boxShadow: "0px 3px 6px #00000029",
		borderRadius: "0px 0px 6px 6px",
		opacity: 1,
		border: "1px solid var(--sk-smart-datepicker-border-color)",
		marginLeft: "12px",
	}
	const onApply = () => {
		// While selecting the date it should be a range, means two date we need to select and then date filter should get apply

		// after navigate to the another screen and come back if we open the filter by default todays date should highlighted, but here previous applied date is highlighting 
		if (selectedIds && selectedIds.length > 0 && selectedIds[0]) {
			if (selectedIds?.[0] === "custom" && startDate && endDate) {
				smartFilterTokenService.addDateFilter(selectedIds && selectedIds.length > 0 && selectedIds[0], startDate, endDate, true);
			} else if(selectedIds?.[0] === "custom" && startDate) {
				smartFilterTokenService.addDateFilter(selectedIds && selectedIds.length > 0 && selectedIds[0], startDate, startDate, true);
			} else {
				smartFilterTokenService.addDateFilter(selectedIds && selectedIds.length > 0 && selectedIds[0], null, null, true);
			}
				
		}
		

		setShowMenuItems(false);
		setSelectText(text)
		props.setRefreshKey && props.setRefreshKey(new Date().getMilliseconds() + "");
		// props?.updateUserFilterPreference()
	}

	const getInitailData = useCallback(() => {
		if (parentDateAttributes && parentDateAttributes?.length > 0) {
			let attributes = cloneDeep(parentDateAttributes);
			setDateAttributes(attributes);
			let item = null;
			if (props?.skSmartDateFilterToken?.inLastDays) {
				item = attributes && attributes.filter((i) => { return ((props.skSmartDateFilterToken.inLastDays === i?.id)) })[0];
			} else { item = attributes && attributes.filter((i) => { return ((i.defaultOption)) })[0]; }
			setText(item?.name)
			setSelectText(item?.name)
			setSelectedIds([item?.id])
			smartFilterTokenService.addDateFilter(item?.id, startDate, endDate, false);
		}

	},[endDate, parentDateAttributes, props?.skSmartDateFilterToken?.inLastDays, startDate])

	useEffect(() => {
		if (parentDateAttributes && parentDateAttributes?.length > 0 && props?.enableGlobalDateFilter) {
			getInitailData();
		}
	}, [parentDateAttributes])

	

	const values = cloneDeep(dateAttributes);
	const onValueSelect = (data) => {
		let id = "", name = "";
		id = [data.id];
		name = data?.name;
		setText(name)
		setSelectedIds(id);
	}
	useEffect(() => {

		let dateInputText = selectText && selectText !== "Custom" ? selectText : "";
		dateInputText = selectedIds?.includes("custom") ? "" : dateInputText;
		dateInputText += (selectedIds?.includes("custom") && smartFilterTokenService?.token?.dateFilter?.startDate) ? "  " : ""
		dateInputText += (selectedIds?.includes("custom") && smartFilterTokenService?.token?.dateFilter?.startDate) ? moment(smartFilterTokenService?.token?.dateFilter?.startDate).format("DD MMM YY") : ""
		dateInputText += (selectedIds?.includes("custom") && smartFilterTokenService?.token?.dateFilter?.startDate) ? " - " : ""
		dateInputText += (selectedIds?.includes("custom") && smartFilterTokenService?.token?.dateFilter?.endDate) ? moment(smartFilterTokenService?.token?.dateFilter?.endDate).format("DD MMM YY") : ""
		setDateText(dateInputText)
		
	},[selectText, selectedIds, smartFilterTokenService?.token?.dateFilter?.startDate,smartFilterTokenService?.token?.dateFilter?.endDate ])


	const btns = {
		primaryBtnLabel: "APPLY",
		primaryBtnAction: onApply
	}
	useEffect(() => {
		if (showMenuItems) { setSelectedIds([smartFilterTokenService?.token?.dateFilter?.inLastDays]) }
	}, [showMenuItems])

	const resetFilter = useCallback(()=>{
		setText(null)
		setSelectText(null)
		setSelectedIds([])
		setStartDate(null)
		setEndDate(null)
		smartFilterTokenService.emptyDateFilter(true);
		props?.updateUserFilterPreference()
	}, [props])

	const filterDates = date => {
		if(!props?.dateRange) return true;
		const currentDate = new Date();
		if (!startDate) return date <= currentDate;
		if(endDate) return date <= currentDate;
		const maxDate= new Date(startDate);
		maxDate.setMonth(startDate.getMonth() + 12);
    return date < maxDate && date <= currentDate;
};

	
	return (
		<>
			<div ref={anchorEle} key={"date_pick"+props?.refreshKey} className={!parentDateAttributes ? "disabled__elements__global-date-filter" : "smartDatepicker"}>
				<div onClick={() => { setShowMenuItems(true) }} className={`${values && values.length > 0 && props.enableGlobalDateFilter? " " : "pointer-events-none "} smartDatepicker-input-wrap`}>
					<div className="d-flex align-items-center">
						<div className="smartDatepicker-input-icon"></div>
						<div className="smartDatepicker-input"> {dateText || "Choose Date"} </div>
					</div>
					<div className="sk__date__filter__arrows_container">
						{props?.showDateResetButn && dateText /*&& !props.hideDateResetButn */ &&  <div onClick={(e)=>{e.stopPropagation();e.preventDefault(); resetFilter()}} className="sk__date__filter__arrow__icon__container-cross-arrow"></div>}
						<div className="sk__date__filter__arrow__icon__container-arrow"></div>
					</div>
				</div>
				{showMenuItems &&
					<ClickAwayListener onClickAway={() => {
						setShowMenuItems(false);
						if (selectedIds?.includes("custom")) {
							onApply()		
						}
					}}>
						<Popover id={"1231324"} open={showMenuItems} anchorEl={anchorEle?.current}
							onClose={() => {
								if (selectedIds?.includes("custom")) {
									onApply()		
								}
								onClosePopUp();
							}}
							anchorOrigin={{ vertical: 'bottom', horizontal: "right", }}
							transformOrigin={{ vertical: 'top', horizontal: 'right', }}
							PaperProps={{ style: dropdownPaperStyle }}>
							<div className="sk-smart-date-fliter-container">
								<div className="filter-dropdown-container">
									<div className="filter-dropdown-header-container">
										<div className="filter-dropdown-header-label">Date Range</div>
										{!(selectedIds?.includes("custom") || values?.[0]?.id === "custom") && <SKSmartUiBtns {...btns} />}
									</div>
								</div>
								<div className="filter-dropdown-search-box-container">
									{values.map(value => {
										const selected = selectedIds.indexOf(value.id) !== -1;
										return (<div key={value.id} className={((value.selected) ? "filter-attribute-dropdown-option-selected" : "filter-attribute-dropdown-option")} onClick={() => { onValueSelect(value); }}>
											<div>
												<SkRadioBtn setCheckValueDependsOnPropsValue={true} checked={selected ? true : false} onChange={(e) => { onValueSelect(value); }} />
											</div>
											<div className={selected ? "filter-attribute-dropdown-option-selected-lable" : ""}>{value.name}</div>
										</div>)
									})}
								</div>
								{selectedIds && selectedIds.includes("custom") && <div className="d-flex align-items-center justify-content-center sk-smart-datepicker-containter"><DatePicker
									className="sk_smark_range_datepicker"
									selected={startDate}
									onChange={onChange}
									startDate={startDate}
									endDate={endDate}
									selectsRange
									inline
									{...(props?.dateRange && { filterDate: filterDates })}
									key={"date_picker"+dateKeyRefresher}
								/></div>
								}
							</div>
						</Popover>
					</ClickAwayListener>
				}
			</div>
		</>
	)
}
export default SmartDatePicker