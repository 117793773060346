import React, { useCallback, useState } from "react";
import { Modal, ModalBody } from 'react-bootstrap';
import "../SKUtil.css";
import SkLoaderComponent from "../SkLoader/SkLoaderComponent";

const SmartConfirmModal = ({ className, msg,subMsg, showIcons, okClickCallback, callBackNotRequired, cancelClick, targetObject, showOnlyOkButton, onlyOkAction, loader, ...props }) => {

	const [loading, setLoading] = useState(false);
	const [buttonDisabled, setButtonDisabled] = useState(false);


	const onOkAction = useCallback(() => {
		setLoading(true);
		okClickCallback && okClickCallback(targetObject)?.then(() => {
			setLoading(false);
			// props.onlyOkAction
		});
	}, [okClickCallback, targetObject])

	return (
			<Modal show className="confirmModal" backdropClassName="touchPointModal alertPoptouchPointModal" size="lg" dialogClassName={`disable-modal-content popUpWidthSPT skConfirmModalWrap  ${props?.dialogClassName}`} >
			<SkLoaderComponent isLoading={!callBackNotRequired ? loader : false}/>
				<ModalBody className={`SPTDuplicateModal smartmodal ${className}`}>
					<div style={{ color: 'white' }}>
						<div className="integration_messagesForAppIntegration">
							<p className="mb integration_messagesForAppIntegration" dangerouslySetInnerHTML={{ __html: msg }}>
								{/* {} */}
							</p>
						</div>
					{!showOnlyOkButton &&
						<>
							<SkLoaderComponent isLoading={loading || loader} />
							<div className="d-flex" style={{ justifyContent: "end", paddingTop: "10px" }}>
								<div className="flexibleBtn"> <Btn clicked={() => { cancelClick(targetObject) }} btnLabel={props?.leftBtn} btnDefualtLabel={'CANCEL'} /></div>
								<div><Btn className={"ok__btn__label"} clicked={() => {
									if (callBackNotRequired && !buttonDisabled) {
										setButtonDisabled(true);
										okClickCallback()
									} else {
										if(!buttonDisabled){
											setButtonDisabled(true);
											onOkAction();
										}
									}

								}} btnLabel={props?.rightBtn} btnDefualtLabel={'PROCEED'} /></div>
							</div>
						</>
					}
						{showOnlyOkButton && <div className="single__okay__button__container"><div><Btn className={"ok__btn__label"} clicked={() => { onlyOkAction() }} btnLabel={props?.rightBtn} btnDefualtLabel={'OK'} showCelebration={props?.showCelebration}/></div></div>}
						
					</div>
				</ModalBody>
			</Modal>
	)
}


export default SmartConfirmModal;

const Btn = ({ btnLabel, btnDefualtLabel, clicked, className, showCelebration }) => {
	return (
		<>
		<input className={`appIntPopup mt-10 ${className}`} type="button" id="see_result1" onClick={(e) => { e.stopPropagation(); clicked(); }} value={btnLabel ? btnLabel : btnDefualtLabel} />

		</>
	)
}


