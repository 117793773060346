export const smartCommentsStyle = {

	input: {
		overflow: 'auto'
	},
	suggestions: {
		zIndex:2,
		list: {
			fontSize: 14,
			background: '#fff',
			borderRadius: '4px',
			boxShadow: '0 0 0 1px rgba(111,119,130,.15), 0 5px 20px 0 rgba(21,27,38,.08)',
			MozBoxSizing: 'border-box',
			boxSizing: 'border-box',
			width: 'auto',
			maxHeight: '150px',
			overflowY: 'auto',
			zIndex: '9999999'
		},
		item: {
			padding: '10px 10px',
			height: '30px',
			textAlign: 'cetner',
			display: 'flex',
			justifyContent: 'left',
			flexDirection: 'start',
			alignItems: 'center',
			borderBottom: '1px solid rgba(0,0,0,0.15)',
			color: '#59686b', fontFamily: 'var(--sk-font-urbanist-family)', fontSize: '12px', fontWeight: '400',
			'&focused': {
				backgroundColor: '#e5e5e8',
				color: '#151b26'
			},
		},
		'&multiLine': {
			overflowY:"auto",
			control: {
				fontFamily: 'var(--sk-font-urbanist-family)',
				minHeight: 56,
				overflowY:"auto"
			},
			highlighter: {
				padding: 9,
				border: '1px solid green',
				color: 'red',
				overflowY:"auto"

			},
			input: {
				padding: 9,
				border: '1px solid silver',
				color: 'red',
				overflowY:"auto"

			}
			
		},
		'&singleLine': {
			display: 'inline-block',
			width: 180,

			highlighter: {
				padding: 1,
				border: '2px inset red',
				color: 'red',
				overflowY:"auto"

			},
			input: {
				padding: 1,
				border: '2px inset yellow',
				color: 'red',
				overflowY:"auto"

			},
		},
	},
}