import React, { useEffect, useRef, useState, lazy, memo, Suspense } from "react";
import { Popover } from "@material-ui/core";
import "./SmartActions.css";
import HWUser from "../../HWLibrary/HWUser";
// import SkSmartTaskPopUp from "../../SKSmartTask/SmartTaskFormComponents/SkSmartTaskPopUp";
// import SkSmartTouchpointPopup from "../../SkSmartTouchpoint/SkSmartTouchpointPopup";
import { useDispatch, useSelector } from 'react-redux';
import { fetchTouchpointWithOutComesData } from "../../store/reducers/skTouchpointReducer";
import access from '../../HWLibrary/AccessControl';
// import PlayBookAssignmentRulesPopup from "../../SkWorkSpaceModule/SkPlayBook/AssignmentRules/PlayBookAssignmentRulesPopup";
// import ComposeMail from "../../SmartEmail/ComposeMail";
// import CreatePlayBookSummaryPopup from "../../SkWorkSpaceModule/SkPlayBook/Summary/CreatePlayBookSummaryPopup";
import { getSmartPlayBookStates, playbookRbackScope } from "../../store/reducers/skPlaybooksReducer";
// import SkSmartUserPopup from "../../SmartAccessSetting/SkSmartUserPopup";
// import SkSmartContactPopup from "../../SkPortfolio/modules/SkContact/SkSmartContactPopup";
import smartFilterTokenService from "../../SmartFilterTab/SmartFilterToken";
import { fetchTaskProductOptions, getSmartTaskStates } from "../../store/reducers/skTaskReducer";
// import SkSmartAccountPopup from "../../SmartAccountManagement/SkSmartAccountPopup";
import moment from "moment";
import SkLoaderComponent from "../SkLoader/SkLoaderComponent";
import { useCallback } from "react";


const SkSmartTaskPopUp= lazy(() => import("../../SKSmartTask/SmartTaskFormComponents/SkSmartTaskPopUp"));
const SkSmartTouchpointPopup= lazy(() => import("../../SkSmartTouchpoint/SkSmartTouchpointPopup"));
const PlayBookAssignmentRulesPopup= lazy(() => import("../../SkWorkSpaceModule/SkPlayBook/AssignmentRules/PlayBookAssignmentRulesPopup"));
const ComposeMail= lazy(() => import("../../SmartEmail/ComposeMail"));
const CreatePlayBookSummaryPopup = lazy(() => import("../../SkWorkSpaceModule/SkPlayBook/Summary/CreatePlayBookSummaryPopup"));
const SkSmartUserPopup= lazy(() => import("../../SmartAccessSetting/SkSmartUserPopup"));
const SkSmartContactPopup= lazy(() => import("../../SkPortfolio/modules/SkContact/SkSmartContactPopup"));
const SkSmartAccountPopup= lazy(() => import("../../SmartAccountManagement/SkSmartAccountPopup"));



function SmartActions(props) {
	const dispatch = useDispatch();
	const [showMenuItems, setShowMenuItems] = useState(false);
	const [openTaskPopup, setOpenTaskPopup] = useState(false);
	const [openContactPopup, setOpenContactPopup] = useState(false);
	const [openTouchpointPopup, setOpenTouchpointPopup] = useState(false);
	const [openAssignmentRulePopup, setOpenAssignmentRulePopup] = useState(false);
	const [openMailPopup, setOpenMailPopup] = useState(false);
	const [openUserPopup, setOpenUserPopup] = useState(false);
	const [openPlaybookPopup, setOpenPlaybookPopup] = useState(false);
	const [openAccountPopup, setOpenAccountPopup] = useState(false);
	const [currentRow, setCurrentRow] = useState();
	const [touchpointToEdit,setTouchpointToEdit] = useState(null);

	const getSmartTaskState = useSelector(getSmartTaskStates);
	const { taskProductOptions,taskOutcomeOptions } = getSmartTaskState;
	const getSmartPlayBookState = useSelector(getSmartPlayBookStates);
	const { playbookRbacResponse} = getSmartPlayBookState;

	const anchorEle = useRef();
	const onClosePopUp = () => {
		setShowMenuItems(false);
	}
	const dropdownPaperStyle = {
		minWidth: '150px', background: "#FFFFFF 0% 0% no-repeat padding-box",
		boxShadow: "0px 3px 6px #00000029",
		borderRadius: "0px",
		opacity: 1, marginTop: "-34px",
		// marginLeft: "-15px",
		padding: "0px"
	}

	const onAddTaskClick = (e) => {
		setOpenTaskPopup(!openTaskPopup);
		setShowMenuItems(false);
	}

	const onAddTouchpointClick = useCallback((e) => {
		let updatedTouchpointPopup = !openTouchpointPopup;
		setOpenTouchpointPopup(updatedTouchpointPopup);
		setShowMenuItems(false);
		// List call to fetch the data again after TP creation
		if (document.getElementById("touchpointDashboard__container") && !openTouchpointPopup === false) {
			setTimeout(() => {
				dispatch(fetchTouchpointWithOutComesData())
			}, 2000)
		}
	}, [dispatch, openTouchpointPopup])

	const onAddContactClick = (e) => {
		setOpenContactPopup(!openContactPopup)
		setShowMenuItems(false);
	}

	const onCustomReportClick = (e) => {
		props && props.onCreateActionClick && props.onCreateActionClick(e); onClosePopUp();
	}

	const onAddAssignmentRuleClick = (e) => {
		setOpenAssignmentRulePopup(!openAssignmentRulePopup);
		setShowMenuItems(false);
	}

	const onDiscard = (e) => {
		setOpenMailPopup(!openMailPopup)
		setShowMenuItems(!showMenuItems);
	}

	const onTemplateClick = (e) => {
		props.actions.onTemplateClick && props.actions.onTemplateClick();
		onClosePopUp();
	}

	const onPlayBookClick = (e) => {
		setOpenPlaybookPopup(!openPlaybookPopup);
		setShowMenuItems(false);
	}

	const onCloseUser = (e) => {
		setOpenUserPopup(false);
		setShowMenuItems(!showMenuItems);
	}

	const onCloseAccount = (e) => {
		setOpenAccountPopup(false);
	}

	const onComposeMailClick = (e) => {
		setOpenMailPopup(true);
	}

	const onAddUserClick = (e) => {
		setOpenUserPopup(true);
	}

	const onAddProductClick = (e) => {
		setShowMenuItems(false);
		props.actions.onClickAddProductPopup(true);
	}

	const onAddPhaseClick = () => {
		setShowMenuItems(false);
		props.actions.onClickAddPhasePopup(true);
	}

	const onAddAccountClick = () => {
		setShowMenuItems(false);
		setOpenAccountPopup(true);
	}

	const onAddAccountSegmentClick = () => {
		setShowMenuItems(false);
		props.actions.onAddSegmentClick && props.actions.onAddSegmentClick();
	}

	const defaultOptions = [
		{ id: 'task', label: 'Task', action: onAddTaskClick, iconClassName: 'smart_action_task_icon', isAvailable: access?.smartTask?.create() },
		{ id: 'touchpoints', label: 'Touchpoints', action: onAddTouchpointClick, iconClassName: 'smart_action_tp_icon', isAvailable: access?.smartTouchpoint?.create() },
		// { id: 'alerts', label: 'Alerts', action: onAddAlertClick, iconClassName: 'smart_action_alert_icon', isAvailable:true},
	]
	const options = [
		{
			id: 'task', label: 'Task', action: onAddTaskClick, iconClassName: 'smart_action_task_icon',
			isAvailable: access?.smartTask?.create()
		},
		{ id: 'touchpoints', label: 'Touchpoints', action: onAddTouchpointClick, iconClassName: 'smart_action_tp_icon', isAvailable: true },
		// { id: 'alerts', label: 'Alerts', action: onAddAlertClick, iconClassName: 'smart_action_alert_icon', isAvailable: true },
		{ id: 'custom_reports', label: 'Custom Reports', action: onCustomReportClick, iconClassName: 'smart_action_task_icon', isAvailable: true },
		{ id: 'contact', label: 'Add Contact', action: onAddContactClick, iconClassName: 'smart_action_task_icon', isAvailable: access?.contacts?.create() },
		{ id: 'assignment_rule', label: 'Trigger', action: onAddAssignmentRuleClick, isAvailable: playbookRbackScope(["rule:Create"]) ? true : false },
		{ id: 'template', label: 'Template', action: onTemplateClick, isAvailable: playbookRbackScope(["template:Create"]) ? true : false },
		{ id: 'playbook', label: 'Playbook', action: onPlayBookClick, isAvailable: playbookRbackScope(["playbook.create:Enabled"]) ? true : false },
		{ id: 'email', label: 'Compose Email', action: onComposeMailClick, isAvailable: true },
		{ id: 'user', label: 'User', action: onAddUserClick, isAvailable: true },
		{ id: 'product', label: HWUser.productOrService() === "product" ? 'Product' : 'Service', action: onAddProductClick, isAvailable: true },
		{ id: 'phase', label: 'Phase', action: onAddPhaseClick, isAvailable: true },
		{ id: 'account', label: 'New Account', action: onAddAccountClick, isAvailable: true },
		{ id: 'accountSegment', label: 'Account Segment', action: onAddAccountSegmentClick, isAvailable: access?.skAccountSegments?.view() },
	]
	const actionConfiguration = [
		{ id: 'Expansion', actions: ['task'], isEnable: access?.smartTask?.create() },
		{ id: 'Touchpoints', actions: ['touchpoints'], isEnable: access?.smartTouchpoint?.create() },
		{ id: 'Touchpoint_List', actions: ['touchpoints'], isEnable: access?.smartTouchpoint?.create() },
		{ id: 'account360_touchpoint_list', actions: ['touchpoints'], isEnable: true },
		{ id: 'Tasks', actions: ['task'], isEnable: access?.smartTask?.create() },
		{ id: 'timeline', actions: ['task'], isEnable: access?.smartTask?.create() },
		{ id: 'Outcome', actions: ['task'], isEnable: access?.smartTask?.create() },
		{ id: 'List', actions: ['task'], isEnable: access?.smartTask?.create() },
		{ id: 'SIA', actions: ['task'], isEnable: access?.smartTask?.create() },
		{ id: 'team_conversations', actions: ['task'], isEnable: access?.smartTask?.create() },
		{ id: 'call_intelligence', actions: ['task'], isEnable: access?.smartTask?.create() },
		{ id: 'key_cloud', actions: ['task'], isEnable: access?.smartTask?.create() },
		{ id: 'my_conversations', actions: ['task'], isEnable: access?.smartTask?.create() },
		{ id: 'RI', actions: ['task'], isEnable: access?.smartTask?.create() },
		{ id: 'EI', actions: ['task'], isEnable: access?.smartTask?.create() },
		{ id: 'PAI', actions: ['task'], isEnable: access?.smartTask?.create() },
		{ id: 'RiskIntelligence', actions: ['task'], isEnable: access?.smartTask?.create() },
		{ id: HWUser.orgName(), actions: ['task'], isEnable: access?.smartTask?.create() },
		{ id: 'CannedReports', actions: ['task', 'touchpoints'], isEnable: access?.smartTask?.create() },
		{ id: 'CustomReports', actions: ['task', 'touchpoints', 'custom_reports'], isEnable: true },
		{ id: 'product_usage', actions: ['task', 'touchpoints'], isEnable: true },
		{ id: 'heatMap', actions: ['task', 'touchpoints'], isEnable: true },
		{ id: 'duration', actions: ['task'], isEnable: access?.smartTask?.create() },
		{ id: 'loyalty', actions: ['task'], isEnable: access?.smartTask?.create() },
		{ id: 'feature', actions: ['task'], isEnable: access?.smartTask?.create() },
		{ id: 'Users', actions: ['task', 'touchpoints'], isEnable: true },
		{ id: 'Contacts', actions: ['touchpoints', 'contact'], isEnable: access?.contacts?.create() },
		{ id: 'Playbooks', actions: ['assignment_rule', 'template', 'playbook'], isEnable: true },
		{ id: 'account_playbooks', actions: ['playbook'], isEnable: playbookRbackScope(["playbook.create:Disabled"]) ? false : true },
		{ id: 'template', actions: ['template'], isEnable: playbookRbackScope(["template:Create"]) ? true : false },
		{ id: 'PlayBooksDashboard', actions: ['playbook','assignment_rule','template'], isEnable: true },
		{ id: 'PlayBooksAssignmentRules', actions: ['assignment_rule'], isEnable: playbookRbackScope(["rule:Create"]) ? true : false },
		{ id: 'playbooks_Summary', actions: ['playbook'], isEnable: playbookRbackScope(["playbook.create:Enabled"]) ? true :  false },
		{ id: 'Inbox', actions: ['email'], action: onComposeMailClick, isEnable: access?.email?.create() },
		{ id: 'Sent', actions: ['email'], action: onComposeMailClick, isEnable: access?.email?.create() },
		{ id: 'user_access', actions: ['user'], action: onAddUserClick, isEnable: true },
		{ id: 'configure_products', actions: ['product'], action: onAddProductClick, isEnable: true },
		{ id: 'account_phases', actions: ['phase'], action: onAddPhaseClick, isEnable: true },
		{ id: 'account_management', actions: ['account'], action: onAddAccountClick, isEnable: true },
		{ id: 'account_categories', actions: ['account'], action: onAddAccountClick, isEnable: true },
		{ id: 'account_access', actions: ['account'], action: onAddAccountClick, isEnable: true },
		{ id: 'renewal_dashboard', actions: ['task','touchpoints'], isEnable: access?.smartTask?.create() },
		{ id: 'renewal_summary', actions: ['task','touchpoints'], isEnable: access?.smartTask?.create() },
		{ id: 'segment_summary', actions: ['accountSegment'], isEnable: access?.skAccountSegments?.view() },
		{ id: 'manage_keywords', actions: ['task'], isEnable: access?.smartTask?.create() },
		{ id: 'team_metrics', actions: ['task'], isEnable: access?.smartTask?.create() },
		{ id: 'Alerts_Dashboard', actions: ['task'], isEnable: access?.smartTask?.create() },
		{ id: 'Alerts_Summary', actions: ['task'], isEnable: access?.smartTask?.create() },	
		{ id: 'my_accounts', actions: ['account'], action: onAddAccountClick, isEnable: HWUser.orgPreType() === "customPlaybookStandalone" && HWUser.permissionLevelAdminOrLeader() },
	]

	const [smartActions, setSmartActions] = useState([]);

	const configureActions = (tab) => {
		let list = [], activeActions = [];
		list = actionConfiguration?.filter(item => item?.id === tab && item?.isEnable);
		activeActions = list[0]?.actions;
		if (activeActions?.length > 0) {
			activeActions?.map(item => {
				let match = options?.filter(option => option?.id === item);
				if (match?.[0]?.isAvailable) {
					list?.push(match?.[0])
				}
			})
			setSmartActions(list)
		} else if (HWUser.roleType() !== "NonAccount") {
			let options = defaultOptions && defaultOptions.filter((i) => { return i?.isAvailable })
			setSmartActions(options)
		}
	}
	useEffect(() => {
		configureActions(props?.selectedTab )
		if (props?.parentTab === 'Account 360') {
			dispatch(fetchTaskProductOptions());
		}
	}, [props?.parentTab, props?.selectedTab,playbookRbacResponse, props?.activeTab])

	const outcomeId = useCallback((id) => {
		let pathname = null
		switch (id) {
			case 'on_boarding':
				pathname = "Onboarding";
				break;
			case 'Renewal':
				pathname = "Renewal";
				break;
			case 'Expansion':
				pathname = "Expansion";
				break;
			default:
				break;
		}
		return pathname
	}, [])
useEffect(()=>{
	var formattedToday = moment().format('YYYY-MM-DD');
	const id = outcomeId(props?.selectedParentTab?.id)

	if(id){
		let data = {
			"taskName":"",
			"taskStatus":"",
			"taskId":"",
			"taskType":"",
			"assigneeUserIds":[],
			"dueDate":formattedToday,
			"customerAccountIds":[],
			"productAllIds":[],
			"collaborators":[],
			"integrationType":"SK_TASK",
			"outcomeId":taskOutcomeOptions && taskOutcomeOptions.filter((i)=>{return (i?.linkedKeyOutcome === id)})?.[0]?.value,
			"attachments":[],
			"productIds":[]
		}
		setCurrentRow(data);
	}else{
		setCurrentRow(null)
	}
},[outcomeId, props?.selectedParentTab?.id, taskOutcomeOptions])

useEffect(()=>{
	const id = outcomeId(props?.selectedParentTab?.id)
	if(id){
		let data = {
			commentCount: null,
			contacts: [],
			appId: HWUser?.appId(),
			userId: HWUser?.userId(),
			customerAccount: null,
			description: "",
			title: "",
			assigneeUserIds: [],
			createdOn: new Date(),
			customerAccountIds: smartFilterTokenService.token?.accountFilter?.accountIds>1?[]:[smartFilterTokenService.token?.accountFilter?.accountIds?.[0]],
			productIds: [],
			collaborators: [],
			outcomeId: taskOutcomeOptions && taskOutcomeOptions.filter((i)=>{return (i.label === id)})?.[0]?.value,
			attachments: [],
		}
		setTouchpointToEdit(data);
	}else{
		setTouchpointToEdit(null)
	}
},[outcomeId, props?.selectedParentTab?.id, taskOutcomeOptions])

	return (
		<>
		<Suspense fallback={<SkLoaderComponent isLoading={true} className="skLoaderComponent" />}>
			{smartActions && smartActions.length > 0 && <div ref={anchorEle} className="plus_icon_wrap">
				<div className={props?.disableActions ? " global-filter-plus_icon-disabled plus_icon" : "plus_icon"} onClick={() => { setShowMenuItems(true) }}></div>
				{showMenuItems &&
					<Popover className="smart__actions__popover" id={"1231324"} open={showMenuItems} anchorEl={anchorEle?.current}
						onClose={() => {
							onClosePopUp();
						}}
						anchorOrigin={{ vertical: 'bottom', horizontal: "right", }}
						transformOrigin={{ vertical: 'top', horizontal: 'right', }}
						PaperProps={{ style: dropdownPaperStyle }}>
						<div className="smart_actions_wrap">
							<div className="smart_actions_create_Btn d-flex  align-items-center justify-content-between">
								<div className="create_text">CREATE</div>
								<div className="smart_actions_create_icon" onClick={() => { setShowMenuItems(false) }} ></div>
							</div>
							<div className="smart_actions_sub_Btn_wrap">
								{smartActions?.map(action => {
									return (<>
										{action?.label ? <SmartActionComponent onActionClick={action?.action} id={action?.id} label={action?.label} iconClassName={action?.iconClassName} /> : <></>}
									</>)
								})}
							</div>
						</div>
					</Popover>
				}
			</div>}
			{openContactPopup && <SkSmartContactPopup isEdit={false} onCancelClick={() => setOpenContactPopup(false)} />}
			{openTaskPopup && <SkSmartTaskPopUp  currentSelectedTaskData={currentRow} productOptions={taskProductOptions} fromAccount360View={props?.parentTab === 'Account 360'} selectedAccount360ViewAccountId={smartFilterTokenService.token?.accountFilter?.accountIds?.[0]} renderActiveTab={"Details"} isEdit={false} openTaskPopup={openTaskPopup} onAddTaskClick={() => setOpenTaskPopup(false)} />}
			{openTouchpointPopup && <SkSmartTouchpointPopup isNewTouchpoint={true}   touchpoint={touchpointToEdit} fromAccount360View={props?.parentTab === 'Account 360'} openTouchpointPopup={openTouchpointPopup} selectedAccount360ViewAccountId={smartFilterTokenService.token?.accountFilter?.accountIds?.[0]} onAddTouchpointClick={onAddTouchpointClick} />}
			{openAssignmentRulePopup && <PlayBookAssignmentRulesPopup isEdit={false} openAssignmentRulePopup={openAssignmentRulePopup} handlePopUpClose={onAddAssignmentRuleClick} />}
			{openMailPopup && <ComposeMail onDiscard={onDiscard} />}
			{openPlaybookPopup && <CreatePlayBookSummaryPopup fromAccount360View={props?.parentTab === 'Account 360'} selectedAccount360ViewAccountId={smartFilterTokenService.token?.accountFilter?.accountIds?.[0]} openPlaybookPopup={openPlaybookPopup} onClosePlayBook={onPlayBookClick} />}
			{openUserPopup && <SkSmartUserPopup onCloseUser={onCloseUser} />}
			{openAccountPopup && <SkSmartAccountPopup onCloseAccount={onCloseAccount} />}
			</Suspense>
		</>
	)
}

export default memo(SmartActions);

const SmartActionComponent = ({ onActionClick, id, label, iconClassName }) => {
	return (
		<div id={id} style={{ cursor: "pointer" }} onClick={(e) => { onActionClick(e) }} className="smart_actions_sub_Btn d-flex align-items-center justify-content-between">
			<div className="sub_text">{label}</div>
			<div className={iconClassName}></div>
		</div>
	)
}