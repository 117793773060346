import React from 'react'
import SKThreeDotMenu from '../SKThreeDotMenu/SKThreeDotMenu';
import ellipsis from "../../KaffeineKarrots/Images/ellipsis.svg";

function SkSmartEditDeleteCommentComponent(props) {
	const customCommentEditDeleteOptionList = () => {
		return ([
			{
				name: 'Edit',
				iconClassName: 'comment__edit__icon',
				onClick: (e) => { 
					e.stopPropagation(); 
					props?.setshowCommentEditBox(true); props?.setShowCommentMenu(false);
					// props?.setSelectedComment(props?.skComment);
				}
			},
			{
				name: 'Delete',
				iconClassName: 'comment__delete__icon', 
				onClick: (e) => {
					e.stopPropagation();
					props?.handleWarningMsgsForPopUp(false,true, 'Are you sure you want to delete this comment?')
				}
			},
		])
	}
	return(
		<div>
		<SKThreeDotMenu dontPreventEventBubbling={false}  isScroll = {props?.isScroll} setPopUpRefNumber={props?.setPopUpRefNumber} popUpRefNumber={props?.popUpRefNumber} className={' smart__comment__three__dot__menu'} placement={'bottom-start'} list={customCommentEditDeleteOptionList()}>
				<div onClick={(e) => {
					// setShowCommentMenu(true); setAnchorElTarget(e?.currentTarget)
				}} className='smart__comments__list__settings__icon__container'>
					<img src={ellipsis} className='smart__comments__list__settings__icon' alt='settings_icon_comments' />
					</div>
			</SKThreeDotMenu>
		</div>
	
	)
}
export default SkSmartEditDeleteCommentComponent