import { ClickAwayListener, Popover } from "@material-ui/core";
import React, {  useRef, useState } from "react";
import SkRadioBtn from "../SkSmartUtil/SkRadioBtn/SkRadioBtn";
import SKSmartUiBtns from "../SkSmartUtil/SKSmartUiBtns/SKSmartUiBtns";

function SmartShare (props){
    const [showMenuItems, setShowMenuItems] = useState(false);
    const [shareMenuType,setShareMenuType] = useState("Download");
		const [formate ,setformate] = useState("pdf")
    const anchorEle = useRef();

    const onClosePopUp = () => {
        setShowMenuItems(false);
    }
    const dropdownPaperStyle = {
        width: '275px',
         background: "#FFFFFF 0% 0% no-repeat padding-box",
        boxShadow: "0px 3px 6px #00000029", 
        opacity: 1, marginTop: "-15px", 
        // marginLeft: "-60px",
        border:"1px solid var(--sk-smart-datepicker-border-color)",
        borderRadius: "0px 0px 13px 13px",
    }

    const onApply = () =>{
        setShowMenuItems(false);
    }

    const handleShareType = (data)=>{
        setShareMenuType(data);
    }
    const onValueSelect =(value)=>{
			setformate(value)
    }
    const exortData = ()=> {
			if(props.showXL){
				props.exortData && props.exortData(formate)
			}
			else{
				props.exortData && props.exortData();
			}
        onClosePopUp();
    }
    const btns = {
        primaryBtnLabel:"EXPORT",
        secondaryBtnLabel:"CANCEL",
        primaryBtnAction:exortData ,
        secondaryBtnAction:onClosePopUp,
        className:"share_filter_apply_btn_container"
        // className:"globalfilter_appy_btn_container"
    }
    return(
        <div ref={anchorEle}>
            <div onClick={()=>{setShowMenuItems(true)}}  className={`${props?.disableGlobalShare?"pointer-events-none":""} share_icon`}></div>
            {showMenuItems && 
                    <ClickAwayListener onClickAway={() => { setShowMenuItems(false); }}>
                        <Popover id={"1231324"} open={showMenuItems} anchorEl={anchorEle?.current}
													
                            onClose={() => {onClosePopUp();}}
                            anchorOrigin={{ vertical: 'bottom', horizontal: "right", }}
                            transformOrigin={{ vertical: 'top', horizontal: 'right', }}
                            PaperProps={{ style: dropdownPaperStyle }}>
                            {props.exportXL?
                            <>
                                <div className="share-filter-dropdown-container">
                                    <div className="">
                                      <div className="share-filter-dropdown-header-label">{"Export XL and send as mail."}</div> 
                                    </div>
                                    <div>
                                        <SKSmartUiBtns  {...btns}/>
                                    </div>
                                </div>
                            </>
                            :<div className="filter-dropdown-container">
                                <div className="share-filter-dropdown-container">
                                    <div className="share-filter-dropdown-header-label">Share</div>
																		 <div>
                                        <SKSmartUiBtns  {...btns}/>
                                    </div>
                                </div>

                                <div className="filter-share-menu-items-wrap">
                                   { !props?.noButtons && <div className="d-flex justify-content-between  align-items-center">
                                        <div>
                                            <SkRadioBtn value='Download'  checked={shareMenuType === "Download" ? true : false} label="Download" onChange={(e) => { handleShareType(e); }} />
                                        </div>
                                        <div>
                                            <SkRadioBtn value='Email'  checked={shareMenuType === "Email" ? true : false} label="Email" onChange={(e) => { handleShareType(e); }} />
                                        </div>
                                        <div>
                                            <SkRadioBtn value='Print'  checked={shareMenuType === "Print" ? true : false} label="Print" onChange={(e) => { handleShareType(e); }} />
                                        </div>
                                    </div>}
                                    <div className="filter-share-menu-items-lists">
                                        <div  className={`${formate === "pdf"?"filter-share-menu-items-checkmark-container-selected ":""} filter-share-menu-items-lists-dropdown-option `}  onClick={() => { onValueSelect("pdf"); }}>
                                            {formate === "pdf" && <div className={"filter-share-menu-items-checkmark-container-selected-tick"}></div>}
                                            <div className="filter-share-menu-items-lists-dropdown-option-lable" >{"Adobe Acrobat(.pdf)"}</div>
                                        </div>
                                        {!props?.showXL && <div  className={`${formate === "pptx"?"filter-share-menu-items-checkmark-container-selected ":""} filter-share-menu-items-lists-dropdown-option `} onClick={() => { onValueSelect("pptx"); }}>
																						{formate === "pptx" && <div className={"filter-share-menu-items-checkmark-container-selected-tick"} onClick={() => { onValueSelect("pptx"); }}></div>}
                                            <div className="filter-share-menu-items-lists-dropdown-option-lable" >{"Microsoft PowerPoint(.pptx)"}</div>
                                        </div>}
																				{props?.showXL && <div  className={`${formate === "xlsx"?"filter-share-menu-items-checkmark-container-selected ":""} filter-share-menu-items-lists-dropdown-option `} onClick={() => { onValueSelect("xlsx"); }}>
																						{formate === "xlsx" && <div className={"filter-share-menu-items-checkmark-container-selected-tick"} onClick={() => { onValueSelect("xlsx"); }}></div>}
                                            <div className="filter-share-menu-items-lists-dropdown-option-lable" >{"Microsoft Excel(.xlsx)"}</div>
                                        </div>}
																				
                                    </div>
                                </div>

                            </div>
														}
                            </Popover>
                    </ClickAwayListener>}
        </div>
    )
}
export default SmartShare