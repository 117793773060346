import React, { Component } from 'react';
import cross from "../../assets/images/settings/cross.png"
import contact from "../../assets/images/icons/contacts.svg"
import account from "../../assets/images/icons/accounts.svg"
import Select from 'react-select';
import ReactDOM from 'react-dom';
import BlockUi from 'react-block-ui';
import defaultUser from '../../assets/images/new-contact.svg'
import HWUser from '../../HWLibrary/HWUser';
import constactServices from '../../views/Settings/SettingsChildren/Contacts/ContactServices';
import HWImageTag from '../../HWLibrary/HWImageTag';
import UiLabel from '../UiLabel/UiLabel';
import Localizer from '../SkLocalizer/Localizer';
import intelligenceAPIService from '../../Intelligence/IntelligenceAPIService';

class MapAccountPopup extends Component {

    constructor(props) {

        super(props);
        const customerAccountOptions = (this.props.accountMapingData || []).map(account => { return { value: account.accountId, label: account.accountName } });
        const emailThreadContacts = (this.props.emailContacts || []).map(contact => { return { value: contact.emailId ? contact.emailId : contact.email, label: contact.emailId ? contact.emailId : contact.email, selectedOption: null, selectedAccountId: null, email: contact.email, emailId: contact.emailId ? contact.emailId : contact.email, imageURL: contact.imageURL, firstName: contact.firstName, lastName: contact.lastName, name: contact.name } });
        this.state = { selectedAccountId: customerAccountOptions[0], dropdownFieldNode: '', customerAccountOptions: customerAccountOptions, emailThreadContacts: emailThreadContacts, isUpdatingMappingDate: false }
    }
    stopeEvent = (event) => { event.stopPropagation(); }

    componentDidMount() {
        const node = ReactDOM.findDOMNode(this).parentNode;
        this.setState({ dropdownFieldNode: node, isUpdatingMappingDate: false });
    }

    render() {
        let isContactPresent= this.props.callingFrom == "Konversations" ? false : true; 
        if(this.props.callingFrom === "Tickets"){
            isContactPresent = this.props.emailThreadContacts &&  this.props.emailThreadContacts.length>0 &&  this.props.emailThreadContacts[0].emailId?true:false;
        }
        const data = "";
        let itemComponent = "";
        switch (this.props.callingFrom || "-") {
            case "Tickets": itemComponent =
            <>
                <thead style={{ zIndex: 2 }}>
                    <tr >
                       {isContactPresent && <th><img src={contact} /> <span><Localizer txtKey="pullover:tbl_head_contact" /></span></th>}
                        <th><img src={account} /><span> <Localizer txtKey="pullover:tbl_head_accounts" /></span></th>
                    </tr>
                </thead>
             <tbody style={{ zIndex: 9, display: "block" }} onScroll={this.stopeEvent}><TicketContactComponent {...this.state} actions={this.actions} selectOptionsStyle={this.selectOptionsStyle} /></tbody>
            </>; break;
            case "ContactPopOver": itemComponent = 
            <>
            <thead style={{ zIndex: 2 }}>
                    <tr >
                        <th><img src={contact} alt="" /> <span><Localizer txtKey="pullover:tbl_head_contact" /></span></th>
                        <th><img src={account} alt="" /><span> <Localizer txtKey="pullover:tbl_head_accounts" /></span></th>
                    </tr>
            </thead>
            <tbody style={{ zIndex: 9 }} className="contactTable" onScroll={this.stopeEvent}> <ContactComponent {...this.state} actions={this.actions} {...this.props} onAccountChange={this.onAccountChange} selectOptionsStyle={this.selectOptionsStyle} /> </tbody>
            </>; break;
             case "Konversations": itemComponent =
             <>
             <thead style={{ zIndex: 2 }}>
                     <tr >
                         <th><img src={account} alt="" /><span> <Localizer txtKey="pullover:tbl_head_accounts" /></span></th>
                     </tr>
             </thead>
             <tbody style={{ zIndex: 9 ,display:"block"}} onScroll={this.stopeEvent}> <KonversationComponent {...this.state} actions={this.actions} {...this.props} onAccountChange={this.onAccountChange} selectOptionsStyle={this.selectOptionsStyle} /> </tbody>
             </>;break;
            default: itemComponent = 
            <>
            <thead style={{ zIndex: 2 }}>
                    <tr >
                        <th  style={{width:"150px"}}><img src={contact} /> <span><Localizer txtKey="pullover:tbl_head_contact" /></span></th>
                        <th  style={{width:"45%"}}><img src={account} /><span> <Localizer txtKey="pullover:tbl_head_accounts" /></span></th>
                        <th><img src={account} /><span> <Localizer txtKey="pullover:tbl_head_product" /></span></th>
                    </tr>
            </thead>
            <tbody style={{ zIndex: 9, display: "block" }} onScroll={this.stopeEvent}><MailComponent {...this.state} actions={this.actions} selectOptionsStyle={this.selectOptionsStyle} /></tbody>
            </>; break;
        }
        return (
            <div onClick={(e) => { this.stopeEvent(e) }} className={data === false ? "modal fade map_account_popup " : "modal map_account_popup_show show fade map_account_popup"} tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
                <div style={{top:(this.props.callingFrom === "EventPopup")?"-110px":"",maxWidth:(this.props.callingFrom === "listcellMappingPopup" || this.props.callingFrom === "MeetingHour")?"600px":""}} className="modal-dialog modal-dialog-centered" role="document">
                    <div className="modal-content" style={{ borderRadius: "0px" }}>
                        <BlockUi style={{ width: "100%" }} blocking={this.state.isUpdatingMappingDate} >
                            <div className="modal-body" style={{ padding: "15px 20px" }}>
                                <div className="row map_account_header _addnewapp d-flex justify-content-between" >
                                    <p className="header_heading addnewpopupP"><Localizer txtKey="pullover:txt_map_account" /></p>
                                    <div className="pop_up_rightnewapp">
                                        <img onClick={this.props.closeMapAccount} className="close_btn_pop close_modle" src={cross} alt="" />
                                    </div>
                                </div>
                                <div className="map_account_header_2">
                                {this.props.callingFrom == "Konversations" ? <p>Map Konversation to a desired Account from the list below</p>:<p><Localizer txtKey = "pullover:txt_map_contact" /></p>}
                                </div>
                                <div className="map_account_contact_list_wrapper">
                                    <div className="row contactRow" style={{ margin: '0px', marginBottom: "-75px", minHeight: this.props.callingFrom === "ContactPopOver" ? "202px" : "300px" }}>
                                        <table className="table map_account_contact_list_table">
                                            {/* <thead style={{ zIndex: 2 }}>
                                                <tr >
                                                    <th><img src={contact} /> <span>Contact</span></th>
                                                    <th><img src={account} /><span> Accounts</span></th>
                                                </tr>
                                            </thead> */}
                                            {itemComponent}
                                        </table>
                                    </div>
                                </div>
                                {this.state.emailThreadContacts && this.state.emailThreadContacts[0] && this.state.emailThreadContacts[0].emailId === "undefined <undefined>" ? "" : <footer className="modelFooter">
                                    <div className="d-flex w-100 mail_footer_wrapper">
                                        <div onClick={this.props.closeMapAccount}>
                                            <button className="cancelBtn" data-dismiss="modal"><Localizer txtKey="pullover:btn_cancel" /></button>
                                        </div>
                                        <div>
                                            <input className="saveBtn" type="button" value="Save" onClick={this.props.callingFrom === "ContactPopOver" ? this.saveAccountToContactMapping : (isContactPresent )? this.onSaveMapping:this.onSaveAccountmapping} />
                                        </div>
                                    </div>
                                </footer>
                                }
                            </div>
                        </BlockUi>
                    </div>
                </div>
            </div>
        )
    }
    actions = {
        onChangeSelectionFor: (contact, mappedAccount) => {
            let contactDomain = "", selectEmailDomain = "";
            let str = contact.emailId.substring(contact.emailId.indexOf("@") + 1);
            selectEmailDomain = str.replace(/>/g, "")
            let emailThreadContacts = (this.state.emailThreadContacts || []).map(i => {
                contactDomain = i.emailId.substring(i.emailId.indexOf("@") + 1);
                contactDomain = contactDomain.replace(/>/g, "");
                if (selectEmailDomain === contactDomain) {
                    i.selectedAccountId = (mappedAccount && mappedAccount.value) || null;
                    i.selectedProductId = [];
                }
                return i;
            }) || [];
            this.setState({ emailThreadContacts: emailThreadContacts });
        },
        onChangeAccount:(mappedAccount)=>{
            this.setState({ selecedCustomerAccountId: mappedAccount && mappedAccount.value?mappedAccount.value:null });
        },
        onChangeProductSection: (contact, mappedProduct) => {
            let contactDomain = "", selectEmailDomain = "";
            let str = contact.emailId.substring(contact.emailId.indexOf("@") + 1);
            selectEmailDomain = str.replace(/>/g, "")
            let emailThreadContacts = (this.state.emailThreadContacts || []).map(i => {
                contactDomain = i.emailId.substring(i.emailId.indexOf("@") + 1);
                contactDomain = contactDomain.replace(/>/g, "");
                if (selectEmailDomain === contactDomain) {
                    i.selectedProductId = (mappedProduct) || [];
                }
                return i;
            }) || [];
            this.setState({ emailThreadContacts: emailThreadContacts });
        },
    }

    selectOptionsStyle = {
        container: (provided, state) => ({
            ...provided,
            width: '95%',
            height: '34px',
            outlineWidth: '0px',
            fontSize: '12px',
            cursor: 'pointer',
            marginRight: '10px'
        }),
        indicatorsContainer: (provided, state) => ({
            ...provided,
            height: '30px'
        }),
        indicatorSeparator: (provided, state) => ({
            ...provided,
            width: "0px"
        }),
        control: (provided, state) => ({
            ...provided,
            height: '34px',
            borderRadius: '0px !important',
            minHeight: '34px',
            boxShadow: 'none',
            cursor: 'pointer'
        }),
        placeholder: (provided, state) => ({
            ...provided,
            marginBottom: '0px',
            color: '#212529',
            fontSize: '12px',
            cursor: 'pointer',
            fontWeight: '500',
        }),
        menuList: (provided, state) => ({
            ...provided,
            overflowX: "hidden",
            paddingBottom: "0px",
            paddingTop: "0px",
            zIndex: "99999",
            color: "#000",
            fontWeight: "400",
            fontFamily: "Urbanist",
            fontSize: "12px",
            maxHeight: "200px"
            // height: '150px',
            // height:"100px"
        }),
        menu: (provided, state) => ({
            ...provided,
            // height: '150px',
            zIndex: "99999",
        }),
        singleValue: (provided, state) => ({
            ...provided,
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            width: 'auto',
            textOverflow: 'ellipsis',
            fontWeight: '500',
            fontSize: '12px',
        }),
        option: (styles, { data, isDisabled, isFocused, isSelected }) => {
            const color = '#f6f6f6';
            return {
                ...styles,
                backgroundColor: isDisabled
                    ? null
                    : isSelected
                        ? "#f6f6f6"
                        : isFocused
                            ? "#f6f6f6"
                            : null,
                ':active': {
                    ...styles[':active'],
                    backgroundColor: isSelected ? color : color,
                },
                // borderBottom: '1px solid #ddd',
                paddingTop: '10px',
                paddingLeft: '10px',
                paddingRight: '10px',
                paddingBottom: '3px',
                width: '200px',
                color: 'black',
            };
        },
    }


    onAccountChange = (accountSelected) => { this.setState({ selectedAccountId: accountSelected }) }


    onSaveMapping = () => {
        let mappingRequest = (this.state.emailThreadContacts || []).map(contact => {
            return { 
                contactId: contact.contactId, 
                emailId: (contact.contactId) ? null : contact.emailId, 
                accountId: contact.selectedAccountId, name: contact.name,
                productIds:contact.selectedProductId?contact.selectedProductId.map((i)=>{
                    return i?.value
                }):[]
            }
        })
        this.setState({ isUpdatingMappingDate: true }, () => {
            constactServices.saveContactAccountMapping(mappingRequest).then((onSuccess) => {
                this.setState({ isUpdatingMappingDate: false }, () => { this.props.closeMapAccount(true); })
            }).catch(error => {
							//  console.log(error); 
							 this.setState({ isUpdatingMappingDate: false }); });
        })
    }
    onSaveAccountmapping=()=>{
        this.setState({ isUpdatingMappingDate: true }, () => {
            if (this.props.callingFrom == "Konversations") {
                intelligenceAPIService.updateSmartKonAccTaging(this.state.selectedAccountId.value,this.props.selectedZoomMeetingId).then((onSuccess)=> { 
                    this.setState({ isUpdatingMappingDate: false},() => {this.props.closeMapAccount(true);})
                }).catch(error => {
									// console.log(error); 
									this.setState({ isUpdatingMappingDate: false }); });
            }
            else {
            constactServices.saveAccountMapping(this.state.selecedCustomerAccountId,this.props.ticektIdToMapAccount).then((onSuccess) => {
                this.setState({ isUpdatingMappingDate: false }, () => { this.props.closeMapAccount(true); })
            }).catch(error => { 
							// console.log(error); 
							this.setState({ isUpdatingMappingDate: false }); });
        }
        })
    }

    saveAccountToContactMapping = () => {
        let data = this.props.eachContact
        const request = { contactId: data.contactId, accountId: this.state.selectedAccountId.value, editMode: "MAP_CONTACT", appId: HWUser.appId() }
        constactServices.mapUnMapContacts(request).then(response => { if (response.data.message === "Success") { this.props.closeMapAccount(); this.props.fetchContactAndAccountDetails() } })
    }
}

class MailComponent extends Component {
    parseProcuctOptions = (data) => {
        // let filters = portFolioService?.productIdsLists?portFolioService?.productIdsLists:[]
				let filters = []
        if (data) {
            filters = filters.filter(({ productId: id1 }) => data.some(id2 => id2 === id1));
        }
        return (filters || []).map((item, index) => {
            return { value: item.productId, valid: true, label: item.productName }
        })
    }
    sortOpt = (Opt) => {
        let sortedProductList = Opt && Opt.sort((a, b) => {
            return ((a.label) || " ").localeCompare(((b.label) || " "));
        })
        return sortedProductList;
    }
    render() {
        return (
            <>
                {(this.props.emailThreadContacts || []).map((contact, index) => {
                    let contactEmailId = (contact.contactId) ? `${contact.firstName} ${contact.lastName} <${contact.email}>` : contact.emailId ? contact.emailId : contact.email;
                    let value = this.props.customerAccountOptions.filter(i => i.value === contact.selectedAccountId)[0];
                    let productOptions = [];
                    if(value &&  value?.value){
                        // let referenceAccountList = portFolioService?.customerAccountLists?portFolioService?.customerAccountLists:[]
												let referenceAccountList = []
                        let productIds = referenceAccountList && referenceAccountList.filter((i)=>i.accountId === value?.value)[0];
                        productOptions = this.parseProcuctOptions(productIds && productIds.productIds?productIds.productIds:null);
                    }
                    productOptions = this.sortOpt(productOptions); 
                    let producuctValue = contact.selectedProductId?contact.selectedProductId:[];
                    return (
                        <tr key={"mailComponent_"+index}>
                            <td style={{width:"150px"}}>
                                <span>
                                 {/* {contactEmailId} */}
                                 <UiLabel length={10} placement="top" tooltipClass="HWSelectMultiSelectTooltip" label={contactEmailId}  ></UiLabel>
                                 </span> 
                            </td>
                            <td style={{width:"45%"}}><Select value={value} menuPortalTarget={document.body} captureMenuScroll={false} menuShouldScrollIntoView={false} onChange={(mappedAccount) => { this.props.actions.onChangeSelectionFor(contact, mappedAccount); }} menuPlacement="top" theme={theme => ({ ...theme, borderRadius: 0 })} styles={this.selectOptionsStyle} isClearable className={((this.props.emailThreadContacts.length - 1) === index) ? " task-details-popup-header-select map-account-popupselect-lastoption" : "task-details-popup-header-select map-account-popupselect"} placeholder="Accounts" isSearchable={true} options={this.props.customerAccountOptions} /></td>
                            <td>
                                <Select 
                                styles={this.styleMultiple}
                                menuPortalTarget={document.body}
                                isMulti
                                menuPlacement="top"
                                // menuIsOpen={true}
                                theme={theme => ({ ...theme, borderRadius: 0 })} 
                                placeholder="Products" options={productOptions}
                                value={producuctValue}
                                isClearable={false}
                                onChange={(mappedProduct) => { this.props.actions.onChangeProductSection(contact, mappedProduct); }}
                            /> 
                            </td>
                        </tr>)
                })}
            </>
        )
    }

    styleMultiple = {
        control:(provided, state) => ({
            ...provided,
            // height:'34px',
            borderRadius:'0px',
            minHeight:'34px',
            boxShadow: 'none',
            cursor:'pointer',
            backgroundColor: "white",
            // border: "1px solid #e5e5e8",
            border: state.selectProps.isViewOnly ? "none": "1px solid #e5e5e8",
          }),
        container: (provided, state) => ({
            ...provided,
            borderRadius: "0px",
            fontSize: '12px',
            minHeight:'34px',
            marginLeft: '-1px',
            // width:"370px"
            
        }),
        indicatorSeparator: (provided, state) => ({
            ...provided,
            width: "0px",
        }),
        singleValue: (provided, state) => ({
            ...provided,
            // width: "68%",
            backgroundColor: '#fad192',
            lineHeight: '31px'
        }),
        multiValue: (provided, state) => {
            return ({
                ...provided,
                backgroundColor: "#fad192",
                borderRadius: '4px',
                lineHeight: '18px',
                padding: '2px 4px',
                alignItems: 'center'
            })
        },
        indicatorsContainer: (provided, state) => ({
            ...provided,
            borderRadius: "0px",
        }),
        multiValueLabel: (provided, state) => ({
            ...provided,
            fontSize: '12px',
            fontFamily: "Urbanist",
            color: '#444444',
            fontWeight: '400',
            whiteSpace: "normal",
            textAlign: 'left',
            padding: '2px'
        }),
        multiValueRemove: (base, state) => {
            return ({
                ...base,
                opacity: 1,
                width: "20px",
                color: "#000000 !important",
                backgroundColor: "#fad192",
                borderRadius: 0,
                "&:hover": {
                    backgroundColor: "#fad192",
                    color: "#000000"
                },
            })
        },
        placeholder: (provided, state) => ({
            ...provided,
            fontSize:'12px',
            fontWeight:'400',
            fontFamily:"Urbanist",
            color:'#59686b',
            width:"100%"
        }),
        valueContainer: (provided, state) => ({
            ...provided,
            position: "none",
            maxWidth: "90%",
            cursor: 'pointer',
            borderRadius: "5px",
            overflowY: 'auto !important',
            maxHeight: '116px'
        }),
        option: (styles, { isDisabled, isFocused, isSelected }) => {
             return { ...styles, 
                fontWeight: "400",
                backgroundColor: isDisabled ? null : isSelected ? "#dddddd" : null, ':hover': { ...styles[':active'], backgroundColor: "#dddddd" },cursor: isDisabled ? 'not-allowed' : 'default', color: 'black' }; 
        },
        menuPortal: base => ({ ...base, zIndex: 9999 }),
        menu: (provided, state) => ({ ...provided, width: "99.5%", borderRadius: '0px', marginTop: '0px',zIndex: 9999 }),
        menuList: (provided, state) => ({ ...provided, overflowX: "hidden", paddingBottom: '0px',borderRadius: '0px', paddingTop: '0px', textAlign: "left", maxHeight: '145px',zIndex: "999" })
    };

    selectOptionsStyle = {
        container: (provided, state) => ({
            ...provided,
            width: '95%',
            height: '34px',
            outlineWidth: '0px',
            fontSize: '12px',
            cursor: 'pointer',
            marginRight: '10px'
        }),
        indicatorsContainer: (provided, state) => ({
            ...provided,
            height: '30px'
        }),
        indicatorSeparator: (provided, state) => ({
            ...provided,
            width: "0px"
        }),
        control: (provided, state) => ({
            ...provided,
            height: '34px',
            borderRadius: '0px !important',
            minHeight: '34px',
            boxShadow: 'none',
            cursor: 'pointer'
        }),
        placeholder: (provided, state) => ({
            ...provided,
            marginBottom: '0px',
            color: '#212529',
            fontSize: '12px',
            cursor: 'pointer',
            fontWeight: '500',
        }),
        menuPortal: base => ({ ...base, zIndex: 9999 }),
        menu: provided => ({ ...provided, zIndex: "9999 !important" }),
        // menu: (provided, state) => ({
        //     ...provided,
        //     position: 'flxed',
        //     width:'auto'
        // }),
        menuList: (provided, state) => ({
            ...provided,
            overflowX: "hidden",
            paddingBottom: "0px",
            paddingTop: "0px",
            zIndex: "999",
            color: "#000",
            fontWeight: "400",
            fontFamily: "Urbanist",
            fontSize: "12px",
            maxHeight: "200px"
        }),
        singleValue: (provided, state) => ({
            ...provided,
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            width: 'auto',
            textOverflow: 'ellipsis',
            fontWeight: '500',
            fontSize: '12px',
        }),
        option: (styles, { data, isDisabled, isFocused, isSelected }) => {
            const color = '#f6f6f6';
            return {
                ...styles,
                backgroundColor: isDisabled
                    ? null
                    : isSelected
                        ? "#f6f6f6"
                        : isFocused
                            ? "#f6f6f6"
                            : null,
                ':active': {
                    ...styles[':active'],
                    backgroundColor: isSelected ? color : color,
                },
                // borderBottom: '1px solid #ddd',
                paddingTop: '10px',
                paddingLeft: '10px',
                paddingRight: '10px',
                paddingBottom: '3px',
                width: '200px',
                color: 'black',
            };
        },
    }
}

class TicketContactComponent extends Component {
    
    render() {
        return (
            <>
                {(this.props.emailThreadContacts || []).map((contact, index) => {
                    let value = this.props.customerAccountOptions.filter(i => i.value === contact.selectedAccountId)[0];
                    return (
                        <tr style={{ position: "absolute" }} key={"ticketContactComponent_"+index}>
                            {
                                contact.emailId === undefined ? 
                                // <p style={{padding:"20px 0px"}}>No Contacts found</p>
                                <>
                                    <td>
                                        <div style={{width:"200px"}}>  
                                            <Select value={value} style={{ minHeight: "10px" }} captureMenuScroll={false} menuShouldScrollIntoView={false} onChange={(mappedAccount) => { this.props.actions.onChangeAccount(mappedAccount); }} 
                                            menuPlacement="bottom" theme={theme => ({ ...theme, borderRadius: 0 })} styles={this.props.selectOptionsStyle} isClearable className="task-details-popup-header-select map-account-popupselect" placeholder="Accounts" 
                                            isSearchable={true} options={this.props.customerAccountOptions} />
                                            

                                        </div>
                                      
                                    </td>
                                </>
                                    :
                                    <>
                                        <td>
                                            {/* <img src={contact.imageURL || defaultUser} alt="" style={{ borderRadius: "11px" }} /> */}
                                            <HWImageTag src={contact.imageURL || defaultUser} alt="" style={{ borderRadius: "11px" }} />
                                            <span> {contact.firstName + " " + contact.lastName}</span> </td>
                                        <td>
                                            <Select value={value} style={{ minHeight: "10px" }} captureMenuScroll={false} menuShouldScrollIntoView={false} onChange={(mappedAccount) => { this.props.actions.onChangeSelectionFor(contact, mappedAccount); }} 
                                                menuPlacement="bottom" theme={theme => ({ ...theme, borderRadius: 0 })} styles={this.props.selectOptionsStyle} isClearable className="task-details-popup-header-select map-account-popupselect" placeholder="Accounts" 
                                                isSearchable={true} options={this.props.customerAccountOptions} />
                                        </td>
                                    </>
                            }
                        </tr>
                    )
                })}
            </>
        )
    }
}


class ContactComponent extends Component {
    render() {
        let contact = this.props && this.props.eachContact
        return (
            <tr>
                <td><img src={contact && contact.uploadedImageUrl ? contact.uploadedImageUrl : defaultUser} alt="" /><span> {contact.firstName + " " + contact.lastName}</span> </td>
                <td><Select defaultValue={this.props.selectedsAccountId} style={{ minHeight: "10px" }} captureMenuScroll={false} menuShouldScrollIntoView={false} onChange={(mappedAccount) => { this.props && this.props.onAccountChange(mappedAccount) }} menuPlacement="top" theme={theme => ({ ...theme, borderRadius: 0 })} styles={this.props.selectOptionsStyle} isClearable className="task-details-popup-header-select map-account-popupselect" placeholder="Accounts" isSearchable={true} options={this.props.customerAccountOptions} /></td>
            </tr>
        )
    }
}
class KonversationComponent extends Component {
    render() {
       
        return (
            <tr style={{ position: "absolute" }}>
                <td>
                <div style={{width:"200px"}}>  
                    <Select defaultValue={this.props.selectedsAccountId} style={{ minHeight: "10px" }} captureMenuScroll={false} menuShouldScrollIntoView={false} onChange={(mappedAccount) => { this.props && this.props.onAccountChange(mappedAccount) }} menuPlacement="bottom" theme={theme => ({ ...theme, borderRadius: 0 })} styles={this.props.selectOptionsStyle} isClearable className="task-details-popup-header-select map-account-popupselect" placeholder="Accounts" isSearchable={true} options={this.props.customerAccountOptions} />
                    </div>
                    </td>
               
            </tr>
        )
    }
}

export default MapAccountPopup;