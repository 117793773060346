import React, { useEffect } from "react";
import VideoJS from './VideoJS' // point to where the functional component is stored


export const SmartVideoPlayer = ({ onPlayerTimeUpdate, currentSeekTime, isTransApiLoading, onReady, videoUrl, ...props }) => {
	const playerRef = React.useRef(null);
	// const [refreshKey, setRefreshKey] = useState(new Date().getTime()+"");

	//const playURL = "https://us02web.zoom.us/rec/download/BBe7vUA8cGkRZBZ_ntFFeztaqDGhieVSqWnPBVI6f53he5Vc5haiAnebq8THdBrCsg0s7GymYAwiKyyz.a3LCb1GIuqAT2zit?access_token=eyJhbGciOiJIUzI1NiJ9.eyJhdWQiOm51bGwsImlzcyI6InVEYXV0Nm8zUXdtWGx6VkNIUjlTX3ciLCJleHAiOjE2OTMwNTQ1MDAsImlhdCI6MTYyOTk3NzE0M30.GQfV9k2GrAKaJXNRzVk1F0_sdiDGNWlFanz5Ctj8kiU"
	const playURL = videoUrl;
	const videoJsOptions = { // lookup the options in the docs for more options
		autoplay: false,
		controls: true,
		playbackRates: [0.5, 1, 1.5, 2],
		responsive: true,
		fluid: true,
		sources: [{ src: playURL, type: 'video/mp4' }]
	}

	const seekToTime = (timeInSec) => {

		if (timeInSec) {
			playerRef.current.currentTime(timeInSec);
			playerRef.current.play();

		}
	}

	useEffect(() => {
		if (currentSeekTime) {
			seekToTime(currentSeekTime)
		}
	
	}, [currentSeekTime]);

	const handlePlayerReady = (player) => {
		onReady(player);
		playerRef.current = player;
		player.on('waiting', () => {
			onReady(player);
			
		});
		// player.on('timeupdate', () => { onPlayerTimeUpdate(player.currentTime()) });
		// player.on('seeked',     () => { onPlayerTimeUpdate(player.currentTime()) });
		player.on('dispose', () => {
			// console.log('player will dispose');
		});
	};

	// const onVisibilityChange = (isVisible) => {
	// 	if (!isVisible) { return; }
	// 		setRefreshKey(new Date().getTime()+"");
	// }

	// const changePlayerOptions = () => {
	//   // you can update the player through the player instance
	//   if (!playerRef.current) {
	//     return;
	//   }
	//   // [update player through instance's api]
	//   playerRef.current.src([{src: 'http://ex.com/video.mp4', type: 'video/mp4'}]);
	//   playerRef.current.autoplay(false);
	// };

	return (
		<div style={{ height: "100%", width: "100%" }}>

			{/* <div style={{height: "23.75rem", width: "400px"}}> */}
			<VideoJS options={videoJsOptions} isTransApiLoading={isTransApiLoading} onReady={handlePlayerReady} />
			{/* </div> */}
		</div>
	);
}