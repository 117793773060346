/* eslint-disable jsx-a11y/alt-text */
import React, { Component } from 'react';
import defaultPlaceholder from "../assets/images/new-contact.svg";
import PropTypes from 'prop-types';

class HWImageTag extends Component {

    constructor(props) {
        super();
        this.state = { src: props.src, isErrorOnLoad: false, isLoaded: false }
    }

    componentWillReceiveProps(newProps) {
        if (this.state.src !== newProps.src) {
            this.setState({ src: newProps.src, isErrorOnLoad: false });
        }
    }

    render() {
        let data = this.parseProps();
        return (<>
                <img className={data.className} style={data.style} src={data.src}/>
                <img style={{width:0, height:0, overflow:"hidden", display:"none", position:"fixed" }} onLoad={this.onLoadedImage} onError={this.onImageLoadError} src={data.loadSrc}/>
                </>
        )
    }

    onLoadedImage = () => {
        if (this.state.isLoaded === false) { this.setState({isLoaded: true}); }
    }

    parseProps = () => {
        let src         = this.state.src || "https://"; // onImageLoadError will not be called if src is undefined or null.
        let placeholder = this.props.placeholder || defaultPlaceholder;
        let style       = this.props.style || {};
        const loadSrc   = this.state.src || "https://"; // onImageLoadError will not be called if src is undefined or null.
        if (this.props.width)  { style.width = this.props.width }
        if (this.props.height) { style.height = this.props.height }
        if(this.props.borderRadius) { style.borderRadius = this.props.borderRadius }
        if (this.state.isErrorOnLoad || !this.state.isLoaded)  { src = placeholder; }
        return { loadSrc: loadSrc, src, style, alt:"", className: this.props.className }
    }

    onImageLoadError = () => { this.setState({ isErrorOnLoad: true }); }
}

HWImageTag.propTypes = {
    src: PropTypes.any,
    placeholder: PropTypes.any,
    styles: PropTypes.object,
    width: PropTypes.any,
    height: PropTypes.any
}

export default HWImageTag;