import axios from 'axios';
import hwAxios from '../HWLibrary/HWAxios';
import HWUser from '../HWLibrary/HWUser';


class IntelligenceAPIService {
	constructor() {
		if (!IntelligenceAPIService.instance) {
			IntelligenceAPIService.instance = this;
		}
		return IntelligenceAPIService.instance;
	}

	fetchCallIntData = (startDate, endDate, sortKey, sortOrder) => {
		let url = window.resolveRegion(process.env.REACT_APP_SMART_DASHBOARDS_BASE_API_URL) + "/smart-konversation/call-intelligence";
		let aConfig = { headers: { "addFilter": true, "Authorization": HWUser.accessToken(), "Content-Type": "application/json" } };
		aConfig.params = {
			userId: HWUser.userId(),
			orgId: HWUser.orgId(),
			appId: HWUser.appId(),
			startDate: startDate ? startDate : null,
			endDate: endDate ? endDate : null,
			sortKey: sortKey ? sortKey : null,
			sortOrder: sortOrder ? sortOrder : null
		};
		return hwAxios.get(url, aConfig);
	}

	fetchKeywordCloudData = (startDate, endDate) => {
		let url = window.resolveRegion(process.env.REACT_APP_SMART_DASHBOARDS_BASE_API_URL) + "/smart-konversation/call-intelligence/keyword-cloud";
		let aConfig = { headers: { "addFilter": true, "Authorization": HWUser.accessToken(), "Content-Type": "application/json" } };
		aConfig.params = {
			userId: HWUser.userId(),
			orgId: HWUser.orgId(),
			appId: HWUser.appId(),
			startDate: startDate ? startDate : null,
			endDate: endDate ? endDate : null
		};
		return hwAxios.get(url, aConfig);
	}

	sendMail(feature) {
		let url = window.resolveRegion(process.env.REACT_APP_SMART_DASHBOARDS_BASE_API_URL) + "/premium/notification";
		let aConfig = {
			headers: {
				Authorization: HWUser.accessToken(),
				'Content-Type': 'application/json',
			},
		};
		aConfig.params = {
			userId: HWUser.userId(),
			orgId: HWUser.orgId(),
			appId: HWUser.appId(),
			feature: feature
		};
		return axios.get(url, aConfig);
	}

	updateSmartKonAccTaging = (accountId, touchpointId) => {
		let url = window.resolveRegion(process.env.REACT_APP_SMART_DASHBOARDS_BASE_API_URL) + "/smart-konversation/tag";
		let aConfig = { headers: { "Authorization": HWUser.accessToken(), "Content-Type": "application/json" } };
		let data = {
			userId: HWUser.userId(),
			orgId: HWUser.orgId(),
			appId: HWUser.appId(),
			accountId: accountId ? accountId : null,
			touchpointId: touchpointId ? touchpointId : null
		};
		return axios.put(url, data, aConfig);
	}

	updateCallCategory = (categoryId, touchpointId) => {
		let url = window.resolveRegion(process.env.REACT_APP_SMART_DASHBOARDS_BASE_API_URL) + "/smart-konversation/tag";
		let aConfig = { headers: { "Authorization": HWUser.accessToken(), "Content-Type": "application/json" } };
		let data = {
			userId: HWUser.userId(),
			orgId: HWUser.orgId(),
			appId: HWUser.appId(),
			callCategoryId: categoryId ? categoryId : null,
			touchpointId: touchpointId ? touchpointId : null
		};
		return axios.put(url, data, aConfig);
	}

	updateCallIntelligence = (intelligence, touchpointId) => {
		let url = window.resolveRegion(process.env.REACT_APP_SMART_DASHBOARDS_BASE_API_URL) + "/smart-konversation/tag";
		let aConfig = { headers: { "Authorization": HWUser.accessToken(), "Content-Type": "application/json" } };
		let data = {
			userId: HWUser.userId(),
			orgId: HWUser.orgId(),
			appId: HWUser.appId(),
			isCallIntelligenceDisabled: intelligence,
			touchpointId: touchpointId ? touchpointId : null
		};
		return axios.put(url, data, aConfig);
	}

	fetchRiskIntelligenceData = (nextToken, request) => {
		let url = window.resolveRegion(process.env.REACT_APP_SMART_DASHBOARDS_BASE_API_URL) + "/risk-intelligence";
		let aConfig = { headers: { "addFilter": true, "Authorization": HWUser.accessToken(), "Content-Type": "application/json" } };
		aConfig.params = {
			userId: HWUser.userId(),
			orgId: HWUser.orgId(),
			appId: HWUser.appId(),
			nextToken: nextToken ? nextToken : null,
			...request
		};
		return hwAxios.get(url, aConfig);
	}


	updateAccountDomain = (data) => {
		let aConfig = { headers: { "Authorization": HWUser.accessToken(), "Content-Type": "application/json" } };
		let url = window.resolveRegion(process.env.REACT_APP_SMART_DASHBOARDS_BASE_API_URL) + '/account-domain/update-account-domain';
		return axios.post(url, data, aConfig);
	}
}

var intelligenceAPIService = new IntelligenceAPIService();
export default intelligenceAPIService;