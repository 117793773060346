import axios from 'axios';
import HWUser from '../HWLibrary/HWUser';
import HWLocalStorage from '../HWLibrary/HWLocalStorage';
// import MailComposer from "nodemailer/lib/mail-composer";
export class GsuiteAPIService {

    constructor() {
        if (!GsuiteAPIService.instance) { GsuiteAPIService.instance = this }
        return GsuiteAPIService.instance
    }

    mailTypes      = { inbox: "Inbox", sent: "Sent" }
    profileBaseURL = "https://www.googleapis.com/plus/v1/people/me";

    loadGmailClient = () => {
        return new Promise((resolve, reject) => {
            if (!this.accessToken) { reject("G Suite integration is not done."); return ; }
            const accessToken = this.accessToken
            window.gapi && window.gapi.client && window.gapi.client.setToken({ access_token: accessToken });
            window.gapi && window.gapi.client && window.gapi.client.load('gmail', 'v1', () => { resolve(); });
        });
    }
    //  Handle Credentials
    credentialsLoadStatusEnum   = { loaded: "LOADED", notStarted: "NOT_STARTED", loading: "LOADING", notAvailable: "NOT_AVAILABLE", serverError: "SERVER_ERROR" }
    credentialsLoadStatus       = this.credentialsLoadStatusEnum.notStarted;
    accessToken                 = null; 
    gsuiteUserEmailId           = null;        
    onCredentialsUpdateCallback = null;
  
    onCredentialsUpdate = () => { 
        if (this.onCredentialsUpdateCallback) { this.onCredentialsUpdateCallback(this.credentialsLoadStatus); } 
    };

    loadCredentials = () => {
        const cachedAccessData = this.loadCachedGsuiteCredentials();
        if (cachedAccessData) {
            this.credentialsLoadStatus = this.credentialsLoadStatusEnum.loaded;
            this.accessToken           = cachedAccessData.accessToken;
            this.gsuiteUserEmailId     = cachedAccessData.gsuiteUserEmailId;
            this.onCredentialsUpdate()
        }
        this.credentialsLoadStatus = this.credentialsLoadStatusEnum.loading;
        this.fetchGsuiteCredentials().then(response => {
            if (response.data.statusCode === 200) {
                this.accessToken       = response.data.accessToken;
                this.gsuiteUserEmailId = response.data.gsuiteUserEmailId;
                this.updateLocalCacheWithGsuiteToken(response.data.accessToken, response.data.expiryTime, response.data.gsuiteUserEmailId);
                this.credentialsLoadStatus      = this.credentialsLoadStatusEnum.loaded;
            } else { this.credentialsLoadStatus = this.credentialsLoadStatusEnum.notAvailable; }
            this.onCredentialsUpdate()
        }).catch(error => {
            this.credentialsLoadStatus = this.credentialsLoadStatusEnum.serverError;
            this.onCredentialsUpdate()
            // alert("ERRORR: " + JSON.stringify(error));
        });
    }

    // SK-Server
    fetchGsuiteCredentials = () => {
        let skConfig = { headers: { "Authorization": HWUser.accessToken() } };
        skConfig.params = { "userId": HWUser.userId(), "appId": HWUser.appId(), "integrationType": "G_SUITE",orgId:HWUser.orgId() }
        let url = window.resolveRegion(process.env.REACT_APP_SK_INTEGRATIONS_BASE_API_URL) + "/credential";
        return axios.get(url, skConfig);
    }

    // Also clear the credentials is it expired.
    loadCachedGsuiteCredentials = () => {
        try {
            let credentialsId       = "sk-gsuite-credentials" + HWUser.userId() + HWUser.appId();
            const cachedCredentials = JSON.parse(HWLocalStorage.load(credentialsId));
            let isValid = Date.parse(cachedCredentials.expiryTime) > (new Date() - (10 * 60000))
            if (!isValid) { HWLocalStorage.clear(credentialsId); return null; }
            return cachedCredentials;
        } catch (error) { }
        return null;
    }

    isCredentilasExpired = () => {
        return !(this.loadCachedGsuiteCredentials() && true);
    }

    // Local
    updateLocalCacheWithGsuiteToken = (accessToken, expiryTime, gsuiteUserEmailId) => {
        try {
            let credentialsId = "sk-gsuite-credentials" + HWUser.userId() + HWUser.appId();
            const tokenDataString  = JSON.stringify({ "accessToken": accessToken, "expiryTime": expiryTime, "gsuiteUserEmailId": gsuiteUserEmailId });
            HWLocalStorage.save(tokenDataString, credentialsId);
        } catch (error) { }
    }

    clearGsuiteCache = () => {
        HWLocalStorage.clearWithPrefix("sk-gsuite");
        this.revokeGsuiteClient();
    }

    // G-Suite Integration Configuration. All scopes are verified.
    scopes = [
        // "https://www.googleapis.com/auth/gmail.compose", - We don't use
        "https://www.googleapis.com/auth/gmail.labels", 
        "https://www.googleapis.com/auth/gmail.modify", 
        "https://www.googleapis.com/auth/gmail.readonly",
        "https://www.googleapis.com/auth/gmail.send",
        // "https://www.googleapis.com/auth/contacts.readonly",  - We don't use
        "https://www.googleapis.com/auth/userinfo.email", 
        // "https://www.googleapis.com/auth/userinfo.profile",- Its already available
        // "https://www.googleapis.com/auth/calendar.readonly", - Not required
        "https://www.googleapis.com/auth/calendar.events.readonly" 
    ]



    gsuiteOAuthURL = (encodedStateString) => { 
        const baseURL     = "https://accounts.google.com/o/oauth2/v2/auth";
        const scope       = this.scopes.join(" ");
        const accessType  = "access_type=offline&include_granted_scopes=true&response_type=code&prompt=consent";
        const clientIdURL = "redirect_uri=" + this.integrationCallbackURL() + "&client_id=" + process.env.REACT_APP_GSUITE_APP_CLIENT_ID;
        return baseURL + "?scope=" + scope + "&" + accessType + (encodedStateString ? ("&state=" + encodedStateString) : "") + "&" + clientIdURL;
    }
    
    integrationCallbackURL = () => { return window.origin + "/gsuite/oauth/callback"; }

    revokeGsuiteClient = () => {
        // window.gapi.client.setToken({ access_token: null });
        // this.accessToken = null;
        try {
            window.gapi.client.setToken({ access_token: null });
            this.accessToken = null;
        } catch (error) { }
    }

    canShowGsuiteOauthPopUp = () => {
        return HWUser.isGsuiteIntegratedForApp() && !HWUser.isGsuiteIntegrated() && !HWUser.isGSuitePopUpShown();
    }

    oAuthTriggerBaseURL    = window.resolveRegion(process.env.REACT_APP_PLATFORM_BASE_API_URL_WEB_ADMIN) + "/webapp/oauth-pop-up-trigger"; 
    savePopUpTriggerStatus = () => {
        let aConfig       = { headers: { "Authorization": HWUser.accessToken(), "Content-Type": "application/json" } } ;
        const triggerData = { userId: HWUser.userId(), integrationName: 'G_SUITE', popUpTriggered: true }
        return axios.post(this.oAuthTriggerBaseURL, triggerData, aConfig);
    }
    
}

var gsuiteAPIService = new GsuiteAPIService();
// Object.freeze(gsuiteAPIService)

export default gsuiteAPIService;
