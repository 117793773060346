import { skNotesActions } from "../constants/actionType";


const initialState = {
	notes: [],
	isLoading: false,
	isError: false,
	successMsg: '',
	errorMessage: '',
	nextToken: null,
	accountName: '',
	notesSearchText:''
}

export const skNotesActionReducer = (state = initialState, { type , payload }= {}) => {
	switch (type) {
    case skNotesActions.FETCH_NOTES_LIST: {
		let isLoading = (payload?.isLoading !== undefined && payload?.isLoading !== null) ? payload.isLoading : state.isLoading;
		return ({...state, notes:payload?.notes,isLoading : isLoading, nextToken : payload?.nextToken, accountName : payload?.accountName}); }
		
    case skNotesActions.ADD_NOTE :{return ({...state, msgToShow:payload?.successMsg});} 
		case skNotesActions.EDIT_NOTE: return state; 
		case skNotesActions.DELETE_NOTE:return state; 
		case skNotesActions.SET_NOTES_LOADING:return state; 
		case skNotesActions.SET_SMART_STATE: {return {...state, ...payload}; }
		case skNotesActions.SET_IS_LOADING:return state; 
		case skNotesActions.SET_IS_ERROR:{return ({...state, msgToShow:payload?.errorMessage }); }
    default: return state; 
  }
}


export default skNotesActionReducer 
