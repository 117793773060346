
import { cloneDeep, uniqBy } from 'lodash';
import React, { useEffect, useRef, useState } from 'react';
import { Mention, MentionsInput } from 'react-mentions';
import { useDispatch, useSelector } from 'react-redux';
import HWUser from "../../HWLibrary/HWUser";
import smiley from '../../assets/images/smiley.svg';
import { formatNameToTwoLetters } from '../SkSmartFunctions.js';
import SKSmartUiBtns from '../SKSmartUiBtns/SKSmartUiBtns';
import SkSmartEmojiPicker from '../SkSmartEmojiAndReactions/SkSmartEmojiPicker';
import { deleteComment, editComment, getSmartCommentStates, postComment, setSmartCommentStates } from '../../store/reducers/skCommentsReducer';
import './SkSmartComments.css';
import send from "../../assets/sia/img/ionic-ios-send.svg";
import { smartCommentsStyle } from './SkSmartCommentStyles';
import uuid from 'react-uuid';

const SmartCommentMention = ({featureId,isScroll, smartComment, action, visibility, markAsDeleted , setIsInsidePopperOn, setCurrentComment, parentCommentId, setShowReplyEditor, feature,onCommentCallBack}) => {

	const [container, setContainer] = useState(null)
	const [comment, setComment] = useState(smartComment)
	const [smartMentions, setSmartMentions] = useState([]);
	const [showEmojiPicker, setShowEmojiPicker] = useState(false);
	const [anchorElTarget, setAnchorElTarget] = useState();
	const [commentDescription, setCommentDescription] = useState('');
	const mentionsInputRef = useRef();
	const emojiInputRef = useRef();
	const dispatch = useDispatch();

	// useEffect(() => {
	// 	mentionsInputRef?.current?.focus();
  // });

	const setCommentData = (updatedState) => {
		setComment(prevState => (
			{
				...prevState,
				...updatedState
			}
		))
	}


	// redux implementation
	const getSmartCommentState = useSelector(getSmartCommentStates);
	const { smartCommentsList, smartUsersList , currentSelectedComment, smartKommunityCommentsList} = getSmartCommentState;

	useEffect(() => {
		let container = document.getElementById('smart__comments__input__container');
		setContainer(container);
	}, [])

	useEffect(() => {
		if(isScroll){
      setShowEmojiPicker(false)
		}
	}, [isScroll])


	const addKnkComment = (comment) => {
    let commentList = smartKommunityCommentsList;
		commentList[featureId].commentResponses = comment;
		dispatch(setSmartCommentStates({ smartKommunityCommentsList: commentList }))
	}

	useEffect(() => {
			var element = document.getElementsByClassName("smart__comments__input__container__input");
			if (element?.length > 0) {
				element[element?.length - 1].focus();
			}
	}, [smartCommentsList])


	// comment actions
	const commentActions = {
		onMentionsInputChange: (event, newValue, newPlainTextValue, allMentions) => {
			// const mention = uniqBy([...(smartMentions || []), ...(allMentions || [])], i => i.id);
			const mention = uniqBy([...(allMentions || [])], i => i.id);
			let comment = cloneDeep(smartComment);
			if(event.target.value !== '\n'){
				comment['commentBody'] = event.target.value;
			}
			setComment(comment);
			setSmartMentions(mention);
			if (setCurrentComment) {
				dispatch(setSmartCommentStates({ currentSelectedComment: event.target.value }));
		
				setCurrentComment(event.target.value);
			}
		},
		resetComment: () => {
			let commentToReset = cloneDeep(comment);
			commentToReset['commentBody'] = '';
			setComment(commentToReset);
			setSmartMentions([]);
			if (setCurrentComment) {
				dispatch(setSmartCommentStates({ currentSelectedComment: ''}));
				setCurrentComment('');
			}
		},
		onAddComment: (e) => {
			e.preventDefault();
			let taggedUsers = smartMentions.map((item) => { return item.id });
			comment['taggedUsers'] = taggedUsers;
			comment['commentBody'] = comment.commentBody;
			comment['commentedBy'] = HWUser.userId();
			comment['userImage'] = getCurrentUserImage();
			comment['userName'] = getCurrentUserName();
			comment['commentId'] = uuid();
			comment['likes'] = [];
			comment['featureId'] = featureId;
			comment['parentCommentId'] = parentCommentId;
			setComment(comment);
			let index = 0;
			let commentList = feature !== 'KnkPost' ? cloneDeep(smartCommentsList) : smartKommunityCommentsList[featureId]?.commentResponses;
			let parentComment = {};
			if(parentCommentId){
				parentComment = commentList.find(comment => comment?.parentComment?.commentId === parentCommentId) || [];
				let childComment = parentComment?.replyList?.replies ? parentComment?.replyList?.replies : [];
				childComment.push(comment);
				let repliesMap = parentComment?.replyList || {};
				repliesMap["replies"] = childComment;
				parentComment["replyList"] = repliesMap;
				setShowReplyEditor(false);
				parentComment["replyCount"] = parentComment.replyCount + 1;
			}
			else{
			parentComment["parentComment"] = comment;
			commentList.splice(index, 0, parentComment);
		  }
			feature !== 'KnkPost' ? dispatch(setSmartCommentStates({ smartCommentsList: commentList })) : addKnkComment(commentList);
			dispatch(postComment(comment, comment?.featureId));
			if(feature === 'KnkPost'){onCommentCallBack(comment?.featureId, "add");}
			commentActions?.resetComment();
		},
		onEditCommentClicked: (e) => {
      let commentBody = comment?.commentBody;
      if(!commentBody){ return;}
      let taggedUsers = smartMentions.map((item) => { return item.id });
        let editedComment = {
          commentBody: commentBody,
          taggedUsers: taggedUsers,
        }
      let commentList = feature !== 'KnkPost' ? cloneDeep(smartCommentsList) : smartKommunityCommentsList[featureId]?.commentResponses;

			let commentToUpdate = {}
			if(parentCommentId){
				let parentComment = commentList.find(comment => comment?.parentComment?.commentId === parentCommentId) || [];
				let indexToUpdate = commentList.findIndex(item => item?.parentComment?.commentId === parentCommentId);
				let childIndex = parentComment?.replyList?.replies?.findIndex(item => item.commentId === comment.commentId);
				parentComment.replyList.replies[childIndex] = comment;
				commentList[indexToUpdate] = parentComment;
			}
			else{
				commentToUpdate = commentList.find(item => item?.parentComment?.commentId === smartComment.commentId);
				let indexToUpdate = commentList.findIndex(item => item?.parentComment?.commentId === smartComment.commentId);
				commentToUpdate['parentComment'] = comment
				commentList[indexToUpdate] = commentToUpdate;
			}
      feature !== 'KnkPost' ? dispatch(setSmartCommentStates({smartCommentsList:commentList})) : addKnkComment(commentList);
      dispatch(editComment(comment?.commentId, comment?.featureId, editedComment));
      visibility(false);
		},
		onDeleteCommentClicked: () => {
			dispatch(deleteComment(comment?.featureId, comment?.commentId))
		},
		onCancleClick: () => {
			visibility(false);
		},
		onEmojiPickerToggle: (e) => {
			setAnchorElTarget(e?.target);
			setIsInsidePopperOn(true)
			setShowEmojiPicker(true)
		},
		setTargetForEmojiPicker: (e) => {
			// setAnchorElTarget(e?.target);
		},
		onEmojiClick: (val) => {
			let newComment =   cloneDeep(comment);
			let commentBody = `${newComment?.commentBody}${val}` ;
			setShowEmojiPicker(false);
			setIsInsidePopperOn('wait') ;
			if (/^\s/.test(commentBody)) {
				commentBody = ""
			}

			newComment.commentBody = commentBody;
			setComment(newComment);
		},
		handleClickAway: (e) => {
			setShowEmojiPicker(false);
			setIsInsidePopperOn(false) ;
		}
	}

	const commentActionBtns = {
		primaryBtnLabel: 'SAVE',
		primaryBtnAction: commentActions.onEditCommentClicked,
		secondaryBtnLabel: 'CANCEL',
		secondaryBtnAction: commentActions.onCancleClick,
	}


	let getCurrentUserImage = () => {
		let userImage;
		let user = (JSON.parse(window.localStorage.getItem('platform-currentUser')))
		if (user && user.user && user.user.profileImageURL) { userImage = user.user.profileImageURL }
		return userImage;
	}

	let getCurrentUserName = () => {
		let userName;
		let user = (JSON.parse(window.localStorage.getItem('platform-currentUser')))
		if (user && user.user && user.user.name) { userName = user.user.name }
		return userName;
	}

  const handleKeyDown = (event)=> {
    // if (comment?.commentBody && event.key === 'Enter') {
      // // Insert a line break character
      // const startPos = event.target.selectionStart;
      // const endPos = event.target.selectionEnd;
			// let prevComment = comment?.commentBody;
			// prevComment = prevComment.substring(0, startPos) + "\n" + prevComment.substring(endPos)
      // // setComment((prevValue) => {
      // //   return { ...prevValue, commentBody : prevValue?.commentBody?.substring(0, startPos) + '\n' + prevValue?.commentBody?.substring(endPos)};
      // // });
			// setCommentData({commentBody : prevComment});
      // event.target.setSelectionRange(startPos + 1, startPos + 1);
      // // Prevent the default behavior (inserting a newline)
      // event.preventDefault();
    // }
		if (comment?.commentBody && event.key === 'Enter' && action === "create" && !event.shiftKey && !isOnlyWhitespace()) {
			commentActions?.onAddComment(event); 
		}
  }

	const onSendIconClick = (event) => {
		if(action === "edit") 
		{
			commentActions.onEditCommentClicked(event)
		}
	 else{
    comment?.commentBody && commentActions?.onAddComment(event)
	 }
	}

	const isOnlyWhitespace = () => {
    return /^\s*$/.test(comment?.commentBody);
	}

	const achorEle = useRef();
	return (
		<div className='smart__comments__container__top__form'>

        <div className='smart__comments__profile__icon__container'>
					<div className='smart__react__select__profile__icon__wrap'>
					  <div className='smart__react__select__profile__icon__container popover--text' style={{backgroundColor: getCurrentUserImage() ? "#fff" : ''}}>
							<div className='smart__react__select__profile__icon'>
								{getCurrentUserImage() ? <img src={getCurrentUserImage()} className="smart__comments__profile__icon" /> :
									<div className="smart__react__select__profile__icon-text">{formatNameToTwoLetters(getCurrentUserName())}</div>
								}	
						</div>
					</div>
				{/* <img className='smart__comments__profile__icon' src={getCurrentUserImage() ? getCurrentUserImage() : avatarIcon}/> */}
					</div>
			</div>

			<div className='smart__comment__form__editable__wrap'>
				<div key={"renerhfjhj"}  id="smart_comment_div" className='smart__comment__form'>
					<MentionsInput
						inputRef={mentionsInputRef} style={smartCommentsStyle}
						allowSpaceInQuery
						allowSuggestionsAboveCursor
						value={comment?.commentBody}
						onChange={commentActions?.onMentionsInputChange}
						suggestionsPortalHost={container}
						onKeyDown={(e) => { handleKeyDown(e) }}
						id='desc_area_popup'
						placeholder='Add a comment...'
						className='smart__comments__input__container'>
						<Mention appendSpaceOnAdd trigger='@' markup='@[__display__](__id__)' displayTransform={(id, name) => `@${name}`} data={smartUsersList} className='mentions__mention' />
					</MentionsInput>
					<div  ref={achorEle} className='smart__comment__emoji__container' onMouseEnter={(e) => commentActions?.setTargetForEmojiPicker(e)}>
						<img  className='smart__comment__emoji__container-icon' onClick={(e) => { commentActions?.onEmojiPickerToggle(e) }} src={smiley} alt='' />
						<span onClick={(event) => {!isOnlyWhitespace() && onSendIconClick(event)}} className={!isOnlyWhitespace() && comment?.commentBody ? "comment-mention-send-active" : "comment-mention-send"}><img src={send} alt=""/></span>
					</div>
					{showEmojiPicker && <SkSmartEmojiPicker  handleClickAway={commentActions?.handleClickAway} onChange={commentActions?.onEmojiClick} anchorElTarget={achorEle?.current} lazyLoadEmojis={false}/>
					}
					</div>

				{action === "edit" &&
					<div className='smart__comments__container__footer'>
						<SKSmartUiBtns  {...commentActionBtns} />
					</div>
				}
			</div>
		</div>
	)
}
export default SmartCommentMention