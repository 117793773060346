import { skSmartInsightActions } from "../constants/actionType";

const initialState = {
	currentHSData: null,
	hsTrendsData: null,
	trendsByCsm: null,
	trendsByCategory: null,
	totalTouchpointTrendList: null,
	clientTouchpointTrendList: null,
	accountTouchpointTrendList: null,
	touchpointDashboardTableNextToken: null,
	touchpointDashboardPageLoader: null,
	touchpointDashboardTableLoader: false,
	tableViewTrend: null,
	isLoading: false,
	nrrDashboardLoader: false,
	nrrDashboardData: null,
	dates: null,
	healthScoreFilter:null,
}

export const skSmartInsightActionReducer = (state = initialState, { type, payload }) => {
	switch (type) {
		case skSmartInsightActions.FETCH_CURRENT_HS:
		case skSmartInsightActions.FETCH_INSIGHTS_TOUCHPOINT:
		case skSmartInsightActions.FETCH_HS_TRENDS:
		case skSmartInsightActions.SET_SMART_INSIGHT_STATES: { return { ...state, ...payload } }
		default: return state;
	}
};

export default skSmartInsightActionReducer