import { skCommentActions } from "../constants/actionType"

const initialState = {
	smartCommentsList: [],
	smartKommunityCommentsList: {},
	smartComment: {
		integrationType: '',
		timeZone: '',
		appId: '',
		commentedBy: '',
		featureId: '',
		feature: '',
		taskId: '',
		commentBody: '',
		taggedUsers: []
	},
	currentSelectedComment: null,
	smartUsersList: [],
	isLoading: false,
	isError: false,
	successMsg: '',
	errorMessage: '',
	smartCommentNextToken: null,
	smartCommentId: '',
	isCommentLoading: false,
}



export const skCommentActionReducer = (state = initialState, { type, payload }) => {
	switch (type) {
		case skCommentActions.SET_SMART_COMMENT_STATES:
		case skCommentActions.POST_SMART_COMMENT:
		case skCommentActions.EDIT_SMART_COMMENT:
		case skCommentActions.FETCH_SINGLE_COMMENT:
		case skCommentActions.DELETE_SMART_COMMENT:
		case skCommentActions.POST_COMMENT_REACTION:
		case skCommentActions.FETCH_SMART_COMMENTS_LIST: {
			return {
				...state, ...payload
			}
		}
		default: return state
	}
}


export default skCommentActionReducer