
class SKAnalyticsLog {

    //Product Success
    static onProductSuccessDurationIndexClick    = () => { this.log("Product Success", "Product Success: Duration Index"); }
    static onProductSuccessLoyaltyIndexClick     = () => { this.log("Product Success", "Product Success: Loyalty Index"); }
    static onProductSuccessRetentionIndexClick   = () => { this.log("Product Success", "Product Success: Retention Index"); }
    static onProductSuccessPerformanceIndexClick = () => { this.log("Product Success", "Product Success: Performance Index"); }
    static onProductSuccessRealTimeIndexClick    = () => { this.log("Product Success", "Product Success: Real-Time"); }

    static onProductSuccessHeatMapClick        = () => { this.log("Product Success", "Product Success: Heat Map"); }
    static onProductSuccessAppStoreIndexClick  = () => { this.log("Product Success", "Product Success: App Store Index"); }
    static onProductSuccessFeaturesClick       = () => { this.log("Product Success", "Product Success: Features"); }
    static onProductSuccessUsersClick          = () => { this.log("Product Success", "Product Success: Users"); }
    static onProductSuccessReportDownload      = () => { this.log("Product Success", "Product Success: Report Downloaded")}//Noor - 22 Oct 2020

    //Settings - Noor - 22 Oct 2020
    static onSettingsEventSummaryClick  = () => { this.log("Settings" , "Settings: Event Summary"); }
    static onSettingsEventDataClick     = () => { this.log("Settings" , "Settings: Event Data"); }
    static onInActiveNActiveStatus      = () => { this.log("Settings" , "Settings: User Active/Inactive Status Changed")}
    static onKeyStakeHolderStatusChange = () => { this.log("Settings" , "Settings: User Key Stakeholder Status Changed")}
    static onCustomerSegmenChanged      = () => { this.log("Settings" , "Settings: Customer Segment Changed")}
    static onRolesNPermissionChanged    = () => { this.log("Settings" , "Settings: Roles and Permissions changed")}

    // Noor - 22 Oct 2020
    // Alerts
    static onAlertAdded     = () => { this.log("Alerts" , "Alerts: Alert Created")}
    static onAlertEdited    = () => { this.log("Alerts" , "Alerts: Alert Edited")}
    static onAlertTriggered = () => { this.log("Alerts" , "Alerts: Alert Triggered")}
    static onAlertActions   = () => { this.log("Alerts" , "Alerts: Actions")}

    //Calendar
    static onCalendarAccessListView = () => { this.log("Calendar" , "Calendar: Access List View")}
    static onCalenderEditAddView    = () => { this.log("Calendar"  , "Calendar: View/Add/Edit/Delete Item")}

    //Contacts
    static onContactListViewed = () => { this.log("Contacts" , "Contacts: Contact List Viewed")}
    static onUnOrMarkChampion  = () => { this.log("Contacts" , "Contacts: Un/Mark Champion")}
    static onAddEditContact    = () => { this.log("Contacts" , "Contacts: Add/Edit Contact")}
    static onImportContact     = () => { this.log("Contacts","Contacts: Contact Import/Mapping")}

    //Customer Success
    static onCustomerAccountView      = () => { this.log("Customer Success", "Customer Success: Customer Account View"); }

    static onHealthScoreView          = () => { this.log("Customer Success" , "Customer Success: Customer Health Scores View")} 
    static onHealthScoreRatingChanged = () => { this.log("Customer Success" , "Customer Success: Health Score Rating Changed")}
    static onHealthScoreBandList      = () => { this.log("Customer Success" , "Customer Success: List by Health Score Band ")}

    static onSuccessPlayAccountListView      = () => { this.log("Customer Success" , "Customer Success: Success Play Account List View")}
    static onAddRemoveSuccessPlay360  = () => { this.log("Customer Success" , "Customer Success: A/c 360 Success Play Add/Remove")}

    static onClickPortfolioAnalysis   = () => { this.log("Customer Success" , "Customer Success: Portfolio Analysis")}
    static onAlertsViewedMoreLess     = () => { this.log("Customer Success" , "Customer Success: Alerts Viewed")}
    static onNotes360                 = () => { this.log("Customer Success" , "Customer Success: A/c 360 Notes")}
    static onClickConnector           = () => { this.log("Customer Success" , "Customer Success: Custom Report ")}
    static onClickActions             = () => { this.log("Customer Success" , "Customer Success: Actions")}

    //Email
    static onEmailAccessed    = () => { this.log("Email" , "Email: Email Accessed")}
    static onEmailResponse    = () => { this.log("Email" , "Email: Email Response")}

    //Financials
    static onViewOrders         = () => { this.log("Financials" , "Financials: View Orders")}
    static onFinancialActions   = () => { this.log("Financials" , "Financials: Actions")}

    //Profile
    static onNotificationAccessed = () => { this.log("Profile" , "Profile: Notifications Accessed")}

    //Search
    static Search = () => { this.log("Search" , "Search")}

    //Support
    static onIssuesReported = () => { this.log("Support" , "Support: Issue reported")}
    static onIssuesViewed   = () => { this.log("Support" , "Support: Issues viewed")}

    //Tasks
    static onTaskDashboardView  = () => { this.log("Tasks" , "Tasks: Dashboard")}
    static onTaskAdded          = () => { this.log("Tasks" , "Tasks: Task Added")}
    static onTaskEdited         = () => { this.log("Tasks" , "Tasks: Task Edited")}
    static onTaskViewed         = () => { this.log("Tasks" , "Tasks: Task Viewed")}
    static onTaskActions        = () => { this.log("Tasks" , "Tasks: Actions")}

    //Tickets
    static onTicketsViewed      = () => { this.log("Tickets" , "Tickets: Ticket Viewed")}
    static onTicketsActions     = () => { this.log("Tickets" , "Tickets: Actions")}

    //Touchpoints
    static onTouchpointAddViewEdit   = () => { this.log("Touchpoints" , "Touchpoints: Touchpoint Added/Edited/Viewed")}
    static onTouchpointListView      = () => { this.log("Touchpoints" , "Touchpoints: Touchpoint List View")}
    static onTopuchpointAddViaEmail  = () => { this.log("Touchpoints" , "Touchpoints: Touchpoint added via email")}
    static onAddEmailAsTouchpoint    = () => { this.log("Touchpoints" , "Touchpoints: Add Email as Touchpoint")}
    static onAddCalenderAsTouchpoint = () => { this.log("Touchpoints" , "Touchpoints: Add Calendar as Touchpoint")}

    static log = (module, eventName) => {
        // try {
        //     if (!this.isValid(module) || !this.isValid(eventName)) { console.log("Invalid Attribute:"); return }
        //     // let eventParams = { "Module": module }
        //     // window && window.UsageAnalytics && window.UsageAnalytics.logEvent(eventName, eventParams);
        // } catch {
        //     console.log("Error while logging: ");
        // }
    }

    static isValid = (attribute) => {
        return attribute && (typeof attribute === 'string' || attribute instanceof String);
    }

    static logEventWithPath = (eventName) => {
        try {
            if (! window.location.pathname || !this.isValid(eventName)) {
							//  console.log("Invalid Attribute:" + eventName); 
							 return }
            let name = eventName + "-s-" + window.location.pathname + "-e";
            window && window.UsageAnalytics && window.UsageAnalytics.logEvent(name);
        } catch { 
					// console.log("Error while logging: " + eventName); 
				}
    }

    static logEvent = (eventName) => {
        try {
            if (!this.isValid(eventName)) { 
							// console.log("Invalid Attribute:" + eventName); 
							return }
            let name = eventName;
            window && window.UsageAnalytics && window.UsageAnalytics.logEvent(name);
        } catch { 
					// console.log("Error while logging: " + eventName); 
				}
    }

}

export default SKAnalyticsLog;