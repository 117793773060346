import React from "react";
import { Card } from 'react-bootstrap';
import "./profile.css";
import {  ToastsStore } from 'react-toasts';
import checkmark from "../assets/images/settings/checkmark.png"
import iconSuccess from "../assets/images/settings/icon_success.png"
import { updateProfileDetails } from '../HWLibrary/HWIframeBridge';
import Loader from 'react-loader'
import SettingService from '../views/Settings/SettingsApi'
import HWLocalStorage from '../HWLibrary/HWLocalStorage';
import HWUser from '../HWLibrary/HWUser';
import uuid from 'react-uuid';
import SKAnalyticsLog from "../HWLibrary/SKAnalyticsLog";
// import Localizer, { getTrans } from "../SkSmartUtil/SkLocalizer/Localizer";
import skSmartServices from "../SkSmartUtil/SkSmartServices";
import skApiService from "../SkApiService";
import { setGlobalStates } from "../store/reducers/skGlobalReducer";
import { connect } from "react-redux";
class Profile extends React.Component {

    constructor() {
        let user = []
        user = (HWLocalStorage.load("platform-currentUser"))
        super();
        this.state = {
            showSwitchPlatformPopup: false,
            name: user.user.name,
            showPasswordTab: false,
            hidePasswordTab: false,
            emailId: user.user.emailId ? user.user.emailId : "",
            phoneNumber: user.user.phoneNumber ? user.user.phoneNumber : '',
            oldphonenumber: '',
            organizationName: '',
            callingCode: user.user.callingCode ? user.user.callingCode:'',
            oldCallingCode: '',
            deviceId: '',
            organizationId: '',
            userId: '',
            imageName: '',
            organizationLogoName: '',
            userData: user,
            otp: '',
            otpSent: false,
            otpVerified: false,
            loaderupload: false,
            oldPassword: '',
            newPassword: '',
            confirmPassword: '',
            otpError: '',
            // phoneError: '',
            passwordError: '',
            mismatchError: '',
            resend: false,
            savedDetails: '',
            photoUploaded: '',
            isNewImageUploaded: false,
            newImageURL: '',
            savedUser: [],
            accessToken: '',
            phoneVerified:user && user.user && user.user.phoneVerified,
						showIcon1: false,
						showIcon2: false,
						showIcon3: false
        }
        localStorage.setItem("sendotp-deviceId",uuid());
    }

    componentDidMount() {
        let saveuser = []
        saveuser = JSON.parse(window.localStorage.getItem("platform-currentUser"))
        this.setState({ savedUser: saveuser, accessToken: HWUser.accessToken() })
        window.localStorage.setItem("acess", this.state.accessToken)
        SKAnalyticsLog.log("Profile", "Profile: Profile Accessed");
    }

    handleUserInput(e) {
        const name = e.target.name
        const value = e.target.value
        // console.log(e.target.value)
        if (name === 'name')
            this.setState({ name: value })
        if (name === 'phoneNumber') {
            let phoneno = "^([+]?\d{1,2}[.-\s]?)?(\d{3}[.-]?){2}\d{4}$"
            if (value.length === 0) {
                this.setState({ phoneNumber: "", phoneError: '', callingCode: '' })
            }
            else if (value !== phoneno && (value.length > 14 || value.length < 13)) {
                this.setState({ phoneError: "Please enter valid phone number" })
            }

            else {
                if (value.includes('+1')) {
                    this.setState({ phoneNumber: value.substr(2, 13), phoneError: null, callingCode: value.substr(0, 2) })
                }
                else if (value.includes('+91')) {
                    this.setState({ phoneNumber: value.substr(3, 13), phoneError: null, callingCode: value.substr(0, 3) })
                }
                else {
                    this.setState({ phoneNumber: value.substr(3, 13), phoneError: null, callingCode: value.substr(0, 3) })
                }
            }
        }
        if (name === 'otp')
            this.setState({ otp: value })
        if (name === 'oldPassword')
            this.setState({ oldPassword: value })
        if (name === 'newPassword')
            this.setState({ newPassword: value })
        if (name === 'confirmPassword')
            this.setState({ confirmPassword: value })
        
    }

    resendOtp() {
        this.setState({ resend: true })
        if (this.state.resend === true) {
            this.sendOTP()
        }
    }
    handlephoneChange(e) {
        let phoneno = "^([0][1-9]\d{9}$|^[1-9]\d{9}){10}$"

        if (e.target.value !== phoneno && e.target.value.length !== 10) {
            this.setState({ phoneError: "Please enter valid phone number" })
        }
        else {
            this.setState({ phoneNumber: e.target.value, phoneError: null })
        }
    }
    sendOTP() {
        this.setState({ phoneError: null })
        let data = {
            userId: this.state.userData.user.userId,
            deviceId: (window.localStorage.getItem("sendotp-deviceId"))
        }
        let request = {name: this.state.name || this.state.userData.user.name,emailId: this.state.emailId,phoneNumber: this.state.phoneNumber,organizationName: this.state.userData.user.organizationName,callingCode: this.state.callingCode,deviceId: HWLocalStorage.deviceId(),organizationId: this.state.userData.user.organizationId,userId: this.state.userData.user.userId,imageName: this.state.imageName,organizationLogoName: ""}
        skApiService.updateprofile(request).then(response=>{
            if (response.data.status === 'SUCCESS') {
                HWUser.updateUserProfileData(response.data);
                window.localStorage.setItem("platform-userPic", this.state.newImageURL)
                window.postMessage("refresh-nav-bar");
                updateProfileDetails()
                let saveduser = []
                saveduser = HWLocalStorage.load("platform-currentUser")
                // console.log(saveduser)
                this.setState({
                    photoUploaded: null, passwordError: null, phoneError: null,
                    otpSent: false,
                    phoneNumber: saveduser.user.phoneNumber, name: saveduser.user.name,
                    userImageURL: saveduser.user.profileImageURL,
                    phoneVerified:saveduser.user.phoneVerified
                })
                skApiService.sendOTP(data).then(response=> {
                    if (response.data.status === 'SUCCESS') {
                        this.setState({ otpSent: true, phoneError: null,otp:'' })
                    }
                })
            }
        })
        
    }


    showPassword() {
        this.setState({ showPasswordTab: true })
    }
    hidePassword() {
        this.setState({ showPasswordTab: false, passwordError: null, mismatchError: null, oldPassword: null, newPassword: null, confirmPassword: null, showIcon1: false, showIcon2: false, showIcon3: false })
    }

    verifyOTP() {
        let data = {
            userId: this.state.userData.user.userId,
            deviceId:  (window.localStorage.getItem("sendotp-deviceId")),
            otp: this.state.otp
        }
        skApiService.verifyOTP(data).then(res=>{
            if (res.data.status === 'SUCCESS') {
                this.setState({ otpVerified: true, otpSent: false, otpError: null,phoneVerified:true })
                updateProfileDetails();
            }
            else if (res.data.status === 'INVALID_OTP') {
                this.setState({ otpVerified: false, otpSent: true, phoneVerified:false,otpError: 'Please enter valid OTP', newPassword: '', confirmPassword: '' })
            }
        })
    }

    uploadServices = new SettingService();
    onFileSelect = (event) => {
        let imageFile = event.target.files && event.target.files[0];
        if (imageFile['type'] === 'image/jpeg' || imageFile.type === 'image/png') {
            this.setState({ loaderupload: !this.state.loaderupload })
						skSmartServices.fetchUploadURL("ACCOUNT_SETTINGS_ORG_LOGO").then(response => {
                let fileName = response.data.key;
                this.state.newImageURL = response.data.url.split("?")[0]
                this.state.imageName = response.data.key;
                let url = response.data.url;
                let imageFetchURL = response.data.resourceURL

                return skSmartServices.uploadLogoImage(imageFile, url).then(response1 => {
                    let urlUploaded = response1.config.url
                    this.setState({ uploadedImgeUrl: response.data.resourceURL, fileName: imageFile['name'], loaderupload: !this.state.loaderupload, photoUploaded: "Photo uploaded successfully", isNewImageUploaded: true})
                })
            })
        }
        else {
            alert('Please upload Jpeg or Png format. ');

        }
    }

    saveUserDetails() {
        this.setState({ otpSent: false })
        if(this.state.phoneError !="Please enter valid phone number"){
        let data = {name: this.state.name || this.state.userData.user.name,emailId: this.state.emailId, phoneNumber: this.state.phoneNumber,organizationName: this.state.userData.user.organizationName,callingCode: this.state.callingCode,deviceId: HWLocalStorage.deviceId(),organizationId: this.state.userData.user.organizationId,userId: this.state.userData.user.userId,imageName: this.state.imageName,organizationLogoName: ""}
				skApiService.updateprofile(data).then(res=>{
                if (res.data.status === 'SUCCESS') {
                    ToastsStore.success("Details saved successfully!")
                    HWUser.updateUserProfileData(res.data);
                    window.localStorage.setItem("platform-userPic", this.state.newImageURL)
                    window.postMessage("refresh-nav-bar");
                    updateProfileDetails()
                    let saveduser = []
                    saveduser = HWLocalStorage.load("platform-currentUser")
                    this.setState({
                        photoUploaded: null, passwordError: null, phoneError: null,
                        otpSent: false,
                        phoneNumber: saveduser.user.phoneNumber, name: saveduser.user.name,
                        userImageURL: saveduser.user.profileImageURL,
                        phoneVerified:saveduser.user.phoneVerified
                    })
										this?.props?.showNotification({ showNotification: true, notificationMsg:'Profile updated successfully' })
	
										setTimeout(() => {
											this?.props?.showNotification({ showNotification: false,  })
								
										},6000)
                }
            })
        }else{
        }
        
    }


    savePassword() {
        this.setState({ passwordError: null })
        if (this.state.newPassword !== this.state.confirmPassword) {
            this.setState({ mismatchError: "Passwords do not match" })
        } else {
            this.setState({ mismatchError: null })
            let data = {password: this.state.newPassword,deviceId: HWLocalStorage.deviceId(),"token": "","userId": this.state.userData.user.userId,"oldPassword": this.state.oldPassword}
            skApiService.updatepassword(data).then(res => {
                if (res.data.status === 'SUCCESS') {
                    this.setState({ showPasswordTab: false, passwordError: null, oldPassword: null, newPassword: null, confirmPassword: null })
                    ToastsStore.success("Password updated successfully!")
										this?.props?.showNotification({ showNotification: true, notificationMsg:'Password updated successfully' })
	
										setTimeout(() => {
											this?.props?.showNotification({ showNotification: false,  })
								
										},6000)
                }
                else if (res.data.status === 'INVALID_OLD_PASSWORD') {
                    this.setState({ showPasswordTab: true, passwordError: 'Invalid current password' })
                }
            })
        }
    }

    render() {
        let userImageURL = HWUser.userProfileImageURL();
        let userName = this.state.userData.user.name
        let userRole = this.state.userData.user.userRole
        let phoneNumber = this.state.userData.user.phoneNumber
        let emailId = this.state.userData.user.emailId
        if (this.state.userData.user.callingCode !== undefined) {
            this.state.oldCallingCode = this.state.userData.user.callingCode
        }
        if (this.state.userData.user.phoneNumber !== undefined) {
            this.state.oldphonenumber = this.state.userData.user.phoneNumber
        }
        let logoImageURL = this.state.userData.user.logoImageURL
        let Role;
        switch(userRole){
            case "CustomerSuccessManager" : Role ="Customer Success Manager";break;
            case "Admin" : Role ="Admin";break;
            case "Executive" : Role ="Executive";break;
            case "CSMLeader" : Role ="CSM Leader";break;
            case "ProductManagement" : Role ="Product Management";break;
            case "Standard" : Role ="Platform User";break;
        }
        // console.log( this.state.phoneNumber)
        return (
            <div className="main-content-container container-fluid px-4">
                <div className="page-header row no-gutters pt-3">
                    <div className="col-12 col-sm-12 text-center text-sm-left mb-4 mt-1">
                        <h3 className="page-title" style={{ margin: "0px 0px 0px 3px", fontWeight: '500', letterSpacing: "0.5px" }}>Profile</h3>
                    </div>
                    {/* <div className="col-12 col-sm-2 text-center text-sm-right mb-3 d-flex justify-content-end"  style={{display: 'none'}}>
                        <div className="sk_toggle_wrap" style={{ margin: "-13px -28px 0px" }}><span className="cstl">SK 2.3</span>
                            <div className="custom-control custom-toggle top_bar d-block my-2">
                                <input type="checkbox" checked={!this.state.showSwitchPlatformPopup} id="customToggle122" name="customToggle122" className="custom-control-input" onChange={() => { this.setState({ showSwitchPlatformPopup: true }) }} />
                                <label className="custom-control-label" htmlFor="customToggle122">
                                </label>
                            </div>
                            <span className="cstr">SK 3.0</span>
                        </div>
                    </div> */}
                </div>
                <Card>
                    <div style={{ padding: '12px 20px 5px 26px', color: 'black', fontSize: '14px', fontWeight: '400' }}>
                        <p className="profilePc">Profile</p>
                    </div>
                    <div className="dropdown-divider"></div>
                    <div className="row">
                        <div className="col-lg-6 col-md-6 col-sm-12">
                            <div className="row" style={{ padding: '10px 20px 0px 26px' }}>
                                <div className="col-lg-8 col-md-8 col-sm-12 profile_wrapper" style={{ paddingBottom: '20px' }}>
                                    <div>
                                        <div className="form-group form-line-active">
                                            <label style={{ fontSize: '14px', color: '#000', fontWeight: '500' }}>Name</label>
                                            <input name="name" type="text" className="form-control profile-input" defaultValue={this.state.name} onChange={event => this.handleUserInput(event)} value={this.state.name}></input>
                                        </div>
                                        <div className="form-group form-line-active">
                                            <label style={{ fontSize: '14px', color: '#000', fontWeight: '500' }}>Email</label>
                                            <div className="row" style={{ padding: '0 14px' }}><input name="emailId" type="text" className="form-control profile-input readonlyActive" placeholder={emailId} value={this.state.email} readOnly></input>
                                                <span><button className="email_ver_btn skar btn-in-tr otp-btn verify-number" style={{ height: '28px', marginLeft: '-3px', marginTop: '2px' }} ><img style={{ width: '12px', height: '12px', marginRight: '4px' }} src={iconSuccess}></img>verified
                                                </button></span></div>
                                        </div>
                                        <div className="form-group form-line-active">
                                            <label style={{ fontSize: '14px', color: '#000', fontWeight: '500' }}>Phone Number</label>
                                            <div className="row" style={{ padding: '0 14px' }}><input name="phoneNumber" type="tel" className="form-control profile-input" defaultValue={this.state.oldCallingCode + this.state.oldphonenumber} onChange={event => this.handleUserInput(event)} ></input>
                                                {this.state.otpSent == false && this.state.phoneVerified == false && this.state.phoneNumber && this.state.phoneError == null ? <span><button className="ver_btn skar btn-in-tr otp-btn verify-number" style={{ height: '35px', border: '1px solid #e5e5e8', marginLeft: '3px' }} onClick={(e) => { e.preventDefault(); this.sendOTP(); return false }}>
                                                    <img style={{ marginRight: '5px' }} src={checkmark}></img>Verified
                                                </button></span> : ''}
                                                {this.state.phoneVerified ? <span><button className="ver_btn skar btn-in-tr otp-btn verify-number" style={{ height: '28px', marginLeft: '-4px', marginTop: '2px'}} disabled={this.state.phoneVerified}><img style={{  width: '12px', height: '12px', marginRight: '3px' }} src={iconSuccess}></img>Verified
                                                </button></span> : ''}</div>
                                            {this.state.phoneError ? <span style={{ color: 'red' }}>{this.state.phoneError}</span> : ''}
                                        </div>
                                        {this.state.otpSent ? <div className="form-group form-line-active">
                                            <label style={{ fontSize: '14px', color: '#000', fontWeight: '500' }}>OTP</label>
                                            <div className="row" style={{ padding: '0 14px' }}><input name="otp" type="number" maxLength="10" className="form-control profile-input" placeholder="Enter OTP here" onChange={event => this.handleUserInput(event)} defaultValue={this.state.otp}></input><button className="ver_btn skar btn-in-tr otp-btn verify-number" onClick={(e) => { e.preventDefault(); this.verifyOTP(); return false }} style={{ height: '35px', marginLeft: '3px', border: '1px solid #e5e5e8' }}><img style={{ marginRight: '5px' }} src={checkmark}></img>Verify
                                                </button>
                                                <div >
                                                    {this.state.otpError ? <div style={{ color: 'red' }}>{this.state.otpError}</div> : ''}
                                                    <a style={{ color: '#FC7242', textDecoration: 'underline', cursor: 'pointer', fontSize: '12px', fontWeight: '500' }} onClick={(e) => { e.preventDefault(); this.resendOtp(); return false }}>Resend OTP</a></div></div>

                                        </div> : ''}
                                        <div className="form-group form-line-active">
                                            <label style={{ fontSize: '14px', color: '#000', fontWeight: '500' }}>Role</label>
                                            <input name="role" type="text" className="form-control profile-input readonlyActive" placeholder={Role} readOnly></input>
                                        </div>
                                        <div className="form-group ">
                                            <button style={{ marginTop: '10px' }} className="sk_btn cancel_1 " id="save_1" data-toggle="modal" data-target="#myModal" onClick={(e) => { e.preventDefault(); this.saveUserDetails(); return false }}>
                                                <i className="sk_icon sk_icon_Icon-feather-save"></i>Save
                                            </button>
                                            {this.state.savedDetails ? <span style={{ color: '#FFB13D' }}>{this.state.savedDetails}</span> : ''}
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-4 col-md-4 col-sm-12">
                                    <div className="view-profile">
                                        <label style={{ fontSize: '15px', color: '#000', fontWeight: '500' }}>Profile Picture</label>
                                        <div className="d-flex show-profile">
                                            <div>
                                                <figure>
                                                    {this.state.isNewImageUploaded ? <img style={{minWidth:'53px', minHeight:'53px', maxWidth: '53px', maxHeight: '53px', borderRadius: '50%' }} src={this.state.newImageURL}></img> : <img style={{ width: '53px', height: '53px', maxWidth: '53px', maxHeight: '53px', borderRadius: '50%' }} src={userImageURL}></img>}
                                                </figure>
                                            </div>
                                            <div className="upload-profile-section" >
                                                <button className="sk_btn skar skmt10" style={{ padding: '4px 8px 5px 7px', width: '80%', marginBottom: '5px', marginRight: '21px',cursor:"pointer" }}><input type="file" onChange={this.onFileSelect} />

                                                    <i className="sk_icon sk_icon_camera2"></i>upload
                                                    <Loader style={{ marginLeft: "5px" }} loaded={(this.state.loaderupload == false) ? true : false} lines={13} length={2} width={1} radius={3}
                                                        corners={1} rotate={0} direction={1} color="#000" speed={1}
                                                        trail={60} shadow={false} hwaccel={false} className="spinner"
                                                        zIndex={2e9} top="50%" left="92%" scale={1.00}
                                                        loadedClassName="loadedContent" />
                                                </button>
                                                <p className="aspectRatio" style={{ color: '#59686B', fontWeight: '500' }}>(aspect ratio 1 : 1)</p>

                                                {this.state.photoUploaded ? <span style={{ color: '#FFB13D' }}>{this.state.photoUploaded}</span> : ''}

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-6 col-sm-12" style={this.state.showPasswordTab ? { borderLeft: '1px solid #efefef' } : { borderLeft: 'none' }}>
                            <div style={{ paddingRight: '30px', paddingTop: '10px' }}>
                                <div className="d-flex justify-content-between">
                                    {!this.state.showPasswordTab ? <p className="change-pass-title" style={{ visibility: 'hidden', fontSize: '15px', fontWeight: '600' }}>Password Changed</p> :
                                        <p className="change-pass-title" style={{ fontSize: '15px', fontWeight: '600' }}>Change Password</p>}
                                    {!this.state.showPasswordTab ? <p className="change-password-link cust-link" onClick={(e) => { e.preventDefault(); this.showPassword(); return false }}>Change Password</p>
                                        : <div className="change-password-action" style={{ display: 'block' }}>
                                            <button className="sk_btn cancel_1 " id="save_1" data-toggle="modal" data-target="#myModal" onClick={(e) => { e.preventDefault(); this.savePassword(); return false }}>
                                                <i className="sk_icon sk_icon_Icon-feather-save"></i>Save
                                            </button>
                                            <button className="sk_btn cancel_1 " onClick={(e) => { e.preventDefault(); this.hidePassword(); return false }}>
                                                <i className="sk_icon sk_icon_Icon-ionic-ios-close-circle-outline" ></i>Cancel
                                            </button>
                                        </div>}

                                </div>
                                {this.state.showPasswordTab ? <div style={{ display: 'block', marginTop: '20px' }}>
                                    <div className="form-group form-line-active">
																				<div className="edit-password-label-wrapper">Current Password</div>
																				<div className="edit-password-input-wrapper form-control password-input">
																					<div className="edit-password-input-input">
																						<input name="oldPassword" className="edit-password-input-input-field" type={this.state.showIcon1 ? "text" : "password"} placeholder={"Enter your current password"} onChange={event => this.handleUserInput(event)} defaultValue={this.state.oldPassword} />
																					</div>
																					<div className={'edit-password-show-hide-button ' + (this.state.showIcon1 ? 'makePasswordVisible' : 'makePasswordHidden')} onClick={() => { this.setState({showIcon1:!this.state.showIcon1}) }} />
																				</div>
                                        {this.state.passwordError ? <span style={{ color: 'red' }}>{this.state.passwordError}</span> : ''}
                                    </div>

                                    <div className="form-group form-line-active">
																				<div className="edit-password-label-wrapper">New Password</div>
																				<div className="edit-password-input-wrapper form-control password-input"> 
																					<div className="edit-password-input-input">
																						<input name="newPassword" className="edit-password-input-input-field" type={this.state.showIcon2 ? "text" : "password"} placeholder="Enter new password" onChange={event => this.handleUserInput(event)} defaultValue={this.state.newPassword} />
																					</div> 
																					<div className={'edit-password-show-hide-button ' + (this.state.showIcon2 ? 'makePasswordVisible' : 'makePasswordHidden')} onClick={() => { this.setState({showIcon2:!this.state.showIcon2}) }} />
																				</div>
                                    </div>
																		
                                    <div className="form-group form-line-active">
																				<div className="edit-password-label-wrapper">Confirm Password</div>
																				<div className="edit-password-input-wrapper form-control password-input">
																					<div className="edit-password-input-input">
																						<input name="confirmPassword" className="edit-password-input-input-field" type={this.state.showIcon3 ? "text" : "password"} placeholder="Enter new password again" onChange={event => this.handleUserInput(event)} defaultValue={this.state.confirmPassword}></input>
																					</div>
																					<div className={'edit-password-show-hide-button ' + (this.state.showIcon3 ? 'makePasswordVisible' : 'makePasswordHidden')} onClick={() => { this.setState({showIcon3:!this.state.showIcon3}) }} />
																				</div>
                                    </div>
                                    {this.state.mismatchError ? <span style={{ color: 'red' }}>{this.state.mismatchError}</span> : ''}
                                </div> : ''

                                }
                            </div>
                        </div>

                    </div>
                </Card>

            </div>
        )
    }
}


const mapDispatchToProps = (dispatch) => {
	return {
			showNotification: (val) => dispatch(setGlobalStates(val))
	}
};


export default connect(null, mapDispatchToProps)(Profile)