
import moment from "moment";
import uuid from "react-uuid";
import HWUser from "../HWLibrary/HWUser";
import hwAxios from "../HWLibrary/HWAxios";
import axios from 'axios';
import cache from "../SkSmartUtil/InMemoryCache";
import { cloneDeep } from "lodash";

export const defaultSmartFilterData = (path) => {
	let pathName = window.location.search;
	const accountId = new URLSearchParams(pathName)?.get('accountId');
	return {
		userId: HWUser.userId(),
		orgId: HWUser.orgId(),
		appId: HWUser.appId(),
		"accountFilter": {
			"accountIds": accountId ? [accountId] : [],
			"allFilters": accountId ? [] : ["AllAccounts"],
			accountSelection: "Manual"
		},
		"productFilter": {
			"productIds": [],
			"allFilters": ["AllProducts"]
		},
		path
	}
}
class SmartFilterToken {

	tokenString = null;
	token = null;
	account360Id=null;
	callBacks = [];

	constructor() {
		if (!SmartFilterToken.instance) { SmartFilterToken.instance = this; }
	
		return SmartFilterToken.instance;
	}

	getFilters = () => {
		if (!(this.token && this.token.filters && this.token.filters.length > 0)) { return []; }
		return this.token.filters;
	}
	getFeatureFilters = () => {
		if (!(this.token && this.token.featureFilters && this.token.featureFilters.length > 0)) { return []; }
		return this.token.featureFilters;
	}

	getProductIds = () => {
		if (!(this.token && this.token.productFilter && this.token.productFilter.productIds && this.token.productFilter.productIds.length > 0)) {
			return [];
		}
		return this.token.productFilter.productIds;
	}

	getDateFilter = () => {
		if (!(this.token && this.token.dateFilter && this.token.dateFilter)) {
			return {};
		}
		return this.token.dateFilter;
	}

	getAccountIds = () => {
		if (!(this.token && this.token.accountFilter && this.token.accountFilter.accountIds && this.token.accountFilter.accountIds.length > 0)) {
			return [];
		}
		return this.token.accountFilter.accountIds;
	}
	getAccountSegments = () => {
		if (!(this.token && this.token.accountFilter && this.token.accountFilter.accountSegmentIds && this.token.accountFilter.accountSegmentIds.length > 0)) {
			return [];
		}
		return this.token.accountFilter.accountSegmentIds;
	}
	getAccountSelection = () => {
		if (!(this.token && this.token.accountFilter && this.token.accountFilter.accountSelection)) {
			return "Manual";
		}
		return this.token.accountFilter.accountSelection;
	}

	getAllAccountIds = () => {
		if (!(this.token && this.token.accountFilter && this.token.accountFilter.allFilters && this.token.accountFilter.allFilters.length > 0)) {
			return []
		}
		return this.token.accountFilter.allFilters;
	}
	getAllProductIds = () => {
		if (!(this.token && this.token.productFilter && this.token.productFilter.allFilters && this.token.productFilter.allFilters.length > 0)) {
			return []
		}
		return this.token.productFilter.allFilters;
	}

	addPathFilter = (path) => {
		if (!path) { return; }
		const tokenObject = this.token || {};
		tokenObject.path = path;
		this.token = tokenObject;
	}

	addSubPathFilter = (path) => {
		// if(!path) { return; }
		const tokenObject = this.token || {};
		tokenObject.subPath = path;
		this.token = tokenObject;
	}

	updateGlobalfilter = (dateFilter, featureFilters, accountFilter, productFilter, addCallBacks, path) => {
		const tokenObject = this.token || {};
		tokenObject.dateFilter = dateFilter || {};
		tokenObject.featureFilters = featureFilters;
		tokenObject.accountFilter = accountFilter || this.token.accountFilter;
		tokenObject.productFilter = productFilter || this.token.productFilter;
		if (path) {
			tokenObject.path = path;
		}
		this.token = tokenObject;
		if (addCallBacks) {
			this.callBacks && this.callBacks.forEach((i) => { i.callBack && i.callBack(this.token) })
		}
	}


	// Filter Structure
	// { id : attribute.id, name: attribute.name, condition: condition, values: values, allFilters: allValues }
	addFilter = (filter) => {
		if (!filter) { return; }
		const tokenObject = this.token || {};
		let filters = tokenObject.filters || [];
		const filterExists = (filters.filter(i => i.attribute === filter.attribute).length > 0);
		if (filterExists) { // Replace if exists
			filters = filters.map(i => { if (i.attribute === filter.attribute) { return filter; } return i; });
		} else { // Add new one
			filters = filters.filter(i => i.attribute !== filter.attribute);
			filters.unshift(filter);
		}
		tokenObject.filters = filters;
		this.token = tokenObject;
		this.callBacks && this.callBacks.forEach((i) => { i.callBack && i.callBack(this.token) })
	}

	// Feature Filter Structure
	// { id : attribute.id, name: attribute.name, condition: condition, values: values, allFilters: allValues }
	addFeatureFilter = (featureFilter, dontAddCallBacks, productIds, path, accountIds) => {

		if (!featureFilter) { return; }
		const tokenObject = this.token || {};
		let featureFilters = tokenObject.featureFilters || [];

		if (Array.isArray(featureFilter)) {
			featureFilter && featureFilter.map((eachFeatureFilter) => {
				const filterExists = (featureFilters.filter(i => i.attribute === eachFeatureFilter.attribute).length > 0);
				if (filterExists) { // Replace if exists
					featureFilters = featureFilters.map(i => { if (i.attribute === eachFeatureFilter.attribute) { return eachFeatureFilter; } return i; });
				} else { // Add new one
					featureFilters = featureFilters.filter(i => i.attribute !== eachFeatureFilter.attribute);
					featureFilters.unshift(eachFeatureFilter);
				}
			})
		} else {
			const filterExists = (featureFilters.filter(i => i.attribute === featureFilter.attribute).length > 0);
			if (filterExists) { // Replace if exists
				featureFilters = featureFilters.map(i => { if (i.attribute === featureFilter.attribute) { return featureFilter; } return i; });
			} else { // Add new one
				featureFilters = featureFilters.filter(i => i.attribute !== featureFilter.attribute);
				featureFilters.unshift(featureFilter);
			}
		}


		tokenObject.featureFilters = featureFilters;

		if (productIds) {
			let productFilter = tokenObject.productFilter || {};
			productFilter.productIds = [...new Set(productIds)].filter(i => i && true);
			productFilter.allFilters = [];
			tokenObject.productFilter = productFilter;
		}
		if (accountIds) {
			let accountFilter = tokenObject.accountFilter || {};
			accountFilter.accountIds = [...new Set(accountIds)].filter(i => i && true);
			accountFilter.allFilters = [];
			tokenObject.accountFilter = accountFilter;
		}
		if (path) {
			tokenObject.path = path;
		}
		this.token = tokenObject;
		if (!dontAddCallBacks) {
			this.callBacks && this.callBacks.forEach((i) => { i.callBack && i.callBack(this.token) })
		}
	}

	getFeatureFilter = (featureFilter) => {
		if (!featureFilter) { return; }
		const tokenObject = this.token || {};
		let featureFilters = tokenObject.featureFilters || [];
		let feature = featureFilters?.find(i => i.attribute === featureFilter);
		return feature?.values;
	}

	addDateFilter = (dateEnum, startDate, endDate, addCallBacks, path) => {
		if (!dateEnum) { return; }
		const tokenObject = this.token || {};
		if (path) {
			tokenObject.path = path;
		}
		let dateFilter = tokenObject?.dateFilter || {};
		dateFilter.inLastDays = dateEnum || dateFilter?.inLastDays
		dateFilter.startDate = startDate ? moment(startDate).format("YYYY-MM-DD") : dateFilter?.startDate
		dateFilter.endDate = endDate ? moment(endDate).format("YYYY-MM-DD") : dateFilter?.endDate
		tokenObject.dateFilter = dateFilter;
		this.token = tokenObject;
		if (addCallBacks) {
			this.callBacks && this.callBacks.forEach((i) => { i.callBack && i.callBack(this.token) })
		}
	}

	emptyDateFilter = (addCallBacks) => {
		const tokenObject = this.token || {};
		let dateFilter = {};
		tokenObject.dateFilter = dateFilter;
		this.token = tokenObject;
		if (addCallBacks) {
			this.callBacks && this.callBacks.forEach((i) => { i.callBack && i.callBack(this.token) })
		}
	}

	addAccounts = (accountIds, allAccountIds, dontAddCallBacks, productIds) => {
		if (!accountIds) { return; }
		const tokenObject = this.token || {};
		let updatedaccountFilter = tokenObject.accountFilter || {};
		updatedaccountFilter.accountIds = [...new Set(accountIds)].filter(i => i && true);
		updatedaccountFilter.accountSegmentIds = [];
		updatedaccountFilter.accountSelection = "Manual";
		tokenObject.accountFilter = updatedaccountFilter;
		if (allAccountIds) {
			updatedaccountFilter.allFilters = [...new Set(allAccountIds)].filter(i => i && true);
			tokenObject.accountFilter = updatedaccountFilter;
		}
		if (productIds) {
			let productFilter = tokenObject.productFilter || {};
			productFilter.productIds = [...new Set(productIds)].filter(i => i && true);
			productFilter.allFilters = [];
			tokenObject.productFilter = productFilter;
		}
		this.token = tokenObject;
		if (!dontAddCallBacks && dontAddCallBacks !== true) {
			this.callBacks && this.callBacks.forEach((i) => { i.callBack && i.callBack(this.token) })
		}
	}

	addAllAccounts = (accountIds) => {
		if (!accountIds) { return; }
		const tokenObject = this.token || {};
		let accountFilter = tokenObject.accountFilter || {};
		accountFilter.allFilters = [...new Set(accountIds)].filter(i => i && true);
		accountFilter.accountSegmentIds = [];
		accountFilter.accountSelection = "Manual";
		tokenObject.accountFilter = accountFilter;
		this.token = tokenObject;
		this.callBacks && this.callBacks.forEach((i) => { i.callBack && i.callBack(this.token) })
	}

	addAccountSegments = (segmentIds, dontAddCallBacks) => {
		if (!segmentIds) { return; }
		const tokenObject = this.token || {};
		let accountFilter = tokenObject.accountFilter || {};
		accountFilter.accountSegmentIds = [...new Set(segmentIds)].filter(i => i && true);
		accountFilter.accountIds = []
		accountFilter.allFilters = []
		accountFilter.accountSelection = "AccountSegment";
		tokenObject.accountFilter = accountFilter;
		this.token = tokenObject;
		if (!dontAddCallBacks && dontAddCallBacks !== true) {
			this.callBacks && this.callBacks.forEach((i) => { i.callBack && i.callBack(this.token) })
		}
	}
	removeSegmentFilter = (dontAddCallBacks) => {
		const tokenObject = this.token || {};
		let accountFilter = tokenObject.accountFilter || {};
		accountFilter.accountSegmentIds = [];
		accountFilter.accountIds = []
		accountFilter.allFilters = ["AllAccounts"]
		accountFilter.accountSelection = "Manual";
		tokenObject.accountFilter = accountFilter;
		this.token = tokenObject;
		if (!dontAddCallBacks) {
			this.callBacks && this.callBacks.forEach((i) => { i.callBack && i.callBack(this.token) })
		}
	}

	addAllProducts = (accountIds, dontAddCallBacks, productIds) => {
		if (!accountIds) { return; }
		const tokenObject = this.token || {};
		let productFilter = tokenObject.productFilter || {};
		productFilter.allFilters = [...new Set(accountIds)].filter(i => i && true);
		if (productIds) {
			productFilter.productIds = []
		}
		tokenObject.productFilter = productFilter;
		this.token = tokenObject;
		if (!dontAddCallBacks) {
			this.callBacks && this.callBacks.forEach((i) => { i.callBack && i.callBack(this.token) })
		}
	}

	addProducts = (productIds, dontAddCallBacks, path, dateEnum,) => {
		if (!productIds) { return; }
		const tokenObject = this.token || {};
		let productFilter = tokenObject.productFilter || {};
		productFilter.productIds = [...new Set(productIds)].filter(i => i && true);
		productFilter.allFilters = [];
		tokenObject.productFilter = productFilter;
		if (path) {
			tokenObject.path = path;
		}
		if (dateEnum) {
			let dateFilter = tokenObject?.dateFilter || {};
			dateFilter.inLastDays = dateEnum
			tokenObject.dateFilter = dateFilter;
		}
		this.token = tokenObject;
		if (!dontAddCallBacks) {
			this.callBacks && this.callBacks.forEach((i) => { i.callBack && i.callBack(this.token) })
		}
	}

	removeFilter = (filter) => {
		if (!filter) { return; }
		const tokenObject = this.token || {};
		let filters = tokenObject.filters || [];
		tokenObject.filters = filters.filter(i => (i?.id || i?.attribute) !== filter.id);
		this.token = tokenObject;
		this.callBacks && this.callBacks.forEach((i) => { i.callBack && i.callBack(this.token) })
	}


	removeFeatureFilter = (data, dontAddCallBacks, path) => {
		if (!data) { return; }
		const tokenObject = this.token || {};
		let filters = tokenObject.featureFilters || [];
		tokenObject.featureFilters = filters.filter(i => (i?.id || i?.attribute) !== data.id);
		if (path) {
			tokenObject.path = path
		}
		this.token = tokenObject;

		if (!dontAddCallBacks) {
			this.callBacks && this.callBacks.forEach((i) => { i.callBack && i.callBack(this.token) })
		}
	}

	replaceFilter = (oldFilter, newFilter) => {
		if (!oldFilter || !newFilter) { return; }
		const tokenObject = this.token || {};
		let filters = tokenObject.filters || [];
		filters = filters.map(i => {
			if (i.id === oldFilter.id) { return new newFilter; }
			return i;
		})
		tokenObject.filters = filters;
		this.token = tokenObject;
		this.callBacks && this.callBacks.forEach((i) => { i.callBack && i.callBack(this.token) })
	}

	ofToken = (tokenString) => {
		this.tokenString = tokenString;
		this.token = this.decodeToken(this.tokenString);
		this.callBacks && this.callBacks.forEach((i) => { i.callBack && i.callBack(this.token) })
	}

	encodeToken = () => {
		if (!this.token) { return null; }
		const base64String = window.btoa(JSON.stringify(this.token));
		return base64String ? encodeURIComponent(base64String) : null;
	}

	decodeToken = (uriEncodedBase64String) => {
		if (!uriEncodedBase64String) { return null; }
		const base64String = decodeURIComponent(uriEncodedBase64String);
		return JSON.parse(window.atob(base64String));
	}

	subscribe = (callBack) => {
		const id = uuid();
		this.callBacks.push({ id: id, callBack: callBack });
		return id;
	}

	unSubscribe = (id) => {
		if (!this.callBacks || this.callBacks.length <= 0) { return }
		this.callBacks = this.callBacks.filter((i) => {
			return i.id !== id
		})

	}

	

	globalFilterAccountSegmentsSearchApi = (request) => {
		let url = window.resolveRegion(process.env.REACT_APP_SMART_DASHBOARDS_BASE_URL) + "/smart-filter/account-segments";
		let aConfig = { headers: { "Authorization": HWUser.accessToken(), "Content-Type": "application/json", "addFilter": true } };
		aConfig.params = {
			orgId: HWUser.orgId(),
			appId: HWUser.appId(),
			userId: HWUser.userId(),
			...request
		}
		return hwAxios.get(url, aConfig);
	}

	globalFilterAccountSearchApi = (request) => {
		if(request){
			let url = window.resolveRegion(process.env.REACT_APP_SMART_DASHBOARDS_BASE_URL) + "/smart-filter/accounts";
			let aConfig = { headers: { "Authorization": HWUser.accessToken(), "Content-Type": "application/json", "addFilter": true } };
			aConfig.params = {
				orgId: HWUser.orgId(),
				appId: HWUser.appId(),
				userId: HWUser.userId(),
				...request
			}
			return hwAxios.get(url, aConfig);
		}else{
			this.fetchAccountList()
		}
	
	}

	fetchAccountList = () => {
		const cacheKey = HWUser.userId();
		const fetchFunction = () => {
			let url = window.resolveRegion(process.env.REACT_APP_SMART_DASHBOARDS_BASE_URL) + "/smart-filter/accounts";
			let aConfig = { headers: { "Authorization": HWUser.accessToken(), "Content-Type": "application/json", "addFilter": true } };
			aConfig.params = {
				orgId: HWUser.orgId(),
				appId: HWUser.appId(),
				userId: HWUser.userId(),
			}
			return hwAxios.get(url, aConfig);
		}
		return cache.get(cacheKey, cache.CacheModule.accounts, fetchFunction);
	}
	serachAccountsOptionsFor = (request) => {
		let url = window.resolveRegion(process.env.REACT_APP_SMART_DASHBOARDS_BASE_API_URL) + "/account/search";
		let aConfig = { headers: { "Authorization": HWUser.accessToken(), "Content-Type": "application/json" } };
		let token = JSON.stringify(defaultSmartFilterData())
		token = btoa(token.replace(/[\u00A0-\u2666]/g, function (c) {
			return '&#' + c.charCodeAt(0) + ';';
		}));
		aConfig.params = {
			orgId: HWUser.orgId(),
			appId: HWUser.appId(),
			userId: HWUser.userId(),
			smartFilterToken: token,
			...request
		}
		return axios.get(url, aConfig);
	}
	getAccountFilterCount = (request) => {
		let url = window.resolveRegion(process.env.REACT_APP_SMART_DASHBOARDS_BASE_URL) + "/smart-filter/account-count";
		let aConfig = { headers: { "Authorization": HWUser.accessTokePn(), "Content-Type": "application/json", "addFilter": true } };
		aConfig.params = {
			orgId: HWUser.orgId(),
			appId: HWUser.appId(),
			userId: HWUser.userId(),
			...request
		}
		return hwAxios.get(url, aConfig);
	}
	getMainDashboard = (request) => {
		let url = window.resolveRegion(process.env.REACT_APP_SMART_DASHBOARDS_BASE_URL) + "/mail-dashboard";
		let aConfig = { headers: { "Authorization": HWUser.accessToken(), "Content-Type": "application/json" } };
		aConfig.params = {
			orgId: HWUser.orgId(),
			appId: HWUser.appId(),
			userId: HWUser.userId()
		}
		return axios.get(url, aConfig);
	}

	fetchUserFilterPreference = (features) => {
		// debugger
		const cacheKey = HWUser.userId();
		const fetchFunction = () => {
			let url = window.resolveRegion(process.env.REACT_APP_SMART_DASHBOARDS_BASE_URL) + "/smart-filter/user-filter-preference";
			let aConfig = { headers: { "Authorization": HWUser.accessToken(), "Content-Type": "application/json" } };
			aConfig.params = {
				orgId: HWUser.orgId(),
				appId: HWUser.appId(),
				userId: HWUser.userId()
			}
			return axios.get(url, aConfig);
		}
		return cache.get(cacheKey, cache.CacheModule.userFilterPreference, fetchFunction);
	}

	updateUserFilterPreference = async (data) => {
		let updateFunction = async () => {
			let url = window.resolveRegion(process.env.REACT_APP_SMART_DASHBOARDS_BASE_URL) + "/smart-filter/user-filter-preference";
			let aConfig = { headers: { "Authorization": HWUser.accessToken(), "Content-Type": "application/json" } };
			let request = { userId: HWUser.userId(), orgId: HWUser.orgId(), appId: HWUser.appId(), ...data }
			return await axios.put(url, request, aConfig);
		}
		const cacheKey = HWUser.userId();
		const cacheReducer = (responseData) => {
			return responseData;
		}
		return await cache.cacheAndUpdateAfterResponse(cacheKey, cache.CacheModule.userFilterPreference, cacheReducer, updateFunction);
	}

	updateSkAccountfilterOnRedirection = async (accountId, allFilter, path) => {
		let existingFilter = cloneDeep(smartFilterTokenService?.token);
		if (accountId) {
			existingFilter.accountFilter.accountIds = [accountId]
		} else {
			existingFilter.accountFilter.accountIds = []
		}
		if (allFilter) {
			existingFilter.accountFilter.allFilters = [allFilter]
		} else {
			existingFilter.accountFilter.allFilters = []
		}
		if (path) {
			existingFilter.path = path
		}

		let request = {
			appId: HWUser.appId(),
			orgId: HWUser.orgId(),
			userId: HWUser.userId(),
			...existingFilter
		}
		await smartFilterTokenService.updateUserFilterPreference(request).then((response) => {
		
			let {featurePreferences, ...data} = response?.data;
			data.featureFilters = featurePreferences[path];
			// this.token = data

		})
	}
}

var smartFilterTokenService = new SmartFilterToken();
export default smartFilterTokenService;