import { hsAction } from "../constants/actionType";

const initialState = {
    hsCategory: [],
    hsEditConfig:{},
    isError: "",
    isApiLoading: false,
    isLoading:false,
    errorPopupMsg: "",
    successMsg: "",
    activeId: "",
    apiError:'',
    renderToggle: "",
    nextToken:null,
    searchTxt:'',
    sortBy:'',
  
};

const hsReducer = (state = initialState, { type, payload } = {}) => {
  
  switch (type) {
    case hsAction.FETCH_HS_CAT: 
    case hsAction.CHANGE_HS_STATUS: 
    case hsAction.SET_HS_STATE: 
    case hsAction.FETCH_HS_EDIT_CONFIG: 
    case hsAction.SAVE_HS_CONFIG: 
    case hsAction.SET_HS_LOADING: 
    case hsAction.FETCH_RISK_RATING:{return { ...state,...payload}}
    default:return state;
  }
};

export default hsReducer;
