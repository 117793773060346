import React, { Component } from 'react'
import { Snackbar } from '@material-ui/core';
import Grow from '@material-ui/core/Grow';
import HWLocalStorage from './HWLocalStorage';

class HWMessage extends Component {

    constructor(props) {
        super(props);
        this.state = { show: false }
        HWMessage.autoHideDuration = HWLocalStorage.load("sk-test-popup-config-milisec") || 1500;//milliseconds
    }

    static autoHideDuration = 1500; //milliseconds
    
    static showMessage = (message, timeInterval) => {
        HWMessage.autoHideDuration = timeInterval || HWMessage.autoHideDuration;
        window.postMessage(JSON.stringify({skInfoMessageToShow: message}), window.origin);
    }

    componentDidMount() {
        window.addEventListener("message", this.onNewMessage, false);
    }

    onNewMessage = (messageEvent) => {
        if (messageEvent.origin !== window.origin) return;
        try {
            let platformMessage = JSON.parse(messageEvent.data);
            if (platformMessage.skInfoMessageToShow) {
                this.setState({ show: true, message: platformMessage.skInfoMessageToShow });
            }
        } catch (error) { }
    }

    componentWillUnmount() {
        window.removeEventListener("message", this.onNewMessage, false);
    }

    render() {
        return(
            <Snackbar anchorOrigin={{ vertical: 'top', horizontal: 'center', }} open={this.state.show}
             autoHideDuration={HWMessage.autoHideDuration || 6000} onClose={this.onClose}
             TransitionComponent={Grow}
            message={this.state.message}
            action={
              <React.Fragment>
                {/* <Button color="secondary" size="small" onClick={this.onClose}>
                  UNDO
                </Button> */}
                {/* <IconButton size="small" aria-label="close" color="inherit" onClick={this.onClose}>
                  <CloseIcon fontSize="small" />
                </IconButton> */}
              </React.Fragment>
            }
          />)
    }

    onClose = () => {
        this.setState({show: false , message: "" });
    }

}

export default HWMessage;