import axios from 'axios';
import hwAxios from '../../../HWLibrary/HWAxios';
import HWUser from '../../../HWLibrary/HWUser';
import HWLocalStorage from '../../../HWLibrary/HWLocalStorage';

class SettingServices { 

    fetchCustomerJourneyPhases(data) {
        // console.log("data::",data)
        let config = { headers: { "Authorization": HWUser.accessToken(), "Content-Type": "application/json" } };
        let url = window.resolveRegion(process.env.REACT_APP_DASHBOARDS_BASE_API_URL) + "/csm/customer-journey-phases";
        config.params = { orgId: data.orgId,appId: HWUser.appId() }
        return axios.get(url, config).then(response => { return response.data.phases });
    }

    // User this only for Customer Journey Phase pop up screen.
    fetchCustomerAccountListByPhase = () => {
        let data   = { appId: HWUser.appId(), userId: HWUser.userId(), filterByPhase: true }
        let config = { headers: { "Authorization": HWUser.accessToken(), "Content-Type": "application/json" } };
        let url = window.resolveRegion(process.env.REACT_APP_PLATFORM_BASE_API_URL_WEB_ADMIN) + "/multilevel/customer/list";
        return axios.post(url, data, config).then(response => { return response.data.customerAccounts });
    }

    updateCustomerPhase(data) {
        let url = window.resolveRegion(process.env.REACT_APP_DASHBOARDS_BASE_API_URL) + "/csm/customer-journey-phases";
        return axios.post(url, data).then(response => { return response });
    }

    updateRolesAndPermission(data) {
        let config = { headers: { "Authorization": HWUser.accessToken(), "Content-Type": "application/json" } };
        let url    = window.resolveRegion(process.env.REACT_APP_DASHBOARDS_BASE_API_URL) + "/platform-setting/configure-role-permission";
        return axios.post(url, data, config).then(response => { return response });
    }

    SettingsSKUserCacheKey = "sk-Settings-SKUsers-data"
    isServerCallInProgress = false;
    fetchAllSmartkarrotUser(data, cacheExists, onResponse, onError) {
        if (!cacheExists) {
            const cachedData = HWLocalStorage.load(this.SettingsSKUserCacheKey);
            if (cachedData) {
                cacheExists = true;
                onResponse(cachedData);
            }
        }
        if (this.isServerCallInProgress) { return; }
        this.isServerCallInProgress = true;

        let url = window.resolveRegion(process.env.REACT_APP_PLATFORM_BASE_API_URL_WEB_ADMIN) + "/rolesandpermission/fetchallusers";
        let aConfig = { headers: { "Authorization": HWUser.accessToken(), "Content-Type": "application/json" } };
        axios.post(url, data, aConfig).then((response) => {
            HWLocalStorage.save(response.data, this.SettingsSKUserCacheKey);
            onResponse(response.data);
        }).catch(() => {  onError && onError(); }).finally(() => { this.isServerCallInProgress = false; });
    }

    SettingsAssignAccountCacheKey = "sk-Settings-AssignAccount-data"
    isServerCallInProgress = false;

    fetch(data, cacheExists, onResponse){
        if (!cacheExists) {
            const cachedData = HWLocalStorage.load(this.SettingsAssignAccountCacheKey);
            if (cachedData) {
                cacheExists = true;
                onResponse(cachedData);
            }
        }
        if (this.isServerCallInProgress) { return; }
        this.isServerCallInProgress = true;
        let url = window.resolveRegion(process.env.REACT_APP_BASE_API_ML_ANALYTICS_EVENTS) + "customer-csm-mapping/fetch-scaled";
        let aConfig = { headers: { "Authorization": HWUser.accessToken(), "Content-Type": "application/json" } };
        axios.post(url, data, aConfig).then((response) => {
            // HWLocalStorage.save(response.data, this.SettingsAssignAccountCacheKey);
            onResponse(response.data);
        }).finally(() => this.isServerCallInProgress = false);
    }
    fetchAccountExecutive(data, cacheExists, onResponse){
        let url = window.resolveRegion(process.env.REACT_APP_PLATFORM_BASE_API_URL_WEB_ADMIN)+ "/multilevel/account-executive-mapping";
        let aConfig    = { headers: { "Authorization": HWUser.accessToken(), "Content-Type": "application/json" } };
        aConfig.params = data;
        return axios.get(url, aConfig);
    }

    fetchImplManager(data, cacheExists, onResponse){
        let url = window.resolveRegion(process.env.REACT_APP_PLATFORM_BASE_API_URL_WEB_ADMIN)+ "/multilevel/implementation-manager-mapping";
        let aConfig    = { headers: { "Authorization": HWUser.accessToken(), "Content-Type": "application/json" } };
        aConfig.params = data;
        return axios.get(url, aConfig);
        // return axios.get(url,aConfig).then(response => { return response });
    }
    SettingsAccountCustomersListCacheKey = "sk-Settings-AccountCustomersList-data"
    isServerCallInProgress = false;
    fetchCustomersList(data, cacheExists, onResponse){
        if (!cacheExists) {
            const cachedData = HWLocalStorage.load(this.SettingsAccountCustomersListCacheKey);
            if (cachedData) {
                cacheExists = true;
                onResponse(cachedData);
            }
        }
        // if (this.isServerCallInProgress) { return; }
        this.isServerCallInProgress = true;
        let url = window.resolveRegion(process.env.REACT_APP_CUSTOMER_ACCOUNT_API_URL);
        let aConfig = { headers: { "Authorization": HWUser.accessToken(), "Content-Type": "application/json" } };
        axios.post(url, data, aConfig).then((response) => {
            HWLocalStorage.save(response.data, this.SettingsAccountCustomersListCacheKey);
            onResponse(response.data);
        }).finally(() => this.isServerCallInProgress = false);

    }

    // getreferenceDate=(data) => {
    //     let url = window.resolveRegion(process.env.REACT_APP_PLATFORM_BASE_API_URL_WEB_ADMIN) + "/rolesandpermission/reference-data";
    //     return axios.get(url, {
    //         params: {
    //             userId: HWUser.userId(),
    //             appId: HWUser.appId(),
    //             orgId:HWUser.orgId()
    //         }
    //     })
    // }

    listAccountUsers(data){
        let url = window.resolveRegion(process.env.REACT_APP_PLATFORM_BASE_API_URL_WEB_ADMIN)+"/webapp/list-account-users"
        let aConfig = { headers: { "Authorization": HWUser.accessToken(), "Content-Type": "application/json" } };
        return axios.post(url, data,aConfig).then(response => { return response.data });
    }

    makeAsKeyPersonnel = (data) => {
        let url = window.resolveRegion(process.env.REACT_APP_PLATFORM_BASE_API_URL_WEB_ADMIN)+"/webapp/update-analytics-user-details"
        let aConfig = { headers: { "Authorization": HWUser.accessToken(), "Content-Type": "application/json" } };
        return axios.post(url, data,aConfig).then(response => { return response.data });
    }

    updateAssignAccounts = (data) =>{
        let url = window.resolveRegion(process.env.REACT_APP_BASE_API_ML_ANALYTICS_EVENTS)+"customer-csm-mapping/update"
        let aConfig = { headers: { "Authorization": HWUser.accessToken(), "Content-Type": "application/json" } };
        return axios.post(url, data,aConfig).then(response => { return response.data });
    }

    resendVerificationLink = (data) => {
        let url = window.resolveRegion(process.env.REACT_APP_DASHBOARDS_BASE_API_URL)+"/platform-setting/resend-verification-link"
        let aConfig = { headers: { "Authorization": HWUser.accessToken(), "Content-Type": "application/json" } };
        return axios.post(url, data,aConfig).then(response => { return response.data });
    }

    fetchEventDataSummary = (data) => {
        let url = window.resolveRegion(process.env.REACT_APP_DASHBOARDS_BASE_API_URL) + "/platform-setting/event-data/summary";
        let aConfig = { headers: { "Authorization": HWUser.accessToken(), "Content-Type": "application/json", "addFilter": true, "addOnlySelectedFilter": true } };
        aConfig.params = { userId: data.userId,appId: data.appId,filter: data.filter,customerAccountId:data.customerAccountId || null,forFeatureEventCountSummary: true }
        return hwAxios.get(url, aConfig).then(response => { return response.data });
    }

    fetchEventDataLogs = (data) => {
        // https://hp1gviybxb.execute-api.us-east-1.amazonaws.com/v3/platform-setting/event-data/logs
        let url = window.resolveRegion(process.env.REACT_APP_DASHBOARDS_BASE_API_URL) + "/platform-setting/event-data/logs";
        let aConfig = { headers: { "Authorization": HWUser.accessToken(), "Content-Type": "application/json", "addFilter": true, "addOnlySelectedFilter": true } };
        aConfig.params = { userId: data.userId, appId: data.appId, customerAccountId:data.customerAccountId || null,
            feature:data.feature || null,eventName:data.eventName || null , nextToken:data.nextToken || null,platform:data.platform || null,
            searchText:data.searchText || null,userId:data.userId
        }
        return hwAxios.get(url,aConfig).then(response => { return response.data });
    }

    fetchEventDataFilter = (data) => {
        // "https://hp1gviybxb.execute-api.us-east-1.amazonaws.com/v3/platform-setting/event-data/possible-attribute-values"
        let url = window.resolveRegion(process.env.REACT_APP_DASHBOARDS_BASE_API_URL) + "/platform-setting/event-data/possible-attribute-values";
        return axios.get(url, {
            params: {
                userId: data.userId,
                appId: data.appId,
            }
        }).then(response => { return response.data });
    }
    
    fetchUserList = (data) => {
        // "https://hp1gviybxb.execute-api.us-east-1.amazonaws.com/v3/platform-setting/event-data/possible-attribute-values"
        let url = window.resolveRegion(process.env.REACT_APP_DASHBOARDS_BASE_API_URL) + "/platform-setting/event-data/user-name-attribute-values";
        return axios.get(url, {
            params: {
                appId: data.appId,
                searchText:data.searchText,
            }
        }).then(response => { return response.data });
    }

   /*  fetchAccountCustomerSegment = (data) => {
        let url = window.resolveRegion(process.env.REACT_APP_DASHBOARDS_BASE_API_URL) + "/platform-setting/account-management/customer-segment";
        return axios.get(url, {
            params: {
                orgId: data.orgId
            }
        }).then(response => { return response.data });
    } */

    fetchAccountCustomerSegment = (data) => {
        let url = window.resolveRegion(process.env.REACT_APP_DASHBOARDS_BASE_API_URL) + "/platform-setting/account-management/customer-category";
        return axios.get(url, {
            params: { appId: HWUser.appId(),
                orgId:HWUser.orgId()}
        }).then(response => { return response.data });
    }

   

    updateAccountCustomerSegment = (data) => {
       
        let url = window.resolveRegion(process.env.REACT_APP_DASHBOARDS_BASE_API_URL) + "/platform-setting/account-management/customer-segment";
        return axios.post(url,data).then(response => { return response.data });
    }



    deleteAccountCustomerSegment = (data) => {
        let url = window.resolveRegion(process.env.REACT_APP_DASHBOARDS_BASE_API_URL) + "/platform-setting/account-management/customer-segment";
        return axios.post(url,data).then(response => { return response.data });
    }

    fetchAccountAccountDetails = (data) => {
    let url = window.resolveRegion(process.env.REACT_APP_DASHBOARDS_BASE_API_URL) + "/platform-setting/account-management/account-detail";
        let params = {
            orgId: HWUser.orgId(),
            appId:HWUser.appId(),
            userId:HWUser.userId()
        }
        if(data==='undefined'){params.filterToken=data}
        return axios.get(url, {
            params: params
        }).then(response => { return response.data });
    }
    fetchScaleAccountAccountDetails = (data) => {
        let url = window.resolveRegion(process.env.REACT_APP_SMART_DASHBOARDS_BASE_API_URL) + "/multi-product/account-management";
        let aConfig = { headers: { "Authorization": HWUser.accessToken(), "Content-Type": "application/json" } };
        aConfig.params = { ...data,userId:HWUser.userId()}
        return hwAxios.get(url, aConfig).then(response => { return response.data });
    }

    


    addSegmentCategory = (data) => {
        let url = window.resolveRegion(process.env.REACT_APP_DASHBOARDS_BASE_API_URL) + "/platform-setting/account-management/customer-category";
        let aConfig = { headers: { "Authorization": HWUser.accessToken(), "Content-Type": "application/json" } };
         data = { ...data, appId: HWUser.appId(), orgId: HWUser.orgId()}
        return axios.post(url, data, aConfig);
    }

    updateSegmentCategory = (updatedObj) => {
        let url = window.resolveRegion(process.env.REACT_APP_DASHBOARDS_BASE_API_URL) + "/platform-setting/account-management/customer-category";
        let aConfig    = { headers: { "Authorization": HWUser.accessToken(), "Content-Type": "application/json" } };
        let data       = { appId: HWUser.appId(), orgId: HWUser.orgId(), ...updatedObj};
        return axios.put(url,data,aConfig).then(response => { return response.data });
    }

    updatePhase=(updatedObj) => {
        let url = window.resolveRegion(process.env.REACT_APP_DASHBOARDS_BASE_API_URL) + "/platform-setting/event-data/phase-edit";
        let aConfig    = { headers: { "Authorization": HWUser.accessToken(), "Content-Type": "application/json" } };
        let data       = {...updatedObj};
        return axios.put(url,data,aConfig).then(response => { return response.data });
    }

    deleteSegmentCategory = (segmentName) => {
        let url = window.resolveRegion(process.env.REACT_APP_DASHBOARDS_BASE_API_URL) + "/platform-setting/account-management/customer-category";
        let aConfig = { headers: { "Authorization": HWUser.accessToken(), "Content-Type": "application/json" } };
        aConfig.params = { orgId: HWUser.orgId(), appId: HWUser.appId(),segmentName: segmentName }
        return axios.delete(url, aConfig)
    }

    updateAccountAccountDetails = (data) => {
        let url = window.resolveRegion(process.env.REACT_APP_DASHBOARDS_BASE_API_URL) + "/platform-setting/account-management/account-detail";
        return axios.post(url,data).then(response => { return response.data });
    }

    loginAs = (data) => {
        let config = { headers: { "Authorization": HWUser.accessToken(), "Content-Type": "application/json" } };
        let url = window.resolveRegion(process.env.REACT_APP_SUPER_ADMIN_SETTING_BASE_API_URL) + "superAdmin/loginSuperAdminAs";
        return axios.post(url,data,config).then(response => { return response.data });
    }

		loginWithAuthToken = (data) => {
			let config = { headers: { "Authorization": data?.token, "Content-Type": "application/json" } };
			let url = window.resolveRegion(process.env.REACT_APP_SUPER_ADMIN_SETTING_BASE_API_URL) + "superAdmin/loginSuperAdminAs";
			return axios.post(url,data,config).then(response => { return response.data });
	}

    fetchCustomerSuccessPlay(data) {
        // console.log("data::",data)
        let config = { headers: { "Authorization": HWUser.accessToken(), "Content-Type": "application/json" } };
        let url = window.resolveRegion(process.env.REACT_APP_DASHBOARDS_BASE_API_URL) + "/platform-setting/success-play";
        config.params = { orgId: data.orgId }
        return axios.get(url, config).then(response => { return response.data.accountSegmentDetails });
    }
    
    updateSuccessPlay(data) {
        let url = window.resolveRegion(process.env.REACT_APP_DASHBOARDS_BASE_API_URL) + "/platform-setting/success-play";
        return axios.post(url, data).then(response => { return response });
    }

    fetchTouchpointDigestSettings = () => {
        let url = window.resolveRegion(process.env.REACT_APP_DASHBOARDS_BASE_API_URL) + "/touchpoints/digest-settings";
        let config = { headers: { "Authorization": HWUser.accessToken(), "Content-Type": "application/json" } };
        config.params = { appId: HWUser.appId(), userId: HWUser.userId()  }
        return axios.get(url, config);
    }

    fetchSPDigestSettings = () => {
        let url = window.resolveRegion(process.env.REACT_APP_SMART_DASHBOARDS_BASE_API_URL) + "/success-play/planner";
        let config = { headers: { "Authorization": HWUser.accessToken(), "Content-Type": "application/json" } };
        config.params = { appId: HWUser.appId(), userId: HWUser.userId()  }
        return axios.get(url, config);
    }
    saveTouchpointDigestSettings=(request) => {
        let url    = window.resolveRegion(process.env.REACT_APP_DASHBOARDS_BASE_API_URL) + "/touchpoints/digest-settings";
        let config = { headers: { "Authorization": HWUser.accessToken(), "Content-Type": "application/json" } };
        let data   = { appId: HWUser.appId(), userId: HWUser.userId(), ...request };
        return axios.put(url, data, config);
    }

    saveSPDigestSettings=(request) => {
        let url    = window.resolveRegion(process.env.REACT_APP_SMART_DASHBOARDS_BASE_API_URL) + "/success-play/planner";
        let config = { headers: { "Authorization": HWUser.accessToken(), "Content-Type": "application/json" } };
        let data   = { appId: HWUser.appId(), userId: HWUser.userId(), ...request };
        return axios.put(url, data, config);
    }

    fetchCreateAccSegment = () => {
       let url = window.resolveRegion(process.env.REACT_APP_DASHBOARDS_BASE_API_URL) + "/multi-product/account/reference-data/account-attributes";
       //let url='/acSegment.json';
        let config = { headers: { "Authorization": HWUser.accessToken(), "Content-Type": "application/json" } };
        config.params = { appId: HWUser.appId(), orgId: HWUser.orgId(),userId:HWUser.userId()  }
        return axios.get(url, config);
    }
    checkIdIsValid = (id) => {
        let url = window.resolveRegion(process.env.REACT_APP_SMART_DASHBOARDS_BASE_API_URL)+ "/multi-product/account-validate";
         let config = { headers: { "Authorization": HWUser.accessToken(), "Content-Type": "application/json" } };
         config.params = { appId: HWUser.appId(), orgId: HWUser.orgId(),customerAccountId:id,userId:HWUser.userId()  }
         return axios.get(url, config);
     }
    

    fetchAccProductList = () => {
         let url = window.resolveRegion(process.env.REACT_APP_DASHBOARDS_BASE_API_URL) + "/multi-product/account/reference-data/product-list";
        //let url='/acProductList.json';
         let config = { headers: { "Authorization": HWUser.accessToken(), "Content-Type": "application/json" } };
         config.params = { appId: HWUser.appId(), orgId: HWUser.orgId(),userId:HWUser.userId()  }
         return axios.get(url, config);
     }

     fetchSingleAccDetails = (accountId) => {
          let url = window.resolveRegion(process.env.REACT_APP_DASHBOARDS_BASE_API_URL) + "/multi-product/account";
         //let url='/acProductList.json';
          let config = { headers: { "Authorization": HWUser.accessToken(), "Content-Type": "application/json" } };
          config.params = { accountId:accountId, orgId: HWUser.orgId(),appId: HWUser.appId(),userId:HWUser.userId()  }
          return axios.get(url, config);
      }


     fetchAccCSMList = () => {
        let url = window.resolveRegion(process.env.REACT_APP_DASHBOARDS_BASE_API_URL) + "/multi-product/account/reference-data/csm";
        //let url='/acCSMList.json';
         let config = { headers: { "Authorization": HWUser.accessToken(), "Content-Type": "application/json" } };
         config.params = { appId: HWUser.appId(), orgId: HWUser.orgId(),userId:HWUser.userId()  }
         return axios.get(url, config);
     }

     

     saveAccount = (data) => {
        let url = window.resolveRegion(process.env.REACT_APP_DASHBOARDS_BASE_API_URL) + "/multi-product/account";
        let aConfig = { headers: { "Authorization": HWUser.accessToken(), "Content-Type": "application/json" } };
         data = { ...data, appId: HWUser.appId(), orgId: HWUser.orgId(),userId:HWUser.userId()}
        return axios.post(url, data, aConfig);
    }

    editAccount = (data) => {
        let url = window.resolveRegion(process.env.REACT_APP_DASHBOARDS_BASE_API_URL) + "/multi-product/account";
        let aConfig = { headers: { "Authorization": HWUser.accessToken(), "Content-Type": "application/json" } };
         data = { ...data, appId: HWUser.appId(), orgId: HWUser.orgId(),userId:HWUser.userId()}
        return axios.put(url, data, aConfig);
    }


    generatePreSignedUrlForCSV = () => {
        let aConfig = { headers: { "Authorization": HWUser.accessToken(), "Content-Type": "application/json" } };
        let request = {type: "IMPORT_CONTACTS_SPREADSHEETS",fileExtension: ".xlsx"}
        let url = window.resolveRegion(process.env.REACT_APP_DASHBOARDS_BASE_API_URL) + "/platform-setting/get-pre-signed-url"
        return axios.post(url,request,aConfig)
    }

    generatePFUserPreSignedUrlForCSV = (upload_enum,fileExt) => {
        let aConfig = { headers: { "Authorization": HWUser.accessToken(), "Content-Type": "application/json" } };
        let request = {type: upload_enum,fileExtension:fileExt}
        let url = window.resolveRegion(process.env.REACT_APP_DASHBOARDS_BASE_API_URL) + "/platform-setting/get-pre-signed-url"
        return axios.post(url,request,aConfig)
    }

    uploadImpPFUserImage(request, uploadURL, fileType){
        let config = { headers: { "content-type": fileType } }
        request = {orgId:HWUser.orgId(),...request}
        return axios.post(uploadURL, request, config);
    }

    importContacts = (request)=>{
        let aConfig = { headers: { "Authorization": HWUser.accessToken(), "Content-Type": "application/json" } };
        // let url = window.resolveRegion(process.env.REACT_APP_DASHBOARDS_BASE_API_URL) + "/multi-product/account/bulk";
        let url = window.resolveRegion(process.env.REACT_APP_SMART_DASHBOARDS_BASE_API_URL) + "/platform-setting/account/bulk";
        request = { ...request, orgId: HWUser.orgId(),appId: HWUser.appId(),userId:HWUser.userId()}
        return axios.post(url,request,aConfig)
    }

    fetchAssociatedAccounts = (request) => {
        let url = window.resolveRegion(process.env.REACT_APP_SMART_DASHBOARDS_BASE_API_URL) + "/user-management/user-association";
        let config = { headers: { "Authorization": HWUser.accessToken(), "Content-Type": "application/json" } };
             config.params = {...request }
             return axios.get(url, config);
    }



    fetchAssignedCSMMapping(accountIds){
        let url = window.resolveRegion(process.env.REACT_APP_DASHBOARDS_BASE_API_URL) + "/multi-product/account/csm-mapping";
        let config = { headers: { "Authorization": HWUser.accessToken(), "Content-Type": "application/json" } };
         let data = { orgId: HWUser.orgId(), accountIds:accountIds,userId:HWUser.userId()  }
         return axios.post(url,data,config);
    }

    saveAssignedCSMMapping = (data) => {
        let url = window.resolveRegion(process.env.REACT_APP_DASHBOARDS_BASE_API_URL) + "/multi-product/account/csm-mapping";
        let aConfig = { headers: { "Authorization": HWUser.accessToken(), "Content-Type": "application/json" } };
         data = { ...data, appId: HWUser.appId(), orgId: HWUser.orgId(),userId:HWUser.userId()}
        return axios.put(url, data, aConfig);
    }

    fetchFilteredAccounts = (data) => {
    //    let url = window.resolveRegion(process.env.REACT_APP_DASHBOARDS_BASE_API_URL) + "/platform-setting/account-management/account-detail";
        let url = window.resolveRegion(process.env.REACT_APP_SMART_DASHBOARDS_BASE_API_URL) + "/multi-product/account-management";
        let config = { headers: { "Authorization": HWUser.accessToken(), "Content-Type": "application/json","addFilter": true } };
         config.params = { appId: HWUser.appId(), orgId: HWUser.orgId(),userId:HWUser.userId() }
         return hwAxios.get(url, config);
    }

    fetchAccounts = (accountId) => {
        let url = window.resolveRegion(process.env.REACT_APP_DASHBOARDS_BASE_API_URL) + "/platform-setting/account-management/account-detail";
        let config = { headers: { "Authorization": HWUser.accessToken(), "Content-Type": "application/json" } };
             config.params = { appId: HWUser.appId(), orgId: HWUser.orgId(),userId:HWUser.userId(),accountId:accountId?accountId:null }
             return axios.get(url, config);
    }

    fetchChildAccountsRef = (id) => {
            let url = window.resolveRegion(process.env.REACT_APP_SMART_DASHBOARDS_BASE_API_URL) + "/reference-data/parent";
            let config = { headers: { "Authorization": HWUser.accessToken(), "Content-Type": "application/json" } };
                 config.params = { appId: HWUser.appId(), orgId: HWUser.orgId(), userId: HWUser.userId(), accountId:id }
                 return axios.get(url, config);
            }


    fetchAccountManagementXl(request){
            let config = { headers: { "Authorization": HWUser.accessToken(), "addFilter": true,"Content-Type": "application/json" } };
            let url = window.resolveRegion(process.env.REACT_APP_SMART_DASHBOARDS_BASE_API_URL)+"/export/all-accounts";
            config.params = { appId:HWUser.appId(),userId:HWUser.userId(),orgId:HWUser.orgId()}
            return hwAxios.get(url, config);
            }
    
    fetchTaskDigest=(data) => {
        let config = { headers: { "Authorization": HWUser.accessToken(), "Content-Type": "application/json" } };
        let url = window.resolveRegion(process.env.REACT_APP_SMART_DASHBOARDS_BASE_API_URL) + "/task-digest";
        config.params = {appId: HWUser.appId(),  orgId:HWUser.orgId(), userId: HWUser.userId() }
        return axios.get(url, config)
    }
    
    saveTaskDigestSettings = (data) => {
        let url = window.resolveRegion(process.env.REACT_APP_SMART_DASHBOARDS_BASE_API_URL) + "/task-digest";
        let aConfig = { headers: { "Authorization": HWUser.accessToken(), "Content-Type": "application/json" } };
            data = { ...data, appId: HWUser.appId(), orgId: HWUser.orgId()}
        return axios.put(url, data, aConfig);
    }
}

export default SettingServices;