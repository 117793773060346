import React from 'react';
import Image from '../SkSmartUtil/SkImage/SkImage';
import close from '../assets/images/close.svg';
import { useEffect } from 'react';
import HWLocalStorage from './HWLocalStorage';

const HWPopMsg = (props) => {

    const autoCloseTime = () => {
        // This for Surojit testing, sk-test-popup-config-milisec".
        const configuredWaitTime = HWLocalStorage.load("sk-test-popup-config-milisec");
        return (configuredWaitTime) ? configuredWaitTime: (props.closeTime ? props.closeTime : 1500);
    } 
    
    const hidePopupTimer = () => {
        setTimeout(() => {
            props.close && props.close();
        }, (autoCloseTime()));
        return false;
    };

    useEffect(() => {
        if (props.callingFrom === 'configureProducts') { }
        else {
            props?.autoClose!==false && hidePopupTimer();
            return () => {
                clearTimeout(hidePopupTimer)
            }
        }
    }, [props.close])


    return (
        <div className={`popAlertMessage ${props.className}`} style={{ marginTop: props.callingFrom === "focusAccount" ? "40px" : 0 }}>
					<span className='popAlertMessage_wrapper'>
					{props.message && <span dangerouslySetInnerHTML={{ __html: props.message }}></span>}
            <span className='closePopup' onClick={(e) => { e.stopPropagation(); props.hide() }}><Image src={close} /></span>
					</span>
        </div>
    );
}

export default HWPopMsg;

