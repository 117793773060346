import React, { useEffect } from "react";
import videojs from "video.js";
import "video.js/dist/video-js.css";

export const VideoJS = ( props ) => {

  const videoRef = React.useRef(null);
  const playerRef = React.useRef(null);
  const { options, onReady } = props;

  React.useEffect(() => {
    // make sure Video.js player is only initialized once
    if (!playerRef.current) {
      const videoElement = videoRef.current;
      if (!videoElement) return;

    //   videojs.registerPlugin('transcript', transcript);

      const player = playerRef.current = videojs(videoElement, options, () => {
        // console.log("player is ready");
        onReady && onReady(player);
      });
    } else {
      // you can update player here [update player through props]
      const player = playerRef.current;
      player.autoplay(options.autoplay);
      player.src(options.sources);
      //player.track()

      // Create a track object.
    // const track = new videojs.TextTrack({
    //     id: 'my-spanish-text-track',
    //     kind: 'captions',
    //     label: 'English',
    //     src:'https://stage-csm-dashboard.s3.amazonaws.com/CGM-ZoomTranscript.vtt',
    //     language: 'en'
    // });
  
  // Add the track to the player's audio track list.
//   player.audioTracks().addTrack(track);
    }
  }, [options, videoRef]);

  // Dispose the Video.js player when the functional component unmounts
  useEffect(() => {
    const player = playerRef.current;

    return () => {
      if (player) {
        player.dispose();
        playerRef.current = null;
      }
    };
  }, [playerRef]);

  return (
    <div style={{height:"100%", padding:'0px'}} data-vjs-player>
     {/* props.isTransApiLoading ? <BlockUi blocking={props.isTransApiLoading } className="segmentLoadder" style={{ width: '100%' }}>fetching...</BlockUi>: */ <video ref={videoRef} className="video-js vjs-big-play-centered " />}
      <track src="https://csmstage.smartkarrot.com/CGM-ZoomTranscript.vtt" kind="captions"  srclang="en" label="English"/>
    </div>
  );
}

export default VideoJS;