import React, { useState, useRef, useEffect } from 'react';
import HWUser from '../../HWLibrary/HWUser';
import { ClickAwayListener } from '@material-ui/core';
import Popper from '@material-ui/core/Popper';
import moreIcon from '../../assets/images/icons/moreDots.svg';
import { Link } from 'react-router-dom';
import UiLabel from '../../SkSmartUtil/UiLabel/UiLabel';
const SmartLikePopup = ({ list, children, className, isScroll, likes, isClose, setLikePopupClose, setIsScroll, title, commentId, featureId, ...props }) => {
	const [state, setActState] = useState({
		shouldShowTooltip: false,
		shouldShowTaskActions: false,
		anchorEl: '',
		showAddOrCreateTaskPopUp: false,
		taskIdToShow: null,
		projectId: ''
	});
	const target = useRef();
	const popperRef = React.createRef();
	const setState = (updatedState) => {
		setActState(prevState => (
			{
				...prevState,
				...updatedState,
			}
		))
	}
	useEffect(() => {
		if (isClose) {
			setState({ shouldShowTaskActions: false })
		}
	}, [isClose]);
	useEffect(() => {
		if (isScroll) { setState({ shouldShowTaskActions: false }) }
	}, [isScroll]);
	const handleClick = (e) => {
		if (!state.anchorEl) {
			setState({ anchorEl: e?.currentTarget })
		}
	}
	const activeIcon = likes?.filter(item => item?.userId === HWUser?.userId())[0]?.reaction;
	const likeId = likes?.filter(item => item?.userId === HWUser?.userId())[0]?.likeId;
	return (
		<div onMouseLeave={(e) => {
				e.stopPropagation(); e.preventDefault(); handleClick(null);
				setState({ shouldShowTooltip: state.shouldShowTaskActions, shouldShowTaskActions: false });
			}} className={`${className}Wrap`}>
			{children ? <span ref={target}
				onMouseEnter={(e) => { e.stopPropagation(); e.preventDefault(); handleClick(e, !state.shouldShowTaskActions); setState({ shouldShowTooltip: state.shouldShowTaskActions, shouldShowTaskActions: true }); setLikePopupClose(false); setIsScroll && setIsScroll(false) }}>{children}</span> : <img src={moreIcon} className='more-icon' ref={target} onClick={(e) => { e.stopPropagation(); e.preventDefault(); handleClick(e); setState({ shouldShowTooltip: state.shouldShowTaskActions, shouldShowTaskActions: !state.shouldShowTaskActions }) }} alt='' />}
			{state.shouldShowTaskActions &&
				<ClickAwayListener onClickAway={() => { setState({ shouldShowTaskActions: !state.shouldShowTaskActions }); setLikePopupClose(false); setIsScroll && setIsScroll(false) }}>
					<Popper id={'tooltip-'} ref={popperRef} className={className} placement='top' open={true} anchorEl={target?.current}>
							<div className='smart__like__reactions'>
									{title && <div className='skMore'>{title} ({list?.length})</div>}
									{(list || [])?.map(item => {
										return <div className='smart__reaction'>
											<div className={`show_sk_popup ${activeIcon === item?.name ? 'active-icon' : ''}`} onClick={(e) => { item?.onClick && item.onClick(e, activeIcon, likeId); setIsScroll && setIsScroll(false); setState({ shouldShowTaskActions: !state.shouldShowTaskActions }) }} data-type='touchpoint_popup' ><UiLabel length={1} tooltipClass='likePopTooltip' label={item?.name}><img src={item?.url} alt='' /></UiLabel></div></div>
									})}
								</div>
					</Popper>
				</ClickAwayListener>
			}
		</div>
	)
}
export default SmartLikePopup;



