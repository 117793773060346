import axios from 'axios';
import HWLocalStorage from '../HWLibrary/HWLocalStorage'
import HWUser from './HWUser';

class PlatformAPI {

   baseURL = window.resolveRegion(process.env.REACT_APP_PLATFORM_BASE_API_URL_WEB_ADMIN);

   switchPlatformEnvironment = (forceToProd) => {
        let aConfig = { };
        aConfig.headers = this.getAccessTokenHeaders();
        let platformEnvironmentURL = this.baseURL+ "/webapp/switch-platform-environment";
        let data = {};
        if (forceToProd) { data.forceSwitchToProd = true; }
        return axios.post(platformEnvironmentURL, data, aConfig);
    }

    getAccessTokenHeaders() {
        return {
            'Content-Type': 'application/json',
            Authorization: "\"" + HWLocalStorage.load('platform-accessToken') + "\""
        }
    }

    signout(){
        let data    = { accessToken: HWLocalStorage.load('platform-accessToken'), deviceId: HWLocalStorage.deviceId() }
        let aConfig = { headers: { "Authorization": HWUser.accessToken(), "Content-Type": "application/json" } };
        let url = window.resolveRegion(process.env.REACT_APP_BASE_API_URL_AUTH) + "/signout";
        return axios.post(url, data, aConfig)
    }

    switchRole(roleId){
        let data    = { userId: HWUser.userId(), appId: HWUser.appId(), orgId: HWUser.orgId(), roleId: roleId }
        let aConfig = { headers: { "Authorization": HWUser.accessToken(), "Content-Type": "application/json" } };
        let url = window.resolveRegion(process.env.REACT_APP_SMART_DASHBOARDS_BASE_URL) + "/rbac/preferred-role";
        return axios.put(url, data, aConfig)
    }

    allySignout(){
        let data    = { accessToken: HWLocalStorage.load('platform-accessToken'), deviceId: HWLocalStorage.deviceId() }
        let aConfig = { headers: { "Authorization": HWUser.accessToken(), "Content-Type": "application/json" } };
        let url = window.resolveRegion(process.env.REACT_APP_SMART_DASHBOARDS_BASE_API_URL) + "/ally/signout";
        return axios.post(url, data, aConfig)
    }

    

    switchOrganization(updateOrgId){
        let data    = { accessToken: HWLocalStorage.load('platform-accessToken'), userId: HWUser.userId(), orgId:HWUser.orgId(),updatedPreferenceOrgId:updateOrgId}
        let aConfig = { headers: { "Content-Type": "application/json" } };
        let url = window.resolveRegion(process.env.REACT_APP_DASHBOARDS_BASE_API_URL) + "/platform-setting/switch-organization";
        return axios.post(url, data, aConfig)
    }

    fetchAccessTokenAndRefresh(){
			let deviceId = HWLocalStorage.deviceId();
			let data    = { "deviceId": deviceId, "userId": HWUser.userId(), "refreshToken": HWUser.refreshToken()}
			let aConfig = { headers: { "Authorization": HWUser.refreshToken(), "Content-Type": "application/json" } };
			let url = window.resolveRegion(process.env.REACT_APP_BASE_API_URL_AUTH) + "/refreshtoken";
			return axios.post(url, data, aConfig)
		}

    static fetchCustomerAccounts = (cacheExists, onResponse, onError) => {
        let customerAccountsListStorageKey = "sk-customer-accounts-list-"+ HWUser.userId();
        if (!cacheExists) {
            const cachedData = HWLocalStorage.load(customerAccountsListStorageKey);
            if (cachedData) { onResponse && onResponse(cachedData); }
        }
        let data    = { userId: HWUser.userId(), appId: HWUser.appId() }
        let aConfig = { headers: { "Authorization": HWUser.accessToken(), "Content-Type": "application/json" } };
        let url     = window.resolveRegion(process.env.REACT_APP_PLATFORM_BASE_API_URL_WEB_ADMIN) + "/multilevel/customer/list";
        axios.post(url, data, aConfig).then(response => {
            let customerAccountsList = (response && response.data && response.data.customerAccounts) || [];
            onResponse(customerAccountsList);
            HWLocalStorage.save(customerAccountsList, customerAccountsListStorageKey);
        }).catch(error => { onError && onError(error); });
    }

    static fetchOrgUsers = (cacheExists, onResponse, onError) => {
        let orgUsersListStorageKey = "sk-org-users-list-"+ HWUser.userId();
        if (!cacheExists) {
            const cachedData = HWLocalStorage.load(orgUsersListStorageKey);
            if (cachedData) { onResponse && onResponse(cachedData); }
        }
        let data    = { userId: HWUser.userId(), appId: HWUser.appId(), orgId: HWUser.orgId() }
        let aConfig = { params: data, headers: { "Authorization": HWUser.accessToken(), "Content-Type": "application/json" } };
        let url     = window.resolveRegion(process.env.REACT_APP_DASHBOARDS_BASE_API_URL) + "/platform-setting/org-users";
        axios.get(url, aConfig).then(response => {
            let users = (response && response.data && response.data.users) || [];
            onResponse(users);
            HWLocalStorage.save(users, orgUsersListStorageKey);
        }).catch(error => { onError && onError(error); });
    }

    static fetchUsersForOrgId = (orgId) => {
        let data    = { userId: HWUser.userId(), appId: HWUser.appId(), orgId: orgId }
        let aConfig = { params: data, headers: { "Authorization": HWUser.accessToken(), "Content-Type": "application/json" } };
        let url     = window.resolveRegion(process.env.REACT_APP_DASHBOARDS_BASE_API_URL) + "/platform-setting/org-users";
        

        return axios.get(url, aConfig).then(response => {
            let users = (response && response.data && response.data.users) || [];
            return users;
        })
    }

    static featureUsersPortfolioAccountList = (data, cacheExists, onResponse, onError) => {
        let usersPortfolioAccountListStorageKey = "sk-users-portfolio-customer-accounts-list";
        if (!cacheExists) {
            const cachedData = HWLocalStorage.load(usersPortfolioAccountListStorageKey);
            if (cachedData) {  
                cacheExists = true; 
                onResponse(cachedData); 
            }
        }
        let aConfig    = { headers: { "Authorization": HWUser.accessToken(), "Content-Type": "application/json" } };
        aConfig.params = { appId: HWUser.appId() , userId: HWUser.userId(), orgId: HWUser.orgId() }
        // let url = window.resolveRegion(process.env.REACT_APP_DASHBOARDS_BASE_API_URL) + "/csm/product-success-console/portfolio-account-list/new";
        let url = window.resolveRegion(process.env.REACT_APP_DASHBOARDS_BASE_API_URL) + "/csm/portfolio-filter";
        axios.get(url, aConfig).then(response => {
            let  csmAccounts = (response && response.data && response.data.filters) || [];
            HWLocalStorage.save(csmAccounts, usersPortfolioAccountListStorageKey);
            onResponse(csmAccounts);
        }).catch(error => { onError && onError(error); })
    }

    static fetchOrgUsersForCustomerAccountId = (accountId) => {
        let data    = { userId: HWUser.userId(), appId: HWUser.appId(), orgId: HWUser.orgId(), filterCustomerAccountId: accountId }
        let aConfig = { params: data, headers: { "Authorization": HWUser.accessToken(), "Content-Type": "application/json" } };
        let url     = window.resolveRegion(process.env.REACT_APP_DASHBOARDS_BASE_API_URL) + "/platform-setting/org-users";
        return axios.get(url, aConfig).then(response => {
            let users = (response && response.data && response.data.users) || [];
            return users;
        });
    }

}

export default PlatformAPI;
