import React, { Component } from 'react'
import UnsupportedBrowserImg from "./assets/phoenix/UnsupportedBrowser.png"
import './App.css'

export const UnsupportedBrowser = () => {
    return (
			<div style={{height:"100vh"}}>
				<img src={UnsupportedBrowserImg} className="main-no-logo" alt="" height={"100%"} width={"100%"} style={{height:"100%", width:"100%"}}/>
			</div>
    )
}