import { skMailActions } from "../constants/actionType";

const initialState = {
	mails: [],
	isLoading: false,
	nextToken: null,
	totalCount: '',
	showPreview: false,
	showTouchpointPopover: false,
	showSkeleton: true,
	showPreviewSkeleton: false,
	messageThread: [],
	touchpointEditData: null,
	activeTab: 'Inbox',
	touchpointData: {
		contacts: [],
		appId: window.localStorage.getItem("platform-currentUser")?.userAppPreferences?.appId,
		userId: window.localStorage.getItem("platform-currentUser")?.user?.userId,
		customerAccount: null,
		title: '',
		createdOn: new Date(),
		customerAccountIds: [],
		productIds: [],
		collaborators: [],
		outcomeId: '',
		attachments: [],
		emailThreadId: '',
		isTouchpointPresent: false,
		subject: ''
	}
}


export const skMailsActionReducer = (state = initialState, { type, payload }) => {
	switch (type) {
		case skMailActions.FETCH_MAILS_LIST:
			{
				let isLoading = (payload?.isLoading !== undefined && payload?.isLoading != null) ? payload.isLoading : state.isLoading;
				return { ...state, mails: payload?.mails, isLoading: isLoading, nextToken: payload?.nextToken, showSkeleton: payload?.showSkeleton }
			}

		case skMailActions.ADD_MAIL: { return { ...state, msgToShow: payload?.successMsg } }
		case skMailActions.SET_MAIL_LOADING: { return state }
		case skMailActions.SET_SMART_MAIL_STATE: { return { ...state, ...payload } }
		case skMailActions.SET_IS_LOADING: { return state }
		case skMailActions.SET_IS_ERROR: { return { ...state, msgToShow: payload?.errorMessage } }
		default: return state;
	}
}

export default skMailsActionReducer