import axios from 'axios';
import { cloneDeep } from 'lodash';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import celebrateIcon from '../../KaffeineKarrots/Images/Celebrate.svg';
import funnyIcon from '../../KaffeineKarrots/Images/Funny.svg';
import insightIcon from '../../KaffeineKarrots/Images/Insightful.svg';
import likeIcon from '../../KaffeineKarrots/Images/LikeIcon.svg';
import loveIcon from '../../KaffeineKarrots/Images/Love.svg';
import supportIcon from '../../KaffeineKarrots/Images/Support.svg';
import HWUser from '../../HWLibrary/HWUser';
import { getSmartCommentStates, setSmartCommentStates } from '../../store/reducers/skCommentsReducer';
import aCelebrate from './Images/CelebrateReaction.svg';
import aFunny from './Images/FunnyReaction.svg';
import aInsightful from './Images/InsightfulReaction.svg';
import aLike from './Images/LikeReaction.svg';
import aLove from './Images/LoveReaction.svg';
import aSupport from './Images/SupportReaction.svg';
import './SkSmartEmojiAndReactions.css';
import SmartLikePopup from './SmartLikePopup';

import { ClickAwayListener, Popper } from '@material-ui/core';
import { useEffect } from 'react';
import UiLabel from '../../SkSmartUtil/UiLabel/UiLabel';

export default function SmartReactions({ smartComment, ...props }) {
	const [likePopupClose, setLikePopupClose] = useState(false);
	// const [userReaction, setUserReaction] = useState();
	const dispatch = useDispatch();
	const getSmartCommentState = useSelector(getSmartCommentStates);
	const { smartCommentsList,smartKommunityCommentsList } = getSmartCommentState;

	const [reactedUsersPopUpState, setReactedUsersPopUpState] = useState({
		showReactedUsersPopUp: false,
		reactedUsersList: [],
		anchorElTarget: null,
	})

	const setReactionsPopupStates = (updatedState) => {
		setReactedUsersPopUpState(prevState => (
			{
				...prevState,
				...updatedState
			}
		))
	}

	const addKommunityReaction = (comment, featureId) => {
		let kommunityCommentList = smartKommunityCommentsList;
		kommunityCommentList[featureId].commentResponses = comment;
		dispatch(setSmartCommentStates({ smartKommunityCommentsList: kommunityCommentList }))
	}


	let onReactionUpdate = (featureId, commentId, reaction, feature, parentCommentId) => {

		updateReactionApiCall(featureId, commentId, reaction, feature).then(response => {
			let data = response?.data;
			let commentList = feature !== 'KnkPost' ? cloneDeep(smartCommentsList) : smartKommunityCommentsList[featureId]?.commentResponses;
			if(smartComment?.parentCommentId){
				let parentComment = commentList.find(comment => comment?.parentComment?.commentId === smartComment?.parentCommentId) || [];
				let indexToUpdate = commentList.findIndex(item => item?.parentComment?.commentId === smartComment?.parentCommentId);
				let childIndex = parentComment?.replyList?.replies?.findIndex(item => item.commentId === smartComment.commentId);
				parentComment.replyList.replies[childIndex] = data;
				commentList[indexToUpdate] = parentComment;
				
			}
			else{
				let commentToUpdate = commentList.find(item => item?.parentComment?.commentId === smartComment.commentId);
				let indexToUpdate = commentList.findIndex(item => item.commentId === smartComment.commentId);
				commentToUpdate['parentComment'] = data
				commentList[indexToUpdate] = commentToUpdate;
		  }

			feature !== 'KnkPost' ? dispatch(setSmartCommentStates({ smartCommentsList: commentList })) : addKommunityReaction(commentList, featureId)
			
		}).catch((err) => {

		})
	}

	let onRemoveReaction = (likeId, commentId) => {
		removeReactionApiCall(likeId, commentId).then(response => {
			let data = response?.data;
			let commentList = cloneDeep(smartCommentsList);
			let indexToUpdate = commentList.findIndex(item => item.commentId === smartComment.commentId);
			commentList[indexToUpdate] = data;
			dispatch(setSmartCommentStates({ smartCommentsList: commentList }))
		}).catch((err) => {  })
	}


	let removeReactionApiCall = (likeId, commentId) => {
		let url = window.resolveRegion(process.env.REACT_APP_SMART_DASHBOARDS_BASE_URL) + "/comment/like";
		let aConfig = { headers: { "Authorization": HWUser.accessToken(), "Content-Type": "application/json" } };
		aConfig.params = { appId: HWUser.appId(), userId: HWUser.userId(), orgId: HWUser.orgId(), commentId: commentId, likeId: likeId }
		return axios.delete(url, aConfig)
	}

	let updateReactionApiCall = (featureId, commentId, reaction, feature) => {
		let url = window.resolveRegion(process.env.REACT_APP_SMART_DASHBOARDS_BASE_URL) + "/comment/like";
		let aConfig = { headers: { "Authorization": HWUser.accessToken(), "Content-Type": "application/json" } };
		let data = { appId: HWUser.appId(), userId: HWUser.userId(), orgId: HWUser.orgId(), featureId: featureId, commentId: commentId, reaction: reaction, feature: feature }
		return axios.post(url, data, aConfig)

		// return dispatch(postCommentReaction(featureId, commentId, reaction, feature));
	}


	useEffect(() => {
		const loggedInUserReaction = smartComment?.likes?.find((i) => {
			return (HWUser.userId() === i?.userId)
		})
		let allReactions = smartComment?.likes?.map(x => x.reaction);
		let uniqueReactions = allReactions && likeMenu.filter(x => {
			return allReactions?.includes(x.name)
		});
	}, [])

	const likeMenu = [
		{
			name: 'Like',
			url: likeIcon,
			colorCode: '#0A66C2',
			icon: aLike,
			onClick: (e, activeIcon, reactionId) => {
				if (activeIcon !== 'Like') {
					onReactionUpdate(smartComment.featureId, smartComment.commentId, 'Like', smartComment.feature)
				} else {
					onRemoveReaction(reactionId, smartComment.commentId)
				}
			}
		},
		{
			name: 'Celebrate',
			url: celebrateIcon,
			colorCode: '#44712E',
			icon: aCelebrate,
			onClick: (e, activeIcon, reactionId) => {
				if (activeIcon !== 'Celebrate') {
					onReactionUpdate(smartComment.featureId, smartComment.commentId, 'Celebrate', smartComment.feature)
				} else {
					onRemoveReaction(reactionId, smartComment.commentId)
				}
			}
		},
		{
			name: 'Support',
			url: supportIcon,
			colorCode: '#715E86',
			icon: aSupport,
			onClick: (e, activeIcon, reactionId) => {
				if (activeIcon !== 'Support') {
					onReactionUpdate(smartComment.featureId, smartComment.commentId, 'Support', smartComment.feature)
				} else {
					onRemoveReaction(reactionId, smartComment.commentId)
				}
			}
		},
		{
			name: 'Funny',
			url: funnyIcon,
			colorCode: '#13707E',
			icon: aFunny,
			onClick: (e, activeIcon, reactionId) => {
				if (activeIcon !== 'Funny') {
					onReactionUpdate(smartComment.featureId, smartComment.commentId, 'Funny', smartComment.feature)
				} else {
					onRemoveReaction(reactionId, smartComment.commentId)
				}
			}
		},
		{
			name: 'Love',
			url: loveIcon,
			colorCode: '#B24020',
			icon: aLove,
			onClick: (e, activeIcon, reactionId) => {
				if (activeIcon !== 'Love') {
					onReactionUpdate(smartComment.featureId, smartComment.commentId, 'Love', smartComment.feature)
				} else {
					onRemoveReaction(reactionId, smartComment.commentId)
				}
			}
		},
		{
			name: 'Insightful',
			url: insightIcon,
			colorCode: '#915907',
			icon: aInsightful,
			onClick: (e, activeIcon, reactionId) => {
				if (activeIcon !== 'Insightful') {
					onReactionUpdate(smartComment.featureId, smartComment.commentId, 'Insightful', smartComment.feature)
				} else {
					onRemoveReaction(reactionId, smartComment.commentId)
				}
			}
		}
	];

	let allReactions = smartComment?.likes?.map(x => x.reaction);
	let uniqueReactions = allReactions && likeMenu.filter(x => {
		return allReactions?.includes(x.name)
	});
	let userReaction = smartComment?.likes?.find((i) => { return (HWUser.userId() === i?.userId) })
	const selectedItem = userReaction && userReaction.reaction && likeMenu.filter(item => userReaction.reaction === item.name)[0];

	return (
		<div className='smart__comments__list__reaction__container'>
			<div className='smart__comments__list__reaction-reaction'>
				<div className='smart__comments__list__reaction-lable' style={{ display: 'inline-flex', alignItems: "center" }}>
					<div style={{ cursor: 'pointer' }}>
						<SmartLikePopup className={'smart__comments__list__reaction__container-popup'} isClose={likePopupClose} setLikePopupClose={setLikePopupClose} list={likeMenu} likes={smartComment.likes} commentId={smartComment.commentId} setState={props.setState} isScroll={props?.isScroll} setIsScroll={props.setisScroll} >
							<button className='smart__comments__list__reaction-button'
								onClick={() => {
									if (userReaction) {
										onRemoveReaction(userReaction?.likeId, smartComment?.commentId)
									} else {
										onReactionUpdate(smartComment.featureId, smartComment.commentId, 'Like', smartComment.feature)
									}
								}}>
								{selectedItem && <div className="smart__comments__list__reaction-button-text" style={{ color: selectedItem.colorCode }}>{selectedItem.name}</div>}
								{!selectedItem && <div className="smart__comments__list__reaction-button-unselected-text" >Like</div>}
							</button>
						</SmartLikePopup>
					</div>
					<div className='smart__comments__list_reaction-dot'>.</div>
					{uniqueReactions && uniqueReactions.length > 0 &&
						<div style={{ cursor: smartComment?.likes?.length > 0 ? 'pointer' : 'auto', padding: '2px 0px 0px 5px', marginLeft: '0.3rem', marginRight: smartComment?.likes?.length > 0 ? '0.2rem' : '0rem' }} className='smart__comments__list__reaction-image'
						>
							{uniqueReactions?.map(like => {
								return <span class='avatar' style={{ color: 'black' }}>
									<img className='' src={like.icon} alt='' />
								</span>
							})}
						</div>}

					<div style={{ cursor: "pointer" }} onClick={(e) => { setReactionsPopupStates({ showReactedUsersPopUp: true, reactedUsersList: smartComment?.likes, anchorElTarget: e?.currentTarget }) }} className='smart__comments__list__reaction-count' > {smartComment.likes?.length}</div>
					<div className='smart__comments__list_reaction-line'>|</div>
					{reactedUsersPopUpState?.showReactedUsersPopUp &&
						<SmartReactedUsersPopUp reactedUsersPopUpState={reactedUsersPopUpState} setReactionsPopupStates={setReactionsPopupStates} />
					}
				</div>
			</div>
		</div>
	)
}

const SmartReactedUsersPopUp = ({ reactedUsersPopUpState, setReactionsPopupStates }) => {

	return (


		<ClickAwayListener onClickAway={() => {
			setReactionsPopupStates({
				showReactedUsersPopUp: false,
				reactedUsersList: [],
				anchorElTarget: null
			})
		}}>
			<Popper className="likeList" placement="bottom-start" open={true} anchorEl={reactedUsersPopUpState?.anchorElTarget}>
				{/* {reactedUsersPopUpState?.showReactedUsersPopUp && */}
					<div className="widget-like-tooltiptext" style={{ display: reactedUsersPopUpState?.reactedUsersList?.length > 0 ? 'block': 'none' }}>
						<div className="reaction-wrapper">
							{reactedUsersPopUpState && reactedUsersPopUpState?.reactedUsersList?.map(({ userId, userName, reaction }) => {
								let reactionIcon = likeIcon;
								switch (reaction) {
									case 'Like':
										reactionIcon = likeIcon
										break;
									case 'Love':
										reactionIcon = loveIcon
										break;
									case 'Celebrate':
										reactionIcon = celebrateIcon
										break;
									case 'Funny':
										reactionIcon = funnyIcon
										break;

									case 'Insightful':
										reactionIcon = insightIcon
										break;
									case 'Support':
										reactionIcon = supportIcon
										break;
									default: reactionIcon = likeIcon
								}
								return <div className="reaction-content" key={userId}>
									<div style={{ width: "20%", marginTop: "-0.313rem" }}>
										<img className="reaction-content_image" src={reactionIcon} style={{ height: 30 }} alt="" />
									</div>
									<div className="reaction-content_name" style={{ marginLeft: (userName?.length >= 15) ? "0.8rem" : "" }}>
										<UiLabel tooltipClass={"kommunity__like__pop__up__label"} label={userName} length={15} /></div></div>
							})

							}
						</div>
					</div>
				{/* } */}
			</Popper>
		</ClickAwayListener>
					
	)
}