import access from "../../HWLibrary/AccessControl";
import { skPlayBookActions } from "../constants/actionType";

const initialState = {
	isPlayBookLoading: false,
	isPlayBookError: false,
	blockConfirmationPopup: false,
	fetchingTemplateData:false,
	fetchApiForAssignmentRuleListingScreen:false,
	playBookSuccessMsg: '',
	playBookErrorMessage: '',
	assignmentRuleToken: '',
	smartAssignmentRulesList: null,
	smartAssignmentRuleHistoryLogsList: null,
	smartAssignmentRuleHistoryLogsSortOrder:'',
	smartAssignmentRuleTemplateData: null,
	smartVerifyAudienceData: null,
	assignmentRulePhaseOptionsList: [],
	emailAudienceOptionsList:[],
	referenceTemplatesList: [],
	taskAssigneeOption: [],
	productDataForOrganization:[],
	smartAssignmentRuleHistoryLogsNextToken: '',
	playbookAnalyticsCurrentlyActiveWidget:null,
	playbookAnalyticsStatusWidget: null,
	playbookAnalyticsUserGrowthWidget: null,
	playbookAnalyticsTableWidget: null,
	playbookAnalyticsLoader: false,
	playbookAnalyticsDashboardRedirectionLoader: false,
	playbookAnalyticsOverview: null,
	playbookAnalyticsTemplate: null,
	playbookAnalyticsNextToken: null,
	playbookAnalyticsTemplateLoader: false,
	clientUsersReferenceList: null,
	portalUsersReferenceList: [],
	customerPortalUserEnabled: false,
	assignmentRuleTriggerOptions: [
		{ id: 1, label: 'Renewal', value: 'Renewal' },
		{ id: 2, label: 'Phase Change', value: 'PhaseChange' },
		{ id: 3, label: 'License Utilization', value: 'LicenseUtilization' },
		{ id: 4, label: 'Date', value: 'Date' },
	],
	assignmentRuleAccountSelectionModeOptions:access?.skAccountSegments?.view()? [
		{ id: 1, label: 'Manual Selection', value: 'ManualSelection' },
		{ id: 2, label: 'Account Segment', value: 'AccountSegment' },
	]:[{ id: 1, label: 'Manual Selection', value: 'ManualSelection' }]
	,
	assignmentRuleRepeatOptions: [
		{ id: 1, label: 'Daily', value: 'Daily' },
		{ id: 2, label: 'Weekly', value: 'Weekly' },
		{ id: 3, label: 'Monthly', value: 'Monthly' },
	],
  templateTypeOptions: [
		{ id: 1, label: 'Account', value: 'Account' },
		{ id: 2, label: 'Non-Account', value: 'NonAccount' },
	],
	mileStoneOption:
		[
			{ value: 0, label: "0" },
			{ value: 1, label: "1" },
			{ value: 2, label: "2" },
			{ value: 3, label: "3" },
			{ value: 4, label: "4" },
			{ value: 5, label: "5" },
			{ value: 6, label: "6" },
			{ value: 7, label: "7" },
			{ value: 8, label: "8" },
			{ value: 9, label: "9" },
			{ value: 10, label: "10" },
		],
	taskTypeOption:
		[
			{ id: 1, value: 'Task', label: 'Task' },
			{ id: 2, value: 'Touchpoint', label: 'Touchpoint' },
			{ id: 3, value: 'Email', label: 'Email' },
		],
		taskNonAcccountTypeOption:
		[
			{ id: 1, value: 'Task', label: 'Task' },
			{ id: 3, value: 'Email', label: 'Email' }
		],
	taskOnlyOption: [
		{ id: 1, value: 'Task', label: 'Task' }
		],
	weightageOption:
		[
			{ value: 0, label: "0" },
			{ value: 1, label: "1" },
			{ value: 2, label: "2" },
			{ value: 3, label: "3" },
			{ value: 4, label: "4" },
			{ value: 5, label: "5" },
			{ value: 6, label: "6" },
			{ value: 7, label: "7" },
			{ value: 8, label: "8" },
			{ value: 9, label: "9" },
			{ value: 10, label: "10" },
		],
	gettingStartedDetails: null,
	gettingStartedLoader: false,
	disableGettingStartedTab: false
}

export const skPlayBookActionReducer = (state = initialState, { type, payload }) => {
	switch (type) {
		case skPlayBookActions.FETCH_TEMPLATE_OPTIONS:
		case skPlayBookActions.FETCH_PHASE_OPTIONS:
		case skPlayBookActions.FETCH_AUDIENCE_OPTIONS:
		case skPlayBookActions.FETCH_SINGLE_ASSIGNMENT_RULE:
		case skPlayBookActions.EDIT_ASSIGNMENT_RULE:
		case skPlayBookActions.DELETE_ASSIGNMENT_RULE:
		case skPlayBookActions.FETCH_ASSIGNMENT_RULES:
		case skPlayBookActions.ADD_ASSIGNMENT_RULE:
		case skPlayBookActions.FETCH_OUTCOME_OPTIONS:
		case skPlayBookActions.FETCH_ROLE_OPTIONS:
		case skPlayBookActions.FETCH_ASSIGNEE_OPTIONS:
		case skPlayBookActions.FETCH_REFERENCE_PRODUCTS:
		case skPlayBookActions.ADD_TEMPLATE:
		case skPlayBookActions.FETCH_USER_OPTIONS:
		case skPlayBookActions.FETCH_HISTORY_LOGS_ASSIGNMENT_RULE:
		case skPlayBookActions.UPDATE_TEMPLATE:
		case skPlayBookActions.FETCH_VERIFY_AUDIENCE_DATA:
		case skPlayBookActions.EDIT_VERIFY_AUDIENCE_DATA:
		case skPlayBookActions.FETCH_PLAYBOOK_ANALYTICS:
		case skPlayBookActions.FETCH_PLAYBOOK_GETTING_STARTED_DETAILS:
		case skPlayBookActions.FETCH_PLAYBOOK_GETTING_STARTED_LIST_DATA:
		case skPlayBookActions.SET_SMART_PLAY_BOOK_STATES: { return { ...state, ...payload } }
		default: return state;
	}
};


export default skPlayBookActionReducer;
