import { combineReducers } from "redux";
import hsDefination from "./hsDefinition/hsReducer";
import userReducer from "./user/userReducer";
import skKonversationActionReducer from "./actions/skKonversationAction";
import skAttachmentActionReducer from "./actions/skAttachmentsActions";
import skGlobalReducer from "./actions/skGlobalActions";
import skKeyOutcomesActionReducer from "./actions/skKeyOutcomesActions";
import skAcountManagementActionReducer from "./actions/skAccountManagementActions";
import skUserPermissionActionReducer from "./actions/skUserPermissionActions";
import skAccountListingActionReducer from "./actions/skAccountListingActions";
import skAccount360ActionReducer from "./actions/skAccount360Actions";
import skNotesActionReducer from "./actions/skNotesActions";
import skEmailNotificationReducer from "./actions/skEmailNotificationActions";
import skCalendarActionsReducer from "./actions/skCalendarActions";
import skPlayBookActionReducer from "./actions/skPlaybooksActions";
import skTaskActionReducer from "./actions/skTaskActions";
import skTouchpointActionReducer from "./actions/skTouchpointActions";
import skCommentActionReducer from "./actions/skCommentsActions";
import skMailsActionReducer from "./actions/skMailActions";
import skContactActionReducer from "./actions/skContactActions";
import skCustomerPortalActionReducer from "./actions/skCustomerPortalAction";
import skAlertActionReducer from "./actions/skAlertActions";
import skConfigurationReducer from "./actions/skConfigurationActions"
import skSettingReducer from "./actions/skSettings";
import skSmartInsightActionReducer from "./actions/skSmartInsightActions";

export const rootReducer = combineReducers({
	user: userReducer,
	hsDef: hsDefination,
	smartKonv: skKonversationActionReducer,
	smartCommentReducer: skCommentActionReducer,
	smartTaskReducer: skTaskActionReducer,
	smartTouchpointReducer: skTouchpointActionReducer,
	smartAttachmentReducer: skAttachmentActionReducer,
	smartContactReducer: skContactActionReducer,
	smartNoteReducer: skNotesActionReducer,
	smartMailReducer:skMailsActionReducer,
	smartPlayBookReducer:skPlayBookActionReducer,
	smartCalendarReducer:skCalendarActionsReducer,
	smartNotificationReducer: skEmailNotificationReducer,
	smartAccountViewReducer: skAccount360ActionReducer,
	skPortfolioReducer:skAccountListingActionReducer,
	smartUserReducer: skUserPermissionActionReducer,
	smartAccountReducer: skAcountManagementActionReducer,
	smartKeyOutcomesReducer: skKeyOutcomesActionReducer,
	globalReducer: skGlobalReducer,
	customerPortalReducer: skCustomerPortalActionReducer,
	smartAlertReducer: skAlertActionReducer,
	skConfigurationReducer : skConfigurationReducer,
	skSettingReducer:skSettingReducer,
	smartInsightReducer :skSmartInsightActionReducer
});
