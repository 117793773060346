import React, { Component, Suspense, lazy } from 'react'
import './App.css';
import '../src/SkSmartUtil/SmartGlobalStyles.css';
import 'bootstrap/dist/css/bootstrap.min.css'
import 'bootstrap/dist/js/bootstrap.min.js';
import './assets/styles/bootstrap.min.css';
import { BrowserRouter as Router, Routes, Route, Navigate, useLocation } from 'react-router-dom'
import { platformWatchForPlatformEvents, platformSignOut, onPlatformTokenRefresh, onPlatformEnvironmentChange, markUserAsLoggedInSDK, onPlatformUserSwitch } from './HWLibrary/HWIframeBridge';
import HWUser from './HWLibrary/HWUser'
import PageVisibility from 'react-page-visibility/dist/umd/PageVisibility'
import axios from 'axios'
import HWLocalStorage from './HWLibrary/HWLocalStorage'
import HWMessage from './HWLibrary/HWMessage'
import PlatformAPI from './HWLibrary/PlatformAPI'
import { UnsupportedScreenSize } from './UnsupportedScreenSize'
import { UnsupportedBrowser } from './UnsupportedBrowser'
// import CheckBandWidth from './CheckBandWidth'
import authJson from './SmartAuthenticationModule/Auth.json'
import authInJson from './SmartAuthenticationModule/AuthIn.json'

// import SmartKonversationsBanner from './SmartKonversations/Banners/SmartKonversationsBanner'
// import APIService from "../../..SettingsChildren/APIService";
import APIService from './views/Settings/SettingsChildren/APIService';
import {logoutOnUnsupportedBrowser} from  './HWLibrary/HWIframeBridge'


// import AcademyRedirect from './SkSmartUtil/AcademyRedirect/AcademyRedirect'
import skApiService from './SkApiService'
// import SmartModal from './SkSmartUtil/SmartModal/SmartModal';
import '../src/assets/styles/digikit.css'
// import SmartAuthenticationModule from './SmartAuthenticationModule/SmartAuthenticationModule'
// import SignUp from './SmartAuthenticationModule/SignUp'
import Dashboard from './Layouts/Dashboard'
import SkLoaderComponent from './SkSmartUtil/SkLoader/SkLoaderComponent';



const CheckBandWidth = lazy(() => import('./CheckBandWidth'));
const SmartKonversationsBanner = lazy(() => import('./SmartKonversations/Banners/SmartKonversationsBanner'));
const AcademyRedirect = lazy(() => import('./SkSmartUtil/AcademyRedirect/AcademyRedirect'));
const SmartModal = lazy(() => import('./SkSmartUtil/SmartModal/SmartModal'));
const SmartAuthenticationModule = lazy(() => import('./SmartAuthenticationModule/SmartAuthenticationModule'));
const SignUp = lazy(() => import('./SmartAuthenticationModule/SignUp'));
// const Dashboard = lazy(() => import('./Layouts/Dashboard'));

class App extends Component {


  constructor(props) {
    super();
		const isTestLoad = this.checkAndAutoLoginForTesting();
		if (!isTestLoad){
			HWLocalStorage.save({ "loadPathName": window.location.pathname, "loadPathSearch": window.location.search }, "sk-load-path");
		}

	
    const lowbandwidthConfig = HWUser.lowbandwidth();
    this.state = { 
			isTestLoad: isTestLoad,
      lowbandwidthConfig: lowbandwidthConfig, isInvalidScreenSize: false, isTokensVerified: false, hardRefreshKey: null, showBanner: false,
      doNotshow: false,
			redirectAuthentication: false
    };
    axios.interceptors.response.use((response) => { return response; }, this.onError);
    this.isAlly = HWUser.isAllySite();
    window.document.title = this.isAlly ? "Enabling collaboration for success" :  (window.location.host.includes('digiplaybooks'))  ? "DigiPlaybooks" : "SmartKarrot - Customer Success Platform";

    this.bannerPopup = {
      close: () => {
        this.setState({ bannerStatus: "close" });
        this.setState({ showBanner: false });
        this.setBannerStatus("close");
      },
    }

    if (!HWUser.isPremiumFeatureEnable() && HWUser.showBanner()) {
      setInterval(() => {
        skApiService.fetchBannerStatus().then(response => {
          if (response.data) {
            if (response.data.ShowSmartKonversationBanner == true) {
              this.setState({ showBanner: true });
            }
          }
        }).catch(() => { 
					// console.log("Could not fetch org details.")
				 });
      }, 60 * 60 * 1000);
    }
  }

	checkAndAutoLoginForTesting(){

		// Check if its auth-test path
		let pathName = window.location.search;
		const auth = new URLSearchParams(pathName)?.get('auth');
		if(!auth){ return false; }
		// // this.setState({autoLogin: true});
		console.log("testing", auth);
		this.loginWithJson(auth, window.location.pathname);

	// 	 // Store each key-value pair in local storage
	// 	 markUserAsLoggedInSDK();

	// 	 for (const key in authJson) {
			
	// 		if (authJson.hasOwnProperty(key)) {
	// 			const value = authJson[key];
	// 			if (typeof value === 'object' && value !== null) {
	// 					// Stringify objects before storing them
	// 					localStorage.setItem(key, JSON.stringify(value));
	// 			} else {
	// 					// Store primitive values directly
	// 					localStorage.setItem(key, value);
	// 			}
	// 	}
	// }
	// window.location.href = pathName;	

	  return true;
	}


	loginWithJson = async(data, pathName) =>{
		let response;
		if(data === "a570b369-cd33-440f-9bea-464743d9ad39"){
			response = authJson;
		}else {
			response= authInJson;
		}
    
		
		console.log("===== response",response);
		// HWUser.setUserImpersonated();
		onPlatformUserSwitch(response);
		// markUserAsLoggedInSDK();
		localStorage.setItem("auth", "json");

		window.location.href = pathName;
	}


	loginAs = async (data, pathName) => {
		const requestParam = {
			userId: data,
			token: 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbnZpcm9ubWVudCI6IlByb2R1Y3Rpb24iLCJyZWZyZXNoVG9rZW5FeHBUaW1lIjoiMjAyNC0wNi0yN1QxMDoxMDoxNi40ODQ4NDhaIiwiYXBwSWQiOiJmYzgwMjlmZS1mMTQxLTQ4M2ItOGE3ZC1lZTIxMDE0ZGIxYjgiLCJzY29wZSI6IiouKiBjdXN0b21lclBvcnRhbDoqIiwiaXNTdXBlckFkbWluIjoiZmFsc2UiLCJza1BsYXRmb3JtIjoiUGhvZW5peCIsInR5cGUiOiJBdXRob3JpemF0aW9uVG9rZW4iLCJ1c2VySWQiOiJhNTcwYjM2OS1jZDMzLTQ0MGYtOWJlYS00NjQ3NDNkOWFkMzkiLCJkZXZpY2VJZCI6IjI1MDEwMTU3NTM3MzYxMTEwMDA1MzczNjUxNDQwMjU2MDI0LWQxMTI1ZTQ1LThiN2EtNDllNi05NDY3LWVhNjc0Njk2NmFjYyIsInZlcnNpb24iOiJ2MyIsInRpbWVzdGFtcCI6IjIwMjQtMDUtMjhUMTA6MTA6MTkuNTQ0MzE5WiIsImV4cCI6MTc0ODQyNzAxNSwiaXNzIjoiU21hcnRLYXJyb3QifQ.fRODu8ffr3IU9y9O7NNDLyV8TQyBaHGX5kLvDbPtQfo'
		};
		const settingsAPIService = new APIService();
		try {
			const response = await settingsAPIService.loginWithAuthToken(requestParam);
			if (response.status === "SUCCESS") {
				HWUser.setUserImpersonated();
				onPlatformUserSwitch(response);
				markUserAsLoggedInSDK();
				window.location.href = pathName;
			}
		} catch (error) {
			console.error("Login failed:", error);
			// Handle error appropriately here, e.g., show error message to user
		}
	}

  setBannerStatus = (val) => {
    this.setState({ showBanner: false });

    let request = {
      featureList: [{ bannerStatus: this.state.doNotshow == true ? "doNotSend" : val }
      ],
      userId: HWUser.userId(),
      orgId: HWUser.orgId()
    }

    skApiService.setBannerStatus(request).then(response => {
    }).catch(() => { 
			// console.log("Could not fetch org details.") 
		});
  }

  doNotshow = (e) => {
    if (e) {
      this.setState({ doNotshow: true });
    }
    else {
      this.setState({ doNotshow: false });
    }
  }

  onRefresh = () => {
    const lowbandwidthConfig = HWUser.lowbandwidth();
    this.setState({ hardRefreshKey: new Date().getTime() + "", lowbandwidthConfig: lowbandwidthConfig }, () => {
			this.checkScreenSize();
		});
  }

	isLocalhost = () => {
		return window.origin.indexOf("localhost") !== -1
	}

  routeSwitch = (isLoggedIn) => {
		// let path = this.redirectToPath();
		
    return (
      <>
			<Suspense fallback={<SkLoaderComponent isLoading={true} className="skLoaderComponent" digiisDigiPlaybook={window.location.host.includes('digiplaybooks')} />}>
        <Routes>
					{/* { isLoggedIn && path && (<Route path="*" element={<Navigate replace to={path} />} />)} */}
					{(<Route exact path='/redirect-to-log-out' element={<SmartAuthenticationModule authenticationType={"sign-in"} redirectAuthentication={true}/>}></Route>)}
					{ isLoggedIn && <Route index={false} path={"/academy-redirect"} element={<AcademyRedirect/>}></Route>}
					{isLoggedIn && <Route index={true} path={"*"} element={<Dashboard />}></Route>}
					{!isLoggedIn && (<Route exact path='/sign-in' element={<SmartAuthenticationModule authenticationType={"sign-in"} />}></Route>)}
          {!isLoggedIn && (<Route exact path='/verify' element={<SmartAuthenticationModule authenticationType={"reset-password"} />}></Route>)}
          {!isLoggedIn && (<Route exact path='/forgot-password' element={<SmartAuthenticationModule authenticationType={"forgot-password"} />}></Route>)}
          {!isLoggedIn && (<Route exact path='/sign-up' element={<SignUp onRefresh={this.onRefresh} />}></Route>)}
					{!isLoggedIn && (<Route exact path='/verify-otp' element={<SmartAuthenticationModule authenticationType={"otp-signup"} />}></Route>)}
					{!isLoggedIn && (<Route exact path='/log-out' element={<SmartAuthenticationModule authenticationType={"sign-in"} redirectAuthentication={true}/>}></Route>)}
					{!isLoggedIn && this.state.redirectAuthentication && (<Route path="*" element={<SmartAuthenticationModule authenticationType={"sign-in"} redirectAuthentication={true}/>}></Route>)}
          {!isLoggedIn && (<Route path="*" element={<Navigate replace to="/sign-in" />} />)}
        </Routes>
        { isLoggedIn && this.state.lowbandwidthConfig?.enabled && !this.isLocalhost() && <CheckBandWidth lowbandwidthConfig={this.state.lowbandwidthConfig}/>}
         {this.state.showBanner &&
          <SmartModal className="smart_konversations" {...this.bannerPopup}>
            <SmartKonversationsBanner setBannerStatus={this.setBannerStatus} bannerStatus={this.state.bannerStatus} doNotshow={this.doNotshow} />
          </SmartModal>
        }
				</Suspense>
       </>
    )
  }

	isBrowserCheckRequired = () => {
		const browserSupport = window.localStorage.getItem('browserSupport');
		if(browserSupport === 'All') return false;
		return true;
	}



  render() {
    let isLoggedIn = window.localStorage.getItem("platform-currentUser") && true;
		if (this.state.isTestLoad) { return (<div></div>) }
		
    if (this.state.isInvalidScreenSize) { return (<UnsupportedScreenSize />) }

		function isChromeBrowser() {
			if (navigator.userAgent.indexOf("Opera") !== -1 || navigator.userAgent.indexOf('OPR') !== -1) {
				return false;
			} else if (navigator.userAgent.indexOf("Edg") !== -1) {
				return false;
			} else if (navigator.userAgent.indexOf("Safari") !== -1 && navigator.userAgent.indexOf("Chrome") === -1) {
				return false;
			} else if (navigator.userAgent.indexOf("Firefox") !== -1) {
				return false;
			} else if(navigator?.brave) {
				return false;
			} else if (navigator.userAgent.indexOf("Chrome") !== -1) {
				return true;
			} else {
				return false;
			}
		}
		
		if(HWUser.isLoggedIn() && this.isBrowserCheckRequired() && !isChromeBrowser()) {
			 logoutOnUnsupportedBrowser();
			 return (<UnsupportedBrowser />) 
			}
		
		let customTheme = window.localStorage.getItem("CustomTheme");
		if(customTheme) {
			const styleElement = document.createElement('style');
			styleElement.textContent = customTheme;
			document.head.appendChild(styleElement);
		}

		// console.log("===== rendered")
    return (
      <Router key={this.state.hardRefreshKey} /*onUpdate={()=>window.userpilot.reload()}*/>
        {this.state.hardRefreshKey && <>{isLoggedIn && <HWMessage />}
        <PageVisibility onChange={this.onVisibilityChange} />   {/* Check for access token expiry time. */}
        {this.routeSwitch(isLoggedIn)}</>}
      </Router>
    );
  }

	/* Page refresh should not take the users to the tasks. To fix this issue,commenting ithis code*/
	// redirectToPath = () => {
	// 	const initalLoadScreen = HWLocalStorage.load("sk-load-path");
  //   if (initalLoadScreen && initalLoadScreen.loadPathName && (
  //     initalLoadScreen.loadPathName.indexOf("sign-up") === -1 &&
  //     initalLoadScreen.loadPathName.indexOf("sign-in") === -1 &&
  //     initalLoadScreen.loadPathName.indexOf("verify") === -1 &&
  //     initalLoadScreen.loadPathName.indexOf("forgot-password") === -1
  //   )) {
	// 		window.history.replaceState("", "", initalLoadScreen.loadPathName+initalLoadScreen.loadPathSearch);
	// 					return initalLoadScreen.loadPathName;
	// 	}
	// 	return null;
	// }

  componentDidMount() {
		let pathName = window.location.search;
		const token = new URLSearchParams(pathName)?.get('authtoken')
		this.setState({redirectAuthentication: token ? true : false});
		 
    platformWatchForPlatformEvents(this, (forceRefreshScreen) => { this.onDataUpdate(forceRefreshScreen); });
    this.onDataUpdate();
    this.refreshAppIfAppVersionIsChanged();
    window.addEventListener('resize', this.checkScreenSize);
    this.checkScreenSize();
  }

	onDataUpdate = (forceRefreshScreen) => {
    this.verifyTokens();
    this.forceSwitchToProdIfSandboxIsDisabled();
		if (!(forceRefreshScreen === false)) {
			this.onRefresh();
		}
  }

  checkScreenSize = () => {
		//----------Screen Scale---------//
		/*Effecting tooltips position.so added this changes in Dashboard.jsx*/
		// Make it configurable for certain user this logic should be switched off. The default will be enabled.
		if (HWUser.scaleScreen() && HWUser.isLoggedIn())  {
			document.body.style.zoom = "" + (window.screen.width / 1440) * 100 + "%";
			// console.log("Body Size: " + document.body.style.zoom);
		} else {
			document.body.style.zoom = '';
		}
		//----------Screen Scale---------//

		// Show minimum screen size message. - Not related to scaling.
		const isInvalidScreenSize = window.innerWidth < 1270; 
    if (this.state.isInvalidScreenSize !== isInvalidScreenSize) {
      !this.isAlly && this.setState({ isInvalidScreenSize: isInvalidScreenSize });
    }
  };

  componentWillUnmount() {
    window.removeEventListener('resize', this.checkScreenSize);
  }

  onVisibilityChange = (isVisible) => {
    if (!isVisible) { return; }
    // this.verifyTokens();
    this.refreshAppIfAppVersionIsChanged();
  }

  onError = (error) => {
    let url = window.resolveRegion(process.env.REACT_APP_BASE_API_URL_AUTH) + "/refreshtoken";
    if (error.config && error.config.url && url.toLowerCase() === error.config.url.toLowerCase() && error.status === 401) {
      platformSignOut();
    } else if (error.config && error.config.url && error.config.url.toLowerCase().indexOf(window.resolveRegion(process.env.REACT_APP_API_DOMAIN_NAME) && window.resolveRegion(process.env.REACT_APP_API_DOMAIN_NAME).toLowerCase()) !== -1 && error.status === 401) {
      this.verifyTokens();
    }
    return Promise.reject(error);
  }

  verifyTokens = () => {
		// const authToken = new URLSearchParams(pathName)?.get('auth');

    let isLoggedIn = window.localStorage.getItem("platform-currentUser") && true;
    if (!isLoggedIn) { return; }
    let accessTokenExpiryTimeEpochSec = HWUser.acccessTokenExpTimeInEpochSec();
    let refreshTokenExpiryTimeEpochSec = HWUser.refreshTokenExpTimeInEpochSec();
    if (!accessTokenExpiryTimeEpochSec || !refreshTokenExpiryTimeEpochSec) { /* console.log("SK-TOKEN: INVALID TOKEN:")*/;
      platformSignOut(); return;
    }
    //console.log("SK-TOKEN: EXP TIME:" + expiryTimeEpochSec);
    if (refreshTokenExpiryTimeEpochSec <= (new Date().getTime() / 1000)) { // Signout on Refresh Token Expiry.
      platformSignOut(); return;
    } else if (accessTokenExpiryTimeEpochSec < ((new Date().getTime() / 1000) + 1800)) { // Refresh access token on expiry.
      // this.fetchAccessTokenAndRefresh();
    } else { // Valid Token
      this.setState({ isTokensVerified: true });
      // console.log("SK-TOKEN: Good To Go:");
    }
  }

  isRefreshingToken = false;
  fetchAccessTokenAndRefresh = () => {
    if (this.isRefreshingToken === true) {
      //  console.log("Already refreshing tokens."); 
      return;
    }
    //console.log("SK-TOKEN:  START REFRESH ");
    let url = window.resolveRegion(process.env.REACT_APP_BASE_API_URL_AUTH) + "/refreshtoken";
    let deviceId = HWLocalStorage.deviceId();
    const body = { "deviceId": deviceId, "userId": HWUser.userId(), "refreshToken": HWUser.refreshToken() }
    let aConfig = { headers: { "Authorization": HWUser.refreshToken(), "Content-Type": "application/json" } };
    this.isRefreshingToken = true;
    axios.post(url, body, aConfig).then(response => {
      //console.log(response.data);
      onPlatformTokenRefresh(response.data.accessToken, response.data.refreshToken);
      // HWUser.updateTokens(response.data.accessToken, response.data.refreshToken);
      // this.setState({ isTokensVerified: true, hardRefreshKey: new Date().getTime() + "" });
    }).finally(() => {
      this.isRefreshingToken = false;
    });
  }

  

  // Refresh the app when the build version changes. 
  // Build version is hardcoded in app-config.json file (public/app-config.json). Build version will be updated on every deployment.
  isCheckingAppVersion = false;
  refreshAppIfAppVersionIsChanged = () => {
    if (this.isCheckingAppVersion) { return }
    this.isCheckingAppVersion = true;
    let url = "/app-config.json?t=" + new Date().getTime();
    axios.get(url).then(response => {
      try {
        let newAppVersion = response.data && response.data.appVersion && response.data.appVersion.trim();
        if (!newAppVersion) { return }
        let storageKey = "react-platform-app-version";
        let localAppVersion = localStorage.getItem(storageKey);
        if (!localAppVersion) { localStorage.setItem(storageKey, newAppVersion); }
        if (localAppVersion.trim().toLowerCase() !== newAppVersion.trim().toLowerCase()) {
          localStorage.setItem(storageKey, newAppVersion);
          // console.log("There is a version of app available now, page needs refresh.");
          window.location.reload();
        }
      } catch (error) { }
    }).catch(() => { 
			// console.log("App Config Not Found") 
		}).finally(() => { this.isCheckingAppVersion = false; });
  }

  isSwitchingPlatform = false;
  forceSwitchToProdIfSandboxIsDisabled = () => {
    let isLoggedIn = window.localStorage.getItem("platform-currentUser") && true;
    let shouldSwitchToProd = isLoggedIn && HWUser.platformEnvironment() && HWUser.platformEnvironment() === "Sandbox" && !HWUser.isSandboxAndProdSwitchEnabled();
    if (shouldSwitchToProd) {
      if (this.isSwitchingPlatform) { return }
      this.isSwitchingPlatform = true;
      let platformAPIService = new PlatformAPI();
      platformAPIService.switchPlatformEnvironment(true).then(response => {
        let status = response.data.status;
        if (status === "RESTRICTED_PLATFORM") {
          // console.log("RESTRICTED_PLATFORM");
          // alert("This account is available only in " + val + " Mode.")
          return;
        }
        onPlatformEnvironmentChange(response);
      }).catch(error => { 
				this.isSwitchingPlatform = false; 
				// console.log("Could not switch platform"); 
			});
    }
  }


}

export default App;
