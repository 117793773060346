
class InMemoryCache {
    static cacheVersion = new Date().getTime()+"";
    static cache = {};
    ttlInMilliSec = 30 * 60 * 1000; // 30 Mins

    CacheModule = {
        skCredentials:  "SK-Credentials",
        userPreference: "User-Preference",
        personaData:    "Persona-Data",
        csPerspective:  "CS-Perspective",
        otherFilterData:  "Other-Filter-Data",
        upcrossReference: "UpcrossReference",
        keySegments: "KeySegments",
        productConfig: "ProductConfig",
        touchpointType:"TouchpointType",
        durationFrequency:"Frequency",
        durationLength:"Length",
        featureUsersTotalusers:"FeatureUsersTotalusers",
        featureUsageSnapshot:"FeatureUsageSnapshot",
        instanceReferenceData:"InstanceReferenceData",
        fetchUserRoles:"FetchUserRoles",
        projectist:"Projectist",
        possibleChurnReasons:"PossibleChurnReasons",
        expansionLabels:"ExpansionLabels",
        champions:"Champions",
				skFilterAttributes:"skFilterAttributes",
				userFilterPreference:"userFilterPreference",
				userColumnPreference: "userColumnPreference",
			sortPreference: "sortPreference",
			viewPreference: "viewPreference",
				userRbacScope: "userRbacScope",
				usersData:"usersData",
				usersRoleData:"usersRoleData",
				outcomeRefData:"outcomeRefData",
				RbacUsers:"RbacUsers",
				externalUsers: "externalUsers",
				heatMapData:"heatMapData",
				loyaltyData:"loyaltyData",
				totalUsersVsActiveUsers:"totalUsersVsActiveUsers",
				accountByActiveUsers:"accountByActiveUsers",
				accountByInactiveUsers:"accountByInactiveUsers",
				puUserTredData:"puUserTredData",
				puTopAccounts:"puTopAccounts",
				puBottomAccounts:"puBottomAccounts",
				usersTypeRoleData:"usersTypeRoleData",
				segments:"segments",
				segmentConditions:"segmentConditions",
				accounts:"accounts",
				taskCategoryData:"taskCategoryData",
				riskRatingData:"riskRatingData",
				globalfilterAccounts:"globalfilterAccounts",
			customerPortalRoles: "customerPortalRoles",
			gettingStartedDetails: "gettingStartedDetails",
				smartSegments:"smartSegments"
    }

    keyModule = (key, module) => { return key + "#" + module; }

    get(key, module, fetchPromiseFunction, forceInvalidate) {
        let cachedResponse = !forceInvalidate && InMemoryCache.cache[this.keyModule(key, module)];
				let data = null;
        return cachedResponse && !this.isTimeout(cachedResponse.timestamp) ? Promise.resolve(cachedResponse.response):fetchPromiseFunction?this.onMiss(key, module, fetchPromiseFunction):Promise.resolve(data);
    }

	  // cacheReducer(cachedData) -> updatedCacheData
	cacheAndUpdate = (key, module, cacheReducer, updateFunction) => {
			// console.log("Update Cache Hit Key: " + key + module);
			let moduleKey      = this.keyModule(key, module);
			let cachedResponse = InMemoryCache.cache[moduleKey];
			// console.log("Update Cache Hit Key: cachedResponse " + cachedResponse);
			if (cachedResponse && !this.isTimeout(cachedResponse.timestamp)) { // Update local cache
				try { 
					let updatedCacheData = cacheReducer(cachedResponse.response);
					if (updatedCacheData) {
						InMemoryCache.cache[moduleKey] = { response: updatedCacheData, timestamp: new Date().getTime() };
						// console.log("Updated cache: " + moduleKey); 
					}
				 } catch(error) {
					//  this.invalidateForKey(key, module);
					 console.log("Failed to update local cache :" + moduleKey + " : "  + error); 
					}
			} else {
				// console.log("Nothing to update.");
			}
			return updateFunction();
	  }

		  // cacheReducer(cachedData) -> updatedCacheData
		cacheAndUpdateAfterResponse = (key, module, cacheReducer, updateFunction) => {
				// console.log("Update Cache Hit Key After Update: " + key + module);
				let moduleKey      = this.keyModule(key, module);
				let cachedResponse = InMemoryCache.cache[moduleKey]
				if (cachedResponse && !this.isTimeout(cachedResponse.timestamp)) { // Update local cache
					return updateFunction().then(response => {
						try { 
							let updatedCacheData = cacheReducer(response);
							if (updatedCacheData) {
								InMemoryCache.cache[moduleKey] = { response: updatedCacheData, timestamp: new Date().getTime() };
								// console.log("Updated cache: " + moduleKey);
							}
						 } catch(error) {
							 this.invalidateForKey(key, module);
							//  console.log("Failed to update local cache :" + moduleKey + " : "  + error); 
							}
						return response
					})
				} else {
					// console.log("Nothing to update.");
				}
				return updateFunction();
			}

    callInProgress = {}
    onMiss = (key, module, fetchPromiseFunction) => {
        const moduleKey = this.keyModule(key, module);
        if (this.callInProgress[moduleKey]) { return this.callInProgress[moduleKey]; }
        // console.log("SK_CACHE: on MISS: " + moduleKey);
        const version = InMemoryCache.cacheVersion;
        let fetchPromise = fetchPromiseFunction(key).then(response => {
            if (version !== InMemoryCache.cacheVersion) { return; }
            InMemoryCache.cache[moduleKey] = { response: response, timestamp: new Date().getTime() };
            return response;
        }).finally(() => { this.callInProgress[moduleKey] = null; });
        this.callInProgress[moduleKey] = fetchPromise;
        
        // Fallback if the funciton won't finish in 30 seconds. 
        setTimeout(() => { this.callInProgress[moduleKey] = null; }, 30000);
        return fetchPromise;
    }

    invalidate = () => {
        InMemoryCache.cacheVersion = new Date().getTime() + "";
        InMemoryCache.cache = {};
    }

    invalidateForKey = (key, module) => {
        InMemoryCache.cache[this.keyModule(key, module)] = null;
    }

    isTimeout = (timestamp)  => {
        if (!timestamp) { return true; }
        const time       = new Date().getTime();
        const sourceTime = parseInt(timestamp) + this.ttlInMilliSec;
        let isTime = sourceTime < time;
        // console.log("SK_CACHE: " + sourceTime +  "  :  " + time + "  :  " + isTime  + " :  " + (sourceTime - time));
        return isTime;
    }

}

var cache = new InMemoryCache();
export default cache;