import { store } from "../index";
import { skConfiguration } from "../constants/actionType";
import HWUser from "../../HWLibrary/HWUser";
import { apiCall } from "../api";
import { setGlobalStates } from '../../store/reducers/skGlobalReducer';

export const setSmartConfigurationStates = (obj) => async dispatch => {
	dispatch({
		type: skConfiguration?.SET_CONFIGURATION_DATA, payload: obj
	})
}

export const updateDeletedTaskCategoty = (obj) => async dispatch => {
	dispatch({
		type: skConfiguration?.UPDATE_DELETED_CATEGORY_LIST, payload: {...obj}
	})
}

export const updateDeletedFunction = (obj) => async dispatch => {
	dispatch({
		type: skConfiguration?.UPDATE_DELETED_FUNCTION_LIST, payload: {...obj}
	})
}



export const undoDeletedTaskCategory = (requestData) => async dispatch => {
	let request = {}
	request.params = { orgId: HWUser.orgId(), appId: HWUser.appId(), userId: HWUser.userId() , categoryId:requestData?.category?.id , action:requestData?.action};
	try {
		const response = await apiCall({
			url: window.resolveRegion(process.env.REACT_APP_SMART_DASHBOARDS_BASE_URL) + "/smart-settings/task-category",
			method: 'DELETE',
			payload: request,
			addFilter: true
		});
		let req ={
			category:{id: requestData?.category?.id, name: requestData?.category?.name},
			action:"Remove"
		}
		dispatch(updateDeletedTaskCategoty(req));
		dispatch(fetchCategoryData());
		
	} catch (error) {
	}
}



export const getConfigurationStates = () => {
	return store.getState()?.skConfigurationReducer;
}



export const fetchCategoryData = (requestData)=>async dispatch=>{
	let request = {}
	request.params = { orgId: HWUser.orgId(), appId: HWUser.appId(), userId: HWUser.userId() ,...requestData};
	dispatch(setSmartConfigurationStates({ isLoadingCategory: requestData?.sortOrder ? false : true}))
	dispatch(setSmartConfigurationStates({ showCategoryLoader: requestData?.sortOrder ? true : false }))
	try {
		const response = await apiCall({
			url: window.resolveRegion(process.env.REACT_APP_SMART_DASHBOARDS_BASE_URL) + "/smart-settings/task-category",
			method: 'GET',
			payload: request,
			addFilter: true
		});

		dispatch(setSmartConfigurationStates({isLoadingCategory:false, categories: response?.data?.categories, showCategoryLoader:false }))
	} catch (error) {
		dispatch(setSmartConfigurationStates({ isLoadingCategory: false, showCategoryLoader:false}))
	}
}

export const updateCategoryData= (requestData)=>async dispatch=>{
	let request = { orgId: HWUser.orgId(), appId: HWUser.appId(), userId: HWUser.userId() ,...requestData};
	try {
		const response = await apiCall({
			url: window.resolveRegion(process.env.REACT_APP_SMART_DASHBOARDS_BASE_URL) + "/smart-settings/task-category",
			method: 'PUT',
			payload: request,
			addFilter: true
		});

	} catch (error) {
	}
}

export const deleteCategoryData= (requestData)=>async dispatch=>{
	let request = {}
	console.log("requestData...",requestData)
	request.params = { orgId: HWUser.orgId(), appId: HWUser.appId(), userId: HWUser.userId() ,...requestData};
	try {
		const response = await apiCall({
			url: window.resolveRegion(process.env.REACT_APP_SMART_DASHBOARDS_BASE_URL) + "/smart-settings/task-category",
			method: 'DELETE',
			payload: request,
			addFilter: true
		});

		if(requestData.action === 'Delete'){
			dispatch(
				setGlobalStates({
					showNotification: true,
					notificationMsg: "Category Deleted.",
				})
			);
	
			// Clear notificationMsg after 5 seconds
			setTimeout(() => {
				dispatch(
					setGlobalStates({
						showNotification: false,
						notificationMsg: "",
					})
				);
			}, 3000);
		}
		
   
	} catch (error) {
	}
}

export const AddCategoryData = (requestData)=>async dispatch=>{
	let request = { orgId: HWUser.orgId(), appId: HWUser.appId(), userId: HWUser.userId() ,...requestData};
	try {
		const response = await apiCall({
			url: window.resolveRegion(process.env.REACT_APP_SMART_DASHBOARDS_BASE_URL) + "/smart-settings/task-category",
			method: 'POST',
			payload: request,
			addFilter: true
		});
    return response;
	} catch (error) {
		return error.response;
	}
}

export const fetchFunctionData = (requestData, type) => async dispatch => {
	let request = {}
	request.params = { orgId: HWUser.orgId(), appId: HWUser.appId(), userId: HWUser.userId() ,...requestData};
	dispatch(setSmartConfigurationStates({ isLoadingFunction: requestData?.sortOrder || type === 'Update' ? false : true }))
	dispatch(setSmartConfigurationStates({ showFunctionLoader: requestData?.sortOrder ? true : false }))
	dispatch(setSmartConfigurationStates({ isUpdating: type ? true : false }))
	

	try {
		const response = await apiCall({
			url: window.resolveRegion(process.env.REACT_APP_SMART_DASHBOARDS_BASE_URL) + "/smart-settings/functions",
			method: 'GET',
			payload: request,
			addFilter: false
		});

		dispatch(setSmartConfigurationStates({isLoadingFunction:false, functions: response?.data?.functions, showFunctionLoader:false, isUpdating: false }))
	} catch (error) {
		dispatch(setSmartConfigurationStates({ isLoadingFunction: false, showFunctionLoader: false, isUpdating: false}))
	}

}

export const updateFunctionData = (requestData) => async dispatch => {
	let request = { orgId: HWUser.orgId(), appId: HWUser.appId(), userId: HWUser.userId() ,...requestData};
	try {
		const response = await apiCall({
			url: window.resolveRegion(process.env.REACT_APP_SMART_DASHBOARDS_BASE_URL) + "/smart-settings/functions",
			method: 'PUT',
			payload: request,
			addFilter: false
		});

	} catch (error) {
	}
}

export const deleteFunctionData= (requestData)=>async dispatch=>{
	let request = {}
	request.params = { orgId: HWUser.orgId(), appId: HWUser.appId(), userId: HWUser.userId() ,...requestData};
	try {
		const response = await apiCall({
			url: window.resolveRegion(process.env.REACT_APP_SMART_DASHBOARDS_BASE_URL) + "/smart-settings/functions",
			method: 'DELETE',
			payload: request,
			addFilter: false
		});
		if(requestData.action === 'Delete'){
			dispatch(
				setGlobalStates({
					showNotification: true,
					notificationMsg: "Function Deleted.",
				})
			);
	
			// Clear notificationMsg after 5 seconds
			setTimeout(() => {
				dispatch(
					setGlobalStates({
						showNotification: false,
						notificationMsg: "",
					})
				);
			}, 3000);
		}
	} catch (error) {
	}
}

export const undoDeletedFunction = (requestData)=>async dispatch=>{
	let request = {}
	request.params = { orgId: HWUser.orgId(), appId: HWUser.appId(), userId: HWUser.userId() ,functionId:requestData?.func?.id , action:requestData?.action};
	try {
		const response = await apiCall({
			url: window.resolveRegion(process.env.REACT_APP_SMART_DASHBOARDS_BASE_URL) + "/smart-settings/functions",
			method: 'DELETE',
			payload: request,
			addFilter: false
		});
		let req ={
			function:{id: requestData?.func?.id, name: requestData?.func?.name},
			action:"Remove"
		}
		dispatch(updateDeletedFunction(req));
		dispatch(fetchFunctionData());
	} catch (error) {
	}
}


export const AddFunctionData = (requestData)=>async dispatch=>{
	let request = { orgId: HWUser.orgId(), appId: HWUser.appId(), userId: HWUser.userId() ,...requestData};
	try {
		const response = await apiCall({
			url: window.resolveRegion(process.env.REACT_APP_SMART_DASHBOARDS_BASE_URL) + "/smart-settings/functions",
			method: 'POST',
			payload: request,
			addFilter: false
		});
    return response;
	} catch (error) {
	}
}
