import React, { useEffect, } from 'react';
import './SkSmartInputButton.css';
import UiLabel from '../UiLabel/UiLabel';

const SkSmartInputButton = ({ isDisabled, className, type, onBlur, style, value, onChange, id, isRequired, isError, apiError, styles, placeholder, autoFocus, allowedKey, showArrowIcon, increamentValue, decreamentValue, showSearchIcon, showCalendarIcon,onClickCalendarIcon, isSubtask, ...props }) => {
	const checkValueEmpty = (val) => {
		return ((val === ''|| !val) && isError) ? 'error__border__bottom' : ''
		// return (isError && (val === '' || val === 0 || apiError)) ? 'sk__input__error_msg' : ((val === '' || val === 0 || !val) && !isError) ? 'error__border__bottom' : ''
	}
	// const checkValueEmptyOnChange = (val) => {
	// 	return (isError && (val === '' || val === 0)) ? { borderBottom: '' } : ((val === '' || val === 0) && isError == '') ? { borderBottom: '1px solid red', color: '#000' } : { borderBottom: '' }
	// }

	// const toFixValue = (e) => {
	// 	var t = e.target.value;
	// 	e.target.value = (t.indexOf('.') >= 0) ? (t.substr(0, t.indexOf('.')) + t.substr(t.indexOf('.'), 2)) : t;
	// }

	const onKeyDown = (evt) => {
		type === 'number' && (allowedKey === '.' ? evt.key === '-' || evt.key === 'e' : evt.key === '.' || evt.key === '-' || evt.key === '+' || evt.key === 'e') && evt.preventDefault();
		// type === 'number' && allowedKey === '.' && toFixValue(evt);
		if ((evt.key === ' ' || evt.keyCode === 32 || evt.code === 'Space') && evt.target.selectionStart === 0) {
			evt.preventDefault();
		}
		if (evt.key === 'Enter' /* Enter */) { evt.preventDefault(); }
		// type === 'number' && (evt.which !== 8 && evt.which !== 0 && evt.which < 48 || evt.which > 57) && evt.preventDefault();
		props?.onKeyDown && props.onKeyDown(evt, props);
	} //use this to prevent with '-', '.'

	useEffect(() => {
		if (id) {
			var element = document.getElementById(id);
			if (element && autoFocus) {
				element.focus();
			}
		}
		else {
			var element = document.getElementsByClassName("smart__input__btn");
			if (element?.length > 0 && autoFocus) {
				element[0].focus();
			}
		}
	}, [])

	return (
		<span onClick={(e) => { e.preventDefault(); e.stopPropagation(); props.onClickContainer && props.onClickContainer(e) }} className={`${showArrowIcon ? "smart__input__btn_conatiner" : "smart__input__btn-container"} ${props.containerCallsname}`}>
			{props.showTooltip?<span className='w-100'><UiLabel showOnlyTooltip={true}  length={0} placement="top" tooltipClass="HWSelectMultiSelectTooltip" label={props?.showTooltipText}  >
				<input disabled={isDisabled || false} autoComplete='off' type={type} {...props} onKeyDown={onKeyDown} onBlur={(e) => { e.preventDefault(); e.stopPropagation(); onBlur && onBlur(e) }} className={`smart__input__btn  ${className}  ${isRequired ? checkValueEmpty(value) : ''}`} style={isSubtask ? {width:'80%', marginLeft: '10px'} : style} value={value} onChange={(e) => {onChange && onChange(e)}}  id={id} placeholder={placeholder} />
			</UiLabel></span>:
			<span className='w-100'><input disabled={isDisabled || false} autoComplete='off' type={type} {...props} onKeyDown={onKeyDown} onBlur={(e) => { e.preventDefault(); e.stopPropagation(); onBlur && onBlur(e) }} className={`smart__input__btn  ${className}  ${isRequired ? checkValueEmpty(value) : ''}`} style={isSubtask ? {width:'80%', marginLeft: '10px'} : style} value={value} onChange={(e) => {onChange && onChange(e)}}  id={id} placeholder={placeholder} /></span>}
			{props?.isPercentageField &&
				<span className='smart__input__btn-percentage-icon' >%</span>
			}
			{props.inputDescriptionText &&
				<span className='smart__input__btn-text' >{props.inputDescriptionText}</span>
			}
			{showArrowIcon && !isDisabled &&
				<span className='smart__input__btn_icon_container' onClick={(e) => { e.stopPropagation(); }} style={style} >
					<i className='smart__input__btn_up_arrow' onClick={(e) => { e.preventDefault(); e.stopPropagation(); increamentValue && increamentValue() }} ></i>
					<i className='smart__input__btn_arrow' onClick={(e) => { e.preventDefault(); e.stopPropagation(); decreamentValue && decreamentValue() }}>
					</i></span>}

			{showSearchIcon && <span className='search__icon__container'>
				<span className='smart__input___search__icon'></span>
			</span>}
			{showCalendarIcon &&
				<span className='input__btn__calendar__icon__container' onClick={(e)=>{onClickCalendarIcon && onClickCalendarIcon(e)}}>
					<span className='calendar__icon'>
					</span>
				</span>}


		</span>
	)
}



export default SkSmartInputButton


