import { userAction } from "../constants/actionType";

const initialState = {
    isError: "",
    isApiLoading: false,
    isLoading:false,
    userList: [],
    pfUser:'',
    contactList: [],
    endUserList:[],
    assignedIds: [],
    userDetails: {assignedAccount: []},
    errorPopupMsg: "",
    successMsg: "",
    activeId: "",
    apiError:'',
    activeContact:{},
    renderToggle: "",
    contactId:'',
    actEndUserAcc:'',
    nextToken:null,
    searchTxt:'',
    sortBy:'',
    userRoleOptions:[]
  
};

const userActionReducer = (state = initialState, { type, payload } = {}) => {
  
  switch (type) {
    case userAction.FETCH_PLATFORM_USERS: {return { ...state,...payload}}
    case userAction?.FETCH_PLATFORM_SINGLE_USERS: {return { ...state,...payload}}
    case userAction?.FETCH_PLATFORM_SINGLE_USERS_ACCOUNT_ASSOCIATIONS: {return { ...state,...payload}}
    case userAction.SAVE_PLATFORM_USERS: {return { ...state,...payload}}
    case userAction?.SET_LOADING: {return { ...state,...payload}}
    case userAction?.SET_STATE: {return { ...state,...payload}}
    case userAction?.CHANGE_USER_STATUS: {return { ...state,...payload}}
    case userAction.FETCH_CONTACT_LIST: {return { ...state,...payload}}
    case userAction?.IS_ERROR: {return { ...state,...payload}}
    case userAction?.EDIT_CONTACT: {return { ...state,...payload}}
    case userAction?.FETCH_END_USER: {return { ...state,...payload}}
    case userAction?.SET_PRIMARY_CSM: {return { ...state,...payload}}
    case userAction?.FETCH_USER_ROLE: {return { ...state,...payload}}
    case userAction?.FETCH_USER_MAPPING_REF: {return { ...state,...payload}}
    default:return state;
  }
};

export default userActionReducer;
