import axios from "axios";
import hwAxios from "../../HWLibrary/HWAxios";
import HWUser from "../../HWLibrary/HWUser";


class SkTouchpointservice {
	constructor() {
		if (!SkTouchpointservice.instance) { SkTouchpointservice.instance = this; }
		return SkTouchpointservice.instance;
	}

	touchpointOutComesData =  (request) => {
		let url = window.resolveRegion(process.env.REACT_APP_SMART_DASHBOARDS_BASE_URL) + "/touchpoints/dashboard";
		let aConfig = { headers: { "Authorization": HWUser.accessToken(), "Content-Type": "application/json","addFilter": true } };
		aConfig.params = { 
				orgId: HWUser.orgId(), 
				appId: HWUser.appId(), 
				userId: HWUser.userId(),
				...request 
		}
		return hwAxios.get(url, aConfig);
	}

	touchpointtype = (request) => {
		let data   = { 
				appId: HWUser.appId(), 
				userId: HWUser.userId(),
				orgId:HWUser.orgId(),
				...request
		}
		let config = { headers: { "Authorization": HWUser.accessToken(), "Content-Type": "application/json","addFilter": true  } };
		let url = window.resolveRegion(process.env.REACT_APP_SMART_DASHBOARDS_BASE_URL) + "/touchpoints/touchpoint-type";
		return hwAxios.post(url, data, config);
}

featureLeveAccountProducts = (request) => {
	let url =	window.resolveRegion(process.env.REACT_APP_SMART_DASHBOARDS_BASE_URL) + "/smart-filter/product-search";
	let aConfig = { headers: { "Authorization": HWUser.accessToken(), "Content-Type": "application/json"} };
	aConfig.params = { 
			orgId: HWUser.orgId(), 
			appId: HWUser.appId(), 
			userId: HWUser.userId(),
			...request 
	}
	return axios.get(url, aConfig);
}
	fetchTouchpointConacts = (request) => {
		let url =	window.resolveRegion(process.env.REACT_APP_DASHBOARDS_BASE_API_URL)+ "/contacts";
		let aConfig = { headers: { "Authorization": HWUser.accessToken(), "Content-Type": "application/json"} };
		aConfig.params = { 
				orgId: HWUser.orgId(), 
				appId: HWUser.appId(), 
				userId: HWUser.userId(),
				...request 
		}
		return axios.get(url, aConfig);
	}

	

	touchpointListingData = (request) => {
		// debugger
		let data   = { 
			appId: HWUser.appId(), 
			userId: HWUser.userId(),
			orgId:HWUser.orgId(),
			...request
		}
		let config = { headers: { "Authorization": HWUser.accessToken(), "Content-Type": "application/json", "addFilter": true } };	
		let url =	window.resolveRegion(process.env.REACT_APP_SMART_DASHBOARDS_BASE_URL) + "/touchpoints/listing";
		return hwAxios.post(url, data, config);
	}

	

	updateTouchpoint = (touchpointData) => {

		// let url = window.resolveRegion(process.env.REACT_APP_DASHBOARDS_BASE_API_URL) + "/touchpoints";
		let url = window.resolveRegion(process.env.REACT_APP_SMART_DASHBOARDS_BASE_URL) + "/touchpoints/smart-touchpoint";
		let aConfig = { headers: { "Authorization": HWUser.accessToken(), "Content-Type": "application/json" } };
		let request = { 
				userId: HWUser.userId(), 
				orgId:HWUser.orgId(),
				appId:HWUser.appId(),
				...touchpointData  
		}
		return axios.put(url, request, aConfig);
	}
	editTouchpointtype= (touchpointData) => {
		let url = window.resolveRegion(process.env.REACT_APP_SMART_DASHBOARDS_BASE_URL) + "/touchpoints/touchpoint-type";
		let aConfig = { headers: { "Authorization": HWUser.accessToken(), "Content-Type": "application/json" } };
		let request = { 
				userId: HWUser.userId(), 
				orgId:HWUser.orgId(),
				appId:HWUser.appId(),
				...touchpointData  
		}
		return axios.put(url, request, aConfig);
	}

	templateDataList =  (request) =>  {
		let url =	window.resolveRegion(process.env.REACT_APP_SMART_DASHBOARDS_BASE_URL) + "/playbook/template/list";
		let aConfig = { headers: { "Authorization": HWUser.accessToken(), "Content-Type": "application/json","addFilter": true } };
		aConfig.params = { 
				orgId: HWUser.orgId(), 
				appId: HWUser.appId(), 
				userId: HWUser.userId(),
				...request 
		}
		return hwAxios.get(url, aConfig);
	}
	templateData =  (request) =>  {
		let url =	window.resolveRegion(process.env.REACT_APP_SMART_DASHBOARDS_BASE_URL) + "/playbook/template";
		let aConfig = { headers: { "Authorization": HWUser.accessToken(), "Content-Type": "application/json","addFilter": true } };
		aConfig.params = { 
				orgId: HWUser.orgId(), 
				appId: HWUser.appId(), 
				userId: HWUser.userId(),
				...request 
		}
		return hwAxios.get(url, aConfig);
	}

	templateTaskData =  (request) =>  {
		let url =	window.resolveRegion(process.env.REACT_APP_SMART_DASHBOARDS_BASE_URL) + "/playbook/template/task-list";
		let aConfig = { headers: { "Authorization": HWUser.accessToken(), "Content-Type": "application/json","addFilter": true } };
		aConfig.params = { 
				orgId: HWUser.orgId(), 
				appId: HWUser.appId(), 
				userId: HWUser.userId(),
				...request 
		}
		return hwAxios.get(url, aConfig);
	}
	
	createMilestone=(request) =>  {
		let data   = { 
			appId: HWUser.appId(), 
			userId: HWUser.userId(),
			orgId:HWUser.orgId(),
			...request
	}
		let config = { headers: { "Authorization": HWUser.accessToken(), "Content-Type": "application/json"  } };
		let url = window.resolveRegion(process.env.REACT_APP_SMART_DASHBOARDS_BASE_URL) + "/playbook/template/milestone";
		return axios.post(url, data, config);
	}
	createMilestoneTask = (request) =>  {
		let data   = { 
			appId: HWUser.appId(), 
			userId: HWUser.userId(),
			orgId:HWUser.orgId(),
			...request
	}
		let config = { headers: { "Authorization": HWUser.accessToken(), "Content-Type": "application/json"  } };
		let url = window.resolveRegion(process.env.REACT_APP_SMART_DASHBOARDS_BASE_URL) + "/playbook/template/task";
		return axios.post(url, data, config);
	}

	createTouchpoint = (request) =>  {
		let data   = { 
			appId: HWUser.appId(), 
			userId: HWUser.userId(),
			orgId:HWUser.orgId(),
			...request
	}
		let config = { headers: { "Authorization": HWUser.accessToken(), "Content-Type": "application/json"  } };
		let url = window.resolveRegion(process.env.REACT_APP_DASHBOARDS_BASE_API_URL) + "/touchpoints";
		return axios.post(url, data, config);
	}
	updateMilestoneTask = (data) => {
		let url = window.resolveRegion(process.env.REACT_APP_SMART_DASHBOARDS_BASE_URL) + "/playbook/template/task";
		let aConfig = { headers: { "Authorization": HWUser.accessToken(), "Content-Type": "application/json" } };
		let request = { 
				userId: HWUser.userId(), 
				orgId:HWUser.orgId(),
				appId:HWUser.appId(),
				...data  
		}
		return axios.put(url, request, aConfig);
	}
	linkTouchpoint = (data) => {
		let url = window.resolveRegion(process.env.REACT_APP_SMART_DASHBOARDS_BASE_URL) + "/playbook/live/task/complete";
		let aConfig = { headers: { "Authorization": HWUser.accessToken(), "Content-Type": "application/json" } };
		let request = { 
				userId: HWUser.userId(), 
				orgId:HWUser.orgId(),
				appId:HWUser.appId(),
				...data  
		}
		return axios.put(url, request, aConfig);
	}
	updateMilestone = (data) => {
		let url = window.resolveRegion(process.env.REACT_APP_SMART_DASHBOARDS_BASE_URL) + "/playbook/template/milestone";
		let aConfig = { headers: { "Authorization": HWUser.accessToken(), "Content-Type": "application/json" } };
		let request = { 
				userId: HWUser.userId(), 
				orgId:HWUser.orgId(),
				appId:HWUser.appId(),
				...data  
		}
		return axios.put(url, request, aConfig);
	}
	deleteMilestask= (request)=>{
		let url        = window.resolveRegion(process.env.REACT_APP_SMART_DASHBOARDS_BASE_URL)+"/playbook/template/task"
		let aConfig    = { headers: { "Authorization": HWUser.accessToken(), "Content-Type": "application/json" } };
		aConfig.params = { ...request,appId:HWUser.appId(),orgId:HWUser.orgId(),userId:HWUser.userId() }
		return axios.delete(url, aConfig);
	}
	deleteMilestone= (request)=>{
		let url        = window.resolveRegion(process.env.REACT_APP_SMART_DASHBOARDS_BASE_URL)+"/playbook/template/milestone"
		let aConfig    = { headers: { "Authorization": HWUser.accessToken(), "Content-Type": "application/json" } };
		aConfig.params = { ...request,appId:HWUser.appId(),orgId:HWUser.orgId(),userId:HWUser.userId() }
		return axios.delete(url, aConfig);
	}

	palybookDataList =  (request) =>  {
		// let url =	window.resolveRegion(process.env.REACT_APP_SMART_DASHBOARDS_BASE_URL) + "/playbook/live/list";
		// let aConfig = { headers: { "Authorization": HWUser.accessToken(), "Content-Type": "application/json","addFilter": true } };
		// aConfig.params = { 
		// 		orgId: HWUser.orgId(), 
		// 		appId: HWUser.appId(), 
		// 		userId: HWUser.userId(),
		// 		...request 
		// }
		// return hwAxios.get(url, aConfig);
		let data   = { 
			orgId: HWUser.orgId(), 
				appId: HWUser.appId(), 
				userId: HWUser.userId(),
				...request 
		}
		let config = { headers: { "Authorization": HWUser.accessToken(), "Content-Type": "application/json" ,"addFilter": true } };
		let url =	window.resolveRegion(process.env.REACT_APP_SMART_DASHBOARDS_BASE_URL) + "/playbook/live/list";
		return hwAxios.post(url, data, config);
	}

	playbookTaskData =  (request) =>  {
		let url =	window.resolveRegion(process.env.REACT_APP_SMART_DASHBOARDS_BASE_URL) + "/playbook/live/task-list";
		let aConfig = { headers: { "Authorization": HWUser.accessToken(), "Content-Type": "application/json","addFilter": true } };
		aConfig.params = { 
				orgId: HWUser.orgId(), 
				appId: HWUser.appId(), 
				userId: HWUser.userId(),
				...request 
		}
		return hwAxios.get(url, aConfig);
	}
}

var skTouchpointservice = new SkTouchpointservice();
export default skTouchpointservice;