export const userAction = {
	FETCH_PLATFORM_USERS: 'FETCH_PLATFORM_USERS',
	FETCH_PLATFORM_SINGLE_USERS: 'FETCH_PLATFORM_SINGLE_USERS',
	FETCH_PLATFORM_SINGLE_USERS_ACCOUNT_ASSOCIATIONS: 'FETCH_PLATFORM_SINGLE_USERS_ACCOUNT_ASSOCIATIONS',
	FETCH_CONTACT_LIST: 'FETCH_CONTACT_LIST',
	SAVE_PLATFORM_USERS: 'SAVE_PLATFORM_USERS',
	SET_LOADING: 'SET_LOADING',
	CHANGE_USER_STATUS: 'CHANGE_USER_STATUS',
	IS_ERROR: 'IS_ERROR',
	SET_STATE: 'SET_STATE',
	EDIT_CONTACT: 'EDIT_CONTACT',
	FETCH_END_USER: 'FETCH_END_USER',
	SET_PRIMARY_CSM: 'SET_PRIMARY_CSM',
	FETCH_USER_ROLE: 'FETCH_USER_ROLE',
	FETCH_USER_MAPPING_REF: 'FETCH_USER_MAPPING_REF',
};

export const successplayAction = {
	SET_STATE: 'SET_STATE',
	FETCH_RERERENCE_DATA: 'FETCH_RERERENCE_DATA'
}

export const hsAction = {
	FETCH_HS_CAT: 'FETCH_HS_CAT',
	SET_HS_LOADING: 'SET_HS_LOADING',
	CHANGE_HS_STATUS: 'CHANGE_HS_STATUS',
	IS_ERROR: 'IS_ERROR',
	SET_HS_STATE: 'SET_HS_STATE',
	FETCH_HS_EDIT_CONFIG: 'FETCH_HS_EDIT_CONFIG',
	SAVE_HS_CONFIG: 'SAVE_HS_CONFIG',
	FETCH_RISK_RATING: 'SAVE_HS_CONFIG',
};

export const skCommentActions = {
	FETCH_SMART_COMMENTS_LIST: 'FETCH_SMART_COMMENTS_LIST',
	FETCH_SINGLE_COMMENT: 'FETCH_SINGLE_COMMENT',
	POST_SMART_COMMENT: 'POST_SMART_COMMENT',
	EDIT_SMART_COMMENT: 'EDIT_SMART_COMMENT',
	DELETE_SMART_COMMENT: 'DELETE_SMART_COMMENT',
	SET_SMART_COMMENT_STATES: 'SET_SMART_COMMENT_STATES',
	POST_COMMENT_REACTION: 'POST_COMMENT_REACTION'
}



export const skAttachmentActions = {
	SET_ATTACHMENT_LOADING: 'SET_ATTACHMENT_LOADING',
	SET_ATTACHMENT_ERROR: 'SET_ATTACHMENT_ERROR',
	FETCH_SMART_ATTACHMENTS_LIST: 'FETCH_SMART_ATTACHMENTS_LIST',
	POST_SMART_ATTACHMENT: 'POST_SMART_ATTACHMENT',
	DELETE_SMART_ATTACHMENT: 'DELETE_SMART_ATTACHMENT',
	SET_SMART_ATTACHMENT_STATES: 'SET_SMART_ATTACHMENT_STATES',
}


export const skKonversationActions = {
	FETCH_KONVERSATIONS_LIST: 'FETCH_KONVERSATIONS_LIST',
	SET_KONVERSATIONS_LOADING: 'SET_KONVERSATIONS_LOADING',
	FETCH_TRANSCRIPTS: 'FETCH_TRANSCRIPTS',
	EDIT_COACHING: 'EDIT_COACHING',
	FETCH_COMMENTS: 'FETCH_COMMENTS',
	IS_ERROR: 'IS_ERROR',
	SET_SMARTK_STATE: 'SET_SMARTK_STATE',
	FETCH_HS_EDIT_CONFIG: 'FETCH_HS_EDIT_CONFIG',
	SAVE_HS_CONFIG: 'SAVE_HS_CONFIG',
	FETCH_RISK_RATING: 'SAVE_HS_CONFIG',
	SET_RATING: 'SET_RATING',
	EDIT_COMMENT: 'EDIT_COMMENT',
	DELETE_COMMENT: 'DELETE_COMMENT',
	ZOOM_INTIGRATION_PULLOVER: 'ZOOM_INTIGRATION_PULLOVER',
	CALL_SUMMARY: 'CALL_SUMMARY',
	FETCH_REFERENCE_DATA: 'FETCH_REFERENCE_DATA',
	FETCH_MANAGE_KEYWORD_REFERENCE_DATA: 'FETCH_MANAGE_KEYWORD_REFERENCE_DATA',
	DELETE_KEYWORD_DATA: 'DELETE_KEYWORD_DATA',
	UPDATE_KEYWORD_DATA: 'UPDATE_KEYWORD_DATA'

};


export const skTaskActions = {
	SET_IS_LOADING: 'SET_IS_LOADING',
	SET_IS_ERROR: 'SET_IS_ERROR',
	POST_SMART_TASK: 'POST_SMART_TASK',
	EDIT_SMART_TASK: 'EDIT_SMART_TASK',
	MARK_TASK_AS_COMPLETE: 'MARK_TASK_AS_COMPLETE',
	CLEAN_SMART_SUB_TASK: 'CLEAN_SMART_SUB_TASK',
	DELETE_SMART_TASK: 'DELETE_SMART_TASK',
	FETCH_SMART_TASK_LIST: 'FETCH_SMART_TASK_LIST',
	FETCH_SINGLE_TASK_FOR_EDIT: 'FETCH_SINGLE_TASK_FOR_EDIT',
	SET_SMART_TASK_STATES: 'SET_SMART_TASK_STATES',
	FETCH_ACCOUNT_OPTIONS: 'FETCH_ACCOUNT_OPTIONS',
	FETCH_PRODUCT_OPTIONS: 'FETCH_PRODUCT_OPTIONS',
	FETCH_COLLABORATOR_OPTIONS: 'FETCH_COLLABORATOR_OPTIONS',
	FETCH_OUTCOME_OPTIONS: 'FETCH_OUTCOME_OPTIONS',
	FETCH_TIMELINE_OUTCOME_OPTIONS: 'FETCH_TIMELINE_OUTCOME_OPTIONS',
	RESET_SMART_TASK_LIST: 'RESET_SMART_TASK_LIST',
	SHOW_SMART_SKELETON: 'SHOW_SMART_SKELETON',
	HIDE_SMART_SKELETON: 'HIDE_SMART_SKELETON',
	SHOW_SMART_LOADING: 'SHOW_SMART_LOADING',
	HIDE_SMART_LOADING: 'HIDE_SMART_LOADING',
	COLUMN_PREFERENCE: 'COLUMN_PREFERENCE',
	SORT_PREFERENCE: 'SORT_PREFERENCE'
}

export const skTouchpointActions = {
	SET_IS_LOADING: 'SET_IS_LOADING',
	SET_IS_ERROR: 'SET_IS_ERROR',
	SET_SMART_TOUCHPOINT_STATES: 'SET_SMART_TOUCHPOINT_STATES',
}

export const skContactActions = {
	FETCH_CONTACTS_LIST: 'FETCH_CONTACTS_LIST',
	ADD_CONTACT: 'ADD_CONTACT',
	EDIT_CONTACT: 'EDIT_CONTACT',
	SET_CONTACT_LOADING: 'SET_CONTACT_LOADING',
	SET_SMARTK_STATE: 'SET_SMARTK_STATE',
	SET_IS_LOADING: 'SET_IS_LOADING',
	SET_IS_ERROR: 'SET_IS_ERROR',
	DELETE_CONTACT: 'DELETE_CONTACT',
	SEND_CONTACT_INVITE_CUSTOMER_PORTAL:'SEND_CONTACT_INVITE_CUSTOMER_PORTAL'
};


export const skAccountActions = {
	SET_ACCOUNT_STATE: 'SET_ACCOUNT_STATE',
	FETCH_ACCOUNT_LIST: 'FETCH_ACCOUNT_LIST',
	FETCH_ACCOUNT_SEGMENT_LIST: 'FETCH_ACCOUNT_SEGMENT_LIST'
};

export const skNotesActions = {
	FETCH_NOTES_LIST: 'FETCH_NOTES_LIST',
	ADD_NOTE: 'ADD_NOTE',
	EDIT_NOTE: 'EDIT_NOTE',
	SET_NOTES_LOADING: 'SET_NOTES_LOADING',
	SET_SMART_STATE: 'SET_SMART_STATE',
	SET_IS_LOADING: 'SET_IS_LOADING',
	SET_IS_ERROR: 'SET_IS_ERROR',
	DELETE_NOTE: 'DELETE_NOTE'
};


export const skMailActions = {
	FETCH_MAILS_LIST: 'FETCH_MAILS_LIST',
	ADD_MAIL: 'ADD_MAIL',
	SET_MAIL_LOADING: 'SET_MAIL_LOADING',
	SET_SMART_MAIL_STATE: 'SET_SMART_MAIL_STATE',
	SET_IS_LOADING: 'SET_IS_LOADING',
	SET_IS_ERROR: 'SET_IS_ERROR',
};

export const skPlayBookActions = {
	SET_SMART_PLAY_BOOK_STATES: 'SET_SMART_PLAY_BOOK_STATES',
	ADD_ASSIGNMENT_RULE: 'ADD_ASSIGNMENT_RULE',
	FETCH_ASSIGNMENT_RULES: 'FETCH_ASSIGNMENT_RULES',
	DELETE_ASSIGNMENT_RULE: 'DELETE_ASSIGNMENT_RULE',
	EDIT_ASSIGNMENT_RULE: 'EDIT_ASSIGNMENT_RULE',
	FETCH_SINGLE_ASSIGNMENT_RULE: 'FETCH_SINGLE_ASSIGNMENT_RULE',
	FETCH_PHASE_OPTIONS: 'FETCH_PHASE_OPTIONS',
	FETCH_TEMPLATE_OPTIONS: 'FETCH_TEMPLATE_OPTIONS',
	FETCH_OUTCOME_OPTIONS: 'FETCH_OUTCOME_OPTIONS',
	FETCH_ROLE_OPTIONS: 'FETCH_ROLE_OPTIONS',
	FETCH_USER_OPTIONS: 'FETCH_USER_OPTIONS',
	FETCH_ASSIGNEE_OPTIONS: 'FETCH_ASSIGNEE_OPTIONS',
	FETCH_HISTORY_LOGS_ASSIGNMENT_RULE: 'FETCH_HISTORY_LOGS_ASSIGNMENT_RULE',
	ADD_TEMPLATE: 'ADD_TEMPLATE',
	UPDATE_TEMPLATE: 'UPDATE_TEMPLATE',
	FETCH_AUDIENCE_OPTIONS: 'FETCH_AUDIENCE_OPTIONS',
	FETCH_VERIFY_AUDIENCE_DATA: 'FETCH_VERIFY_AUDIENCE_DATA',
	EDIT_VERIFY_AUDIENCE_DATA: 'EDIT_VERIFY_AUDIENCE_DATA',
	FETCH_REFERENCE_PRODUCTS: 'FETCH_REFERENCE_PRODUCTS',
	FETCH_PLAYBOOK_ANALYTICS: 'FETCH_PLAYBOOK_ANALYTICS',
	FETCH_PLAYBOOK_GETTING_STARTED_DETAILS: 'FETCH_PLAYBOOK_GETTING_STARTED_DETAILS',
	FETCH_PLAYBOOK_GETTING_STARTED_LIST_DATA:'FETCH_PLAYBOOK_GETTING_STARTED_LIST_DATA'
}


export const skCalendarActions = {
	FETCH_EVENT_LIST: 'FETCH_EVENT_LIST',
	SET_SMART_CALENDAR_STATE: 'SET_SMART_CALENDAR_STATE'
};

export const skMailNotificationActions = {
	FETCH_FEATURES: "FETCH_FEATURES",
	UPDATE_FEATURES: "UPDATE_FEATURES",
	SET_IS_LOADING: "SET_IS_LOADING",
	SET_IS_ERROR: "SET_IS_ERROR",
};


export const skAccount360Actions = {
	SET_SMART_ACCOUNT_360_STATES: 'SET_SMART_ACCOUNT_360_STATES',
	FETCH_ACCOUNT_360_SUMMARY: 'FETCH_ACCOUNT_360_SUMMARY',
	FETCH_ACCOUNT_360_NPS_TREND_DATA: 'FETCH_ACCOUNT_360_NPS_TREND_DATA',
	FETCH_ACCOUNT_360_HEALTH_SCORE_DATA: 'FETCH_ACCOUNT_360_HEALTH_SCORE_DATA',
	FETCH_ACCOUNT_360_ARR_TREND_DATA: 'FETCH_ACCOUNT_360_ARR_TREND_DATA',
	FETCH_ACCOUNT_360_PRODUCT_USAGE_TREND_DATA: 'FETCH_ACCOUNT_360_PRODUCT_USAGE_TREND_DATA',
	FETCH_ACCOUNT_360_REVENUE_TREND_DATA: 'FETCH_ACCOUNT_360_REVENUE_TREND_DATA',
	FETCH_ACCOUNT_360_USER_DATA: 'FETCH_ACCOUNT_360_USER_DATA',
	FETCH_ACCOUNT_360_CUSTOMER_PRIORITIES_DATA: 'FETCH_ACCOUNT_360_CUSTOMER_PRIORITIES_DATA',
	POST_ACCOUNT_360_CUSTOMER_PRIORITY: 'POST_ACCOUNT_360_CUSTOMER_PRIORITY',
	DELETE_ACCOUNT_360_CUSTOMER_PRIORITY: 'DELETE_ACCOUNT_360_CUSTOMER_PRIORITY',
	UPDATE_ACCOUNT_360_CUSTOMER_PRIORITY: 'UPDATE_ACCOUNT_360_CUSTOMER_PRIORITY'
}

export const skAccountListingActions = {
	SET_SMART_ACCOUNT_LISTING_STATES: 'SET_SMART_ACCOUNT_LISTING_STATES'
}


export const skUserPermissionActions = {
	FETCH_USERS_LIST: 'FETCH_USERS_LIST',
	ADD_USER: 'ADD_USER',
	EDIT_USER: 'EDIT_USER',
	SET_USER_LOADING: 'SET_USER_LOADING',
	SET_USER_STATE: 'SET_USER_STATE',
	SET_IS_LOADING: 'SET_IS_LOADING',
	SET_IS_ERROR: 'SET_IS_ERROR',
	DELETE_USER: 'DELETE_USER',
	SET_SMART_STATE: 'SET_SMART_STATE',
	FETCH_SINGLE_USER: 'FETCH_SINGLE_USER',
	DELETE_ALLOCATION: 'DELETE_ALLOCATION',
	UPDATE_ALLOCATIONT: 'UPDATE_ALLOCATIONT',
	FETCH_ACCOUNT: 'FETCH_ACCOUNT',
};


export const skAccountManagementActions = {
	FETCH_ACCOUNTS_LIST: 'FETCH_ACCOUNTS_LIST',
	ADD_ACCOUNT: 'ADD_ACCOUNT',
	EDIT_ACCOUNT: 'EDIT_ACCOUNT',
	SET_ACCOUNT_LOADING: 'SET_ACCOUNT_LOADING',
	SET_ACCOUNT_STATE: 'SET_ACCOUNT_STATE',
	SET_IS_LOADING: 'SET_IS_LOADING',
	SET_IS_ERROR: 'SET_IS_ERROR',
	DELETE_ACCOUNT: 'DELETE_ACCOUNT',
	SET_SMART_STATE: 'SET_SMART_STATE',
	FETCH_SINGLE_ACCOUNT: 'FETCH_SINGLE_ACCOUNT',
	FETCH_ATTRIBUTES: 'FETCH_ATTRIBUTES',
};

export const smartKeyOutcomeActions = {
	SET_SMART_KEY_OUTCOME_STATES: 'SET_SMART_KEY_OUTCOME_STATES',
	FETCH_CHURN_REASONS_REFERENCE_LIST: 'FETCH_CHURN_REASONS_REFERENCE_LIST',
	FETCH_CHURN_DASHBOARD_COUNT: 'FETCH_CHURN_DASHBOARD_COUNT',
	UPDATE_CHURN_REASON: 'UPDATE_CHURN_REASON',
	FETCH_DATA_CHURN_DASHBOARD: 'FETCH_DATA_CHURN_DASHBOARD',
	POST_CHURN_TOLERANCE: 'POST_CHURN_TOLERANCE'
}

export const skGlobalActions = {
	SET_GLOBAL_STATE: 'SET_GLOBAL_STATE',
	ADD_LOG: 'ADD_LOG',
	GET_COUNT: 'GET_COUNT',
	SET_ACCOUNT_STATE: 'SET_ACCOUNT_STATE',
	FETCH_ACCOUNT_LIST: 'FETCH_ACCOUNT_LIST',
	FETCH_ACCOUNT_SEGMENT_LIST: 'FETCH_ACCOUNT_SEGMENT_LIST',
	SET_IS_ERROR:'SET_IS_ERROR'
};

export const skKeyOutcomeActions = {
	SET_SMART_KEY_OUTCOME_STATES: 'SET_SMART_KEY_OUTCOME_STATES',
	FETCH_CHURN_REASONS_REFERENCE_LIST: 'FETCH_CHURN_REASONS_REFERENCE_LIST',
	FETCH_CHURN_DASHBOARD_COUNT: 'FETCH_CHURN_DASHBOARD_COUNT',
	UPDATE_CHURN_REASON: 'UPDATE_CHURN_REASON',
	FETCH_DATA_CHURN_DASHBOARD: 'FETCH_DATA_CHURN_DASHBOARD',
	POST_CHURN_TOLERANCE: 'POST_CHURN_TOLERANCE'
}

export const skCustomerPortalActions = {
	SET_SMART_CUSTOMER_PORTAL_STATES: 'SET_SMART_CUSTOMER_PORTAL_STATES',
	FETCH_CUSTOMER_PORTAL_USERS: 'FETCH_CUSTOMER_PORTAL_USERS'
}

export const skAlertActions = {
	SET_SMART_ALERT_STATES: 'SET_SMART_ALERT_STATES',
	FETCH_ALERT_SUMMARY_DATA: 'FETCH_ALERT_SUMMARY_DATA',
	FETCH_ALERT_DASHBOARD_DATA: 'FETCH_ALERT_DASHBOARD_DATA',
	UPDATE_ALERT_CLICK_DATA:'UPDATE_ALERT_CLICK_DATA'
}

export const skConfiguration = {
	SET_CONFIGURATION_DATA: 'SET_CONFIGURATION_DATA',
	GET_CONFIGURATION_DATA: 'GET_CONFIGURATION_DATA',
	SET_IS_ERROR:'SET_IS_ERROR',
  UPDATE_DELETED_FUNCTION_LIST:'UPDATE_DELETED_FUNCTION_LIST',
	UPDATE_DELETED_CATEGORY_LIST:'UPDATE_DELETED_CATEGORY_LIST',

}

export const skSettings = {
	SET_SETTING_DATA: 'SET_SETTING_DATA',
	GET_SETTING_DATA: 'GET_SETTING_DATA',
	UPDATE_DELETED_USER:'UPDATE_DELETED_USER',
	UPDATE_DELETED_ROLE:'UPDATE_DELETED_ROLE',
	SET_IS_ERROR:'SET_IS_ERROR',
}

export const skSmartInsightActions = {
	SET_SMART_INSIGHT_STATES: 'SET_SMART_INSIGHT_STATES',
	FETCH_CURRENT_HS: 'FETCH_CURRENT_HS',
	FETCH_HS_TRENDS: 'FETCH_HS_TRENDS',
	FETCH_INSIGHTS_TOUCHPOINT: 'FETCH_INSIGHTS_TOUCHPOINT' 
}













