import React, { useState, useEffect, useCallback,useMemo } from 'react';
import { ClickAwayListener } from '@material-ui/core';
import Popper from '@material-ui/core/Popper';
import moreIcon from '../../assets/images/icons/moreDots.svg';
import { Link } from 'react-router-dom';
import { Tooltip } from "@material-ui/core";
import { tooltipClasses } from '@mui/material/Tooltip';
import { SmartCheckBox } from '../SkSmartDropdown/SelectDropdown';
import { styled } from '@mui/material/styles';
import UiLabel from '../UiLabel/UiLabel';
import { useSelector } from 'react-redux';
import { getGlobalStates, setGlobalStates } from '../../store/reducers/skGlobalReducer';
import { useDispatch } from 'react-redux';
import { cloneDeep } from 'lodash';

const SKThreeDotMenu = ({ list, children, className, isScroll, isClose, rowData,
	setClose, setIsScroll, customIcon, popUpRefNumber, setPopUpRefNumber, title, showCustomThreeDotIcon, ...props }) => {

	const [state, setActState] = useState({
		shouldShowTooltip: false,
		shouldShowTaskActions: props.openThreeDotOpenBydeafult || false,
		anchorEl: "",
		showAddOrCreateTaskPopUp: false,
		taskIdToShow: null,
		projectId: ''
	});
	const [showTooltip, setShowTooltip] = useState(false);
	const getGlobalState = useSelector(getGlobalStates);
	const { gridTablesLastAccessedRowArray } = getGlobalState;
	const dispatch = useDispatch()

	useEffect(() => {
		const handleScroll = () => {
			setState({ shouldShowTaskActions: false, anchorEl: null });
		}
		let elemnt = document.querySelector(".datagrid__table__container .MuiDataGrid-virtualScroller");
		if (elemnt) {
			elemnt.addEventListener('scroll', handleScroll)
		}
		window.addEventListener('scroll', handleScroll)
		return () => {
			if (elemnt) {
				elemnt.removeEventListener('scroll', handleScroll)
			}
			window.removeEventListener('scroll', handleScroll)
		}
	}, [])


	const target = React.createRef();
	const popperRef = React.createRef();
	const wrapperTarget =React.createRef();
	const setState = (updatedState) => {
		setActState(prevState => (
			{
				...prevState,
				...updatedState,
			}
		))
	}

	const [refNumber] = useState(new Date().getTime() + (Math.random() * 100) + "");

	useEffect(() => {
		if (popUpRefNumber !== refNumber && !props.openThreeDotOpenBydeafult) { setState({ shouldShowTaskActions: false });}
	}, [popUpRefNumber, refNumber,props.openThreeDotOpenBydeafult]);

	useEffect(() => {
		if (isScroll && !props.openThreeDotOpenBydeafult) { setState({ shouldShowTaskActions: false }) }
	}, [isScroll],props.openThreeDotOpenBydeafult);

	useEffect(() => {
		if(!props.openThreeDotOpenBydeafult){
			setState({ shouldShowTaskActions: false });
		}
	}, [isClose,props.openThreeDotOpenBydeafult]);


	const handleClick = (e) => {
		setPopUpRefNumber && setPopUpRefNumber(refNumber);
		setState({ anchorEl: e.currentTarget })
		props.openPopup && props.openPopup();
	}


	const handleSelectedCell = useCallback(() => {

		let lastAccessedArray = gridTablesLastAccessedRowArray ? cloneDeep(gridTablesLastAccessedRowArray) : [];

		if (props?.rowId && props?.tableId) {
		
			if (lastAccessedArray && lastAccessedArray?.length > 0) {
				let currenttable = lastAccessedArray?.find(item => props?.tableId === item?.id);
				if (currenttable?.id === props?.tableId) {
					let newObject = {
						id: props?.tableId,
						lastAccessedRowId: [props?.rowId]
					};
					const indexToReplace = lastAccessedArray?.findIndex(obj => obj.id === props?.tableId);
					if (indexToReplace !== -1) {
						lastAccessedArray[indexToReplace] = newObject;
					}
				} else {
					let obj = {
						id: props?.tableId,
						lastAccessedRowId: [props?.rowId]
					}
					lastAccessedArray?.push(obj);
				}
			} else {
				let obj = {
					id: props?.tableId,
					lastAccessedRowId: [props?.rowId]
				}
				lastAccessedArray?.push(obj);
			}
			dispatch(setGlobalStates({ gridTablesLastAccessedRowArray: lastAccessedArray }))
		}
	},[dispatch, gridTablesLastAccessedRowArray, props?.rowId, props?.tableId])
	const sk_threedotMenuWrapper = useMemo(()=>{
		return document.getElementsByClassName("sk_threedotMenuWrapper")
	},[])

	return (
		<div ref={wrapperTarget} className={`${className}Wrap sk_threedotMenuWrapper`}>
			{children ? <span ref={target} onClick={(e) => {
				if (!props?.dontPreventEventBubbling) {
					e?.stopPropagation();
					e?.preventDefault()
				}
				handleClick(e);
				if (props?.handleSelectedCellInThreeDot) {
					handleSelectedCell()
				}
				
			
				setState({ shouldShowTooltip: state.shouldShowTaskActions, shouldShowTaskActions: !state.shouldShowTaskActions });
				setIsScroll && setIsScroll(false)
			}}>{children}</span> :
				<>
					{showCustomThreeDotIcon ?
						<div className='three__dot__small__icon__container'>
							<IconTooltip title={'Search'}>
								<i onClick={(e) => {
									e.stopPropagation(); e.preventDefault();
									handleClick(e);
									setState({ shouldShowTooltip: state.shouldShowTaskActions, shouldShowTaskActions: !state.shouldShowTaskActions })
									setShowTooltip(false)
									setClose && setClose(false);
									setIsScroll && setIsScroll(false)
								}} className='three__dot__small__icon'>
								</i></IconTooltip>
						</div> :
						<Tooltip open={showTooltip} placement="top" title={props?.iconDescription || "Actions"}>
							<img src={props.moreIcon ? props.moreIcon : moreIcon} className='more-icon' ref={target} onMouseOver={(e) => { setShowTooltip(true) }} onMouseLeave={(e) => { setShowTooltip(false) }} onClick={(e) => {
								e.stopPropagation(); e.preventDefault();
								handleClick(e);
								setState({ shouldShowTooltip: state.shouldShowTaskActions, shouldShowTaskActions: !state.shouldShowTaskActions })
								setShowTooltip(false)
								setClose && setClose(false);
								setIsScroll && setIsScroll(false)
							}} alt="" />
						</Tooltip>}
				</>
			}
			{state.shouldShowTaskActions && 
				<ClickAwayListener onClickAway={(e) => {  setState({ shouldShowTaskActions: false });props.onCloseThreedotMenu && props.onCloseThreedotMenu(); setClose && setClose(false); setIsScroll && setIsScroll(false) }}>
					<Popper menuPortalTarget={document.body}  id={"tooltip-"} ref={popperRef} className={`skActions spTaskActions ${className}`} placement={props.placement} open={true} anchorEl={state.anchorEl || wrapperTarget?.current || sk_threedotMenuWrapper?.[0]}>
						<div className="show_list_items_abs_click bottom left">
							<div className="show_list_items_abs_menus">
								<ul>
									{title && <li className="skMore">{title} ({list?.length})</li>}
									{(list || [])?.map(item =>
									
									{
										return (
											<li className={"action-item " + item?.className} style={{ backgroundColor: item.isDisabled ? "#f2f2f2" : "", opacity: item.isDisabled ? 0.6:1  , cursor: item.isDisabled ? 'default': 'pointer'}} onClick={(e) => {
												
												e.stopPropagation(); e.preventDefault(); props.onCloseThreedotMenu && props.onCloseThreedotMenu();setState({ shouldShowTaskActions: false }); item?.onClick && !item.isDisabled && item.onClick(e, rowData); setIsScroll && setIsScroll(false)
											}}>
											<>
											<UiLabel className={item?.isDisabled ? "disabled__three__dot__menu__tooltip" :""} showOnlyTooltip={true} showToolTipWithHtml={true} length={0} placement='top'  label={item?.wrapperDiscription}>
														{item.type !== "check-box" && <Link to={item?.link} state={item?.state} className={"show_sk_popup "} data-type="touchpoint_popup" style={{ cursor: item?.isDisabled ? "default" : "" }} onClick={(e) => {
															e.stopPropagation(); e.preventDefault(); props.onCloseThreedotMenu && props.onCloseThreedotMenu();setState({ shouldShowTaskActions: false }); item?.onClick && !item.isDisabled && item.onClick(e, rowData); setIsScroll && setIsScroll(false)
												} } >
													{item.icon && <img src={item.icon} alt={item?.name || ""} />}
													{item?.iconClassName &&
																<>{item?.iconDiscription?<UiLabel showToolTipWithHtml={true} length={0} placement='bottom' tooltipClass={'pc-usage-trend-infi-tooltip w300 account__360__pu__score__tooltip ' + item?.infoIconClassname}
																label={item?.iconDiscription}>
																	<div className={item?.iconClassName+ " icon"} ></div>	</UiLabel>:<div className={item?.iconClassName + " icon"}></div>}</>
													
													}
													<div style={{ marginLeft: (item?.iconClassName || item.icon) ? "5px" : "10px" }} className='three__dot__menu__name'>{item?.name}</div>
													{item?.infoIconRequired && 
													<div style={{ marginLeft: '5px', marginTop:'4px' }}>
													<UiLabel showToolTipWithHtml={true} length={0} placement='right' tooltipClass={'pc-usage-trend-infi-tooltip w300 account__360__pu__score__tooltip ' + item?.infoIconClassname}
														label={item?.infoIconText}>
														<div className='theme__based__info__icon'/>
													</UiLabel>
												</div>}
												</Link>}
									
												{item.type === "check-box" && <SmartCheckBox className={"show_sk_popup_contact_optout "} label={'Opt-out'} checked={item?.isEnabled} value={'optout'} onClick={(e) => {
													e.stopPropagation(); e.preventDefault(); setState({ shouldShowTaskActions: false });props.onCloseThreedotMenu && props.onCloseThreedotMenu(); item?.onClick && item.onClick(e)
												}} />}
												</UiLabel>
												</>
											</li>
										)
					}
									)}
								</ul>
							</div>
						</div>
					</Popper>
				</ClickAwayListener>
			}
		</div>
	)
}

const IconTooltip = styled(({ className, ...props }) => (
	<Tooltip {...props} classes={{ popper: className }} HeatMapGraphContainer />
))
	(() => ({
		[`& .${tooltipClasses.tooltip}`]: {
			backgroundColor: "#000043 !important",
			color: "#FFFFFF",
			top: "7px ",
			height: "33px !important",
			opacity: "0.65 !important",
			display: "flex",
			left: "0px !important",
			alignItems: "center !important",
			position: "absolute",
			marginTop: "33px !important",
			marginLeft: "-300px !important",
		}
	}));
export default SKThreeDotMenu;