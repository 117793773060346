import Skeleton from '@mui/material/Skeleton';
import Box from '@mui/material/Box';

import { Row, Col } from 'react-bootstrap';


export const SkeletonCardFeatureUsage = () => {
	return (
		<div style={{ width: "100%", padding: "30px" }}>
			<Skeleton height={50} width={"100%"} animation="wave" variant="rounded" style={{ marginBottom: "30px" }} />
			<Skeleton height={350} width={"100%"} animation="wave" variant="rounded" />
		</div>
	);
}

export const TaskSkeletonCard = () => {
	
	return (
		<Box sl={{ width: 300, height: 500 }}>
		<Skeleton />
		<Skeleton animation="wave" />
		<Skeleton animation={false} />
	</Box>
	);
}

export const TableSkeleton = ({ className, isTaskTable }) => {

	return (

		<div style={{ width: "100%", padding: "30px", backgroundColor: "#fff" }}>
			<Row className="table-head">
				<Skeleton height={40} width={"100%"} animation="wave" variant="rounded" style={{ marginBottom: "10px" }} />
				<Skeleton height={40} width={"100%"} animation="wave" variant="rounded" style={{ marginBottom: "10px" }} />
				<Skeleton height={40} width={"100%"} animation="wave" variant="rounded" style={{ marginBottom: "10px" }} />
				<Skeleton height={40} width={"100%"} animation="wave" variant="rounded" style={{ marginBottom: "10px" }} />
				<Skeleton height={40} width={"100%"} animation="wave" variant="rounded" style={{ marginBottom: "10px" }} />
				{!isTaskTable && <Skeleton height={40} width={"100%"} animation="wave" variant="rounded" style={{ marginBottom: "10px" }} />}
				{!isTaskTable && <Skeleton height={40} width={"100%"} animation="wave" variant="rounded" style={{ marginBottom: "10px" }} />}
				{!isTaskTable && <Skeleton height={40} width={"100%"} animation="wave" variant="rounded" style={{ marginBottom: "10px" }} />}
				{!isTaskTable && <Skeleton height={40} width={"100%"} animation="wave" variant="rounded" style={{ marginBottom: "10px" }} />}
				{!isTaskTable && <Skeleton height={40} width={"100%"} animation="wave" variant="rounded" style={{ marginBottom: "10px" }} />}
				{!isTaskTable && <Skeleton height={40} width={"100%"} animation="wave" variant="rounded" style={{ marginBottom: "10px" }} />}
				{!isTaskTable && <Skeleton height={40} width={"100%"} animation="wave" variant="rounded" style={{ marginBottom: "10px" }} />}
				{!isTaskTable && <Skeleton height={40} width={"100%"} animation="wave" variant="rounded" style={{ marginBottom: "10px" }} />}

			</Row>
		</div>

	)
}

export const GridSkeleton = ({ className, isTaskTable }) => {

	return (

		<div style={{ width: "100%", padding: "30px", backgroundColor: "#fff" }}>
			<Row className="table-head">
			<Col><Skeleton height={150} width={"100%"} animation="wave" variant="rounded" style={{ marginBottom: "10px" }} /></Col>
			<Col><Skeleton height={150} width={"100%"} animation="wave" variant="rounded" style={{ marginBottom: "10px" }} /></Col>
			<Col><Skeleton height={150} width={"100%"} animation="wave" variant="rounded" style={{ marginBottom: "10px" }} /></Col>
			</Row>
			<Row className="table-head">
			<Col><Skeleton height={150} width={"100%"} animation="wave" variant="rounded" style={{ marginBottom: "10px" }} /></Col>
			<Col><Skeleton height={150} width={"100%"} animation="wave" variant="rounded" style={{ marginBottom: "10px" }} /></Col>
			<Col><Skeleton height={150} width={"100%"} animation="wave" variant="rounded" style={{ marginBottom: "10px" }} /></Col>
			</Row>
			<Row className="table-head">
			<Col><Skeleton height={150} width={"100%"} animation="wave" variant="rounded" style={{ marginBottom: "10px" }} /></Col>
			<Col><Skeleton height={150} width={"100%"} animation="wave" variant="rounded" style={{ marginBottom: "10px" }} /></Col>
			<Col><Skeleton height={150} width={"100%"} animation="wave" variant="rounded" style={{ marginBottom: "10px" }} /></Col>
			</Row>
		</div>

	)
}

export const GraphSkeleton = ({ headerText, showHeader }) => {
	return (
		<div className='smart__account__360__view__graph__detail__wrap'>
			{showHeader &&
				<div className='smart__account__360__view__wrap-header'>
					<div className='smart__account__360__view__wrap-header-text'>
						{headerText}
					</div>
				</div>
			}
			<div className='smart__account__360__view__graph__detail__wrap-content' style={{ height: (showHeader) ? '85%' : '100%', flexDirection: 'row' }}>
				<div style={{ width: '100%', height: '80%', display: 'flex', margin: '10px' }}>
					<Skeleton height={'100%'} width={'100%'} animation='wave' variant='rectangular' />
				</div>
			</div>
		</div>
	)
}


export const TableSkeletonForPopup = (className) => {

	return (

		<div style={{ width: "100%", padding: "30px", backgroundColor: 'white' }}>
			<Row className="table-head">
				<Skeleton height={30} width={"100%"} animation="wave" variant="rounded" style={{ marginBottom: "10px" }} />
				<Skeleton height={30} width={"100%"} animation="wave" variant="rounded" style={{ marginBottom: "10px" }} />
				<Skeleton height={30} width={"100%"} animation="wave" variant="rounded" style={{ marginBottom: "10px" }} />
				<Skeleton height={30} width={"100%"} animation="wave" variant="rounded" style={{ marginBottom: "10px" }} />
				<Skeleton height={30} width={"100%"} animation="wave" variant="rounded" style={{ marginBottom: "10px" }} />
				<Skeleton height={30} width={"100%"} animation="wave" variant="rounded" />
			</Row>
		</div>

	)
}

export const SkeletonCardForTouchpointIntelligence = () => {
	return (
		<div className="warningContent_wrap" style={{ backgroundColor: "#fff" }}>
			<div className="successIntelligenceContent_wrap">
				<div className="row h-100">
					<div className="col-md-12  intelligenceLeftContent_wrap">
						<Skeleton height={50} width={"100%"} animation="wave" variant="rounded" />
					</div>
				</div>
				<div className="row h-100">
					<div className="col-md-6  intelligenceLeftContent_wrap">
						<Skeleton height={250} width={"100%"} animation="wave" variant="rounded" />
					</div>
					<div className="col-md-6  intelligenceLeftContent_wrap">
						<Skeleton height={250} width={"100%"} animation="wave" variant="rounded" />
					</div>
				</div>
				<div className="row h-100">
					<div className="col-md-6  intelligenceLeftContent_wrap">
						<Skeleton height={250} width={"100%"} animation="wave" variant="rounded" />
					</div>
					<div className="col-md-6  intelligenceLeftContent_wrap">
						<Skeleton height={250} width={"100%"} animation="wave" variant="rounded" />
					</div>
				</div>
			</div>
		</div>
	)
}

export const SkeletonCardForPopOver = () => {
	return (
		<div className="row" style={{ backgroundColor: "#fff", width: "100%", height: '100%', overflow:'hidden'}}>
			<div>
				<ul className="notifications_list">
					<li>
						<figure style={{width:"60px"}}>
							<Skeleton variant="circular" width={45} height={45} animation="wave" />
						</figure>
						<p><Skeleton height={30} width={"100%"} animation="wave" /></p>
					</li>
				</ul>
				<ul className="notifications_list">
					<li>
						<figure style={{width:"60px"}}>
							<Skeleton variant="circular" width={45} height={45} animation="wave" />
						</figure>
						<p><Skeleton height={30} width={"100%"} animation="wave" /></p>
					</li>
				</ul>
				<ul className="notifications_list">
					<li>
						<figure style={{width:"60px"}}>
							<Skeleton variant="circular" width={45} height={45} animation="wave" />
						</figure>
						<p><Skeleton height={30} width={"100%"} animation="wave" /></p>
					</li>
				</ul>
				<ul className="notifications_list">
					<li>
						<figure style={{width:"60px"}}>
							<Skeleton variant="circular" width={45} height={45} animation="wave" />
						</figure>
						<p><Skeleton height={30} width={"100%"} animation="wave" /></p>
					</li>
				</ul>
				<ul className="notifications_list">
					<li>
						<figure style={{width:"60px"}}>
							<Skeleton variant="circular" width={45} height={45} animation="wave" />
						</figure>
						<p><Skeleton height={30} width={"100%"} animation="wave" /></p>
					</li>
				</ul>
				<ul className="notifications_list">
					<li>
						<figure style={{width:"60px"}}>
							<Skeleton variant="circular" width={45} height={45} animation="wave" />
						</figure>
						<p><Skeleton height={30} width={"100%"} animation="wave" /></p>
					</li>
				</ul>
				<ul className="notifications_list">
					<li>
						<figure style={{width:"60px"}}>
							<Skeleton variant="circular" width={45} height={45} animation="wave" />
						</figure>
						<p><Skeleton height={30} width={"100%"} animation="wave" /></p>
					</li>
				</ul>
				<ul className="notifications_list">
					<li>
						<figure style={{width:"60px"}}>
							<Skeleton variant="circular" width={45} height={45} animation="wave" />
						</figure>
						<p><Skeleton height={30} width={"100%"} animation="wave" /></p>
					</li>
				</ul>
			</div>
		</div>
	);
}


export const CallIntelligenceSkeleton = (className) => {

	return (
		<div className="warningContent_wrap">
			<div className="successIntelligenceContent_wrap">
				<div className="row h-100">
					<div className="col-md-2  intelligenceLeftContent_wrap" style={{ padding: "10px" }}>
						<Skeleton variant="circular" width={40} height={40} style={{ marginBottom: "10px" }} />
					</div>
					<div className="col-md-3  intelligenceLeftContent_wrap">
						<Skeleton height={20} width={"100%"} animation="wave" variant="rounded" style={{ marginBottom: "10px" }} />
					</div>
					<div className="col-md-6  intelligenceLeftContent_wrap">
						<Skeleton height={20} width={"100%"} animation="wave" variant="rounded" style={{ marginBottom: "10px" }} />
					</div>
				</div>
				<div className="row h-100">
					<div className="col-md-2  intelligenceLeftContent_wrap" style={{ padding: "10px" }} >
						<Skeleton variant="circular" width={40} height={40} style={{ marginBottom: "10px" }} />
					</div>
					<div className="col-md-3  intelligenceLeftContent_wrap">
						<Skeleton height={20} width={"100%"} animation="wave" variant="rounded" style={{ marginBottom: "10px" }} />
					</div>
					<div className="col-md-6  intelligenceLeftContent_wrap">
						<Skeleton height={20} width={"100%"} animation="wave" variant="rounded" style={{ marginBottom: "10px" }} />
					</div>
				</div>
				<div className="row h-100">
					<div className="col-md-2  intelligenceLeftContent_wrap" style={{ padding: "10px" }}>
						<Skeleton variant="circular" width={40} height={40} style={{ marginBottom: "10px" }} />
					</div>
					<div className="col-md-3  intelligenceLeftContent_wrap">
						<Skeleton height={20} width={"100%"} animation="wave" variant="rounded" style={{ marginBottom: "10px" }} />
					</div>
					<div className="col-md-6  intelligenceLeftContent_wrap">
						<Skeleton height={20} width={"100%"} animation="wave" variant="rounded" style={{ marginBottom: "10px" }} />
					</div>
				</div>
				<div className="row h-100">
					<div className="col-md-2  intelligenceLeftContent_wrap" style={{ padding: "10px" }}>
						<Skeleton variant="circular" width={40} height={40} style={{ marginBottom: "10px" }} />
					</div>
					<div className="col-md-3  intelligenceLeftContent_wrap">
						<Skeleton height={20} width={"100%"} animation="wave" variant="rounded" style={{ marginBottom: "10px" }} />
					</div>
					<div className="col-md-6  intelligenceLeftContent_wrap">
						<Skeleton height={20} width={"100%"} animation="wave" variant="rounded" style={{ marginBottom: "10px" }} />
					</div>
				</div>
				<div className="row h-100">
					<div className="col-md-2  intelligenceLeftContent_wrap" style={{ padding: "10px" }}>
						<Skeleton variant="circular" width={40} height={40} style={{ marginBottom: "10px" }} />
					</div>
					<div className="col-md-3  intelligenceLeftContent_wrap">
						<Skeleton height={20} width={"100%"} animation="wave" variant="rounded" style={{ marginBottom: "10px" }} />
					</div>
					<div className="col-md-6  intelligenceLeftContent_wrap">
						<Skeleton height={20} width={"100%"} animation="wave" variant="rounded" style={{ marginBottom: "10px" }} />
					</div>
				</div>
			</div>
		</div>
	)
}

export const SkeletonCardHeatMap = () => {
	return (
		<div className="row h-100" style={{ width: "100%", padding: "30px" }}>
			<div className="col-md-9">
				<Skeleton height={50} width={"100%"} animation="wave" variant="rounded" style={{ marginBottom: "30px" }} />
				<Skeleton height={350} width={"100%"} animation="wave" variant="rounded" />
			</div>
			<div className="col-md-3">
				<Skeleton height={80} width={"100%"} animation="wave" variant="rounded" style={{ marginBottom: "30px" }} />
				<Skeleton height={80} width={"100%"} animation="wave" variant="rounded" />
			</div>
		</div>
	);
}

export const ContactCrudSkeleton = (className) => {

	return (

		<div style={{ width: "100%", padding: "30px", backgroundColor: "#fff" }}>
			<Row className="table-head">
				<Skeleton height={45} width={"100%"} animation="wave" variant="rounded" style={{ marginBottom: "20px" }} />
				<Skeleton height={45} width={"100%"} animation="wave" variant="rounded" style={{ marginBottom: "20px" }} />
				<Skeleton height={45} width={"100%"} animation="wave" variant="rounded" style={{ marginBottom: "20px" }} />
				<Skeleton height={45} width={"100%"} animation="wave" variant="rounded" style={{ marginBottom: "20px" }} />
				<Skeleton height={45} width={"100%"} animation="wave" variant="rounded" style={{ marginBottom: "20px" }} />
				<Skeleton height={45} width={"100%"} animation="wave" variant="rounded" style={{ marginBottom: "20px" }} />
				<Skeleton height={45} width={"100%"} animation="wave" variant="rounded" style={{ marginBottom: "20px" }} />
			</Row>
		</div>

	)
}