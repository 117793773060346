import { skAccountListingActions } from "../constants/actionType";

const initialState = {
	finacialModelTimeInterval: [
		{ label: 'All', value: 'ALL' },
		{ label: 'Last 3 months', value: 'LAST_3_MONTHS' },
		{ label: 'Last 6 months', value: 'LAST_6_MONTHS' },
		{ label: 'Last 9 months', value: 'LAST_9_MONTHS' },
		{ label: 'Last 12 months', value: 'LAST_12_MONTHS' },
	],
	exapndedIds:[],
	displayExpandedView:false
}


export const skAccountListingActionReducer = (state = initialState, { type, payload }) => {
	switch (type) {
		case skAccountListingActions.SET_SMART_ACCOUNT_LISTING_STATES: { return { ...state, ...payload } }
		default: return state;
	}
};

export default skAccountListingActionReducer