import { skConfiguration } from "../constants/actionType";


const initialState = {
	categories : [],
	deletedFunction : [],
	deletedTaskCategory:[],
	deletionTimer:[],
	isLoadingCategory: false
}



export const skConfigurationActionReducer = (state = initialState, { type , payload }= {}) => {
	switch (type) {

		case skConfiguration.UPDATE_DELETED_CATEGORY_LIST: {
			let deletedTaskCategory;
			if(payload?.action === "Add"){
				deletedTaskCategory = [...state.deletedTaskCategory ,payload?.category]
			}
			if(payload?.action === "Remove"){
				deletedTaskCategory = state.deletedTaskCategory.filter(x =>x?.id !== payload?.category?.id);
			}
			return {
				...state, 
				deletedTaskCategory
			}
		}
		case skConfiguration.UPDATE_DELETED_FUNCTION_LIST: {
			let deletedFunction;
			if(payload?.action === "Add"){
				deletedFunction = [...state.deletedFunction ,payload?.function]
			}
			if(payload?.action === "Remove"){
				deletedFunction = state.deletedFunction.filter(x =>x?.id !== payload?.function?.id);
			}
			return {
				...state, 
				deletedFunction
			}
		}
    case skConfiguration.SET_CONFIGURATION_DATA:
		case skConfiguration.GET_CONFIGURATION_DATA:
		case skConfiguration.SET_IS_ERROR: {return { ...state, ...payload }}
    default: return state;
  }
}

export default skConfigurationActionReducer

