import React from 'react';
import BlockUi from 'react-block-ui';
import { Modal, ModalBody, ModalFooter } from 'react-bootstrap';
import ModalHeader from "react-bootstrap/ModalHeader";
import HWPopMsg from '../../HWLibrary/HWPopMsg';
import UiLabel from '../../SkSmartUtil/UiLabel/UiLabel';
import closeImage from '../../assets/images/close.svg';
import SKSmartUiBtns from '../SKSmartUiBtns/SKSmartUiBtns';
import './SmartModal.css';
import SkLoaderComponent from '../SkLoader/SkLoaderComponent';

const SmartModal = ({disabledPrimaryBtnLabel, disabledSecondaryBtnLabel,heading, leftBtnLabel, closeErrPopup, isSkAutoPopError, isSkPopsuccessMsg, isSkPopError, leftBtnAction, rightBtnLabel, rightBtnAction, close, styleHeader = null, isEdit, disableModalFooter, rightBtnOnEdit, infoIconMsg, showSearchBar, ...props }) => {

	const modalActionBtns = {
		primaryBtnLabel: rightBtnLabel,
		primaryBtnAction: rightBtnAction,
		secondaryBtnLabel: leftBtnLabel,
		secondaryBtnAction: leftBtnAction,
		disabledSecondaryBtnLabel:disabledSecondaryBtnLabel,
		disabledPrimaryBtnLabel:disabledPrimaryBtnLabel,
	}

	return (
			<Modal aria-labelledby={props?.ariaLabelledby} centered="true" modalClass={props?.modalClass} show backdropClassName="smart-modal-backdrop" dialogClassName={`disable-modal-content smart-modal-popup ${props.className}`}>
				<div className={`smart_modal_container ${props.containerClassName}`}>
					{(heading || close) && <ModalHeader className="smart-modal-header">
							<div className="smart-modal-header-lable">{heading && <h6 className="modal-title" style={styleHeader?styleHeader:{}}>{heading}</h6>}
							{infoIconMsg && <span className='smart_modal_info_icon'>
								<UiLabel tooltipClass='smart_modal_info_icon-message' showToolTipWithHtml={true} length={0} placement="top" label={infoIconMsg}>
									<div className='theme__based__info__icon'/>
								</UiLabel>
		          </span>}</div>
							{close && <img className="close_btn_pop" style={{ cursor: 'pointer' }} src={closeImage} data-dismiss="modal" alt="" onClick={() => close()} />}
					</ModalHeader>}
					{/* <BlockUi blocking={props.stateValue && props.stateValue.isLoading1} className='smart__modal__blockui'> */}
					<SkLoaderComponent  isLoading={(props.stateValue && props.stateValue.isLoading1) || props?.isLoading}/>
							<ModalBody className={`smart-modal ${props.modalClass}`}>
							{(isSkPopError || isSkAutoPopError || isSkPopsuccessMsg) && <div className='HWPopMsgWrape'><HWPopMsg className={`${isSkPopsuccessMsg ? 'successMsg' : 'alertMsg'} addUserPopup`} autoClose={(isSkAutoPopError || isSkPopsuccessMsg) ? true : false} closeTime={1500} close={() => closeErrPopup()} hide={() => closeErrPopup()} message={isSkAutoPopError || isSkPopsuccessMsg || isSkPopError} /></div>}
									
									<div className="sk-pop-wrapper">
												{props.children}
									</div> 	
							</ModalBody>		
				{/* </BlockUi> */}
				{!disableModalFooter &&
					<ModalFooter className='smart-modal-footer'>
					<div className='smart__modal-footer'>
					  <SKSmartUiBtns  {...modalActionBtns} className={'smart__ui__btns'} />
				  </div>
					</ModalFooter>}

					</div>
			</Modal>
	)
}




export default SmartModal;