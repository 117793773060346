import React from 'react'
export const customStyles = {
    container: (provided, state) => ({
      ...provided,
      width: "150px",
      minHeight: "30px",
      outlineWidth: "0px",
      fontSize: "13px",
      fontWeight: "400",
      fontFamily: "Urbanist, sans-serif !important",
      cursor: "pointer",
      marginLeft: "0px",
      lineHeight: "17px",
      color: "#000000",
    }),
    indicatorsContainer: (provided, state) => ({ ...provided, height: "30px" }),
    indicatorSeparator: (provided, state) => ({ ...provided, display: "none" }),
    MenuPortal : (provided, state) => ({...provided, zIndex:"9999"}),
    control: (provided, state) => {
      return {
        ...provided,
        borderRadius: "0px !important",
        minHeight: "30px",
        boxShadow: "none",
        cursor: "pointer",
        borderWidth: "0px",
        border: "1px solid var(--sk-border-color)",
        boxShadow: state.isFocused ? "1px solid #CCCCCC" : "1px solid #CCCCCC",
        "&:hover": {
          border: state.isFocused ? "1px solid var(--sk-border-color)" : "1px solid var(--sk-border-color)",
        },
        color: "#000000",
      };
    },
    dropdownIndicator: (provided) => ({
      ...provided,
      "svg": {
        fill: "var(--sk-share-and-konversations-label-color)"
      }
    }),
    placeholder: (provided, state) => ({
      ...provided,
      marginBottom: "0px",
      color: "var(--sk-share-and-konversations-label-color)",
      fontSize: "12px",
      marginTop: "-1px",
      fontWeight: "300",
      cursor: "pointer",
    }),
    menuList: (provided, state) => ({
      ...provided,
      overflowX: "hidden",
      paddingBottom: "0px",
      paddingTop: "0px",
      maxHeight: "150px",
    }),
    menu: (provided, state) => ({
      ...provided,
      width: "100%",
      boxShadow: "2px 1px 10px rgba(0, 0, 0, 0.2)",
      borderRadius: "0px",
      zIndex: 99999,
      marginTop: "0px",
      marginBottom: "1px",
      border: "1px solid var(--sk-border-color)",
      borderTop: "0 none",
    }),
    multiValueRemove: (provided, state) => ({ ...provided, display: "none" }),
    multiValueLabel: (provided, state) => ({
      ...provided,
      backgroundColor: "white",
    }),
    singleValue: (provided, state) => ({
      ...provided,
      whiteSpace: "nowrap",
      overflow: "hidden",
      width: "auto",
      textOverflow: "ellipsis",
      fontSize: "12px",
      fontWeight: "400",
      color: "var(--sk-share-and-konversations-label-color)",
    }),
    option: (styles, { data, isDisabled, isFocused, isSelected }) => {
      const color = "var(--sk-menu-bg-color)";
      return {
        ...styles,
        backgroundColor: isDisabled ? null : isFocused ? color :null,
        ":active": { ...styles[":active"], backgroundColor: color },
        textAlign: "left",
        padding: "6px",
        color: "var(--sk-share-and-konversations-label-color)",
        fontSize: "12px",
        fontWeight: "400",
        height: "auto",
        overflow: "hidden",
        cursor:'pointer'
      };
    },
    valueContainer: (provided, state) => ({
      ...provided,
      whiteSpace: "nowrap",
      overflow: "hidden",
      width: "auto",
      textOverflow: "ellipsis",
    }),
  
    OptionContainer: (provided, state) => ({ ...provided, width: "112px" }),
    menu: (provided, state) => ({
      ...provided,
      width: "100%",
      boxShadow: "2px 1px 10px rgba(0, 0, 0, 0.2)",
      borderRadius: "0px",
      zIndex: 99999,
      marginTop: "0px",
      marginBottom: "1px",
      border: "1px solid #e5e5e8",
      borderTop: "0 none",
    }),
    IndicatorsContainer: (provided, state) => ({ ...provided, display: "none" }),
  };

  export const singleSelect = customStyles && {
    ...customStyles,
    placeholder: (provided, state) => ({
      ...provided,
      marginBottom: "0px",
      color: "#000",
      fontSize: "13px",
      marginTop: "-1px",
      fontWeight: "500",
      cursor: "pointer",
    }),
    placeholder: (provided, state) => ({
      ...provided,
      marginBottom: "0px",
      color: "#000",
      fontSize: "12px",
      marginTop: "-1px",
      fontWeight: "400",
      cursor: "pointer",
    }),
    singleValue: (provided, state) => ({
      ...provided,
      whiteSpace: "nowrap",
      overflow: "hidden",
      width: "auto",
      textOverflow: "ellipsis",
      fontSize: "12px",
      fontWeight: "400",
      color: "#000",
    }),
  
  }

  export const emailIcon = () => <svg id="Group_10947" data-name="Group 10947" xmlns="http://www.w3.org/2000/svg" width={16} height={16} viewBox="0 0 16 16.001">
  <g id="Group_10946" data-name="Group 10946" transform="translate(0 0)">
      <path id="Path_9743" data-name="Path 9743" d="M15.816,5.6l-2.295-1.68V2.5a.5.5,0,0,0-.5-.5h-2.11L8.316.1a.5.5,0,0,0-.59,0L5.131,2H3.021a.5.5,0,0,0-.5.5V3.917L.226,5.6A.5.5,0,0,0,.021,6v8.5a1.5,1.5,0,0,0,1.5,1.5h13a1.5,1.5,0,0,0,1.5-1.5V6A.5.5,0,0,0,15.816,5.6Zm-2.295-.68L15.148,6,13.521,7.015ZM8.021.854,9.8,2H6.3ZM3.165,2.7h9.657V7.419l-4.8,2.773L3.165,7.419ZM2.521,4.916v2.1L.907,6ZM.751,14.624V6.887l4.932,2.78Zm.415.581L6.29,10.119l1.461.808a.5.5,0,0,0,.5,0l1.45-.808L14.867,15.2Zm14.109-.581L10.332,9.667l4.943-2.78Z" transform="translate(-0.021 0)" />
      <rect id="Rectangle_4371" data-name="Rectangle 4371" width="6.833" height="0.8" transform="translate(4.583 4.451)" />
      <rect id="Rectangle_4372" data-name="Rectangle 4372" width="4.555" height="0.8" transform="translate(5.722 6.429)" />
  </g>
</svg>

export const phoneIcon = () => <svg xmlns="http://www.w3.org/2000/svg" width={16} height={16} viewBox="0 0 15.676 15.704">
<path id="Icon_feather-phone-call" data-name="Icon feather-phone-call" d="M12.29,4.32a3.525,3.525,0,0,1,2.785,2.785M12.29,1.5A6.346,6.346,0,0,1,17.9,7.1m-.705,5.626V14.84a1.41,1.41,0,0,1-1.537,1.41,13.953,13.953,0,0,1-6.085-2.165,13.749,13.749,0,0,1-4.23-4.23A13.953,13.953,0,0,1,3.174,3.742a1.41,1.41,0,0,1,1.4-1.537H6.692A1.41,1.41,0,0,1,8.1,3.418,9.053,9.053,0,0,0,8.6,5.4a1.41,1.41,0,0,1-.317,1.488l-.9.9a11.281,11.281,0,0,0,4.23,4.23l.9-.9A1.41,1.41,0,0,1,14,10.8a9.053,9.053,0,0,0,1.981.494A1.41,1.41,0,0,1,17.19,12.725Z" transform="translate(-2.717 -1.003)" fill="none" stroke="#000" strokeLinecap="round" strokeLinejoin="round" strokeWidth="0.9" />
</svg>


export const getFilterObj = (key,value) => {

  let filterObj = {
    "userId": "0a25779d-8be6-475c-941d-4183c97e9e84",
    "orgId": "b2645fb8-8050-4486-b1da-d5257d64c2f9",
    "customerSuccessPerspective": {
        "perspectiveFilter": "ORG_ACCOUNTS",
        "accountIds": [
            "ALL_ACCOUNTS"
        ],
        "productIds": [
            "ALL_PRODUCTS"
        ],
        "csmUserId": null,
        "businessUnit": null,
        "accountSegmentId": null
    },
    "otherFilters": {
        "subscription": [],
        "size": [],
        "phase": [],
        "segment0": [],
        "segment1": [],
        "segment2": [],
        "segment4": [],
        "healthScore": [
            "Moderate"
        ],
        "renewals": []
    },
    "accountManagementFilters": {
        "accountStatus": [],
        "accountVisibility": []
    },
    "customerAccountList": {
        "churnedReason": [],
        "accountListingFlag": false
    }
}
  
  Object.keys(filterObj).map(pKey=>{
  if(pKey==='otherFilters'){
    Object.keys(pKey).map(cKey=>{
      if(pKey==='key'){
        filterObj[pKey][cKey] = [value]
      }
    })
  }
})
  return filterObj;
}

export const styleMultiple = {
  control: (base, state) => (
      {
          ...base,
          backgroundColor: state.isDisabled ? "#e5e5e8" : "#FFFFFF",
          padding: "2px 5px 2px 5px",
          borderColor: state.isFocused ? 'var(--sk-border-color)' : state.isSelected ? 'var(--sk-border-color)' : 'var(--sk-border-color)',
          boxShadow: 'none', "&:hover": {
              borderColor: state.isFocused ? '#e5e5e8' : '#e5e5e8',
          },
          minHeight: "33px",
          borderRadius: "0px !important",
          width:"150px"
      }),

      multiSelectSignleValue:(provided, state)=>({
        ...provided,
        color:"var(--sk-share-and-konversations-label-color)"
      }),
  container: (provided, state) => ({
      ...provided,
      border: 'none',
      borderRadius: "5px",
      // border: '1.5px solid #EBEBEE',
      width: '100%',
      fontSize: '12px',
      // height: 30,
      minHeight: '30px',
      marginLeft: '-1px',
     

  }),
  indicatorSeparator: (provided, state) => ({
      ...provided,
      width: "0px",
  }),
  singleValue: (provided, state) => ({
      ...provided,
      // width: "68%",
      backgroundColor: '#fad192',
      lineHeight: '31px'
  }),
  multiValue: (provided, state) => {
      return ({
          ...provided,
          backgroundColor: "#fad192",
          borderRadius: '4px',
          lineHeight: '18px',
          padding: '2px 4px',
          alignItems: 'center'
      })
  },
  indicatorsContainer: (provided, state) => ({
      ...provided,
      borderRadius: "0px",
  }),
  multiValueLabel: (provided, state) => ({
      ...provided,
      fontSize: '12px',
      fontFamily: "Urbanist",
      color: 'var(--sk-share-and-konversations-label-color)',
      fontWeight: '400',
      whiteSpace: "normal",
      textAlign: 'left',
      padding: '2px'
  }),
  multiValueRemove: (base, state) => {
      return ({
          ...base,
          opacity: 1,
          width: "20px",
          color: "var(--sk-share-and-konversations-label-color) !important",
          backgroundColor: "#fad192",
          borderRadius: 0,
          "&:hover": {
              backgroundColor: "#fad192",
              color: "var(--sk-share-and-konversations-label-color)"
          },
      })
  },
  placeholder: (provided, state) => ({
      ...provided,
      color: 'var(--sk-share-and-konversations-label-color)',
      width: "100%",
  }),
  valueContainer: (provided, state) => ({
      ...provided,
      position: "none",
      maxWidth: "90%",
      cursor: 'pointer',
      borderRadius: "5px",
      overflowY: 'auto !important',
      maxHeight: '116px'

  }),
  option: (styles, { isDisabled, isFocused, isSelected }) => {
      return { ...styles, backgroundColor: isDisabled ? null : isSelected ? "var(--sk-menu-bg-color)" : null, ':hover': { ...styles[':active'], backgroundColor: "var(--sk-menu-bg-color)" }, cursor: isDisabled ? 'not-allowed' : 'default', color: 'var(--sk-share-and-konversations-label-color)' };
  },
  menu: (provided, state) => ({ ...provided, width: "99.6%", borderRadius: '0px', marginTop: '0px', }),
  menuList: (provided, state) => ({ ...provided, overflowX: "hidden", paddingBottom: '0px', borderRadius: '0px', paddingTop: '0px', textAlign: "left", maxHeight: '145px' })
};
//to remove tab-index of Modal component
export const removeTabIndexFromModal = () => {
  const modalList = window.document.getElementsByClassName("modal-dialog");
  if (modalList && modalList.length > 0 && modalList[0].parentElement && modalList[0].parentElement.attributes && modalList[0].parentElement.attributes.removeNamedItem) {
    try {
      modalList[0].parentElement.attributes.removeNamedItem("tabindex")
    } catch {}
  };
}