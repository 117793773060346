import { skSettings } from "../constants/actionType";


const initialState = {
	roles : [],
	isLoadingRoles: false,
	rolesPermission: [],
	isLoadingUsers: false,
	users:[],
	deletedUser:[],
	deletionTimer:[],
	deletedRole:[],
	roleReferenceData: [],
	externalUsers: []
}



export const skSettingReducer = (state = initialState, { type , payload }= {}) => {
	switch (type) {
		case skSettings.UPDATE_DELETED_USER:{
			let deletedUser;
			if(payload?.action === "Add"){
				deletedUser = [...state.deletedUser ,payload?.user]
			}
			if(payload?.action === "Remove"){
				deletedUser = state.deletedUser.filter(x =>x?.id !== payload?.user?.id);
			}
			return {
				...state, 
				deletedUser
			}
		}
		case skSettings.UPDATE_DELETED_ROLE:{
			let deletedRole;			
			if(payload?.action === "Add"){
				deletedRole = [...state.deletedRole ,payload?.role]
			}
			if(payload?.action === "Remove"){
				deletedRole = state.deletedRole.filter(x =>x?.id !== payload?.role?.id);
			}
			return {
				...state, 
				deletedRole
			}
		}
    case skSettings.GET_SETTING_DATA:
		case skSettings.SET_SETTING_DATA:
		case skSettings.SET_IS_ERROR: {return { ...state, ...payload }}
    default: return state;
  }
}

export default skSettingReducer