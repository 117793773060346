import axios from 'axios';
import HWUser from '../../../../HWLibrary/HWUser';
import HWLocalStorage from '../../../../HWLibrary/HWLocalStorage';
import hwAxios from '../../../../HWLibrary/HWAxios';
import cache from '../../../../SkSmartUtil/InMemoryCache';
class ContactServices {
    constructor() {
        if (!ContactServices.instance) { ContactServices.instance = this }
        return ContactServices.instance
    }
    fetchContactConfig() {
        let url = window.resolveRegion(process.env.REACT_APP_SMART_DASHBOARDS_BASE_API_URL) + "/contact"
        let aConfig = { headers: { "Authorization": HWUser.accessToken(), "Content-Type": "application/json" } };
        aConfig.params = { appId: HWUser.appId(), orgId: HWUser.orgId() }
        return axios.get(url, aConfig)
    }
    fetchContactExport(accountId) {
        let url = window.resolveRegion(process.env.REACT_APP_SMART_DASHBOARDS_BASE_API_URL) + "/contact/export"
        let aConfig = { headers: { "Authorization": HWUser.accessToken(),"addFilter": true, "Content-Type": "application/json" } };
        aConfig.params = { appId: HWUser.appId(), orgId: HWUser.orgId(), accountId: accountId ,userId: HWUser.userId()}
        return hwAxios.get(url, aConfig)
    }
    editContactConfig = (isThirdPartyEnable) => {
        let data = { orgId: HWUser.orgId(), isThirdPartyEnable: isThirdPartyEnable, appId: HWUser.appId() }
        let aConfig = { headers: { "Authorization": HWUser.accessToken(), "Content-Type": "application/json" } };
        let url = window.resolveRegion(process.env.REACT_APP_SMART_DASHBOARDS_BASE_API_URL) + "/contact"
        return axios.put(url, data, aConfig)
    }
    editContactConfigTouchpoint = (isThirdPartyTouchPointsEnabled) => {
        let data = { orgId: HWUser.orgId(), isThirdPartyTouchPointsEnabled: isThirdPartyTouchPointsEnabled, appId: HWUser.appId() }
        let aConfig = { headers: { "Authorization": HWUser.accessToken(), "Content-Type": "application/json" } };
        let url = window.resolveRegion(process.env.REACT_APP_SMART_DASHBOARDS_BASE_API_URL) + "/contact"
        return axios.put(url, data, aConfig)
    }
    fetchContactAndAccountDetails(cacheExists, onResponse, onError) {
        let contactMappingListStorageKey = "sk-contact-mapping-list-";
        if (!cacheExists) {
            const cachedData = HWLocalStorage.load(contactMappingListStorageKey);
            if (cachedData) { onResponse && onResponse(cachedData); }
        }
        let aConfig = { headers: { "Authorization": HWUser.accessToken(), "Content-Type": "application/json" } };
        aConfig.params = { appId: HWUser.appId(), userId: HWUser.userId(), accountFlag: true }
        // let url     = window.resolveRegion(process.env.REACT_APP_PLATFORM_BASE_API_URL_WEB_ADMIN) + "/multilevel/customer/list";
        let url = window.resolveRegion(process.env.REACT_APP_DASHBOARDS_BASE_API_URL) + "/contacts"
        axios.get(url, aConfig).then(response => {
            let contactMappingList = (response && response.data && response.data) || [];
            onResponse(contactMappingList);
            HWLocalStorage.save(contactMappingList, contactMappingListStorageKey);
        }).catch(error => { onError && onError(error); });
    }

    mapUnMapContacts = (data) => {
        let aConfig = { headers: { "Authorization": HWUser.accessToken(), "Content-Type": "application/json" } };
        let url = window.resolveRegion(process.env.REACT_APP_DASHBOARDS_BASE_API_URL) + "/contacts"
        return axios.put(url, data, aConfig)
    }

    saveContactAccountMapping = (mappingData) => {
        let data = { userId: HWUser.userId(), appId: HWUser.appId(), orgId: HWUser.orgId(), mappings: mappingData }
        let aConfig = { headers: { "Authorization": HWUser.accessToken(), "Content-Type": "application/json" } };
        let url = window.resolveRegion(process.env.REACT_APP_DASHBOARDS_BASE_API_URL) + "/contacts/map";
        return axios.post(url, data, aConfig);
    }
    saveAccountMapping = (accountId, ticketId) => {
        let data = { userId: HWUser.userId(), appId: HWUser.appId(), id: ticketId, accountId: accountId }
        let aConfig = { headers: { "Authorization": HWUser.accessToken(), "Content-Type": "application/json" } };
        let url = window.resolveRegion(process.env.REACT_APP_DASHBOARDS_BASE_API_URL) + "/ticket-dashboard/tickets/map-account";
        return axios.post(url, data, aConfig);
    }

    referenceList = () => {
        const cacheKey = HWUser.userId();
        const fetchFunction = () => {
            let url = window.resolveRegion(process.env.REACT_APP_SMART_DASHBOARDS_BASE_API_URL) + "/contact/reference-data";
            let aConfig = { headers: { "Authorization": HWUser.accessToken(), "Content-Type": "application/json" } };
            aConfig.params = { appId: HWUser.appId(), orgId: HWUser.orgId()}
            return axios.get(url, aConfig);
        }
        return cache.get(cacheKey, cache.CacheModule.champions, fetchFunction);
    }
}

var constactServices = new ContactServices();
export default constactServices;