import { skMailNotificationActions } from "../constants/actionType";

const initialState = {
	userId: "",
	appId: "",
	isLoading: false,
	showSkeleton: false,
	msgToShow: "",
	features: [],
};


export const skEmailNotificationReducer = (
	state = initialState,
	{ type, payload } = {}
) => {
	switch (type) {
		case skMailNotificationActions.FETCH_FEATURES: {
			let isLoading = payload?.isLoading !== undefined && payload?.isLoading != null
				? payload.isLoading
				: state.isLoading;
			return { ...state, isLoading: isLoading, showSkeleton: payload?.showSkeleton, features: payload?.features }
		}

		case skMailNotificationActions.UPDATE_FEATURES: {
			return { ...state, msgToShow: payload?.successMsg }
		}

		case skMailNotificationActions.SET_IS_LOADING:
			return state;

		case skMailNotificationActions.SET_IS_ERROR:
			{
				return { ...state, msgToShow: payload?.errorMessage }
			}


		default:
			return state;

	}
};

export default skEmailNotificationReducer 