import React, {useState, useRef, useEffect, useCallback} from 'react';
import { Overlay ,Tooltip } from 'react-bootstrap';
import './UiLabel.css';
import { Tooltip as CustomeTooltip, makeStyles } from '@material-ui/core';

const AutoUiLabel = (props) => {
  const [show, setShow] = useState(false);
	const [showToolip, setshowToolip] = useState(false);
	const[conatiner,setConatiner] = useState(false);

	useEffect(() => {
		const handleScroll = () => {
			setShow(false)
		}
		let elemnt = document.querySelector(".datagrid__table__container .MuiDataGrid-virtualScroller");
		let skDashBoardContainer = document.querySelector(".sk-smart-dashboard-container-content");
		if (skDashBoardContainer) { skDashBoardContainer.addEventListener('scroll', handleScroll) }
		if (elemnt) {  elemnt.addEventListener('scroll', handleScroll)}
		window.addEventListener('scroll', handleScroll)
		if(document.querySelector(".sk-smart-backgroung-light-bg-height")){
			setConatiner(document.querySelector(".sk-smart-backgroung-light-bg-height"))
		}
		if(document.querySelector(".smart__setting_table-table_container")) {
			setConatiner(document.querySelector(".smart__setting_table-table_container"));
		}
		return () => {
			if (skDashBoardContainer) { skDashBoardContainer.removeEventListener('scroll', handleScroll) }
			if (elemnt) { elemnt.removeEventListener('scroll', handleScroll) }
			window.removeEventListener('scroll', handleScroll)
		}
	
	}, [showToolip])

  const descriptionCell = {
    whiteSpace: "nowrap",
    width: props.width?props.width:"90%",
    overflow: "hidden",
    textOverflow: "ellipsis",
    display:props.display?props.display:"block",
    pointerEvents: "auto",
		maxWidth:props?.maxWidth,
		...props?.style
  }
  const refElement =  useRef();
	let { label, className, placement, top, children, tooltipClass, clicked, showTip, list, index, hideLable, showToolTipWithHtml, showInAnchor } = props
    label = label?.trim();
    let listLabels = list ? list:'';
    let toolTipText = listLabels ? listLabels : label

    const updateSize = useCallback(()=>{
      if (refElement?.current?.scrollWidth > refElement?.current?.clientWidth) {
        setshowToolip(true);
      } else if (showToolip) {
        setshowToolip(false);
      }
		},[showToolip])
		
    useEffect(()=>{
      window.addEventListener('resize', updateSize);
      updateSize();
      return () => window.removeEventListener('resize', updateSize);
    },[props.label, showToolip, updateSize])

		const useStyles = makeStyles((theme) => ({
			customTooltip: {
				// backgroundColor: 'white !important',
				// color: 'var(--smart-insights-and-konversations-intelligence-heading-color)',
				color:props.theme === "white"?"var(--smart-insights-and-konversations-intelligence-heading-color)":"var(--primary-color)",
				backgroundColor:props.theme === "white"?'white !important':'var(--sk-heading-color)  !important',
				fontSize: '13px',
				fontFamily: 'var(--sk-font-urbanist-family)',
				boxShadow: '0px 9px 30px var(--sk-graph-popper)',
				borderRadius: '11px',
				padding: '8px !important',
				minWidth: '50px !important',
				border: '1px solid var(--sk-heading-color)',
				fontWeight: '500',
				top: '12px',
				left: '8px',
				'& .MuiTooltip-arrow': {
					color: props.theme === "white"?'white !important':'var(--sk-heading-color)!important'
				},
				'& .MuiTooltip-arrow:before': {
					border: props.theme === "white"?'1px solid var(--sk-smart-datepicker-border-color)':'1px solid var(--sk-heading-color)',
				}
			},
		}));

		const classes = useStyles();

    return(<>
     { 
		 props.showOnlyTooltip?
		 		<>
						<CustomeTooltip open={show}  innerRef={refElement} classes={{ tooltip: classes.customTooltip }} className='add__column__info__react__tooltip' title={label} arrow placement="top">
						<span key={"s"+index} className={className} ref={refElement} style={{ textTransform: (props.callingFrom === "accountMangement") ? "uppercase" : "none",...descriptionCell,/* marginLeft:"2px" */}}
						onClick={(e) => { setShow(false); clicked && clicked(e) }}  onMouseOver={(e) => { 
							if(props.preventDefaultActions){
							 e.stopPropagation();e.preventDefault();
							}
							props.onMouseOverTitle && props.onMouseOverTitle()
							showToolip && setShow(true)}} 
							onMouseLeave={(e) => {
								if(props.preventDefaultActions){
								 e.stopPropagation();e.preventDefault();
								 props.onMouseLevTitle && props.onMouseLevTitle()
							 }
								setShow(false);
							 }}
						>  
								{hideLable?"":children ? children : label}
							</span>
						</CustomeTooltip>
				</>:
		 		
				 // Please add addition style from props if it is required.
         <span key={"s"+index} className={className} ref={refElement} onClick={(e) => { setShow(false); clicked && clicked(e) }}  onMouseOver={(e) => { 
					 if(props.preventDefaultActions){
						e.stopPropagation();e.preventDefault();
					 }
					 props.onMouseOverTitle && props.onMouseOverTitle()
					 showToolip && setShow(true)}} 
					 onMouseLeave={(e) => {
						 if(props.preventDefaultActions){
							e.stopPropagation();e.preventDefault();
							props.onMouseLevTitle && props.onMouseLevTitle()
						}
						 setShow(false);
						}} 
						  style={{ textTransform: (props.callingFrom === "accountMangement") ? "uppercase" : "none",...descriptionCell,/* marginLeft:"2px" */}}>  
          {hideLable?"":children ? children : label}
          {!showTip && show && <Overlay  container={props.setContainer?refElement.current:conatiner} target={refElement.current} show={true} placement={index && index === 0 ? 'right' : placement || 'top'}>
            {(props) => (
              <Tooltip  id="UiLabelTooltip"  key={"t"+index}  className={`${tooltipClass} ${index && index === 0 ? 'firstLineLabel' : ''}`} style={{ marginTop: top }} {...props}>
								{/* {
									props?.showToolTipWithHtml ? 
									props?.showInAnchor ? 
									(<a href={toolTipText} target='_blank'><span dangerouslySetInnerHTML={{ __html: toolTipText }}></span></a>) 
									: (<span  dangerouslySetInnerHTML={{ __html: toolTipText }}></span>)
									:  (toolTipText)
								} */}

<span  /* onMouseOver={(e) => {e.stopPropagation();e.preventDefault(); setShow(props.openTooltiponHover || false)}} */  dangerouslySetInnerHTML={{ __html: toolTipText }}></span>
              </Tooltip>
            )}
          </Overlay>}
        </span>
      }
    </>
    )
}


export default AutoUiLabel;