import Axios from "axios";
class SkSmartServices {
	accessToken = null
	config = () => { return { headers: { "Authorization": "Bearer " + this.accessToken } } };
	constructor() {
		if (!SkSmartServices.instance) { SkSmartServices.instance = this }
		return SkSmartServices.instance
	}
	fetchUploadURL(bucketType) {
		let request = { type: bucketType }
		let url = window.resolveRegion(process.env.REACT_APP_DASHBOARDS_BASE_API_URL) + "/platform-setting/get-pre-signed-url";
		return Axios.post(url, request);
	}
	uploadLogoImage(imageFile, uploadURL) {
		let config = { headers: { "content-type": imageFile.type } }
		return Axios.put(uploadURL, imageFile, config);
	}


}

var skSmartServices = new SkSmartServices();
export default skSmartServices;