import React from 'react';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import { styled } from '@mui/material/styles';

const SkTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }}HeatMapGraphContainer/>
))


(() => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: "#000043 !important",
		color:"#FFFFFF",
    marginTop : "-22px !important",
    top: "7px ",
		height:"33px !important",
		opacity: "0.65 !important",
    position: "absolute",
		display : "flex",
		alignItems:"center !important",
    top: "20px",
    position: "absolute",
		marginLeft: "30px !important"
  }
}));

export default SkTooltip;