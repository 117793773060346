import { ClickAwayListener, Popper } from '@material-ui/core';
import React, { useState, useEffect } from 'react';
import Picker from 'emoji-picker-react';
import './SkSmartEmojiAndReactions.css';

const SkSmartEmojiPicker = ({ handleClickAway, onChange, skinTonesDisabled, searchDisabled, autoFocusSearch, searchPlaceholder, lazyLoadEmojis, emojiStyle, suggestedEmojisMode, className, id, ...props }) => {

	const [anchorElTarget, setAnchorElTarget] = useState(props.anchorElTarget);

	useEffect(() => {
		setAnchorElTarget(props.anchorElTarget)
	}, [props.anchorElTarget])

	const onEmojiClick = (event, emojiData) => {
		onChange(event?.emoji)
	}
	
	return (
		<ClickAwayListener onClickAway={(e) => {
			e?.stopPropagation();
			handleClickAway(e)
		}
		}>
     	<Popper id={props?.id}
					className={props.className + " " + "emoji__dropDown"}
					open={true} anchorEl={anchorElTarget}
					placement='top'
					onClose={() => { 
						handleClickAway();
					}}
					PaperProps={{style:{"color":"#000"}}}
				>
			<div className='smart__emoji__picker__wrap' ref={anchorElTarget}>
				<div className='smart__emoji__picker__wrap-inside' >
					<Picker
						
						onEmojiClick={onEmojiClick}
						height={350}
            width="100%"
						skinTonesDisabled={skinTonesDisabled || true}
						searchDisabled={searchDisabled || false}
						autoFocusSearch={autoFocusSearch || true}
						searchPlaceholder={searchPlaceholder || 'Search...'}
						lazyLoadEmojis={lazyLoadEmojis || true}
						emojiStyle={emojiStyle ? emojiStyle : 'google'}
						suggestedEmojisMode={suggestedEmojisMode ? suggestedEmojisMode : 'recent'}
					/>
				</div>
			</div>
			</Popper>
		</ClickAwayListener>
	)
}

export default SkSmartEmojiPicker