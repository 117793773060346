import { Popover } from "@material-ui/core";
import { cloneDeep, isArray, values } from "lodash";
import React, { useCallback, useEffect, useMemo, useRef, useState, memo } from "react";
import hwAxios from "../HWLibrary/HWAxios";
import HWUser from "../HWLibrary/HWUser";
import SKSmartUiBtns from "../SkSmartUtil/SKSmartUiBtns/SKSmartUiBtns";
import SkRadioBtn from "../SkSmartUtil/SkRadioBtn/SkRadioBtn";
import SmartActions from "../SkSmartUtil/SmartActions/SmartActions";
import SmartDatePicker from "../SkSmartUtil/SmartDatePicker/SmartDatePicker";
import cachedAPI from "../SkSmartUtil/CachedAPI";
import AutoUiLabel from "../SkSmartUtil/UiLabel/AutoUiLabel";
import UiLabel from "../SkSmartUtil/UiLabel/UiLabel";
import "./SmartFilterTab.css";
import smartFilterTokenService from "./SmartFilterToken";
import SmartShare from "./SmartShare";
import 'react-multi-carousel/lib/styles.css';
import { useDispatch, useSelector } from "react-redux";
import { getAccountListStates, getGlobalStates, setGlobalStates } from "../store/reducers/skGlobalReducer";
import SkSmartInputButton from "../SkSmartUtil/SkSmartInputButton/SkSmartInputButton";
import SkSmartMultiSelectDropDown from "../SkSmartUtil/SkSmartMultiSelectDropDown/SkSmartMultiSelectDropDown";
import { fetchAccountSegmentsList, getSmartPortfolioStates } from "../store/reducers/skAccountListingReducer";
import access from '../HWLibrary/AccessControl';
import SkLoaderComponent from '../SkSmartUtil/SkLoader/SkLoaderComponent';
import { Link } from "react-router-dom";


const dropdownPaperStyle = {
	width: '275px', background: "#FFFFFF 0% 0% no-repeat padding-box",
	boxShadow: "0px 3px 6px #00000029", borderRadius: "0px 0px 13px 13px",
	opacity: 1, marginTop: "5px", marginLeft: "12px",
	border: "1px solid var(--sk-smart-datepicker-border-color)"
}

function  SmartFilterTab({ showDateResetButn, disableUserFilterPreference, exortData, exportXL, exportPDF, selectedProductIdsFromProps, tabs, inflow, activeReportId, onTabActive, propsActions,
	disableGlobalAccountFilter, enableGlobalDateFilter, disableGlobalShare, dontSetdeafultTab, disableGlobalProductFilter, disableGlobalAttributeFilter, persistFilterValuesInDisabledState,
	activeTab, productSelectOption, desableTaskFromAddBtn, desableTouchpointFromAddBtn, desableAlertFromAddBtn, updateUserPrefrence, disableAlltabs,disableActions,hideDateResetButn,
	callingFrom, loadingData, onCreateActionClick, enableCreateReport, enableAddContact, inflowActiveTab, desableFeatureAttribute, disableAccountSegmentFilter,noButtons, showXL,dateRange, ...props }) {
	const [selectedTab, setSelectedTab] = useState(null);
	const [selectedSubTab, setSelectedSubTab] = useState(null);
	const [addingNewAttribute, setAddingNewAttribute] = useState(false);
	const [addingNewFeatureAttribute, setAddingNewFeatureAttribute] = useState(false);
	const [editingAttribute, setEditingAttribute] = useState(false);
	const [editingFeatureAttribute, setEditingFeatureAttribute] = useState(false);
	const [refreshKey, setRefreshKey] = useState(new Date().getMilliseconds() + "");
	const [productsList, setProductsList] = useState([]);
	const [userPrefrenceRequest, setuserPrefrenceRequest] = useState(null);
	const [skSmartFilterToken, setSkSmartFilterToken] = useState();
	const [disableAccountSegment,setdisableAccountSegment] = useState(false);
	const [accountList,setAccountList] = useState(null);
	// const [selectedActiveTab,setSelectedActiveTab] = useState(null)
	const dispatch = useDispatch();
	const getSmartPoprtfolioState = useSelector(getSmartPortfolioStates);
	const { accountSegments } = getSmartPoprtfolioState;

	useEffect(()=>{
		 // Set skSmartFilterToken if props are available
		 if (props.skSmartFilterToken) {
			setSkSmartFilterToken(props.skSmartFilterToken);
	}
	},[props.skSmartFilterToken])

	useEffect(() => {
   
    // Determine if account segment should be disabled
    setdisableAccountSegment(access?.skAccountSegments?.view() ? false : true);

    // Fetch account list and configure products in parallel
    const fetchData = async () => {
        try {
            const [accountListResponse, productListResponse] = await Promise.all([
                smartFilterTokenService.fetchAccountList(),
                cachedAPI.fetchConfigureProducts()
            ]);

            // Handle account list response
            const accounts = accountListResponse?.data?.data || [];
            setAccountList(accounts);
            dispatch(setGlobalStates({ filterTabAccountList: accounts }));

            // Handle product list response
            const productList = productListResponse?.data?.productList || [];
            const sortedProductList = productList.sort((a, b) => (a.name + "").localeCompare(b.name + ""));
            setProductsList(sortedProductList);
        } catch (error) {
            console.error("Error fetching data:", error);
        }
    };

    fetchData();

    // Fetch account segments if the filter is not disabled
    if (!disableAccountSegmentFilter) {
        fetchAccountSegments();
    }
}, [ dispatch, disableAccountSegmentFilter, access?.skAccountSegments]);

useEffect(() => {
    // Update selected tab and subtab based on activeTab
    if(activeTab){
			let parentTab = null;let subTab= null;
			if(tabs.some(i => i.tabs && i.tabs.some(p => p.id === activeTab))){
				parentTab = tabs.filter(i => i.tabs && i.tabs.some(p => p.id === activeTab))[0];
				subTab = parentTab.tabs && parentTab.tabs.filter(i => i.id === activeTab)[0];
			}else if (tabs.some(i =>  i.id === activeTab)){
				parentTab = tabs.filter(i =>  i.id === activeTab)[0];
			}
			setSelectedTab(parentTab);
			setSelectedSubTab(subTab);
		}else{
			setSelectedTab(tabs.filter(item => item.isEnable)[0])
		}
}, []);

useEffect(() => {
	if (inflowActiveTab != null && inflowActiveTab.id != null && tabs != null && (tabs.some(i => i.tabs && i.tabs.some(p => p.id === inflowActiveTab.id)) || tabs.some(i =>  i.id === inflowActiveTab.id) )) {
		let parentTab = null;let subTab= null;
		if(tabs.some(i => i.tabs && i.tabs.some(p => p.id === inflowActiveTab.id))){
			parentTab = tabs.filter(i => i.tabs && i.tabs.some(p => p.id === inflowActiveTab.id))[0];
			subTab = parentTab.tabs && parentTab.tabs.filter(i => i.id === inflowActiveTab.id)?.filter(item => item.isEnable)[0];
		}else if (tabs.some(i =>  i.id === inflowActiveTab.id)){
			parentTab = tabs.filter(i =>  i.id === inflowActiveTab.id)[0];
		}
		setSelectedTab(parentTab);
		setSelectedSubTab(subTab);
	}
}, [inflowActiveTab, tabs])



	const fetchAccountSegments =() => {
		const request = {
			path: selectedTab?.path,
		}
		dispatch(fetchAccountSegmentsList(request))
	}
	
	useEffect(() => {
		if (selectedTab && !selectedSubTab) {
			dispatch(setGlobalStates({ globalActiveTab: selectedTab }));
			dispatch(setGlobalStates({ globalParentActiveTab: selectedTab }));
		} else if (selectedTab &&  selectedSubTab) {
			dispatch(setGlobalStates({ globalActiveTab: selectedSubTab }));
			dispatch(setGlobalStates({ globalParentActiveTab: selectedTab }));			
		} else if(!selectedSubTab && !selectedTab){
			dispatch(setGlobalStates({ globalActiveTab: null }));
			dispatch(setGlobalStates({globalParentActiveTab: null}))
		}
	},[selectedTab, selectedSubTab])

	useEffect(() => {
		if (props.refreshKey) { setRefreshKey(props.refreshKey) }
	}, [props.refreshKey])

	useEffect(() => {
		if (selectedTab && tabs) {
			const tab = tabs.filter(i => i.id === selectedTab.id)[0];
			if (JSON.stringify(selectedTab) !== JSON.stringify(tab)) { setSelectedTab(tab); }
		}
	}, [tabs, selectedTab])

	


	useEffect(() => {
		if (!selectedTab) { return; }
		if (selectedSubTab) {
			onTabActive && onTabActive(selectedSubTab, true);
		} else if (selectedTab && selectedTab.tabs) {
			if (selectedTab.tabs.length >= 2) {
				if (activeReportId) {
					var tabsList = selectedTab.tabs.filter(i => { return i.id === activeReportId; });
					setSelectedSubTab(tabsList[0])
				}
			}
			if (!props.defaultFirstSelectDisabled) {
				let tabs = cloneDeep(selectedTab?.tabs);
				tabs = tabs && tabs.filter((i) => { return (i?.isEnable) })
				setSelectedSubTab(tabs?.[0])
				onTabActive && onTabActive(tabs?.[0]);
			} else {
				onTabActive && onTabActive(selectedTab);
			}
		} else {
			onTabActive && onTabActive(selectedTab);
		}
	}, [selectedTab, selectedSubTab, inflow, activeReportId])


	useEffect(() => {
		if (!activeReportId) { return; }
		if (!inflow) { return; }
		if (selectedTab && selectedTab.tabs && selectedTab.tabs.length > 0) {
			var tabsList = selectedTab.tabs.filter(i => { return i.id === activeReportId; });
			if (tabsList.length > 0) { setSelectedSubTab(tabsList[0]); }
		}
	}, [tabs, inflow, activeReportId])

	useEffect(() => {
    const id = smartFilterTokenService.subscribe(() => {
        updateUserFilterPreference();
        setRefreshKey(new Date().getMilliseconds() + "");
    });

    return () => {
        smartFilterTokenService.unSubscribe(id);
    };
}, [userPrefrenceRequest?.path, skSmartFilterToken, selectedTab?.path, selectedSubTab?.path, props.refreshKey]);

useEffect(() => {
    if (updateUserPrefrence && !loadingData && (selectedTab?.path || selectedSubTab?.path)) {
        updateUserFilterPreference();
    }
}, [updateUserPrefrence, loadingData, selectedTab?.path, selectedSubTab?.path]);


	// useEffect(()=>{
	// 	if((selectedTab?.path || selectedSubTab?.path ) && (activeTab) && !selectedActiveTab){
	// 		setSelectedActiveTab(activeTab)
	// 	}
	// },[activeTab,selectedTab?.path ,selectedSubTab?.path ])

	useEffect(() => {
    const shouldFetchUserFilterPreference =
        ((!updateUserPrefrence &&
            !props?.updateOnfilterPathChange &&
            !loadingData &&
            !props?.activeSubTabs &&
            !props?.disableFilterPreferenceApiCall &&
            // selectedActiveTab &&
            activeTab) ||
            props.forceRefresh) ||
        (props?.activeSubTabs && !updateUserPrefrence && !loadingData);

    if (shouldFetchUserFilterPreference) {
        // Condition to handle previous active tab, ensuring the correct tab is set
        if ((activeTab === selectedTab?.id && selectedTab.path) ||
            (activeTab === selectedSubTab?.id && selectedSubTab.path) ||
            activeReportId) {
            fetchUserFilterPreference();
        }
    }
}, [updateUserPrefrence, loadingData,activeTab, props.forceRefresh, props?.updateOnfilterPathChange,props?.activeSubTabs,
    props?.disableFilterPreferenceApiCall, activeReportId, selectedSubTab?.path,selectedTab?.path]);


		const fetchUserFilterPreference = async () => {
			try {
					setRefreshKey(new Date().getMilliseconds() + "");
					const response = await smartFilterTokenService.fetchUserFilterPreference();
					let data = cloneDeep(response.data);
					const pathName = window.location.search;
					const accountId = new URLSearchParams(pathName)?.get('accountId');	// account360
					const globalSearchSelectedAccounts = getAccountListStates().globalSearchSelectedAccounts?.value;
	
					if (data.accountFilter && (accountId || globalSearchSelectedAccounts)) {
							data.accountFilter.accountIds = globalSearchSelectedAccounts ? [globalSearchSelectedAccounts] : [accountId];
							data.accountFilter.allFilters = [];
					}
	
					setRefreshKey(new Date().getMilliseconds() + "");
					setSkSmartFilterToken(data);
					await fetchAttributes(data);
					setRefreshKey(new Date().getMilliseconds() + "");
			} catch (error) {
					setGlobalfilterAttributes([]);
					setFeatureFilters([]);
			}
	};
	
	const fetchAttributes = async (token) => {
			const path = selectedSubTab?.path || selectedTab?.path;
			if(!path){return; }
			try {
					const response = await cachedAPI.fetchSkSmartFilterAttributes(path);
					const filters = response?.data.filters;
	
					smartFilterTokenService.addPathFilter(path);
					smartFilterTokenService.addSubPathFilter(selectedSubTab?.subPath || selectedTab?.subPath);
	
					const attributes = getPathGlobalFilter(filters, path);
					const dateAttributes = getPathDateAttributes(filters, path) || [];
					const featureAttributes = getPathfeatureAttributes(filters, path);
	
					setGlobalfilterAttributes(attributes);
					setDateAttributes(dateAttributes);
					setFeatureFilters(featureAttributes);
	
					const featureFilter = (!selectedSubTab?.disableUserFilterPreference && !selectedTab?.disableUserFilterPreference)
							? cloneDeep(token.featurePreferences[path])
							: cloneDeep(smartFilterTokenService.token);
	
					const featureFiltersLen = featureFilter?.featureFilters?.length > 0 ? featureFilter : null;
					const featureDatefilterLen = featureFilter?.featureDateFilter?.inLastDays ? featureFilter?.featureDateFilter?.inLastDays : null;
					const effectiveFeatureFilter = (featureFiltersLen || featureDatefilterLen) ? featureFilter : null;
	
					const defaultOption = featureAttributes.find(attr => attr.defaultOption);
					if (defaultOption?.id && !effectiveFeatureFilter) {
							const url = `${window.resolveRegion(process.env.REACT_APP_SMART_DASHBOARDS_BASE_URL)}/smart-filter/attributes/values`;
							const config = {
									params: {
											appId: HWUser.appId(),
											userId: HWUser.userId(),
											orgId: HWUser.orgId(),
											attributeId: defaultOption.id,
									}
							};
							const valueResponse = await hwAxios.get(url, config);
							const selectedValues = valueResponse?.data?.values?.filter(val => val.selectedFlag) || [];
							if (selectedValues.length > 0) {
									onFeatureValueselect(defaultOption, null, selectedValues);
							}
					}
	
					if (effectiveFeatureFilter) {
							const featureDateFilter = {
									inLastDays: featureFilter?.featureDateFilter?.inLastDays || dateAttributes.find(attr => attr.defaultOption)?.id
							};
							if (featureFilter?.featureDateFilter?.inLastDays && enableGlobalDateFilter) {
									const dateFilter = dateAttributes.find(attr => featureFilter.featureDateFilter.inLastDays === attr.id);
									featureDateFilter.startDate = featureFilter.featureDateFilter.startDate;
									featureDateFilter.endDate = featureFilter.featureDateFilter.endDate;
							}
	
							if (needsAccountFilterUpdate(token)) {
									updateAccountFilter(token);
							}
							smartFilterTokenService.updateGlobalfilter(featureDateFilter, featureFilter?.featureFilters, token?.accountFilter, token?.productFilter, true);
					} else {
							const featureDateFilter = { inLastDays: dateAttributes.find(attr => attr.defaultOption)?.id };
							if (needsAccountFilterUpdate(token)) {
									updateAccountFilter(token);
							}
							smartFilterTokenService.updateGlobalfilter(featureDateFilter, [], token?.accountFilter, token?.productFilter, true);
					}
			} catch (error) {
					console.error("Error fetching attributes:", error);
			}
	};
	
	const needsAccountFilterUpdate = (token) => {
			return (disableAccountSegmentFilter || disableAccountSegment || disableGlobalAccountFilter) &&
					token?.accountFilter?.accountSelection === "AccountSegment";
	};
	
	const updateAccountFilter = (token) => {
			token.accountFilter.accountSelection = "Manual";
			token.accountFilter.accountSegmentIds = [];
			token.accountFilter.accountIds = [];
			if (token.accountFilter.accountIds.length === 0) {
					token.accountFilter.allFilters = ["AllAccounts"];
			}
	};

	const [globalfilterAttributes, setGlobalfilterAttributes] = useState([]);
	const [dateAttributes, setDateAttributes] = useState([]);
	const [featureFilters, setFeatureFilters] = useState([]);

	const getPathDateAttributes = (data, path) => {

		let dateAttributes = (data[path]?.dateFilters || []);
		return dateAttributes;
	}
	const getPathGlobalFilter = (data, path) => {
		let attributes = (data[path]?.globalFilters || []);
		return attributes;
	}
	const getPathfeatureAttributes = (data, path) => {
		let attributes = (data[path]?.featureFilters || []);
		return attributes;
	}

	const onValueSelect = useCallback((attribute, condition, values, allValues) => {
		values = values && values.map((i) => { return (i.id) })
		var filter = { attribute: attribute?.id || attribute?.attribute || attribute.name, name: attribute.name, condition: condition, values: values, allFilters: allValues }
		smartFilterTokenService.addFilter(filter);
	}, [])

	const onFeatureValueselect = useCallback((attribute, condition, values, allValues) => {
		values = values && values.map((i) => { return (i.id) })
		var filter = { attribute: attribute?.id || attribute?.attribute || attribute.name, name: attribute.name, condition: condition, values: values, allFilters: allValues }
		smartFilterTokenService.addFeatureFilter(filter);
		setRefreshKey(new Date().getMilliseconds() + "")
	}, [])

	const onAccountsSelect = useCallback((accountIds, allAccountIds) => {
		smartFilterTokenService.addAccounts((accountIds ?? []), allAccountIds);
		setRefreshKey(new Date().getMilliseconds() + "")
	}, [])

	const onAccountsSegmentSelect = useCallback((accountIds) => {
		smartFilterTokenService.addAccountSegments((accountIds ?? []));
		setRefreshKey(new Date().getMilliseconds() + "")
	}, [])

	const onAllAccountSelect = useCallback((accountIds) => {
		smartFilterTokenService.addAllAccounts((accountIds ?? []));
		setRefreshKey(new Date().getMilliseconds() + "")
	}, [])

	const onAllProductSelect = useCallback((allProductIds) => {
		smartFilterTokenService.addAllProducts((allProductIds ?? []),false,[]);
		setRefreshKey(new Date().getMilliseconds() + "")
	}, [])


	const onProductsSelect = useCallback((productIds) => {
		smartFilterTokenService.addProducts((productIds ?? []));
		setRefreshKey(new Date().getMilliseconds() + "")
	}, [])

	const editAttribute = useCallback((attribute) => {
		setEditingAttribute(attribute);
	}, [])
	const editFeatureAttribute = useCallback((attribute) => {
		setEditingFeatureAttribute(attribute);
	}, [])
	const filterAttributes = smartFilterTokenService.getFilters();
	const filterFeatureAttributes = smartFilterTokenService.getFeatureFilters();
	const selectedAccountIds = smartFilterTokenService.getAccountIds();
	const selectedAllAccountIds = smartFilterTokenService.getAllAccountIds();
	const selectedAllProductIds = smartFilterTokenService.getAllProductIds();
	const selectedProductIds = smartFilterTokenService.getProductIds();
	const selectedAccountSegmentIds = smartFilterTokenService.getAccountSegments();
	const selectedAccountType = smartFilterTokenService.getAccountSelection();
	const attributeContainer = useRef();
	const featureAttributeContainer = useRef();

	const onRemoveFilterAttribute = (attribute) => {
		if (attribute) {
			var filter = { id: attribute?.attribute || attribute.id, name: attribute.name }
			smartFilterTokenService.removeFilter(filter);
		}
		setRefreshKey(new Date().getMilliseconds() + "")
	}
	const onRemoveSegmentFilter = ()=>{
		smartFilterTokenService.removeSegmentFilter();
		setRefreshKey(new Date().getMilliseconds() + "")
	}

	const onRemoveFeatureFilterAttribute = (attribute) => {
		if (attribute) {
			var filter = { id: attribute?.attribute || attribute.id, name: attribute.name }
			smartFilterTokenService.removeFeatureFilter(filter);
		}
		setRefreshKey(new Date().getMilliseconds() + "")
	}



	const selectedProductName = useMemo(() => {
		if (productSelectOption === "singleSelect" && selectedProductIds && selectedProductIds.length > 0 && productsList) {
			let productName = productsList && productsList.filter((i) => {
				return (i.id === selectedProductIds[0])
			})[0]
			return productName?.name;
		}
	}, [selectedProductIds])

	const attributeIds = globalfilterAttributes && globalfilterAttributes.map((i) => { return (i?.id); })
	const featureAttributeIds = featureFilters && featureFilters.map((i) => { return (i?.id); })


	const featureFilter = useMemo(() => {
		return skSmartFilterToken && cloneDeep(skSmartFilterToken?.featurePreferences[selectedSubTab?.path || selectedTab?.path]);
	},[selectedSubTab?.path, selectedTab?.path, skSmartFilterToken])


	const updateUserFilterPreference = async() => {
		let request = {
			appId: HWUser.appId(),
			orgId: HWUser.orgId(),
			userId: HWUser.userId(),
			...smartFilterTokenService.token
		}
		if(updateUserPrefrence){ request.path = selectedSubTab?.path || selectedTab?.path;}
		

		let pathName = window.location.search;
		const accountId = new URLSearchParams(pathName)?.get('accountId');
		let requestData = cloneDeep(request)
		if(accountId){
			requestData.accountFilter.accountIds = []
			requestData.accountFilter.allFilters = ["AllAccounts"]
		}
		if ((skSmartFilterToken || updateUserPrefrence)  &&   ((!selectedSubTab?.disableUserFilterPreference && !selectedTab?.disableUserFilterPreference) || accountId)) {
				await smartFilterTokenService.updateUserFilterPreference(accountId?requestData:request).then((response) => {
					if(!accountId){
						setuserPrefrenceRequest(request)
						setSkSmartFilterToken(response?.data);
						props.setUpdateUserPrefrence && props.setUpdateUserPrefrence(false);
					}
				}).catch(() => { 
					// console.log("error") 
				})
			// }
		}
	}

	useEffect(() => {
		let parentElemnt = document?.querySelector(".sk_tab_filter_and_condition_container");
		let offsetHeight = document?.getElementsByClassName("sk_tab_filter_and_condition_container")[0].offsetHeight;
		let requiredHrs = Math.ceil(offsetHeight / 50)
		let i = 1;
		if (requiredHrs > 1) {
			let pathEle = document?.querySelectorAll(".sk_tab_filter_and_condition_container hr");;
			if (pathEle) {
				[].forEach.call(pathEle, function (eachEl) {
					eachEl.remove();
				})
			}
			for (i = 1; i < requiredHrs; i++) {
				let hrElement = document.createElement('hr');
				hrElement.style.marginTop = (50 * i) + "px";
				hrElement.style.marginBottom = "0px";
				parentElemnt.appendChild(hrElement)
			}
		}
	}, [filterAttributes, globalfilterAttributes])

	const serviceOrProduct = useMemo(() => {
		return HWUser.productOrService() === "service" ? "Services:" : "Products:";
	}, [])

	const hideCrossIcon = useCallback((attribute) => {
		if (
			((attribute?.id || attribute?.attribute) === "taskStatus_timeline") ||
			((attribute?.id || attribute?.attribute) === "account360_archive_status") ||
			((attribute?.id || attribute?.attribute) === "account360_livePlaybook_status") ||
			((attribute?.id || attribute?.attribute) === "touchpointList_archive_status") ||
			((attribute?.id || attribute?.attribute) === "taskStatus_account360") || ((attribute?.id || attribute?.attribute) === "taskStatus_list") || ((attribute?.id || attribute?.attribute) === "taskStatus_outcome") || ((attribute?.id || attribute?.attribute) === "contact_status") || ((attribute?.id || attribute?.attribute) === "playbook_live_status")) {
			return true
		}
		else {
			return false
		}
	}, [])
const [accountsOpenPopup,setAccountsOpenPopup] = useState(false);
const [accountName ,setAccountName] = useState(null);
const anchorEle = useRef();

useEffect(()=>{
	if(selectedAccountIds && selectedAccountIds?.length>0 && accountList){
		let account = cloneDeep(accountList);
		account = account && account.filter((i)=>{
			return (i.accountId === selectedAccountIds?.[0])
		})?.[0]
		if(account){
			setAccountName(account)
		}else{
			let request = {
				accountIds : selectedAccountIds?.[0]
			}
			smartFilterTokenService.serachAccountsOptionsFor(request).then(response=>{
				let accountLists = response.data.accounts.map(item => { return ({ accountId: item.id, valid: true, accountName: item.name }) });
				let list = [...accountList,...accountLists];
				dispatch(setGlobalStates({filterTabAccountList : list}))
				setAccountList(list)
				list = list && list.filter((i)=>{
					return (i.accountId === selectedAccountIds?.[0])
				})?.[0]
				setAccountName(list)
			}).catch(error => {
				// console.log(error)
			});
		}
	}else{
		setAccountName(null)
	}
},[selectedAccountIds])


	return (
		<div>
			<div className="sk_tab_filter_container">
				<PrimaryTab activeTab={activeTab} hideDateResetButn={hideDateResetButn} disableAlltabs={disableAlltabs} showDateResetButn={showDateResetButn} propsActions={propsActions} featureFilter={featureFilter} skSmartDateFilterToken={featureFilter?.featureDateFilter} exortData={exortData} exportXL={exportXL} onCreateActionClick={onCreateActionClick} setRefreshKey={setRefreshKey} refreshKey={refreshKey} dateAttributes={dateAttributes} enableCreateReport={enableCreateReport} disableGlobalShare={disableGlobalShare}
					selectedTab={selectedTab} selectedSubTab={selectedSubTab} setSelectedTab={setSelectedTab} setSelectedSubTab={setSelectedSubTab} tabs={tabs} callingFrom={callingFrom} 
					desableTouchpointFromAddBtn={desableTouchpointFromAddBtn} desableTaskFromAddBtn={desableTaskFromAddBtn} desableAlertFromAddBtn={desableAlertFromAddBtn} enableGlobalDateFilter={enableGlobalDateFilter}
					enableAddContact={enableAddContact} disableActions={disableActions} updateUserFilterPreference={updateUserFilterPreference} noButtons={noButtons} showXL={showXL} exportPDF={exportPDF} dateRange={dateRange}
				/>
				<div className="sk_tab_filter_and_condition_container">
					{!(props?.hideSubtabs) && selectedTab && selectedTab.tabs && selectedTab.tabs.length > 0 &&
						<SubTabs disableAlltabs={disableAlltabs} selectedTab={selectedTab} selectedSubTab={selectedSubTab} setSelectedSubTab={setSelectedSubTab} subTabSelectionDisabled={props.subTabSelectionDisabled} />}
					<div /*style={{height:filterAttributes&& filterAttributes.length>1?"auto":""}}*/ className="sk_tab_filter_and_condition_box_right"  key={selectedSubTab+selectedTab+"attribute_wrap"}>
						<div /*style={{marginTop:filterAttributes&& filterAttributes.length>1?"3px":""}}*/ className="right-filter-dropdowns-tab-container">
							{featureFilters && featureFilters.length > 0 && !disableGlobalAttributeFilter &&
								<div className="sk_feature_attribute_container" onClick={() => { setAddingNewFeatureAttribute(true); }}>
									<div className="sk-filter-attribute-container-filter"></div>
									<div ref={featureAttributeContainer} className="sk-filter-products-container-label">
										Filters
										{/* <span key={selectedSubTab + selectedTab + "attribute_wrap-text"}>
											{props.attributeSubTitle ? props.attributeSubTitle : (selectedSubTab?.title || selectedTab?.title) + " Attributes"}
										</span> */}
										<div className="sk__filter__accounts__arrow__icon__container">
											<div className="sk__filter__accounts__arrow__icon__container-arrow">
											</div>
										</div>
									</div>
								</div>
							}
							{globalfilterAttributes && globalfilterAttributes.length > 0 && <div className={`${disableGlobalAttributeFilter ? "pointer-events-none" : ""} sk-filter-attribute-container`} onClick={() => { setAddingNewAttribute(true); }}>
								<div className="sk-filter-attribute-container-filter" ></div>
								<div ref={attributeContainer} className="sk-filter-products-container-label">
									<span>
										Attributes
										{/* {smartFilterTokenService.token && smartFilterTokenService.token.filters ? "+ "+smartFilterTokenService.token.filters.length:""} */}
									</span>
									<div className="sk__filter__accounts__arrow__icon__container">

										<div className="sk__filter__accounts__arrow__icon__container-arrow">
										</div>
									</div>
								</div>
							</div>}
							{HWUser.orgType() === "playbookStandalone" && HWUser.roleType() === "NonAccount" ? "": <AccountsAndProducts accountName={accountName}  disableAccountSegmentFilter={disableAccountSegmentFilter || disableAccountSegment} setAccountsOpenPopup={setAccountsOpenPopup}  accountsOpenPopup={accountsOpenPopup} accountSegments={accountSegments} selectedAccountType={selectedAccountType} 
							selectedAccountSegmentIds={selectedAccountSegmentIds}  onAccountsSegmentSelect={onAccountsSegmentSelect} selectedProductIdsFromProps={selectedProductIdsFromProps} productSelectOption={productSelectOption} productsList={productsList} dateAttributes={dateAttributes} disableGlobalAccountFilter={disableGlobalAccountFilter} disableGlobalProductFilter={disableGlobalProductFilter} selectedAllAccountIds={selectedAllAccountIds} persistFilterValuesInDisabledState={persistFilterValuesInDisabledState}
								selectedAccountIds={selectedAccountIds} selectedAllProductIds={selectedAllProductIds} selectedProductIds={selectedProductIds} onAccountsSelect={onAccountsSelect} onAllAccountSelect={onAllAccountSelect} onAllProductSelect={onAllProductSelect} onProductsSelect={onProductsSelect} accountSelectOption={props.accountSelectOption} selectedTab={selectedTab} selectedSubTab={selectedSubTab} />}
						</div>
					</div>
					{addingNewAttribute &&
						<AttributeCondition filterAttributes={filterAttributes} anchorEl={attributeContainer?.current} open={true} attributes={globalfilterAttributes} onClose={() => { setAddingNewAttribute(null); }} onValueSelect={onValueSelect} />
					}
					{addingNewFeatureAttribute &&
						<AttributeCondition cutomeAttributeHeaderName={props.cutomeAttributeHeaderName} filterAttributes={filterFeatureAttributes} attributeHeaderName={(selectedSubTab?.title || selectedTab?.title)} anchorEl={featureAttributeContainer?.current} open={true} attributes={featureFilters} onClose={() => { setAddingNewFeatureAttribute(null); }} onValueSelect={onFeatureValueselect} />
					}
				{selectedAccountSegmentIds && selectedAccountSegmentIds.length>0 && !disableGlobalAccountFilter && !disableAccountSegmentFilter && !disableAccountSegment && 	<div className="sk_tab_filter_and_condition_box" >
						<div  ref={anchorEle} className={`filter-attribute-container`} >
							<SegmentAttributeConditionView onRemoveFilterAttribute={onRemoveSegmentFilter} onClick={()=>{setAccountsOpenPopup(anchorEle)}}  accountSegments={accountSegments} selectedAccountSegmentIds={selectedAccountSegmentIds}/>
						</div>
					</div>
				}

					{filterAttributes.map((attribute, index) => {
						if (editingAttribute && attribute?.values?.length > 0 && (attribute?.attribute === editingAttribute?.attribute)) {
							return (<div className="sk_tab_filter_and_condition_box" ><AttributeCondition open={true} attributes={globalfilterAttributes} editingAttribute={editingAttribute} onClose={() => { setEditingAttribute(null); }} onValueSelect={onValueSelect} /></div>)
						}
						return (attribute && attribute?.values?.length > 0) ? (
							<div className="sk_tab_filter_and_condition_box" >
								<div className={`${attributeIds.includes(attribute?.attribute) ? "" : "pointer-events-none"} filter-attribute-container`} >
									<AttributeConditionView attributes={globalfilterAttributes} attribute={attribute} onClick={() => { editAttribute(attribute); }} onRemoveFilterAttribute={onRemoveFilterAttribute} />
								</div>
							</div>) : <></>
					})}
					{featureAttributeIds && featureAttributeIds.length > 0 && filterFeatureAttributes.map((attribute, index) => {
						if (attribute && attribute?.values?.length > 0 && editingFeatureAttribute && (attribute?.attribute === editingFeatureAttribute?.attribute)) {
							return (<div className="sk_tab_filter_and_condition_box" ><AttributeCondition open={true} attributes={featureFilters} editingAttribute={editingFeatureAttribute} onClose={() => { setEditingFeatureAttribute(null); }} onValueSelect={onFeatureValueselect} /></div>)
						}
						return (attribute && attribute?.values?.length > 0) ? (
							<div className="sk_tab_filter_and_condition_box" >
								<div className={`${desableFeatureAttribute ? "hide1" : ""} ${featureAttributeIds.includes(attribute?.attribute) ? "" : "hide1"} filter-attribute-container`} >
									<AttributeConditionView hideCrossIcon={hideCrossIcon(attribute)} attributes={featureFilters} attribute={attribute} onClick={() => { editFeatureAttribute(attribute); }} onRemoveFilterAttribute={onRemoveFeatureFilterAttribute} />
								</div>
							</div>) : <></>
					})}
					{productSelectOption === "singleSelect" && productsList && productsList.length > 1 &&
						<div className="sk_tab_filter_and_condition_box" >
							<div className="filter-attribute-container">
								<div className="filter-attribute-container-label"> {serviceOrProduct}
									<span style={{ marginLeft: "5px" }} className="filter-attribute-container-label-value">
										{selectedProductName}
									</span>
								</div>
							</div>
						</div>
					}
				</div>
			</div>
		</div>
	)
}

export default memo(SmartFilterTab);

const PrimaryTab = ({ showDateResetButn, desableAlertFromAddBtn, desableTouchpointFromAddBtn, propsActions,
	desableTaskFromAddBtn, selectedTab, setSelectedTab, setSelectedSubTab, selectedSubTab,
	tabs, disableGlobalShare, dateAttributes, setRefreshKey, callingFrom, featureFilter, disableAlltabs,activeTab,
	onCreateActionClick, enableCreateReport, exportXL, exortData, exportPDF, skSmartDateFilterToken, enableAddContact, hideDateResetButn ,disableActions, refreshKey, enableGlobalDateFilter, updateUserFilterPreference, noButtons, showXL, dateRange}) => {
	return (
		<div className="sk_tab_filter_container_row sk_tab_filter_bottom_bar" >
			<div className="primaryTab-left">
				{tabs && tabs.filter(prop => prop.isEnable).map((tab) => {
					return (
						<div onClick={() => { setSelectedTab(tab); setSelectedSubTab(null); }} className={`sk_tab_filter_tab ${(tab.disable || disableAlltabs) ? "pointer-events-none" : ""}  ${(tab.id === selectedTab?.id ? " sk_tab_filter_tab_selected" : " sk_tab_filter_tab_unselected")}`} key={"sk-tab-item" + tab.title} >
							<Link to={tab?.rootPath} key={"sidebar" + tab.path} onClick={e => {
								  if (!tab?.rootPath || (selectedTab && selectedTab?.rootPath === tab?.rootPath && !selectedSubTab)) {
										e.preventDefault()
								 }
							}}>
								<div className="sk_tab_filter_tab_title" id={tab.id}>
									{tab.title}
									{tab?.isProTab && <div className="pro__icon__wrap"><div className="pro__icon"></div>
												<div className="pro__feature__text">
											PRO</div> </div>}
									{tab.title === selectedTab?.title && <div style={{ width: (callingFrom === "Sia") ? "1.356rem" : "", left: (callingFrom === "Sia") ? "34%" : "" }} className={tab.title === selectedTab?.title ? "sk_tab_filter_tab_selected_line" : ""}></div>}
								</div>
							</Link>
						</div>)
				})}
			</div>
			<div className="primaryTab-right">
				<div style={{ width: "75%" }}>
					<div>
						<SmartDatePicker updateUserFilterPreference={updateUserFilterPreference} enableGlobalDateFilter={enableGlobalDateFilter} hideDateResetButn={hideDateResetButn} refreshKey={refreshKey} showDateResetButn={showDateResetButn} skSmartDateFilterToken={featureFilter?.featureDateFilter} dateAttributes={dateAttributes} setRefreshKey={setRefreshKey} selectedTab={selectedTab} selectedSubTab={selectedSubTab} activeTab={activeTab} dateRange={dateRange}/>
					</div>
				</div>
				{(exportXL || exportPDF) && !disableGlobalShare && <div>
					<SmartShare exortData={exortData} exportXL={exportXL} disableGlobalShare={disableGlobalShare} noButtons={noButtons} showXL={showXL}/>
				</div>}
				<SmartActions
					disableActions={disableActions}
					actions={propsActions}  activeTab={activeTab} selectedParentTab={selectedTab} parentTab={selectedTab?.id} selectedTab={selectedSubTab?.id || selectedTab?.id} enableCreateReport={enableCreateReport} onCreateActionClick={onCreateActionClick} desableTouchpointFromAddBtn={desableTouchpointFromAddBtn} desableTaskFromAddBtn={desableTaskFromAddBtn} desableAlertFromAddBtn={desableAlertFromAddBtn} enableAddContact={enableAddContact} />
			</div>
		</div>
	)
}


const SubTabs = ({ selectedTab, selectedSubTab, setSelectedSubTab, subTabSelectionDisabled, disableAlltabs }) => {
	return (
		<div className="sk_tab_filter_and_condition_box_left">
			<div className="sk_tab_filter_and_condition_box_left_element">
				{selectedTab?.tabs && selectedTab?.tabs.filter(prop => prop.isEnable).map((tab) => {
					return <div className={`${tab.disable || (disableAlltabs && tab.title !== selectedSubTab?.title) ? " pointer-events-none " : ""} sk_tab_filter_sub_tab__container`}><div onClick={() => { if (subTabSelectionDisabled) { return; } setSelectedSubTab(tab); }}
						className={tab.title === selectedSubTab?.title ? "sk_tab_filter_sub_selected_tab sk_tab_filter_sub_tab" : "sk_tab_filter_sub_tab"}
						key={"sk-sub-tab-item" + tab.title} >
						<div className="filter-dot"></div>
						<Link to={tab?.rootPath} key={"sidebar" + tab.path}  onClick={e => {if(!tab?.rootPath){e.preventDefault()} }} /*onClick={e => {item.onClick && item.onClick(e);handleOnClick(e, item.name);}}*/>
							<div className={"sk_tab_filter_tab_title" + (tab.title === selectedSubTab?.title ? " sk_tab_filter_sub_tab_selected" : " sk_tab_filter_sub_tab_unselected")} > {tab.title} </div>
						</Link>
						</div>
					</div>
				})}
			</div>
		</div>
	);
}

const AccountsAndProducts = (props) => {
	const [productsOpen, setProductsOpen] = useState(false);
	const [accountsOpen, setAccountsOpen] = useState(false);
	const [selectedAccountsText, setSelectedAccountsText] = useState("");
	const [selectedProductsText, setSelectedProductsText] = useState("");
	const [selectedAccountIds, setSelectedAccountIds] = useState([]);
	const [selectedAllAccountIds, setSelectedAllAccountIds] = useState([]);
	const [selectedProductIds, setSelectedProductIds] = useState([]);
	const [selectedAllProductIds, setSelectedAllProductIds] = useState([]);
	const[selectedAccountSegmentsIds,setSelectedAccountSegmentsIds] = useState([]);
	const [selectedAccountType,setSelectedAccountType] = useState(null);
	const [count, setCount] = useState(null);

	useEffect(()=>{
		if(props.accountsOpenPopup){
			setAccountsOpen(props.accountsOpenPopup)
		}
	},[props.accountsOpenPopup])

	useEffect(()=>{
		setSelectedAccountType(props.selectedAccountType)
	},[props.selectedAccountType])

	const onAccountsSelect = useCallback((accountIds) => {
		const ids = (accountIds ?? []);
		setSelectedAccountIds(ids);
	}, [])
	const onAllAccountSelect = useCallback((accountIds) => {
		const ids = (accountIds ?? []);
		setSelectedAllAccountIds(ids);
	}, [])
	const onAccountSegmentsSelect = useCallback((accountIds) => {
		const ids = (accountIds ?? []);
		setSelectedAccountSegmentsIds(ids);
	}, [])
	const onAccountSelction  = useCallback((value) => {
		setSelectedAccountType(value);
	}, [])
	const onAllProductSelect = useCallback((productIds) => {
		const ids = (productIds ?? []);
		setSelectedAllProductIds(ids);
	}, [])

	const onProductsSelect = useCallback((productIds) => {
		const ids = (productIds ?? []);
		setSelectedProductIds(ids);
	}, [])

	const onApply = () => {
		if (accountsOpen) {
			if(selectedAccountType === "AccountSegment"){
				props.onAccountsSegmentSelect && props.onAccountsSegmentSelect(selectedAccountSegmentsIds);
			}else{
				props.onAccountsSelect && props.onAccountsSelect(selectedAccountIds, selectedAllAccountIds);
			}		
		} else if (productsOpen) {
			if (selectedAllProductIds && selectedAllProductIds.length > 0) {
				props.onAllProductSelect && props.onAllProductSelect(selectedAllProductIds);
			} else {
				props.onProductsSelect && props.onProductsSelect(selectedProductIds);
			}
		}
		onClosePopUp();
	}

	useEffect(() => {
		let accountIds = cloneDeep(props.selectedAllAccountIds) || [];
		let text = "";

		if (accountIds && accountIds.includes("AllChildAccounts")) {
			text = "All Child "
		}
		if (accountIds && accountIds.includes("AllParentAccounts")) {
			text = "All Parent "
		}
		if (accountIds && accountIds.includes("AllAccounts")) {
			text = "All"
		}
	
		if (!(text?.toLowerCase()?.includes("all"))) {
			text += props.selectedAccountIds && props.selectedAccountIds.length > 0 ? (props?.accountName?.accountName)  : props?.accountName?.accountName;
			if(props?.selectedAccountIds?.length > 1){
				setCount((props.selectedAccountIds.length-1))
			}else{
				setCount(null);
			}
		}else{
			setCount(null);
		}
		text = (text !== undefined && text !== "undefined")?text:""
		setSelectedAccountsText(text);
	}, [props?.accountName?.accountName, props.selectedAccountIds, props.selectedAllAccountIds])

	useEffect(() => {
		let text = "";
		if (props?.selectedAllProductIds?.includes("AllProducts")) {
			text = "All"
		} else {
			text = props.selectedProductIds && props.selectedProductIds.length > 0 ? ("+" + props.selectedProductIds.length) : "";
		}
		setSelectedProductsText(text);
	}, [props.selectedProductIds, props?.selectedAllProductIds])

	const onClosePopUp = () => {
		if (!(accountsOpen || productsOpen)) { setAccountsOpen(false); setProductsOpen(false);props.setAccountsOpenPopup(false); }
		else if (accountsOpen) { setAccountsOpen(false);props.setAccountsOpenPopup(false); }
		else if (productsOpen) { setProductsOpen(false); }
	}

	const anchorEle = useRef();
	const productAnchorEle = useRef()
	const btns = {
		primaryBtnLabel: "APPLY",
		primaryBtnAction: onApply,
		className: "globalfilter_appy_btn_container"
	}
	const serviceOrProduct = HWUser.productOrService() === "service" ? "Services:" : "Products:";
	return (
		<>
			<div ref={anchorEle} className={`${props?.disableGlobalAccountFilter ? "pointer-events-none" : ""} ${(props?.selectedAccountIds?.length > 0 && !props?.disableGlobalAccountFilter) ? "sk-filter-accounts-blue-container" : ""}  sk-filter-accounts-container`} onClick={(e) => { setAccountsOpen(true); }}>
				<div className="sk-filter-accounts-container-icon"></div>
				<div className="sk-filter-accounts-container-label">
					<span className="d-flex"> 
						Accounts: 
						{(props?.disableGlobalAccountFilter && !props?.persistFilterValuesInDisabledState) ? 
						<span>All</span>: <AutoUiLabel className={`${props?.disableGlobalAccountFilter ? "pointer-events-disable" : ""}`}  maxWidth={"200px"} width={"auto"} length={"auto"} placement="top" tooltipClass="HWSelectMultiSelectTooltip" label={selectedAccountsText|| ""} />}   
						{count && <span>{props.disableGlobalAccountFilter ? "" : count ? "+" + count + " " : ""}</span>}
					</span>
				</div>
				<div style={{ marginTop: "-3px" }} className="sk__filter__accounts__arrow__icon__container">
					<div className="sk__filter__accounts__arrow__icon__container-arrow"></div>
				</div>
			</div>
			{props.productsList && props.productsList.length > 1 && <div ref={productAnchorEle}
				className={` ${props?.disableGlobalProductFilter ? "pointer-events-none" : ""} ${props.selectedProductIds && props.selectedProductIds.length > 0 ? "sk-filter-products-blue-container" : ""} sk-filter-products-container`}
				onClick={(e) => { setProductsOpen(true); }}>
				<div className={HWUser.productOrService() === "product" ? "sk-filter-products-container-icon" : "sk-filter-service-container-icon"}></div>
				<div className={` sk-filter-products-container-label`}>
					<span> {serviceOrProduct} {selectedProductsText}</span>
					<div className="sk__filter__accounts__arrow__icon__container">
						<div className="sk__filter__accounts__arrow__icon__container-arrow">
						</div>
					</div>
				</div>
			</div>}
			{(accountsOpen || productsOpen) && <Popover id={"1231324"} open={accountsOpen || productsOpen} anchorEl={accountsOpen ? (props?.accountsOpenPopup?.current || anchorEle?.current) : productsOpen ? productAnchorEle?.current : null}
				onClose={() => {
					onClosePopUp();
				}}
				anchorOrigin={{ vertical: 'bottom', horizontal: "right", }}
				transformOrigin={{ vertical: 'top', horizontal: 'right', }}
				PaperProps={{ style: dropdownPaperStyle, }}>
				<div className="filter-dropdown-container">
					<div className="filter-dropdown-header-container">
						<div className="filter-dropdown-header-label">{accountsOpen ? "Accounts" : HWUser.productOrService() === "service" ? "Services" : "Products"} Filter</div>
					</div>
					{accountsOpen && <AccountSearch disableAccountSegmentFilter={props.disableAccountSegmentFilter} accountSegments={props.accountSegments} selectedAccountType={props.selectedAccountType} onAccountSelction={onAccountSelction} selectedAccountSegmentIds={props.selectedAccountSegmentIds} 
					onAccountSegmentsSelect={onAccountSegmentsSelect} selectedAccountIds={props.selectedAccountIds} selectedAllAccountIds={props.selectedAllAccountIds} onAccountsSelect={onAccountsSelect} onAllAccountSelect={onAllAccountSelect} accountSelectOption={props.accountSelectOption} selectedTab={props.selectedTab} />}
					{productsOpen && <ProductSearch productSelectOption={props.productSelectOption} selectedAllProductIds={props.selectedAllProductIds} productsList={props.productsList} selectedProductIds={props.selectedProductIds} onProductsSelect={onProductsSelect} onAllProductSelect={onAllProductSelect} />}
					<SKSmartUiBtns  {...btns} />
				</div>
			</Popover>}</>
	)
}

const AttributeConditionView = (props) => {
	const [valueDataText, setValueDataText] = useState(null);
	const { outcomeVisibleName } = useSelector(getGlobalStates);

	const textPrefix = useMemo(() => {
			const { attribute, attributes } = props;
			let text = attribute?.name || "";
			const name = attributes?.find(i => i.id === (attribute?.attribute || attribute?.id));
			
			if (!attribute?.name && (attribute?.attribute || attribute?.id)) {
					text = name?.name || "";
			}

			if (attribute?.condition && name?.conditions) {
					const conditionVisibleName = name.conditions?.find(i => i.condition === attribute.condition)?.visibleName || "";
					text += `<span class='filter_attribute_condition_name--container'>${conditionVisibleName}</span>`;
			}

			if (text === "Outcome") {
					const userOutcomeConfig = JSON.parse(window.localStorage.getItem("platform-currentUser"))?.outcomeConfig?.outcomeVisibleName;
					return userOutcomeConfig || text;
			}

			return text;
	}, [props.attribute, props.attributes, outcomeVisibleName]);

	const fetchValueText = useCallback(async (attribute) => {
			const url = `${window.resolveRegion(process.env.REACT_APP_SMART_DASHBOARDS_BASE_URL)}/smart-filter/attributes/values`;
			const config = {
					params: {
							appId: HWUser.appId(),
							userId: HWUser.userId(),
							orgId: HWUser.orgId(),
							attributeId: attribute.id || attribute.attribute,
					}
			};

			try {
					const response = await hwAxios.get(url, config);
					const attributeValues = Array.isArray(response?.data?.values) ? response?.data?.values : [];
					let text = null;

					if (attribute?.values && attribute?.values.length > 0) {
							const inputType = response?.data?.inputType;

							if (["TextField", "NumericField", "DecimalField"].includes(inputType)) {
									text = attribute?.values[0];
							} else {
									const matchedValue = attributeValues.find(i => attribute?.values.includes(i.id));
									text = matchedValue?.name || null;
							}
							setValueDataText(text);
					}
			} catch (error) {
					console.error("Error fetching attribute values:", error);
			}
	}, []);

	useEffect(() => {
			if (props.attribute.values && props.attribute.values.length > 0 && !props.attribute.values[0]?.name) {
					fetchValueText(props.attribute);
			}
	}, [props.attribute, fetchValueText]);

	const valueText = useMemo(() => {
			const { attribute } = props;
			if (attribute.values && attribute.values.length > 0) {
					return attribute.values[0]?.name || valueDataText;
			}
			return null;
	}, [props.attribute, valueDataText]);

	const textSuffix = useMemo(() => {
			const { attribute } = props;
			return attribute.values && attribute.values.length > 1 ? ` +${attribute.values.length - 1}` : "";
	}, [props.attribute]);

	return (
		<>
			<div className={`filter-attribute-container-label`} onClick={(e) => { props.onClick && props.onClick(e); }}>
				<span dangerouslySetInnerHTML={{ __html: textPrefix }}></span>
				<span className={"filter-attribute-container-label-value"}>
					<span>: </span>
					<UiLabel length={30} placement="top" tooltipClass="HWSelectMultiSelectTooltip" label={valueDataText ? valueDataText : valueText} />
				</span> {textSuffix}</div>
			{!props?.hideCrossIcon && <div className="filter-attribute-container-remove-icon" onClick={(e) => { e.stopPropagation(); props.onRemoveFilterAttribute(props.attribute) }}></div>}
		</>
	)
};
const SegmentAttributeConditionView = (props)=>{
	const valueText = useMemo(()=>{
		let text ="";
		let list = cloneDeep(props.accountSegments);
		text = list && list.filter((i)=>{return (i.id === props.selectedAccountSegmentIds?.[0])})?.[0]?.name;
		return text
	},[props.selectedAccountSegmentIds,props.accountSegments])

	const textSufffix = useMemo(() => {
		let attribute = props.selectedAccountSegmentIds;
		if (attribute?.length > 1) { return " +" + (attribute.length - 1); }
		return "";
	}, [props.selectedAccountSegmentIds])

	return (
		<>
			<div className={`filter-attribute-container-label`} onClick={(e) => { props.onClick && props.onClick(e); }}>
				{/* {textPrefix} */}
				<span dangerouslySetInnerHTML={{ __html: "Account Segments" }}></span>
				<span className={"filter-attribute-container-label-value"}>
					<span>: </span>
					<UiLabel length={30} placement="top" tooltipClass="HWSelectMultiSelectTooltip" label={valueText} />
				</span> {textSufffix}</div>
				<div className="filter-attribute-container-remove-icon" onClick={(e) => { e.stopPropagation(); props.onRemoveFilterAttribute() }}></div>
		</>
	)
}

// Options: { id: optionsId, name: Option Name }
const AttributeCondition = (props) => {
	const [attributes, setAttributes] = useState([]);
	const [optionsToSelect, setOptionToSelect] = useState("attribute");
	const [selectedAttribute, setSelectedAttribute] = useState();
	const [selectedCondition, setSelectedCondition] = useState();
	const [editMode, setEditMode] = useState(props.editingAttribute && true)
	const dropdownRef = useRef();
	const [selectedValues, setSelectedValues] = useState(props.selectedValues || []);
	const [searchText, setSearchText] = useState(null);
	const getGlobalState = useSelector(getGlobalStates);
	const {outcomeVisibleName} = getGlobalState;
	const displayAttribute = selectedAttribute &&
	{
		id: selectedAttribute.id,
		name: selectedAttribute.name,
		condition: selectedCondition,
		values: [],
		allValues: []
	}

	useEffect(() => {
		if (editMode && props.editingAttribute) {
			if (attributes && attributes.length > 0) {
				if (attributes.filter(i => i.id === props.editingAttribute.attribute).length > 0) {
					const attribute = attributes.filter(i => i.id === props.editingAttribute.attribute)[0];
					const aAttribute = props.editingAttribute;
					aAttribute.name = attribute?.name
					aAttribute.conditions = attribute.conditions;
					setSelectedAttribute(aAttribute);
				}
			}
		}
	}, [editMode, attributes, props.editingAttribute])

	useEffect(() => {
		if (!selectedAttribute) { return; }
		const isConditionsPresent = selectedAttribute.conditions && selectedAttribute.conditions.length > 0;
		if ((isConditionsPresent && selectedCondition) || !isConditionsPresent) {
			setOptionToSelect("values");
		} else if (isConditionsPresent) {
			setOptionToSelect("condition");
		}
	}, [selectedAttribute, selectedCondition])

	useEffect(() => { setAttributes(props.attributes); }, [props.attributes])

	const checkAndClose = useCallback(() => {
		if (selectedAttribute) {
			props.onValueSelect && props.onValueSelect(selectedAttribute, selectedCondition, ((selectedValues && selectedValues.length > 0) ? selectedValues : []));
		}
		props.onClose && props.onClose();
	}, [selectedValues, selectedAttribute, selectedCondition])

	const onValueSelect = useCallback((values) => {
		if (selectedAttribute) {
			setSelectedValues(values || []);
			if (selectedAttribute.selectType === "SingleSelect") {
				checkAndClose();
			}
		}
	}, [selectedAttribute, checkAndClose])
	const onRemoveFilterAttribute = (attribute) => {
		setSelectedAttribute(null);
		setOptionToSelect("attribute");
		setSelectedCondition(null);
	}
	const btns = {
		primaryBtnLabel: "APPLY",
		primaryBtnAction: checkAndClose
	}

	const setAttribute = (attribute) => {
		if (props.filterAttributes && props.filterAttributes.length > 0) {
			let isExitInfilters = props.filterAttributes && props.filterAttributes.filter((i) => {
				return (i.attribute === (attribute?.attribute || attribute.id))
			})
			if (isExitInfilters && isExitInfilters.length > 0) {
				attribute.values = isExitInfilters[0].values;
				attribute.conditions = isExitInfilters[0].conditions;
			}
		}
		setSelectedAttribute(attribute);
	}
	return (
		<div ref={dropdownRef} className={props?.attributeContainerClassName}>
			{!props.anchorEl && <div className="filter-attribute-search-container">
				{!selectedAttribute &&
					<div className="filter-dropdown-search-box-container">
						<div className="filter-dropdown-search-box">
							<input placeholder="Search..." className="filter-dropdown-search-box-input" onChange={(e) => { setSearchText(e.target.value); }} />
						</div>
					</div>
				}
				{selectedAttribute && <div className="filter-attribute-container filter-attribute-container-dropdown"><AttributeConditionView attributes={props?.attributes} attribute={displayAttribute} /></div>}
			</div>}
			{dropdownRef.current && <Popover id={"123234234234"} open={props.open} anchorEl={props.anchorEl ? props.anchorEl : dropdownRef.current}
				onClose={() => { props.onClose && props.onClose(); }}
				anchorOrigin={{ vertical: 'bottom', horizontal: "right", }}
				transformOrigin={{ vertical: 'top', horizontal: 'right', }}
				PaperProps={{
					style: {
						width: '250px', background: "#FFFFFF 0% 0% no-repeat padding-box", boxShadow: "0px 3px 6px #00000029",
						borderRadius: "0px 0px 13px 13px", opacity: 1, marginTop: selectedAttribute ? "1px" : "-2px",
						border: "1px solid var(--sk-smart-datepicker-border-color)"
					},
				}}>
				<div className="filter-dropdown-header-container">
					<div className="filter-dropdown-header-label attribute-filter-dropdown-header-label">
					Filters
						{/* {props.cutomeAttributeHeaderName ? props.cutomeAttributeHeaderName : props.attributeHeaderName ? props.attributeHeaderName + " Attributes " : "Attributes "} */}
					</div>
				</div>
				{props.anchorEl && <div className="filter-attribute-search-container">
					{/* {!selectedAttribute && attributes && attributes.length > 7 &&
                    <div className="filter-dropdown-search-box-container">
                            <div className="filter-dropdown-search-box">
                                <input placeholder={"Search "+ props.attributeHeaderName + " Attributes..."} className="filter-dropdown-search-box-input" onChange={(e) => { setSearchText(e.target.value); }} />
                            </div>
                    </div>
                    } */}
					{selectedAttribute && <div className="filter-attribute-container filter-attribute-container-dropdown"><AttributeConditionView attributes={props?.attributes} onRemoveFilterAttribute={onRemoveFilterAttribute} attribute={displayAttribute} /></div>}
				</div>}

				<div className={`${!selectedAttribute ? "filter-attribute-dropdown-container-search" : ""} filter-attribute-dropdown-container`}>

					{optionsToSelect === "attribute" && attributes && attributes.length > 0 &&
						<div className="filter-attribute-dropdown-options">
							{attributes.map(attribute => {
								return (
									<div key={attribute.id}
										onClick={() => { setAttribute(attribute) }}
										className="filter-attribute-dropdown-option filter-attribute-dropdown-option-lable" >
										<div className="w-100">
											<AutoUiLabel with={"90%"} placement="top" tooltipClass="HWSelectMultiSelectTooltip" label={attribute?.name === "Outcome"?JSON.parse(window.localStorage.getItem("platform-currentUser"))?.outcomeConfig?.outcomeVisibleName:attribute?.name} />
										</div>
									</div>)
							})}
						</div>}
					{optionsToSelect === "condition" && selectedAttribute && selectedAttribute.conditions && selectedAttribute.conditions.length > 0 &&
						<div className="filter-attribute-dropdown-options">
							{selectedAttribute.conditions.map((condition, index) => {
								return (
									<div
										key={condition + index + ""}
										className="filter-attribute-dropdown-option filter-attribute-dropdown-option-lable"
										onClick={() => { setSelectedCondition(condition?.condition); }}>
										<div className="w-100">
											<AutoUiLabel with={"90%"} placement="top" tooltipClass="HWSelectMultiSelectTooltip" label={condition?.visibleName === "Outcome"?JSON.parse(window.localStorage.getItem("platform-currentUser"))?.outcomeConfig?.outcomeVisibleName:condition?.visibleName}  />
										</div>
									</div>)
							})}
						</div>}
					{optionsToSelect === "values" && values &&
						<div className="">
							<AttributeValueSearch attribute={selectedAttribute} editMode={editMode} editingAttribute={props.editingAttribute} condition={selectedCondition}
								setCondition={setSelectedCondition} onValueSelect={onValueSelect}
								selectedValues={selectedValues}
							/>
						</div>}
					{selectedAttribute && optionsToSelect === "values" &&
						<div className="filter-dropdown-header-apply-button-edit-mode">
							{/* <div className="filter-dropdown-header-apply-button" onClick={checkAndClose} >Apply</div> */}
							<SKSmartUiBtns {...btns} />
						</div>
					}
				</div>
			</Popover>}
		</div>);
}

const AttributeValueSearch = (props) => {
	const [searchText, setSearchText] = useState(null);
	const [values, setValues] = useState(props.values || []);
	const [selectedValues, setSelectedValues] = useState(props.selectedValues);
	const [selectOption, setSelectOption] = useState(null);
	const [initialValue, setInitialValue] = useState([]);
	const [inputType, setInputType] = useState(null);
	// const [inputTypeValue,setInputTypeValue] = useState(null);
	const getGlobalState = useSelector(getGlobalStates);
	const {outcomeVisibleName} = getGlobalState;
	useEffect(() => {
		let updatedValues
		var selectedNames = (selectedValues ?? []).map(i => i.name);
		if (selectedNames?.includes("All")) {
			updatedValues = values.map(i => {
				i.selected = true
				return i;
			})
		} else {
			updatedValues = values.map(i => {
				i.selected = selectedNames.indexOf(i.name) != -1;
				return i;
			})
		}
		setValues(updatedValues);
		props.onValueSelect && props.onValueSelect(selectedValues);

	}, [selectedValues])

	console.log("values.....", selectedValues);


	const onValueSelect = (value) => {
		if (selectOption === "multiSelect") {
			let attributeValues
			if (value?.id === 'All') {
				if (!value?.selected) {
					attributeValues = [value]
				} else {
					attributeValues = []
				}
			} else {
				const allAttribute = selectedValues && selectedValues?.length > 0 && selectedValues.find(value => value.id === "All");
				if (allAttribute?.selected) {
				  attributeValues = values.filter(i => (i.name !== 'All') && (i.name !== value.name));
					attributeValues = attributeValues.map(i => { i.selected = true; return i; });
				} else {
					attributeValues = (selectedValues && selectedValues.filter(i => i.name === value.name).length > 0)
						? selectedValues.filter(i => i.name !== value.name)
						: [...selectedValues, value] ?? [value];
					if (attributeValues.length + 1 === values.length && values.find(value => value.id === "All")) {
						attributeValues = values.filter(i => i.name === 'All')
					}
					attributeValues = attributeValues.map(i => { i.selected = true; return i; });
				}
			}
			setSelectedValues(attributeValues);
		} else {
			// let attributeValues = []
			// if(selectedValues && selectedValues.filter(i => i.name === value.name).length > 0){
			//     attributeValues = []
			// }else{
			//     attributeValues =  [value]
			// }
			let attributeValues = [value]
			setSelectedValues(attributeValues);
		}
	}

	useEffect(() => {
		if (!props.attribute) { return; }
		const url = window.resolveRegion(process.env.REACT_APP_SMART_DASHBOARDS_BASE_URL) + "/smart-filter/attributes/values"
		const config = {
			params: {
				appId: HWUser.appId(), userId: HWUser.userId(), orgId: HWUser.orgId(),
				attributeId: props?.attribute?.id || props?.attribute?.attribute, searchText: searchText
			}
		}
		hwAxios.get(url, config).then(response => {
			setSelectOption(response?.data?.selectOption)
			setInputType(response?.data?.inputType)
			const attributeValues = isArray(response?.data?.values) ? response?.data?.values : [];
			let selectedAttributes = [];
			if ((response?.data?.inputType === "NumericField" || response?.data?.inputType === "TextField" || inputType === "DecimalField") && (props?.attribute?.values && props?.attribute?.values.length > 0)) {
				selectedAttributes = [{ id: props?.attribute?.values?.[0], name: props?.attribute?.values?.[0] }]
			} else if (props?.attribute?.values && props?.attribute?.values.length > 0) {
				attributeValues.map((i) => {
					if (props?.attribute?.values.includes(i.id)) {
						i.selected = true;
						selectedAttributes = [...selectedAttributes, i]
					}
				})
			}
			setSelectedValues([...new Set([...selectedValues, ...selectedAttributes])].filter(i => i && true))
			// setSelectedValues([...selectedValues, ...selectedAttributes]);
			if (!searchText) {
				setInitialValue(attributeValues)
			}
			setValues(attributeValues);
		})
	}, [props.attribute, searchText])
	const handleValueChange = useCallback((value) => {
		setSelectedValues([{ id: value, name: value }]);
	}, [])
	return (
		<div className="filter-dropdown-search-box-container global_filter_dropdown">
			{initialValue && initialValue.length > 7 && (inputType !== "TextField" && inputType !== "NumericField" &&  inputType !== "DecimalField") && <div className="filter-dropdown-search-box">
				<input placeholder={"Search " + props.attribute?.name + "..."} className="filter-dropdown-search-box-input" onChange={(e) => { setSearchText(e.target.value); }} />
			</div>}
			<>
				{(inputType === "TextField" || inputType === "NumericField" || inputType === "DecimalField") ? <>
					<div className="filter-dropdown-input-feild--container">
						<SkSmartInputButton  allowedKey={ inputType === "DecimalField"?'.':null} autoFocus={true} type={(inputType === "NumericField" || inputType === "DecimalField" )? "number" : "text"} value={selectedValues[0]?.name || selectedValues[0]?.id} isRequired={true} placeholder={"Type " + props.attribute?.name + "..."} style={{ borderBottom: (props.title === '') ? '1px solid red' : '' }}
							onChange={(e) => {
								if (/^\s/.test(e.target.value)) e.target.value = '';
								handleValueChange(e.target.value);
							}} />
					</div>
				</> :
					<>{values && values.length > 0 && <div className="filter-dropdown-search-box-options">
						<div className="filter-dropdown-search-box-options-container">
							{values.map(value => {
								// const strimTiltle = value?.name?.length>350?value?.name.slice(0, 350)+"....":null
								return (
									<div key={value.id} className={((value.selected) ? "filter-attribute-dropdown-option-selected filter-attribute-dropdown-option-lable" : "filter-attribute-dropdown-option filter-attribute-dropdown-option-lable")} onClick={() => { onValueSelect(value); }} >
										{selectOption === "multiSelect" && <div className={value.selected ? "filter-checkmark-container-selected" : "filter-checkmark-container-unselected"}><div className="filter-checkmark-tick"></div></div>}
										{selectOption === "singleSelect" &&
											<div>
												<SkRadioBtn checked={value.selected ? true : false} onChange={(e) => { onValueSelect(value); }} />
											</div>
										}
										<div className="w-100">
											{/* {value.name} */}
											<AutoUiLabel with={"90%"} placement="top" tooltipClass="HWSelectMultiSelectTooltip grid_title__tooltip" label={value?.name==="Outome"? JSON.parse(window.localStorage.getItem("platform-currentUser"))?.outcomeConfig?.outcomeVisibleName :value?.name} />
										</div>
									</div>)
							})}
						</div>
					</div>}
					</>}
			</>
		</div>
	)
}

const AccountSearch = (props) => {
	const [values, setValues] = useState([]);
	const [searchText, setSearchText] = useState(null);
	const [selectedValues, setSelectedValues] = useState(props.selectedValues || []);
	const [selectedIds, setSelectedIds] = useState([]);
	const [selectedSegmentIds,setselectedSegmentIds] =useState(props.selectedAccountSegmentIds || [])
	const [isSelectedAllIds, setIsSelectedAllIds] = useState([]);
	const [exapandesParentAccountIds, setExapandesParentAccountIds] = useState([]);
	const [isLodingApi, setIsLodingApi] = useState(false);
	const[isLodingSegmentsApi,setIsLodingSegmentsApi] = useState(false);
	const [allAccountsList,setAllAccountsList] = useState([]);
	const [accountSelection,setAccountSelection] = useState({value:"Manual",label:"All Accounts"});
	const [accountSegments,setAccountSegments]= useState([]);

	const [accountSelectionOption,setaccountSelectionOption] = useState([
		{ value: "Manual", label: "All Accounts" },
		{ value: "AccountSegment", label: "Account Segments" }
	])

	useEffect(()=>{
		if(props.disableAccountSegmentFilter){
			setaccountSelectionOption([
				{ value: "Manual", label: "All Accounts" }])
		}else{
			setaccountSelectionOption([
				{ value: "Manual", label: "All Accounts" },
				{ value: "AccountSegment", label: "Account Segments" }
			])
		}
	},[props.disableAccountSegmentFilter])

	useEffect(()=>{
		setselectedSegmentIds(props.selectedAccountSegmentIds)
	},[props.selectedAccountSegmentIds])

	useEffect(()=>{
		let type = cloneDeep(accountSelectionOption);
		type = type && type.filter((i)=>{return (i.value === props.selectedAccountType )})[0];
		setAccountSelection(type)
	},[props.selectedAccountType])

	useEffect(() => {
		if (props.selectedAccountIds) {
			const values = props.selectedAccountIds.map(id => { return { "id": id, "name": "" }; });
			setSelectedValues(values);
		}
	}, [props.selectedAccountIds])

	useEffect(() => {
		if (props?.accountSelectOption !== "singleSelect") {
			const values = cloneDeep(props.selectedAllAccountIds);
			setIsSelectedAllIds(values)
		}
	}, [props.selectedAllAccountIds])

	useEffect(() => {
		if (!searchText) {
			fetchGlobalFiterAccounts();

		}
	}, [searchText])

	useEffect(() => {
		setAccountSegments(props.accountSegments)
	}, [props.accountSegments])

	useEffect(() => {
		const delayDebounceFn = setTimeout(() => {
			if (searchText && accountSelection?.value !== "AccountSegment") {
				fetchGlobalFiterAccounts();
			}
		}, 100)
		return () => clearTimeout(delayDebounceFn)
	}, [searchText])

	useEffect(() => {
		const delayDebounceFn = setTimeout(() => {
			if (searchText && accountSelection?.value === "AccountSegment") {
				fetchAccountSegments();
			}
		}, 100)
		return () => clearTimeout(delayDebounceFn)
	}, [searchText, accountSelection?.value])

	const  parseOptions = (filters) => {
		filters = filters ? filters : [];
		return (filters || []).map((item, index) => {
				return { id: item.accountId, valid: true, name: item?.accountName, ...item}
		})
	}
	const fetchGlobalFiterAccounts = () => {
		setIsLodingApi(true);
		const request = {
			searchForText: searchText,
			path: props.selectedTab?.path,
		}
		smartFilterTokenService.globalFilterAccountSearchApi(request).then(response => {
			let accounts = response?.data?.data || [];
			let allAccounts = cloneDeep(allAccountsList);
			if(allAccountsList && allAccountsList.length<=0){
				allAccounts = accounts && accounts.filter((i) => {
					return (i.accountId?.toLowerCase()?.includes("all"))
				})
				allAccounts = parseOptions(allAccounts)
				setAllAccountsList(allAccounts)
			}
			accounts = accounts && accounts.filter((i) => {
				return !(i.accountId?.toLowerCase()?.includes("all"))
			})
			accounts = accounts && accounts.map((i) => {
				return ({ id: i?.accountId, name: i.accountName, ...i })
			})
			setValues(accounts);
			setIsLodingApi(false);
		})
	}
	const fetchAccountSegments =() => {
		setIsLodingSegmentsApi(true);
		const request = {
			searchForText: searchText,
			path: props.selectedTab?.path,
		}
		smartFilterTokenService.globalFilterAccountSegmentsSearchApi(request).then(response => {
			let accounts = response?.data?.accountSegments || [];
			setAccountSegments(accounts)
			setIsLodingSegmentsApi(false);
		})
	}

	useEffect(() => {
		var ids = (selectedValues ?? []).map(i => i.id);
		const updatedValues = values.map(i => {
			i.selected = ids.indexOf(i.id) != -1;
			return i;
		})
		setValues(updatedValues);
		setSelectedIds(ids);
		props.onAccountsSelect && props.onAccountsSelect(ids);
	}, [selectedValues])

	useEffect(() => {
		props.onAllAccountSelect && props.onAllAccountSelect(isSelectedAllIds)
	}, [isSelectedAllIds])

	const onValueSelect = (value) => {
		let attributeValues = [];
		if ((selectedValues && selectedValues.filter(i => i.id === value.id).length > 0) || isSelectedAllIds.includes("AllAccounts")) {
			attributeValues = selectedValues.filter(i => i.id !== value?.id);
			let ids=cloneDeep(attributeValues);
			if (isSelectedAllIds.includes("AllAccounts")) {
				attributeValues = values.filter(i => (i.id !== value?.id));
				onSelectAllValue(value)
			}
			if(value.type === "PARENT" && !ids.includes(value?.accountId) ){
				let parentAccount = cloneDeep(accountLists);
				parentAccount = parentAccount && parentAccount.filter((i)=>{return ((i.parentAccountId) === (value.id || value.accountId)) });
				parentAccount = parentAccount && parentAccount.map((i)=>{
					return i?.accountId
				})
				if(parentAccount?.length>0) {
					attributeValues = attributeValues.filter(({ accountId: id1 }) => !parentAccount.some(id2 => id2 === id1));
				}
			}
		} else {
			if (props.accountSelectOption === "singleSelect") {
				attributeValues = [];
				if (selectedValues && selectedValues.filter(i => i.name === value.name).length > 0) {
					attributeValues = []
				} else {
					attributeValues = [value]
				}
				setSelectedValues(attributeValues);
				setIsSelectedAllIds([]);
			}
			else {
				attributeValues = [...selectedValues, value] ?? [value];
				if(isSelectedAllIds.includes("AllParentAccounts") && value.type === 'PARENT'){
					attributeValues = values.filter( i => ((i.id !== value.id) && (i.type === value.type)))
					setIsSelectedAllIds([]);

				}
				if(value.type === "CHILD"){
					let parentAccount = cloneDeep(accountLists);
					parentAccount = parentAccount && parentAccount.filter((i)=>{return ((i.id || i.accountId) === value?.parentAccountId) })[0]
					if(parentAccount && !(selectedIds.includes(parentAccount?.id))) {
						attributeValues = [...selectedValues, value,parentAccount] ?? [value];
					}
				}
		}
	
		}
		attributeValues = attributeValues.map(i => { i.selected = true; return i; });
		setSelectedValues(attributeValues);
	}

	const onSegmentValueSelect = (value) => {
		let values = cloneDeep(selectedSegmentIds);
		if(selectedSegmentIds?.includes(value.id)){
			values = values && values.filter((i)=>{
				return (i !== value.id)
			})
		}else{
			values = [...values,value.id]
		}
		setselectedSegmentIds(values);
		props.onAccountSegmentsSelect(values)
	}
	const onSelectAllValue = (data) => {
		let allAccountIds = cloneDeep(isSelectedAllIds);
		if ((isSelectedAllIds.includes(data?.id) || isSelectedAllIds.includes("AllAccounts")) ) {
			if(data.id !==  'AllParentAccounts' && data.id !==  'AllChildAccounts'){
				allAccountIds = []
			}
			else{
				allAccountIds = allAccountIds.filter((i) => {
					return (i !== data?.id && i !== "AllAccounts")
				})
			}	
		} else {
			if(data?.id === "AllAccounts"){
				allAccountIds = ['AllAccounts']
			}else{
				allAccountIds = [...allAccountIds, data?.id]
			}
		}
		setIsSelectedAllIds(allAccountIds);
		setSelectedValues([]);
	}

	const isSlectedAccount = (data) => {
		if (isSelectedAllIds.includes("AllAccounts")) {
			return true
		}
		if (isSelectedAllIds.includes("AllParentAccounts") && data?.type === "PARENT") {
			return true
		}
		if (isSelectedAllIds.includes("AllChildAccounts") && data?.type === "CHILD") {
			return true
		}
		if (selectedIds.indexOf(data?.id) != -1) {
			return true
		}
		return false
	}
	const isSlectedAccountSegment  = (data) => {
		if (selectedSegmentIds.indexOf(data?.id) != -1) {
			return true
		}
		return false
	}
	const showChildsAccounts = (id) => {
		if (exapandesParentAccountIds.includes(id)) {
			let ids = cloneDeep(exapandesParentAccountIds);
			ids = ids && ids.filter((i) => {
				return (i !== id)
			})
			setExapandesParentAccountIds(ids);
			let accounts = cloneDeep(values);
			accounts = accounts && accounts.filter((i) => {
				return ((i?.parentAccountId) !== id)
			})
			setValues(accounts);
			let attributeValues = selectedValues.filter(i => i?.parentAccountId !== id);
			attributeValues = attributeValues.map(i => { i.selected = true; return i; });
			setSelectedValues(attributeValues);
		} else {
			setIsLodingApi(true);
			let ids = [...exapandesParentAccountIds, id];
			setExapandesParentAccountIds(ids)
			const request = { parentAccountId: id, path: props.selectedTab?.path, }
			smartFilterTokenService.globalFilterAccountSearchApi(request).then(response => {
				let accounts = response?.data?.data || [];
				accounts = accounts && accounts.map((i) => {
					return ({ id: i?.accountId, name: i.accountName, ...i })
				})
				accounts = [...values, ...accounts];
				setValues(accounts);
				setIsLodingApi(false);
			})
		}

	}
	const sortOpt = (data) => {
		let sortedProductList = data && data.sort((a, b) => {
			return ((a.name) || " ").localeCompare(((b.name) || " "));
		})
		return sortedProductList;
	}
	const getAccountsList = (data) => {
		let list = [];
		let childWithParentCustomerAccountIdAccounts = data && data.filter((i) => {
			return (i?.parentAccountId && i?.type === "CHILD");
		})
		let singularAccounts = data && data.filter((i) => {
			return (i?.type === "SINGULAR" || !i?.type);
		})
		singularAccounts = sortOpt(singularAccounts);
		data = sortOpt(data);
		data && data.map((ecahAccount) => {
			if (ecahAccount?.type === "PARENT") {
				list.push(ecahAccount);
			} else if (ecahAccount?.type === "CHILD") {
				if (ecahAccount?.parentAccountId) {
				} else {
					list.push(ecahAccount);
				}
			}
			if (ecahAccount?.type === "PARENT") {
				childWithParentCustomerAccountIdAccounts && childWithParentCustomerAccountIdAccounts.map((ecahChildAccount) => {
					if ((ecahChildAccount) && (ecahChildAccount.parentAccountId) && (ecahChildAccount?.parentAccountId === ecahAccount.accountId)) {
						list.push(ecahChildAccount);
					}
				})
			}
		})
		list = [...list, ...singularAccounts];
		list = list && list.reduce((unique, o) => {
			if (!unique.some(obj => obj.accountId === o.accountId)) {
				unique.push(o);
			}
			return unique;
		}, []);
		return list;
	}
	const accountLists = getAccountsList(values);
	const onChangesAccountSelection = useCallback((e)=>{
		setAccountSelection(e)
		props.onAccountSelction(e?.value)
	},[props.onAccountSelction])
	
	const segmentList = sortOpt(accountSegments);

	return (
		<>
			<SkLoaderComponent isLoading={ isLodingApi || isLodingSegmentsApi}/>
				<div>
					<div className="filter-dropdown-search-box-container global_filter_dropdown">
						<div className="account-selection--container">
								<SkSmartMultiSelectDropDown menuPosition={"absolute"} type="single_select" optionTooltipClass={"HWSelectMultiSelectTooltip grid_title__tooltip"} isDisabled={props.disableAccountSegmentFilter?true:false} onChange={(e) => {onChangesAccountSelection(e)  }}
								isSearchable={true} isRequired={false} value={accountSelection} isMultiSelect={false} options={accountSelectionOption} isClearable={false} className='' showArrowIcon={true} placeholderText={"-Select-"} blurInputOnSelect={true} />
						</div>
						<div className="filter-dropdown-search-box">
							<input placeholder={accountSelection?.value === "AccountSegment"?"Search Segment...":"Search Account..."} className="filter-dropdown-search-box-input" onChange={(e) => { setSearchText(e.target.value); }} />
						</div>
						{((accountLists && accountLists.length > 0 ) || (allAccountsList && allAccountsList.length > 0)) && accountSelection.value === "Manual" &&  <div className="filter-dropdown-search-box-options">
							<div className="filter-dropdown-search-box-options-container">
								{props?.accountSelectOption !== "singleSelect" && !searchText && allAccountsList.map(value => {
									const selected = (isSelectedAllIds.includes("AllAccounts") || isSelectedAllIds.indexOf(value.id) != -1);
									return (<div key={value.id} className={((selected) ? "filter-attribute-dropdown-option-selected" : "filter-attribute-dropdown-option")} onClick={() => { onSelectAllValue(value); }}>
										<div className={selected ? "filter-checkmark-container-selected" : "filter-checkmark-container-unselected"}><div className="filter-checkmark-tick"></div></div>
										{/* <div className="global__filter__account__icon__wrapper"></div> */}
										<div>{value.name}</div>
									</div>)
								})}
								{accountLists && accountLists.length>0 ?<>
								{accountLists.map((value, index) => {
									const selected = isSlectedAccount(value);
									return (
										<div /*style={{paddingLeft:value?.type === "CHILD"?"30px":""}}*/ key={value.id} className={((selected) ? "filter-attribute-dropdown-option-selected" : "filter-attribute-dropdown-option")} onClick={(e) => {
											if (props?.accountSelectOption === "singleSelect") {
												if (!selected) { onValueSelect(value); }
											} else { onValueSelect(value);}}}>
											{value?.type === "CHILD" && <div className="filter-attribute-dropdown-child-icon"></div>}
											<div style={{ width: "90%" }} className="d-flex align-items-center">
												{props?.accountSelectOption === "singleSelect" ?
													<div>
														<SkRadioBtn id={"radio" + index} setCheckValueDependsOnPropsValue={true} checked={selected ? true : false} value={value} onChange={(e) => {
															if (!selected) { onValueSelect(value); }
														}} name={"accountFilter" + index} />
													</div> : <div className={selected ? "filter-checkmark-container-selected" : "filter-checkmark-container-unselected"}>
														<div className="filter-checkmark-tick"></div>
													</div>}
												<div className="w-100" style={{ display: 'inline-flex' }}>
													{value?.type === "PARENT" && <div className="global__filter__account__icon__wrapper">
															<div className="global__filter__parent__account__icon"></div>
													</div>}
													<div className="global__filter__account__icon__text">
														<AutoUiLabel width={"90%"} placement="top" tooltipClass="HWSelectMultiSelectTooltip" label={value?.name} />
													</div>
												</div>
											</div>
											{value?.type === "PARENT" && <div className={` ${exapandesParentAccountIds.includes(value?.id) ? "filter__dropdown__arrow__icon_rotate" : ""} filter__dropdown__arrow__icon`} onClick={(e) => { e.stopPropagation(); e.preventDefault(); showChildsAccounts(value?.id) }}></div>}
										</div>)
								})}
								</>:<div className={`${!searchText?"hide1":""} filter-attribute-dropdown-option text-center justify-content-center`}>No data found</div>}
							</div></div>}
							{ accountSelection.value === "AccountSegment"  && 
							<div className="filter-dropdown-search-box-options">
								<div className="filter-dropdown-search-box-options-container">
									{segmentList && segmentList.map((value, index) => {
										const selected = isSlectedAccountSegment(value);
										return (
											<div key={value.id} className={((selected) ? "filter-attribute-dropdown-option-selected" : "filter-attribute-dropdown-option")} onClick={(e) => { onSegmentValueSelect(value) }}>
												<div style={{ width: "90%" }} className="d-flex align-items-center">
													<div className={selected ? "filter-checkmark-container-selected" : "filter-checkmark-container-unselected"}>
														<div className="filter-checkmark-tick"></div>
													</div>
													<div className="w-100" style={{ display: 'inline-flex' }}>
														<div className="global__filter__account__icon__text">
															<AutoUiLabel width={"90%"} placement="top" tooltipClass="HWSelectMultiSelectTooltip" label={value?.name} />
														</div>
													</div>
												</div>
											</div>)
									})}
								</div>
							</div>
							}
					</div>
				</div>
		</>
	)
}

const ProductSearch = (props) => {
	const [searchText, setSearchText] = useState("");
	const [products, setProducts] = useState(props?.productsList || []);
	const [selectedValues, setSelectedValues] = useState(props.selectedValues || []);
	const [selectedIds, setSelectedIds] = useState([]);
	const [isSelectedAllIds, setIsSelectedAllIds] = useState([]);

	useEffect(() => {
		setProducts(props.productsList)
	}, [props.productsList])

	useEffect(() => {
		if (props.selectedProductIds) {
			let ids = cloneDeep(props.selectedProductIds)
			const values = ids && ids.map(id => { return { "id": id, "name": "" }; });
			setSelectedValues(values);
		}
	}, [props.selectedProductIds])

	const onSelectAllValue = (data) => {
		let ids = cloneDeep(isSelectedAllIds);
		if (isSelectedAllIds.includes(data?.id) || isSelectedAllIds.includes("AllProducts")) {
			ids = ids.filter((i) => {
				return (i !== data?.id && i !== "AllProducts")
			})
		} else {
			ids = [...ids, data?.id]
		}
		setIsSelectedAllIds(ids);
		setSelectedValues([]);
	}
	var allServiceOrProduct = HWUser.productOrService() === "service" ? "All Services" : "All Products";
	const onValueSelect = (value) => {
		if (props.productSelectOption === "singleSelect") {
			let attributeValues = []
			if (selectedValues && selectedValues.filter(i => i.name === value.name).length > 0) {
				attributeValues = []
			} else {
				attributeValues = [value]
			}
			setSelectedValues(attributeValues);
			setIsSelectedAllIds([]);
		} else {
			if (isSelectedAllIds.includes("AllProducts")) {
				onSelectAllValue({ id: "AllProducts", name: allServiceOrProduct })
			}
			let attributeValues = (selectedValues && selectedValues.filter(i => i.id === value.id).length > 0)
				? selectedValues.filter(i => i.id !== value.id)
				: [...selectedValues, value] ?? [value];
			attributeValues = attributeValues.map(i => { i.selected = true; return i; });
			setSelectedValues(attributeValues);

		}
	}


	useEffect(() => {
		var ids = (selectedValues ?? []).map(i => i.id);
		const updatedValues = products && products.map(i => {
			i.selected = ids.indexOf(i.id) != -1;
			return i;
		})
		setProducts(updatedValues);
		setSelectedIds(ids);
		props.onProductsSelect && props.onProductsSelect(ids);
	}, [selectedValues])

	useEffect(() => {
		props.onAllProductSelect && props.onAllProductSelect(isSelectedAllIds)
	}, [isSelectedAllIds])

	useEffect(() => {
		const values = cloneDeep(props.selectedAllProductIds);
		setIsSelectedAllIds(values)
	}, [props.selectedAllProductIds])

	const customFilter = () => {
		let list = cloneDeep(products);
		if (searchText) {
			list = list && list.filter((i) => {
				return (i.name?.toLowerCase()?.includes(searchText?.toLowerCase()))
			})
		}
		return list
	}

	var allServiceOrProduct = HWUser.productOrService() === "service" ? "All Services" : "All Products";

	const productsLists = customFilter();
	const allProductList = [
		{ id: "AllProducts", name: allServiceOrProduct }
	]
	const isSlectedAccount = (data) => {
		if (isSelectedAllIds.includes("AllProducts")) {
			return true
		}
		if (selectedIds.indexOf(data?.id) != -1) {
			return true
		}
		return false
	}
	return (<div className="filter-dropdown-search-box-container global_filter_dropdown">
				{products?.length>6 && <div className="filter-dropdown-search-box">
            <input placeholder="Search..." className="filter-dropdown-search-box-input"  onChange={(e) => { setSearchText(e.target.value); }} />
      	</div>}
		{/* <div className={((selected) ? "filter-attribute-dropdown-option-selected" : "filter-attribute-dropdown-option")} onClick={() => { onValueSelect(product); }}>
            <div className={selected ? "filter-checkmark-container-selected" : "filter-checkmark-container-unselected"}><div className="filter-checkmark-tick"></div></div>
            <div className="w-100">
                All Product                           
            </div>
        </div> */}

		<div className="filter-dropdown-search-box-options">
			<div className="filter-dropdown-search-box-options-container" >
				{productsLists && productsLists.length>0 && !searchText && props?.productSelectOption !== "singleSelect" && allProductList.map(value => {
					const selected = (isSelectedAllIds.includes("AllProducts") || isSelectedAllIds.indexOf(value.id) != -1);
					return (<div key={value.id} className={((selected) ? "filter-attribute-dropdown-option-selected" : "filter-attribute-dropdown-option")} onClick={() => { onSelectAllValue(value); }}>
						<div className={selected ? "filter-checkmark-container-selected" : "filter-checkmark-container-unselected"}><div className="filter-checkmark-tick"></div></div>
						<div>{value.name}</div>
					</div>)
				})}
				{productsLists && productsLists.length>0?<>
				{productsLists.map(product => {
					const selected = isSlectedAccount(product);
					// return (<div onClick={() => { onValueSelect(product); }} key={product.productId} className="filter-dropdown-search-box-option"><div>{product.productName}</div></div>)
					return (<div key={product.id} className={((selected) ? "filter-attribute-dropdown-option-selected" : "filter-attribute-dropdown-option")} onClick={() => { onValueSelect(product); }}>
						{props?.productSelectOption === "singleSelect" ?
							<div>
								<SkRadioBtn checked={selected ? true : false} onChange={(e) => { onValueSelect(product); }} />
							</div> : <div className={selected ? "filter-checkmark-container-selected" : "filter-checkmark-container-unselected"}><div className="filter-checkmark-tick"></div></div>}
						<div className="w-100">
							<AutoUiLabel with={"90%"} placement="top" tooltipClass="HWSelectMultiSelectTooltip" label={product?.name} />
						</div>
					</div>)
				})}
				</>:<div className={`${!searchText?"hide1":""} filter-attribute-dropdown-option text-center justify-content-center`}>No data found</div>}
			</div></div>
	</div>)
}
