import axios from "axios";
import HWLocalStorage from "../HWLibrary/HWLocalStorage";
import HWUser from "../HWLibrary/HWUser";
export class OutlookAPIService {

	config = () => { return { headers: { "Authorization": HWUser.accessToken(), "Content-Type": "application/json" } } };
	emailServiceBaseURL = window.resolveRegion(process.env.REACT_APP_DASHBOARDS_BASE_API_URL) + "/email";

	constructor() {

		if (!OutlookAPIService.instance) {
			OutlookAPIService.instance = this;
		}
		return OutlookAPIService.instance;
	}

	mailBox = { inbox: "inbox", sent: "sent" };



	sendEmail = (fromId, toIds, ccIds, bccIds, subject, emailBody, attachments) => {
		var message = {
			appId: HWUser.appId(),
			userId: HWUser.userId(),
			orgId: HWUser.orgId(),
			from: fromId ? { name: "", address: fromId } : null,
			to: toIds
				? toIds.map((i) => {
					return { name: "", address: i };
				})
				: [],
			cc: ccIds
				? ccIds.map((i) => {
					return { name: "", address: i };
				})
				: [],
			bcc: bccIds
				? bccIds.map((i) => {
					return { name: "", address: i };
				})
				: [],
			subject: subject,
			text: emailBody,
			bodyContentType: "html",
			attachments: attachments || [],
		};

		const apiUrl = this.emailServiceBaseURL + "/send";
		let aConfig = { ...this.config() }
		return axios.post(apiUrl, message, aConfig).catch(error => { "Can't send now please try in some time" });
	};


	sendReplyEmail = (fromEmailId, toIds, ccIds, bccIds, subject, emailBody, attachedFiles, bodyContentType, messageId) => {
		var message = {
			appId: HWUser.appId(), userId: HWUser.userId(), orgId: HWUser.orgId(),
			//from: fromEmailId,
			to: toIds ? toIds.map((i) => { return i; }) : [],
			cc: ccIds,
			bcc: bccIds,
			subject: subject,
			text: emailBody,
			id: messageId,
			bodyContentType: "html",
		};

		const apiUrl = this.emailServiceBaseURL + "/reply";
		let aConfig = { ...this.config() }
		return axios.post(apiUrl, message, aConfig).catch(error => { "Can't send now please try in some time" });
	};

	sendReplyAllEmail = (fromEmailId, toIds, ccIds, bccIds, subject, emailBody, attachedFiles, bodyContentType, messageId) => {
		var message = {
			appId: HWUser.appId(), userId: HWUser.userId(), orgId: HWUser.orgId(),
			//from: fromEmailId,
			to: toIds
				? toIds.map((i) => {
					return i;
				})
				: [],
			cc: ccIds,
			bcc: bccIds,
			subject: subject,
			text: emailBody,
			id: messageId,
			bodyContentType: "html",
		};

		const apiUrl = this.emailServiceBaseURL + "/replyall";
		let aConfig = { ...this.config() }
		return axios.post(apiUrl, message, aConfig).catch(error => { "Can't send now please try in some time" });
	};

	sendForwardEmail = (fromEmailId, toIds, ccIds, bccIds, subject, emailBody, attachedFiles, bodyContentType, messageId) => {
		var message = {
			appId: HWUser.appId(), userId: HWUser.userId(), orgId: HWUser.orgId(),
			//from: fromEmailId, 
			// to: toIds.address === null ? [] : toIds.address,
			to: toIds
				? toIds.map((i) => { return i; })
				: [],
			cc: ccIds,
			bcc: bccIds,
			subject: subject,
			text: emailBody,
			id: messageId,
			bodyContentType: "html",
		};

		const apiUrl = this.emailServiceBaseURL + "/forward";
		let aConfig = { ...this.config() }
		return axios.post(apiUrl, message, aConfig).catch(error => { "Can't send now please try in some time" });
	};

	getMessagesForConversationId = (conversationId, mailType) => {

		const apiUrl = this.emailServiceBaseURL + "/messages";
		let aConfig = { ...this.config() }
		aConfig.params = { bodyContentType: "html", appId: HWUser.appId(), userId: HWUser.userId(), orgId: HWUser.orgId(), conversationId: conversationId, mailbox: mailType && mailType.toLowerCase() }
		return axios.get(apiUrl, aConfig).then(response => {
			return { threads: response.data.mails, nextToken: response.data.nextPage };
		});
	}

	scopes = [
		"https://graph.microsoft.com/Mail.ReadWrite",
		"https://graph.microsoft.com/Mail.Send",
		"offline_access",
		"https://graph.microsoft.com/Calendars.ReadWrite",
		"openid", "https://graph.microsoft.com/Files.ReadWrite.All",
		"profile"
	]

	requestScopeString = () => { return this.scopes.join(" ") }

	outlookOAuthURL = (encodedStateString) => {
		const baseURL = "https://login.microsoftonline.com/common/oauth2/v2.0/authorize";
		const scope = this.requestScopeString();//this.scopes.join(" ");
		const accessType = "response_type=code&grant_type=client_credentials";
		const clientIdURL = "redirect_uri=" + this.integrationCallbackURL()
			+ "&client_id=" + process.env.REACT_APP_OUTLOOK_APP_CLIENT_ID;
		// "&client_secret=" + process.env.REACT_APP_OUTLOOK_APP_SECRET_ID;
		return baseURL + "?scope=" + scope + "&" + accessType + (encodedStateString ? ("&state=integration") : "") + "&" + clientIdURL;
	}

	integrationCallbackURL = () => { return window.origin + "/outlook/oauth/callback"; }


	oAuthTriggerBaseURL = window.resolveRegion(process.env.REACT_APP_PLATFORM_BASE_API_URL_WEB_ADMIN) + "/webapp/oauth-pop-up-trigger";
	savePopUpTriggerStatus = () => {
		let aConfig = { headers: { "Authorization": HWUser.accessToken(), "Content-Type": "application/json" } }
		const getData = { userId: HWUser.userId(), integrationName: 'OUTLOOK', popUpTriggred: true }
		return axios.post(this.oAuthTriggserBaseURL, getData, aConfig)
	}





	// Local
	updateLocalCacheWithOutlookToken = (accessToken, expiryTime, outlookUserEmailId) => {
		try {
			let credentialsId = "sk-outlook-credentials" + HWUser.userId() + HWUser.appId();
			const tokenDataString = JSON.stringify({ "accessToken": accessToken, "expiryTime": expiryTime, "outlookUserEmailId": outlookUserEmailId });
			HWLocalStorage.save(tokenDataString, credentialsId);
		} catch (error) { return error }
	}

	clearOutlookCache = () => {
		HWLocalStorage.clearWithPrefix("sk-outlook");
		this.revokeOutlookClient();
	}

	revokeOutlookClient = () => {
		window.gapi.client.setToken({ access_token: null });
		this.accessToken = null;
	}

	// Outlook Integration Configuration.
	// scopes = [
	//     "https://graph.microsoft.com/Mail.ReadWrite",
	//     "https://graph.microsoft.com/Mail.Send" ,
	//     "offline_access"
	// ]

	// outlookOAuthURL = (encodedStateString) => { 
	//     const baseURL     = "https://login.microsoftonline.com/common/oauth2/v2.0/authorize";
	//     const scope       = this.scopes.join(" ");
	//     const accessType  = "response_type=code&grant_type=client_credentials";
	//     const clientIdURL = "redirect_uri=" + this.integrationCallbackURL() 
	//         + "&client_id=" + process.env.REACT_APP_OUTLOOK_APP_CLIENT_ID + "&client_secret=" + process.env.REACT_APP_OUTLOOK_APP_SECRET_ID;
	//     return baseURL + "?scope=" + scope + "&" + accessType + (encodedStateString ? ("&state=integration") : "") + "&" + clientIdURL;
	// }

	// integrationCallbackURL = () => { return window.origin + "/outlook/oauth/callback"; }

	loadOutLookClient = () => {
		return new Promise((resolve, reject) => {
			if (!this.accessToken) { reject("Outlook integration is not done."); return; }
			const accessToken = this.accessToken
			window.gapi && window.gapi.client && window.gapi.client.setToken({ access_token: accessToken });
			window.gapi && window.gapi.client && window.gapi.client.load('outlook', 'v1', () => { resolve(); });
		});
	}

}

var outlookAPIService = new OutlookAPIService();

export default outlookAPIService;