import React from 'react';
import SmartCommentsList from './SmartCommentsList';
import {fetchSmartCommentsList, getSmartCommentStates, setSmartCommentStates, fetchSmartCommentsListForKommunity} from '../../store/reducers/skCommentsReducer';
import { useDispatch, useSelector } from 'react-redux';

const SkSmartCommentWithReply = ({feature, replyNextToken, replyCount,replies, setPopUpRefNumber, popUpRefNumber, featureId, setIsInsidePopperOn, parentCommentId, ...props}) => {
	const dispatch = useDispatch();
	const getSmartCommentState = useSelector(getSmartCommentStates);
	const { smartCommentsList, smartKommunityCommentsList} = getSmartCommentState;

	const fetchKommunityReply = () => {
		dispatch(fetchSmartCommentsList(featureId, null, null, {parentCommentId: parentCommentId,replyNextToken: replyNextToken})).then((response) => {
			let kommunityCommentList = smartKommunityCommentsList;
			let commentList = kommunityCommentList[featureId];
			let parentComment = commentList?.commentResponses?.find(comment => comment?.parentComment?.commentId === parentCommentId) || [];
			let childComment = parentComment?.replyList?.replies ? parentComment?.replyList?.replies : [];
			let repliesMap = parentComment?.replyList || {};
			repliesMap["nextToken"] = response?.nextToken;
			repliesMap["replies"] = [...childComment, ...response?.replies];
			parentComment["replyList"] = repliesMap;
			kommunityCommentList[featureId] = commentList;
			dispatch(setSmartCommentStates({ smartCommentsList: kommunityCommentList }))
		})
	}

  const fetchReplies = () => {
		dispatch(fetchSmartCommentsList(featureId, null, null, {parentCommentId: parentCommentId,replyNextToken: replyNextToken})).then((response) => {
			let commentList = [...smartCommentsList];
			let parentComment = commentList.find(comment => comment?.parentComment?.commentId === parentCommentId) || [];
			let childComment = parentComment?.replyList?.replies ? parentComment?.replyList?.replies : [];
			let repliesMap = parentComment?.replyList || {};
			repliesMap["nextToken"] = response?.nextToken;
			repliesMap["replies"] = [...childComment, ...response?.replies];
			parentComment["replyList"] = repliesMap;
			dispatch(setSmartCommentStates({ smartCommentsList: commentList }))
		})
	}

	return (
		<div className="replied--comment">
			{replies?.map(reply => {
				return(
					<SmartCommentsList {...props} isScroll = {props?.isScroll} feature={feature} parentCommentId={parentCommentId} skComment={reply} setPopUpRefNumber={setPopUpRefNumber} popUpRefNumber={popUpRefNumber} featureId={featureId} smartComment={reply} setIsInsidePopperOn={setIsInsidePopperOn} />
				)
			})}
			{(replyCount > replies.length) && <div className='load--more-comment' onClick={e => {feature !=='KnkPost' ? fetchReplies(): fetchKommunityReply()}}>
					  <div className='load--more-comment--text'>Load More Replies</div>
			 </div>}
		</div>
				


	);
}

export default SkSmartCommentWithReply;
