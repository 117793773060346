import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import HWUser from '../../HWLibrary/HWUser';
import SkLoaderComponent from '../../SkSmartUtil/SkLoader/SkLoaderComponent';
import SKSmartUiBtns from '../SKSmartUiBtns/SKSmartUiBtns';
import SmartConfirmModal from '../SmartConfirmModal/SmartConfirmModal';
import { fetchOrganizationUsers, fetchSmartCommentsList, fetchSmartCommentsListForKommunity, getSmartCommentStates, setSmartCommentStates } from '../../store/reducers/skCommentsReducer';
import SkSmartCommentWithReply from './SkSmartCommentWithReply';
import './SkSmartComments.css';
import SmartCommentMention from './SmartCommentMention';
import SmartCommentsList from './SmartCommentsList';



const SkSmartComments = ({ featureId, isDisabled, integrationType, feature, setIsInsidePopperOn, closeCommentPopupCallBack, tokenCommentId,isLoadingCommentData, ...props }) => {
	const smartCommentObj = {
		integrationType: integrationType,
		timeZone: moment.tz.guess(),
		appId: HWUser.appId(),
		commentedBy: HWUser.userId(),
		featureId: featureId,
		feature: feature,
		commentBody: '',
		taggedUsers: []
	}
	const [state, setState] = useState({
		showOnlyOkAction: false,
		showWarningPopUp: false,
		showWarningMsg: false,
		isLoading: false,	
	})

	// redux implementations
	const getSmartCommentState = useSelector(getSmartCommentStates);
	const { smartCommentsList, smartComment, smartUsersList, smartCommentNextToken,isCommentLoading,smartKommunityCommentsList } = getSmartCommentState;
	const [currentComment, setCurrentComment] = useState('');
	const [isScrolling, setIsScrollling] = useState(props?.isScroll);
	const dispatch = useDispatch();

	useEffect(()=>{
		if(featureId && feature !== 'KnkPost'){
			dispatch(fetchSmartCommentsList(featureId));
		}
	}, [dispatch, featureId, feature])


  useEffect(()=>{
		if(featureId && feature === 'KnkPost'){
			dispatch(fetchSmartCommentsListForKommunity(featureId));
		}
	}, [dispatch, featureId, feature])
	
	useEffect(() => {
		
		if (tokenCommentId && smartCommentsList && smartCommentsList?.length > 0) {
			let filteredComment = smartCommentsList?.find(x => x?.commentId === tokenCommentId);
			if (filteredComment) {
				const indexToMove = smartCommentsList?.findIndex(obj => obj.commentId === tokenCommentId);
				if (indexToMove !== -1) {
					const movedObject = smartCommentsList?.splice(indexToMove, 1)[0];
					smartCommentsList?.unshift(movedObject);
				}
			}
		}
	}, [smartCommentsList, tokenCommentId])
	

	useEffect(() => {
		dispatch(setSmartCommentStates({ smartComment: smartCommentObj }))
		dispatch(fetchOrganizationUsers(smartUsersList));
		return () => {	
			dispatch(setSmartCommentStates({ smartCommentsList: []}))
		}
	}, [])

	useEffect(()=>{
		props.actions && props.actions.setCommentCount && props.actions.setCommentCount(smartCommentsList?.length)
	},[smartCommentsList])


	const handleWarningMsgsForPopUp = (onlyOk, popupState, msg) => { setStatesData({ showOnlyOkAction: onlyOk, showWarningPopUp: popupState, showWarningMsg: msg }) }
	const onOkConfirmation = () => {
		if (state.showWarningMsg === "You have unsaved changes.<br/>Do you wish to continue?") {
			closeCommentPopupCallBack();
		}
	}
	const onCancelConfirmation = () => {
		handleWarningMsgsForPopUp(false, false, '');
	}



	const onCancelComment = () => {
		if(currentComment){
			handleWarningMsgsForPopUp(false, true, `You have unsaved changes.<br/>Do you wish to continue?`);
		} else { closeCommentPopupCallBack(); }
	}

	const setStatesData = (updatedState) => {
		setState(prevState => (
			{
				...prevState,
				...updatedState
			}
		))
	}

	const commentActionBtns = {
		secondaryBtnLabel: 'OK',
		secondaryBtnAction: onCancelComment
	}

	const scrolling = () => {
    setIsScrollling(true);
		setTimeout(() => {
			setIsScrollling(false);
		},2000)
	}

	let newimage;
	let user = (JSON.parse(window.localStorage.getItem('platform-currentUser')))
	if (user && user.user && user.user.profileImageURL) { newimage = user.user.profileImageURL }
	const [popUpRefNumber, setPopUpRefNumber] = useState(null);
	const kommunityComment = smartKommunityCommentsList[featureId];
	const kommunityCommentToShow = kommunityComment?.commentResponses;


	useEffect(() =>{
		if (props?.childRef) {
			props.childRef.onCancelComment = onCancelComment
		}
	},[onCancelComment, props.childRef])


	return (
		<>
		{/* <BlockUi blocking={isCommentLoading} style={{height:"100%"}}> */}
		<SkLoaderComponent isLoading={isCommentLoading || isLoadingCommentData}/>
			<div className='smart__comments__container' id="smart__comments__container--id_wrap">
				<SmartCommentMention isScroll={isScrolling} onCommentCallBack={props.onCommentCallBack} featureId={featureId} smartComment={smartComment} action="create" setIsInsidePopperOn={setIsInsidePopperOn} setCurrentComment={setCurrentComment} feature={feature}/>
				{feature !=='KnkPost' && <div id="smart__comment__list" className='smart__commments__container__bottom__list' onScroll={e => scrolling()}>
					{smartCommentsList && smartCommentsList?.length > 0 && smartCommentsList.map(smartComment => {
						return (
						<div>
						<SmartCommentsList isScroll={isScrolling}  feature={feature} replyCount= {smartComment?.replyCount} skComment={smartComment?.parentComment}   parentCommentId={smartComment?.parentComment?.commentId} setPopUpRefNumber={setPopUpRefNumber} popUpRefNumber={popUpRefNumber} featureId={featureId} smartComment={smartComment?.parentComment} setIsInsidePopperOn={setIsInsidePopperOn} {...props}/>
						{smartComment?.replyList?.replies?.length > 0 && <SkSmartCommentWithReply isScroll={isScrolling} feature={feature} parentCommentId={smartComment?.parentComment?.commentId} replyNextToken={smartComment?.replyList?.nextToken}  replies={smartComment?.replyList?.replies} replyCount= {smartComment?.replyCount} setPopUpRefNumber={setPopUpRefNumber} popUpRefNumber={popUpRefNumber} featureId={featureId} smartComment={smartComment} setIsInsidePopperOn={setIsInsidePopperOn} {...props}/>}
						</div>)
					})}
					{!isCommentLoading && smartCommentNextToken && <div className='load--more-comment' onClick={e => {dispatch(fetchSmartCommentsList(featureId, null, smartCommentNextToken));}}>
					  <div className='load--more-comment--text'>Load More Comments</div>
				  </div>}
				</div>}
				{feature ==='KnkPost' && <div id="smart__comment__list" className='smart__commments__container__bottom__list' onScroll={e => scrolling()}>
					{kommunityCommentToShow && kommunityCommentToShow?.length > 0 && kommunityCommentToShow.map(smartComment => {
						return (
						<div>
						<SmartCommentsList feature={feature} isScroll={isScrolling} skComment={smartComment?.parentComment} replyCount= {smartComment?.replyCount} parentCommentId={smartComment?.parentComment?.commentId} setPopUpRefNumber={setPopUpRefNumber} popUpRefNumber={popUpRefNumber} featureId={featureId} smartComment={smartComment?.parentComment} setIsInsidePopperOn={setIsInsidePopperOn} {...props}/>
						{smartComment?.replyList?.replies?.length > 0 && <SkSmartCommentWithReply isScroll={isScrolling} feature={feature} parentCommentId={smartComment?.parentComment?.commentId} replyNextToken={smartComment?.replyList?.nextToken}  replies={smartComment?.replyList?.replies} replyCount= {smartComment?.replyCount} setPopUpRefNumber={setPopUpRefNumber} popUpRefNumber={popUpRefNumber} featureId={featureId} smartComment={smartComment} setIsInsidePopperOn={setIsInsidePopperOn} {...props}/>}
						</div>)
					})}
					{!isCommentLoading && kommunityComment?.nextToken && <div className='load--more-comment' onClick={e => {dispatch(fetchSmartCommentsListForKommunity(featureId, null, kommunityComment?.nextToken));}}>
					  <div className='load--more-comment--text'>Load More Comments</div>
				  </div>}
				</div>}
			</div>
			{feature !== "KnkPost" && feature !== "SmartKonversation" && <div className='smart__side__popup__form__details__container__row-footer'>
				<SKSmartUiBtns  {...commentActionBtns} className={'smart__ui__btns'} />
			</div>}
			{state.showWarningPopUp && <SmartConfirmModal showOnlyOkButton={state.showOnlyOkAction} onlyOkAction={onOkConfirmation} className="asDeleteModel" msg={state.showWarningMsg} okClickCallback={onOkConfirmation} callBackNotRequired={true} cancelClick={onCancelConfirmation} headtxt="Delete" targetObject={state?.showWarningPopUp} />}
			{/* </BlockUi> */}
		</>
	)
}

export default SkSmartComments
