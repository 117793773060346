import { store } from "../index";
import { skAccountListingActions } from "../constants/actionType";
import HWUser from "../../HWLibrary/HWUser";
import { apiCall } from "../api";
import { cloneDeep } from "lodash";
import cache from "../../SkSmartUtil/InMemoryCache";
import axios from "axios";
import hwAxios, { getCurrentFilterDataBase64Enncoded, getCurrentSmartDefaultFilterDataBase64Enncoded } from "../../HWLibrary/HWAxios";
import skExpansionAnalyserService from "../../KeyGrowthIndicator/ExapansionModule/SkExpansionAnalyserService";

export const setSmartAccountListingStates = (obj) => async dispatch => {
	dispatch({
		type: skAccountListingActions?.SET_SMART_ACCOUNT_LISTING_STATES, payload: obj
	})
}



export const getSmartPortfolioStates = () => {
	return store.getState()?.skPortfolioReducer;
}


export const fetchSmartAccountListingViewSummary = (accountId, fetchType, nextToken, sortedBy, sortMode, path, requestData) => async dispatch => {
	let urlPath = path === 'churn_dashboard' ? '/smart-account/churn/list' : '/smart-account/list';
	let request = {};
	requestData = requestData?requestData:{};
	request = { 
		orgId: HWUser.orgId(), appId: HWUser.appId(),
		 userId: HWUser.userId(), accountId: accountId, fetchType: fetchType, 
		 nextToken: nextToken,
		 sortMode:sortMode,
		//  sortOrder: (sortingOrder)=== "asc"?"ascending":(sortingOrder)=== "desc"?"descending":null,
		 gtmatrics: localStorage.getItem("auth") ? true : false,
		 sortList:sortedBy,
		 path:path,
		 ...requestData
	};	
	dispatch(setSmartAccountListingStates({ isAccountListingViewLoading: true,isLoadingOrgCounts:true }))
	try {
		const response = await apiCall({
			url: window.resolveRegion(process.env.REACT_APP_SMART_DASHBOARDS_BASE_URL) +urlPath,
			method: 'POST',
			payload: request,
			addFilter: true
		});
		let data = [],accountListDetails=[];
		if (accountId && fetchType === "Product") {
			let data1 = [...getSmartPortfolioStates().accountListingData];
			let data2 = cloneDeep(response?.data?.data);
			data2 = data2 && data2.map((i, index) => {
				// if (index !== 0) {
					
				// } 
				// else { i.productCount = data2.length; return i }
				i.fetchType = "Product"; return (i)
			})
			data1 && data1.map((eachAccount) => {
				data.push(eachAccount)
				if (eachAccount.accountId === accountId) {
					data = [...data, ...data2]
				} 
				// else {
				// 	data.push(eachAccount)
				// }
			})
			accountListDetails= [...getSmartPortfolioStates().accountListDetails];
		} else if (accountId && fetchType=== "Child" ){
			let data1 = [...getSmartPortfolioStates().accountListingData];
			let data2 = cloneDeep(response?.data?.data);
			let flag=false;
			data1 && data1.map((eachAccount)=>{
				if(eachAccount && eachAccount.accountType === "PARENT" && eachAccount.accountId === accountId && !flag){
						let parentAccounts = data1 && data1.filter((i)=>{ return (i.accountId === eachAccount.accountId) })
						if(parentAccounts?.length>1){
							parentAccounts && parentAccounts.map((eachParentAccount,index)=>{
								if(index === (parentAccounts.length -1)){
									data.push(eachParentAccount);
									data = [...data, ...data2]
									flag=true;
								}else{
									data.push(eachParentAccount);
								}
							})
						}else if(eachAccount && eachAccount.accountId === accountId){
							data.push(eachAccount);
							data = [...data, ...data2]
							flag=true;
						}
				}else if(eachAccount?.accountId !== accountId){
					data.push(eachAccount)
				}
			})
			accountListDetails= [...getSmartPortfolioStates().accountListDetails, ...response?.data?.data];
		}else if (accountId || nextToken) {
			data = [...getSmartPortfolioStates().accountListingData, ...response?.data?.data];
			accountListDetails= [...getSmartPortfolioStates().accountListDetails, ...response?.data?.data];
		} else {
			data = [...response?.data?.data];
			accountListDetails = [...response?.data?.data];
		}
		// data = getAccountsList(data);
		let accountListingNextTokenFisrstToken = getSmartPortfolioStates().accountListDetails?getSmartPortfolioStates().accountListDetails:response?.data?.nextToken;
		dispatch(setSmartAccountListingStates({ isAccountListingViewLoading: false, isLoadingOrgCounts:false,accountListingData: data, accountListingNextTokenFisrstToken: accountListingNextTokenFisrstToken, accountListingNextToken: response?.data?.nextToken, currency: response?.data?.currency, financialModel: response?.data?.financialModel, orderModel: response?.data?.orderModel,accountListDetails:accountListDetails }))
	} catch (error) {
		dispatch(setSmartAccountListingStates({ isAccountListingViewLoading: false ,isLoadingOrgCounts:false}))
	}
}

export const fetchSmartAccountManagementsListingViewSummary = (accountId, fetchType, nextToken, sortedBy, sortMode, path, requestData) => async dispatch => {
	let urlPath = path === 'churn_dashboard' ? '/smart-account/churn/list' : '/smart-account/list';
	let request = {};
	requestData = requestData?requestData:{};
	request = { 
		orgId: HWUser.orgId(), appId: HWUser.appId(),
		 userId: HWUser.userId(), accountId: accountId, fetchType: fetchType, 
		 nextToken: nextToken,
		 sortMode:sortMode,
		//  sortOrder: (sortingOrder)=== "asc"?"ascending":(sortingOrder)=== "desc"?"descending":null,
		 sortList:sortedBy,
		path: path,
		filterToken: getCurrentFilterDataBase64Enncoded(),
		smartFilterToken: getCurrentSmartDefaultFilterDataBase64Enncoded(null, "account_management"), 
		 ...requestData
	};	
	dispatch(setSmartAccountListingStates({ isAccountListingViewLoading: true,isLoadingOrgCounts:true }))
	try {
		let url = window.resolveRegion(process.env.REACT_APP_SMART_DASHBOARDS_BASE_URL) +urlPath;
	
		hwAxios.post(url, request).then(response => {
			let data = [],accountListDetails=[];
			if (accountId && fetchType === "Product") {
				let data1 = [...getSmartPortfolioStates().accountListingData];
				let data2 = cloneDeep(response?.data?.data);
				data2 = data2 && data2.map((i, index) => {
					// if (index !== 0) {
						
					// } 
					// else { i.productCount = data2.length; return i }
					i.fetchType = "Product"; return (i)
				})
				data1 && data1.map((eachAccount) => {
					data.push(eachAccount)
					if (eachAccount.accountId === accountId) {
						data = [...data, ...data2]
					} 
					// else {
					// 	data.push(eachAccount)
					// }
				})
				accountListDetails= [...getSmartPortfolioStates().accountListDetails];
			} else if (accountId && fetchType=== "Child" ){
				let data1 = [...getSmartPortfolioStates().accountListingData];
				let data2 = cloneDeep(response?.data?.data);
				let flag=false;
				data1 && data1.map((eachAccount)=>{
					if(eachAccount && eachAccount.accountType === "PARENT" && eachAccount.accountId === accountId && !flag){
							let parentAccounts = data1 && data1.filter((i)=>{ return (i.accountId === eachAccount.accountId) })
							if(parentAccounts?.length>1){
								parentAccounts && parentAccounts.map((eachParentAccount,index)=>{
									if(index === (parentAccounts.length -1)){
										data.push(eachParentAccount);
										data = [...data, ...data2]
										flag=true;
									}else{
										data.push(eachParentAccount);
									}
								})
							}else if(eachAccount && eachAccount.accountId === accountId){
								data.push(eachAccount);
								data = [...data, ...data2]
								flag=true;
							}
					}else if(eachAccount?.accountId !== accountId){
						data.push(eachAccount)
					}
				})
				accountListDetails= [...getSmartPortfolioStates().accountListDetails, ...response?.data?.data];
			}else if (accountId || nextToken) {
				data = [...getSmartPortfolioStates().accountListingData, ...response?.data?.data];
				accountListDetails= [...getSmartPortfolioStates().accountListDetails, ...response?.data?.data];
			} else {
				data = [...response?.data?.data];
				accountListDetails = [...response?.data?.data];
			}
			// data = getAccountsList(data);
			let accountListingNextTokenFisrstToken = getSmartPortfolioStates().accountListDetails?getSmartPortfolioStates().accountListDetails:response?.data?.nextToken;
			dispatch(setSmartAccountListingStates({ isAccountListingViewLoading: false, isLoadingOrgCounts:false,accountListingData: data, accountListingNextTokenFisrstToken: accountListingNextTokenFisrstToken, accountListingNextToken: response?.data?.nextToken, currency: response?.data?.currency, financialModel: response?.data?.financialModel, orderModel: response?.data?.orderModel,accountListDetails:accountListDetails }))
		});


	
	} catch (error) {
		dispatch(setSmartAccountListingStates({ isAccountListingViewLoading: false ,isLoadingOrgCounts:false}))
	}
}


const fetchSegments = () => {
	const cacheKey = HWUser.userId() + "segments";
	const fetchFunction = () => {
		let url = window.resolveRegion(process.env.REACT_APP_DASHBOARDS_BASE_API_URL) + "/multi-product/account/reference-data/account-attributes";
		let config = { headers: { "Authorization": HWUser.accessToken(), "Content-Type": "application/json" } };
		config.params = { appId: HWUser.appId(), orgId: HWUser.orgId(), userId: HWUser.userId() }
		return axios.get(url, config);
	}
	return cache.get(cacheKey, cache.CacheModule.segments, fetchFunction);
}



export const fetchAccountSegmentDashboardData= (requestData)=>async dispatch=>{
	let request = {}
	request.params = { orgId: HWUser.orgId(), appId: HWUser.appId(), userId: HWUser.userId() ,...requestData};
	dispatch(setSmartAccountListingStates({ isLoadingSegement: true }))
	try {
		const response = await apiCall({
			url: window.resolveRegion(process.env.REACT_APP_SMART_DASHBOARDS_BASE_URL) + "/smart-account-segment/dashboard",
			method: 'GET',
			payload: request,
			addFilter: true
		});
		let data = [],nextFirst = getSmartPortfolioStates().accountSegmentFirstNextToken || null
		if(requestData?.nextToken){
			data = [...getSmartPortfolioStates().accountSegmentsDashboardData, ...response?.data?.data];
		}else{
			data = [...response?.data?.data];
			nextFirst=response?.data?.nextToken || getSmartPortfolioStates().accountSegmentFirstNextToken 
		}
		dispatch(setSmartAccountListingStates({isCreatingSegments:false, isLoadingSegement: false,accountSegmentsDashboardData:data || [],accountSegmentNextToken:response?.data?.nextToken, accountSegmentFirstNextToken:nextFirst, currency: response?.data?.currency }))
	} catch (error) {
		dispatch(setSmartAccountListingStates({ isLoadingSegement: true, accountSegmentsDashboardData: [] }))
	}
}

export const duplicateSegment = (data) => async dispatch => {
	let request = { orgId: HWUser.orgId(), appId: HWUser.appId(), userId: HWUser.userId(), ...data };
	dispatch(setSmartAccountListingStates({ isCreatingSegments: true}))
	try { 
		const response = await apiCall({
			url: window.resolveRegion(process.env.REACT_APP_SMART_DASHBOARDS_BASE_URL)+'/smart-account-segment/duplicate',
			method: "POST",
			payload: request,
		});
			setSmartAccountListingStates({isCreatingSegments:false,})
			return 'Segments Updated Successfully.'
	} catch (error) {
		dispatch(setSmartAccountListingStates({ isCreatingSegments:false, }))
	}
}

// fetch segments data 
export const fetchSegmentsData = () => async dispatch => {
	dispatch(setSmartAccountListingStates({ isLoadingSegments: true }))
	try {
		fetchSegments().then(response => {
			dispatch(setSmartAccountListingStates({ isLoadingSegments: false, segmentsData: response?.data }))
		})
	} catch (error) {
		dispatch(setSmartAccountListingStates({ isLoadingSegments: false }))
	}
}
export const fetchAccountOrgCounts = (requestData)=>async dispatch=>{
	let request = {}
	request.params = { orgId: HWUser.orgId(), appId: HWUser.appId(), userId: HWUser.userId() ,...requestData};
	dispatch(setSmartAccountListingStates({ isLoadingOrgCounts: true }))
	try {
		const response = await apiCall({
			url: window.resolveRegion(process.env.REACT_APP_SMART_DASHBOARDS_BASE_URL) + "/smart-account/count",
			method: 'GET',
			payload: request,
			addFilter: true
		});

		dispatch(setSmartAccountListingStates({ isLoadingOrgCounts: false,listingOrgLevelCounts:response?.data ,displayExpandedView:response?.data?.displayExpandedView}))
	} catch (error) {
		dispatch(setSmartAccountListingStates({ isLoadingOrgCounts: false, listingOrgLevelCounts: {} }))
	}
}

export const fetchUtilizations = (accountId) => async dispatch => {
	let request = {}
	request.params = { orgId: HWUser.orgId(), appId: HWUser.appId(), userId: HWUser.userId(), accountId: accountId };
	dispatch(setSmartAccountListingStates({ isLoadingUtilizations: true }))
	try {
		const response = await apiCall({
			url: window.resolveRegion(process.env.REACT_APP_SMART_DASHBOARDS_BASE_API_URL) + "/finance/utilization",
			method: 'GET',
			payload: request,
		});

		dispatch(setSmartAccountListingStates({ isLoadingUtilizations: false, utilizationsDetails: response?.data?.subscriptions, selectedAccount: accountId }))
	} catch (error) {
		dispatch(setSmartAccountListingStates({ isLoadingUtilizations: true, utilizationsDetails: [] }))
	}
}

export const fetchAccountSegmentsList = (data) => async dispatch => {
	dispatch(setSmartAccountListingStates({ isLoadingUtilizations: true }))
	try {
		const response = await skExpansionAnalyserService.fetchSegments()
		dispatch(setSmartAccountListingStates({ accountSegments: response?.data?.accountSegments || [] }))
	} catch (error) {
		dispatch(setSmartAccountListingStates({ accountSegments: [] }))
	}
}


export const fetchRenewals = (accountId, productId) => async dispatch => {
	let request = {}
	request.params = { orgId: HWUser.orgId(), appId: HWUser.appId(), userId: HWUser.userId(), accountId: accountId, productId:productId };
	dispatch(setSmartAccountListingStates({ isLodingRenewalsData: true }))
	try {
		const response = await apiCall({
			url: window.resolveRegion(process.env.REACT_APP_SMART_DASHBOARDS_BASE_API_URL) + "/finance/renewal",
			method: 'GET',
			payload: request,
		});

		dispatch(setSmartAccountListingStates({ isLodingRenewalsData: false, subscriptionsDetails: response?.data?.subscriptions, selectedAccount: accountId }))
	} catch (error) {
		dispatch(setSmartAccountListingStates({ isLodingRenewalsData: true, subscriptionsDetails: [] }))
	}
}

export const fetchFinancialModelData = (accountId, productId, timeInterval, orderId, callingFrom, orderDetails, sortedBy, sortingOrder) => async dispatch => {
	let request = {}
	if (callingFrom === 'Account360') {
		request.params = {
			appId: HWUser.appId(), userId: HWUser.userId(), orgId: HWUser.orgId(),		
			date: orderDetails?.orderDate ? orderDetails?.orderDate : null,
			sortOrder: (sortingOrder)=== "asc"?"ascending":(sortingOrder)=== "desc"?"descending":null,
		 sortField:sortedBy,
		};
	} else {
		request.params = {
			appId: HWUser.appId(), userId: HWUser.userId(), orgId: HWUser.orgId(),
			accountId: accountId, productId: productId !== "All" ? productId : null, timeInterval: timeInterval ? timeInterval : "ALL", orderId: orderId ? orderId : null,
			date: orderDetails?.orderDate ? orderDetails?.orderDate : null
		};
	}
	let requestUrl = callingFrom === 'Account360' ? window.resolveRegion(process.env.REACT_APP_SMART_DASHBOARDS_BASE_URL) + '/account-view/dashboard/arr-trend/detail' :
		window.resolveRegion(process.env.REACT_APP_SMART_DASHBOARDS_BASE_API_URL) + "/finance/pop-up";
	dispatch(setSmartAccountListingStates({ isLodingFinancialModelData: true }))
	try {
		const response = await apiCall({
			url: requestUrl,
			method: 'GET',
			payload: request,
			addFilter: callingFrom === 'Account360' ? true : false
		});

		dispatch(setSmartAccountListingStates({
			isLodingFinancialModelData: false, financialModelOrdersData: response?.data?.orders,  financialModelOpportunitiesData: response?.data?.opportunities, financialModelInvoicesData: response?.data?.invoices
		}))
	} catch (error) {
		dispatch(setSmartAccountListingStates({ isLodingFinancialModelData: true, financialModelOrdersData: [], financialModelInvoicesData: [], financialModelOpportunitiesData: [] }))
	}
}
export const deleteSegment = (data) => async dispatch => {
	let request = { orgId: HWUser.orgId(), appId: HWUser.appId(), userId: HWUser.userId(), ...data };
	dispatch(setSmartAccountListingStates({ isCreatingSegments: true }))
	try { 
		const response = await apiCall({
			url: window.resolveRegion(process.env.REACT_APP_SMART_DASHBOARDS_BASE_URL) + '/smart-account-segment/status',
			method: "PUT",
			payload: request,
		});
		dispatch(setSmartAccountListingStates({ isCreatingSegments: false }))
			return response?.data
	} catch (error) {
		dispatch(setSmartAccountListingStates({ isCreatingSegments: false }))
		return error?.response?.data?.message
	}
}