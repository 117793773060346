import { skContactActions } from "../constants/actionType";

const initialState = {
	smartContact: {
		appId: window.localStorage.getItem("platform-currentUser")?.userAppPreferences?.appId,
		userId: window.localStorage.getItem("platform-currentUser")?.user?.userId,
		firstName:'',
		lastName: '',
		email: '',
		phone: '', 
		selectedCountryCode: '', 
		uploadedImageUrl: [],
		role: '',
		accountId: null ,
		productIds: [] , 
		isOptOut: false,
		keyStakeholderIds: [],
		isAddingContact:false,
		showSkeleton: false,
	},
	contacts: null,
	isLoading: false,
	isError: false,
	successMsg: '',
	errorMessage: '',
	nextToken: null,
	previousToken:null,
	totalCount:'',
	msgToShow:'',
	referenceAccountList: [],
	referenceProductList: [],
	referenceKeystakeholderList: [],
}


export const skContactActionReducer = (state = initialState, { type , payload }= {}) => {
	switch (type) {
		case skContactActions.FETCH_CONTACTS_LIST: {
			let isLoading = (payload?.isLoading !== undefined && payload?.isLoading !== null) ? payload.isLoading : state.isLoading;
			return {...state, contacts:payload?.contacts,isLoading : isLoading, nextToken : payload?.nextToken, previousToken: payload?.previousToken, totalCount: payload?.totalCount, showSkeleton:payload?.showSkeleton}
		}
	

    case skContactActions.ADD_CONTACT:{return {...state, msgToShow:payload?.successMsg}} 
		case skContactActions.EDIT_CONTACT: return state
		case skContactActions.DELETE_CONTACT:return state
		case skContactActions.SET_CONTACT_LOADING:return state
		case skContactActions.SET_SMARTK_STATE: {return {...state, ...payload}}
		case skContactActions.SET_IS_LOADING:return state
		case skContactActions.SET_IS_ERROR:{return {...state, msgToShow:payload?.errorMessage }}
    default: return state; 
  }
}


export default skContactActionReducer 