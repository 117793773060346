import { cloneDeep } from "lodash";
import { skTaskActions } from "../constants/actionType";

const initialState = {
  showMainSkeleton: false,
  isLoading: false,
  isError: false,
  successMsg: "",
  isSuccess: false,
  errorMessage: "",
  popOverLoader: false,
  taskAccountOptions: [],
  taskProductOptions: [],
  taskCollaboratorOptions: [],
	usersColorMap: {},
  taskOutcomeOptions: [],
  smartTaskCategoryList: null,
  openedCategoryIds: [],
  showSkeletonForIds: [],
  showLoadingForIds: [],
  currentTaskView: "",
  totalTaskCount: 0,
  delayedTaskCount: 0,
  taskList: [],
	globalTaskTableView: null,
  category: "",
  taskPriorityOptions: [
    {
      id: 3,
      label: "Low",
      value: "LOW",
      name: "Low",
      flagClassName: "smart__react__select_low",
    },
    {
      id: 2,
      label: "Medium",
      value: "MEDIUM",
      name: "Medium",
      flagClassName: "smart__react__select_medium",
    },
    {
      id: 1,
      label: "High",
      value: "HIGH",
      name: "High",
      flagClassName: "smart__react__select_high",
    },
  ],
  taskStatusOptions: [
    { id: 1, label: "Completed", value: "COMPLETED" },
    { id: 2, label: "Incompleted", value: "INCOMPLETE" },
  ],
  activeTaskToggleView: null,
  taskSortList: null,
	isTaskTableEditable: true,
	disableSort: null
};

export const skTaskActionReducer = (
  state = initialState,
  { type, payload }
) => {
  switch (type) {
		case skTaskActions?.FETCH_SMART_TASK_LIST: {

      let taskCategoryList;
      if (
        state?.activeTaskToggleView?.toLowerCase() === "List".toLowerCase() &&
        !state?.smartTaskCategoryList
      ) {
        taskCategoryList = [{ categoryId: "List" }];
      } else {
        taskCategoryList =
          state.smartTaskCategoryList && cloneDeep(state.smartTaskCategoryList);
      }
      let index =
        state?.activeTaskToggleView?.toLowerCase() !== "List".toLowerCase()
          ? taskCategoryList &&
            taskCategoryList.findIndex(
              (x) => x.categoryId === payload.categoryId
            )
          : 0;
      if (
        payload?.isSubTask ||
        (payload?.resetTable !== true &&
          taskCategoryList[index]?.taskData &&
          payload?.prevToken &&
          taskCategoryList[index]?.nextToken &&
          taskCategoryList[index]?.nextToken === payload?.prevToken 
)) {
        // Appending
        taskCategoryList[index].taskData = [
          ...taskCategoryList[index]?.taskData,
          ...payload.taskList,
        ];
      } else {
        // Replacing
        taskCategoryList[index].taskData = payload.taskList
          ? [...payload.taskList]
          : null;
      }
      taskCategoryList[index].nextToken = payload.isSubTask
        ? taskCategoryList[index].nextToken
        : payload.nextToken;
      taskCategoryList[index].sortList = payload.sortList || null;
      taskCategoryList[index].sortMode = payload.sortMode;

      return {
        ...state,
        smartTaskCategoryList: taskCategoryList,
        totalTaskCount: payload.totalTaskCount,
        delayedTaskCount: payload.delayedTaskCount,
      };
    }
    case skTaskActions?.CLEAN_SMART_SUB_TASK: {
      let taskCategoryList = cloneDeep(state.smartTaskCategoryList);
      let index =
        taskCategoryList &&
        taskCategoryList.findIndex((x) => x.categoryId === payload.categoryId);
      taskCategoryList[index].taskData = taskCategoryList[
        index
      ].taskData.filter(
        (item) =>
          item?.path?.length === 1 || !item?.path?.includes(payload.parentTaskId)
      );
      return {
        ...state,
        smartTaskCategoryList: taskCategoryList,
      };
    }
		case skTaskActions?.EDIT_SMART_TASK: {
      let taskCategoryList = cloneDeep(state.smartTaskCategoryList);
      let index =
        taskCategoryList &&
        taskCategoryList.findIndex(
          (x) => x?.categoryId === payload?.categoryId
        );
      let taskIndex = taskCategoryList[index].taskData.findIndex(
        (x) => x?.taskId === payload?.data?.taskId
      );
      taskCategoryList[index].taskData[taskIndex] = payload.data;
      return {
        ...state,
        smartTaskCategoryList: taskCategoryList,
      };
    }

    case skTaskActions?.RESET_SMART_TASK_LIST: {
      let taskCategoryList = cloneDeep(state.smartTaskCategoryList);
      if (payload.categoryId !== null) {
        let index =
          taskCategoryList &&
          taskCategoryList.findIndex(
            (x) => x.categoryId === payload.categoryId
          );
        taskCategoryList[index].taskData = null;
        taskCategoryList[index].nextToken = null;
        taskCategoryList[index].sortList = null;
        taskCategoryList[index].sortMode = null;
        return {
          ...state,
          smartTaskCategoryList: taskCategoryList,
        };
      } else {
        taskCategoryList = taskCategoryList.map((item) => {
          return { ...item, taskData: null };
        });
        return {
          ...state,
          smartTaskCategoryList: taskCategoryList,
          openedCategoryIds: [],
        };
      }
    }
    case skTaskActions?.SHOW_SMART_SKELETON: {
      let newShowSkeletonForIds = cloneDeep(state.showSkeletonForIds);
      if (newShowSkeletonForIds?.includes(payload?.categoryId) === false) {
        newShowSkeletonForIds = [...newShowSkeletonForIds, payload?.categoryId];
      }
      return {
        ...state,
        showSkeletonForIds: newShowSkeletonForIds,
      };
    }
    case skTaskActions?.HIDE_SMART_SKELETON: {
      let newShowSkeletonForIds = cloneDeep(state.showSkeletonForIds);
      newShowSkeletonForIds = newShowSkeletonForIds.filter(
        (id) => id !== payload?.categoryId
      );
      return {
        ...state,
        showSkeletonForIds: newShowSkeletonForIds,
      };
    }
    case skTaskActions?.SHOW_SMART_LOADING: {
      let newShowLoadingForIds = cloneDeep(state.showLoadingForIds);
      if (newShowLoadingForIds?.includes(payload?.categoryId) === false) {
        newShowLoadingForIds = [...newShowLoadingForIds, payload?.categoryId];
      }
      return {
        ...state,
        showLoadingForIds: newShowLoadingForIds,
      };
    }
    case skTaskActions?.HIDE_SMART_LOADING: {
      let newShowLoadingForIds = cloneDeep(state.showLoadingForIds);
      newShowLoadingForIds = newShowLoadingForIds.filter(
        (id) => id !== payload?.categoryId
      );
      return {
        ...state,
        showLoadingForIds: newShowLoadingForIds,
      };
    }
		case skTaskActions?.DELETE_SMART_TASK: {

      let categoryId = payload?.task?.categoryId;
      let taskId = payload?.task?.taskId;
      let taskCategoryList = cloneDeep(state.smartTaskCategoryList);
      let index = taskCategoryList.findIndex(
        (item) => item?.categoryId === categoryId
      );
      taskCategoryList[index].taskData = taskCategoryList[
        index
      ]?.taskData?.filter(
        (task) => task?.taskId !== taskId && task?.parentTaskId !== taskId
      );
      return {
        ...state,
        smartTaskCategoryList: taskCategoryList,
      };
    }
    case skTaskActions?.FETCH_ACCOUNT_OPTIONS:
    case skTaskActions?.COLUMN_PREFERENCE:
    case skTaskActions?.SORT_PREFERENCE:
    case skTaskActions?.FETCH_PRODUCT_OPTIONS:
    case skTaskActions?.FETCH_COLLABORATOR_OPTIONS:
    case skTaskActions?.FETCH_OUTCOME_OPTIONS:
    case skTaskActions?.SET_SMART_TASK_STATES:
    case skTaskActions?.MARK_TASK_AS_COMPLETE:
    case skTaskActions?.FETCH_TIMELINE_OUTCOME_OPTIONS: {
      return {
        ...state,
        ...payload,
      };
    }
    default:
      return state;
  }
};

export default skTaskActionReducer;
