import React, { useCallback } from "react";
import UiLabel from "../../SkSmartUtil/UiLabel/UiLabel";
import smartFilterTokenService from "../../SmartFilterTab/SmartFilterToken";


function TouchpointType(props){

	const applyPlaybookDelayedFilter = useCallback((id) => {
		let activeFilter = {
			attribute: 'touchpointList_type',
			name: 'Type',
			values: [id]
		}
		smartFilterTokenService.addFeatureFilter(activeFilter);
	},[])


	return (
		<div className="d-flex" onClick={(e)=>{e.stopPropagation();}}>
			<span className='workspace__title__header '  /*onClick={(e)=>{e.stopPropagation();props.setShowInputFeild(props?.type?.typeId)}}*/><UiLabel length={15} placement="top" tooltipClass="HWSelectMultiSelectTooltip" label={props?.type?.typeName?.trim()} />:<span style={{marginLeft:!props.isClickable?"5px":""}} className={props.isClickable?'cursor-pointer under-line':''}  onClick={(e)=>{props.isClickable && applyPlaybookDelayedFilter(props?.type?.typeId)}}>{props?.type?.count || 0}</span></span>
			{/* } */}
			{props.index<=3 && <span className='virtical_bar'></span>}
		</div>
	)
}
export default TouchpointType