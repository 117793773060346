import Rating from '@mui/material/Rating';
import { styled } from '@mui/material/styles';
import _, { cloneDeep, uniqBy } from 'lodash';
import moment from 'moment';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import PageVisibility from 'react-page-visibility';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import access from '../HWLibrary/AccessControl';
import HWLocalStorage from '../HWLibrary/HWLocalStorage';
import HWUser from '../HWLibrary/HWUser';
import PlatformAPI from '../HWLibrary/PlatformAPI';
import SKAnalyticsLog from '../HWLibrary/SKAnalyticsLog';
import { addZero, durationToTime } from '../HWLibrary/commonFunc';
import MapAccountPopup from '../SkSmartUtil/SkAccountPopups/MapAccountPopup';
import { formatNameToTwoLetters } from '../SkSmartUtil/SkSmartFunctions';
import { SkeletonCardForTouchpointIntelligence } from '../SkSmartUtil/Skeleton/SkeletonCard';
import SKNoDataScr from '../SkSmartUtil/SKNoData/SKNoData';
import AutoUiLabel from '../SkSmartUtil/UiLabel/AutoUiLabel';
import UiLabel from '../SkSmartUtil/UiLabel/UiLabel';
import avatarIcon from '../assets/images/icons/avatar.png';
import blackInfoIcon from '../assets/phoenix/info_purple.png';
import { addComment, deleteComment, editCoaching, editComment, fetchComments, fetchSummary, fetchTranscripts, getKonversationState, setRating, setSmartKState } from '../store/reducers/skKonversationReducer';
import constactServices from '../views/Settings/SettingsChildren/Contacts/ContactServices';
import { SmartVideoPlayer } from './Details/SmartVideoPlayer';
import NoRecordings from './NoRecordings';
import TouchpointItem from './TouchpointItem';
import Joyride from 'react-joyride';
import SmartConfirmModal from '../SkSmartUtil/SmartConfirmModal/SmartConfirmModal';
import '../SkSmartUtil/WalkThroughComponent/walkThrough.css';
import StarIcon from '@mui/icons-material/Star';
import {sendLogsToSever, getGlobalStates, getNotifcationCount} from '../store/reducers/skGlobalReducer';
import SkSmartComments from '../SkSmartUtil/SkSmartComments/SkSmartComments';
import SkLoaderComponent from '../SkSmartUtil/SkLoader/SkLoaderComponent';
import skApiService from '../SkApiService';


const TouchpointIntelligence = (props) => {
  const location = useLocation();
  const [selectedSeekTime, setSelectedSeekTime] = useState('');
  const transcriptRef = useRef(null);
  const videoRef = useRef(null);
  const dispatch = useDispatch();
  const getTransState = useSelector(getKonversationState);
  const { transcripts, videoUrl ,meetingId,isTransApiLoading, isLoading, transcriptRating, successMsg, errorMessage, touchpointDetail, comments, summary, actionItem, showComment } = getTransState;
  const playerRef = useRef(null);
  // let meetingId = location?.state?.meetingId || HWLocalStorage.load('meetingId')

	const [accountMapingData, setAccountMapingData] = useState([]);
	const [isFetchingMappingData, setIsFetchingMappingData] = useState(false);
	const [showHidePopup, setShowHidePopup] = useState(false);
	const [selectedZoomMeetingId, setSelectedZoomMeetingId] = useState(null);
	const [callingFromMail, setCallingFromMail] = useState(false);
	const commentRef = useRef(null);
	let callingFrom = location?.state?.callingFrom ? location?.state?.callingFrom : null;


	let setTranscriptTime = null;
	const onMountTranscript = (currrentTimeSetters) => {
		setTranscriptTime = currrentTimeSetters[1];
	}

	const onPlayerTimeUpdate = (timeInSec) => {
		setTranscriptTime(timeInSec)
	}

	const onSelectTranscriptItem = (timeInSec) => {
		setSelectedSeekTime(timeInSec)
	}


	const onReady = (player) => {
		playerRef.current = player;
		player.on('timeupdate', () => { onPlayerTimeUpdate(player.currentTime()) });
		player.on('seeked', () => { onPlayerTimeUpdate(player.currentTime()) });
	}

	const onSetRating = (val) => {
		let data = {
			rating: val,
			meetingId: location?.state?.meetingId || HWLocalStorage.load('meetingId')
		}
		dispatch(setRating(data))
	}

	useEffect(() => {
		let req = {
			feature: 'Konversation',
			logType: 'Visit',
			loggedAtTime: new Date().toISOString(),
		}
		fetchContactAndAccountDetails();
		dispatch(sendLogsToSever(req));
	}, []);

	const onEditComment = (commentId, comment) => {
		const data = {
			meetingId: meetingId,
			comment: comment,
			commentId: commentId
		}
		dispatch(editComment(data, false))
	}

	const onDeleteComment = (commentId) => {
		dispatch(deleteComment(commentId, false))
	}
	useEffect(() => {
		fetchContactAndAccountDetails();
		dispatch(getNotifcationCount());
		if (HWLocalStorage.load('meetingId')) {
			dispatch(fetchTranscripts(HWLocalStorage.load('meetingId')));
			dispatch(fetchComments(HWLocalStorage.load('meetingId')));
			dispatch(fetchSummary(HWLocalStorage.load('meetingId')))

		}
		return () => {
			HWLocalStorage.clear('meetingId');
			resetState();
		}
	}, [])

	useEffect(() => {
		if (touchpointDetail && touchpointDetail.name) {
			if (touchpointDetail.hostId && touchpointDetail.hostId === HWUser.userId()) {
				SKAnalyticsLog.logEvent('Touchpoint Intelligence View: Me')
			} else {
				SKAnalyticsLog.logEvent('Touchpoint Intelligence View: My Team')
			}
		}
	}, [touchpointDetail])

	const resetState = () => {
		dispatch(setSmartKState({ transcripts: '', comments: '', videoUrl: '', touchpointDetail: '', hostId: null, meetingId: null, callingFrom: null }))
	}

	useEffect(() => {
		if (meetingId) {
			HWLocalStorage.save(meetingId, 'meetingId');
			dispatch(fetchTranscripts(meetingId))
			dispatch(fetchComments(meetingId));
			dispatch(fetchSummary(meetingId))
		}
	}, [meetingId])


	let fetchContactAndAccountDetails = () => {
		setIsFetchingMappingData(true);
		let cacheExists = (((accountMapingData && accountMapingData.length > 0) || false));
		constactServices.fetchContactAndAccountDetails(cacheExists, (conatctList) => {
			setAccountMapingData(conatctList.customerAccounts);
			setIsFetchingMappingData(false);
		})
	}


	const openMapAccount = (zoomMeetingId) => {
		setSelectedZoomMeetingId(meetingId)
		setShowHidePopup(true);
	}

	const closeMapAccount = () => {
		setShowHidePopup(false);
		dispatch(fetchTranscripts(meetingId, true))

	}

	let isTime = durationToTime(touchpointDetail?.duration, 'minutes', true);
	const [activeTab, setActiveTab] = useState(callingFrom ? callingFrom : 'team_conversations');

	useEffect(() => {
		let tab = callingFrom ? callingFrom : 'team_conversations'
		setActiveTab(tab)
	}, [callingFrom]);


	const onVisibilityChange = useCallback((isVisible) => {
		if (!isVisible) { return; }
		if (videoUrl) {
			try {
				if ((Date.now() >= ((JSON.parse(atob(new URLSearchParams(videoUrl.split('?')[1]).get('access_token').split('.')[1])).exp) * 1000) - 60000)) {
					dispatch(fetchTranscripts(meetingId, true))
				}
			} catch { }
		}
	}, [videoUrl, meetingId, dispatch])

	// Confirmation popup states =======================
	const [confirmationPopupState, setConfirmationPopupState] = useState({
		showWarningPopUp: false,
		warningMsg: '',
		showOnlyOkAction: false,
		rating: '',
		meetingId: '',
		description: ''
	})

	const setConfirmationPopupDetails = (updatedState) => {
		setConfirmationPopupState(prevState => (
			{
				...prevState,
				...updatedState
			}
		))
	}
	// Confirmation popup states =======================

	const confirmationPopupActions = useMemo(() => {
		return {
			handleWarningMsgsForPopUp: (onlyOk, popupState, msg, rating, meetingId, description) => {
				setConfirmationPopupDetails({
					showOnlyOkAction: onlyOk,
					showWarningPopUp: popupState,
					warningMsg: msg,
					rating: rating,
					meetingId: meetingId,
					description: description
				})
			},
			onOkConfirmation: () => {
				if (confirmationPopupState?.warningMsg === "Please be aware this is a one-time process and cannot be modified after completion. Click 'OK' to proceed") {
					let data = {
						rating: confirmationPopupState?.rating,
						meetingId: confirmationPopupState?.meetingId,
						description: confirmationPopupState?.description
					}
					dispatch(editCoaching(data)).then(res => {
						confirmationPopupActions?.handleWarningMsgsForPopUp(true, true, 'Feedback Recorded Successfully');
					})
				}
				if (confirmationPopupState?.warningMsg === 'Feedback Recorded Successfully') {
					confirmationPopupActions?.handleWarningMsgsForPopUp(false, false, '')
				}
			},

			onCancelConfirmation: () => {
				if (confirmationPopupState?.warningMsg === "Please be aware this is a one-time process and cannot be modified after completion. Click 'OK' to proceed") {
					confirmationPopupActions?.handleWarningMsgsForPopUp(false, false, '')
				}

			}

		}
	}, [confirmationPopupState?.description, confirmationPopupState?.meetingId, confirmationPopupState?.rating, confirmationPopupState?.warningMsg, dispatch, successMsg])

  return (
    <>
      {/* <div className='sk_smartFilterTab_wrap'>
        <SmartFilterTab disableGlobalAttributeFilter={true}
          disableGlobalShare={false}
          disableGlobalAccountFilter={true}
          disableGlobalProductFilter={true}
           key='1234234234' tabs={tabs()}
            onTabActive={onTabActive} 
            activeTab={activeTab} 
            /></div>
      <div className='smartpage'>
        <div className='sk-smart-backgroung-light-purple-img sk-smart-backgroung-light-bg-height'>
          <div className='sk-smart-backgroung-dark-purple-img-1'>
            <div className='touchpoint__intelligence__back__btn__container sk_smart__detail-header-name'>
              <Link className='sk_smart__back__btn__container-icon' to='/dashboard/smart-konversations' state={{ activeTab: activeTab }}>
              </Link>
            </div>
          </div>
          <div className='sk-smart-backgroung-dark-purple-img-2'></div>
          <div className='sk-smart-dashboard-container-content'> */}
          <>
            {!touchpointDetail && <SkeletonCardForTouchpointIntelligence />}
            {/* <BlockUi blocking={isLoading} style={{ width: '100%' }}> */}
						<SkLoaderComponent isLoading={isLoading}/>
              {touchpointDetail &&
                <div className='smart__konversations__touchpoint__intelligence'>
                  {(getKonversationState().activeTab === 'team_conversations' || getKonversationState().activeTab === 'my_conversations') &&
                    (access?.smartKonversation?.my?.view() || access?.smartKonversation?.team?.view())
                    &&
                    <div style={{ margin: '0rem', display: 'flex', flexDirection: 'column' }} className='touchpoint__intelligence smart__konversations__dashboard'>
                      <div className='row ptr_1 meet-head'>
                        <div className='touchpoint__intelligence__header meet-table mb-0 pb-0'>
                          <div className='w-25'>
                            <div className='table-icon-td sk__host__name' >
                              <TouchpointItem  length={25} callingFrom='touchpointIntelligence' {...touchpointDetail} isLink={false} isFetchingMappingData={isFetchingMappingData} openMapAccount={openMapAccount} />
                            </div>
                          </div>
                          <div>
                            <div className='timings-p'>
                              <div className='touchpoint__intelligence__calendar__container'>
                                <div className='touchpoint__intelligence__calendar__container-icon'></div>
                              </div>

												<div style={{ marginLeft: '1.563rem', fontWeight: '400' }}>
													{moment(touchpointDetail?.dateTime).format('DD MMM')},&nbsp;&nbsp;
													{moment(touchpointDetail?.dateTime).format('hh:mm A')}</div>
											</div>
										</div>
										<div>
											<div className='d-flex'>
												{touchpointDetail?.collaborators?.[0]?.name ?
													<div style={{ backgroundColor: (touchpointDetail?.collaborators?.[0]?.imageURL) ? 'white' : '' }}
														className='smart__konversations__collaborator__name__wrap'>
														<div className='smart__konversations__collaborator__name__wrap-name'>
															{touchpointDetail?.collaborators?.[0]?.imageURL ?
																<img className='avatar smart__konversation__collaborator__profile__image' src={touchpointDetail?.collaborators?.[0]?.imageURL ? touchpointDetail?.collaborators?.[0]?.imageURL : avatarIcon} alt='' />

																:
																<div className='smart__konversations__collaborator__name__wrap-name'>{touchpointDetail?.collaborators?.[0]?.name ? formatNameToTwoLetters(touchpointDetail?.collaborators?.[0]?.name) : '-'} </div>
															}

														</div>

													</div> : '-'}


												<div className='tp-hostname'>
													<AutoUiLabel placement='top' tooltipClass='HWSelectMultiSelectTooltip touchpoint__intelligence__label' width={'100%'} label={touchpointDetail?.collaborators?.[0]?.name} />
												</div>
											</div>
										</div>

										<div>{isTime ?
											<div className='clockWrap'>
												<div className='touchpoint__intelligence__clock__container'>
													<div className='touchpoint__intelligence__clock__container-icon'>

													</div>
												</div>
												<div className='touchpoint__intelligence__clock__text'>{isTime}</div></div> : <p className='emptyData' style={{ paddingLeft: '5px' }}>-</p>}</div>

										<div>
											<div className='d-flex'>
												<UiLabel tooltipClass='letAlign sentiment__text' showToolTipWithHtml={true} length={0} placement='top' label={'An incisive evaluation of the overall emotional tone or attitude expressed during the conversation.'}>
													<img src={blackInfoIcon} alt='' className='phonix_info' style={{ marginRight: '5px', width: '14px', height: '14px', marginTop: '7px' }} />
												</UiLabel>
												<div className='sentiment-text'>Sentiment</div>
												{/* <Score callingFrom='touchpointDetail'
                                                                score={touchpointDetail?.sentimentScore}
                                                            /> */}
												<ScoreScale score={touchpointDetail?.sentimentScore} />
											</div>
										</div>
										<div><span className='smart__konversations__talk__ratio'>Talk Ratio: <span style={{ marginLeft: '0.313rem' }}>{`${Math.round(touchpointDetail?.talkRatio)}%`}</span></span></div>
										{/* <div><span className='smart__konversations__longest__monologue'>Longest Monologue : <span style={{ marginLeft: '0.313rem' }}>{secondsToHms(touchpointDetail?.longestMonologue)}</span> </span></div> */}
									</div>

								</div>
								<div className='row ptr_1 mt-20 smart_konv_main'>
									{(access?.smartKonversation?.view() || access?.smartKonversation?.view()) &&
										<div className='col-md-7 pl-0 centerGutterRight'>
											{access?.smartKonversation?.view() &&
												<div className='card card-small smart__konversations__video__player' style={{ display: 'flex' }}>
													{videoUrl && <PageVisibility onChange={onVisibilityChange} />}  {/* Check for access token expiry time. */}
													{videoUrl ? <SmartVideoPlayer ref={videoRef} onReady={onReady} videoUrl={videoUrl} isTransApiLoading={isTransApiLoading} key={'1234-121-'} currentSeekTime={selectedSeekTime} onPlayerTimeUpdate={onPlayerTimeUpdate} /> : <NoRecordings adminEmailId={transcripts?.adminEmailId} />}
												</div>
											}
											<div className='mb-15 mt-20 card card-small fit-content-height'>
												<CallSummary summary={summary} actionItem={actionItem} />
											</div>
										</div>
									}
									<div style={{ paddingRight: '0px' }} className='col-md-5 pl-0'>
										{access?.smartKonversation?.view() &&
											<div className='card card-small'>
												<div className='card-header transcript__body'>
													<SmartTranscript showComment={showComment} confirmationPopupActions={confirmationPopupActions} confirmationPopupState={confirmationPopupState} ref={transcriptRef} onEditComment={onEditComment} onDeleteComment={onDeleteComment} commentRef={commentRef} transcriptRating={transcriptRating} onSetRating={onSetRating} onMountTranscript={onMountTranscript} transcripts={transcripts} onSelectTranscriptItem={onSelectTranscriptItem} />
												</div>
											</div>
										}

										<div className='mb-15 mt-20 card card-small fit-content-height'>
											<ActionItem actionItem={actionItem} />
										</div>
									</div>
								</div>
							</div>
						}
						{showHidePopup && <MapAccountPopup callingFrom='Konversations' closeMapAccount={closeMapAccount} accountMapingData={accountMapingData} selectedZoomMeetingId={selectedZoomMeetingId} />}

					</div>}

				{/* </BlockUi> */}
			</>
			{/* </div>
        </div>
      </div> */}
			{confirmationPopupState?.showWarningPopUp && <SmartConfirmModal showOnlyOkButton={confirmationPopupState?.showOnlyOkAction} onlyOkAction={confirmationPopupActions?.onOkConfirmation} className='asDeleteModel' msg={confirmationPopupState?.warningMsg} okClickCallback={confirmationPopupActions?.onOkConfirmation} callBackNotRequired={true} cancelClick={confirmationPopupActions?.onCancelConfirmation} headtxt='Delete' targetObject={confirmationPopupState?.showWarningPopUp} />}
		</>
	);
}

export default TouchpointIntelligence;


const ScoreScale = ({ score, ...props }) => {
	return (
		<div className='sentiment_score_wrap'>
			<div className='sentiment_score__container'>
				<div className='sentiment_score sentiment_first_block' style={{ backgroundColor: '#F24D33', border: score === 1 ? '1.8px solid #000' : '' }}>1</div>
				<div className='sentiment_score' style={{ backgroundColor: '#FF7E71', border: score === 2 ? '1.8px solid #000' : '' }}>2</div>
				<div className='sentiment_score' style={{ backgroundColor: '#FEC86E', border: score === 3 ? '1.8px solid #000' : '' }}>3</div>
				<div className='sentiment_score' style={{ backgroundColor: '#A6CC75', border: score === 4 ? '1.8px solid #000' : '' }}>4</div>
				<div className='sentiment_score sentiment_last_block' style={{ backgroundColor: '#4FD17D', border: score === 5 ? '1.8px solid #000' : '' }}>5</div>
			</div>
		</div>
	)
}


const CallSummary = ({ summary, ...props }) => {

	let summaryToShow = summary?.replace('\n\n', '\n').trim()
	summaryToShow = summaryToShow?.replace('/n', '/n').trim();

	return (
		<div className='summary card-header' id='profile'>
			<div style={{ padding: '0px' }} className='col-12 col-sm-12 d-flex justify-content-between widget_title'>
				<h4 className='m-0 smart__konversations__card__header' >Call Summary</h4>
			</div>
			{summary ?
				<div className='touchpoint__intelligence__scrollable__div'>
					<div className='summary-actions-desc'>
						{summaryToShow}
					</div>
				</div> : <SKNoDataScr minHeight="350px" border={true} text='No data available' />}
		</div>
	);
}

const ActionItem = ({ actionItem, ...props }) => {

	return (
		<div className='summary card-header' id='profile'>
			<div style={{ padding: '0px' }} className='col-12 col-sm-12 d-flex justify-content-between widget_title'>
				<h4 className='m-0 smart__konversations__card__header' >Action Item</h4>
			</div>
			{actionItem ?
				<div className='touchpoint__intelligence__scrollable__div'>
					<div className='summary-actions-desc'>
						{actionItem}
					</div>
				</div> : <SKNoDataScr minHeight="350px" border={true} text='No data available' />}
		</div>
	);
}



const Coaching = ({ meetingId, hostName, successMsg, coachingDetail, confirmationPopupActions, confirmationPopupState, ...props }) => {

	const [rate, setRate] = useState('')
	const [description, setDescription] = useState('')
	const dispatch = useDispatch();
	const [ratingsDisabled, setRatingsDisabled] = useState(null);

	const onEditCoaching = (rating) => {
		const data = {
			meetingId: meetingId,
			rating: rating,
			description: description
		}
		dispatch(editCoaching(data));
	}

	const onRatingsClicked = (rating) => {
		confirmationPopupActions?.handleWarningMsgsForPopUp(false, true, `Please be aware this is a one-time process and cannot be modified after completion. Click 'OK' to proceed`, rating, meetingId, description)
	}



	useEffect(() => {
		if (coachingDetail?.rating) {
			setRate(coachingDetail?.rating);
			setDescription(coachingDetail?.coachingComment || '');
		}

		if (!coachingDetail?.rating) {
			setRate(null);
			setDescription('');
		}

		if (coachingDetail?.rating >= 1) {
			setRatingsDisabled(true)
		}

	}, [coachingDetail]);

	const StyledRating = styled(Rating)({
		// '& .MuiRating-iconFilled': {
		//   color: '#7327CC',
		// },
		// '& .MuiRating-iconHover': {
		//   color: '#7327CC',
		// },
		'& .MuiRating-icon': {
			fontSize: '25px'
		}
	});

	return (<>
		<div className='coatch-div'>
			{/* <UiLabel tooltipClass='letAlign feedback__text' showToolTipWithHtml={true} length={0} placement='top' label={'Please be advised that the option to rate a call is only available to the reporting manager of the Customer Service Manager'}>
               <img src={blackInfoIcon} alt='' className='phonix_info' style={{ width: '13px', margin: '5px 5px 0px 0px' }} />
            </UiLabel> */}
			{/* <div className='smart__konversations__feedback__header'>Rating:</div> */}
			<StyledRating name='customized-color' emptyIcon={<StarIcon style={{ opacity: 0.55 }} fontSize="inherit" />} onChange={(event, newValue) => { onRatingsClicked(newValue) }} value={rate} readOnly={(HWUser.isAdminUser() || HWUser.iscslUser()) ? false : true} size='small' disabled={rate >= 1 ? true : false} />
			<UiLabel tooltipClass='letAlign feedback__text' showToolTipWithHtml={true} length={0} placement='top' label={'Please be advised that the option to rate a call is only available to the reporting manager of the Customer Service Manager'}>
				<img src={blackInfoIcon} alt='' className='phonix_info' style={{ width: '13px', marginLeft: '5px' }} />
			</UiLabel>
		</div>
	</>

	);
}


const Comments = ({ meetingId, commentList, confirmationPopupActions, confirmationPopupState, ...props }) => {
  const getTransState = useSelector(getKonversationState);
  const { isLoading,successMsg, touchpointDetail } = getTransState
  const commentObj = {
    commentId: '',
    commentTime: '',
    commentedByUserId: '',
    commentedByUserImage: '',
    commentedByUserName: '',
    description: ''
  }
  const [comment, setComment] = useState(commentObj);
  const location = useLocation();
  const [description, setDescription] = useState('');
  const [comments, setComments] = useState([]);
  const [users, setUsers] = useState([]);
  const [mention, setMention] = useState([]);
  const [editorText, setEditorText] = useState('');
  const mentionsInputRef = React.createRef();
  const [container, setContainer] = useState(null)
	const [isInsidePopperOn, setIsInsidePopperOn] = useState(false);

	const dispatch = useDispatch();

	const resetComment = (e) => {
		setComment({ description: '' });
		setMention([]);
	}
	useEffect(() => {
		fetchOrgUsersList()
	}, []);


	const onMentionsInputChange = (event, newValue, newPlainTextValue, allMentions) => {
		const mentions = uniqBy([...(mention || []), ...(allMentions || [])], i => i.id);
		setDescription(event.target.value);
		let editedComment = cloneDeep(comment);
		editedComment['description'] = event.target.value;
		setComment(editedComment);
		setMention(mentions);
	}

	const fetchOrgUsersList = () => {
		let cacheExists = ((users && users.length > 0) || false);
		PlatformAPI.fetchOrgUsers(cacheExists, (orgUsers) => {
			let activeOrgUsers = orgUsers && orgUsers.filter((i) => {
				return (i.userStatus === 'Active')
			})
			let userList = activeOrgUsers.map(user => { return { id: user.id, display: user.name } });
			setUsers(userList);
		})
	}

	const onAddComment = (e) => {
		e.preventDefault();
		let taggedUsers = mention.map((item) => { return item.id })
		const data = {
			meetingId: meetingId,
			comment: comment?.description,
			taggedUsers: taggedUsers,
		}

		if (comment?.commentId) {
			let updateComments = _.cloneDeep(comments);
			updateComments = updateComments?.map(item => comment?.commentId === item?.commentId ? comment : item);
			dispatch(setSmartKState({ comments: updateComments, taggedUsers: taggedUsers }))
			props.onEditComment(comment?.commentId, comment?.description);
			setDescription('');
		} else {
			dispatch(addComment(data));
			setDescription('');
		}
		resetComment();
	}

	const onDeleteComment = (commentId) => {
		if (commentId) {
			let updateComments = _.cloneDeep(comments);
			updateComments = updateComments?.filter(item => commentId !== item?.commentId);
			dispatch(setSmartKState({ comments: updateComments }));
			props.onDeleteComment(commentId)
		}
	}

	const onEditComment = (id) => {
		let updateComments = _.cloneDeep(comments);
		updateComments = updateComments?.filter(item => item?.commentId === id)?.[0]
		setComment(updateComments)

	}

	const mentionStyle = {
		suggestions: {
			list: {
				fontSize: 14,
				background: '#fff',
				borderRadius: '4px',
				boxShadow: '0 0 0 1px rgba(111,119,130,.15), 0 5px 20px 0 rgba(21,27,38,.08)',
				MozBoxSizing: 'border-box',
				boxSizing: 'border-box',
				minWidth: '150px',
				maxWidth: '350px',
				maxHeight: '150px',
				overflowY: 'auto',
				zIndex: '9999999'
			},
			item: {
				padding: '10px 10px',
				height: '30px',
				textAlign: 'cetner',
				display: 'flex',
				justifyContent: 'left',
				flexDirection: 'start',
				alignItems: 'center',
				borderBottom: '1px solid rgba(0,0,0,0.15)',
				color: '#59686b', fontFamily: 'var(--sk-font-urbanist-family)', fontSize: '12px', fontWeight: '400',
				'&focused': {
					backgroundColor: '#e5e5e8',
					color: '#151b26'
				},
			},
			'&multiLine': {
				control: {
					fontFamily: 'var(--sk-font-urbanist-family)',
					minHeight: 63,
				},
				highlighter: {
					padding: 9,
					border: '1px solid green',
					color: 'red'
				},
				input: {
					padding: 9,
					border: '1px solid silver',
					color: 'red'
				},
			},
			'&singleLine': {
				display: 'inline-block',
				width: 180,

				highlighter: {
					padding: 1,
					border: '2px inset red',
					color: 'red'
				},
				input: {
					padding: 1,
					border: '2px inset yellow',
					color: 'red'
				},
			},
		},
	}

	useEffect(() => {
		if (commentList) {
			setComments(commentList);
		}
	}, [commentList]);
	let newimage;
	let user = (JSON.parse(window.localStorage.getItem('platform-currentUser')))
	if (user && user.user && user.user.profileImageURL) { newimage = user.user.profileImageURL }



	return (
		<>
			<div className='commentsWrap' id='comments' role='tabpanel' aria-labelledby='comments-tab'>
				<div className='d-flex justify-content-between'>
					<p className='coaching-p'>Rate this call to help us to improve your experience</p>
					<Coaching confirmationPopupActions={confirmationPopupActions} confirmationPopupState={confirmationPopupState} successMsg={successMsg} hostName={touchpointDetail?.hostName} coachingDetail={touchpointDetail?.coachingDetail} meetingId={location?.state?.meetingId || HWLocalStorage.load('meetingId')} />
				</div>
				<SkSmartComments featureId={meetingId} feature="SmartKonversation" isDisabled={false} setIsInsidePopperOn={setIsInsidePopperOn} />
			</div>
		</>
	);
}

const Comment = ({ commentId, commentTime, commentedByUserId, commentedByUserName, description, onEditComment, onDeleteComment, commentedByUserImage }) => {
	let des = skApiService.parseReactMentionsToHTMLText(description);
	useEffect(() => {
		let pathName = window.location.search;
		const comment = new URLSearchParams(pathName)?.get('commentId');
		if (comment) {
			let commentToScroll = document.getElementById(comment);
			commentToScroll?.scrollIntoView({ block: "end" });
		}
	}, []);
	return (
		<div className='comment-wrap' id={commentId}>
			<div className='d-flex smartKon-profile-wrap'>
				<img className='avatar' src={commentedByUserImage ? commentedByUserImage : avatarIcon} alt='' />
				<span className='smartKon-user-name'>{commentedByUserName}</span>
				<span className='smartKon-comment-time'>{moment(commentTime).fromNow(true) + ' ago'}</span>
			</div>
			<div>
				<div className='descriptionText' dangerouslySetInnerHTML={{ __html: des }}></div>
				{HWUser.userId() === commentedByUserId && <><span className='edit-or-delete' onClick={(e) => onEditComment(commentId)}>Edit</span> | <span className='edit-or-delete' onClick={(e) => onDeleteComment(commentId)}>Delete</span></>}
			</div>
		</div>
	)
}


const SmartTranscript = ({ showComment, transcriptRating, onSetRating, transcripts, onSelectTranscriptItem, onMountTranscript, commentRef, onEditComment, onDeleteComment, confirmationPopupActions, confirmationPopupState }) => {
  const getTransState = useSelector(getKonversationState);
	const { successMsg, touchpointDetail, comments } = getTransState;
	const location = useLocation();
	const [currentTime, setCurrentTime] = useState(0);
	const [activeTab, setActiveTab] = useState(showComment ? 'Coaching' : 'Transcript');
	const globalNotificationState = useSelector(getGlobalStates);
	const { konversationCount } = globalNotificationState;


	useEffect(() => {
		onMountTranscript([currentTime, setCurrentTime])
		try {
			let timeSec = transcripts.filter(i => currentTime <= i.end && currentTime >= i.start)[0]?.start;
			const allEle = transcripts.map(i => `ele${i.start}`)
			const elementId = `ele${timeSec}`;
			// eslint-disable-next-line array-callback-return
			allEle.map(item => {
				if (item === elementId) {
					document.getElementById(item).classList.add('currTranscript')
					// document.getElementById(item)?.scrollIntoView({ block: 'end' });
					const ele = document.getElementById('scrolling_div')
					ele.scrollTop = (document.getElementById(item).offsetTop - 150)
				}
				else { document.getElementById(item).classList.remove('currTranscript') }
			})
		} catch {

		}

	}, [onMountTranscript, currentTime, transcripts]);


	const steps = [
		{
			target: '.coaching__header',
			disableBeacon: true,
			content: <div className="sk--walk-through--wrap">
				<div className='sk--walk-through-heading--wrap'>
					<div className='sk--walk-through-heading-text'>New Feature</div>
				</div>
				<div className='sk--walk-through-content--wrap'>
					<div className='sk--walk-through-content-text--wrap'>
						Managers can now rate their team's calls and provide personalised coaching to foster a path of positive improvement.
					</div>
					{/* <div className='sk--walk-through-btn--wrap'>
				<a href = "https://academy2.smartkarrot.com" target='_blank' className='sk--walk-through-heading-text'>Learn More</a>
			  </div> */}
				</div>
			</div>,
		}]


	return (
		<div>
			{(konversationCount === 0 || konversationCount === 4) && <Joyride steps={steps} disableScrolling={true} locale={{ close: 'Ok, got it' }} showCloseButton={false}
				styles={{ options: { primaryColor: 'var(--secondary-color)', } }} hideCloseButton={true} showSkipButton={true} />}
			<div className='d-flex justify-content-between transcript__header__wrap'>
				<div className='d-flex coahing__tab'>
					<div className={`transcript__header ${activeTab === 'Transcript' ? 'smart__kon__active' : ''}`} onClick={e => setActiveTab('Transcript')}>Transcript</div>
					<div className={`coaching__header ${activeTab === 'Coaching' ? 'smart__kon__active' : ''}`} onClick={e => setActiveTab('Coaching')}>Coaching</div>
				</div>
			</div>

			{activeTab === 'Transcript' && <div>
				{transcripts?.length > 0 ? <>
					<div className='touchpoint__intelligence__scrollable__div' id='scrolling_div'>
						<ul className='transcrit-list' >
							{(transcripts || []).map(item => {
								let d = moment.duration(item?.start, 'minutes');
								let hours = Math.floor(d.asHours());
								let mins = Math.floor(d.asMinutes()) - hours * 60;

								return <li onClick={() => onSelectTranscriptItem(item?.start)} className='eleItem' id={`ele${item?.start}`}>
									<p className='heading'>{item?.name}<span>{`${addZero(hours, 2)}:${addZero(mins, 2)}`}</span></p>
									<p>{item?.text}</p>
								</li>
							})}

						</ul>
					</div>
					<div className='text-center w-100'>
					</div>

				</> :
					<SKNoDataScr minHeight="350px" border={true} text='No data available' />}
			</div>}
			{activeTab === 'Coaching' && <div>
				<div id='myTabContent'>
					<Comments meetingId={location?.state?.meetingId || HWLocalStorage.load('meetingId')} confirmationPopupActions={confirmationPopupActions} />
				</div>
			</div>}
		</div>

	);
}



